//js code packages
import React, { Component } from 'react'; //React js language
import { observer, inject } from 'mobx-react'; //state management injecting data and action methods from "Store.js" files

//Library of generic components and js functions
import * as JSFUNC from "../../Library/JSFUNC.js"; //general js functions that do math, array operations, etc
import * as LibraryReact from "../../Library/LibraryReact.js"; //general React Components such as Nowrap, MaxHeightWrap, Message

//generic components and constants for CaptureExec
import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js"; //Input/Genearl React Components specific to CaptureExec with css color scheme (buttons), database field input types (filling selects using DatabaseMobx), etc

//CaptureExec Local Database Mobx
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js"; //classes/functions related to calling php scripts and parsing the returned data

//CaptureExec components
import * as AdminReact from '../Admin/AdminReact.js';
import * as AdminImportReact from "../AdminImport/AdminImportReact.js";
import * as AdminIntegrationsReact from "../AdminIntegrations/AdminIntegrationsReact.js";
import * as BudgetReact from "../Budget/BudgetReact.js";
import * as CapturesReact from "../Captures/CapturesReact.js";
import * as ContactsReact from "../Contacts/ContactsReact.js";
import * as DivexecReact from "../Divexec/DivexecReact.js";
import * as GCSSReact from "../GCSS/GCSSReact.js";
import * as OpenCaptureReact from '../OpenCapture/OpenCaptureReact.js'; //React Component and all subcomponents for single capture screen that slides over entire system when opening a capture
import * as RightPanelReact from "../RightPanel/RightPanelReact.js"; //React Components for user panel menu and all subpanels
import * as SamGovTrackerReact from "../SamGovTracker/SamGovTrackerReact.js";
import * as TeammateContractsReact from "../TeammateContracts/TeammateContractsReact.js";


const CaptureExec = inject("CaptureExecMobx", "UserMobx")(observer(
class CaptureExec extends Component { //props:
  componentDidMount() {
    //1) On-Prem Auth         (only on-premise) verify on-premise authentication and start 1 hour interval verifying authentication
    //2) Code Version         verify live/dev code version and start 1 hour interval verifying live/dev code version (handles case where user leaves captureexec.com on login screen for several days then logs in, when a code update is released, this 1 hour check will change login to the forced refresh screen)
    //3) Direct Access Link   if url contains valid 70 char direct access link, skip login and enter direct access single capture mode
    //also start Auth 1hr interval and Code Version 1hr interval
    this.props.CaptureExecMobx.a_run_verifications_to_initialize_login_from_url();
  }

  componentWillUnmount() {
    //(only on-premise) 1 hour authentication verification interval is always active until browser is closed, will kick a user while logged in if expiration is crossed
    this.props.CaptureExecMobx.a_clear_verify_on_premise_authentication_timed_interval();
  }

  ondragover_captureexec = (event) => {
    event.preventDefault(); //prevent entire system backdrop from working as a drag/drop zone (normal behavior is to display the contents of a file when dropped on a website)
    this.props.CaptureExecMobx.a_set_any_file_or_ce_item_dragged_over_any_part_of_website_tf(true);
  }

  ondrop_captureexec = (event) => {
    event.preventDefault();
    this.props.CaptureExecMobx.a_set_any_file_or_ce_item_dragged_over_any_part_of_website_tf(false);
  }

  render() {
    const o_loginState = this.props.CaptureExecMobx.o_loginState;
    const c_productIsCaptureExecTF = this.props.CaptureExecMobx.c_productIsCaptureExecTF;
    const c_userIsLoggedIntoCaptureExecSystemTF = this.props.CaptureExecMobx.c_userIsLoggedIntoCaptureExecSystemTF;
    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    var mainScreenComponent = null;
    if(!c_userIsLoggedIntoCaptureExecSystemTF) {
      if(c_productIsCaptureExecTF) { //"out", all other login process intermediate steps
        mainScreenComponent = (<CaptureExecLoginScreen />);
      }
      else {
        if(o_loginState === "sgtBuySgtEnterInfo") {
          mainScreenComponent = (<SamGovTrackerReact.SamGovTrackerBuySGTProcess />);
        }
        else if(o_loginState === "sgtCaptureExecLearnMoreAd") {
          mainScreenComponent = (<SamGovTrackerReact.SamGovTrackerHomepageAdWithLogin />);
        }
        else { //"out", all other login process intermediate steps
          mainScreenComponent = (<SamGovTrackerReact.SamGovTrackerHomepageAdWithLogin />);
        }
      }
    }
    else { //only "in" gets you to this point
      mainScreenComponent = (<CaptureExecLoggedIn />);
    }

    return(
      <div
        className="positionFixedFullScreen bgCaptureExec"
        style={{fontSize:c_userFontSizePx + "px"}}
        onDragOver={this.ondragover_captureexec}
        onDrop={this.ondrop_captureexec}>
        {mainScreenComponent}
        <CaptureExecPhpLoadingAnimation />
        <ErrorMessage />
      </div>
    );
  }
}));
export default CaptureExec;


//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&


const CaptureExecLoginScreen = inject("CaptureExecMobx")(observer(
class CaptureExecLoginScreen extends Component { //props:
  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    var outerClass = "yScroll";
    var innerClass = "";
    var innerWidth = "45em";
    var innerMargin = "15vh auto 0 auto";
    if(c_isMobileOrTabletTF) {
      outerClass = "displayFlexColumn";
      innerClass = "flex11a yScroll";
      innerWidth = "auto";
      innerMargin = "1em";
    }

    return(
      <div className={"positionFixedFullScreen bgDarkestGray " + outerClass}>
        <div
          className={"displayFlexColumnHc border2999 borderRadius15 bgBlack " + innerClass}
          style={{width:innerWidth, margin:innerMargin, padding:"3.3em 2.5em"}}>
          <div className="hugeBottomMargin" style={{height:"150px", width:"147px"}}>
            <LibraryReact.Image
              p_src={c_productWebsiteGraphicsSubfolderAddress + "/loginCE150.png"}
              p_alt={c_productStylingObj.productName}
            />
          </div>
          <LoginProcess p_inputTextClass="ceLoginInput font12" />
        </div>
      </div>
    );
  }
}));




export const LoginProcess = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class LoginProcess extends Component { //props: p_verticalTrueHorizontalFalse, p_inputTextClass
  componentWillUnmount() {
    //clear 1 hour code version verification after successful login
    this.props.CaptureExecMobx.a_clear_verify_user_browser_live_or_dev_code_version_timed_interval();
  }

  onclick_log_in_with_my_current_version = () => {
    this.props.CaptureExecMobx.a_set_override_code_version_mismatch_tf(true);
    this.props.CaptureExecMobx.a_logout(); //return to login screen with no message box
  }

  onclick_return_to_login_screen = () => {
    this.props.CaptureExecMobx.a_logout(); //return to login screen with no message box
  }

  render() {
    const p_verticalTrueHorizontalFalse = JSFUNC.prop_value(this.props.p_verticalTrueHorizontalFalse, true);
    const p_inputTextClass = this.props.p_inputTextClass;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const o_loginState = this.props.CaptureExecMobx.o_loginState;
    const o_loggingInUserEmail = this.props.CaptureExecMobx.o_loggingInUserEmail;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyDaysBetweenUserPasswordChange = this.props.DatabaseMobx.c_companyDaysBetweenUserPasswordChange;

    var loginComponent = null;
    if(o_loginState === "loadingCaptureExec") {
      loginComponent = (
        <div className="displayFlexColumnHcVc hugeFullPad">
          <div className="smallBottomMargin textCenter">
            <font className="font16 fontItalic fontTextLighter">
              {"Loading " + c_productStylingObj.productName}
            </font>
          </div>
          <CEGeneralReact.LoadingAnimation />
        </div>
      );
    }
    else if(o_loginState === "codeUpdate") {
      loginComponent = (
        <div className="bgDarkerBlueGradient border1444 hugeFullPad textCenter">
          <font className="font14 fontAlmostWhite">
            {c_productStylingObj.productName + " has released a new version!\nPlease refresh this page to be redirected to the login."}
          </font>
        </div>
      );
    }
    else if(o_loginState === "codeVersionMismatch") {
      loginComponent = (
        <div className="bgDarkerBlueGradient border1444 hugeFullPad">
          <div className="displayFlexColumnHcVc">
            <div className="textCenter" style={{width:((c_isMobileOrTabletTF) ? ("100%") : ("310px"))}}>
              <font className="font17 fontAlmostWhite">
                {"Please refresh this screen using the refresh button in your browser"}
              </font>
            </div>
          </div>
          <div className="displayFlexColumnHcVc medTopMargin hugeBottomMargin lrMedMargin borderT1bbb borderB1bbb tbMedPad">
            <LibraryReact.Image
              p_src={c_productWebsiteGraphicsSubfolderAddress + "/loginrefreshchrome.png"}
              p_alt="Refresh Browser"
            />
          </div>
          {(false) &&
            <>
              <div className="hugeTopMargin textCenter">
                <font className="fontAlmostWhite">
                  {"Your browser is retaining the previous version of " + c_productStylingObj.productName + ", which could prevent data from loading properly. If this message persists after several refresh attempts, use this button below to proceed with login using your current version."}
                </font>
              </div>
              <div className="displayFlexColumnHcVc medTopMargin">
                <CEGeneralReact.CEButton
                  p_type="add"
                  p_text="Log in with my current version"
                  p_fontClass="font09 fontItalic"
                  p_tabIndex={99}
                  f_onClick={this.onclick_log_in_with_my_current_version}
                />
              </div>
            </>
          }
        </div>
      );
    }
    else if(o_loginState === "licenseExpired") {
      const licenseExpirationDate = JSFUNC.compute_end_date_from_start_date_and_num_months(this.props.DatabaseMobx.c_bitLicenseStartDate, this.props.DatabaseMobx.c_bitLicenseNumMonths);
      var licenseExpirationDateMask = "is expired";
      if(JSFUNC.date_is_filled_out_tf(licenseExpirationDate)) {
        const licenseExpirationJSDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(licenseExpirationDate);
        licenseExpirationDateMask = "expired on " + JSFUNC.get_DMjY_date_from_jsdateobj_and_utctf(licenseExpirationJSDateObj, false);
      }

      loginComponent = (
        <div className="bgDarkRedGradient border1444 hugeFullPad">
          <div className="displayFlexColumnHcVc">
            <div className="textCenter" style={{width:((c_isMobileOrTabletTF) ? ("100%") : ("310px"))}}>
              <div>
                <font className="font17 fontAlmostWhite">
                  {"Your " + c_productStylingObj.productName + " license " + licenseExpirationDateMask}
                </font>
              </div>
              <div className="hugeTopMargin">
                <font className="font14 fontAlmostWhite">
                  {"Please contact BIT Solutions at support@bitsolutionsllc.com to extend your license"}
                </font>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else if(o_loginState === "onPremiseNotAuthenticated") {
      loginComponent = (
        <div className="bgDarkRedGradient border1444 hugeFullPad">
          <div className="displayFlexColumnHcVc">
            <div className="textCenter" style={{width:((c_isMobileOrTabletTF) ? ("100%") : ("310px"))}}>
              <div>
                <font className="font17 fontAlmostWhite">
                  {"This on-premise installation of " + c_productStylingObj.productName + " is past its authentication period"}
                </font>
              </div>
              <div className="hugeTopMargin">
                <font className="font14 fontAlmostWhite">
                  {"Please contact BIT Solutions at support@bitsolutionsllc.com to receive the latest key file to continue use of this software"}
                </font>
              </div>
            </div>
          </div>
          <div className="displayFlexColumnHcVc hugeTopMargin border1bbb bgLightestGray medFullPad">
            <div className="smallBottomMargin textCenter">
              <font className="fontTextLight">
                {"Upload a newer Authentication File here"}
              </font>
            </div>
            <CEGeneralReact.OnPremiseUploadNewAuthenticationFile
              f_onUploadFile={() => { alert("Upload successful, refresh this screen to log in"); }}
            />
          </div>
        </div>
      );
    }
    else if(JSFUNC.in_array(o_loginState, ["adminPasswordChange", "forcedPasswordChange", "multiLogin"])) {
      var title = "";
      var subTitle = "";
      var adminOrForcedOrUserFlag = "";
      if(o_loginState === "adminPasswordChange") {
        title = "Set a New Password";
        subTitle = "Welcome to " + c_productStylingObj.productName + "! Choose your new password to use when logging in to get started.";
        adminOrForcedOrUserFlag = "admin";
      }
      else if(o_loginState === "forcedPasswordChange") {
        title = "Reset Your Password";
        subTitle = "Your " + c_productStylingObj.productName + " Admin requires all passwords to be changed every " + c_companyDaysBetweenUserPasswordChange + " days. Please take a moment to choose your new password.";
        adminOrForcedOrUserFlag = "forced";
      }

      loginComponent = (
        <>
          <div
            className="displayFlexColumnHc hugeFullPad border bevelBorderColors borderRadius05 bgGrayGradient"
            style={{width:((c_isMobileOrTabletTF) ? ("100%") : ("33em"))}}>
            {((JSFUNC.in_array(o_loginState, ["adminPasswordChange", "forcedPasswordChange"])) ? (
              <>
                <div className="medBottomMargin textCenter">
                  <font className="font14 fontBold">
                    {title}
                  </font>
                </div>
                <div className="medBottomMargin textCenter">
                  <font className="font11">
                    {subTitle}
                  </font>
                </div>
                <div className="border1bbb borderRadius05 bgLighterGray tbBigPad lrHugePad">
                  <ChangePassword p_adminOrForcedOrUserFlag={adminOrForcedOrUserFlag} />
                </div>
              </>
            ) : (
              <>
                <div className="textCenter">
                  <font className="font14 fontBold">
                    {"Multi User Login Selection for"}
                  </font>
                </div>
                <div className="medBottomMargin textCenter breakWord">
                  <font className="font13">
                    {o_loggingInUserEmail}
                  </font>
                </div>
                <MultiLoginSelection />
              </>
            ))}
          </div>
          <div className="hugeTopMargin">
            <CEGeneralReact.CEButton
              p_type="hyperlink"
              p_text="Return to Login Screen"
              p_fontClass="font10"
              p_tabIndex={99}
              f_onClick={this.onclick_return_to_login_screen}
            />
          </div>
        </>
      );
    }
    else { //"out", plus any other invalid o_loginState
      loginComponent = (
        <MainLogin p_verticalTrueHorizontalFalse={p_verticalTrueHorizontalFalse} p_inputTextClass={p_inputTextClass} />
      );
    }

    return(loginComponent);
  }
}));



const MainLogin = inject("CaptureExecMobx")(observer(
class MainLogin extends Component { //props: p_verticalTrueHorizontalFalse, p_inputTextClass
  onchange_company_code_input = (i_changedCompanyCodeText) => {
    this.props.CaptureExecMobx.a_set_company_code_input(i_changedCompanyCodeText);
  }

  onchange_email_input = (i_changedEmailText) => {
    this.props.CaptureExecMobx.a_set_email_input(i_changedEmailText);
  }

  onchange_password_input = (i_changedPasswordText) => {
    this.props.CaptureExecMobx.a_set_password_input(i_changedPasswordText);
  }

  onclick_or_onkeydownenter_login = () => {
    const o_companyCodeInput = this.props.CaptureExecMobx.o_companyCodeInput;
    const o_emailInput = this.props.CaptureExecMobx.o_emailInput;
    const o_passwordInput = this.props.CaptureExecMobx.o_passwordInput;

    var fakeLoginFormComponent = document.getElementById("dummyLoginForm");

    var fakeCompanyCodeComponent = document.getElementById("code");
    var fakeEmailComponent = document.getElementById("email");
    var fakePasswordComponent = document.getElementById("password");

    fakeCompanyCodeComponent.value = o_companyCodeInput;
    fakeEmailComponent.value = o_emailInput;
    fakePasswordComponent.value = o_passwordInput;

    //fakeLoginFormComponent.submit();

    this.props.CaptureExecMobx.a_login_or_forgot_password("login");
  }

  onclick_forgot_password = () => {
    this.props.CaptureExecMobx.a_login_or_forgot_password("forgotPassword");
  }

  render() {
    const p_verticalTrueHorizontalFalse = JSFUNC.prop_value(this.props.p_verticalTrueHorizontalFalse, true);
    const p_inputTextClass = this.props.p_inputTextClass;

    const o_companyCodeInput = this.props.CaptureExecMobx.o_companyCodeInput;
    const o_emailInput = this.props.CaptureExecMobx.o_emailInput;
    const o_passwordInput = this.props.CaptureExecMobx.o_passwordInput;
    const o_loginMessageObj = this.props.CaptureExecMobx.o_loginMessageObj;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;

    const companyCodeInputTextComponent = (
      <LibraryReact.Text
        p_value={o_companyCodeInput}
        p_class={p_inputTextClass}
        p_tabIndex={1}
        p_placeholder="Company Code"
        p_id="code"
        p_autocompleteName="code"
        f_onChange={this.onchange_company_code_input}
        f_onKeyDownEnter={this.onclick_or_onkeydownenter_login}
      />
    );

    const emailInputTextComponent = (
      <LibraryReact.Text
        p_value={o_emailInput}
        p_class={p_inputTextClass}
        p_tabIndex={2}
        p_placeholder="Email"
        p_id="email"
        p_autocompleteName="email"
        f_onChange={this.onchange_email_input}
        f_onKeyDownEnter={this.onclick_or_onkeydownenter_login}
      />
    );

    const passwordCodeInputTextComponent = (
      <LibraryReact.Text
        p_value={o_passwordInput}
        p_isPasswordTF={true}
        p_class={p_inputTextClass}
        p_tabIndex={3}
        p_placeholder="Password"
        p_id="password"
        p_autocompleteName="password"
        f_onChange={this.onchange_password_input}
        f_onKeyDownEnter={this.onclick_or_onkeydownenter_login}
      />
    );

    const forgotPasswordHyperlinkComponent = (
      <LibraryReact.InteractiveDiv
        p_class="cursorPointer"
        p_tabIndex={5}
        f_onClick={this.onclick_forgot_password}
        f_onKeyDownEnter={this.onclick_forgot_password}>
        <font className="fontTextLight">
          {"Forgot Password?"}
        </font>
      </LibraryReact.InteractiveDiv>
    );

    const loginButtonComponent = (
      <LibraryReact.ButtonSubmit
        p_value="Login"
        p_class="ceButton ceButtonBlue font12 cursorPointer"
        p_tabIndex={4}
        f_onClick={this.onclick_or_onkeydownenter_login}
      />
    );

    if(p_verticalTrueHorizontalFalse) {
      return(
        <div style={{width:((c_isMobileOrTabletTF) ? ("85%") : ("28.75em"))}}>
          <form name="dummyLoginForm" id="dummyLoginForm" method="POST">
            <div className="bigTopMargin">
              {companyCodeInputTextComponent}
            </div>
            <div className="bigTopMargin">
              {emailInputTextComponent}
            </div>
            <div className="bigTopMargin">
              {passwordCodeInputTextComponent}
            </div>
          </form>
          <div className="bigTopMargin displayFlexRow">
            <div className="flex00a">
              {forgotPasswordHyperlinkComponent}
            </div>
            <div className="flex11a" />
            <div className="flex00a">
              {loginButtonComponent}
            </div>
          </div>
          <CEGeneralReact.Message p_messageObj={o_loginMessageObj} />
        </div>
      );
    }

    return(
      <div className="displayFlexColumnHcVc">
        <div className="flex00a" style={{width:"60em"}}>
          <form name="dummyLoginForm" id="dummyLoginForm" method="POST">
            <div className="displayFlexRow">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                {companyCodeInputTextComponent}
              </div>
              <div className="flex00a" style={{flexBasis:"0.5em"}} />
              <div className="flex11a" style={{flexBasis:"100em"}}>
                {emailInputTextComponent}
              </div>
              <div className="flex00a" style={{flexBasis:"0.5em"}} />
              <div className="flex11a" style={{flexBasis:"100em"}}>
                {passwordCodeInputTextComponent}
              </div>
            </div>
          </form>
          <div className="smallTopMargin displayFlexRow">
            <div className="flex00a">
              {forgotPasswordHyperlinkComponent}
            </div>
            <div className="flex11a" />
            <div className="flex00a">
              {loginButtonComponent}
            </div>
          </div>
          <CEGeneralReact.Message p_messageObj={o_loginMessageObj} />
        </div>
      </div>
    );
  }
}));



export const ChangePassword = inject("CaptureExecMobx")(observer(
class ChangePassword extends Component { //props: p_adminOrForcedOrUserFlag
  onclick_or_onkeydownenter_change_password = () => { this.props.CaptureExecMobx.a_change_password(this.props.p_adminOrForcedOrUserFlag); }
  onchange_old_password_input = (i_changedOldPasswordText) => { this.props.CaptureExecMobx.a_set_old_password_input(i_changedOldPasswordText); }
  onchange_new_password1_input = (i_changedNewPassword1Text) => { this.props.CaptureExecMobx.a_set_new_password1_input(i_changedNewPassword1Text); }
  onchange_new_password2_input = (i_changedNewPassword2Text) => { this.props.CaptureExecMobx.a_set_new_password2_input(i_changedNewPassword2Text); }

  render() {
    const p_adminOrForcedOrUserFlag = this.props.p_adminOrForcedOrUserFlag;

    const o_changePasswordMessageObj = this.props.CaptureExecMobx.o_changePasswordMessageObj;

    const passwordInputWidths = "15em";

    return(
      <div className="displayFlexColumnHc">
        <div className="textLeft inlineBlock">
          {(p_adminOrForcedOrUserFlag !== "admin") && //change password from admin default does not require you to reenter the admin password that you just typed on the main login screen
            <div className="hugeBottomMargin">
              <div className="microBottomMargin">{"Old Password:"}</div>
              <LibraryReact.Text
                p_value={this.props.CaptureExecMobx.o_oldPasswordInput}
                p_isPasswordTF={true}
                p_styleObj={{width:passwordInputWidths}}
                p_tabIndex={1}
                p_errorTF={this.props.CaptureExecMobx.oldPasswordErrorTF}
                f_onChange={this.onchange_old_password_input}
                f_onKeyDownEnter={this.onclick_or_onkeydownenter_change_password}
              />
            </div>
          }
          <div className="medTopMargin">
            <div className="microBottomMargin">{"New Password:"}</div>
            <LibraryReact.Text
              p_value={this.props.CaptureExecMobx.o_newPassword1Input}
              p_isPasswordTF={true}
              p_styleObj={{width:passwordInputWidths}}
              p_tabIndex={(p_adminOrForcedOrUserFlag !== "admin") ? (2) : (1)}
              p_errorTF={this.props.CaptureExecMobx.newPassword1ErrorTF}
              f_onChange={this.onchange_new_password1_input}
              f_onKeyDownEnter={this.onclick_or_onkeydownenter_change_password}
            />
          </div>
          <div className="medTopMargin">
            <div className="microBottomMargin">{"Retype New Password:"}</div>
            <LibraryReact.Text
              p_value={this.props.CaptureExecMobx.o_newPassword2Input}
              p_isPasswordTF={true}
              p_styleObj={{width:passwordInputWidths}}
              p_tabIndex={3}
              p_errorTF={this.props.CaptureExecMobx.newPassword2ErrorTF}
              f_onChange={this.onchange_new_password2_input}
              f_onKeyDownEnter={this.onclick_or_onkeydownenter_change_password}
            />
          </div>
          <div className="bigTopMargin textRight">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text={((p_adminOrForcedOrUserFlag === "admin") ? ("Set") : ("Change")) + " Password"}
              p_tabIndex={4}
              f_onClick={this.onclick_or_onkeydownenter_change_password}
            />
          </div>
        </div>
        <CEGeneralReact.Message p_messageObj={o_changePasswordMessageObj} />
      </div>
    );
  }
}));



export const MultiLoginSelection = inject("UserMobx")(observer(
class MultiLoginSelection extends Component {
  render() {
    const c_sortedMultiLoginCombinedUsersArrayOfObjs = this.props.UserMobx.c_sortedMultiLoginCombinedUsersArrayOfObjs;

    if(c_sortedMultiLoginCombinedUsersArrayOfObjs.length === 0) {
      return("No Multiple Login Users to choose from");
    }

    return(
      <div className="width100" style={{maxWidth:"30em"}}>
        {c_sortedMultiLoginCombinedUsersArrayOfObjs.map((m_combinedUserObj) =>
          <SingleMultiLogin
            key={m_combinedUserObj.user_id}
            p_combinedUserObj={m_combinedUserObj}
          />
        )}
      </div>
    );
  }
}));



const SingleMultiLogin = inject("CaptureExecMobx", "UserMobx")(observer(
class SingleMultiLogin extends Component { //props: p_combinedUserObj
  onclick_select_single_multi_login = () => {
    const p_combinedUserObj = this.props.p_combinedUserObj;
    const o_userID = this.props.UserMobx.o_userID;
    if(o_userID !== p_combinedUserObj.user_id) { //do not update the user if you clicked the same one
      this.props.CaptureExecMobx.a_multi_login_selection_click(p_combinedUserObj.user_id);
    }
  }

  render() {
    const p_combinedUserObj = this.props.p_combinedUserObj;

    const o_userID = this.props.UserMobx.o_userID;

    const currentUserTF = (o_userID === p_combinedUserObj.user_id); //make it look like you can click on users, but not the current one
    const singleMultiLoginItemHeight = "3em";
    const multiLoginDisplaysDivisionNameTF = (p_combinedUserObj.powerIsDivExecTF || p_combinedUserObj.powerIsContractsExecTF || p_combinedUserObj.powerIsBudgetExecTF); //execs where you can have multiple copies of them over a given division (can only have 1 admin)

    return(
      <div
        className={"displayFlexRowVc border bevelBorderColors " + ((currentUserTF) ? ("bgMultiLoginSelected") : ("bgPanelLightGray hoverLightestGrayGradient cursorPointer"))}
        style={{height:singleMultiLoginItemHeight}}
        onClick={this.onclick_select_single_multi_login}>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1em"}}>
          <div className="flex11a border bevelBorderColors" style={{width:"0.6em", height:"2.4em", background:p_combinedUserObj.powerHashColor}} />
        </div>
        <div className="flex11a lrPad">
          <LibraryReact.Nowrap p_fontClass="font11">
            {p_combinedUserObj.powerDisplayName}
          </LibraryReact.Nowrap>
          {(multiLoginDisplaysDivisionNameTF) &&
            <div style={{paddingLeft:"0.6em"}}>
              <LibraryReact.Nowrap p_fontClass="font09">
                {p_combinedUserObj.divisionName}
              </LibraryReact.Nowrap>
            </div>
          }
        </div>
      </div>
    );
  }
}));




//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&




const CaptureExecLoggedIn = inject("CaptureExecMobx")(observer(
class CaptureExecLoggedIn extends Component {
  componentDidMount() {
    const o_isLocalhost3000SystemTF = this.props.CaptureExecMobx.o_isLocalhost3000SystemTF;
    const o_captureExecInSingleCaptureDirectAccessModeTF = this.props.CaptureExecMobx.o_captureExecInSingleCaptureDirectAccessModeTF;

    //real system from manual login, this component mounts when loginState is finally set to "in" either from single user login, choosing a multilogin, or after setting or changing their password
    if(!o_isLocalhost3000SystemTF && !o_captureExecInSingleCaptureDirectAccessModeTF) { //localhost system already has all data injected, direct access link mode loads its slightly different phase1 and phase2 directly following its own login php process success, doesn't need to load here
      this.props.CaptureExecMobx.a_load_phase1_phase2_data_into_local_memory(); //loads the rest of the tables from memory in 2 successive waves
    }

    //set the selected tab to the default tab
    if(!o_isLocalhost3000SystemTF) {
      this.props.CaptureExecMobx.a_set_left_nav_to_default_tab();
    }

    //initialize left nav expanded setting from user preference in database
    this.props.CaptureExecMobx.a_left_nav_initialize_left_nav_is_expanded_tf();

    //regular interval to check and refresh data that has changed (fields in usersPerEmail table checked for currently logged in user, returns tables that need updating in DatabaseMobx specified in CaptureExecMobx), this function also checks if the php SESSION is still valid, otherwise it forces a logout
    if(!o_isLocalhost3000SystemTF) { //localhost doesn't need 5 sec refreshes
      const waitTimeMs = 5000; //interval length in ms
      this.refreshDataTimedIntervalID = setInterval(() => JSPHP.refresh_data_tables_from_user_per_email_flags(), waitTimeMs);
    }
  }

  componentWillUnmount() {
    const o_isLocalhost3000SystemTF = this.props.CaptureExecMobx.o_isLocalhost3000SystemTF;

    if(!o_isLocalhost3000SystemTF) {
      clearInterval(this.refreshDataTimedIntervalID);
    }
  }

  render() {
    return(
      <>
        <TopBar />
        <LeftNav />
        <MainContent />
        <RightPanelReact.DesktopRightPanel />
        <RightPanelReact.DesktopHelpPanel />
        <ArchivedCapturesWarningBottomBar />
        <ViewSpecifiedSearchResultCapturesInFloatingBox />
        <OpenCaptureReact.OpenCapture />
        <CapturesReact.PresetEditorFloatingBox />
        <RightPanelReact.BroadcastReaderFloatingBox />
        <RightPanelReact.TasksAddNewOrEditTaskFloatingBox />
        <TeammateContractsReact.TeammateContractFloatingBox />
        <OpenCaptureReact.TeammatesFloatingBoxSurvey />
        <BudgetReact.BudgetTodoOpenFundingRequestFloatingBox />
        <CapturesReact.ProcessMultipleCapturesProgressFloatingBox />
        <TopBarSearchCapturesResultsFloatingBox />
        <RightPanelReact.MobileOrTabletFloatingRightPanelOrHelpPanel />
      </>
    );
  }
}));


const TopBar = inject("CaptureExecMobx", "RightPanelMobx")(observer(
class TopBar extends Component {
  render() {
    const o_captureExecInSingleCaptureDirectAccessModeTF = this.props.CaptureExecMobx.o_captureExecInSingleCaptureDirectAccessModeTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_topBarHeight = this.props.CaptureExecMobx.c_topBarHeight; //c_topBarHeight is thinner on mobile
    const c_currentSystemRightEdgeDueToHelpPanel = this.props.RightPanelMobx.c_currentSystemRightEdgeDueToHelpPanel;

    return(
      <div
        className={"positionAbsolute t0 l0 displayFlexRow " + c_productStylingObj.topBarBgClass}
        style={{right:c_currentSystemRightEdgeDueToHelpPanel, height:c_topBarHeight, borderBottom:"solid 2px #bbb"}}>
        {(!o_captureExecInSingleCaptureDirectAccessModeTF) &&
          <TopBarMobileLeftNavLauncher />
        }
        <TopBarLogo />
        {(!o_captureExecInSingleCaptureDirectAccessModeTF) &&
          <TopBarSearchCaptures />
        }
        <TopBarCompanyName />
        {(!o_captureExecInSingleCaptureDirectAccessModeTF) &&
          <>
            <RightPanelReact.RightPanelLauncher p_rightPanelCode1234={3} />
            <RightPanelReact.RightPanelLauncher p_rightPanelCode1234={2} />
          </>
        }
        <RightPanelReact.RightPanelLauncher p_rightPanelCode1234={1} />
        <RightPanelReact.RightPanelLauncher p_rightPanelCode1234={4} />
      </div>
    );
  }
}));


const TopBarLogo = inject("CaptureExecMobx", "UserMobx")(observer(
class TopBarLogo extends Component {
  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const logoContainerWidth = ((c_isMobileOrTabletTF) ? (c_productStylingObj.topBarLogoIconWidth) : (c_productStylingObj.topBarFullLogoWidth));
    const topBarCaptureExecOrCELogoFileNameAndExt = ((c_isMobileOrTabletTF) ? (c_productStylingObj.topBarLogoIconFileNameBase) : (c_productStylingObj.topBarFullLogoFileNameBase)) + c_userFontSizePx + ".png";
    const logoFilePath = c_productWebsiteGraphicsSubfolderAddress + "/" + topBarCaptureExecOrCELogoFileNameAndExt;

    return(
      <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:logoContainerWidth}}>
        <LibraryReact.Image p_src={logoFilePath} p_alt={c_productStylingObj.productName} />
      </div>
    );
  }
}));


const TopBarSearchCaptures = inject("CaptureExecMobx", "UserMobx")(observer(
class TopBarSearchCaptures extends Component {
  onclick_search_captures_input = () => {
    this.props.CaptureExecMobx.a_set_search_captures_box_is_open_tf(true);
  }

  onclick_close_search_box = () => {
    this.props.CaptureExecMobx.a_set_search_captures_box_is_open_tf(false);
  }

  onchange_search_captures_input_text = (i_changedSearchText) => {
    this.props.CaptureExecMobx.a_set_search_captures_input_text(i_changedSearchText);
  }

  onclick_clear_capture_search_text = () => {
    this.props.CaptureExecMobx.a_set_search_captures_input_text("");
  }

  render() {
    const o_searchCapturesInputText = this.props.CaptureExecMobx.o_searchCapturesInputText;
    const o_searchSelectedSearchTypeTab = this.props.CaptureExecMobx.o_searchSelectedSearchTypeTab;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_userCannotSearchCapturesTF = this.props.UserMobx.c_userCannotSearchCapturesTF;

    //capture consultants cannot search for or filter captures that they are not directly assigned to, thus the search bar is completely taken away
    if(c_userCannotSearchCapturesTF) {
      return(null);
    }

    const containerFlex = ((c_isMobileOrTabletTF) ? ("flex11a") : ("flex01a"));

    var searchInputTypeDisplay = "";
    if(o_searchSelectedSearchTypeTab === "captures") { searchInputTypeDisplay = "Captures"; }
    else if(o_searchSelectedSearchTypeTab === "documents") { searchInputTypeDisplay = "Documents"; }

    return(
      <div className={containerFlex + " displayFlexColumnVc lrPad"} style={{flexBasis:"21em"}}>
        <CEGeneralReact.SearchInputTextWithClearX
          p_inputText={o_searchCapturesInputText}
          p_placeholderText={"Search " + searchInputTypeDisplay + "..."}
          p_includeSearchIconTF={true}
          p_heightEm={2.6}
          p_bgHashColor={c_productStylingObj.topBarSearchInputTextBgHashColor}
          f_onChange={this.onchange_search_captures_input_text}
          f_onClick={this.onclick_search_captures_input}
          f_onKeyDownEsc={this.onclick_close_search_box}
        />
      </div>
    );
  }
}));


const TopBarSearchCapturesResultsFloatingBox = inject("CaptureExecMobx", "OpenCaptureMobx", "RightPanelMobx")(observer(
class TopBarSearchCapturesResultsFloatingBox extends Component {
  onclick_capture_result = (i_captureID) => {
    this.onclick_close_search_box();
    this.props.OpenCaptureMobx.a_open_single_capture(i_captureID);
  }

  onclick_search_type_tab = (i_selectedSearchTypeTab) => {
    if(i_selectedSearchTypeTab === "documents") { //when clicking to the documents tab, load all of the documents across the system into memory
      this.props.CaptureExecMobx.a_search_load_thin_search_all_documents_data();
    }
    else { //clicking back on the captures tab resets any loading flag for documents
      this.props.CaptureExecMobx.a_set_search_documents_is_loading_data_tfu(false);
    }
    this.props.CaptureExecMobx.a_set_search_selected_search_type_tab(i_selectedSearchTypeTab);
  }

  onclick_retry_load_documents = () => {
    this.props.CaptureExecMobx.a_search_load_thin_search_all_documents_data();
  }

  onclick_show_all_matching_documents = () => {
    this.props.CaptureExecMobx.a_set_search_documents_draw_all_docs_tf(true);
  }

  onclick_exact_checkbox = () => {
    this.props.CaptureExecMobx.a_set_search_captures_exact_tf(!this.props.CaptureExecMobx.o_searchCapturesExactTF);
  }

  onclick_close_search_box = () => {
    this.props.CaptureExecMobx.a_set_search_captures_box_is_open_tf(false);
  }

  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const o_searchCapturesBoxIsOpenTF = this.props.CaptureExecMobx.o_searchCapturesBoxIsOpenTF;
    const o_searchSelectedSearchTypeTab = this.props.CaptureExecMobx.o_searchSelectedSearchTypeTab;
    const o_searchDocumentsIsLoadingDataTFU = this.props.CaptureExecMobx.o_searchDocumentsIsLoadingDataTFU;
    const o_searchCapturesExactTF = this.props.CaptureExecMobx.o_searchCapturesExactTF;
    const o_searchCapturesInputText = this.props.CaptureExecMobx.o_searchCapturesInputText;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_topBarHeight = this.props.CaptureExecMobx.c_topBarHeight;
    const c_searchFilteredCapturesFromSearchTermArrayOfObjs = this.props.CaptureExecMobx.c_searchFilteredCapturesFromSearchTermArrayOfObjs;
    const c_searchFilteredDocumentCategoriesArrayOfObjs = this.props.CaptureExecMobx.c_searchFilteredDocumentCategoriesArrayOfObjs;
    const c_searchDocumentsTotalNumFilteredDocs = this.props.CaptureExecMobx.c_searchDocumentsTotalNumFilteredDocs;
    const c_searchDocumentsDisplayShowAllDocsButtonTF = this.props.CaptureExecMobx.c_searchDocumentsDisplayShowAllDocsButtonTF;

    const c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels = this.props.RightPanelMobx.c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels;

    //search box has not been opened
    if(!o_searchCapturesBoxIsOpenTF) {
      return(null);
    }

    //draw floating search results box
    var searchCapturesRight = "15%"; //5 or 6
    if(o_mediaQueryFlag === 1) { searchCapturesRight = "1%"; }
    else {
      if(c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels !== 0) {
        searchCapturesRight = c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels;
      }
      else {
        if(o_mediaQueryFlag === 2) { searchCapturesRight = "3%"; }
        else if(o_mediaQueryFlag === 3) { searchCapturesRight = "5%"; }
        else if(o_mediaQueryFlag === 4) { searchCapturesRight = "10%"; }
      }
    }

    const searchCapturesBottom = ((o_mediaQueryFlag === 1) ? ("40%") : ("55%"));

    var searchCapturesLeft = "16em"; //3-6
    if(o_mediaQueryFlag === 1) { searchCapturesLeft = "1%"; }
    else if(o_mediaQueryFlag === 2) { searchCapturesLeft = "3%"; }



    var isSearchCapturesTabTF = false;
    var isSearchDocumentsTabTF = false;
    if(o_searchSelectedSearchTypeTab === "captures") { isSearchCapturesTabTF = true; }
    else if(o_searchSelectedSearchTypeTab === "documents") { isSearchDocumentsTabTF = true; }

    var searchCapturesInputTextIsBlankTF = false;
    var resultsBoxTitle = "";
    if(isSearchCapturesTabTF) {
      if(o_searchCapturesInputText === "") {
        searchCapturesInputTextIsBlankTF = true;
        resultsBoxTitle = "Recently Opened Captures";
      }
      else {
        const totalNumFilteredCaptures = c_searchFilteredCapturesFromSearchTermArrayOfObjs.length;
        resultsBoxTitle = "Search Captures for '" + o_searchCapturesInputText + "' (" + totalNumFilteredCaptures + " " + JSFUNC.plural(totalNumFilteredCaptures, "result", "results") + ")";
      }
    }
    else if(isSearchDocumentsTabTF) {
      resultsBoxTitle = "Search Documents for '" + o_searchCapturesInputText + "' (" + c_searchDocumentsTotalNumFilteredDocs + " " + JSFUNC.plural(c_searchDocumentsTotalNumFilteredDocs, "result", "results") + ")";
    }

    var resultsBoxComponent = null;
    if(o_searchDocumentsIsLoadingDataTFU === true) { //documents are loading
      resultsBoxComponent = (
        <CEGeneralReact.EmptyScreenGray>
          <div className="smallBottomMargin">
            {"Gathering All Documents across " + c_productStylingObj.productName}
          </div>
          <CEGeneralReact.LoadingAnimation />
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else if(o_searchDocumentsIsLoadingDataTFU === undefined) { //documents failed to load
      resultsBoxComponent = (
        <CEGeneralReact.EmptyScreenGray>
          <div className="smallBottomMargin">
            <font className="fontRed">
              {"Could not load " + c_productStylingObj.productName + " documents"}
            </font>
          </div>
          <div>
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Retry"
              f_onClick={this.onclick_retry_load_documents}
            />
          </div>
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else { //documents have not started loading or loaded and finished successfully
      if(isSearchCapturesTabTF) {
        resultsBoxComponent = (
          <div className="flex11a xyScroll">
            <CapturesReact.SearchResultsCapturesTable
              p_searchResultCapturesArrayOfObjs={c_searchFilteredCapturesFromSearchTermArrayOfObjs}
              p_initialSortIsCustomTF={searchCapturesInputTextIsBlankTF}
              p_doNotInitializeSortByAdminColumnsTF={searchCapturesInputTextIsBlankTF}
              f_onClickCapture={this.onclick_capture_result}
            />
          </div>
        );
      }
      else if(isSearchDocumentsTabTF) {
        const numDocumentCategories = c_searchFilteredDocumentCategoriesArrayOfObjs.length;
        if(false && o_searchCapturesInputText === "") {
          resultsBoxComponent = (
            <CEGeneralReact.EmptyScreenGray>
              {"Enter a Search Term above to find Documents with matching names or descriptions/keywords"}
            </CEGeneralReact.EmptyScreenGray>
          );
        }
        else if(numDocumentCategories === 0) {
          resultsBoxComponent = (
            <CEGeneralReact.EmptyScreenGray>
              {"No Document names or description/keywords match '" + o_searchCapturesInputText + "'"}
            </CEGeneralReact.EmptyScreenGray>
          );
        }
        else {
          resultsBoxComponent = (
            <div className="flex11a xyScroll">
              {c_searchFilteredDocumentCategoriesArrayOfObjs.map((m_documentCategoryObj) =>
                (m_documentCategoryObj.drawCategoryTF) &&
                <>
                  <div className="smallFullPad bgPurpleGradient" style={{minWidth:"60em"}}>
                    <font className="font11 fontItalic fontWhite">
                      {m_documentCategoryObj.tblDisplayName}
                    </font>
                  </div>
                  {m_documentCategoryObj.tblDocsArrayOfObjs.map((m_tblDocObj, m_index) =>
                    (m_documentCategoryObj.tblDocsDrawDocTFArray[m_index]) &&
                    <SearchDocumentsSingleDocResultRow
                      p_documentCategoryObj={m_documentCategoryObj}
                      p_tblDocObj={m_tblDocObj}
                      f_onClick={this.onclick_close_search_box}
                    />
                  )}
                  <div className="hugeBottomMargin" />
                </>
              )}
              {(c_searchDocumentsDisplayShowAllDocsButtonTF) &&
                <div className="smallFullMargin">
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text={"Show all " + c_searchDocumentsTotalNumFilteredDocs + " matching Documents"}
                    f_onClick={this.onclick_show_all_matching_documents}
                  />
                </div>
              }
            </div>
          );
        }
      }
    }


    return (
      <LibraryReact.DivWithOffClick
        p_offClickIncludesParentTF={true}
        p_preventClickDefaultAndPropagationTF={true}
        p_class="positionAbsolute displayFlexColumn searchCapturesShadow border1bbb bgWhite"
        p_styleObj={{zIndex:4, top:c_topBarHeight, right:searchCapturesRight, bottom:searchCapturesBottom, left:searchCapturesLeft}}
        f_offClick={this.onclick_close_search_box}>
        <div className="flex00a displayFlexRow borderB1bbb bgLightGrayGradient" style={{flexBasis:"2.3em"}}>
          <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"150em"}}>
            <LibraryReact.Nowrap p_fontClass="font11 fontBold">
              {resultsBoxTitle}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={["captures", "documents"]}
              p_tabDisplayNamesArray={["Captures", "Documents"]}
              p_selectedTabDbName={o_searchSelectedSearchTypeTab}
              p_tabHeight="1.6em"
              p_textMaxHeight="nowrap"
              p_tabWidth={undefined}
              p_unselectedFontClass="fontTextLight"
              p_selectedFontClass="fontBold fontWhite"
              p_unselectedBgClass="bgLightGray"
              p_selectedBgClass="bgDarkBlueGradient"
              p_canEditTF={(o_searchDocumentsIsLoadingDataTFU !== true)}
              p_rowFlexWrapTF={false}
              p_unselectValue={undefined}
              f_onSelect={this.onclick_search_type_tab}
            />
          </div>
          <div
            className={"flex00a displayFlexRowVc lrMedPad borderL1ddd " + ((isSearchCapturesTabTF) ? ("cursorPointer") : (""))}
            style={{flexBasis:"7.5em"}}
            title={((isSearchCapturesTabTF) ? ("Only find captures with values that exactly match the search term (useful for Capture ID)") : (undefined))}
            onClick={((isSearchCapturesTabTF) ? (this.onclick_exact_checkbox) : (undefined))}>
            {(isSearchCapturesTabTF) &&
              <>
                <div className="flex11a lrPad">
                  <font className="fontItalic fontTextLight">
                    {"Exact?"}
                  </font>
                </div>
                <div className="flex00a lrPad">
                  <LibraryReact.CheckBox
                    p_u0_s1_p2_du3_ds4={((o_searchCapturesExactTF) ? (1) : (0))}
                    p_sizeEm={1}
                  />
                </div>
              </>
            }
          </div>
          <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"3em"}}>
            <CEGeneralReact.RemoveItemButton
              p_fontClass="font13"
              f_onClick={this.onclick_close_search_box}
            />
          </div>
        </div>
        {resultsBoxComponent}
      </LibraryReact.DivWithOffClick>
    );
  }
}));

const SearchDocumentsSingleDocResultRow = inject("CaptureExecMobx")(observer(
class SearchDocumentsSingleDocResultRow extends Component { //props: p_documentCategoryObj, p_tblDocObj, f_onClick
  onclick_document_result = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick();
    }

    this.props.CaptureExecMobx.a_search_documents_navigate_to_document_ffs_location_in_system(this.props.p_documentCategoryObj.tblName, this.props.p_tblDocObj);
  }

  render() {
    const documentCategoryObj = this.props.p_documentCategoryObj;
    const tblDocObj = this.props.p_tblDocObj;

    return (
      <div
        className="displayFlexRow borderB1ddd hoverLighterBlueGradient cursorPointer"
        style={{height:"1.5em", minWidth:"60em"}}
        title={tblDocObj.rowTitle}
        onClick={this.onclick_document_result}>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"200em"}}>
          <LibraryReact.Nowrap p_fontClass="">
            {tblDocObj.display_name}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
            {tblDocObj.captureOrContactName}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
            {tblDocObj.keywords}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
            {tblDocObj.notes}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:"8em"}}>
          {tblDocObj.upload_date}
        </div>
      </div>
    );
  }
}));





const TopBarMobileLeftNavLauncher = inject("CaptureExecMobx", "OpenCaptureMobx")(observer(
class TopBarMobileLeftNavLauncher extends Component {
  onclick_mobile_left_nav_launcher = () => {
    this.props.CaptureExecMobx.a_left_nav_expand_collapse_toggle();
  }

  render() {
    const o_leftNavIsExpandedTF = this.props.CaptureExecMobx.o_leftNavIsExpandedTF;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const singleCaptureIsOpenTF = this.props.OpenCaptureMobx.c_singleCaptureIsOpenTF;

    if(!c_isMobileOrTabletTF || singleCaptureIsOpenTF) {
      return(null); //launcher not displayed in top bar in desktop mode (leftNav is expanded or collapsed, but not closed like in mobile)
    }

    const launcherBgClass = ((o_leftNavIsExpandedTF) ? ("bgMobileLeftNavLauncherOpen") : (""));
    const launcherButtonWidth = "3em";
    const barWidth = "2.1em";
    const barHeight = "0.25em";
    return (
      <div
        className={"flex00a displayFlexColumnHcVc hoverLightestGrayGradient cursorPointer " + launcherBgClass}
        style={{flexBasis:launcherButtonWidth}}
        onClick={this.onclick_mobile_left_nav_launcher}>
        <div className="inlineBlock" style={{width:barWidth}}>
          <div className="bgLeftNavAndTabs" style={{height:barHeight}} />
          <div style={{height:barHeight}} />
          <div className="bgLeftNavAndTabs" style={{height:barHeight}} />
          <div style={{height:barHeight}} />
          <div className="bgLeftNavAndTabs" style={{height:barHeight}} />
        </div>
      </div>
    );
  }
}));


const TopBarCompanyName = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class TopBarCompanyName extends Component {
  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_topDivision00Name = this.props.DatabaseMobx.c_topDivision00Name;

    if(c_isMobileOrTabletTF) {
      return(null); //company name not displayed in top bar in mobile
    }

    return (
      <div className="flex11a displayFlexColumnHcVc lrPad textCenter">
        <LibraryReact.Nowrap p_fontClass={c_productStylingObj.topBarCompanyNameFontClass}>
          {c_topDivision00Name}
        </LibraryReact.Nowrap>
      </div>
    );
  }
}));




const LeftNav = inject("CaptureExecMobx")(observer(
class LeftNav extends Component {
  onclick_expand_collapse = () => {
    this.props.CaptureExecMobx.a_left_nav_expand_collapse_toggle();
  }

  render() {
    const o_captureExecInSingleCaptureDirectAccessModeTF = this.props.CaptureExecMobx.o_captureExecInSingleCaptureDirectAccessModeTF;
    const o_leftNavIsExpandedTF = this.props.CaptureExecMobx.o_leftNavIsExpandedTF;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_topBarHeight = this.props.CaptureExecMobx.c_topBarHeight;
    const c_leftNavWidth = this.props.CaptureExecMobx.c_leftNavWidth;

    //single capture direct access cannot close the 1 open capture, so no left nav needs to be beneath it
    if(o_captureExecInSingleCaptureDirectAccessModeTF) {
      return(null);
    }

    //for mobile, expanded is fullscreen, collapsed is hidden, tab selection closes it, new menu button in topBar to open/close
    if(c_isMobileOrTabletTF && !o_leftNavIsExpandedTF) { //leftNav in 'collapsed' mode is completely hidden in mobile
      return(null);
    }

    return(
      <div
        className={"positionAbsolute b0 l0 displayFlexColumn " + c_productStylingObj.leftNavBgClass + " " + ((c_isMobileOrTabletTF) ? ("leftNavMobile") : (""))}
        style={{top:c_topBarHeight, width:c_leftNavWidth, borderRight:"solid 1px #555"}}>
        <div
          className={c_productStylingObj.leftNavTabUnselectedBgClass + " lrPad textRight cursorPointer"}
          onClick={this.onclick_expand_collapse}>
          <div className="font13" style={{color:"#" + c_productStylingObj.leftNavTabUnselectedFontColor}}>
            {(o_leftNavIsExpandedTF) ? ("<<") : (">>")}
          </div>
        </div>
        <div className={"flex11a " + ((o_leftNavIsExpandedTF) ? ("yScroll") : ("yScrollHidden"))}>
          <LeftNavTabs />
        </div>
      </div>
    );
  }
}));


const LeftNavTabs = inject("CaptureExecMobx")(observer(
class LeftNavTabs extends Component {
  render() {
    const c_leftNavUserTabsArrayOfObjs = this.props.CaptureExecMobx.c_leftNavUserTabsArrayOfObjs;

    if(!JSFUNC.is_array(c_leftNavUserTabsArrayOfObjs)) {
      return(null);
    }

    return(
      c_leftNavUserTabsArrayOfObjs.map((m_leftNavUserTabObj) =>
        <>
          <LeftNavSingleTab
            key={m_leftNavUserTabObj.tabName}
            p_isSubTabTF={false}
            p_isSelectedTF={m_leftNavUserTabObj.tabOrAnySubTabIsSelectedTF}
            p_tabNumber={m_leftNavUserTabObj.tabNumber}
            p_tabName={m_leftNavUserTabObj.tabName}
            p_tabDisplayName={m_leftNavUserTabObj.tabDisplayName}
            p_tabNameOpenedWhenSelected={m_leftNavUserTabObj.tabNameOpenedWhenSelected}
          />
          {(m_leftNavUserTabObj.anySubTabIsSelectedTF) &&
            m_leftNavUserTabObj.subTabsArrayOfObjs.map((m_subTabObj) =>
              <LeftNavSingleTab
                key={m_subTabObj.subTabName}
                p_isSubTabTF={true}
                p_isSelectedTF={m_subTabObj.subTabIsSelectedTF}
                p_tabNumber={m_subTabObj.subTabNumber}
                p_tabName={m_subTabObj.subTabName}
                p_tabDisplayName={m_subTabObj.subTabDisplayName}
                p_tabNameOpenedWhenSelected={m_subTabObj.subTabName}
              />
            )
          }
        </>
      )
    );
  }
}));


const LeftNavSingleTab = inject("CaptureExecMobx", "DatabaseMobx", "UserMobx")(observer(
class LeftNavSingleTab extends Component { //props: p_isSubTabTF, p_isSelectedTF, p_tabNumber, p_tabName, p_tabDisplayName, p_tabNameOpenedWhenSelected
  onclick_tab = () => {
    const p_isSelectedTF = this.props.p_isSelectedTF;
    const p_tabNameOpenedWhenSelected = this.props.p_tabNameOpenedWhenSelected;

    //clicking on the tab you are already on does not do anything
    if(!p_isSelectedTF) {
      this.props.CaptureExecMobx.a_left_nav_select_tab(p_tabNameOpenedWhenSelected);
    }
  }

  render() {
    const p_isSelectedTF = this.props.p_isSelectedTF;
    const p_isSubTabTF = this.props.p_isSubTabTF;
    const p_tabNumber = this.props.p_tabNumber;
    const p_tabName = this.props.p_tabName;
    const p_tabDisplayName = this.props.p_tabDisplayName;
    const p_tabNameOpenedWhenSelected = this.props.p_tabNameOpenedWhenSelected;

    const o_leftNavIsExpandedTF = this.props.CaptureExecMobx.o_leftNavIsExpandedTF;
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const iconSizeEm = ((o_leftNavIsExpandedTF) ? (3) : (2));

    var tabBgClass = c_productStylingObj.leftNavTabUnselectedBgClass;
    var tabIconColor = c_productStylingObj.leftNavTabUnselectedIconColor;
    var tabFontColor = c_productStylingObj.leftNavTabUnselectedFontColor;
    var subTabBgClass = c_productStylingObj.leftNavSubTabUnselectedBgClass;
    var subTabNumberColor = c_productStylingObj.leftNavSubTabUnselectedNumberColor;
    var subTabFontColor = c_productStylingObj.leftNavSubTabUnselectedFontColor;
    if(p_isSelectedTF) {
      tabBgClass = c_productStylingObj.leftNavTabSelectedBgClass;
      tabIconColor = c_productStylingObj.leftNavTabSelectedIconColor;
      tabFontColor = c_productStylingObj.leftNavTabSelectedFontColor;
      subTabBgClass = c_productStylingObj.leftNavSubTabSelectedBgClass;
      subTabNumberColor = c_productStylingObj.leftNavSubTabSelectedNumberColor;
      subTabFontColor = c_productStylingObj.leftNavSubTabSelectedFontColor;
    }

    //subtab
    if(p_isSubTabTF) {
      return(
        <div
          className="displayFlexRow"
          style={{height:((o_leftNavIsExpandedTF) ? ("1.7em") : ("1.5em"))}}
          title={((o_leftNavIsExpandedTF) ? (undefined) : (p_tabDisplayName))}>
          <div className={"flex00a " + c_productStylingObj.leftNavSubTabSelectedBgClass} style={{flexBasis:"0.5em"}} />
          <div
            className={"flex11a displayFlexRow " + subTabBgClass + " " + ((p_isSelectedTF) ? ("") : ("cursorPointer"))}
            onClick={this.onclick_tab}>
            <div className="flex00a displayFlexColumnVc textRight" style={{flexBasis:"1.7em", marginLeft:"0.2em"}}>
              <font className={((o_leftNavIsExpandedTF) ? ("") : ("font09"))} style={{color:"#" + subTabNumberColor}}>{p_tabNumber + ". "}</font>
            </div>
            <div className="flex11a displayFlexRowVc">
              {(o_leftNavIsExpandedTF) ? (
                <LibraryReact.MaxHeightWrap p_maxHeight="1.5em">
                  <font className="font11" style={{color:"#" + subTabFontColor}}>{p_tabDisplayName}</font>
                </LibraryReact.MaxHeightWrap>
              ) : (
                <LibraryReact.Nowrap p_ellipsisTF={false}>
                  <font className="" style={{color:"#" + subTabFontColor}}>{p_tabDisplayName}</font>
                </LibraryReact.Nowrap>
              )}
            </div>
          </div>
        </div>
      );
    }

    //main tab
    var iconSvgComponent = null;
    if(p_tabName === "Captures") {
      iconSvgComponent = (<CEGeneralReact.SvgCapture p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "GovCon Smart Search") {
      iconSvgComponent = (<CEGeneralReact.SvgSmartFunnel p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "My Summaries") {
      iconSvgComponent = (<CEGeneralReact.SvgSpiralNotebookWithCheck p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Contacts") {
      iconSvgComponent = (<CEGeneralReact.SvgCompanySlashPerson p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Marketing Docs") {
      iconSvgComponent = (<CEGeneralReact.SvgFile p_sizeEm={iconSizeEm} p_color={tabIconColor} p_thickLinesTF={true} />);
    }
    else if(p_tabName === "User Activity") {
      iconSvgComponent = (<CEGeneralReact.SvgUserActivity p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Capture Import") {
      iconSvgComponent = (<CEGeneralReact.SvgCaptureImport p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "System Setup") {
      iconSvgComponent = (<CEGeneralReact.SvgWrench p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Executive Management") {
      iconSvgComponent = (<CEGeneralReact.SvgExecutiveManagement p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Contracts Workload") {
      iconSvgComponent = (<CEGeneralReact.SvgWorkload p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Contracts Todo") {
      iconSvgComponent = (<CEGeneralReact.SvgTodo p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Budgets Workload") {
      iconSvgComponent = (<CEGeneralReact.SvgWorkload p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Budgets Todo") {
      iconSvgComponent = (<CEGeneralReact.SvgTodo p_sizeEm={iconSizeEm} p_color={tabIconColor} />);
    }
    else if(p_tabName === "Upgrade To CaptureExec") {
      var upgradeToCETabCELogoFontSizePx = Math.round(iconSizeEm * c_userFontSizePx / 3.27); //available logo font sizes are 8,9,...,19,20,22,24,26,28
      if(upgradeToCETabCELogoFontSizePx >= 28) { upgradeToCETabCELogoFontSizePx = 28; }
      else if(upgradeToCETabCELogoFontSizePx >= 26) { upgradeToCETabCELogoFontSizePx = 26; }
      else if(upgradeToCETabCELogoFontSizePx >= 24) { upgradeToCETabCELogoFontSizePx = 24; }
      else if(upgradeToCETabCELogoFontSizePx >= 22) { upgradeToCETabCELogoFontSizePx = 22; }
      else if(upgradeToCETabCELogoFontSizePx >= 20) { upgradeToCETabCELogoFontSizePx = 20; }
      else if(upgradeToCETabCELogoFontSizePx <= 8) { upgradeToCETabCELogoFontSizePx = 8; }
      iconSvgComponent = (<LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/celogo" + upgradeToCETabCELogoFontSizePx + ".png"} p_alt="CaptureExec" />);
    }
    else {
      iconSvgComponent = (<font className="font13" style={{color:"#" + tabIconColor}}>{"\u2318"}</font>);
    }

    return(
      <div
        className={"flex11a " + ((o_leftNavIsExpandedTF) ? ("displayFlexRow") : ("displayFlexColumnHcVc")) + " " + tabBgClass + " " + ((p_isSelectedTF) ? ("") : ("cursorPointer"))}
        style={{height:c_productStylingObj.leftNavTabHeightEm + "em"}}
        title={((o_leftNavIsExpandedTF) ? (undefined) : (p_tabDisplayName))}
        onClick={this.onclick_tab}>
        {(o_leftNavIsExpandedTF) ? (
          <>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"4em"}}>
              {iconSvgComponent}
            </div>
            <div className="flex11a displayFlexRowVc lrMargin">
              <LibraryReact.MaxHeightWrap p_maxHeight="3.3em" p_fontClass="font13">
                <font style={{color:"#" + tabFontColor}}>
                  {p_tabDisplayName}
                </font>
              </LibraryReact.MaxHeightWrap>
            </div>
          </>
        ) : (
          iconSvgComponent
        )}
      </div>
    );
  }
}));


const MainContent = inject("CaptureExecMobx", "RightPanelMobx")(observer(
class MainContent extends Component {
  render() {
    const o_captureExecInSingleCaptureDirectAccessModeTF = this.props.CaptureExecMobx.o_captureExecInSingleCaptureDirectAccessModeTF;
    const topBarHeight = this.props.CaptureExecMobx.c_topBarHeight;
    const leftNavRightEdge = this.props.CaptureExecMobx.c_leftNavRightEdge;
    const leftNavTabNameSelected = this.props.CaptureExecMobx.o_leftNavTabNameSelected;
    const currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels = this.props.RightPanelMobx.c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels;

    //single capture direct access cannot close the 1 open capture, so no main content needs to be beneath it
    if(o_captureExecInSingleCaptureDirectAccessModeTF) {
      return(null);
    }

    var mainContentComponent = null;
    if(leftNavTabNameSelected === "Capture Table View") { mainContentComponent = (<CapturesReact.CapturesTab p_capturesViewFlag="captureTable" />); }
    else if(leftNavTabNameSelected === "Stage View") { mainContentComponent = (<CapturesReact.CapturesTab p_capturesViewFlag="stage" />); }
    else if(leftNavTabNameSelected === "Gantt Chart View") { mainContentComponent = (<CapturesReact.CapturesTab p_capturesViewFlag="ganttChart" />); }
    else if(leftNavTabNameSelected === "Progress Chart View") { mainContentComponent = (<CapturesReact.CapturesTab p_capturesViewFlag="progressChart" />); }
    else if(leftNavTabNameSelected === "GovCon Smart Search") { mainContentComponent = (<GCSSReact.GovConSmartSearch />); }
    else if(leftNavTabNameSelected === "My Performance") { mainContentComponent = (<MyPerformance />); }
    else if(leftNavTabNameSelected === "My Surveys") { mainContentComponent = (<MySurveys />); }
    else if(leftNavTabNameSelected === "Contacts") { mainContentComponent = (<ContactsReact.ContactsTabWithNoSelection />); }
    else if(leftNavTabNameSelected === "Marketing Docs") { mainContentComponent = (<GeneralDocuments />); }

    else if(leftNavTabNameSelected === "Performance") { mainContentComponent = (<DivexecReact.Performance />); }
    else if(leftNavTabNameSelected === "Daily Snapshot") { mainContentComponent = (<DivexecReact.DailySnapshot />); }
    else if(leftNavTabNameSelected === "Trend Analyzer") { mainContentComponent = (<DivexecReact.TrendAnalyzer />); }
    else if(leftNavTabNameSelected === "Financial Projections") { mainContentComponent = (<DivexecReact.FinancialProjections />); }
    else if(leftNavTabNameSelected === "Hot Bits") { mainContentComponent = (<DivexecReact.HotBits />); }
    else if(leftNavTabNameSelected === "Critical Thresholds") { mainContentComponent = (<DivexecReact.CriticalThresholds />); }
    else if(leftNavTabNameSelected === "Stage Flow") { mainContentComponent = (<DivexecReact.StageFlow />); }
    else if(leftNavTabNameSelected === "Excel Report Writer") { mainContentComponent = (<DivexecReact.ExcelReportWriter />); }

    else if(leftNavTabNameSelected === "Contracts Workload") { mainContentComponent = (<TeammateContractsReact.ContractsExecWorkload />); }
    else if(leftNavTabNameSelected === "Contracts Todo") { mainContentComponent = (<TeammateContractsReact.ContractsTodo />); }

    else if(leftNavTabNameSelected === "Budgets Workload") { mainContentComponent = (<BudgetReact.BudgetExecWorkload />); }
    else if(leftNavTabNameSelected === "Budgets Todo") { mainContentComponent = (<BudgetReact.BudgetsTodo />); }

    else if(leftNavTabNameSelected === "User Activity") { mainContentComponent = (<AdminReact.AdminTabUserActivity />); }
    else if(leftNavTabNameSelected === "Capture Import") { mainContentComponent = (<AdminImportReact.AdminTabCaptureImport />); }
    else if(leftNavTabNameSelected === "Company/Divisions") { mainContentComponent = (<AdminReact.AdminSubtabDivisions />); }
    else if(leftNavTabNameSelected === "Users") { mainContentComponent = (<AdminReact.AdminSubtabUsers />); }
    else if(leftNavTabNameSelected === "Capture Types") { mainContentComponent = (<AdminReact.AdminSubtabCaptureTypes />); }
    else if(leftNavTabNameSelected === "Capture Cards") { mainContentComponent = (<AdminReact.AdminSubtabCaptureCards />); }
    else if(leftNavTabNameSelected === "Stages") { mainContentComponent = (<AdminReact.AdminSubtabStages />); }
    else if(leftNavTabNameSelected === "Details Card Groups") { mainContentComponent = (<AdminReact.AdminSubtabDetailsCardGroups />); }
    else if(leftNavTabNameSelected === "Details/Dates Fields") { mainContentComponent = (<AdminReact.AdminSubtabDetailsDatesFields />); }
    else if(leftNavTabNameSelected === "Details Fields Options") { mainContentComponent = (<AdminReact.AdminSubtabDetailsFieldsOptions />); }
    else if(leftNavTabNameSelected === "Deal Shaping Questions") { mainContentComponent = (<AdminReact.AdminSubtabDealShapingQuestions />); }
    else if(leftNavTabNameSelected === "Teammates") { mainContentComponent = (<AdminReact.AdminSubtabTeammates />); }
    else if(leftNavTabNameSelected === "Teammate Contracts") { mainContentComponent = (<AdminReact.AdminSubtabTeammateContracts />); }
    else if(leftNavTabNameSelected === "Teammate Ratings") { mainContentComponent = (<AdminReact.AdminSubtabTeammateRatings />); }
    else if(leftNavTabNameSelected === "Teammate Surveys") { mainContentComponent = (<AdminReact.AdminSubtabTeammateSurveys />); }
    else if(leftNavTabNameSelected === "Competitors") { mainContentComponent = (<AdminReact.AdminSubtabCompetitors />); }
    else if(leftNavTabNameSelected === "Proposal Themes") { mainContentComponent = (<AdminReact.AdminSubtabProposalThemes />); }
    else if(leftNavTabNameSelected === "Risk Assessment") { mainContentComponent = (<AdminReact.AdminSubtabRiskAssessment />); }
    else if(leftNavTabNameSelected === "Budgets") { mainContentComponent = (<AdminReact.AdminSubtabBudgets />); }
    else if(leftNavTabNameSelected === "Capture Templates") { mainContentComponent = (<AdminReact.AdminSubtabSingleCaptureTemplates />); }
    else if(leftNavTabNameSelected === "GCSS Options") { mainContentComponent = (<AdminReact.AdminSubtabGCSSOptions />); }
    else if(leftNavTabNameSelected === "Contacts Fields") { mainContentComponent = (<AdminReact.AdminSubtabContactsFields />); }
    else if(leftNavTabNameSelected === "Tasks Options") { mainContentComponent = (<AdminReact.AdminSubtabTasksOptions />); }
    else if(leftNavTabNameSelected === "Automated Reminders") { mainContentComponent = (<AdminReact.AdminSubtabAutomatedReminders />); }
    else if(leftNavTabNameSelected === "Automated Exports") { mainContentComponent = (<AdminReact.AdminSubtabAutomatedExports />); }
    else if(leftNavTabNameSelected === "Excel Report Templates") { mainContentComponent = (<AdminReact.AdminSubtabExcelReportTemplates />); }
    else if(leftNavTabNameSelected === "System") { mainContentComponent = (<AdminReact.AdminSubtabSystem />); }
    else if(leftNavTabNameSelected === "Shortcut Presets") { mainContentComponent = (<AdminReact.AdminSubtabShortcutPresets />); }
    else if(leftNavTabNameSelected === "Capture Archiving") { mainContentComponent = (<AdminReact.AdminSubtabCaptureArchiving />); }
    else if(leftNavTabNameSelected === "New Capture Additional Fields") { mainContentComponent = (<AdminReact.AdminSubtabNewCaptureAdditionalFields />); }
    else if(leftNavTabNameSelected === "Search Fields") { mainContentComponent = (<AdminReact.AdminSubtabSearchFields />); }
    else if(leftNavTabNameSelected === "PWin") { mainContentComponent = (<AdminReact.AdminSubtabPwin />); }
    else if(leftNavTabNameSelected === "Progress Colors") { mainContentComponent = (<AdminReact.AdminSubtabProgressColors />); }
    else if(leftNavTabNameSelected === "Upcoming Date Colors") { mainContentComponent = (<AdminReact.AdminSubtabUpcomingDateColors />); }
    else if(leftNavTabNameSelected === "Delete Capture Reasons") { mainContentComponent = (<AdminReact.AdminSubtabDeleteCaptureReasons />); }
    else if(leftNavTabNameSelected === "Question Tags") { mainContentComponent = (<AdminReact.AdminSubtabQuestionTags />); }
    else if(leftNavTabNameSelected === "3rd Party Integrations") { mainContentComponent = (<AdminIntegrationsReact.IntegrationsTab />); }
    else if(leftNavTabNameSelected === "Admin Changelog") { mainContentComponent = (<AdminReact.AdminSubtabAdminChangelog />); }
    else if(leftNavTabNameSelected === "On-Premise") { mainContentComponent = (<AdminReact.AdminSubtabOnPremise />); }
    else if(leftNavTabNameSelected === "My Account") { mainContentComponent = (<AdminReact.AdminSubtabMyAccount />); }

    else if(leftNavTabNameSelected === "Upgrade To CaptureExec") { mainContentComponent = (<UpgradeToCaptureExec />); }

    else if(leftNavTabNameSelected === "Test") { mainContentComponent = (<TestMainContent />); }
    else if(leftNavTabNameSelected === "Test2") { mainContentComponent = (<Test2MainContent />); }
    else if(leftNavTabNameSelected === "Blank") { mainContentComponent = null; }
    else { mainContentComponent = (<div className="smallFullMargin fontItalic">{"Select a tab in the left-side navigation menu. (Invalid tab '" + leftNavTabNameSelected + "' currently selected)"}</div>); }

    return (
      <div
        className="positionAbsolute b0 displayFlexColumn bgWhite"
        style={{top:topBarHeight, right:currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels, left:leftNavRightEdge}}>
        {mainContentComponent}
      </div>
    );
  }
}));




const MyPerformance = inject("DivexecMobx", "UserMobx")(observer(
class MyPerformance extends Component {
  componentDidMount() {
    const userTrueDivisionFalse = true;
    const userOrDivisionID = this.props.UserMobx.o_userID;
    this.props.DivexecMobx.a_performance_set_selected_user_or_division_id(userTrueDivisionFalse, userOrDivisionID);
  }

  render() {
    return(
      <DivexecReact.PerformanceAllGraphs p_divexecPerformanceTrueUserMyPerformanceFalse={false} />
    );
  }
}));




const MySurveys = inject("CaptureExecMobx")(observer(
class MySurveys extends Component {
  componentDidMount() {
    this.props.CaptureExecMobx.a_my_surveys_load_survey_data();
  }

  onclick_my_surveys_subtab = (i_selectedTabDbname) => {
    this.props.CaptureExecMobx.a_set_my_surveys_selected_tab_db_name(i_selectedTabDbname);
  }

  onclick_surveys_page_left = () => {
    const o_mySurveysCurrentViewSurveyStartIndex = this.props.CaptureExecMobx.o_mySurveysCurrentViewSurveyStartIndex;

    const numSurveysPerPage = 10;
    var updatedCurrentViewSurveyStartIndex = (o_mySurveysCurrentViewSurveyStartIndex - numSurveysPerPage);
    if(updatedCurrentViewSurveyStartIndex >= 0) {
      this.props.CaptureExecMobx.a_set_my_surveys_current_view_survey_start_index(updatedCurrentViewSurveyStartIndex);
    }
  }

  onclick_surveys_page_right = () => {
    const o_mySurveysCurrentViewSurveyStartIndex = this.props.CaptureExecMobx.o_mySurveysCurrentViewSurveyStartIndex;
    const c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs = this.props.CaptureExecMobx.c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs;

    const numSurveysPerPage = 10;
    const numSurveys = c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs.length;
    var updatedCurrentViewSurveyStartIndex = (o_mySurveysCurrentViewSurveyStartIndex + numSurveysPerPage);
    if(updatedCurrentViewSurveyStartIndex < numSurveys) {
      this.props.CaptureExecMobx.a_set_my_surveys_current_view_survey_start_index(updatedCurrentViewSurveyStartIndex);
    }
  }

  onselect_my_surveys_filter_idiq_capture = (i_selectedIDIQCaptureID) => {
    this.props.CaptureExecMobx.a_set_my_surveys_selected_filter_idiq_capture_id(i_selectedIDIQCaptureID);
  }

  onselect_my_surveys_filter_teammate_contact_company_id = (i_selectedTeammateContactCompanyID) => {
    this.props.CaptureExecMobx.a_set_my_surveys_selected_filter_teammate_contact_company_id(i_selectedTeammateContactCompanyID);
  }

  render() {
    const mySurveysLoadingDataTF = this.props.CaptureExecMobx.o_mySurveysLoadingDataTF;
    const mySurveysSelectedTabDbName = this.props.CaptureExecMobx.o_mySurveysSelectedTabDbName;
    const o_mySurveysCurrentViewSurveyStartIndex = this.props.CaptureExecMobx.o_mySurveysCurrentViewSurveyStartIndex;
    const o_mySurveysSelectedFilterTeammateContactCompanyID = this.props.CaptureExecMobx.o_mySurveysSelectedFilterTeammateContactCompanyID;
    const o_mySurveysSelectedFilterIDIQCaptureID = this.props.CaptureExecMobx.o_mySurveysSelectedFilterIDIQCaptureID;
    const c_mySurveysSelectTeammateFieldTypeObj = this.props.CaptureExecMobx.c_mySurveysSelectTeammateFieldTypeObj;
    const c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs = this.props.CaptureExecMobx.c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs;
    const c_mySurveysSelectedSingleIDIQSurveysFilteredBySelectedTeammateObj = this.props.CaptureExecMobx.c_mySurveysSelectedSingleIDIQSurveysFilteredBySelectedTeammateObj;
    const c_mySurveysSelectIDIQCaptureFieldTypeObj = this.props.CaptureExecMobx.c_mySurveysSelectIDIQCaptureFieldTypeObj;

    const numSurveys = c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs.length;

    const numSurveysPerPage = 10;

    var limitSurveysInPagesTF = false;
    var selectIDIQCapturesTF = false;
    var mySurveysComponent = null;
    if(mySurveysLoadingDataTF) {
      mySurveysComponent = (
        <div className="displayFlexColumnHcVc hugeTopMargin">
          <div className="smallBottomMargin">
            <font className="fontItalic fontTextLighter">
              {"Loading Survey Data"}
            </font>
          </div>
          <CEGeneralReact.LoadingAnimation />
        </div>
      );
    }
    else if(mySurveysSelectedTabDbName === "allMySentSurveys") {
      limitSurveysInPagesTF = true;
      mySurveysComponent = (
        <>
          {c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs.map((m_mySentSurveyObj, m_index) =>
            ((m_index >= o_mySurveysCurrentViewSurveyStartIndex) && (m_index < (o_mySurveysCurrentViewSurveyStartIndex + numSurveysPerPage))) &&
            <MySurveysSingleSurveyItem p_mySentSurveyObj={m_mySentSurveyObj} />
          )}
        </>
      );
    }
    else if(mySurveysSelectedTabDbName === "mySentSurveysGroupedbyIDIQ") {
      selectIDIQCapturesTF = true;
      mySurveysComponent = (
        <MySurveysSingleIDIQItem p_idiqSurveyObj={c_mySurveysSelectedSingleIDIQSurveysFilteredBySelectedTeammateObj} />
      );
    }

    return(
      <>
        <div className="flex00a tbMicroPad borderB1bbb">
          <div className="displayFlexColumnHcVc tbMicroPad">
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={["allMySentSurveys", "mySentSurveysGroupedbyIDIQ"]}
              p_tabDisplayNamesArray={["All My Sent Surveys", "My Sent Surveys Grouped by IDIQ"]}
              p_selectedTabDbName={mySurveysSelectedTabDbName}
              p_tabHeight="2.3em"
              p_textMaxHeight="2.1em"
              p_tabWidth="18em"
              p_selectedBgClass="bgOrangeGradient"
              p_selectedFontClass="fontBold"
              p_unselectedBgClass="bgLightGrayGradient"
              p_unselectedFontClass="fontBold fontTextLighter"
              p_canEditTF={true}
              p_rowFlexWrapTF={true}
              f_onSelect={this.onclick_my_surveys_subtab}
            />
          </div>
          <div className="displayFlexRow flexWrap">
            {(limitSurveysInPagesTF && (numSurveys > numSurveysPerPage)) &&
              <div className="flex00a displayFlexRowVc lrMedPad" style={{flexBasis:"14em"}}>
                <CEGeneralReact.ButtonLeftRightCircledCarat
                  p_leftFalseRightTrue={false}
                  p_sizeEm={2}
                  f_onClick={this.onclick_surveys_page_left}
                />
                <div className="flex11a displayFlexColumnHcVc">
                  <div className="">
                    <font className="fontItalic fontTextLighter">
                      {"Viewing Surveys"}
                    </font>
                  </div>
                  <div className="">
                    <font className="fontItalic fontTextLighter">
                      {(o_mySurveysCurrentViewSurveyStartIndex + 1) + " - " + (((o_mySurveysCurrentViewSurveyStartIndex + numSurveysPerPage) <= numSurveys) ? (o_mySurveysCurrentViewSurveyStartIndex + numSurveysPerPage) : (numSurveys)) + " of " + numSurveys}
                    </font>
                  </div>
                </div>
                <CEGeneralReact.ButtonLeftRightCircledCarat
                  p_leftFalseRightTrue={true}
                  p_sizeEm={2}
                  f_onClick={this.onclick_surveys_page_right}
                />
              </div>
            }
            {(selectIDIQCapturesTF) &&
              <div className="flex00a smallBottomMargin lrMedPad" style={{flexBasis:"25em"}}>
                <div className="microBottomMargin textCenter">
                  <font className="fontItalic fontTextLighter">
                    {"Select IDIQ to view"}
                  </font>
                </div>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={c_mySurveysSelectIDIQCaptureFieldTypeObj}
                  p_valueRaw={o_mySurveysSelectedFilterIDIQCaptureID}
                  f_onChangeOrOnSelect={this.onselect_my_surveys_filter_idiq_capture}
                />
              </div>
            }
            <div className="flex11a" />
            <div className="flex00a smallBottomMargin lrMedPad" style={{flexBasis:"25em"}}>
              <div className="microBottomMargin textCenter">
                <font className="fontItalic fontTextLighter">
                  {"Filter Surveys for selected Teammate"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_mySurveysSelectTeammateFieldTypeObj}
                p_valueRaw={o_mySurveysSelectedFilterTeammateContactCompanyID}
                f_onChangeOrOnSelect={this.onselect_my_surveys_filter_teammate_contact_company_id}
              />
            </div>
          </div>
        </div>
        <div className="flex11a yScroll medFullPad">
          {mySurveysComponent}
        </div>
      </>
    );
  }
}));


const MySurveysSingleSurveyItem = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class MySurveysSingleSurveyItem extends Component { //props: p_mySentSurveyObj
  onclick_capture_survey_link = () => {
    const p_mySentSurveyObj = this.props.p_mySentSurveyObj;

    const functionOnSuccess = (i_parseResponse) => {
      this.props.OpenCaptureMobx.a_launch_card_full(this.props.DatabaseMobx.k_cardIDTeammates);
      this.props.OpenCaptureMobx.a_teammates_set_left_tab_selected("surveysCapabilitiesGapAnalysis");
      this.props.OpenCaptureMobx.a_teammates_surveys_select_survey(p_mySentSurveyObj.surveyInfoObj.id);
    }
    this.props.OpenCaptureMobx.a_open_single_capture(p_mySentSurveyObj.surveyInfoObj.capture_id, functionOnSuccess);
  }

  render() {
    const p_mySentSurveyObj = this.props.p_mySentSurveyObj;

    const surveyInfoObj = p_mySentSurveyObj.surveyInfoObj;
    const surveyResponseTimesArrayOfObjs = p_mySentSurveyObj.surveyResponseTimesArrayOfObjs;

    return(
      <div className="displayFlexRow medBottomMargin border1bbb bgLighterGray medFullPad">
        <div className="flex11a" style={{flexBasis:"100em"}}>
          <div
            className="border1ddd borderRadius10 bgLightestestGray tbPad lrMedPad hoverLighterBlueGradient cursorPointer"
            onClick={this.onclick_capture_survey_link}>
            <div className="lrPad">
              <font className="font11 fontItalic fontTextLight">{"Survey: "}</font>
              <font className="font11 fontBold">{surveyInfoObj.title}</font>
            </div>
            <div className="smallTopMargin lrPad">
              <font className="font11 fontItalic fontTextLight">{"Capture: "}</font>
              <font className="font11 fontBold">{surveyInfoObj.captureNamePlainText}</font>
            </div>
            <div className="smallTopMargin lrPad">
              <font className="font11 fontItalic fontTextLight">{"Most Recent Activity: "}</font>
              <font className="font11">{surveyInfoObj.surveyMostRecentActivityDateTimeLocal}</font>
            </div>
          </div>
        </div>
        <div className="flex00a" style={{flexBasis:"1em"}} />
        <div className="flex11a" style={{flexBasis:"150em"}}>
          {surveyResponseTimesArrayOfObjs.map((m_mySentSurveyResponseTimeObj) =>
            <MySurveysSingleSurveyResponseTimeItem p_mySentSurveyResponseTimeObj={m_mySentSurveyResponseTimeObj} />
          )}
        </div>
      </div>
    );
  }
}));


const MySurveysSingleSurveyResponseTimeItem = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class MySurveysSingleSurveyResponseTimeItem extends Component { //props: p_mySentSurveyResponseTimeObj, p_thinTF
  onclick_capture_survey_teammate_response_link = () => {
    const p_mySentSurveyResponseTimeObj = this.props.p_mySentSurveyResponseTimeObj;

    const functionOnSuccess = (i_parseResponse) => {
      this.props.OpenCaptureMobx.a_launch_card_full(this.props.DatabaseMobx.k_cardIDTeammates);
      this.props.OpenCaptureMobx.a_teammates_set_left_tab_selected("surveysCapabilitiesGapAnalysis");
      this.props.OpenCaptureMobx.a_teammates_set_floating_box_survey_teammate_id_and_survey_id(p_mySentSurveyResponseTimeObj.teammate_id, p_mySentSurveyResponseTimeObj.survey_id);
    }
    this.props.OpenCaptureMobx.a_open_single_capture(p_mySentSurveyResponseTimeObj.capture_id, functionOnSuccess);
  }

  render() {
    const p_mySentSurveyResponseTimeObj = this.props.p_mySentSurveyResponseTimeObj;
    const p_thinTF = JSFUNC.prop_value(this.props.p_thinTF, false);

    return(
      <div
        className={"displayFlexRow microBottomMargin border1ddd bgLightestestGray " + ((p_thinTF) ? ("tbMicroPad") : ("tbPad")) + " lrPad hoverLighterBlueGradient cursorPointer"}
        onClick={this.onclick_capture_survey_teammate_response_link}>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <div
            className="border1ddd borderRadius10"
            style={{width:"0.9em", height:((p_thinTF) ? ("1em") : ("2.2em")), background:"#" + p_mySentSurveyResponseTimeObj.surveyLightColor}}
            title={p_mySentSurveyResponseTimeObj.surveyLightText}
          />
        </div>
        <div className="flex11a">
          {(p_thinTF) ? (
            <LibraryReact.Nowrap p_fontClass="">{p_mySentSurveyResponseTimeObj.teammateNamePlainText}</LibraryReact.Nowrap>
          ) : (
            <div className="">
              <font className="fontItalic fontTextLight">{"Teammate: "}</font>
              <font className="">{p_mySentSurveyResponseTimeObj.teammateNamePlainText}</font>
            </div>
          )}
          {(!p_thinTF) &&
            <div className="displayFlexRow flexWrap">
              <div className="flex00a displayFlexRow" style={{flexBasis:"15em"}}>
                <div className="flex00a"><font className="fontItalic fontTextLight">{"Sent By: "}</font></div>
                <div className="flex11a rMargin"><LibraryReact.Nowrap p_fontClass="">{p_mySentSurveyResponseTimeObj.sentByUserNamePlainText}</LibraryReact.Nowrap></div>
              </div>
              <div className="flex00a" style={{flexBasis:"15em"}}>
                <font className="fontItalic fontTextLight">{"Sent: "}</font>
                <font className="">{p_mySentSurveyResponseTimeObj.sentDateTimeLocal}</font>
              </div>
              <div className="flex00a" style={{flexBasis:"15em"}}>
                <font className="fontItalic fontTextLight">{"Due: "}</font>
                <font className="">{p_mySentSurveyResponseTimeObj.dueDateTimeLocal}</font>
              </div>
              <div className="flex00a" style={{flexBasis:"15em"}}>
                <font className="fontItalic fontTextLight">{"Completed: "}</font>
                <font className="">{p_mySentSurveyResponseTimeObj.completedDateTimeLocal}</font>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}));

const MySurveysSingleIDIQItem = inject("OpenCaptureMobx")(observer(
class MySurveysSingleIDIQItem extends Component { //props: p_idiqSurveyObj
  onclick_idiq_capture_link = () => {
    const p_idiqSurveyObj = this.props.p_idiqSurveyObj;
    this.props.OpenCaptureMobx.a_open_single_capture(p_idiqSurveyObj.idiqCaptureID);
  }

  render() {
    const p_idiqSurveyObj = this.props.p_idiqSurveyObj;

    const idiqCaptureID = p_idiqSurveyObj.idiqCaptureID;
    const idiqCaptureName = p_idiqSurveyObj.idiqCaptureName;
    const idiqCapturesWithSurveysArrayOfObjs = p_idiqSurveyObj.idiqCapturesWithSurveysArrayOfObjs;

    if(!JSFUNC.select_int_is_filled_out_tf(idiqCaptureID)) {
      return(
        <div className="textCenter">
          <font className="fontItalic fontTextLighter">
            {"Select an IDIQ Capture above to view all linked Task Order surveys"}
          </font>
        </div>
      );
    }

    return(
      <div className="border1bbb bgLightBlue medFullPad">
        <div
          className="medBottomMargin border1ddd borderRadius10 bgLighterBlue tbPad lrMedPad textCenter hoverLighterBlueGradient cursorPointer"
          onClick={this.onclick_idiq_capture_link}>
          <div><font className="font12 fontItalic fontTextLight">{"IDIQ Capture: "}</font></div>
          <div><font className="font12 fontBold">{idiqCaptureName}</font></div>
        </div>
        {idiqCapturesWithSurveysArrayOfObjs.map((m_idiqCaptureWithSurveysObj) =>
          <MySurveysSingleIDIQCapturesWithSurveysList p_idiqCaptureWithSurveysObj={m_idiqCaptureWithSurveysObj} />
        )}
      </div>
    );
  }
}));

const MySurveysSingleIDIQCapturesWithSurveysList = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class MySurveysSingleIDIQCapturesWithSurveysList extends Component { //props: p_idiqCaptureWithSurveysObj
  onclick_capture_to_within_idiq_link = () => {
    const p_idiqCaptureWithSurveysObj = this.props.p_idiqCaptureWithSurveysObj;

    const functionOnSuccess = (i_parseResponse) => {
      this.props.OpenCaptureMobx.a_launch_card_full(this.props.DatabaseMobx.k_cardIDTeammates);
      this.props.OpenCaptureMobx.a_teammates_set_left_tab_selected("surveysCapabilitiesGapAnalysis");
    }
    this.props.OpenCaptureMobx.a_open_single_capture(p_idiqCaptureWithSurveysObj.captureID, functionOnSuccess);
  }

  render() {
    const p_idiqCaptureWithSurveysObj = this.props.p_idiqCaptureWithSurveysObj;

    const captureID = p_idiqCaptureWithSurveysObj.captureID;
    const captureNamePlainText = p_idiqCaptureWithSurveysObj.captureNamePlainText;
    const captureIDIQCaptureID = p_idiqCaptureWithSurveysObj.captureIDIQCaptureID;
    const idiqCaptureSurveysArrayOfObjs = p_idiqCaptureWithSurveysObj.idiqCaptureSurveysArrayOfObjs;

    return(
      <div className="displayFlexRow medBottomMargin border1bbb bgLighterGray medFullPad">
        <div className="flex11a" style={{flexBasis:"100em"}}>
          <div
            className="border1ddd borderRadius10 bgLightestestGray tbPad lrMedPad hoverLighterBlueGradient cursorPointer"
            onClick={this.onclick_capture_to_within_idiq_link}>
            <div className="lrPad">
              <font className="font11 fontItalic fontTextLight">{"Capture: "}</font>
              <font className="font11 fontBold">{captureNamePlainText}</font>
            </div>
          </div>
        </div>
        <div className="flex00a" style={{flexBasis:"1em"}} />
        <div className="flex11a" style={{flexBasis:"200em"}}>
          {idiqCaptureSurveysArrayOfObjs.map((m_idiqCaptureSurveyObj) =>
            <MySurveysSingleIDIQSingleCaptureWithSurveysList p_idiqCaptureSurveyObj={m_idiqCaptureSurveyObj} />
          )}
        </div>
      </div>
    );
  }
}));

const MySurveysSingleIDIQSingleCaptureWithSurveysList = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class MySurveysSingleIDIQSingleCaptureWithSurveysList extends Component { //props: p_idiqCaptureSurveyObj
  onclick_idiq_capture_single_survey_link = () => {
    const p_idiqCaptureSurveyObj = this.props.p_idiqCaptureSurveyObj;

    const functionOnSuccess = (i_parseResponse) => {
      this.props.OpenCaptureMobx.a_launch_card_full(this.props.DatabaseMobx.k_cardIDTeammates);
      this.props.OpenCaptureMobx.a_teammates_set_left_tab_selected("surveysCapabilitiesGapAnalysis");
      this.props.OpenCaptureMobx.a_teammates_set_floating_box_survey_teammate_id_and_survey_id(p_idiqCaptureSurveyObj.surveyInfoObj.teammate_id, p_idiqCaptureSurveyObj.surveyInfoObj.survey_id);
    }
    this.props.OpenCaptureMobx.a_open_single_capture(p_idiqCaptureSurveyObj.surveyInfoObj.capture_id, functionOnSuccess);
  }

  render() {
    const p_idiqCaptureSurveyObj = this.props.p_idiqCaptureSurveyObj;

    const surveyInfoObj = p_idiqCaptureSurveyObj.surveyInfoObj;
    const surveyResponseTimesArrayOfObjs = p_idiqCaptureSurveyObj.surveyResponseTimesArrayOfObjs;

    return(
      <div className="medBottomMargin border1bbb bgLightGray medFullPad">
        <div
          className="smallBottomMargin border1ddd borderRadius10 bgLightesterGray tbMicroPad lrMedPad textCenter hoverLighterBlueGradient cursorPointer"
          onClick={this.onclick_idiq_capture_single_survey_link}>
          <div className="lrPad">
            <font className="font11 fontItalic fontTextLight">{"Survey: "}</font>
            <font className="font11 fontBold">{surveyInfoObj.title}</font>
          </div>
        </div>
        <div className="displayFlexRow">
          <div className="flex11a" style={{flexBasis:"900em"}}>
            {surveyResponseTimesArrayOfObjs.map((m_idiqCaptureSurveyObj) =>
              <MySurveysSingleSurveyResponseTimeItem p_mySentSurveyResponseTimeObj={m_idiqCaptureSurveyObj} p_thinTF={true} />
            )}
          </div>
          <div className="flex11a" style={{flexBasis:"100em"}} />
        </div>
      </div>
    );
  }
}));










const GeneralDocuments = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class GeneralDocuments extends Component {
  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const o_navigateToFolderIDTblGGeneralDocumentsFileFolderSystem = this.props.CaptureExecMobx.o_navigateToFolderIDTblGGeneralDocumentsFileFolderSystem;

    return(
      <CEGeneralReact.FileFolderSystem
        p_tblName="tbl_g_general_documents_filefoldersystem"
        p_tblMapOfMaps={this.props.DatabaseMobx.o_tbl_g_general_documents_filefoldersystem}
        p_tblFilterValuesObj={undefined}
        p_title={undefined}
        p_defaultXmlType={undefined}
        p_allowedExtsArray={undefined}
        p_disableUploadFilesTF={false}
        p_disableCreateOnlineLinkDocsTF={false}
        p_disableCreateFoldersTF={false}
        p_viewWideTrueNarrowFalse={(o_mediaQueryFlag >= 4)}
        p_navigateToFolderID={o_navigateToFolderIDTblGGeneralDocumentsFileFolderSystem}
        f_onPerformFFSAction={undefined}
        f_onNavigateToFolder={this.props.CaptureExecMobx.a_set_navigate_to_folder_id_tbl_g_general_documents_filefoldersystem}
      />
    );
  }
}));








function UpgradeToCaptureExec(props) {
  return(
    <div className="flex11a yScroll">
      <div className="displayFlexRow" style={{height:"70em"}}>
        <div className="flex11a" style={{flexBasis:"300em"}}>

        </div>
        <div className="flex11a medFullMargin border1bbb bgLightBlueGradient medFullPad" style={{flexBasis:"100em"}}>
          <font className="font14 fontBold">
            {"Reasons for CaptureExec"}
          </font>
        </div>
      </div>
    </div>
  );
}







const ArchivedCapturesWarningBottomBar = inject("CaptureExecMobx", "DatabaseMobx", "UserMobx")(observer(
class ArchivedCapturesWarningBottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_alwaysLoadArchivedCapturesInTheFutureCheckBox01: 0,
      s_reloadingAllCapturesTF: false
    };
  }

  onclick_archived_captures_warning_dismiss = () => {
    this.props.CaptureExecMobx.a_set_archived_captures_warning_bottom_bar_user_has_dismissed_warning_tf(true);
  }

  onclick_archived_captures_warning_dismiss_and_dont_show_again = () => {
    this.props.UserMobx.a_update_user_per_email_field("archived_captures_preference", "noLoadArchivedNoPrompt", "s");
    this.props.CaptureExecMobx.a_set_archived_captures_warning_bottom_bar_user_has_dismissed_warning_tf(true);
  }

  onclick_load_archived_captures_into_the_system = () => {
    const s_alwaysLoadArchivedCapturesInTheFutureCheckBox01 = this.state.s_alwaysLoadArchivedCapturesInTheFutureCheckBox01;
    const c_phase1TblNamesToLoadArray = this.props.DatabaseMobx.c_phase1TblNamesToLoadArray;

    this.setState({s_reloadingAllCapturesTF:true});

    //reload all captures and capture tables
    const phase1TblNamesComma = JSFUNC.convert_array_to_comma_list(c_phase1TblNamesToLoadArray);
    const forceLoadArchivedCapturesTF = true;
    const singleCaptureIDOrM1AllCaptures = -1; //load all accessible captures for this user including archived captures

    const functionOnFinish = () => {
      //if always load in the future is checked, update the user setting
      if(s_alwaysLoadArchivedCapturesInTheFutureCheckBox01 === 1) {
        this.props.UserMobx.a_update_user_per_email_field("archived_captures_preference", "alwaysLoadArchived", "s");
      }

      //dismiss the warning just to make sure it doesn't show up again after loading
      this.props.CaptureExecMobx.a_set_archived_captures_warning_bottom_bar_user_has_dismissed_warning_tf(true);
    }

    JSPHP.load_db_data_to_local_memory_from_filtered_capture_tbl_names_comma(phase1TblNamesComma, forceLoadArchivedCapturesTF, singleCaptureIDOrM1AllCaptures, functionOnFinish);
  }

  onclick_always_load_archived_captures_in_the_future_checkbox = () => {
    this.setState((i_state, i_props) => ({
      s_alwaysLoadArchivedCapturesInTheFutureCheckBox01: ((i_state.s_alwaysLoadArchivedCapturesInTheFutureCheckBox01 === 1) ? (0) : (1))
    }));
  }

  render() {
    const s_alwaysLoadArchivedCapturesInTheFutureCheckBox01 = this.state.s_alwaysLoadArchivedCapturesInTheFutureCheckBox01;
    const s_reloadingAllCapturesTF = this.state.s_reloadingAllCapturesTF;

    const o_archivedCapturesWarningBottomBarUserHasDismissedWarningTF = this.props.CaptureExecMobx.o_archivedCapturesWarningBottomBarUserHasDismissedWarningTF;
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_archivedCaptureIDsComma = this.props.DatabaseMobx.o_archivedCaptureIDsComma;
    const c_userArchivedCapturesNoLoadArchivedWithPromptTF = this.props.UserMobx.c_userArchivedCapturesNoLoadArchivedWithPromptTF;

    //if after loading data through login there are 0 archived captures left behind (due to not using archiving or user preference to always load archived), do not draw this archived captures warning bar
    if(o_archivedCaptureIDsComma === "") {
      return(null);
    }

    //if there are archived captures, but the user does not ever want to be prompted to load them based on their preferences, don't display this warning bar
    if(!c_userArchivedCapturesNoLoadArchivedWithPromptTF) {
      return(null);
    }

    //if the user has already seen this warning and has dismissed it, don't display it
    if(o_archivedCapturesWarningBottomBarUserHasDismissedWarningTF) {
      return(null);
    }

    const archivedCaptureIDsArray = JSFUNC.convert_comma_list_to_int_array(o_archivedCaptureIDsComma);
    const numArchivedCaptures = archivedCaptureIDsArray.length;

    var archivedCapturesWarningContentComponent = null;
    if(s_reloadingAllCapturesTF) {
      archivedCapturesWarningContentComponent = (
        <>
          <div className="flex11a" />
          <div className="flex00a lrMedPad">
            <font className="fontItalic">
              {"Loading archived Captures"}
            </font>
          </div>
          <div className="flex00a lrMedPad">
            <CEGeneralReact.LoadingAnimation />
          </div>
        </>
      );
    }
    else {
      archivedCapturesWarningContentComponent = (
        <>
          <div className="flex00a tbMicroPad lrMedPad">
            <font className="font12 fontItalic">
              {numArchivedCaptures + " archived " + JSFUNC.plural(numArchivedCaptures, "Capture was", "Captures were") + " not loaded into the system."}
            </font>
          </div>
          <div className="flex11a" />
          <div className="flex00a lrMedPad">
            <CEGeneralReact.CEButton
              p_type="hyperlinkBoldUnderline"
              p_text="Dismiss"
              f_onClick={this.onclick_archived_captures_warning_dismiss}
            />
          </div>
          <div className="flex00a tbMicroPad lrMedPad">
            <CEGeneralReact.CEButton
              p_type="hyperlinkBoldUnderline"
              p_text="Dismiss and Don't Show Again"
              f_onClick={this.onclick_archived_captures_warning_dismiss_and_dont_show_again}
            />
          </div>
          <div className="flex00a" style={{flexBasis:"3em"}} />
          <div className="flex00a tbMicroPad lrMedPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={"Load " + numArchivedCaptures + " Archived " + JSFUNC.plural(numArchivedCaptures, "Capture", "Captures") + " into the System"}
              f_onClick={this.onclick_load_archived_captures_into_the_system}
            />
          </div>
          <div className="flex00a displayFlexRowVc tbMicroPad lrMedPad" style={{flexBasis:"14em"}}>
            <div className="flex11a rMargin">
              <font className="fontItalic">
                {"Always load archived Captures in the future"}
              </font>
            </div>
            <div className="flex00a">
              <LibraryReact.CheckBox
                p_u0_s1_p2_du3_ds4={s_alwaysLoadArchivedCapturesInTheFutureCheckBox01}
                p_sizeEm={1.3}
                f_onClick={this.onclick_always_load_archived_captures_in_the_future_checkbox}
              />
            </div>
          </div>
        </>
      );
    }

    return(
      <div className="positionFixed b0 l0 r0 displayFlexRowVc flexWrap border1bbb bgLightBlueGradient medFullPad" style={{zIndex:5}}>
        {archivedCapturesWarningContentComponent}
      </div>
    );
  }
}));




const ViewSpecifiedSearchResultCapturesInFloatingBox = inject("CaptureExecMobx", "OpenCaptureMobx", "UserMobx")(observer(
class ViewSpecifiedSearchResultCapturesInFloatingBox extends Component {
  onclick_capture_open_capture = (i_captureID) => {
    this.props.OpenCaptureMobx.a_open_single_capture(i_captureID);
  }

  onclick_close_view_search_results_captures = () => {
    this.props.CaptureExecMobx.a_set_view_specified_search_results_captures_in_floating_box_capture_ids_array_and_floating_box_title(undefined, undefined);
  }

  render() {
    const o_viewSpecifiedSearchResultCapturesInFloatingBoxTitle = this.props.CaptureExecMobx.o_viewSpecifiedSearchResultCapturesInFloatingBoxTitle;
    const c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs = this.props.CaptureExecMobx.c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs;
    const c_userCanMassEditAndMassDeleteCaptureTableTF = this.props.UserMobx.c_userCanMassEditAndMassDeleteCaptureTableTF;

    if(!JSFUNC.is_array(c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs)) {
      return(null);
    }

    const numCaptures = c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs.length;

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="large"
        p_title={o_viewSpecifiedSearchResultCapturesInFloatingBoxTitle}
        p_zIndex={1}
        f_onClickCancel={this.onclick_close_view_search_results_captures}>
        <div className="flex00a displayFlexRow">
          <div className="flex11a displayFlexColumnVc lrMedPad textRight">
            <LibraryReact.Nowrap p_fontClass="font12 fontBold fontItalic fontTextLighter">
              {numCaptures + " " + JSFUNC.plural(numCaptures, "Capture", "Captures")}
            </LibraryReact.Nowrap>
          </div>
          {(c_userCanMassEditAndMassDeleteCaptureTableTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
              <CapturesReact.CaptureTableExportCsvMassEditMassDeleteVerticalDotsMenu
                p_capturesArrayOfObjs={c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs}
                p_filterName={o_viewSpecifiedSearchResultCapturesInFloatingBoxTitle}
                p_exportToCsvOptionTF={false}
              />
            </div>
          }
        </div>
        <div className="flex11a xyScroll lrMedPad hugeBottomPad">
          <CapturesReact.SearchResultsCapturesTable
            p_searchResultCapturesArrayOfObjs={c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs}
            p_initialSortIsCustomTF={false}
            f_onClickCapture={this.onclick_capture_open_capture}
          />
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));






//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++




const CaptureExecPhpLoadingAnimation = inject("CaptureExecMobx")(observer(
class CaptureExecPhpLoadingAnimation extends Component {
  render() {
    if(!this.props.CaptureExecMobx.o_phpFileIsCurrentlyRunningTF) {
      return(null);
    }

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const top = ((c_isMobileOrTabletTF) ? ("1.1em") : ("2.9em"));
    const left = ((c_isMobileOrTabletTF) ? ("2.5em") : ("11.5em"));
    const transform = ((c_isMobileOrTabletTF) ? ("rotate(270deg)") : (undefined));
    return(
      <CEGeneralReact.LoadingAnimation
        p_frameDelay={5}
        p_class="positionFixed"
        p_styleObj={{zIndex:999999, top:top, left:left, transform:transform}}
      />
    );
  }
}));



const ErrorMessage = inject("CaptureExecMobx")(observer(
class ErrorMessage extends Component {
  onclick_error = () => { this.props.CaptureExecMobx.a_close_error_message(); }

  render() {
    const o_isLocalhost3000SystemTF = this.props.CaptureExecMobx.o_isLocalhost3000SystemTF;
    const o_errorMessage = this.props.CaptureExecMobx.o_errorMessage;

    //never display this error message if this is the live system
    if(!o_isLocalhost3000SystemTF || !o_errorMessage) {
      return(null);
    }

    return(
      <div
        className="positionFixedFullScreen captureExecErrorMessage bgTopBar"
        style={{zIndex:888888, overflow:"scroll", maxHeight:"40vh"}}
        onClick={this.onclick_error}>
        {o_errorMessage}
      </div>
    );
  }
}));



const TestMainContent = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class TestMainContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_dateTime: "2021-12-22 02:00:58",
      s_twsState012: 0,
      s_vsState012: 0,
      s_stateValue: undefined,
      s_stateValue2: undefined,
      s_stateValue3: undefined,
      s_stateValue4: undefined,
      s_xmlOut: "xmlOut..."
    }
  }

  componentDidMount() {
    //test full excel xml string replacement on filter 'all captures' and sort 'stagecov' for a few fields
    var excelReportTemplateXml = "preambleSINGLECAPTURE";
    excelReportTemplateXml += "\n<Row><Cell>[[id]]</Cell><Cell>[[opportunity_name]]</Cell><Cell>[[stage_id]]</Cell><Cell>[[contract_overall_value]]</Cell><Cell>[[test_date_dayMdyDaysUntil2Overdue_fromRawDateTimeUtc]]</Cell><Cell>[[+filter:filtercaptureid1]]</Cell><Cell>[[+sort:stagecov]]</Cell></Row>";
    excelReportTemplateXml += "\nconclusion";
    /*var excelReportTemplateXml = "preambleMULTIPLEWITHSUBTOTALS\n";
    excelReportTemplateXml += "<Row><Cell>[[id]]</Cell><Cell>[[opportunity_name]]</Cell><Cell>[[stage_id]]</Cell><Cell>[[contract_overall_value]]</Cell><Cell>[[test_date_dayMdyDaysUntil2Overdue_fromRawDateTimeUtc]]</Cell><Cell>[[+filter:all]]</Cell><Cell>[[+sort:stagecov]]</Cell>\n</Row>";
    excelReportTemplateXml += "<Row><Cell>[[!subtotal]]</Cell><Cell></Cell><Cell></Cell><Cell>[[!subtotal]]</Cell><Cell></Cell><Cell>[[+total:stage_id]]</Cell><Cell></Cell>\n</Row>";
    excelReportTemplateXml += "conclusion";*/
    const functionOnFinish = (i_xmlOut) => {
      this.onchange_state_xml_out(i_xmlOut);
    }
    //JSPHP.write_excel_report_xml_from_template_xml_data_string(excelReportTemplateXml, functionOnFinish);
  }

  onchange_ce_datetime = (i_newValue) => { this.setState({s_dateTime:i_newValue}); }

  onchange_datetime = (event) => {
    var newValue = event.target.value;
    this.setState({s_dateTime:newValue});
  }

  onswitch_tws_to_center = () => { this.setState({s_twsState012:0}); }
  onswitch_tws_to_left = () => { this.setState({s_twsState012:1}); }
  onswitch_tws_to_right = () => { this.setState({s_twsState012:2}); }

  onchange_vs_state_value = (i_newValue) => {
    this.setState({s_vsState012:i_newValue});
  }

  onchange_state_value = (i_newValue) => {
    this.setState({s_stateValue:i_newValue});
  }

  onchange_state_value2 = (i_newValue) => {
    this.setState({s_stateValue2:i_newValue});
  }

  onchange_state_value3 = (i_newValue) => {
    this.setState({s_stateValue3:i_newValue});
  }

  onchange_state_value4 = (i_newValue) => {
    this.setState({s_stateValue4:i_newValue});
  }

  onchange_state_xml_out = (i_newValue) => {
    this.setState({s_xmlOut:i_newValue});
  }

  onclick_import_csv_data_test = (i_csvDataArrayOrArrayOfArrays, i_runThisFunctionUponSuccess, i_runThisFunctionUponError) => {
    JSFUNC.alert_array(i_csvDataArrayOrArrayOfArrays);
    i_runThisFunctionUponSuccess();
  }

  render() {
    const s_dateTime = this.state.s_dateTime;
    const s_twsState012 = this.state.s_twsState012;
    const s_vsState012 = this.state.s_vsState012;
    const stateValue = this.state.s_stateValue;
    const stateValue2 = this.state.s_stateValue2;
    const stateValue3 = this.state.s_stateValue3;
    const stateValue4 = this.state.s_stateValue4;
    const s_xmlOut = this.state.s_xmlOut;

    var runSingleCaptureTemplateTestTF = true;
    var runExcelReportTemplateEveryFieldTestTF = false;

    const captureMap = this.props.DatabaseMobx.o_tbl_captures.get(1);

    const dateFromStateDateTime = JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(s_dateTime);
    const dateTimeTypesArrayOfObjs = [
      {valueRaw:s_dateTime, inputType:"datetime", generic:"c_genericDateTimeFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateTimeFieldTypeObj},
      {valueRaw:s_dateTime, inputType:"datetime_YmdgiA", generic:"c_genericDateTimeYmdgiaFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateTimeYmdgiaFieldTypeObj},
      {valueRaw:s_dateTime, inputType:"datetime_MjYgiA", generic:"c_genericDateTimeMjYgiAFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateTimeMjYgiAFieldTypeObj},
      {valueRaw:s_dateTime, inputType:"datetime_dayMdyDaysUntil1", generic:"c_genericDateTimeDayMdyDaysUntil1FieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil1FieldTypeObj},
      {valueRaw:s_dateTime, inputType:"datetime_dayMdyDaysUntil1Overdue", generic:"c_genericDateTimeDayMdyDaysUntil1OverdueFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil1OverdueFieldTypeObj},
      {valueRaw:s_dateTime, inputType:"datetime_dayMdyDaysUntil2", generic:"c_genericDateTimeDayMdyDaysUntil2FieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil2FieldTypeObj},
      {valueRaw:s_dateTime, inputType:"datetime_dayMdyDaysUntil2Overdue", generic:"c_genericDateTimeDayMdyDaysUntil2OverdueFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil2OverdueFieldTypeObj},
      {valueRaw:dateFromStateDateTime, inputType:"date", generic:"c_genericDateFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateFieldTypeObj},
      {valueRaw:dateFromStateDateTime, inputType:"date_DMjY", generic:"c_genericDateDMjYFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateDMjYFieldTypeObj},
      {valueRaw:dateFromStateDateTime, inputType:"date_dayMdyDaysUntil1", generic:"c_genericDateDayMdyDaysUntil1FieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1FieldTypeObj},
      {valueRaw:dateFromStateDateTime, inputType:"date_dayMdyDaysUntil1Overdue", generic:"c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj},
      {valueRaw:dateFromStateDateTime, inputType:"date_dayMdyDaysUntil2", generic:"c_genericDateDayMdyDaysUntil2FieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil2FieldTypeObj},
      {valueRaw:dateFromStateDateTime, inputType:"date_dayMdyDaysUntil2Overdue", generic:"c_genericDateDayMdyDaysUntil2OverdueFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil2OverdueFieldTypeObj},
      {valueRaw:"9001-03-03", inputType:"dateWithRelativeDate", generic:"c_genericDateWithRelativeDateFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj},
      {valueRaw:"2022-07-31 00:20:03", inputType:"dateWithDuration", generic:"c_genericDateWithDurationFieldTypeObj", fieldTypeObj:this.props.DatabaseMobx.c_genericDateWithDurationFieldTypeObj}
    ];

    var lowercaseCodewordsArray = [];
    var teammateContractLowercaseCodewordsArray = [];
    if(runSingleCaptureTemplateTestTF) {
      lowercaseCodewordsArray = [
        "[[id]]",
        "[[opportunity_name]]",
        "[[stage_id]]",
        "[[rfp_date]]",
        "[[rfi_date]]",
        "[[proposal_due_datetime_utc]]",
        "[[recent_pinned_notestamp]]",
        "[[notepad]]",
        "[[@notepad_notestamps_firstpinnedorrecent]]",
        "[[@notepad_notestamps_mostrecentlycreated]]",
        "[[@notepad_notestamps_mostrecentlyedited]]",
        "[[@notepad_notestamps]]",
        "[[next_active_task]]",
        "",
        "[[@ds_q1]]",
        "[[@ds_q1a]]",
        "[[@ds_q1p]]",
        "[[@ds_q2]]",
        "[[@ds_q2a]]",
        "[[@ds_q2p]]",
        "",
        "[[@theme_diff1_differentiator]]",
        "[[@theme_diff1_justification]]",
        "",
        "[[@team_all_selected_teammates_list_names_sort_alphabetic]]",
        "[[@team_all_selected_teammates_list_names_sort_allocation]]",
        "[[@team_all_selected_teammates_list_names_sort_lb_sb_allocation]]",
        "[[@team_all_selected_teammates_list_names_sort_lb_allocation]]",
        "[[@team_all_selected_teammates_list_names_sort_sb_allocation]]",
        "[[@team_all_selected_teammates_list_names_and_allocation_sort_allocation]]",
        "[[@team_all_selected_teammates_list_names_and_allocation_sort_lb_sb_allocation]]",
        "[[@team_all_selected_teammates_list_names_and_allocation_sort_lb_allocation]]",
        "[[@team_all_selected_teammates_list_names_and_allocation_sort_sb_allocation]]",
        "",
        "[[@team_lb1_contactcompany_name]]",
        "[[@team_lb1_contactcompany_abbreviated_name]]",
        "[[@team_lb1_contactcompany_business_type_id]]",
        "[[@team_lb1_contactcompany_sb_certifications_bm_set_aside_ids_comma]]",
        "[[@team_lb1_contactcompany_city]]",
        "[[@team_lb1_test_team]]",
        "[[@team_lb1_notes]]",
        "[[@team_lb1_invalid]]",
        "",
        "[[@team_sb1_contactcompany_name]]",
        "[[@team_sb1_contactcompany_abbreviated_name]]",
        "[[@team_sb1_contactcompany_business_type_id]]",
        "[[@team_sb1_contactcompany_sb_certifications_bm_set_aside_ids_comma]]",
        "",
        "[[@team_lb2_contactcompany_name]]",
        "[[@team_lb2_contactcompany_abbreviated_name]]",
        "[[@team_lb2_contactcompany_business_type_id]]",
        "[[@team_lb2_contactcompany_sb_certifications_bm_set_aside_ids_comma]]",
        "",
        "[[@team_sb2_contactcompany_name]]",
        "[[@team_sb2_contactcompany_abbreviated_name]]",
        "[[@team_sb2_contactcompany_business_type_id]]",
        "[[@team_sb2_contactcompany_sb_certifications_bm_set_aside_ids_comma]]",
        "",
        "[[description_of_work]]",
        "[[description_of_work|maxchars20]]",
        "[[description_of_work|maxchars200]]",
        "[[@ds_q1]]",
        "[[@ds_q1|maxchars10]]",
        "[[test_website]]",
        "[[test_website|websitemask]]",
        "[[test_website|websiteaddress]]",
        "[[naics_code_ids_comma]]",
        "[[naics_code_ids_comma|naicscodedesconly]]",
        "[[naics_code_ids_comma|naicscodeonly]]",
        "",
        "[[@team_lb1_contactcompany_abbreviated_name]]",
        "[[@team_lb1_contactcompany_abbreviated_name|maxchars5]]",
        "[[@team_lb1_contactcompany_abbreviated_name|blankifblank]]",
        "",
        "[[@conv_date_notes:td-90:td]]",
        "[[@conv_datetime_notes:td-90:td]]",
        "[[@conv_date_persons_notes:td-90:td]]",
        "[[@conv_datetime_persons_notes:td-90:td]]",
        "[[@conv_date_notes:2019-01-01:2019-06-06]]", //includes 2019-01-01 00:00:00 to 2019-06-06 23:59:59
        "",
        "[[@budget_b_p_total_approved_funding]]",
        "[[@budget_b_p_remaining]]",
        "[[@budget_b_p_pending_approval]]",
        "[[@budget_b_p_spent]]",
        "[[@budget_capture_total_approved_funding]]",
        "[[@budget_capture_remaining]]",
        "[[@budget_capture_pending_approval]]",
        "[[@budget_capture_spent]]",
        "[[@budget_invalid_total_approved_funding]]",
        "[[@budget_b_p_invalid]]"
      ];

      teammateContractLowercaseCodewordsArray = [
        "[[@team_con_contractsmanager_name]]",
        "[[@team_con_contractsmanager_first_name]]",
        "[[@team_con_contractsmanager_last_name]]",
        "[[@team_con_contractsmanager_title]]",
        "[[@team_con_contractsmanager_email]]",
        "[[@team_con_contractsmanager_phone]]",
        "[[@team_con_contractsmanager_contact_company_id]]"
      ];
    }

    var testExcelReportAllFieldsInSingleCaptureArrayOfObjs = [];
    if(runExcelReportTemplateEveryFieldTestTF) {
      const dealShapingCodewordComponentsObjOrUndefined = undefined;
      const codewordIsAtTeamTF = false;
      const codewordModifiersArray = [];
      const codewordModifierIsSplitDivisionTF = false;
      const testCapture1Map = this.props.DatabaseMobx.o_tbl_captures.get(1);
      const capturesTextareaDataArrayOfObjsOrUndefined = undefined;
      const capturesDealShapingDataArrayOfObjsOrUndefined = undefined;
      const expandedFiltersArrayOfObjs = [];

      for(let expandedCaptureFieldMap of this.props.DatabaseMobx.c_tbl_captures_fields.values()) {
        var fieldDbName = expandedCaptureFieldMap.get("db_name");
        var codewordLowercaseWithoutModifiers = fieldDbName.toLowerCase();

        var codewordReplacementObj = JSPHP.get_excel_xml_codeword_replacement_with_modifiers_applied_obj_from_codeword_lowercase_and_capture_map_and_expanded_capture_field_map(codewordLowercaseWithoutModifiers, dealShapingCodewordComponentsObjOrUndefined, codewordIsAtTeamTF, codewordModifiersArray, codewordModifierIsSplitDivisionTF, testCapture1Map, expandedCaptureFieldMap, capturesTextareaDataArrayOfObjsOrUndefined, capturesDealShapingDataArrayOfObjsOrUndefined, expandedFiltersArrayOfObjs);
        testExcelReportAllFieldsInSingleCaptureArrayOfObjs.push({
          codewordLowercaseWithoutModifiers: codewordLowercaseWithoutModifiers,
          codewordReplacement: codewordReplacementObj.codewordReplacement,
          codewordReplacementIsInvalidExcelDateTF:  codewordReplacementObj.codewordReplacementIsInvalidExcelDateTF
        });

        if(fieldDbName === "description_of_work") {
          var maxcharsCodewordModifiersArray = ["maxchars20"];
          var descCodewordReplacementObj = JSPHP.get_excel_xml_codeword_replacement_with_modifiers_applied_obj_from_codeword_lowercase_and_capture_map_and_expanded_capture_field_map(codewordLowercaseWithoutModifiers, dealShapingCodewordComponentsObjOrUndefined, codewordIsAtTeamTF, maxcharsCodewordModifiersArray, codewordModifierIsSplitDivisionTF, testCapture1Map, expandedCaptureFieldMap, capturesTextareaDataArrayOfObjsOrUndefined, capturesDealShapingDataArrayOfObjsOrUndefined, expandedFiltersArrayOfObjs);
          testExcelReportAllFieldsInSingleCaptureArrayOfObjs.push({
            codewordLowercaseWithoutModifiers: codewordLowercaseWithoutModifiers + "|" + maxcharsCodewordModifiersArray.join("|"),
            codewordReplacement: descCodewordReplacementObj.codewordReplacement,
            codewordReplacementIsInvalidExcelDateTF:  descCodewordReplacementObj.codewordReplacementIsInvalidExcelDateTF
          });
        }

        if(fieldDbName === "naics_code_ids_comma") {
          var naics1CodewordModifiersArray = ["naicscodedesconly"];
          var naics1CodewordReplacementObj = JSPHP.get_excel_xml_codeword_replacement_with_modifiers_applied_obj_from_codeword_lowercase_and_capture_map_and_expanded_capture_field_map(codewordLowercaseWithoutModifiers, dealShapingCodewordComponentsObjOrUndefined, codewordIsAtTeamTF, naics1CodewordModifiersArray, codewordModifierIsSplitDivisionTF, testCapture1Map, expandedCaptureFieldMap, capturesTextareaDataArrayOfObjsOrUndefined, capturesDealShapingDataArrayOfObjsOrUndefined, expandedFiltersArrayOfObjs);
          testExcelReportAllFieldsInSingleCaptureArrayOfObjs.push({
            codewordLowercaseWithoutModifiers: codewordLowercaseWithoutModifiers + "|" + naics1CodewordModifiersArray.join("|"),
            codewordReplacement: naics1CodewordReplacementObj.codewordReplacement,
            codewordReplacementIsInvalidExcelDateTF:  naics1CodewordReplacementObj.codewordReplacementIsInvalidExcelDateTF
          });

          var naics2CodewordModifiersArray = ["naicscodeonly"];
          var naics2CodewordReplacementObj = JSPHP.get_excel_xml_codeword_replacement_with_modifiers_applied_obj_from_codeword_lowercase_and_capture_map_and_expanded_capture_field_map(codewordLowercaseWithoutModifiers, dealShapingCodewordComponentsObjOrUndefined, codewordIsAtTeamTF, naics2CodewordModifiersArray, codewordModifierIsSplitDivisionTF, testCapture1Map, expandedCaptureFieldMap, capturesTextareaDataArrayOfObjsOrUndefined, capturesDealShapingDataArrayOfObjsOrUndefined, expandedFiltersArrayOfObjs);
          testExcelReportAllFieldsInSingleCaptureArrayOfObjs.push({
            codewordLowercaseWithoutModifiers: codewordLowercaseWithoutModifiers + "|" + naics2CodewordModifiersArray.join("|"),
            codewordReplacement: naics2CodewordReplacementObj.codewordReplacement,
            codewordReplacementIsInvalidExcelDateTF:  naics2CodewordReplacementObj.codewordReplacementIsInvalidExcelDateTF
          });
        }
      }
      JSFUNC.sort_arrayOfObjs(testExcelReportAllFieldsInSingleCaptureArrayOfObjs, "codewordLowercaseWithoutModifiers", true);
    }

    return(
      <div className="flex11a yScroll">
        <div className="flex00a displayFlexRowVc tbMedPad borderB1ddd">
          <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"7em"}}>
            {"Date/Time"}
          </div>
          <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"12em"}}>
            {s_dateTime}
          </div>
          <div className="flex00a" style={{flexBasis:"88em"}}>
            {dateTimeTypesArrayOfObjs.map((m_dateTimeTypeObj) =>
              <div className="displayFlexRowVc borderB1ddd hoverLighterBlueGradient">
                <div className="flex00a" style={{flexBasis:"20em"}}>
                  {m_dateTimeTypeObj.inputType}
                </div>
                <div className="flex00a" style={{flexBasis:"30em"}}>
                  {m_dateTimeTypeObj.generic}
                </div>
                <div className="flex00a" style={{flexBasis:"20em"}}>
                  {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(m_dateTimeTypeObj.valueRaw, m_dateTimeTypeObj.fieldTypeObj)}
                </div>
                <div className="flex11a" style={{flexBasis:"100em"}}>
                  {this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(m_dateTimeTypeObj.valueRaw, m_dateTimeTypeObj.fieldTypeObj)}
                </div>
              </div>
            )}
          </div>
          <div className="flex00a lrMedPad">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateTimeFieldTypeObj}
              p_valueRaw={s_dateTime}
              f_onChangeOrOnSelect={this.onchange_ce_datetime}
            />
          </div>
          <div className="flex00a lrMedPad">
            <input
              type="datetime-local"
              value={s_dateTime}
              onChange={this.onchange_datetime}
            />
          </div>
        </div>
        <div className="flex00a displayFlexRowVc medFullPad borderB1ddd">
          <div className="flex00a">
            <LibraryReact.ThreeWaySwitch
              p_stateC0L1R2={s_twsState012}
              p_sizeEm={undefined}
              p_leftColor={undefined}
              p_rightColor={undefined}
              p_tabIndex={undefined}
              p_focusTF={undefined}
              p_leftTitle="Left"
              p_centerTitle="Center"
              p_rightTitle="Right"
              p_errorTF={undefined}
              f_onSelectCenter={this.onswitch_tws_to_center}
              f_onSelectLeft={this.onswitch_tws_to_left}
              f_onSelectRight={this.onswitch_tws_to_right}
              f_onClick={undefined}
              f_onKeyDownEnter={undefined}
              f_onKeyDownUpArrow={undefined}
              f_onKeyDownDownArrow={undefined}
            />
          </div>
          <div className="flex00a lrMedPad">
            {"TWS: " + s_twsState012}
          </div>
          <div className="flex00a" style={{flexBasis:"25em", marginLeft:"2em"}}>
            <CEGeneralReact.VerticalSwitch
              p_valueArray={[0,1,2]}
              p_displayArray={["Zero", "One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One One v", "Two"]}
              p_switchToMessageArray={undefined}
              p_switchToMessageButtonNamesArray={undefined}
              p_selectedValue={s_vsState012}
              p_rowHeightEm={2}
              p_sizeMultiplier={undefined}
              p_onColor={undefined}
              p_offColor={undefined}
              p_onTextFontClass={undefined}
              p_offTextFontClass={undefined}
              f_onSelect={this.onchange_vs_state_value}
            />
          </div>
          <div className="flex00a lrMedPad">
            {"VS: " + s_twsState012}
          </div>
          <div className="flex00a" style={{flexBasis:"5em"}} />
          <div className="flex00a">
            <CEGeneralReact.LoadingAnimation />
          </div>
          <div className="flex00a" style={{flexBasis:"5em"}} />
          <div className="flex00a medFullPad">
            <CEGeneralReact.GovWinLoadingAnimation />
          </div>
          <div className="flex00a" style={{flexBasis:"5em"}} />
          <div className="flex00a bgLighterGray medFullPad">
            <CEGeneralReact.GovWinLoadingAnimation />
          </div>
        </div>
        <div className="flex00a displayFlexRow medFullPad borderB1ddd">
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <CEGeneralReact.ImportDataFromCsvFileButtonWithFloatingBoxInterface
              p_buttonLabel="Test Import Single Column like Select Options"
              p_importTitle="Test Import Single Column like Select Options"
              p_instructionsHtmlOrUndefined={(<><div>{"Do This"}</div><div>{"Do That"}</div></>)}
              p_existingValuesArray={["Option1", "Option5"]}
              p_maxNumColumnsInCsvOrUndefined={1}
              p_maxNumColumnsToShow={1}
              p_combineAll2DDataInto1DTF={true}
              p_markAndPreventDuplicateEntriesTF={true}
              f_onClickImportCsvData={this.onclick_import_csv_data_test}
            />
          </div>
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <CEGeneralReact.ImportDataFromCsvFileButtonWithFloatingBoxInterface
              p_buttonLabel="Test Import 2 columns like Survey Questions"
              p_importTitle="Test Import 2 columns like Survey Questions"
              p_instructionsHtmlOrUndefined={undefined}
              p_existingValuesArray={["Question1", "Question5"]}
              p_maxNumColumnsInCsvOrUndefined={2}
              p_maxNumColumnsToShow={2}
              p_combineAll2DDataInto1DTF={false}
              p_markAndPreventDuplicateEntriesTF={false}
              f_onClickImportCsvData={this.onclick_import_csv_data_test}
            />
          </div>
        </div>
        <div className="flex00a displayFlexRow">
          <div className="flex00a" style={{flexBasis:"400px", margin:"0.5em"}}>
            <LibraryReact.CircleGraph
              p_categoriesArrayOfObjs={[{valueRaw:111,valueMaskPlainText:"111",color:"44c",label:"NY"},{valueRaw:11,valueMaskPlainText:"11",color:"c44",label:"Chi"}]}
              p_heightPx={200}
              p_titlesTF={true}
              p_legendTF={true}
              p_legendTitle="User Timezones"
              p_legendTotalValueLabel={(111+11)}
              p_fontSizePx={12}
              p_maxNumDecimals={1}
            />
          </div>
          <div className="flex00a displayFlexRow medFullPad borderB1ddd">
            {[1,1.5,2,2.5,3,3.5,4,4.5,5].map((m_num) =>
              <CEGeneralReact.RatingScoreBox1to5 p_rating={m_num} p_sizeEm={4} />
            )}
          </div>
        </div>
        <div className="flex00a medFullPad borderB1ddd">
          <div className="">
            {stateValue}
          </div>
          <div className="smallTopMargin">
            {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(stateValue, this.props.DatabaseMobx.c_genericPercentIntm1000to100FieldTypeObj)}
          </div>
          <div className="smallTopMargin" style={{width:"40em"}}>
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString="test_ceEditItemString"
              p_isContactFieldTF={false}
              p_fieldDisplayName="Test StateValue Field Display Name"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericPercentIntm1000to100FieldTypeObj}
              p_valueRaw={stateValue}
              p_valueMask={undefined}
              p_valueIsEditableTFU={true}
              p_valuesToNotIncludeArray={undefined}
              p_containerClass={undefined}
              p_fieldClass="fontBlue"
              p_fieldWidth="16em"
              p_fieldNowrapTF={undefined}
              p_fieldTitle={undefined}
              p_valueClass={undefined}
              p_valueMaxHeight={undefined}
              p_fieldValueVerticallyAlignedTF={undefined}
              p_floatingBoxTitle="Test Floating Box Title"
              f_onSaveChanged={this.onchange_state_value}
            />
          </div>
        </div>
        <div className="flex00a medFullPad borderB1ddd" style={{width:"20em"}}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiContactCompaniesWithUsMinus2AtTopFieldTypeObj}
            p_valueRaw={stateValue2}
            f_onChangeOrOnSelect={this.onchange_state_value2}
          />
          <div className="smallTopMargin">
            {stateValue2}
          </div>
          <div className="smallTopMargin">
            {JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(stateValue2)}
          </div>
        </div>
        <div className="tbMedMargin borderT1ddd" />
        <div className="medTopMargin" style={{width:"20em"}}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
            p_valueRaw={stateValue3}
            f_onChangeOrOnSelect={this.onchange_state_value3}
          />
        </div>
        <div className="smallTopMargin">
          {stateValue3 + " (" + typeof(stateValue3) + ")"}
        </div>
        <div className="medTopMargin" style={{width:"20em"}}>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="intp"
            p_fieldDisplayName="IntPos Test"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
            p_valueRaw={stateValue4}
            p_valueMask={undefined}
            p_valueIsEditableTFU={true}
            p_fieldWidth="5em"
            f_onSaveChanged={this.onchange_state_value4}
          />
        </div>
        <div className="smallTopMargin">
          {stateValue4 + " (" + typeof(stateValue4) + ")"}
        </div>
        <div className="flex00a borderB1ddd medFullPad bgLighterOrangeGradient">
          {s_xmlOut}
        </div>
        <div className="medFullPad">
          {lowercaseCodewordsArray.map((m_codeword) =>
            ((m_codeword === "") ? (
              <div className="tbMedMargin borderT1ddd" />
            ) : (
              <div className="displayFlexRow hoverLighterBlueGradient">
                <div className="flex00a" style={{flexBasis:"30em"}}>
                  <LibraryReact.Nowrap>{m_codeword}</LibraryReact.Nowrap>
                </div>
                <div className="flex11a lrPad">
                  {JSPHP.replace_all_codewords_in_xml_data_string(m_codeword, captureMap, undefined)}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="medFullPad">
          {teammateContractLowercaseCodewordsArray.map((m_codeword) =>
            ((m_codeword === "") ? (
              <div className="tbMedMargin borderT1ddd" />
            ) : (
              <div className="displayFlexRow hoverLighterBlueGradient">
                <div className="flex00a" style={{flexBasis:"30em"}}>
                  <LibraryReact.Nowrap>{m_codeword}</LibraryReact.Nowrap>
                </div>
                <div className="flex11a lrPad">
                  {JSPHP.replace_all_codewords_in_xml_data_string(m_codeword, captureMap, 1)}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="borderT1ddd medFullPad">
          {testExcelReportAllFieldsInSingleCaptureArrayOfObjs.map((m_excelCodewordObj) =>
            <div className="displayFlexRow hoverLighterBlueGradient">
              <div className="flex00a" style={{flexBasis:"30em"}}>
                <LibraryReact.Nowrap>{m_excelCodewordObj.codewordLowercaseWithoutModifiers}</LibraryReact.Nowrap>
              </div>
              <div className="flex00a" style={{flexBasis:"5em"}}>
                {((m_excelCodewordObj.codewordReplacementIsInvalidExcelDateTF) ? ("true1") : ("false0"))}
              </div>
              <div className="flex11a lrPad">
                {m_excelCodewordObj.codewordReplacement}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}));


const Test2MainContent = inject("CaptureExecMobx", "AdminIntegrationsMobx", "DatabaseMobx")(observer(
class Test2MainContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_actionDbNamesComma: ""
    }
  }
  onset = (i_val) => {this.setState({s_actionDbNamesComma:i_val})}
  render() {
    return(
      <div className="flex11a medFullPad yScroll">
        {"test"}
        <div style={{width:"20em"}}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.AdminIntegrationsMobx.c_selectIntegrationsLogActionsFieldTypeObj}
            p_valueRaw={this.state.s_actionDbNamesComma}
            p_tabIndex={2}
            f_onChangeOrOnSelect={this.onset}
          />
        </div>
      </div>
    );
  }
}));
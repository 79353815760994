//js code packages
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { observer, inject } from 'mobx-react';

//components and js functions
import * as JSFUNC from "../../Library/JSFUNC.js";
import * as LibraryReact from "../../Library/LibraryReact.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";

import * as CaptureExecReact from '../CaptureExec/CaptureExecReact.js';



export const RightPanelLauncher = inject("CaptureExecMobx", "RightPanelMobx", "UserMobx")(observer(
class RightPanelLauncher extends Component { //props: p_rightPanelCode1234
  onclick_launcher = () => {
    const p_rightPanelCode1234 = this.props.p_rightPanelCode1234;

    const o_closed0_u1_t2_n3 = this.props.RightPanelMobx.o_closed0_u1_t2_n3;
    const o_helpPanelIsOpenTF = this.props.RightPanelMobx.o_helpPanelIsOpenTF;

    if(p_rightPanelCode1234 === 4) { //separate help panel open/close on far right
      this.props.RightPanelMobx.a_set_help_panel_is_open_tf(!o_helpPanelIsOpenTF);
    }
    else { //other combined right panels 1,2,3
      const launchersPanelIsOpenTF = (p_rightPanelCode1234 === o_closed0_u1_t2_n3);
      this.props.RightPanelMobx.a_set_open_panel((launchersPanelIsOpenTF) ? (0) : (p_rightPanelCode1234));
    }
  }

  render() {
    const p_rightPanelCode1234 = this.props.p_rightPanelCode1234;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_closed0_u1_t2_n3 = this.props.RightPanelMobx.o_closed0_u1_t2_n3;
    const o_helpPanelIsOpenTF = this.props.RightPanelMobx.o_helpPanelIsOpenTF;
    const c_numNotifications = this.props.RightPanelMobx.c_numNotifications;
    const c_numUnreadNotifications = this.props.RightPanelMobx.c_numUnreadNotifications;
    const c_myActiveTasksArrayOfObjs = this.props.RightPanelMobx.c_myActiveTasksArrayOfObjs;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    var width = undefined;
    var bgClass = undefined;
    var label = undefined;
    var launcherTitle = undefined;
    var numActiveTasks = c_myActiveTasksArrayOfObjs.length;
    var atLeast1ActiveTaskTF = (numActiveTasks > 0);
    var atLeast1NotificationTF = (c_numNotifications > 0);
    var atLeast1UnreadNotificationTF = (c_numUnreadNotifications > 0);
    if(p_rightPanelCode1234 === 1) {
      width = ((c_isMobileOrTabletTF) ? ("3em") : ("18em")); //user launcher is wider to fit the name
      bgClass = ((o_closed0_u1_t2_n3 === 1) ? ("bgUserPanelLauncherOpen") : (""));
      label = ((c_isMobileOrTabletTF) ? ("U") : (c_combinedUserObj.fullName));
      launcherTitle = "Click to open the User Panel on the right side";
      launcherTitle += "\n\nInside you can:";
      launcherTitle += "\n - Switch between multiple logins";
      launcherTitle += "\n - Edit User Settings (font size, start tab, etc)";
      launcherTitle += "\n - Change Password";
      launcherTitle += "\n - Create/receive Support Tickets";
      launcherTitle += "\n - Log out of " + c_productStylingObj.productName;
    }
    else if(p_rightPanelCode1234 === 2) {
      width = ((c_isMobileOrTabletTF) ? ("3em") : ("10em"));
      bgClass = ((o_closed0_u1_t2_n3 === 2) ? ("bgTasksPanelLauncherOpen") : (""));
      label = ((c_isMobileOrTabletTF) ? ("T") : ("Tasks"));
      launcherTitle = "Click to open the Tasks Panel on the right side";
      if(atLeast1ActiveTaskTF) {
        launcherTitle += "\n(" + numActiveTasks + " Active " + JSFUNC.plural(numActiveTasks, "Task", "Tasks") + " assigned to me)";
      }
    }
    else if(p_rightPanelCode1234 === 3) {
      width = ((c_isMobileOrTabletTF) ? ("3em") : ("10em"));
      bgClass = ((o_closed0_u1_t2_n3 === 3) ? ("bgNotificationsPanelLauncherOpen") : (""));
      label = ((c_isMobileOrTabletTF) ? ("N") : ("Notifications"));
      launcherTitle = "Click to open the Notifications Panel on the right side";
      if(atLeast1UnreadNotificationTF) {
        launcherTitle += "\n(" + c_numUnreadNotifications + " unread " + JSFUNC.plural(c_numUnreadNotifications, "Notification", "Notifications") + ")";
        launcherTitle += "\n(" + c_numNotifications + " total " + JSFUNC.plural(c_numNotifications, "Notification", "Notifications") + ")";
      }
      else if(atLeast1NotificationTF) {
        launcherTitle += "\n(" + c_numNotifications + " " + JSFUNC.plural(c_numNotifications, "Notification", "Notifications") + ")";
      }
    }
    else if(p_rightPanelCode1234 === 4) {
      width = ((c_isMobileOrTabletTF) ? ("3em") : ("5em"));
      bgClass = ((o_helpPanelIsOpenTF) ? ("bgHelpPanelLauncherOpen") : (""));
      label = ((c_isMobileOrTabletTF) ? ("H") : ("Help"));
      launcherTitle = "Click to open the Help Panel on the right side";
      launcherTitle += "\n - The help topic displayed matches what is currently on the page";
      launcherTitle += "\n - At the top is always 'General Top Row Panels' describing the top bar";
      launcherTitle += "\n - Below is the page topic with descriptions in pictures and words";
    }

    return(
      <div
        className={"flex00a displayFlexColumnHcVc topBarPanelLauncherLeftBorder hoverLightestGrayGradient cursorPointer lrPad textCenter notificationContainer " + bgClass}
        style={{flexBasis:width}}
        title={launcherTitle}
        onClick={this.onclick_launcher}>
        <LibraryReact.Nowrap p_fontClass={c_productStylingObj.rightPanelLauncherLabelFontClass}>
          {label}
        </LibraryReact.Nowrap>
        {((p_rightPanelCode1234 === 1) && !c_isMobileOrTabletTF) &&
          <>
            <LibraryReact.Nowrap>{c_combinedUserObj.powerDisplayName}</LibraryReact.Nowrap>
            <LibraryReact.Nowrap>{c_combinedUserObj.divisionName}</LibraryReact.Nowrap>
          </>
        }
        {((p_rightPanelCode1234 === 2) && atLeast1ActiveTaskTF) &&
          <CEGeneralReact.NotificationIcon
            p_type="gray"
            p_number={numActiveTasks}
          />
        }
        {((p_rightPanelCode1234 === 3) && atLeast1NotificationTF) &&
          <CEGeneralReact.NotificationIcon
            p_type={((atLeast1UnreadNotificationTF) ? ("red") : ("gray"))}
            p_number={((atLeast1UnreadNotificationTF) ? (c_numUnreadNotifications) : (c_numNotifications))}
          />
        }
      </div>
    );
  }
}));


export const DesktopRightPanel = inject("CaptureExecMobx", "RightPanelMobx")(observer(
class DesktopRightPanel extends Component {
  render() {
    const c_topBarHeight = this.props.CaptureExecMobx.c_topBarHeight;
    const c_desktopRightPanelIsOpenTF = this.props.RightPanelMobx.c_desktopRightPanelIsOpenTF;
    const c_currentDesktopRightPanelWidthEm = this.props.RightPanelMobx.c_currentDesktopRightPanelWidthEm;
    const c_currentDesktopHelpPanelWidthEm = this.props.RightPanelMobx.c_currentDesktopHelpPanelWidthEm;

    if(!c_desktopRightPanelIsOpenTF) {
      return(null);
    }

    return(
      <div
        className="positionAbsolute b0 displayFlexColumn"
        style={{top:c_topBarHeight, right:c_currentDesktopHelpPanelWidthEm + "em", width:c_currentDesktopRightPanelWidthEm + "em"}}>
        <RightPanelContent />
      </div>
    );
  }
}));


export const DesktopHelpPanel = inject("CaptureExecMobx", "RightPanelMobx")(observer(
class DesktopHelpPanel extends Component {
  render() {
    const c_desktopHelpPanelIsOpenTF = this.props.RightPanelMobx.c_desktopHelpPanelIsOpenTF;
    const c_currentDesktopHelpPanelWidthEm = this.props.RightPanelMobx.c_currentDesktopHelpPanelWidthEm;

    if(!c_desktopHelpPanelIsOpenTF) {
      return(null);
    }

    return(
      <div
        className="positionAbsolute t0 r0 b0 displayFlexColumn"
        style={{width:c_currentDesktopHelpPanelWidthEm + "em"}}>
        <HelpPanelContent />
      </div>
    );
  }
}));


export const MobileOrTabletFloatingRightPanelOrHelpPanel = inject("CaptureExecMobx", "RightPanelMobx")(observer(
class MobileOrTabletFloatingRightPanelOrHelpPanel extends Component {
  render() {
    const c_topBarHeight = this.props.CaptureExecMobx.c_topBarHeight;
    const o_helpPanelIsOpenTF = this.props.RightPanelMobx.o_helpPanelIsOpenTF;
    const combinedRightAndHelpMobileFloatingPanelIsOpenTF = this.props.RightPanelMobx.c_combinedRightAndHelpMobileFloatingPanelIsOpenTF;

    if(!combinedRightAndHelpMobileFloatingPanelIsOpenTF) {
      return(null);
    }

    return(
      <div
        className="positionFixed b0 r0 displayFlexColumn rightPanelAnimationMobile"
        style={{zIndex:5, top:c_topBarHeight, width:"90vw"}}>
        {(o_helpPanelIsOpenTF) ? (
          <HelpPanelContent />
        ) : (
          <RightPanelContent />
        )}
      </div>
    );
  }
}));






//========================================================================================================================
//========================================================================================================================
//========================================================================================================================
//========================================================================================================================
//========================================================================================================================





const RightPanelContent = inject("RightPanelMobx")(observer(
class RightPanelContent extends Component {
  onclick_close_panel = () => {
    this.props.RightPanelMobx.a_set_open_panel(0);
  }

  render() {
    const o_closed0_u1_t2_n3 = this.props.RightPanelMobx.o_closed0_u1_t2_n3;
    const rightPanelIsOpenTF = this.props.RightPanelMobx.c_rightPanelIsOpenTF;

    //panel is closed, do not draw anything in this space
    if(!rightPanelIsOpenTF) {
      return(null);
    }

    var panelName = "";
    var topBorderColor = "666";
    var rightPanelContentComponent = null;
    if(o_closed0_u1_t2_n3 === 1) {
      panelName = "User Panel";
      topBorderColor = "005da3";
      rightPanelContentComponent = (<UserPanel />);
    }
    else if(o_closed0_u1_t2_n3 === 2) {
      panelName = "Tasks Panel";
      topBorderColor = "274";
      rightPanelContentComponent = (<TasksPanel />);
    }
    else if(o_closed0_u1_t2_n3 === 3) {
      panelName = "Notifications Panel";
      topBorderColor = "884";
      rightPanelContentComponent = (<NotificationsPanel />);
    }

    return(
      <RightPanelOrHelpPanelContentShell
        p_topBorderColor={topBorderColor}
        p_panelTitle={panelName}
        f_onClickClose={this.onclick_close_panel}>
        {rightPanelContentComponent}
      </RightPanelOrHelpPanelContentShell>
    );
  }
}));


function RightPanelOrHelpPanelContentShell(props) { //props:  p_topBorderColor, p_panelTitle, f_onClickClose, children
  const topBorderColor = props.p_topBorderColor;
  const panelTitle = props.p_panelTitle;

  return(
    <div
      className="flex11a displayFlexColumn bgPanelLightGray"
      style={{borderTop:"solid 4px #" + topBorderColor, borderLeft:"solid 2px #bbb"}}>
      <div className="flex00a displayFlexRow" style={{borderBottom:"solid 1px #ddd"}}>
        <div className="flex11a displayFlexRowVc smallFullPad">
          <LibraryReact.Nowrap p_fontClass="font13 fontBold fontTextLighter">
            {panelTitle}
          </LibraryReact.Nowrap>
        </div>
        <div
          className="flex00a displayFlexColumnHcVc hoverLightestGrayGradient cursorPointer"
          style={{flexBasis:"3em", borderLeft:"solid 1px #ddd"}}
          onClick={props.f_onClickClose}>
          <font className="font13">
            {"\u2715"}
          </font>
        </div>
      </div>
      {props.children}
    </div>
  );
}


const UserPanel = inject("RightPanelMobx")(observer(
class UserPanel extends Component {
  onclick_subpanel_return = () => {
    this.props.RightPanelMobx.a_subpanel_return_click();
  }

  render() {
    const userPanelTabNameOpen = this.props.RightPanelMobx.o_userTabNameOpen;
    const singleTicketIsOpenTF = this.props.RightPanelMobx.c_ticketsSubpanelSingleTicketIsOpenTF;

    //subpanel
    if(JSFUNC.in_array(userPanelTabNameOpen, ["Settings", "Message My Admin", "Change Password", "Support Ticket for BIT Solutions"])) {
      var subPanelTitle = userPanelTabNameOpen;
      var subPanelComponent = null;
      var subPanelFullYScrollTF = true;
      if(userPanelTabNameOpen === "Settings") {
        subPanelComponent = (<UserPanelSettings />);
      }
      else if(userPanelTabNameOpen === "Message My Admin") {
        subPanelComponent = (<MessageMyAdmin />);
      }
      else if(userPanelTabNameOpen === "Change Password") {
        subPanelComponent = (<CaptureExecReact.ChangePassword p_adminOrForcedOrUserFlag="user" />);
      }
      else if(userPanelTabNameOpen === "Support Ticket for BIT Solutions") {
        if(!singleTicketIsOpenTF) {
          subPanelComponent = (<TicketsPanel />);
        }
        else {
          subPanelTitle = "Ticket Responses";
          subPanelComponent = (<TicketsPanelResponsesPanel />);
        }
        subPanelFullYScrollTF = false;
      }

      return(
        <div className="flex11a displayFlexColumn">
          <div
            className="flex00a displayFlexRow hoverLightestGrayGradient cursorPointer"
            style={{flexBasis:"2em", borderBottom:"solid 1px #ddd", backgroundColor:"#f6f6f6"}}
            title="Return to User Panel Main Menu"
            onClick={this.onclick_subpanel_return}>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em", borderRight:"solid 1px #ddd"}}>
              <font className="font13 fontTextLighter" style={{transform:"rotate(180deg)"}}>
                {"\u2794"}
              </font>
            </div>
            <div className="flex11a displayFlexRowVc lrMedPad">
              <LibraryReact.Nowrap p_fontClass="font11 fontBold fontItalic fontTextLighter">
                {subPanelTitle}
              </LibraryReact.Nowrap>
            </div>
          </div>
          <div className={"flex11a displayFlexColumn " + ((subPanelFullYScrollTF) ? ("yScroll yScrollBottomPad smallTopPad lrPad") : (""))}>
            {subPanelComponent}
          </div>
        </div>
      );
    }

    //main menu
    return(
      <UserPanelMainMenuTabs />
    );
  }
}));



const UserPanelMainMenuTabs = inject("RightPanelMobx")(observer(
class UserPanelMainMenuTabs extends Component {
  render() {
    const c_userPanelTabNamesArray = this.props.RightPanelMobx.c_userPanelTabNamesArray;

    return(
      <div className="flex11a yScroll">
        {c_userPanelTabNamesArray.map((m_userPanelTabName) =>
          <UserPanelMainMenuSingleTab key={m_userPanelTabName} p_tabName={m_userPanelTabName} />
        )}
      </div>
    );
  }
}));


const UserPanelMainMenuSingleTab = inject("RightPanelMobx")(observer(
class UserPanelMainMenuSingleTab extends Component { //props: p_tabName
  onclick_tab = () => {
    if(this.props.p_tabName !== this.props.RightPanelMobx.o_userTabNameOpen) { //clicking on the tab you are already on does not do anything
      this.props.RightPanelMobx.a_tab_click(this.props.p_tabName);
    }
  }

  render() {
    const p_tabName = this.props.p_tabName;

    const c_numTicketsTabNotifications = this.props.RightPanelMobx.c_numTicketsTabNotifications;

    if(p_tabName === "Switch Login") {
      return(
        <div className="borderTB bevelBorderColors bgPanelSwitchLoginTab smallFullPad">
          <div className="smallBottomMargin">
            <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLight">
              {p_tabName}
            </LibraryReact.Nowrap>
          </div>
          <CaptureExecReact.MultiLoginSelection />
        </div>
      );
    }

    return(
      <div
        className="displayFlexColumnVc borderTB bevelBorderColors hoverLightestGrayGradient cursorPointer lrPad notificationContainer"
        style={{height:"5em"}}
        onClick={this.onclick_tab}>
        <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLight">
          {p_tabName}
        </LibraryReact.Nowrap>
        {(p_tabName === "Tickets") &&
          <CEGeneralReact.Notification p_num={c_numTicketsTabNotifications} />
        }
      </div>
    );
  }
}));


const UserPanelSettings = inject("CaptureExecMobx", "DatabaseMobx", "UserMobx")(observer(
class UserPanelSettings extends Component {
  onselect_font_size = (i_newValue) => {
    this.props.UserMobx.a_update_user_per_email_field("font_size_px", i_newValue, "i");
  }

  onselect_right_panel_width_em = (i_newValue) => {
    this.props.UserMobx.a_update_user_per_email_field("right_panel_width_em", i_newValue, "i");
  }

  onswitch_notification_email = () => {
    const updatedNotificationEmail01 = ((this.props.UserMobx.c_combinedUserObj.notification_email_01 === 0) ? (1) : (0));
    this.props.UserMobx.a_update_user_per_email_field("notification_email_01", updatedNotificationEmail01, "i");
  }

  onselect_starting_navigation_tab_after_login = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("start_tab_name", i_newValue, "s");
  }

  onselect_archives_captures_preference = (i_newValue) => {
    this.props.UserMobx.a_update_user_per_email_field("archived_captures_preference", i_newValue, "s");
  }

  onselect_capture_card_min_height_em = (i_newValue) => {
    var fieldNamesArray = ["capture_card_min_height_em"];
    var valuesArray = [i_newValue];
    var idsbArray = ["i"];
    if(this.props.UserMobx.c_combinedUserObj.capture_card_max_height_em < i_newValue) {
      fieldNamesArray.push("capture_card_max_height_em");
      valuesArray.push(i_newValue);
      idsbArray.push("i");
    }
    this.props.UserMobx.a_update_user_per_email_field(fieldNamesArray, valuesArray, idsbArray);
  }

  onselect_capture_card_max_height_em = (i_newValue) => {
    var fieldNamesArray = ["capture_card_max_height_em"];
    var valuesArray = [i_newValue];
    var idsbArray = ["i"];
    if(this.props.UserMobx.c_combinedUserObj.capture_card_min_height_em > i_newValue) {
      fieldNamesArray.push("capture_card_min_height_em");
      valuesArray.push(i_newValue);
      idsbArray.push("i");
    }
    this.props.UserMobx.a_update_user_per_email_field(fieldNamesArray, valuesArray, idsbArray);
  }

  onswitch_dates_card_cols = () => {
    const updatedDatesCardCols01 = ((this.props.UserMobx.c_combinedUserObj.dates_card_three_cols_0_single_col_1 === 0) ? (1) : (0));
    this.props.UserMobx.a_update_user_per_email_field("dates_card_three_cols_0_single_col_1", updatedDatesCardCols01, "i");
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_leftNavSelectStartTabFieldTypeObj = this.props.CaptureExecMobx.c_leftNavSelectStartTabFieldTypeObj;
    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    const anyOpenCaptureSettingsTF = (c_productStylingObj.userPanelSettingsOpenCaptureSnapshotCardsMinHeightTF || c_productStylingObj.userPanelSettingsOpenCaptureSnapshotCardsMaxHeightTF || c_productStylingObj.userPanelSettingsDatesCardLayoutTF);

    const labelClass = "smallTopMargin microBottomMargin font11 fontBold fontTextLight";
    const inputContainerClass = "bigBottomMargin";
    const switchSizeEm = 3.5;

    const swsOptionsObj = undefined;

    const fontSizeValueArray = [8,9,10,11,12,13,14,15,16,17,18,19,20,22,24,26,28];
    const fontSizeDisplayArray = ["8 (very small)","9","10 (small)","11","12 (default)","13","14","15","16 (large)","17","18","19","20","22","24","26","28 (very large)"];
    const selectWithSearchDataObj1 = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Font Size", fontSizeValueArray, false, fontSizeDisplayArray, swsOptionsObj);
    const selectFontSizeFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj1);

    const rightPanelWidthValueArray = [24,26,28,30,32,34,36,38,40,42,44,46,48,50];
    const rightPanelWidthDisplayArray = ["24","26","28 (default)","30","32","34","36","38","40","42","44","46","48","50"];
    const selectWithSearchDataObj2 = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Right Panel Width", rightPanelWidthValueArray, false, rightPanelWidthDisplayArray, swsOptionsObj);
    const selectRightPanelWidthFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj2);

    const captureCardMinHeightValueArray = [15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
    const captureCardMinHeightDisplayArray = ["15","16","17","18","19","20 (default)","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40"];
    const selectWithSearchDataObj3 = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Card Min Height", captureCardMinHeightValueArray, false, captureCardMinHeightDisplayArray, swsOptionsObj);
    const selectCaptureCardMinHeightFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj3);

    const captureCardMaxHeightValueArray = [15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
    const captureCardMaxHeightDisplayArray = ["15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35 (default)","36","37","38","39","40"];
    const selectWithSearchDataObj4 = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Card Max Height", captureCardMaxHeightValueArray, false, captureCardMaxHeightDisplayArray, swsOptionsObj);
    const selectCaptureCardMaxHeightFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj4);

    const archivedCapturesPreferenceValueArray = ["alwaysLoadArchived", "noLoadArchivedWithPrompt", "noLoadArchivedNoPrompt"];
    const archivedCapturesPreferenceDisplayArray = ["Always load all captures, including all archived captures", "Prompt me every time I log in if I want to load archived captures", "Do not load archived captures and do not prompt me about it"];
    const selectWithSearchDataObj5 = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Archived Capture Preference", archivedCapturesPreferenceValueArray, true, archivedCapturesPreferenceDisplayArray, swsOptionsObj);
    const selectArchivedCapturesPreferenceFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj5);

    return(
      <>
        <SettingsDividerLabel p_label="System" />
        <div className={labelClass}>{"System Font Size:"}</div>
        <div className={inputContainerClass}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={selectFontSizeFieldTypeObj}
            p_valueRaw={c_combinedUserObj.font_size_px}
            p_tabIndex={1}
            f_onChangeOrOnSelect={this.onselect_font_size}
          />
        </div>
        {(c_productStylingObj.userPanelSettingsRightPanelWidthTF) &&
          <>
            <div className={labelClass}>{"Right Panel Width:"}</div>
            <div className={inputContainerClass}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={selectRightPanelWidthFieldTypeObj}
                p_valueRaw={c_combinedUserObj.right_panel_width_em}
                p_tabIndex={2}
                f_onChangeOrOnSelect={this.onselect_right_panel_width_em}
              />
            </div>
          </>
        }
        <div className={labelClass}>{"Notification Emails:"}</div>
        <div className={inputContainerClass}>
          <CEGeneralReact.SwitchWithTextAndConfirmBox
            p_isOnTF={(c_combinedUserObj.notification_email_01 === 1)}
            p_sizeEm={switchSizeEm}
            p_onText="Receive an email for each system Notification"
            p_offText="Only receive in-system Notifications"
            p_tabIndex={3}
            f_onSwitch={this.onswitch_notification_email}
          />
        </div>
        <div className={labelClass}>{"Starting Navigation Tab After Login:"}</div>
        <div className={inputContainerClass}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_leftNavSelectStartTabFieldTypeObj}
            p_valueRaw={c_combinedUserObj.start_tab_name}
            p_tabIndex={4}
            f_onChangeOrOnSelect={this.onselect_starting_navigation_tab_after_login}
          />
        </div>
        {(c_productStylingObj.userPanelSettingsArchivedCapturesPreferenceTF) &&
          <>
            <div className={labelClass}>{"Archived Captures Preference:"}</div>
            <div className={inputContainerClass}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={selectArchivedCapturesPreferenceFieldTypeObj}
                p_valueRaw={c_combinedUserObj.archived_captures_preference}
                p_tabIndex={5}
                f_onChangeOrOnSelect={this.onselect_archives_captures_preference}
              />
            </div>
          </>
        }
        {(anyOpenCaptureSettingsTF) &&
          <>
            <div className="hugeTopMargin hugeBottomMargin" />
            <SettingsDividerLabel p_label="Open Capture" />
          </>
        }
        {(c_productStylingObj.userPanelSettingsOpenCaptureSnapshotCardsMinHeightTF) &&
          <>
            <div className={labelClass}>{"Open Capture Snapshot Cards Min Height:"}</div>
            <div className={inputContainerClass}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={selectCaptureCardMinHeightFieldTypeObj}
                p_valueRaw={c_combinedUserObj.capture_card_min_height_em}
                p_tabIndex={7}
                f_onChangeOrOnSelect={this.onselect_capture_card_min_height_em}
              />
            </div>
          </>
        }
        {(c_productStylingObj.userPanelSettingsOpenCaptureSnapshotCardsMaxHeightTF) &&
          <>
            <div className={labelClass}>{"Open Capture Snapshot Cards Max Height:"}</div>
            <div className={inputContainerClass}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={selectCaptureCardMaxHeightFieldTypeObj}
                p_valueRaw={c_combinedUserObj.capture_card_max_height_em}
                p_tabIndex={8}
                f_onChangeOrOnSelect={this.onselect_capture_card_max_height_em}
              />
            </div>
          </>
        }

        {(c_productStylingObj.userPanelSettingsDatesCardLayoutTF) &&
          <>
            <div className={labelClass}>{c_cardNameDates + " Card Layout:"}</div>
            <div className={inputContainerClass}>
              <CEGeneralReact.SwitchWithTextAndConfirmBox
                p_isOnTF={(c_combinedUserObj.dates_card_three_cols_0_single_col_1 === 0)}
                p_sizeEm={switchSizeEm}
                p_offColor="66e"
                p_onText="Upcoming/Not Set/Past columns with automatic sorting (default)"
                p_offText="Single column of Dates in admin defined order"
                p_tabIndex={9}
                f_onSwitch={this.onswitch_dates_card_cols}
              />
            </div>
          </>
        }
      </>
    );
  }
}));

function SettingsDividerLabel(props) { //props: p_label
  return(
    <div className="tbMicroMargin tbMicroPad borderT1ddd borderB1ddd bgLightGray textCenter">
      <font className="font14 fontBold fontItalic fontDarkBlue">
        {props.p_label}
      </font>
    </div>
  );
}


const MessageMyAdmin = inject("RightPanelMobx", "DatabaseMobx")(observer(
class MessageMyAdmin extends Component {
  componentDidMount() {
    const c_myAdminUsersArrayOfObjs = this.props.RightPanelMobx.c_myAdminUsersArrayOfObjs;

    //select first admin user in select
    var defaultFirstSelectedAdminUserID = -1;
    if(c_myAdminUsersArrayOfObjs.length > 0) {
      defaultFirstSelectedAdminUserID = c_myAdminUsersArrayOfObjs[0].user_id;
    }
    this.onselect_admin_user(defaultFirstSelectedAdminUserID);

    //clear message text
    this.props.RightPanelMobx.a_message_my_admin_set_text("");
  }

  onselect_admin_user = (i_userID) => {
    this.props.RightPanelMobx.a_message_my_admin_set_my_admin_selected_user_id(i_userID);
  }

  render() {
    const o_myAdminSelectedUserID = this.props.RightPanelMobx.o_myAdminSelectedUserID;
    const c_myAdminUsersArrayOfObjs = this.props.RightPanelMobx.c_myAdminUsersArrayOfObjs;

    const numAdmins = c_myAdminUsersArrayOfObjs.length;

    if(numAdmins === 0) {
      return(
        <div className="medFullMargin border1bbb bgLightesterGray medFullPad textCenter">
          <font className="fontItalic fontTextLight">
            {"Your CaptureExec system does not have any users designated as Admins yet"}
          </font>
        </div>
      );
    }

    var adminSelectComponent = null;
    if(numAdmins === 1) {
      adminSelectComponent = (
        <div className="">
          <font className="">
            {c_myAdminUsersArrayOfObjs[0].fullName}
          </font>
        </div>
      );
    }
    else {
      var adminUsersValueArray = [];
      var adminUsersDisplayArray = [];
      for(let userObj of c_myAdminUsersArrayOfObjs) {
        adminUsersValueArray.push(userObj.user_id);
        adminUsersDisplayArray.push(userObj.fullName + " [" + userObj.divisionName + "]");
      }
      const swsOptionsObj = undefined;
      const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Admin User", adminUsersValueArray, false, adminUsersDisplayArray, swsOptionsObj);
      const selectAdminUserFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

      adminSelectComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={selectAdminUserFieldTypeObj}
          p_valueRaw={o_myAdminSelectedUserID}
          p_tabIndex={2}
          f_onChangeOrOnSelect={this.onselect_admin_user}
        />
      );
    }

    return(
      <>
        <div className="smallBottomMargin">
          <font className="font12 fontBold fontTextLighter">
            {"Your CaptureExec Admin"}
          </font>
        </div>
        {adminSelectComponent}
        <div className="hugeTopMargin smallBottomMargin">
          <font className="font12 fontBold fontTextLighter">
            {"Message to Send"}
          </font>
        </div>
        <MessageMyAdminMessageWithSendButton />
      </>
    );
  }
}));

const MessageMyAdminMessageWithSendButton = inject("RightPanelMobx", "DatabaseMobx")(observer(
class MessageMyAdminMessageWithSendButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_sentMessageTF: false
    };
  }

  onchange_message = (i_newValue) => {
    this.props.RightPanelMobx.a_message_my_admin_set_text(i_newValue);
  }

  onclick_send_message_to_my_admin = () => {
    const o_myAdminSelectedUserID = this.props.RightPanelMobx.o_myAdminSelectedUserID;
    const o_messageMyAdminText = this.props.RightPanelMobx.o_messageMyAdminText;

    this.setState({s_sentMessageTF:true});

    const captureID = -1; //general task
    const assignedToUserIDsComma = JSFUNC.convert_array_to_comma_list([o_myAdminSelectedUserID]);
    const dateDue = JSFUNC.now_date();
    const dateCompleted = JSFUNC.blank_date();
    const important01 = 0;
    const description = o_messageMyAdminText;
    const preReminderDays = -1;
    const postReminderDays = -1;
    this.props.RightPanelMobx.a_tasks_insert_new_task(captureID, assignedToUserIDsComma, dateDue, dateCompleted, important01, description, preReminderDays, postReminderDays);
  }

  render() {
    const s_sentMessageTF = this.state.s_sentMessageTF;

    const o_messageMyAdminText = this.props.RightPanelMobx.o_messageMyAdminText;
    const c_messageMyAdminSelectedAdminFullName = this.props.RightPanelMobx.c_messageMyAdminSelectedAdminFullName;
    const c_cardNameDetails = this.props.RightPanelMobx.c_cardNameDetails;
    const c_cardNameDealShaping = this.props.RightPanelMobx.c_cardNameDealShaping;

    const messageIsBlankTF = (o_messageMyAdminText === "");

    return(
      <>
        <div className="smallBottomMargin">
          <LibraryReact.Textarea
            p_value={o_messageMyAdminText}
            p_styleObj={{height:"20vh"}}
            p_tabIndex={1}
            p_placeholder={"Common requests to your Admin might be to update your name or email, to add a new field to the " + c_cardNameDetails + " card, or edit the text of a " + c_cardNameDealShaping + " Question, or even to add more relevant options to choose from within a certain 'Select' type field."}
            f_onChange={this.onchange_message}
          />
        </div>
        {(!s_sentMessageTF) ? (
          <div className="displayFlexRow">
            <div className="flex11a" />
            <div className="flex00a">
              <CEGeneralReact.CEButton
                p_type={((messageIsBlankTF) ? ("blueDisabled") : ("blue"))}
                p_text="Submit New Ticket"
                p_tabIndex={3}
                p_title={((messageIsBlankTF) ? ("Fill out your request to submit to your Admin") : (undefined))}
                f_onClick={this.onclick_send_message_to_my_admin}
              />
            </div>
          </div>
        ) : (
          <CEGeneralReact.Message
            p_messageObj={JSFUNC.message_obj("success", "Message successfully sent to " + c_messageMyAdminSelectedAdminFullName)}
          />
        )}
      </>
    );
  }
}));


const TicketsPanel = inject("RightPanelMobx")(observer(
class TicketsPanel extends Component {
  render() {
    return(
      <>
        <div key="newTicket" className="flex00a">
          <TicketsPanelNewTicket />
        </div>
        <div key="viewLabel" className="flex00a smallTopMargin lrPad">
          <div className="borderTB bevelBorderColors medBottomMargin"></div>
          <div className="font12 fontBold fontTextLighter">
            {"View Ticket Responses"}
          </div>
        </div>
        <div key="tickets" className="flex11a displayFlexColumn yScroll smallTopMargin smallBottomMargin lrPad">
          <div className="smallBottomMargin fontItalic fontTextLighter">
            {"Open Tickets (" + this.props.RightPanelMobx.c_numOpenTickets + ")"}
          </div>
          {this.props.RightPanelMobx.c_userTicketsArrayOfObjs.map((m_ticketObj) => (m_ticketObj.closed0_open1 === 1) &&
            <TicketsPanelSingleTicketInList key={m_ticketObj.id} p_ticketObj={m_ticketObj} />
          )}
          <div className="hugeTopMargin"></div>
          <div className="smallTopMargin smallBottomMargin fontItalic fontTextLighter">
            {"Closed Tickets (" + this.props.RightPanelMobx.c_numClosedTickets + ")"}
          </div>
          {this.props.RightPanelMobx.c_userTicketsArrayOfObjs.map((m_ticketObj) => (m_ticketObj.closed0_open1 === 0) &&
            <TicketsPanelSingleTicketInList key={m_ticketObj.id} p_ticketObj={m_ticketObj} />
          )}
        </div>
      </>
    );
  }
}));


const TicketsPanelNewTicket = inject("RightPanelMobx", "DatabaseMobx")(observer(
class TicketsPanelNewTicket extends Component {
  onselect_priority = (i_newValue) => { this.props.RightPanelMobx.a_new_ticket_select_priority(i_newValue); }
  onchange_description = (i_newValue) => { this.props.RightPanelMobx.a_new_ticket_change_description(i_newValue); }
  onclick_submit_new_ticket = () => { this.props.RightPanelMobx.a_new_ticket_submit(); }

  render() {
    const priorityValueArray = [1, 2, 3, 4];
    const priorityDisplayArray = ["Enhancement Idea", "High Priority Issue", "Medium Priority Issue", "Low Priority Issue"];
    const priorityColorArray = ["ffffff", "ffbbaa", "e9e988", "99eeaa"];
    const swsOptionsObj = {treeIDArray:"00", colorArray:priorityColorArray};
    const ticketPrioritySwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Ticket Priority", priorityValueArray, false, priorityDisplayArray, swsOptionsObj);
    const selectTicketPriorityFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", ticketPrioritySwsDataObj);

    return(
      <div className="smallFullPad">
        <div>
          <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLighter">
            {"Submit a New Ticket"}
          </LibraryReact.Nowrap>
        </div>
        <div className="displayFlexRowVc flexWrap smallTopMargin">
          <div className="flex00a rMargin">
            {"Priority:"}
          </div>
          <div className="flex11a">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={selectTicketPriorityFieldTypeObj}
              p_valueRaw={this.props.RightPanelMobx.o_newTicketObj.priorityCode}
              p_tabIndex={1}
              p_errorTF={this.props.RightPanelMobx.o_newTicketObj.priorityErrorTF}
              f_onChangeOrOnSelect={this.onselect_priority}
            />
          </div>
        </div>
        <div className="smallTopMargin">
          <LibraryReact.Textarea
            p_value={this.props.RightPanelMobx.o_newTicketObj.description}
            p_styleObj={{height:"20vh"}}
            p_tabIndex={2}
            p_placeholder="Describe any questions, issues, or ideas that you have with the CaptureExec system here!  A notification will appear when we have replied."
            p_errorTF={this.props.RightPanelMobx.o_newTicketObj.descriptionErrorTF}
            f_onChange={this.onchange_description}
          />
        </div>
        <div className="displayFlexRow smallTopMargin">
          <div className="flex11a" />
          <div className="flex00a">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Submit New Ticket"
              p_tabIndex={3}
              f_onClick={this.onclick_submit_new_ticket}
            />
          </div>
        </div>
        <CEGeneralReact.Message p_messageObj={this.props.RightPanelMobx.o_newTicketObj.messageObj} />
      </div>
    );
  }
}));


const TicketsPanelSingleTicketInList = inject("RightPanelMobx")(observer(
class TicketsPanelSingleTicketInList extends Component {
  onclick_view_single_ticket = () => { this.props.RightPanelMobx.a_view_ticket_click(this.props.p_ticketObj.id); }

  render() {
    const bgTicketClass = ((this.props.p_ticketObj.closed0_open1 === 1) ? ("bgTicketOpen") : ("bgTicketClosed"));
    return(
      <div
        className={"flex00a displayFlexRow border bevelBorderColors hoverLighterBlueGradient cursorPointer " + bgTicketClass}
        style={{flexBasis:"5.3em"}}
        onClick={this.onclick_view_single_ticket}>
        <TicketPriorityCell p_priorityCode1234={this.props.p_ticketObj.priority_code} />
        <div className="flex11a displayFlexColumn smallFullPad notificationContainer">
          <div className="flex00a textRight fontItalic fontTextLighter">
            {this.props.p_ticketObj.dateMask}
          </div>
          <div className="flex11a displayFlexRowVc">
            <LibraryReact.MaxHeightWrap p_maxHeight="3.1em">
              {this.props.p_ticketObj.initial_message}
            </LibraryReact.MaxHeightWrap>
          </div>
          <CEGeneralReact.Notification p_num={this.props.RightPanelMobx.c_ticketNumResponsesMap.get(this.props.p_ticketObj.id)} />
        </div>
      </div>
    );
  }
}));


const TicketsPanelResponsesPanel = inject("RightPanelMobx", "DatabaseMobx")(observer(
class TicketsPanelResponsesPanel extends Component {
  onselect_ticket_closed0_open1 = (i_newValue01) => { this.props.RightPanelMobx.a_set_ticket_closed0_open1(i_newValue01); }

  render() {
    const valueArray = [1, 0];
    const displayArray = ["Open", "Closed"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Ticket Status", valueArray, false, displayArray, swsOptionsObj);
    const selectTicketOpenClosedFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a displayFlexRowVc flexWrap smallFullMargin">
          <div className="flex00a rMargin">
            {"Ticket Status:"}
          </div>
          <div className="flex11a">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={selectTicketOpenClosedFieldTypeObj}
              p_valueRaw={this.props.RightPanelMobx.c_currentlyViewedSingleTicketObj.closed0_open1}
              f_onChangeOrOnSelect={this.onselect_ticket_closed0_open1}
            />
          </div>
        </div>
        <ResponsesPanelResponsesThread />
        <div className="borderTB bevelBorderColors lrPad" />
        <ResponsesPanelNewResponse />
      </div>
    );
  }
}));


const ResponsesPanelResponsesThread = inject("RightPanelMobx")(observer(
class ResponsesPanelResponsesThread extends Component {
  componentDidUpdate() {
    var responsesThreadElement = ReactDOM.findDOMNode(this);
    responsesThreadElement.scrollTop = responsesThreadElement.scrollHeight;
  }

  render() {
    return(
      <div className="flex11a yScroll smallBottomMargin lrPad">
        <div className="medBottomMargin displayFlexRow border bevelBorderColors bgTicketOpen">
          <TicketPriorityCell p_priorityCode1234={this.props.RightPanelMobx.c_currentlyViewedSingleTicketObj.priority_code} />
          <div className="flex11a displayFlexColumnVc smallFullPad">
            <div className="textRight fontItalic fontTextLighter">
              {this.props.RightPanelMobx.c_currentlyViewedSingleTicketObj.dateMask}
            </div>
            <div className="smallTopMargin">
              {this.props.RightPanelMobx.c_currentlyViewedSingleTicketObj.initial_message}
            </div>
          </div>
        </div>
        {this.props.RightPanelMobx.c_currentlyViewedSingleTicketResponsesArrayOfObjs.map((m_responseObj) =>
          <ResponsesPanelSingleResponse key={m_responseObj.id} p_responseObj={m_responseObj} />
        )}
      </div>
    );
  }
}));


const ResponsesPanelSingleResponse = inject("RightPanelMobx")(observer(
class ResponsesPanelSingleResponse extends Component { //props: p_responseObj
  render() {
    var responseBgClass = "bgAlmostWhite"; //user 0
    if(this.props.p_responseObj.user0_bit1_bitviewed2 === 1) { //BIT 1
      responseBgClass = "userPanelTicketsTicketResponseFromBIT ticketResponseFromBITUnread";
    }
    else if(this.props.p_responseObj.user0_bit1_bitviewed2 === 2) { //BIT viewed 2
      responseBgClass = "userPanelTicketsTicketResponseFromBIT bgRead";
    }

    return(
      <div
        className={"userPanelTicketsTicketResponse medTopMargin " + responseBgClass}>
        <div className="smallFullMargin">
          <div className="textRight fontItalic fontTextLighter" style={{textAlign:((this.props.p_responseObj.user0_bit1_bitviewed2 === 0) ? ("right") : ("left"))}}>
            {this.props.p_responseObj.dateMask}
          </div>
          <div>
            {this.props.p_responseObj.message}
          </div>
        </div>
      </div>
    );
  }
}));


const ResponsesPanelNewResponse = inject("RightPanelMobx")(observer(
class ResponsesPanelNewResponse extends Component {
  onchange_response_message = (i_newValue) => { this.props.RightPanelMobx.a_ticket_new_response_change_response(i_newValue); }
  onclick_submit_new_response = () => { this.props.RightPanelMobx.a_ticket_submit_new_response(); }

  render() {
    return(
      <div className="flex00a smallFullMargin">
        <div className="font12 fontBold fontTextLighter">
          {"Add a Response"}
        </div>
        <div className="smallTopMargin">
          <form autoComplete="off">
            <LibraryReact.Textarea
              p_value={this.props.RightPanelMobx.o_ticketNewResponseObj.response}
              p_styleObj={{height:"15vh"}}
              p_tabIndex={1}
              p_placeholder="Enter a new response for this ticket here!"
              p_errorTF={this.props.RightPanelMobx.o_ticketNewResponseObj.responseErrorTF}
              f_onChange={this.onchange_response_message}
            />
          </form>
        </div>
        <div className="displayFlexRow smallTopMargin">
          <div className="flex11a" />
          <div className="flex00a">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Submit New Response"
              p_tabIndex={2}
              f_onClick={this.onclick_submit_new_response}
            />
          </div>
        </div>
        <CEGeneralReact.Message p_messageObj={this.props.RightPanelMobx.o_ticketNewResponseObj.messageObj} />
      </div>
    );
  }
}));

function TicketPriorityCell(props) {
  //p_priorityCode1234
  var priorityLetter = "-";
  if(props.p_priorityCode1234 === 1) { priorityLetter = "E"; }
  else if(props.p_priorityCode1234 === 2) { priorityLetter = "H"; }
  else if(props.p_priorityCode1234 === 3) { priorityLetter = "M"; }
  else if(props.p_priorityCode1234 === 4) { priorityLetter = "L"; }

  return(
    <div className={"ticketPriorityCell bgPriority" + props.p_priorityCode1234}>
      <div className="font09 fontBold">
        {priorityLetter}
      </div>
    </div>
  );
}





//============================================================================================================================
//Tasks
//============================================================================================================================
export const TasksPanel = inject("RightPanelMobx", "DatabaseMobx")(observer(
class TasksPanel extends Component {
  onclick_task = (i_taskObj) => { //if changing the screen size while edit task is open, this prevents the item from being derendered if it was flipped the wrong way (the edit would seemingly disappear)
    this.props.RightPanelMobx.a_tasks_set_panel_showing_active_tf(!i_taskObj.isCompletedTF);
  }

  onclick_panel_active_completed_tab = (i_selectedTabDbName) => { //small mobile screens can toggle between active and completed tasks
    var updatedTasksPanelShowingActiveTF = true;
    if(i_selectedTabDbName === "completed") {
      updatedTasksPanelShowingActiveTF = false;
    }
    this.props.RightPanelMobx.a_tasks_set_panel_showing_active_tf(updatedTasksPanelShowingActiveTF);
  }

  onclick_toggle_task_panel_filters_subpanel_open = () => {
    this.props.RightPanelMobx.a_tasks_panel_set_filters_subpanel_open_tf(!this.props.RightPanelMobx.o_tasksPanelFiltersSubpanelOpenTF);
  }

  onclick_reset_filters = () => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_me_to_me_tf(false);
    this.props.RightPanelMobx.a_tasks_panel_set_filter_other_to_me_tf(false);
    this.props.RightPanelMobx.a_tasks_panel_set_filter_me_to_other_tf(false);
    this.onselect_task_panel_filter_assigned_to_user_ids_comma("");
    this.onselect_task_panel_filter_assigned_by_user_ids_comma("");
    this.onselect_task_panel_filter_date_from_ymd(JSFUNC.blank_date());
    this.onselect_task_panel_filter_date_to_ymd(JSFUNC.blank_date());
    this.onchange_task_panel_filter_text("");
  }

  onclick_toggle_task_panel_filter_me_to_me = () => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_me_to_me_tf(!this.props.RightPanelMobx.o_tasksPanelFilterMeToMeTF);
  }

  onclick_toggle_task_panel_filter_other_to_me = () => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_other_to_me_tf(!this.props.RightPanelMobx.o_tasksPanelFilterOtherToMeTF);
  }

  onclick_toggle_task_panel_filter_me_to_other = () => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_me_to_other_tf(!this.props.RightPanelMobx.o_tasksPanelFilterMeToOtherTF);
  }

  onselect_task_panel_filter_assigned_to_user_ids_comma = (i_selectedUserIDsComma) => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_assigned_to_user_ids_comma(i_selectedUserIDsComma);
  }

  onselect_task_panel_filter_assigned_by_user_ids_comma = (i_selectedUserIDsComma) => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_assigned_by_user_ids_comma(i_selectedUserIDsComma);
  }

  onselect_task_panel_filter_date_from_ymd = (i_selectedDateYmd) => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_date_from_ymd(i_selectedDateYmd);
  }

  onselect_task_panel_filter_date_to_ymd = (i_selectedDateYmd) => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_date_to_ymd(i_selectedDateYmd);
  }

  onchange_task_panel_filter_text = (i_newValue) => {
    this.props.RightPanelMobx.a_tasks_panel_set_filter_text(i_newValue);
  }
  
  render() {
    const panelShowingActiveTF = this.props.RightPanelMobx.o_tasksPanelShowingActiveTF;

    const activeCompletedString = ((panelShowingActiveTF) ? ("Active") : ("Completed"));

    const o_tasksPanelFiltersSubpanelOpenTF = this.props.RightPanelMobx.o_tasksPanelFiltersSubpanelOpenTF;
    const o_tasksPanelFilterMeToMeTF = this.props.RightPanelMobx.o_tasksPanelFilterMeToMeTF;
    const o_tasksPanelFilterOtherToMeTF = this.props.RightPanelMobx.o_tasksPanelFilterOtherToMeTF;
    const o_tasksPanelFilterMeToOtherTF = this.props.RightPanelMobx.o_tasksPanelFilterMeToOtherTF;
    const o_tasksPanelFilterAssignedToUserIDsComma = this.props.RightPanelMobx.o_tasksPanelFilterAssignedToUserIDsComma;
    const o_tasksPanelFilterAssignedByUserIDsComma = this.props.RightPanelMobx.o_tasksPanelFilterAssignedByUserIDsComma;
    const o_tasksPanelFilterDateFromYmd = this.props.RightPanelMobx.o_tasksPanelFilterDateFromYmd;
    const o_tasksPanelFilterDateToYmd = this.props.RightPanelMobx.o_tasksPanelFilterDateToYmd;
    const o_tasksPanelFilterText = this.props.RightPanelMobx.o_tasksPanelFilterText;
    const c_tasksNumTaskFiltersApplied = this.props.RightPanelMobx.c_tasksNumTaskFiltersApplied;
    const c_myActiveTasksArrayOfObjs = this.props.RightPanelMobx.c_myActiveTasksArrayOfObjs;
    const activeTasksIAssignedToOthersArrayOfObjs = this.props.RightPanelMobx.c_activeTasksIAssignedToOthersArrayOfObjs;
    const myCompletedTasksArrayOfObjs = this.props.RightPanelMobx.c_myCompletedTasksArrayOfObjs;
    const completedTasksIAssignedToOthersArrayOfObjs = this.props.RightPanelMobx.c_completedTasksIAssignedToOthersArrayOfObjs;

    const numMyActiveTasks = c_myActiveTasksArrayOfObjs.length;
    const numActiveTasksIAssignedToOthers = activeTasksIAssignedToOthersArrayOfObjs.length;
    const numMyCompletedTasks = myCompletedTasksArrayOfObjs.length;
    const numCompletedTasksIAssignedToOthers = completedTasksIAssignedToOthersArrayOfObjs.length;

    const filterAssignedToIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_tasksPanelFilterAssignedToUserIDsComma);
    const filterAssignedByIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_tasksPanelFilterAssignedByUserIDsComma);
    const filterDateFromIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_tasksPanelFilterDateFromYmd);
    const filterDateToIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_tasksPanelFilterDateToYmd);
    const filterTextIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(o_tasksPanelFilterText);

    var tasksFilterSubpanelButtonTitle = "";
    if(o_tasksPanelFiltersSubpanelOpenTF) {
      tasksFilterSubpanelButtonTitle = "Click to close Task Filters subpanel";
    }
    else {
      tasksFilterSubpanelButtonTitle = "Click to view various filters that can be applied to this list of Tasks";
      if(c_tasksNumTaskFiltersApplied > 0) {
        tasksFilterSubpanelButtonTitle += " (" + c_tasksNumTaskFiltersApplied + " " + JSFUNC.plural(c_tasksNumTaskFiltersApplied, "filter", "filters") + " currently applied)";
      }
    }

    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a bgPanelLightGray tbPad" style={{borderBottom:"solid 1px #ccc"}}>
          <div className="displayFlexRow smallBottomMargin">
            <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
              <TasksAddNewButton
                p_initRelevanceCaptureID={-1}
              />
            </div>
            <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
              <CEGeneralReact.TabsList
                p_tabDbNamesArray={["active", "completed"]}
                p_tabDisplayNamesArray={["Active Tasks (" + (numMyActiveTasks + numActiveTasksIAssignedToOthers) + ")", "Completed (" + (numMyCompletedTasks + numCompletedTasksIAssignedToOthers) + ")"]}
                p_selectedTabDbName={((panelShowingActiveTF) ? ("active") : ("completed"))}
                p_tabHeight="1.4em"
                p_textMaxHeight="1.3em"
                p_tabWidth="10em"
                p_selectedBgClass="bgDarkGreenGradient"
                p_selectedFontClass="fontWhite"
                p_unselectedBgClass="bgLightGrayGradient"
                p_unselectedFontClass="fontTextLighter"
                p_verticalTabsTF={true}
                f_onSelect={this.onclick_panel_active_completed_tab}
              />
            </div>
          </div>
          <div className="displayFlexColumnHcVc">
            <div className="flex00a displayFlexRow">
              <div
                className={"flex00a displayFlexRow borderRadius05 " + ((o_tasksPanelFiltersSubpanelOpenTF) ? ("bgLightGray") : ("hoverLightestestGray")) + " cursorPointer"}
                style={{width:"9.2em", height:"1.5em"}}
                title={tasksFilterSubpanelButtonTitle}
                onClick={this.onclick_toggle_task_panel_filters_subpanel_open}>
                <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.9em"}}>
                  <CEGeneralReact.ButtonExpandCollapse p_isExpandedTF={o_tasksPanelFiltersSubpanelOpenTF} p_sizeEm={1.2} />
                </div>
                <div className="flex11a displayFlexRowVc">
                  <LibraryReact.Nowrap p_fontClass="fontItalic">
                    {"Task Filters" + ((c_tasksNumTaskFiltersApplied > 0) ? (" (" + c_tasksNumTaskFiltersApplied + ")") : (""))}
                  </LibraryReact.Nowrap>
                </div>
              </div>
              {(c_tasksNumTaskFiltersApplied > 0) &&
                <div
                  className="flex00a displayFlexColumnHcVc hoverFontUnderline textCenter cursorPointer"
                  style={{width:"5.5em"}}
                  onClick={this.onclick_reset_filters}>
                  <font className="font09 fontItalic fontTextLight">
                    {"reset filters"}
                  </font>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="flex11a yScroll">
          {(o_tasksPanelFiltersSubpanelOpenTF) &&
            <div className="bgGray border bevelBorderDarkColors smallFullPad">
              <div className="microBottomMargin textCenter">
                <font className="font11 fontBold fontWhite">
                  {"Task Filters"}
                </font>
              </div>
              <div className="displayFlexRowHcVc flexWrap">
                <TasksFilterCheckbox
                  p_label="Me to Me only"
                  p_selectedTF={o_tasksPanelFilterMeToMeTF}
                  f_onClick={this.onclick_toggle_task_panel_filter_me_to_me}
                />
                <TasksFilterCheckbox
                  p_label="Other(s) to Me"
                  p_selectedTF={o_tasksPanelFilterOtherToMeTF}
                  f_onClick={this.onclick_toggle_task_panel_filter_other_to_me}
                />
                <TasksFilterCheckbox
                  p_label="Me to Other(s)"
                  p_selectedTF={o_tasksPanelFilterMeToOtherTF}
                  f_onClick={this.onclick_toggle_task_panel_filter_me_to_other}
                />
              </div>
              <div className="tbMargin lrMedMargin borderT1ddd" />
              <TasksFilterItemShell p_isFilledOutTF={filterAssignedToIsFilledOutTF}>
                <div className="displayFlexRow">
                  <div className="flex00a displayFlexRowVc" style={{flexBasis:"6em"}}>
                    <font className="font09 fontItalic">
                      {"Assigned To"}
                    </font>
                  </div>
                  <div className="flex11a displayFlexColumnVc">
                    <CEGeneralReact.GenericInputOrSelectFromInputType
                      p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiUsersFieldTypeObj}
                      p_valueRaw={o_tasksPanelFilterAssignedToUserIDsComma}
                      f_onChangeOrOnSelect={this.onselect_task_panel_filter_assigned_to_user_ids_comma}
                    />
                  </div>
                </div>
              </TasksFilterItemShell>
              <div className="smallTopMargin" />
              <TasksFilterItemShell p_isFilledOutTF={filterAssignedByIsFilledOutTF}>
                <div className="displayFlexRow">
                  <div className="flex00a displayFlexRowVc" style={{flexBasis:"6em"}}>
                    <font className="font09 fontItalic">
                      {"Assigned By"}
                    </font>
                  </div>
                  <div className="flex11a displayFlexColumnVc">
                    <CEGeneralReact.GenericInputOrSelectFromInputType
                      p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiUsersFieldTypeObj}
                      p_valueRaw={o_tasksPanelFilterAssignedByUserIDsComma}
                      f_onChangeOrOnSelect={this.onselect_task_panel_filter_assigned_by_user_ids_comma}
                    />
                  </div>
                </div>
              </TasksFilterItemShell>
              <div className="smallTopMargin" />
              <div className="displayFlexRow">
                <div className="flex11a rMargin" style={{flexBasis:"100em"}}>
                  <TasksFilterItemShell p_isFilledOutTF={filterDateFromIsFilledOutTF}>
                    <div className="displayFlexRow">
                      <div className="flex00a displayFlexRowVc" style={{flexBasis:"2.5em"}}>
                        <font className="font09 fontItalic">
                          <div>{"Due"}</div><div>{"From"}</div>
                        </font>
                      </div>
                      <div className="flex11a displayFlexColumnVc">
                        <CEGeneralReact.GenericInputOrSelectFromInputType
                          p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
                          p_valueRaw={o_tasksPanelFilterDateFromYmd}
                          f_onChangeOrOnSelect={this.onselect_task_panel_filter_date_from_ymd}
                        />
                      </div>
                    </div>
                  </TasksFilterItemShell>
                </div>
                <div className="flex11a" style={{flexBasis:"100em"}}>
                  <TasksFilterItemShell p_isFilledOutTF={filterDateToIsFilledOutTF}>
                    <div className="displayFlexRow">
                      <div className="flex00a displayFlexRowVc" style={{flexBasis:"2.5em"}}>
                        <font className="font09 fontItalic">
                          <div>{"Due"}</div><div>{"To"}</div>
                        </font>
                      </div>
                      <div className="flex11a displayFlexColumnVc">
                        <CEGeneralReact.GenericInputOrSelectFromInputType
                          p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
                          p_valueRaw={o_tasksPanelFilterDateToYmd}
                          f_onChangeOrOnSelect={this.onselect_task_panel_filter_date_to_ymd}
                        />
                      </div>
                    </div>
                  </TasksFilterItemShell>
                </div>
              </div>
              <div className="smallTopMargin" />
              <TasksFilterItemShell p_isFilledOutTF={filterTextIsFilledOutTF}>
                <CEGeneralReact.SearchInputTextWithClearX
                  p_inputText={o_tasksPanelFilterText}
                  p_placeholderText="Search Descriptions and Relevance..."
                  p_includeSearchIconTF={true}
                  p_heightEm={2}
                  f_onChange={this.onchange_task_panel_filter_text}
                />
              </TasksFilterItemShell>
            </div>
          }
          <div className="yScrollBottomPad smallTopPad lrPad">
            <div>
              <font className="font12 fontItalic fontTextLighter">
                {"My " + activeCompletedString + " Tasks (" + ((panelShowingActiveTF) ? (numMyActiveTasks) : (numMyCompletedTasks)) + ")"}
              </font>
            </div>
            <TasksGrouping
              p_container="rightPanel"
              p_tasksArrayOfObjs={((panelShowingActiveTF) ? (c_myActiveTasksArrayOfObjs) : (myCompletedTasksArrayOfObjs))}
              p_isCompletedTF={(!panelShowingActiveTF)}
              f_onClickTask={this.onclick_task}
            />
            <div className="hugeTopMargin">
              <font className="font12 fontItalic fontTextLighter">
                {activeCompletedString + " Tasks I assigned to Others (" + ((panelShowingActiveTF) ? (numActiveTasksIAssignedToOthers) : (numCompletedTasksIAssignedToOthers)) + ")"}
              </font>
            </div>
            <TasksGrouping
              p_container="rightPanel"
              p_tasksArrayOfObjs={((panelShowingActiveTF) ? (activeTasksIAssignedToOthersArrayOfObjs) : (completedTasksIAssignedToOthersArrayOfObjs))}
              p_isCompletedTF={(!panelShowingActiveTF)}
              f_onClickTask={this.onclick_task}
            />
          </div>
        </div>
      </div>
    );
  }
}))



export const TasksAddNewButton = inject("RightPanelMobx")(observer(
class TasksAddNewButton extends Component { //props: p_initRelevanceCaptureID
  onclick_add_task = () => {
    this.props.RightPanelMobx.a_tasks_set_editing_task_relevance_and_task_obj(this.props.p_initRelevanceCaptureID, undefined);
  }

  render() {
    return(
      <CEGeneralReact.CEButton
        p_type="blue"
        p_text="Create a New Task"
        p_tabIndex={1}
        f_onClick={this.onclick_add_task}
      />
    );
  }
}));

function TasksFilterCheckbox(props) { //props: p_label, p_selectedTF, f_onClick
  return(
    <div
      className={"flex00a displayFlexColumnHcVc border1bbb borderRadius05 " + ((props.p_selectedTF) ? ("bgLightGreenGradient") : ("bgLightGray")) + " textCenter cursorPointer"}
      style={{width:"7.7em", height:"1.7em", margin:"0.2em"}}
      onClick={props.f_onClick}>
      <font>
        {props.p_label}
      </font>
    </div>
  );
}

function TasksFilterItemShell(props) { //props: p_isFilledOutTF, children
  const p_isFilledOutTF = props.p_isFilledOutTF;
  return(
    <div className={"tbPad lrPad border bevelBorderColors borderRadius05 " + ((p_isFilledOutTF) ? ("bgLightGreenGradient") : ("bgLightGray"))}>
      {props.children}
    </div>
  );
}

export function TasksGrouping(props) { //props: p_container, p_tasksArrayOfObjs, p_isCompletedTF, f_onClickTask(i_taskObj)
  const isCompletedTF = props.p_isCompletedTF;
  const drawSnapshotTasksTF = (props.p_container === "snapshot");

  //completed sort 1. all due (mixed importance), 2. all due not set
  if(isCompletedTF) {
    return(
      [true, false].map((i_dueDateIsSetTF) =>
        props.p_tasksArrayOfObjs.map((i_taskObj) =>
          (i_dueDateIsSetTF === i_taskObj.dueDateIsSetTF) && (
            ((drawSnapshotTasksTF) ? (
              <TaskSnapshotItem
                key={i_taskObj.id}
                p_taskObj={i_taskObj}
              />
            ) : (
              <TaskItem
                key={i_taskObj.id}
                p_container={props.p_container}
                p_taskObj={i_taskObj}
                f_onClick={props.f_onClickTask}
              />
            ))
          )
        )
      )
    );
  }

  //active sort 1. important/due, 2. important/due not set, 3. not important/due, 4. not important/due not set
  return(
    [true, false].map((i_isImportantTF) =>
      [true, false].map((i_dueDateIsSetTF) =>
        props.p_tasksArrayOfObjs.map((i_taskObj) =>
          ((i_isImportantTF === i_taskObj.isImportantTF) && (i_dueDateIsSetTF === i_taskObj.dueDateIsSetTF)) && (
            ((drawSnapshotTasksTF) ? (
              <TaskSnapshotItem
                key={i_taskObj.id}
                p_taskObj={i_taskObj}
              />
            ) : (
              <TaskItem
                key={i_taskObj.id}
                p_container={props.p_container}
                p_taskObj={i_taskObj}
                f_onClick={props.f_onClickTask}
              />
            ))
          )
        )
      )
    )
  );
}

function TaskSnapshotItem(props) { //props: p_taskObj
  const p_taskObj = props.p_taskObj;

  return(
    <div className="smallTopMargin displayFlexRow" style={{border:"solid 1px #ccc", borderTopColor:"#eee", borderLeftColor:"#eee"}}>
      <TasksImportantIcon
        p_importantTF={p_taskObj.isImportantTF}
        p_completedTF={p_taskObj.isCompletedTF}
        p_width="0.7em"
      />
      <div className="flex11a microTopPad microBottomPad lrPad">
        <div className="displayFlexRow">
          <div className="flex11a">
            <LibraryReact.Nowrap>{p_taskObj.maskAssignedToName}</LibraryReact.Nowrap>
          </div>
          <div className="flex00a">
            {p_taskObj.maskActiveTaskMdyDueDate}
          </div>
        </div>
        <div className="displayFlexRow microTopMargin">
          <div className="flex11a">
            <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
              {p_taskObj.description}
            </LibraryReact.Nowrap>
          </div>
        </div>
      </div>
    </div>
  );
}

export const TaskItem = inject("CaptureExecMobx", "RightPanelMobx", "OpenCaptureMobx")(observer(
class TaskItem extends Component { //props: p_container, p_taskObj, f_onClick
  onclick_task = () => {
    if(this.props.f_onClick) { //register the click in a capture so that the mobile screen can switch automatically between active and completed if needed
      this.props.f_onClick(this.props.p_taskObj);
    }

    //open this task item for editing
    this.props.RightPanelMobx.a_tasks_set_editing_task_relevance_and_task_obj(this.props.p_taskObj.capture_id, this.props.p_taskObj);
  }

  onclick_confirm_delete_task = () => {
    const p_taskObj = this.props.p_taskObj;
    if(p_taskObj.id > 0) {
      this.props.RightPanelMobx.a_tasks_delete_task(this.props.p_taskObj);
    }
  }

  render() {
    const p_container = this.props.p_container;
    const p_taskObj = this.props.p_taskObj;

    const containerIsRightPanelTF = (p_container === "rightPanel");

    //split screen on capture card for most screen sizes
    var fieldsLeftDescriptionRightTF = false; //for the tasks panel, do not use split screen
    if(!containerIsRightPanelTF) {
      fieldsLeftDescriptionRightTF = this.props.OpenCaptureMobx.tasks_item_fields_left_description_right_tf(p_container);
    }

    var taskBgColor = "#ffffff";
    if(containerIsRightPanelTF) {
      taskBgColor = ((p_taskObj.isCompletedTF) ? ("#f6f6f6") : ("#fdfdfd"));
    }
    else {
      taskBgColor = ((p_taskObj.isCompletedTF) ? ("#f6f6f6") : ("#ffffff"));
    }

    return(
      <div
        className="tbMargin hoverLighterBlueGradient"
        style={{border:"solid 1px #ccc", backgroundColor:taskBgColor}}
        onClick={((p_taskObj.id > 0) ? (this.onclick_task) : (undefined))}>
        <div className={"displayFlexRow " + ((p_taskObj.id > 0) ? ("cursorPointer") : (""))}>
          <TasksImportantIcon
            p_importantTF={p_taskObj.isImportantTF}
            p_completedTF={p_taskObj.isCompletedTF}
            p_width="0.9em"
          />
          <div
            className={((fieldsLeftDescriptionRightTF) ? ("flex00a") : ("flex11a")) + " smallTopPad smallBottomPad"}
            style={{flexBasis:"24em", marginLeft:"0.5em"}}>
            <TaskFieldDisplay p_field="Due" p_value={p_taskObj.maskActiveTaskMdyDueDate} p_valuePlainText={p_taskObj.maskActiveTaskMdyDueDatePlainText} p_nowrapTF={false} p_class="microBottomMargin" />
            {(containerIsRightPanelTF) &&
              <>
                <TaskFieldDisplay p_field="Relevance" p_value={p_taskObj.maskRelevancePlainText} p_valuePlainText={p_taskObj.maskRelevancePlainText} p_nowrapTF={true} p_class={"microBottomMargin " + ((p_taskObj.isGeneralTaskTF) ? ("") : ("fontBlueLight"))} />
                {(JSFUNC.is_array(p_taskObj.extraCaptureFieldValuesArrayOfObjs)) &&
                  p_taskObj.extraCaptureFieldValuesArrayOfObjs.map((m_extraCaptureFieldValueObj) =>
                    <TaskFieldDisplay p_field={m_extraCaptureFieldValueObj.captureFieldDisplayName} p_value={m_extraCaptureFieldValueObj.valueMaskNoClickLinks} p_valuePlainText={m_extraCaptureFieldValueObj.valueMaskPlainText} p_nowrapTF={true} p_class="microBottomMargin" />
                  )
                }
              </>
            }
            <TaskFieldDisplay p_field="Assigned To" p_value={p_taskObj.maskAssignedToName} p_valuePlainText={p_taskObj.maskAssignedToNamePlainText} p_nowrapTF={true} p_class="microBottomMargin" />
            <TaskFieldDisplay p_field="Assigned By" p_value={p_taskObj.maskAssignerName} p_valuePlainText={p_taskObj.maskAssignerNamePlainText} p_nowrapTF={true} p_class="microBottomMargin" p_fontLighterTF={true} />
            {(!fieldsLeftDescriptionRightTF) &&
              <TaskFieldDisplay p_field="Description" p_value={p_taskObj.maskDescription} p_valuePlainText={p_taskObj.maskDescriptionPlainText} p_nowrapTF={true} p_class="fontTextLight" />
            }
          </div>
          {(fieldsLeftDescriptionRightTF) &&
            <div className="flex11a displayFlexRowVc lrPad" style={{borderLeft:"solid 1px #ccc"}}>
              <LibraryReact.MaxHeightWrap p_maxHeight="4.2em" p_fontClass="fontTextLight">
                {p_taskObj.maskDescription}
              </LibraryReact.MaxHeightWrap>
            </div>
          }
          <div className="flex00a displayFlexColumnHcVc">
            <CEGeneralReact.DeleteMenu
              p_message={"Are you sure you want to delete this Task?\n\n" + ((JSFUNC.text_or_number_is_filled_out_tf(p_taskObj.description)) ? ("'" + p_taskObj.description + "'") : ("--Task has no Description--"))}
              p_tabIndex={-1}
              f_onDelete={this.onclick_confirm_delete_task}
            />
          </div>
        </div>
      </div>
    );
  }
}));


function TaskFieldDisplay(props) { //props: p_field, p_value, p_valuePlainText, p_nowrapTF, p_class, p_fontLighterTF
  const p_field = props.p_field;
  const p_value = props.p_value;
  const p_valuePlainText = props.p_valuePlainText;
  const p_nowrapTF = props.p_nowrapTF;
  const p_class = props.p_class;
  const p_fontLighterTF = JSFUNC.prop_value(props.p_fontLighterTF, false);

  const fieldFontColorClass = ((p_fontLighterTF) ? ("fontBlue fontItalic") : ("fontBlue"));
  const valueFontColorClass = ((p_fontLighterTF) ? ("fontTextLighter fontItalic") : (""));

  return(
    <div
      className={"displayFlexRow " + p_class}
      title={p_field + ": " + p_valuePlainText}>
      <div className="flex00a" style={{flexBasis:"6.5em"}}>
        <LibraryReact.Nowrap p_fontClass={fieldFontColorClass}>
          {p_field + ":"}
        </LibraryReact.Nowrap>
      </div>
      <div className="flex11a">
        {((p_nowrapTF) ? (
          <LibraryReact.Nowrap p_fontClass={valueFontColorClass}>
            {p_value}
          </LibraryReact.Nowrap>
        ) : (
          p_value
        ))}
      </div>
    </div>
  );
}




export const TasksAddNewOrEditTaskFloatingBox = inject("RightPanelMobx")(observer(
class TasksAddNewOrEditTaskFloatingBox extends Component { //props:
  render() {
    const o_tasksEditingTaskRelevanceCaptureID = this.props.RightPanelMobx.o_tasksEditingTaskRelevanceCaptureID;
    const o_tasksEditingTaskObj = this.props.RightPanelMobx.o_tasksEditingTaskObj;

    if(o_tasksEditingTaskRelevanceCaptureID === undefined) {
      return(null);
    }

    return(
      <TasksAddNewOrEditTaskFloatingBoxInternal
        p_initRelevanceCaptureID={o_tasksEditingTaskRelevanceCaptureID}
        p_taskObj={o_tasksEditingTaskObj}
      />
    );
  }
}));


const TasksAddNewOrEditTaskFloatingBoxInternal = inject("CaptureExecMobx", "RightPanelMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TasksAddNewOrEditTaskFloatingBoxInternal extends Component { //props: p_initRelevanceCaptureID, p_taskObj
  constructor(props) {
    super(props);

    const p_taskObj = this.props.p_taskObj;
    var important01 = 0; //initialization for a new task
    var dateCompleted = JSFUNC.blank_date();
    var relevanceCaptureID = JSFUNC.prop_value(this.props.p_initRelevanceCaptureID, -1);
    var assignedToUserIDsComma = JSFUNC.convert_array_to_comma_list([this.props.UserMobx.o_userID]);
    var dateDue = JSFUNC.now_date();
    var description = "";
    var preReminderDays = -1;
    var postReminderDays = -1;
    if(p_taskObj !== undefined) { //editing an existing task obj if it is provided (if not provided, creating a new task)
      important01 = p_taskObj.important_01;
      dateCompleted = p_taskObj.date_completed;
      relevanceCaptureID = p_taskObj.capture_id;
      assignedToUserIDsComma = p_taskObj.assigned_to_user_ids_comma;
      dateDue = p_taskObj.date_due;
      description = p_taskObj.description;
      preReminderDays = p_taskObj.pre_reminder_days;
      postReminderDays = p_taskObj.post_reminder_days;
    }

    this.state = {
      s_updatedImportant01: important01,
      s_updatedDateCompleted: dateCompleted,
      s_updatedRelevanceCaptureID: relevanceCaptureID,
      s_updatedAssignedToUserIDsComma: assignedToUserIDsComma,
      s_updatedDateDue: dateDue,
      s_updatedDescription: description,
      s_updatedPreReminderDays: preReminderDays,
      s_updatedPostReminderDays: postReminderDays,
      s_anyTaskFieldIsUpdatedTF: false, //flag to confirm cancellation in the floating box if a change was detected
      s_confirmingMarkActiveTF: false //confirm box to reopen a completed task (to lessen the chance of accidently changing it and resetting the completed date)
    }
  }

  onswitch_important_01 = () => { //toggle the important value when clicked between 0 and 1
    this.setState({
      s_updatedImportant01: ((this.state.s_updatedImportant01 === 0) ? (1) : (0)),
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onswitch_active_completed = () => {
    const prevDateCompletedIsFilledOutTF = JSFUNC.date_is_filled_out_tf(this.state.s_updatedDateCompleted); //task was marked as completed, open the confirm box to reopen the task as active
    this.setState({
      s_confirmingMarkActiveTF: prevDateCompletedIsFilledOutTF,
      s_anyTaskFieldIsUpdatedTF: true
    });

    if(!prevDateCompletedIsFilledOutTF) { //set task as completed on today's date
      this.setState({
        s_updatedDateCompleted: JSFUNC.now_date()
      });
    }
  }

  onclick_reopen_active_confirm = () => {
    this.setState({
      s_confirmingMarkActiveTF: false,
      s_updatedDateCompleted: JSFUNC.blank_date(),
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onclick_reopen_active_cancel = () => {
    this.setState({s_confirmingMarkActiveTF:false});
  }

  onchange_relevance_capture_id = (i_newValue) => {
    this.setState({
      s_updatedRelevanceCaptureID: i_newValue,
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onchange_assigned_to_user_ids_comma = (i_newValue) => {
    this.setState({
      s_updatedAssignedToUserIDsComma: i_newValue,
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onchange_date_due = (i_newValue) => {
    this.setState({
      s_updatedDateDue: i_newValue,
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onchange_description = (i_newValue) => {
    this.setState({
      s_updatedDescription: i_newValue,
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onswitch_pre_reminder_on_off = () => {
    this.setState((i_state, i_props) => ({
      s_updatedPreReminderDays: ((i_state.s_updatedPreReminderDays >= 0) ? (-1) : (7)),
      s_anyTaskFieldIsUpdatedTF: true
    }));
  }

  onchange_pre_reminder_days = (i_newValue) => {
    this.setState({
      s_updatedPreReminderDays: i_newValue,
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onswitch_post_reminder_on_off = () => {
    this.setState((i_state, i_props) => ({
      s_updatedPostReminderDays: ((i_state.s_updatedPostReminderDays >= 0) ? (-1) : (1)),
      s_anyTaskFieldIsUpdatedTF: true
    }));
  }

  onchange_post_reminder_days = (i_newValue) => {
    this.setState({
      s_updatedPostReminderDays: i_newValue,
      s_anyTaskFieldIsUpdatedTF: true
    });
  }

  onsave_task_item = () => {
    const s_updatedImportant01 = this.state.s_updatedImportant01;
    const s_updatedDateCompleted = this.state.s_updatedDateCompleted;
    const s_updatedRelevanceCaptureID = this.state.s_updatedRelevanceCaptureID;
    const s_updatedAssignedToUserIDsComma = this.state.s_updatedAssignedToUserIDsComma;
    const s_updatedDateDue = this.state.s_updatedDateDue;
    const s_updatedDescription = this.state.s_updatedDescription;
    const s_updatedPreReminderDays = this.state.s_updatedPreReminderDays;
    const s_updatedPostReminderDays = this.state.s_updatedPostReminderDays;

    const p_taskObj = this.props.p_taskObj;

    if(p_taskObj === undefined) { //adding a new item
      this.props.RightPanelMobx.a_tasks_insert_new_task(s_updatedRelevanceCaptureID, s_updatedAssignedToUserIDsComma, s_updatedDateDue, s_updatedDateCompleted, s_updatedImportant01, s_updatedDescription, s_updatedPreReminderDays, s_updatedPostReminderDays);
    }
    else { //editing an item
      //make a copy of the initial p_taskObj to get all of the initial field values, then overwrite the updated values
      var updatedTaskObj = JSFUNC.copy_obj(p_taskObj);
      updatedTaskObj.capture_id = s_updatedRelevanceCaptureID;
      updatedTaskObj.assigned_to_user_ids_comma = s_updatedAssignedToUserIDsComma;
      updatedTaskObj.date_due = s_updatedDateDue;
      updatedTaskObj.date_completed = s_updatedDateCompleted;
      updatedTaskObj.important_01 = s_updatedImportant01;
      updatedTaskObj.description = s_updatedDescription;
      updatedTaskObj.pre_reminder_days = s_updatedPreReminderDays;
      updatedTaskObj.post_reminder_days = s_updatedPostReminderDays;

      this.props.RightPanelMobx.a_tasks_update_task(p_taskObj, updatedTaskObj);
    }
    this.onclose_task_item();
  }

  onclose_task_item = () => {
    this.props.RightPanelMobx.a_tasks_set_editing_task_relevance_and_task_obj(undefined, undefined);
  }

  onclick_open_relevance_capture = () => {
    const s_updatedRelevanceCaptureID = this.state.s_updatedRelevanceCaptureID;
    if(s_updatedRelevanceCaptureID > 0) {
      this.props.OpenCaptureMobx.a_open_single_capture(s_updatedRelevanceCaptureID);
    }
  }

  render() {
    const s_updatedImportant01 = this.state.s_updatedImportant01;
    const s_updatedDateCompleted = this.state.s_updatedDateCompleted;
    const s_updatedRelevanceCaptureID = this.state.s_updatedRelevanceCaptureID;
    const s_updatedAssignedToUserIDsComma = this.state.s_updatedAssignedToUserIDsComma;
    const s_updatedDateDue = this.state.s_updatedDateDue;
    const s_updatedDescription = this.state.s_updatedDescription;
    const s_updatedPreReminderDays = this.state.s_updatedPreReminderDays;
    const s_updatedPostReminderDays = this.state.s_updatedPostReminderDays;
    const s_anyTaskFieldIsUpdatedTF = this.state.s_anyTaskFieldIsUpdatedTF;
    const s_confirmingMarkActiveTF = this.state.s_confirmingMarkActiveTF;

    const p_taskObj = this.props.p_taskObj;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_companyTasksAssigneeCanEditDueDateTF = this.props.DatabaseMobx.c_companyTasksAssigneeCanEditDueDateTF;
    const o_userID = this.props.UserMobx.o_userID;
    const c_multiLoginUserIDsArray = this.props.UserMobx.c_multiLoginUserIDsArray;

    const isAddingNewTaskTF = (p_taskObj === undefined);
    const taskCanBeEditedTF = (isAddingNewTaskTF || (p_taskObj.id > 0));

    const isImportantTF = (s_updatedImportant01 === 1);
    const isCompletedTF = JSFUNC.date_is_filled_out_tf(s_updatedDateCompleted);
    const assignedToUserIDsArray = JSFUNC.convert_comma_list_to_int_array(s_updatedAssignedToUserIDsComma);
    const preReminderIsOnTF = (s_updatedPreReminderDays >= 0);
    const postReminderIsOnTF = (s_updatedPostReminderDays >= 0);

    const dateCreated = ((isAddingNewTaskTF) ? (JSFUNC.now_date()) : (p_taskObj.date_created));
    const dateCreatedMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(dateCreated, this.props.DatabaseMobx.c_genericDateFieldTypeObj);

    const assignerUserID = ((isAddingNewTaskTF) ? (o_userID) : (p_taskObj.assigner_user_id));
    const assignerNameMask = this.props.DatabaseMobx.user_name_mask_from_user_id(assignerUserID);

    //determine ability to edit each task field
    const assignerIsMeTF = this.props.UserMobx.user_id_is_one_of_logged_in_user_per_email_multilogin_tf(assignerUserID);
    const assignedToMeTF = JSFUNC.any_of_array1_is_in_array2(c_multiLoginUserIDsArray, assignedToUserIDsArray);

    const canEditDateDueTF = (taskCanBeEditedTF && (assignerIsMeTF || c_companyTasksAssigneeCanEditDueDateTF));
    const canEditImportant01TF = (taskCanBeEditedTF && (assignerIsMeTF || assignedToMeTF));
    const canEditDateCompletedTF = (taskCanBeEditedTF && (assignerIsMeTF || assignedToMeTF));
    const canEditRelevanceCaptureTF = (taskCanBeEditedTF && isAddingNewTaskTF);
    const canEditAssignedToTF = (taskCanBeEditedTF && assignerIsMeTF);
    const canEditPrePostRemindersTF = (taskCanBeEditedTF && (assignerIsMeTF || assignedToMeTF));
    const canEditDescriptionTF = (taskCanBeEditedTF && (assignerIsMeTF || assignedToMeTF));

    const extraCaptureFieldValuesArrayOfObjs = this.props.RightPanelMobx.tasks_compute_extra_capture_field_values_arrayOfObjs_from_capture_id(s_updatedRelevanceCaptureID);

    //left and right side components of edit interface split screen
    const leftSideComponents = (
      <>
        <div className="displayFlexRowHcVc flexWrap tbMedPad borderB1ddd medBottomMargin">
          <div className="flex00a lrMedMargin">
            <font className="font13 fontBold fontItalic fontBlue">
              {"Due:"}
            </font>
          </div>
          <div className="flex00a lrMedMargin">
            <font className="font13">
              {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_updatedDateDue, ((isCompletedTF) ? (this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1FieldTypeObj) : (this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj)))}
            </font>
          </div>
        </div>
        {(canEditDateDueTF) &&
          <TaskEditLeftField p_fieldDisplay="Due">
            <LibraryReact.Date
              p_value={s_updatedDateDue}
              p_tabIndex={2}
              f_onChange={this.onchange_date_due}
            />
          </TaskEditLeftField>
        }
        <div className="displayFlexRowVc smallBottomMargin">
          <div className="flex00a">
            <LibraryReact.Switch
              p_isOnTF={isImportantTF}
              p_onColor="d44"
              p_offColor="999"
              p_tabIndex={3}
              p_title={((isImportantTF) ? ("Task is marked as Important. Click to mark task as Normal priority.") : ("Task is Normal priority. Click to mark Task as Important."))}
              f_onClick={((canEditImportant01TF) ? (this.onswitch_important_01) : (undefined))}
            />
          </div>
          <div className="flex11a lrMargin fontBold" style={{color:((isImportantTF) ? ("#d44") : ("#999"))}}>
            {((isImportantTF) ? ("Important") : ("Not Marked Important"))}
          </div>
        </div>
        <div className="displayFlexRowVc smallBottomMargin">
          <div className="flex00a">
            <LibraryReact.Switch
              p_isOnTF={isCompletedTF}
              p_onColor="494"
              p_offColor="999"
              p_tabIndex={4}
              p_title={((isCompletedTF) ? ("Task is marked as completed. Click to reopen Task as Active.") : ("Task is currently Active. Click to mark Task as Completed."))}
              f_onClick={((canEditDateCompletedTF) ? (this.onswitch_active_completed) : (undefined))}
            />
          </div>
          <div className="flex00a lrMargin fontBold" style={{color:((isCompletedTF) ? ("#494") : ("#999"))}}>
            {((isCompletedTF) ? ("Completed") : ("Not Yet Completed"))}
          </div>
          {(isCompletedTF) &&
            <div className="flex00a lrMargin">
              <div className="fontItalic fontTextLighter">
                {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_updatedDateCompleted, this.props.DatabaseMobx.c_genericDateFieldTypeObj)}
              </div>
            </div>
          }
          {(s_confirmingMarkActiveTF) &&
            <CEGeneralReact.ConfirmBox
              p_type="confirm"
              f_onClickConfirm={this.onclick_reopen_active_confirm}
              f_onClickCancel={this.onclick_reopen_active_cancel}>
              {"Are you sure you want to reopen this task as active?"}
            </CEGeneralReact.ConfirmBox>
          }
        </div>
        <TaskEditLeftField p_fieldDisplay="Relevance">
          {(canEditRelevanceCaptureTF) ? (
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectAllCapturesFieldTypeObj}
              p_valueRaw={s_updatedRelevanceCaptureID}
              p_tabIndex={5}
              f_onChangeOrOnSelect={this.onchange_relevance_capture_id}
            />
          ) : (
            (s_updatedRelevanceCaptureID > 0) ? (
              <div className="hoverFontUnderline cursorPointer" onClick={this.onclick_open_relevance_capture}>
                <font className="fontBlue">
                  {this.props.DatabaseMobx.capture_name_plaintext_from_capture_id(s_updatedRelevanceCaptureID)}
                </font>
              </div>
            ) : (
              "General Task"
            )
          )}
        </TaskEditLeftField>
        {extraCaptureFieldValuesArrayOfObjs.map((m_extraCaptureFieldValueObj) =>
          <TaskEditLeftField p_fieldDisplay={m_extraCaptureFieldValueObj.captureFieldDisplayName}>
            {m_extraCaptureFieldValueObj.valueMaskNoClickLinks}
          </TaskEditLeftField>
        )}
        <TaskEditLeftField p_fieldDisplay="Created">
          {dateCreatedMask}
        </TaskEditLeftField>
        <TaskEditLeftField p_fieldDisplay="Assigned To">
          {(canEditAssignedToTF) ? (
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiUsersFieldTypeObj}
              p_valueRaw={s_updatedAssignedToUserIDsComma}
              p_tabIndex={6}
              f_onChangeOrOnSelect={this.onchange_assigned_to_user_ids_comma}
            />
          ) : (
            this.props.DatabaseMobx.user_names_comma_mask_from_user_ids_comma(s_updatedAssignedToUserIDsComma)
          )}
        </TaskEditLeftField>
        {(!isAddingNewTaskTF) &&
          <TaskEditLeftField p_fieldDisplay="Assigned By" p_fieldDisplayItalicTF={true}>
            <font className="fontItalic fontTextLight">{assignerNameMask}</font>
          </TaskEditLeftField>
        }
        <TaskEditLeftField p_fieldDisplay="Pre-Reminder" p_helpMessage="A pre-reminder will send you a single notification if this task is still not completed X days before the due date.">
          <div className="displayFlexRowVc" style={{height:"3em"}}>
            <div className="flex00a lrMargin">
              <LibraryReact.Switch
                p_isOnTF={preReminderIsOnTF}
                p_tabIndex={7}
                f_onClick={((canEditPrePostRemindersTF) ? (this.onswitch_pre_reminder_on_off) : (undefined))}
              />
            </div>
            <div className="flex11a lrMargin">
              {((preReminderIsOnTF) ? (
                <div className="displayFlexRowVc flexWrap">
                  <div className="flex00a rMargin fontItalic">
                    {"Remind me one time"}
                  </div>
                  <div className="flex00a rMargin">
                    {(canEditPrePostRemindersTF) ? (
                      <LibraryReact.Integer
                        p_value={s_updatedPreReminderDays}
                        p_min={0}
                        p_max={1000000}
                        p_styleObj={{width:"4em"}}
                        p_tabIndex={8}
                        f_onChange={this.onchange_pre_reminder_days}
                      />
                    ) : (
                      <font className="fontBold fontTextLighter">
                        {s_updatedPreReminderDays}
                      </font>
                    )}
                  </div>
                  <div className="flex00a fontItalic">
                    {"day(s) before due date"}
                  </div>
                </div>
              ) : (
                <div style={{width:"8em"}}>
                  <font className="fontItalic fontTextLighter">
                    {"--Pre-Reminder is Turned Off--"}
                  </font>
                </div>
              ))}
            </div>
          </div>
        </TaskEditLeftField>
        <TaskEditLeftField p_fieldDisplay="Post-Reminder" p_helpMessage="A post-reminder will send notifications every X days after the due date if this task is still not completed.">
          <div className="displayFlexRowVc" style={{height:"3em"}}>
            <div className="flex00a lrMargin">
              <LibraryReact.Switch
                p_isOnTF={postReminderIsOnTF}
                p_tabIndex={9}
                f_onClick={((canEditPrePostRemindersTF) ? (this.onswitch_post_reminder_on_off) : (undefined))}
              />
            </div>
            <div className="flex11a lrMargin">
              {((postReminderIsOnTF) ? (
                <div className="displayFlexRowVc flexWrap">
                  <div className="flex00a rMargin fontItalic">
                    {"Remind me every"}
                  </div>
                  <div className="flex00a rMargin">
                    {(canEditPrePostRemindersTF) ? (
                      <LibraryReact.Integer
                        p_value={s_updatedPostReminderDays}
                        p_min={0}
                        p_max={1000000}
                        p_styleObj={{width:"4em"}}
                        p_tabIndex={10}
                        f_onChange={this.onchange_post_reminder_days}
                      />
                    ) : (
                      <font className="fontBold fontTextLighter">
                        {s_updatedPostReminderDays}
                      </font>
                    )}
                  </div>
                  <div className="flex00a fontItalic">
                    {"day(s) after due date"}
                  </div>
                </div>
              ) : (
                <div style={{width:"8em"}}>
                  <font className="fontItalic fontTextLighter">
                    {"--Post-Reminder is Turned Off--"}
                  </font>
                </div>
              ))}
            </div>
          </div>
        </TaskEditLeftField>
      </>
    );

    const descriptionComponent = (
      <>
        <div className="flex00a smallBottomMargin">
          <font className="font11 fontItalic fontTextLight">
            {"Task Description:"}
          </font>
        </div>
        {(canEditDescriptionTF) ? (
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
            p_valueRaw={s_updatedDescription}
            p_tabIndex={1}
            f_onChangeOrOnSelect={this.onchange_description}
          />
        ) : (
          s_updatedDescription
        )}
      </>
    );

    var floatingBoxContentComponent = null;
    if(c_isMobileTF) {
      floatingBoxContentComponent = (
        <div className="flex11a yScroll yScrollBottomPad smallFullPad bgTasksLightestBlue">
          {leftSideComponents}
          <div className="hugeTopMargin displayFlexColumn" style={{height:"20em"}}>
            {descriptionComponent}
          </div>
        </div>
      );
    }
    else {
      floatingBoxContentComponent = (
        <div className="flex11a displayFlexRow">
          <div className="flex00a yScroll yScrollBottomPad smallFullPad bgTasksLightestBlueGradientLR" style={{flexBasis:"35em", borderRight:"solid 1px #ddd"}}>
            {leftSideComponents}
          </div>
          <div className="flex11a displayFlexColumn smallFullPad" style={{flexBasis:"100em"}}>
            {descriptionComponent}
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="tasks"
        p_title={((isAddingNewTaskTF) ? ("Create New Task"): ("Editing Task"))}
        p_cancelConfirmationMessage={((s_anyTaskFieldIsUpdatedTF) ? ("Are you sure you want to cancel editing this Task? Any entered data will be lost.") : (undefined))}
        f_onClickSave={this.onsave_task_item}
        f_onClickCancel={this.onclose_task_item}>
        {floatingBoxContentComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

function TaskEditLeftField(props) { //props: p_fieldDisplay, p_fieldDisplayItalicTF, p_helpMessage, children
  const p_fieldDisplay = props.p_fieldDisplay;
  const p_fieldDisplayItalicTF = JSFUNC.prop_value(props.p_fieldDisplayItalicTF, false);
  const p_helpMessage = props.p_helpMessage;

  return(
    <div className="displayFlexRowVc smallBottomMargin">
      <div className="flex00a displayFlexRowVc rMargin" style={{flexBasis:"9em"}}>
        <div className="flex11a">
          <font className={"fontBlue " + ((p_fieldDisplayItalicTF) ? ("fontItalic") : (""))}>
            {p_fieldDisplay + ":"}
          </font>
        </div>
        {(JSFUNC.string_is_filled_out_tf(p_helpMessage)) &&
          <div className="flex00a">
            <CEGeneralReact.HelpHoverIcon>
              {p_helpMessage}
            </CEGeneralReact.HelpHoverIcon>
          </div>
        }
      </div>
      <div className="flex11a positionRelative">
        {props.children}
      </div>
    </div>
  );
}

function TasksImportantIcon(props) { //props: p_importantTF, p_completedTF, p_width
  var bgColor = ((props.p_completedTF) ? ("#aaa") : ("#bbb"));
  if(props.p_importantTF) {
    bgColor = ((props.p_completedTF) ? ("#a88") : ("#d44"));
  }

  return(
    <div
      className="flex00a displayFlexColumnHcVc"
      style={{flexBasis:props.p_width, backgroundColor:bgColor}}>
      <font className="fontWhite">
        {"!"}
      </font>
    </div>
  );
}










//============================================================================================================================
//Notifications
//============================================================================================================================
export const NotificationsPanel = inject("RightPanelMobx", "DatabaseMobx", "UserMobx")(observer(
class NotificationsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_notificationsPanelState: "notifications",
      s_newBroadcastUserPerEmailIDsComma: "",
      s_newBroadcastMessage: "",
      s_newBroadcastShowErrorsTF: false
    }
  }

  onclick_dismiss_all_notifications = () => {
    const notificationsArrayOfObjs = this.props.RightPanelMobx.c_notificationsArrayOfObjs;
    const allNotificationIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(notificationsArrayOfObjs, "id");
    this.props.RightPanelMobx.a_notification_dismiss(allNotificationIDsArray);
  }

  onclick_create_new_broadcast = () => {
    this.setState({s_notificationsPanelState:"createBroadcast"});
  }

  onselect_broadcast_users = (i_newValue) => {
    this.setState({s_newBroadcastUserPerEmailIDsComma:i_newValue});
  }

  onchange_broadcast_message = (i_newValue) => {
    this.setState({s_newBroadcastMessage:i_newValue});
  }

  onclick_send_broadcast = () => {
    if(this.state.s_newBroadcastUserPerEmailIDsComma === "" || this.state.s_newBroadcastMessage === "") {
      this.setState({s_newBroadcastShowErrorsTF:true});
    }
    else {
      const userPerEmailIDsArray = JSFUNC.convert_comma_list_to_int_array(this.state.s_newBroadcastUserPerEmailIDsComma);
      const broadcastMessageWithFrom = "Broadcast from " + this.props.UserMobx.c_userName + "\n\n'"  + this.state.s_newBroadcastMessage + "'";
      const broadcastClickAction = "readBroadcast";
      this.props.RightPanelMobx.a_notification_send_broadcast_notifications(userPerEmailIDsArray, broadcastMessageWithFrom, broadcastClickAction);

      this.setState({s_notificationsPanelState:"broadcastSuccess"});
    }
  }

  onclick_cancel_broadcast = () => {
    this.setState({
      s_notificationsPanelState: "notifications",
      s_newBroadcastUserPerEmailIDsComma: "",
      s_newBroadcastMessage: "",
      s_newBroadcastShowErrorsTF: false
    });
  }

  render() {
    const notificationsPanelState = this.state.s_notificationsPanelState;
    const newBroadcastUserPerEmailIDsComma = this.state.s_newBroadcastUserPerEmailIDsComma;
    const newBroadcastMessage = this.state.s_newBroadcastMessage;
    const newBroadcastShowErrorsTF = this.state.s_newBroadcastShowErrorsTF;

    const notificationsArrayOfObjs = this.props.RightPanelMobx.c_notificationsArrayOfObjs;
    const c_numNotifications = this.props.RightPanelMobx.c_numNotifications;

    if((notificationsPanelState === "createBroadcast") || (notificationsPanelState === "broadcastSuccess")) {
      const selectUsersErrorTF = (newBroadcastShowErrorsTF && !JSFUNC.selectmulti_is_filled_out_tf(newBroadcastUserPerEmailIDsComma));
      const messageErrorTF = (newBroadcastShowErrorsTF && !JSFUNC.text_or_number_is_filled_out_tf(newBroadcastMessage));
      return(
        <div className="flex11a yScroll smallFullPad">
          <div className="textCenter">
            <font className="font12 fontBold fontTextLighter">
              {"Create a New Broadcast"}
            </font>
          </div>
          <div className="microTopMargin">
            <font className="fontItalic fontTextLight">
              {"A broadcast will send your message below to selected CaptureExec users in their Notifications panel"}
            </font>
          </div>
          {(notificationsPanelState === "createBroadcast") ? (
            <>
              <div className="medTopMargin">
                <font className="font11 fontBlue">
                  {"Selected Recipients"}
                </font>
              </div>
              <div className="microTopMargin">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiUsersPerEmailNotDeactivatedFieldTypeObj}
                  p_valueRaw={newBroadcastUserPerEmailIDsComma}
                  p_valuesToNotIncludeArray={undefined}
                  p_tabIndex={1}
                  p_title="Select Users to send Broadcast Message to"
                  p_errorTF={selectUsersErrorTF}
                  f_onChangeOrOnSelect={this.onselect_broadcast_users}
                />
              </div>
              {(selectUsersErrorTF) &&
                <div className="microTopMargin">
                  <CEGeneralReact.ErrorText p_text="Select at least 1 User to send the broadcast to" />
                </div>
              }
              <div className="medTopMargin">
                <font className="font11 fontBlue">
                  {"Broadcast Message"}
                </font>
              </div>
              <div className="microTopMargin">
                <LibraryReact.Textarea
                  p_value={newBroadcastMessage}
                  p_styleObj={{height:"15em"}}
                  p_tabIndex={2}
                  p_errorTF={messageErrorTF}
                  f_onChange={this.onchange_broadcast_message}
                />
              </div>
              {(messageErrorTF) &&
                <div className="microTopMargin">
                  <CEGeneralReact.ErrorText p_text="Fill out your broadcast message above" />
                </div>
              }
              <div className="medTopMargin displayFlexRowHcVc">
                <div className="flex00a lrMargin">
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text="Send Broadcast"
                    p_tabIndex={3}
                    f_onClick={this.onclick_send_broadcast}
                  />
                </div>
                <div className="flex00a lrMargin">
                  <CEGeneralReact.CEButton
                    p_type="gray"
                    p_text="Cancel"
                    p_tabIndex={4}
                    f_onClick={this.onclick_cancel_broadcast}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="hugeTopMargin textCenter">
                <font className="font12 fontBold fontBlue">
                  {"Your Broadcast was sent successfully"}
                </font>
              </div>
              <div className="hugeTopMargin displayFlexColumnHcVc">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Close"
                  p_tabIndex={1}
                  f_onClick={this.onclick_cancel_broadcast}
                />
              </div>
            </>
          )}
        </div>
      );
    }

    return(
      <>
        <div className="flex11a yScroll lrPad smallTopPad yScrollBottomPad">
          {(c_numNotifications === 0) ? (
            <font className="fontItalic fontTextLighter">
              {"You do not have any notifications at this time"}
            </font>
          ) : (
            <>
              <div className="displayFlexRow smallBottomMargin">
                <div className="flex11a" />
                <div className="flex00a">
                  <CEGeneralReact.ButtonWithConfirmBox
                    p_buttonType="add"
                    p_buttonText="Dismiss All"
                    p_confirmType="confirm"
                    p_confirmMessage="Are you sure you want to dismiss all Notifications currently in your Notifications Panel?"
                    p_button1Name="Confirm"
                    f_onClickConfirm={this.onclick_dismiss_all_notifications}
                  />
                </div>
              </div>
              {notificationsArrayOfObjs.map((m_notificationObj) =>
                <NotificationItem
                  key={m_notificationObj.id}
                  p_notificationObj={m_notificationObj}
                />
              )}
            </>
          )}
        </div>
        <div className="flex00a displayFlexColumnHcVc borderT1ddd" style={{flexBasis:"3em"}}>
          <CEGeneralReact.CEButton
            p_type="blue"
            p_text="Create New Broadcast"
            f_onClick={this.onclick_create_new_broadcast}
          />
        </div>
      </>
    );
  }
}));

const NotificationItem = inject("CaptureExecMobx", "RightPanelMobx", "DatabaseMobx")(observer(
class NotificationItem extends Component { //props: p_notificationObj
  constructor(props) {
    super(props);
    this.state = {
      s_isExpandedTF: false,
      s_confirmingDismissTF: false
    }
  }

  onclick_notification_item = () => {
    if(this.props.p_notificationObj.click_action !== "") { //do not turn gold if there is no click action
      this.props.RightPanelMobx.a_notification_set_click_action_notification_id(this.props.p_notificationObj.id);
      this.props.RightPanelMobx.a_notification_handle_click_action(this.props.p_notificationObj.click_action, this.props.p_notificationObj.date, this.props.p_notificationObj.message);
    }
    this.props.RightPanelMobx.a_notification_mark_as_read(this.props.p_notificationObj.id);
  }

  onclick_toggle_notification_is_expanded_tf = () => {
    this.setState((i_state, i_props) => ({
      s_isExpandedTF: (!i_state.s_isExpandedTF)
    }));
  }

  onclick_dismiss_notification = () => {
    this.setState({s_confirmingDismissTF:true});
  }

  onclick_confirm_dismiss = () => {
    this.setState({s_confirmingDismissTF:false});
    this.props.RightPanelMobx.a_notification_dismiss(this.props.p_notificationObj.id);
  }

  onclick_cancel_dismiss = () => {
    this.setState({s_confirmingDismissTF:false});
  }

  render() {
    const isExpandedTF = this.state.s_isExpandedTF;
    const confirmingDismissTF = this.state.s_confirmingDismissTF;

    const notificationObj = this.props.p_notificationObj;

    const nowDate = this.props.CaptureExecMobx.o_nowDate;
    const notificationsClickActionNotificationID = this.props.RightPanelMobx.o_notificationsClickActionNotificationID;

    var boxShadowClass = undefined;
    if(notificationObj.id === notificationsClickActionNotificationID) {
      boxShadowClass = "bgLightGoldGradient";
    }
    else if(notificationObj.read_01 === 0) {
      boxShadowClass = "bgWhite notificationsUnreadBoxShadow";
    }
    else {
      boxShadowClass = "bgWhite";
    }

    var dateMask = undefined;
    if(notificationObj.date === nowDate) {
      dateMask = "Today";
    }
    else {
      dateMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(notificationObj.date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    }

    const hasClickActionTF = (notificationObj.click_action !== "");

    return(
      <>
        <div
          className={"displayFlexColumn smallBottomMargin border1bbb smallFullPad hoverLighterBlueGradient " + boxShadowClass + " " + ((hasClickActionTF) ? ("cursorPointer") : (""))}
          onClick={this.onclick_notification_item}>
          <div className="flex00a displayFlexRowVc smallBottomMargin">
            <div className="flex11a">
              <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                {dateMask}
              </LibraryReact.Nowrap>
            </div>
            <LibraryReact.InteractiveDiv
              p_class="flex00a lrPad hoverFontUnderline cursorPointer"
              f_onClick={this.onclick_dismiss_notification}>
              <font className="fontBlue">
                {"dismiss"}
              </font>
            </LibraryReact.InteractiveDiv>
          </div>
          <div className="flex00a microBottomMargin overflowHidden breakWord" style={{maxHeight:((isExpandedTF) ? (undefined) : ("4em"))}}>
            <font className="">
              {notificationObj.message}
            </font>
          </div>
          <LibraryReact.InteractiveDiv
            p_class="flex00a displayFlexColumnHcVc hoverFontUnderline cursorPointer"
            f_onClick={this.onclick_toggle_notification_is_expanded_tf}>
            <font className="font09 fontItalic fontTextLighter">
              {((isExpandedTF) ? ("Read Less") : ("Read More"))}
            </font>
          </LibraryReact.InteractiveDiv>
        </div>
        {(confirmingDismissTF) &&
          <CEGeneralReact.ConfirmBox
            p_type="confirm"
            p_title="Dismiss Notification"
            p_button1Name="Dismiss"
            f_onClickConfirm={this.onclick_confirm_dismiss}
            f_onClickCancel={this.onclick_cancel_dismiss}>
            {"Dismiss this Notification?\n\n'" + notificationObj.message + "'"}
          </CEGeneralReact.ConfirmBox>
        }
      </>
    );
  }
}));


export const BroadcastReaderFloatingBox = inject("RightPanelMobx", "DatabaseMobx")(observer(
class BroadcastReaderFloatingBox extends Component {
  onclick_cancel_broadcast_reader = () => {
    this.props.RightPanelMobx.a_set_broadcast_reader_notification_date_and_message(undefined, undefined);
  }

  render() {
    const broadcastReaderNotificationDate = this.props.RightPanelMobx.o_broadcastReaderNotificationDate;
    const broadcastReaderNotificationMessage = this.props.RightPanelMobx.o_broadcastReaderNotificationMessage;

    if(broadcastReaderNotificationMessage === undefined) {
      return(null);
    }

    const broadcastDateMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(broadcastReaderNotificationDate, this.props.DatabaseMobx.c_genericDateFieldTypeObj);

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="broadcastReader"
        p_title={"Broadcast Message on " + broadcastDateMask}
        f_onClickCancel={this.onclick_cancel_broadcast_reader}>
        <div className="flex11a medFullPad yScroll">
          {broadcastReaderNotificationMessage}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));






//=================================================================================================================================
//Help Panel
//=================================================================================================================================





const HelpPanelContent = inject("RightPanelMobx")(observer(
class HelpPanelContent extends Component {
  componentDidMount() {
    this.props.RightPanelMobx.a_help_panel_load_help_panel_content_if_not_yet_loaded();
  }

  onclick_close_help_panel = () => {
    this.props.RightPanelMobx.a_set_help_panel_is_open_tf(false);
  }

  render() {
    const o_helpPanelContentIsLoadingTF = this.props.RightPanelMobx.o_helpPanelContentIsLoadingTF;
    const topBarHelpPanelObjOrUndefined = this.props.RightPanelMobx.c_topBarHelpPanelObjOrUndefined;

    var helpPanelContentComponent = null;
    if(o_helpPanelContentIsLoadingTF) {
      helpPanelContentComponent = (
        <CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>
          {"Loading Help Content"}
        </CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>
      );
    }
    else {
      helpPanelContentComponent = (
        <div className="flex11a yScroll">
          {(topBarHelpPanelObjOrUndefined !== undefined) &&
            <>
              <HelpPanelPreviewSelectedHelpPanelTitle p_panelTitle={topBarHelpPanelObjOrUndefined.panel_title} />
              {topBarHelpPanelObjOrUndefined.collapsibleHeadersArrayOfObjs.map((m_helpCollapsibleHeaderObj) =>
                <HelpPanelPreviewCollapsibleHeader p_helpCollapsibleHeaderObj={m_helpCollapsibleHeaderObj} />
              )}
              <div style={{marginTop:"1em"}} />
            </>
          }
          <HelpPanelSystemCurrentHelpPanel />
        </div>
      );
    }

    return(
      <RightPanelOrHelpPanelContentShell
        p_topBorderColor="844"
        p_panelTitle="Help Panel"
        f_onClickClose={this.onclick_close_help_panel}>
        {helpPanelContentComponent}
      </RightPanelOrHelpPanelContentShell>
    );
  }
}));


const HelpPanelSystemCurrentHelpPanel = inject("RightPanelMobx")(observer(
class HelpPanelSystemCurrentHelpPanel extends Component {
  render() {
    const systemCurrentHelpPanelObjOrUndefined = this.props.RightPanelMobx.c_systemCurrentHelpPanelObjOrUndefined;

    if(systemCurrentHelpPanelObjOrUndefined === undefined) {
      return(
        <div className="displayFlexColumnHcVc medFullPad">
          <font className="fontItalic fontTextLighter">
            {"--Help for this part of CaptureExec is under construction--"}
          </font>
        </div>
      );
    }

    return(
      <>
        <HelpPanelPreviewSelectedHelpPanelTitle p_panelTitle={systemCurrentHelpPanelObjOrUndefined.panel_title} />
        {(systemCurrentHelpPanelObjOrUndefined.collapsibleHeadersArrayOfObjs.length === 0) ? (
          <div className="displayFlexColumnHcVc medFullPad">
            <font className="fontItalic fontTextLighter">
              {"--Help for '" + systemCurrentHelpPanelObjOrUndefined.panel_title + "' is under construction--"}
            </font>
          </div>
        ) : (
          systemCurrentHelpPanelObjOrUndefined.collapsibleHeadersArrayOfObjs.map((m_helpCollapsibleHeaderObj) =>
            <HelpPanelPreviewCollapsibleHeader p_helpCollapsibleHeaderObj={m_helpCollapsibleHeaderObj} />
          )
        )}
      </>
    );
  }
}));


function HelpPanelPreviewSelectedHelpPanelTitle(props) { //props: p_panelTitle
  const panelTitle = props.p_panelTitle;

  return(
    <div className="textCenter" style={{padding:"0.5em 1em"}}>
      <font className="font17 fontBold">
        {panelTitle}
      </font>
    </div>
  );
}


class HelpPanelPreviewCollapsibleHeader extends Component { //props: p_helpCollapsibleHeaderObj
  constructor(props) {
    super(props);
    this.state = {
      s_expandedTF: false
    }
  }

  onclick_collapsible_header = () => {
    this.setState({s_expandedTF:!this.state.s_expandedTF});
  }

  render() {
    const s_expandedTF = this.state.s_expandedTF;

    const p_helpCollapsibleHeaderObj = this.props.p_helpCollapsibleHeaderObj;

    return(
      <div className="" style={{borderTop:"solid 1px #f5f5f5", borderBottom:"solid 1px #ccc"}}>
        <div
          className={"displayFlexRow cursorPointer " + ((s_expandedTF) ? ("bgDarkGrayGradient") : ("bgLightesterGray hoverLighterBlueGradient"))}
          style={{padding:"0.5em 0"}}
          onClick={this.onclick_collapsible_header}>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
            <font className={"font20" + ((s_expandedTF) ? (" fontWhite") : (""))}>
              {((s_expandedTF) ? ("-") : ("+"))}
            </font>
          </div>
          <div className="flex11a displayFlexColumnVc" style={{paddingRight:"1em"}}>
            <font className={"font14" + ((s_expandedTF) ? (" fontWhite") : (""))}>
              {p_helpCollapsibleHeaderObj.header_title}
            </font>
          </div>
        </div>
        {(s_expandedTF) &&
          <div className="bgWhite">
            {p_helpCollapsibleHeaderObj.headerContentArrayOfObjs.map((m_headerContentObj) =>
              <HelpPanelPreviewHeaderContent p_headerContentObj={m_headerContentObj} />
            )}
          </div>
        }
      </div>
    );
  }
}


const HelpPanelPreviewHeaderContent = inject("CaptureExecMobx")(observer(
class HelpPanelPreviewHeaderContent extends Component { //props: p_headerContentObj
  render() {
    const p_headerContentObj = this.props.p_headerContentObj;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    const contentTypeFlag = p_headerContentObj.content_type_flag;
    const contentText = p_headerContentObj.text;

    const headerContentPadding = "1em 0.8em";

    if(contentTypeFlag === 1) { //subheader
      return(
        <div className="" style={{padding:headerContentPadding}}>
          <font className="font15 fontBold fontItalic fontTextLight">
            {contentText}
          </font>
        </div>
      );
    }
    else if(contentTypeFlag === 2) { //text
      return(
        <div className="" style={{padding:headerContentPadding}}>
          <HelpPanelTextWithDoubleBracketsTranslated p_text={contentText} />
        </div>
      );
    }
    else if(contentTypeFlag === 3) { //image
      return(
        <div className="displayFlexColumnHcVc" style={{margin:headerContentPadding}}>
          <div className="boxShadowDarkGray border1bbb bgLighterGray">
            <LibraryReact.Image
              p_src={c_productWebsiteGraphicsSubfolderAddress + "/helppanels/" + contentText}
              p_alt={contentText}
              p_styleObj={{maxWidth:"100%"}}
            />
          </div>
        </div>
      );
    }
    else if(contentTypeFlag === 4) { //doc
      return(
        <div className="" style={{padding:headerContentPadding}}>
          <a href={c_productWebsiteGraphicsSubfolderAddress + "/helppanels/" + contentText} target="blank">
            {"Download File"}
          </a>
        </div>
      );
    }
    else if(contentTypeFlag === 5) { //hyperlink
      return(
        <div className="" style={{padding:headerContentPadding}}>
          <a href={contentText} target="blank">
            {contentText}
          </a>
        </div>
      );
    }

    return(null);
  }
}));


function HelpPanelTextWithDoubleBracketsTranslated(props) {//props: p_text
  const p_text = props.p_text;

  var textArrayOfObjs = [];

  const leftBracketSplitArray = p_text.split("[[");
  const numLeftBracketSplits = leftBracketSplitArray.length;
  if((numLeftBracketSplits % 2) === 0) {
    return(
      <font style={{color:"#e0f"}}>
        {p_text}
      </font>
    );
  }

  textArrayOfObjs.push({
    text: leftBracketSplitArray[0],
    fontClass: undefined,
    fontColor: undefined,
  });

  for(let i = 1; i < numLeftBracketSplits; i++) {
    var leftBracketSplitString = leftBracketSplitArray[i];
    var rightBracketSplitArray = leftBracketSplitString.split("]]");
    if(rightBracketSplitArray.length !== 2) {
      return(
        <font style={{color:"#f0e"}}>
          {p_text}
        </font>
      );
    }
    else {
      var bracketWord = rightBracketSplitArray[0];
      var textAfterBrackets = rightBracketSplitArray[1];
      var fontClass = undefined;
      var fontColor = undefined;
      if(bracketWord[0] !== "/") {
        const redColor = "#c00";
        const greenColor = "#090";
        const blueColor = "#00c";
        const yellowColor = "#bb0";
        const orangeColor = "#b60";
        const purpleColor = "#607";
        if(bracketWord === "bold") { fontClass = "fontBold"; }
        else if(bracketWord === "italic") { fontClass = "fontItalic"; }
        else if(bracketWord === "underline") { fontClass = "fontUnderline"; }
        else if(bracketWord === "boldunderline") { fontClass = "fontBold fontUnderline"; }
        else if(bracketWord === "red") { fontColor = redColor; }
        else if(bracketWord === "redbold") { fontClass = "fontBold"; fontColor = redColor; }
        else if(bracketWord === "redunderline") { fontClass = "fontUnderline"; fontColor = redColor; }
        else if(bracketWord === "redboldunderline") { fontClass = "fontBold fontUnderline"; fontColor = redColor; }
        else if(bracketWord === "green") { fontColor = greenColor; }
        else if(bracketWord === "greenbold") { fontClass = "fontBold"; fontColor = greenColor; }
        else if(bracketWord === "greenunderline") { fontClass = "fontUnderline"; fontColor = greenColor; }
        else if(bracketWord === "greenboldunderline") { fontClass = "fontBold fontUnderline"; fontColor = greenColor; }
        else if(bracketWord === "blue") { fontColor = blueColor; }
        else if(bracketWord === "bluebold") { fontClass = "fontBold"; fontColor = blueColor; }
        else if(bracketWord === "blueunderline") { fontClass = "fontUnderline"; fontColor = blueColor; }
        else if(bracketWord === "blueboldunderline") { fontClass = "fontBold fontUnderline"; fontColor = blueColor; }
        else if(bracketWord === "yellow") { fontColor = yellowColor; }
        else if(bracketWord === "yellowbold") { fontClass = "fontBold"; fontColor = yellowColor; }
        else if(bracketWord === "yellowunderline") { fontClass = "fontUnderline"; fontColor = yellowColor; }
        else if(bracketWord === "yellowboldunderline") { fontClass = "fontBold fontUnderline"; fontColor = yellowColor; }
        else if(bracketWord === "orange") { fontColor = orangeColor; }
        else if(bracketWord === "orangebold") { fontClass = "fontBold"; fontColor = orangeColor; }
        else if(bracketWord === "orangeunderline") { fontClass = "fontUnderline"; fontColor = orangeColor; }
        else if(bracketWord === "orangeboldunderline") { fontClass = "fontBold fontUnderline"; fontColor = orangeColor; }
        else if(bracketWord === "purple") { fontColor = purpleColor; }
        else if(bracketWord === "purplebold") { fontClass = "fontBold"; fontColor = purpleColor; }
        else if(bracketWord === "purpleunderline") { fontClass = "fontUnderline"; fontColor = purpleColor; }
        else if(bracketWord === "purpleboldunderline") { fontClass = "fontBold fontUnderline"; fontColor = purpleColor; }
      }

      textArrayOfObjs.push({
        text: textAfterBrackets,
        fontClass: fontClass,
        fontColor: fontColor,
      });
    }
  }

  return(
    textArrayOfObjs.map((m_textObj) =>
      <font className={m_textObj.fontClass} style={{color:m_textObj.fontColor}}>
        {m_textObj.text}
      </font>
    )
  );
}

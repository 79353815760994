import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import OpenCaptureMobx from '../OpenCapture/OpenCaptureMobx.js';
import AdminIntegrationsMobx from '../AdminIntegrations/AdminIntegrationsMobx.js';

class CapturesMobx {
  //========================================================================================
  //constant values
  k_cstEditCellsModeOpenCaptureColumnWidthEm = 2;

  //observable values
  o_viewIsLoadingTF = false;

  //capture table view
  o_captureTableOverrideSortFieldID = -1; //-1 is a flag to use the sort as defined by the selected capture table preset's sort preset, if set by clicking one of the table column headers, then the table is sorted by that field
  o_captureTableOverrideSortFieldIsAscTF = true;
  o_cstOrChartMaxNumCapturesDrawn = 50;
  o_captureTableInlineEditSingleCellValueRaw = undefined; //inline edit temp raw value while editing (when editing a textarea type field cell, this will hold the full textarea text loaded from the database)
  o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF = false; //inline edit - flag for php load single full textarea cell value for single capture/field is running
  o_captureTableCsvIsGeneratingTF = false;
  o_captureTableProcessingCapturesObjOrUndefined = undefined; //show a floating box with progress of recursive capture deletion if set to an object with properties "currentlyProcessingCaptureName", "currentlyProcessingCaptureNumber", "totalNumCapturesToProcess"

  //progress/gantt chart view
  o_chartZoomCoolDownIsRunningTF = false;

  //master/gantt preset editor
  o_masterPresetEditorOpenTF = false; //floating box editor of capture table presets and their cstColumns/filters/sorts
  o_presetEditorOpenPresetType = undefined; //undefined, "master", "cstColumns", "filter", "sort"
  o_presetEditorOpenPresetID = undefined;
  o_presetEditorCreatingNewPresetType = undefined; //undefined, "master", "cstColumns", "filter", "sort"
  o_ganttDatesPresetEditorOpenTF = false;
  o_ganttDatesOpenPresetID = undefined;

  //create new captures
  o_createNewCaptureIsOpenTF = false; //floating box for create new capture process
  o_createNewCaptureOpportunityNameLowercase = "";
  o_createNewCaptureSelectedDocumentsCardFoldersPresetID = -1;

  constructor() {
    makeObservable(this, {
      o_viewIsLoadingTF: observable,
      o_captureTableOverrideSortFieldID: observable,
      o_captureTableOverrideSortFieldIsAscTF: observable,
      o_cstOrChartMaxNumCapturesDrawn: observable,
      o_captureTableInlineEditSingleCellValueRaw: observable,
      o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF: observable,
      o_captureTableCsvIsGeneratingTF: observable,
      o_captureTableProcessingCapturesObjOrUndefined: observable,
      o_chartZoomCoolDownIsRunningTF: observable,
      o_masterPresetEditorOpenTF: observable,
      o_presetEditorOpenPresetType: observable,
      o_presetEditorOpenPresetID: observable,
      o_presetEditorCreatingNewPresetType: observable,
      o_ganttDatesPresetEditorOpenTF: observable,
      o_ganttDatesOpenPresetID: observable,
      o_createNewCaptureIsOpenTF: observable,
      o_createNewCaptureOpportunityNameLowercase: observable,
      o_createNewCaptureSelectedDocumentsCardFoldersPresetID: observable,

      c_expandedSearchFieldsArrayOfObjs: computed,
      c_expandedSearchFieldsUsedInSearchArrayOfObjs: computed,
      c_searchAllCapturesOnlySearchedColumnsMaskedArrayOfObjs: computed,
      c_quickAccessMasterPresetIDsArray: computed,
      c_quickAccessMasterPresetIDSelected: computed,
      c_quickAccessCaptureTablePresetsArrayOfObjs: computed,
      c_selectedCaptureTableMasterPresetObj: computed,
      c_cstColumnPresetObj: computed,
      c_filterPresetObj: computed,
      c_sortPresetObj: computed,
      c_cstColumnsArrayOfObjs: computed,
      c_filterPresetExpandedFiltersArrayOfObjs: computed,
      c_sortsArrayOfObjs: computed,
      c_selectedSortFieldsAndDirectionsObj: computed,
      c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs: computed,
      c_filteredCapturesMapOfMaps: computed,
      c_cstRowHeightFieldTypeObj: computed,
      c_stageViewBoxSizeFieldTypeObj: computed,
      c_ganttChartRowHeightFieldTypeObj: computed,
      c_progressChartRowHeightFieldTypeObj: computed,
      c_cstSumRowColumnsArrayOfObjsOrUndefined: computed,
      c_cstFilteredMaskedCaptureValuesArrayOfObjs: computed,
      c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs: computed,
      c_ganttOrProgressChartSortedFilteredCapturesArrayOfObjs: computed,
      c_numFilteredCaptures: computed,
      c_captureTableTotalWidthEm: computed,
      c_captureTableCurrentlyProcessingCapturesTF: computed,
      c_stageViewSelectedCaptureTypeStagesArrayOfObjs: computed,
      c_stageViewAtLeast1CaptureInSelectedCaptureTypeTF: computed,
      c_stageViewAtLeast1StageHasMoreCapturesThanLimitTF: computed,
      c_timelineChartTimeSpanObj: computed,
      c_presetMyCaptureTablePresetsArrayOfObjs: computed,
      c_presetPublicCaptureTablePresetsArrayOfObjs: computed,
      c_presetMyCstColumnPresetsArrayOfObjs: computed,
      c_presetPublicCstColumnPresetsArrayOfObjs: computed,
      c_presetMyFilterPresetsArrayOfObjs: computed,
      c_presetPublicFilterPresetsArrayOfObjs: computed,
      c_presetMySortPresetsArrayOfObjs: computed,
      c_presetPublicSortPresetsArrayOfObjs: computed,
      c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj: computed,
      c_presetEditorOpenPresetObj: computed,
      c_viewButtonEditorOpenCFSSelectMultiCaptureFieldsFieldTypeObj: computed,
      c_ganttDatesPresetMyPresetsArrayOfObjs: computed,
      c_ganttDatesPresetPublicPresetsArrayOfObjs: computed,
      c_selectGanttPresetsFieldTypeObj: computed,
      c_ganttPresetEditorOpenPresetObj: computed,
      c_ganttHasValidPresetSelectedTF: computed,
      c_ganttSelectedPresetObj: computed,
      c_createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs: computed,
      c_createNewCaptureDocumentsCardFoldersSelectedPresetName: computed,
      c_createNewCaptureDocumentsCardFoldersSortedTreeWithIndentsArrayOfObjs:computed,
      
      a_set_cst_or_chart_max_num_captures_drawn: action,
      a_load_limited_progress_chart_thin_log_data: action,
      a_set_view_is_loading_tf: action,
      a_set_captures_tab_selected_view_button: action,
      a_cst_column_sort_override: action,
      a_cst_column_resize: action,
      a_set_capture_table_inline_edit_single_cell_value_raw: action,
      a_set_capture_table_inline_edit_single_cell_full_textarea_is_loading_tf: action,
      a_capture_table_inline_edit_load_single_cell_full_textarea_from_capture_id_and_field_db_name: action,
      a_generate_and_download_capture_table_report_csv: action,
      a_generate_and_download_capture_table_report_csv_internal: action,
      a_set_capture_table_csv_is_generating_tf: action,
      a_mass_edit_field_for_all_captures_in_capture_table: action,
      a_mass_edit_captures_recursive_edit_next_capture: action,
      a_delete_all_captures_in_capture_table: action,
      a_delete_all_captures_recursive_delete_next_capture: action,
      a_set_capture_table_processing_captures_obj_or_undefined: action,
      a_set_capture_table_preset_editor_open_tf: action,
      a_remove_master_preset_from_quick_access: action,
      a_resort_selected_quick_access_master_presets: action,
      a_create_new_master_columns_filter_sort_preset: action,
      a_delete_master_columns_filter_sort_preset: action,
      a_update_preset_field: action,
      a_cfs_preset_add_capture_field_items: action,
      a_update_cfs_field_item_field: action,
      a_remove_cfs_field_item_from_preset: action,
      a_copy_preset_with_new_name: action,
      a_set_gantt_dates_preset_editor_open_tf: action,
      a_set_preset_editor_open_preset_type_and_id: action,
      a_set_preset_editor_creating_new_preset_type: action,
      a_set_gantt_dates_preset_editor_open_preset_id: action,
      a_select_gantt_dates_preset: action,
      a_create_new_gantt_preset: action,
      a_delete_gantt_preset: action,
      a_update_gantt_preset_field: action,
      a_add_dates_to_gantt_preset: action,
      a_update_gantt_date_color: action,
      a_delete_gantt_date_from_preset: action,
      a_copy_gantt_dates_preset_with_new_name: action,
      a_timeline_chart_update_start_end_dates: action,
      a_timeline_zoom_in_or_out: action,
      a_timeline_set_zoom_cool_down_is_running_tf: action,
      a_timeline_zoom_to_single_month: action,
      a_set_create_new_capture_is_open_tf: action,
      a_set_create_new_capture_opportunity_name_lowercase: action,
      a_set_create_new_capture_selected_documents_card_folders_preset_id: action,
      a_create_new_capture_from_new_capture_process: action
    });
  }


  //================================================================================================================================================================================
  //================================================================================================================================================================================
  //computed values
  //search results captures
  get c_expandedSearchFieldsArrayOfObjs() { //expansion of search fields to append expanded field maps to each one
    var expandedSearchFieldsArrayOfObjs = [];
    for(let searchFieldMap of DatabaseMobx.o_tbl_a_search_fields.values()) {
      var expandedSearchFieldObj = JSFUNC.obj_from_map(searchFieldMap);
      var searchFieldID = expandedSearchFieldObj.field_id;
      var expandedFieldMap = DatabaseMobx.c_tbl_captures_fields.get(searchFieldID);
      if(expandedFieldMap !== undefined) { //skip fields that do not exist
        expandedSearchFieldObj.usedInSearchTF = (expandedSearchFieldObj.used_in_search_01 === 1);
        expandedSearchFieldObj.maskFieldName = "mask_fID" + searchFieldID;
        expandedSearchFieldObj.maskPlainTextFieldName = "maskplaintext_fID" + searchFieldID;
        expandedSearchFieldObj.sortFieldName = "sort_fID" + searchFieldID;
        expandedSearchFieldObj.searchFieldName = "search_fID" + searchFieldID;
        expandedSearchFieldObj.sortColumnTF = (expandedSearchFieldObj.sortcol_1no_2asc_3desc === 2 || expandedSearchFieldObj.sortcol_1no_2asc_3desc === 3);
        expandedSearchFieldObj.sortColumnIsAscTF = (expandedSearchFieldObj.sortcol_1no_2asc_3desc === 2);

        expandedSearchFieldObj.expandedFieldMap = expandedFieldMap;
        expandedSearchFieldObj.fieldDbName = expandedFieldMap.get("db_name");
        expandedSearchFieldObj.fieldDisplayName = expandedFieldMap.get("display_name");
        expandedSearchFieldObj.cstCellAlignClass = expandedFieldMap.get("cstCellAlignClass");

        expandedSearchFieldsArrayOfObjs.push(expandedSearchFieldObj);
      }
    }
    JSFUNC.sort_arrayOfObjs(expandedSearchFieldsArrayOfObjs, "sort", true);
    return(expandedSearchFieldsArrayOfObjs);
  }

  get c_expandedSearchFieldsUsedInSearchArrayOfObjs() {
    var expandedSearchFieldsUsedInSearchArrayOfObjs = [];
    for(let expandedSearchFieldObj of this.c_expandedSearchFieldsArrayOfObjs) {
      if(expandedSearchFieldObj.usedInSearchTF) {
        expandedSearchFieldsUsedInSearchArrayOfObjs.push(expandedSearchFieldObj);
      }
    }
    return(expandedSearchFieldsUsedInSearchArrayOfObjs);
  }

  get c_searchAllCapturesOnlySearchedColumnsMaskedArrayOfObjs() { //precomute mask value (lowercase) for all captures for all specified search columns
    //determine which column will be used to sort the captures
    var captureSortTF = false;
    var captureSortIsAscTF = false;
    for(let searchFieldObj of this.c_expandedSearchFieldsArrayOfObjs) {
      if(searchFieldObj.sortColumnTF) {
        captureSortTF = true;
        captureSortIsAscTF = searchFieldObj.sortColumnIsAscTF;
      }
    }

    //get the mask values for all columns of the search table for all captures
    var allCapturesOnlySearchedColumnsMaskedArrayOfObjs = [];
    for(let captureMap of DatabaseMobx.o_tbl_captures.values()) { //loop over all captures in system
      var searchResultsCaptureObj = this.create_search_results_capture_obj_from_capture_map(captureMap);
      allCapturesOnlySearchedColumnsMaskedArrayOfObjs.push(searchResultsCaptureObj);
    }

    //sort the captures if a sort column was specified
    if(captureSortTF) {
      JSFUNC.sort_arrayOfObjs(allCapturesOnlySearchedColumnsMaskedArrayOfObjs, "captureSortCol", captureSortIsAscTF);
    }

    return(allCapturesOnlySearchedColumnsMaskedArrayOfObjs);
  }


  create_search_results_capture_obj_from_capture_map(i_captureMap) {
    const c_expandedSearchFieldsArrayOfObjs = this.c_expandedSearchFieldsArrayOfObjs;

    //regardless of search fields, captureID is always included so that a selected capture from the search can be opened, and archive_date is always included for the admin to filter archived captures
    var searchResultsCaptureObj = {
      id: i_captureMap.get("id"),
      archive_date: i_captureMap.get("archive_date")
    };

    //add a mask field for every search field, add a search (lowercase) version of the mask field for all fields usedInSearchTF
    for(let searchFieldObj of c_expandedSearchFieldsArrayOfObjs) {
      //display mask and plaintext mask (for title)
      var valueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, searchFieldObj.expandedFieldMap, false);
      searchResultsCaptureObj[searchFieldObj.maskPlainTextFieldName] = valueMaskSortIfoCanEditObj.valueMaskPlainText;
      searchResultsCaptureObj[searchFieldObj.maskFieldName] = valueMaskSortIfoCanEditObj.valueMaskNoClickLinks;
      searchResultsCaptureObj[searchFieldObj.sortFieldName] = valueMaskSortIfoCanEditObj.valueSort;

      //lowercase search string of mask
      var valueMaskPlainTextStringLowercase = undefined;
      if(searchFieldObj.usedInSearchTF) {
        var valueMaskString = JSFUNC.num2str(valueMaskSortIfoCanEditObj.valueMaskPlainText);
        valueMaskPlainTextStringLowercase = valueMaskString.toLowerCase();
        searchResultsCaptureObj[searchFieldObj.searchFieldName] = valueMaskPlainTextStringLowercase;
      }

      //sort column
      if(searchFieldObj.sortColumnTF) {
        if(valueMaskPlainTextStringLowercase === undefined) {
          var valueMaskString = JSFUNC.num2str(valueMaskSortIfoCanEditObj.valueMaskPlainText);
          valueMaskPlainTextStringLowercase = valueMaskString.toLowerCase();
        }
        searchResultsCaptureObj["captureSortCol"] = valueMaskPlainTextStringLowercase;
      }
    }
    return(searchResultsCaptureObj);
  }


  any_search_results_capture_obj_field_matches_lowercase_search_term_tf(i_searchResultsCaptureObj, i_lowercaseSearchTermString, i_searchCapturesExactTF=false) {
    const c_expandedSearchFieldsUsedInSearchArrayOfObjs = this.c_expandedSearchFieldsUsedInSearchArrayOfObjs;

    if(i_searchCapturesExactTF) {
      for(let searchFieldObj of c_expandedSearchFieldsUsedInSearchArrayOfObjs) {
        var valueMaskPlainTextStringLowercase = i_searchResultsCaptureObj[searchFieldObj.searchFieldName];
        if(valueMaskPlainTextStringLowercase === i_lowercaseSearchTermString) {
          return(true);
        }
      }
    }
    else {
      for(let searchFieldObj of c_expandedSearchFieldsUsedInSearchArrayOfObjs) {
        var valueMaskPlainTextStringLowercase = i_searchResultsCaptureObj[searchFieldObj.searchFieldName];
        if(JSFUNC.input_lowercase_string_contains_lowercase_search_term_string_tf(valueMaskPlainTextStringLowercase, i_lowercaseSearchTermString)) {
          return(true);
        }
      }
    }
    
    return(false);
  }


  filtered_search_results_captures_from_capture_ids_array(i_captureIDsArray) {
    if(!JSFUNC.is_array(i_captureIDsArray)) {
      return(undefined);
    }

    if(i_captureIDsArray.length === 0) {
      return([]);
    }

    var searchResultsCapturesArrayOfObjs = [];
    const capturesArrayOfObjs = this.c_searchAllCapturesOnlySearchedColumnsMaskedArrayOfObjs;
    const totalNumCaptures = capturesArrayOfObjs.length;
    for(let captureID of i_captureIDsArray) {
      var c = 0;
      var idMatchFoundTF = false;
      while(c < totalNumCaptures && !idMatchFoundTF) {
        if(captureID === capturesArrayOfObjs[c].id) {
          searchResultsCapturesArrayOfObjs.push(capturesArrayOfObjs[c]);
          idMatchFoundTF = true;
        }
        c++;
      }
    }
    return(searchResultsCapturesArrayOfObjs);
  }



  //capture columns/filtering/sorting for all views
  get c_quickAccessMasterPresetIDsArray() { //capture table preset buttons available in quick access above the CST for this user
    return(JSFUNC.convert_comma_list_to_int_array(UserMobx.c_combinedUserObj.quick_access_master_preset_ids_comma));
  }

  get c_quickAccessMasterPresetIDSelected() {
    var quickAccessMasterPresetIDSelected = UserMobx.c_combinedUserObj.quick_access_master_preset_id_selected;
    if(!JSFUNC.in_array(quickAccessMasterPresetIDSelected, this.c_quickAccessMasterPresetIDsArray)) {
      quickAccessMasterPresetIDSelected = -2; //if the previously selected preset no longer exists in the system, use the CaptureExec default
    }
    return(quickAccessMasterPresetIDSelected);
  }

  get c_quickAccessCaptureTablePresetsArrayOfObjs() {
    var quickAccessCaptureTablePresetsArrayOfObjs = [];

    //all capture table presets designated to be in the quick access by the user, does not include the one currently being used
    for(let captureTablePresetID of this.c_quickAccessMasterPresetIDsArray) {
      var captureTablePresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_capture_table_presets", captureTablePresetID);
      var captureTablePresetObj = JSFUNC.obj_from_map(captureTablePresetMap);
      quickAccessCaptureTablePresetsArrayOfObjs.push(captureTablePresetObj);
    }

    //add the default "My Active Captures" as the last entry
    if((this.c_quickAccessMasterPresetIDSelected === -2) || (quickAccessCaptureTablePresetsArrayOfObjs.length === 0)) {
      quickAccessCaptureTablePresetsArrayOfObjs.push(this.my_active_captures_capture_table_preset_obj());
    }

    return(quickAccessCaptureTablePresetsArrayOfObjs);
  }

  get c_selectedCaptureTableMasterPresetObj() {
    return(this.compute_capture_table_view_button_obj_from_capture_table_view_button_id(this.c_quickAccessMasterPresetIDSelected));
  }

  get c_cstColumnPresetObj() {
    return(this.compute_cst_column_preset_obj_from_cst_column_preset_id(this.c_selectedCaptureTableMasterPresetObj.cst_column_preset_id));
  }

  get c_filterPresetObj() {
    return(this.compute_filter_preset_obj_from_filter_preset_id(this.c_selectedCaptureTableMasterPresetObj.filter_preset_id));
  }

  get c_sortPresetObj() {
    return(this.compute_sort_preset_obj_from_sort_preset_id(this.c_selectedCaptureTableMasterPresetObj.sort_preset_id));
  }

  get c_cstColumnsArrayOfObjs() {
    if(!this.c_cstColumnPresetObj.existsTF) {
      return([]);
    }

    var cstColumnsArrayOfObjs = undefined;
    if(this.c_cstColumnPresetObj.id === -2) {
      cstColumnsArrayOfObjs = this.my_active_captures_cst_columns_arrayOfObjs();
    }
    else {
      const tblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_f_cst_columns");
      cstColumnsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(tblRef, "cst_column_preset_id", this.c_cstColumnPresetObj.id, "sort", true);
    }

    for(let cstColumnObj of cstColumnsArrayOfObjs) {
      //fetch the expanded capture field map for this fieldID in this CST column
      var columnExpandedCaptureFieldMap = DatabaseMobx.tbl_row_map_from_id("tbl_captures_fields", cstColumnObj.capture_field_id);

      //set extra data fields used by the CST columns
      cstColumnObj.fieldDbName = columnExpandedCaptureFieldMap.get("db_name");
      cstColumnObj.fieldDisplayName = columnExpandedCaptureFieldMap.get("display_name");
      cstColumnObj.cstCellAlignClass = columnExpandedCaptureFieldMap.get("cstCellAlignClass");
      cstColumnObj.rawTextareaTF = columnExpandedCaptureFieldMap.get("rawTextareaTF");
      cstColumnObj.canCalculateCstSumRowTF = columnExpandedCaptureFieldMap.get("canCalculateCstSumRowTF");
    }

    return(cstColumnsArrayOfObjs);
  }

  get c_filterPresetExpandedFiltersArrayOfObjs() {
    var filtersArrayOfObjs = [];
    if(!this.c_filterPresetObj.existsTF) {
      filtersArrayOfObjs = [];
    }
    else if(this.c_filterPresetObj.id === -2) {
      filtersArrayOfObjs = this.my_active_captures_filters_arrayOfObjs();
    }
    else {
      filtersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_f_filters, "filter_preset_id", this.c_filterPresetObj.id);
    }

    return(this.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(filtersArrayOfObjs));
  }

  get c_sortsArrayOfObjs() {
    //override sort has been selected for 1 column
    if(this.o_captureTableOverrideSortFieldID > 0) {
      const overrideIsAsc01 = ((this.o_captureTableOverrideSortFieldIsAscTF) ? (1) : (0));
      return([{capture_field_id:this.o_captureTableOverrideSortFieldID, is_asc_01:overrideIsAsc01}]);
    }

    if(!this.c_sortPresetObj.existsTF) {
      return([]);
    }

    var sortsArrayOfObjs = undefined;
    if(this.c_sortPresetObj.id === -2) {
      sortsArrayOfObjs = this.my_active_captures_sorts_arrayOfObjs();
    }
    else {
      sortsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_f_sorts, "sort_preset_id", this.c_sortPresetObj.id, "sort", true);
    }

    return(sortsArrayOfObjs);
  }

  get c_selectedSortFieldsAndDirectionsObj() {
    return(this.create_sort_fields_and_directions_obj_from_sorts_arrayOfObjs(this.c_sortsArrayOfObjs));
  }

  compute_capture_table_view_button_obj_from_capture_table_view_button_id(i_captureTableViewButtonID) {
    const o_tbl_f_capture_table_presets = DatabaseMobx.o_tbl_f_capture_table_presets;

    var captureTablePresetObj = undefined;
    if(i_captureTableViewButtonID === -2) {
      captureTablePresetObj = this.my_active_captures_capture_table_preset_obj();
      captureTablePresetObj.existsTF = true;
    }
    else {
      const captureTablePresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_capture_table_presets", i_captureTableViewButtonID);
      captureTablePresetObj = JSFUNC.obj_from_map(captureTablePresetMap);
      captureTablePresetObj.existsTF = o_tbl_f_capture_table_presets.has(i_captureTableViewButtonID);
    }
    return(captureTablePresetObj);
  }

  compute_cst_column_preset_obj_from_cst_column_preset_id(i_cstColumnPresetID) {
    const o_tbl_f_cst_column_presets = DatabaseMobx.o_tbl_f_cst_column_presets;

    var cstColumnPresetObj = undefined;
    if(i_cstColumnPresetID === -2) {
      cstColumnPresetObj = this.my_active_captures_cst_column_preset_obj();
      cstColumnPresetObj.existsTF = true;
    }
    else {
      const cstColumnPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_cst_column_presets", i_cstColumnPresetID);
      cstColumnPresetObj = JSFUNC.obj_from_map(cstColumnPresetMap);
      cstColumnPresetObj.existsTF = o_tbl_f_cst_column_presets.has(i_cstColumnPresetID);
    }
    return(cstColumnPresetObj);
  }

  compute_filter_preset_obj_from_filter_preset_id(i_filterPresetID) {
    const o_tbl_f_filter_presets = DatabaseMobx.o_tbl_f_filter_presets;

    var filterPresetObj = undefined;
    if(i_filterPresetID === -2) {
      filterPresetObj = this.my_active_captures_filter_preset_obj();
      filterPresetObj.existsTF = true;
    }
    else {
      const filterPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_filter_presets", i_filterPresetID);
      filterPresetObj = JSFUNC.obj_from_map(filterPresetMap);
      filterPresetObj.existsTF = o_tbl_f_filter_presets.has(i_filterPresetID);
    }
    return(filterPresetObj);
  }

  compute_sort_preset_obj_from_sort_preset_id(i_sortPresetID) {
    const o_tbl_f_sort_presets = DatabaseMobx.o_tbl_f_sort_presets;

    var sortPresetObj = undefined;
    if(i_sortPresetID === -2) {
      sortPresetObj = this.my_active_captures_sort_preset_obj();
      sortPresetObj.existsTF = true;
    }
    else {
      const sortPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_sort_presets", i_sortPresetID);
      sortPresetObj = JSFUNC.obj_from_map(sortPresetMap);
      sortPresetObj.existsTF = o_tbl_f_sort_presets.has(i_sortPresetID);
    }
    return(sortPresetObj);
  }

  create_sort_fields_and_directions_obj_from_sorts_arrayOfObjs(i_sortsArrayOfObjs) {
    var propertyNamesArray = [];
    var sortIsAscTFArray = [];
    for(let sortObj of i_sortsArrayOfObjs) {
      var captureFieldMap = DatabaseMobx.o_tbl_captures_fields.get(sortObj.capture_field_id); //only need db_name, so can use "o" database tbl
      if(captureFieldMap !== undefined) {
        propertyNamesArray.push(captureFieldMap.get("db_name") + "SORT");
        sortIsAscTFArray.push((sortObj.is_asc_01 !== 0));
      }
    }

    return({
      propertyNamesArray: propertyNamesArray,
      sortIsAscTFArray: sortIsAscTFArray
    });
  }

  get c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs() { //all fields that are either table columns and/or selected for sorting (possible to sort by a column not in the current table), used to mask each data cell as the capture summary table data is created
    var allUniqueCstColumnAndSortCaptureFieldIDsArray = []; //add all capture fieldIDs (from c_tbl_captures_fields) from both cstColumns (from cstColumn preset) and sorts (from selected sort preset) merged together

    //cstColumn preset fieldIDs
    for(let cstColumnObj of this.c_cstColumnsArrayOfObjs) {
      allUniqueCstColumnAndSortCaptureFieldIDsArray.push(cstColumnObj.capture_field_id);
    }

    //sort preset fieldIDs
    for(let sortsObj of this.c_sortsArrayOfObjs) {
      if(!JSFUNC.in_array(sortsObj.capture_field_id, allUniqueCstColumnAndSortCaptureFieldIDsArray)) {
        allUniqueCstColumnAndSortCaptureFieldIDsArray.push(sortsObj.capture_field_id);
      }
    }

    return(this.create_cst_column_and_sort_capture_fields_arrayOfObjs_from_field_ids_array(allUniqueCstColumnAndSortCaptureFieldIDsArray))
  }

  create_cst_column_and_sort_capture_fields_arrayOfObjs_from_field_ids_array(i_cstColumnAndSortCaptureFieldIDsArray, i_expandedCaptureFieldsMapOfMaps=undefined) {
    //option to pass preloaded expandedCaptureFieldsMapOfMaps (c_tbl_captures_fields) to avoid mobx computed recomputation using .get() within each loop
    const expandedCaptureFieldsMapOfMaps = ((i_expandedCaptureFieldsMapOfMaps === undefined) ? (DatabaseMobx.c_tbl_captures_fields) : (i_expandedCaptureFieldsMapOfMaps));

    const allUniqueCstColumnAndSortCaptureFieldIDsArray = JSFUNC.unique(i_cstColumnAndSortCaptureFieldIDsArray);

    //get each fieldMap from c_tbl_captures_fields using the fieldID, if it does not exist in the map, create a special fieldObj for this array that indicates that it did not exist, which fills in --DNE-- values for each summary table cell instead of using the masking function
    var allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs = [];
    for(let fieldID of allUniqueCstColumnAndSortCaptureFieldIDsArray) {
      var expandedCaptureFieldMap = expandedCaptureFieldsMapOfMaps.get(fieldID);
      var fieldMapDoesNotExistTF = (expandedCaptureFieldMap === undefined);

      allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs.push({
        id: fieldID,
        db_name: ((fieldMapDoesNotExistTF) ? ("fieldID" + fieldID) : (expandedCaptureFieldMap.get("db_name"))),
        fieldMap: expandedCaptureFieldMap,
        fieldMapDoesNotExistTF: fieldMapDoesNotExistTF
      });
    }

    return(allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs);
  }


  get c_filteredCapturesMapOfMaps() {
    //if the cstColumns or filter component of the master preset selected does not exist, do not do this calculation and return 0 captures as an empty array (ok to not have a sort preset selected for the master)
    if(!this.c_selectedCaptureTableMasterPresetObj.existsTF || !this.c_cstColumnPresetObj.existsTF || !this.c_filterPresetObj.existsTF) {
      return(new Map());
    }
    return(this.get_filtered_captures_mapOfMaps_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs(DatabaseMobx.o_tbl_captures, this.c_filterPresetExpandedFiltersArrayOfObjs));
  }


  create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(i_filtersArrayOfObjs, i_expandedCaptureFieldsMapOfMaps=undefined) {
    //example i_filtersArrayOfObjs: [
    //  {capture_field_id:15, operator:"e", value:"2,5,1,12"},
    //  {capture_field_id:9, operator:"gte", value:"2019-04-15"}
    //]
    //
    //Filter Types:
    //  "e"   equals                  either equal to a single value, or in an array of values
    //  "ne"  not equals              either not equal to a single value, or not in an array of values
    //  "gt"  greater than            > single value
    //  "gte" greater than or equals  >= single value
    //  "lt"  less than               < single value
    //  "lte" less than or equals     <= single value
    //  "c"   contains                contains single value, or contains any of array of values
    //  "is"  is set                  finds set items for select types or text/numbers/dates that are filled out
    //  "ins" is not set              finds all not set (-1) and does not exist items for select types or blank text for texts or blank dates for dates
    //
    //for inputs with type "date", need to shuffle the raw date value if it is detected to be a date relative to [today's date] (year is >= 8000)


    //option to pass preloaded expandedCaptureFieldsMapOfMaps (c_tbl_captures_fields) to avoid mobx computed recomputation using .get() within each loop
    const expandedCaptureFieldsMapOfMaps = ((i_expandedCaptureFieldsMapOfMaps === undefined) ? (DatabaseMobx.c_tbl_captures_fields) : (i_expandedCaptureFieldsMapOfMaps));

    //loop over each filter to compute expanded properties for each one
    var expandedFiltersArrayOfObjs = [];
    for(let filterObj of i_filtersArrayOfObjs) {
      //original filterObj fields
      var filterCaptureFieldID = filterObj.capture_field_id;
      var filterOperator = filterObj.operator;
      var filterValueString = filterObj.value; //a filter value is always a string ("" or "3" or "3,5" or "8019-04-15" or "2019-04-15,2019-05-01" or "some text"), can be a comma list of ints (select, multiselect, sharedpercent, all treated as multiselects when setting up the filters), or a comma list of strings, or a single int or string, all dates are edited as relative dates

      //initialize new filterObj flags for use in c_filteredCapturesMapOfMaps when checking each filter against every capture
      var expandedCaptureFieldMap = expandedCaptureFieldsMapOfMaps.get(filterCaptureFieldID); //get the expanded capture field map for this filter field, used when getting the raw value from each capture
      var allCapturesMatchFilterTF = false;
      var zeroCapturesMatchFilterTF = false;
      var captureFieldIsMultiSelectTF = false;
      var captureFieldIsSharedPercentTF = false;
      var captureFieldIsDateTF = false;
      var captureFieldIsDateTimeTF = false;
      var captureFieldIsFavoriteTF = false;
      var valueConverted = undefined;
      var valueConvertedIsArrayTF = false;
      var operatorEqualsTF = (filterOperator === "e");
      var operatorNotEqualsTF = (filterOperator === "ne");
      var operatorGtTF = (filterOperator === "gt");
      var operatorGteTF = (filterOperator === "gte");
      var operatorLtTF = (filterOperator === "lt");
      var operatorLteTF = (filterOperator === "lte");
      var operatorContainsTF = (filterOperator === "c");
      var operatorIsSetTF = (filterOperator === "is");
      var operatorIsNotSetTF = (filterOperator === "ins");

      if(expandedCaptureFieldMap === undefined) { //if the selected capture field no longer exists, then this filter returns 0 captures
        zeroCapturesMatchFilterTF = true;
      }
      else {
        var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");
        if(fieldTypeObj === undefined) { //no captures match if the expanded capture field map does not have a field type obj
          zeroCapturesMatchFilterTF = true;
        }
        else {
          //handle all select fields (select/multiSelect, select contacts, sharedpercent)
          if(fieldTypeObj.requiresSelectWithSearchDataObjTF) {
            if(fieldTypeObj.selectWithSearchDataObj === undefined || !JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.valueArray) || !JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.displayArray)) { //verify that the select with search obj exists for these field types, if not then 0 captures match this filter
              zeroCapturesMatchFilterTF = true;
            }
            else { //select/sharedpercent types have use of the e/ne/c/ins operators
              var swsDataObj = fieldTypeObj.selectWithSearchDataObj;
              captureFieldIsSharedPercentTF = fieldTypeObj.sharedPercentTF;
              captureFieldIsMultiSelectTF = swsDataObj.isMultiSelectTF;

              var filterSelectedOptionIDsArray = [];
              if(operatorEqualsTF || operatorNotEqualsTF) { //all chosen values in the valueString for this select type filter are overwritten in the preset editor user interface to all treated as multiselects (applies to capture fieldID select/multiselects/select contacts/sharedpercents field types) to choose their optionIDs to use in the filter
                filterSelectedOptionIDsArray = JSFUNC.convert_comma_list_to_int_array(filterValueString);
              }
              else if(operatorContainsTF) { //contains filter values are forced to be text inputs separated by commas in the preset editor interface, these provided values need to be matched up against the options displayNames in the swsDataObj displayArray, the resulting matches optionIDs are put into an array and the operator is changed to "e"
                operatorContainsTF = false;
                operatorEqualsTF = true; //the operator is switched to "e" for each option name that matched any of the contains strings in the filter
                var containsMatchStringsArray = JSFUNC.convert_comma_list_to_array(filterValueString); //string(s) user entered as ORs to try to match to any of the displayArray option names for this select
                if(containsMatchStringsArray.length > 0) { //no need to check any of the option names if there are 0 contains strings specified, leaving filterSelectedOptionIDsArray as empty will result in a filter with 0 captures matching
                  //convert all filter contains string to lowercase
                  var lowerCaseContainsMatchStringsArray = [];
                  for(let containsMatchString of containsMatchStringsArray) {
                    lowerCaseContainsMatchStringsArray.push(containsMatchString.toLowerCase());
                  }

                  //loop through every option in this select to determine which display names match any of the provided contains strings
                  for(let v = 0; v < swsDataObj.valueArray.length; v++) {
                    var optionNameContainsAnyContainsStringTF = JSFUNC.input_string_contains_any_from_lowercase_contains_strings_array(swsDataObj.displayArray[v], lowerCaseContainsMatchStringsArray);
                    if(optionNameContainsAnyContainsStringTF) {
                      filterSelectedOptionIDsArray.push(swsDataObj.valueArray[v]); //push the optionID that matched the contains string(s) onto the list of values this filter considers matching the filter
                    }
                  }
                }
              }
              else if(operatorIsSetTF) { //the Is Set filter for selected gets every optionID from valueArray and changes the operator to "e" to get any of the valid values
              operatorIsSetTF = false;
              operatorEqualsTF = true; //the operator is switched to "e"
              filterSelectedOptionIDsArray = swsDataObj.valueArray;
              }
              else if(operatorIsNotSetTF) { //the Is Not Set filter for selects gets every optionID from valueArray and changes the operator to "ne" to get all not set and does not exist values for this field
                operatorIsNotSetTF = false;
                operatorNotEqualsTF = true; //the operator is switched to "ne"
                filterSelectedOptionIDsArray = swsDataObj.valueArray;
              }

              //operators are only be "e" or "ne" for selects at this point, the valueConverted is either empty, a single optionID or an array of optionIDs
              var filterNumSelectedOptions = filterSelectedOptionIDsArray.length;
              if(filterNumSelectedOptions === 0) { //zero select options selected to be values for this filter
                if(operatorNotEqualsTF) { //for "ne" an empty array of values means all captures match
                  allCapturesMatchFilterTF = true;
                }
                else { //for "e" and empty array of values means 0 captures match
                  zeroCapturesMatchFilterTF = true;
                }
              }
              else if(filterNumSelectedOptions === 1) { //extract the single value from the options array, valueConvertedIsArrayTF is false
                valueConverted = filterSelectedOptionIDsArray[0];
              }
              else { //2 or more optionIDs selected as filter values, mark that the converted filter value is an array of values
                valueConverted = filterSelectedOptionIDsArray;
                valueConvertedIsArrayTF = true;
              }
            }
          }
          else if(fieldTypeObj.valueDisplayIsDateOrDateTimeTF) {
            captureFieldIsDateTF = (fieldTypeObj.dateTF || fieldTypeObj.dateWithRelativeDateTF);
            captureFieldIsDateTimeTF = (fieldTypeObj.dateTimeTF || fieldTypeObj.dateWithDurationTF);
            if(operatorEqualsTF || operatorNotEqualsTF || operatorGtTF || operatorGteTF || operatorLtTF || operatorLteTF) { //can enter a single date or multiple dates separated by commas for "e" and "ne", only allows a single date value to be selected for a filter on a date field with "gt"/"gte"/"lt"/"lte" selected for the operator
              var filterValueRelativeDateStringsArray = JSFUNC.convert_comma_list_to_array(filterValueString);
              var filterValueFixedDateStringsArray = [];
              for(let filterValueRelativeDateString of filterValueRelativeDateStringsArray) {
                if(JSFUNC.date_is_filled_out_tf(filterValueRelativeDateString)) { //filter values for date, dateWithRelativeDate, and dateTime, all use a relative date(s) for filter comparison (done like this so every date has the choice of being fixed or relative in the filter setup)
                  filterValueFixedDateStringsArray.push(JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(filterValueRelativeDateString));
                }
              }

              var numFilterValueRelativeDatesFilledOut = filterValueFixedDateStringsArray.length;
              if(numFilterValueRelativeDatesFilledOut === 0) {
                if(operatorNotEqualsTF) { //if this filter is [Date Field] "ne" --No Value Added--, then skip this filter assuming it matches every capture
                  allCapturesMatchFilterTF = true;
                }
                else { //if this filter is [Date Field] "e"/"gt"/"gte"/"lt"/"lte" --No Value Added--, then assume this filter matches 0 captures
                  zeroCapturesMatchFilterTF = true;
                }
              }
              else if(numFilterValueRelativeDatesFilledOut === 1) {
                valueConverted = filterValueFixedDateStringsArray[0];
              }
              else {
                valueConverted = filterValueFixedDateStringsArray;
                valueConvertedIsArrayTF = true;
              }
            }
            else if(operatorIsSetTF || operatorIsNotSetTF) {
              //cannot pick any values for date "is" or "ins", only determined for each capture when an "is"/"ins" operator is used, no changes need to be made at this level
            }
          }
          else if(fieldTypeObj.captureFavoritesTF) {
            captureFieldIsFavoriteTF = true;
            if(operatorIsSetTF || operatorIsNotSetTF) { //"is" and "ins" are the only 2 operator options for the capture favorite field
              //cannot pick any values for date "is" or "ins", only determined for each capture when an "is"/"ins" operator is used, no changes need to be made at this level
            }
          }
          else { //all other field types (text/textarea/email/phone/website, numeric fields, color)
            if(operatorEqualsTF || operatorNotEqualsTF || operatorContainsTF) { //can enter strings or numbers separated by commas for "e" and "ne" ("c" contains only available for text inputs, not numbers)
              var valueStringsArray = JSFUNC.convert_comma_list_to_array(filterValueString);
              var valueStringsOrNumbersArray = [];
              for(let valueString of valueStringsArray) {
                if(JSFUNC.text_or_number_is_filled_out_tf(valueString)) {
                  if(fieldTypeObj.valueRawIsNumericIntTF) {
                    valueStringsOrNumbersArray.push(JSFUNC.str2int(valueString));
                  }
                  else if(fieldTypeObj.valueRawIsNumericTF) {
                    valueStringsOrNumbersArray.push(JSFUNC.str2int_or_decimal(valueString));
                  }
                  else if(operatorContainsTF) { //for contains, set the contains strings all to lowercase
                    valueStringsOrNumbersArray.push(valueString.toLowerCase());
                  }
                  else {
                    valueStringsOrNumbersArray.push(valueString);
                  }
                }
              }

              var filterNumValues = valueStringsOrNumbersArray.length;
              if(filterNumValues === 0) {
                if(operatorNotEqualsTF) {
                  allCapturesMatchFilterTF = true;
                }
                else {
                  zeroCapturesMatchFilterTF = true;
                }
              }
              else if((filterNumValues === 1) && !operatorContainsTF) { //contains operator has its field values (the lowercase contains strings to compare to) always in an array, even with only 1 item
                valueConverted = valueStringsOrNumbersArray[0];
              }
              else {
                valueConverted = valueStringsOrNumbersArray;
                valueConvertedIsArrayTF = true;
              }
            }
            else if(operatorGtTF || operatorGteTF || operatorLtTF || operatorLteTF) { //preset editor only allows a single value to be selected for "gt"/"gte"/"lt"/"lte" operator
              if(JSFUNC.text_or_number_is_filled_out_tf(filterValueString)) {
                valueConverted = DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(filterValueString, fieldTypeObj);
              }
              else { //the compare value has not been filled out for this filter, thus 0 captures match
                zeroCapturesMatchFilterTF = true;
              }
            }
            else if(operatorIsSetTF || operatorIsNotSetTF) {
              //cannot pick any values for "is" or "ins", only determined for each capture when an "is"/"ins" operator is used, no changes for expanded filter obj
            }
          }
        }
      }

      expandedFiltersArrayOfObjs.push({
        capture_field_id: filterCaptureFieldID,
        operator: filterOperator,
        value: filterValueString,
        expandedCaptureFieldMap: expandedCaptureFieldMap,
        allCapturesMatchFilterTF: allCapturesMatchFilterTF,
        zeroCapturesMatchFilterTF: zeroCapturesMatchFilterTF,
        captureFieldIsMultiSelectTF: captureFieldIsMultiSelectTF,
        captureFieldIsSharedPercentTF: captureFieldIsSharedPercentTF,
        captureFieldIsDateTF: captureFieldIsDateTF,
        captureFieldIsDateTimeTF: captureFieldIsDateTimeTF,
        captureFieldIsFavoriteTF: captureFieldIsFavoriteTF,
        valueConverted: valueConverted,
        valueConvertedIsArrayTF: valueConvertedIsArrayTF,
        operatorEqualsTF: operatorEqualsTF,
        operatorNotEqualsTF: operatorNotEqualsTF,
        operatorGtTF: operatorGtTF,
        operatorGteTF: operatorGteTF,
        operatorLtTF: operatorLtTF,
        operatorLteTF: operatorLteTF,
        operatorContainsTF: operatorContainsTF,
        operatorIsSetTF: operatorIsSetTF,
        operatorIsNotSetTF: operatorIsNotSetTF
      });
    }

    return(expandedFiltersArrayOfObjs);
  }

  get_filtered_captures_mapOfMaps_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs(i_capturesMapOfMaps, i_expandedFiltersArrayOfObjs) {
    //shortcut to determine if the selected filter preset has 0 filters in it, meaning return all captures (entire tbl_captures mapOfMaps)
    if(i_expandedFiltersArrayOfObjs.length === 0) {
      return(i_capturesMapOfMaps);
    }

    var filteredCapturesMapOfMaps = new Map();
    for(let [captureID, captureMap] of i_capturesMapOfMaps) { //filter by looping through all captures in the system
      var captureMatchesAllFiltersPartialMult0to1OrFalse = this.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, i_expandedFiltersArrayOfObjs);
      if(captureMatchesAllFiltersPartialMult0to1OrFalse !== false) {
        filteredCapturesMapOfMaps.set(captureID, captureMap);
      }
    }
    return(filteredCapturesMapOfMaps);
  }

  compute_matching_captures_obj_from_filters_arrayOfObjs(i_filtersArrayOfObjs) {
    //searches all captures in system to match against input filters, counts the results captures and returns an array of matching captureIDs (used in Admin Division/User capture count bubbles)
    const expandedFiltersArrayOfObjs = this.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(i_filtersArrayOfObjs);

    var matchingCaptureIDsArray = [];
    var partialNumMatchingCaptures = 0;
    for(let [captureID, captureMap] of DatabaseMobx.o_tbl_captures) {
      var partialMultiplier0to1OrFalse = this.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, expandedFiltersArrayOfObjs);
      if(partialMultiplier0to1OrFalse !== false) {
        matchingCaptureIDsArray.push(captureID);
        partialNumMatchingCaptures += partialMultiplier0to1OrFalse;
      }
    }

    //returned "matching captures obj" with 3 fields
    return({
      matchingCaptureIDsArray: matchingCaptureIDsArray,
      numMatchingCaptures: matchingCaptureIDsArray.length, //whole number count of each matching capture
      partialNumMatchingCaptures: partialNumMatchingCaptures //summation of all matching captures calculated partialMultiplier0to1OrFalse based on the filters
    });
  }

  capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(i_captureMap, i_expandedFiltersArrayOfObjs) {
    //returns a partialMultiplier0to1 value between 0 and 1 (based on sharedpercent filters ownership of the capture), false if the capture does not match one or more of the filters
    //
    //example
    //  (capture fieldID 1 is capture managers, fieldID 2 is division owners, fieldID 7 is stage, fieldID 13 is Reasons Won/Lost)
    //  (userID 1 is Drew, userID 2 is Skip, userID 3 is Mike)
    //  (divisionID 1 is Dev, divisionID 2 is Fed, divisionID 3 is Army)
    //  (reason 4 is Price, reason 5 is Tech)
    //
    //  Capture #1  id:1, capture_managers_ids_colon_percent_comma:"2:90,1:10", division_owners_ids_colon_percent_comma:"1:40,2:25,3:35", stage_id:9, reasons_won_lost_ids_colon_percent_comma:"4:100"
    //    (Skip owns 90%, Drew 10%) (Dev 40%, Fed 25%, Army 35%) (Stage is "Closed - Won") (Reasons Won/Lost is Price 100%)
    //    this capture will match all 6 of the filters below
    //
    //  Main Filter #1      capture_field_id:1, operator:"e", value:"2"               (user is Skip)                           [2] in? [2,1]      captureFieldID 1: 2:90,1:0    <- this one is 0 for value id1 even though the capture has a value of 10 for id1, but id1 did not match this filter, so it's set to 0
    //  Main Filter #2      capture_field_id:1, operator:"e", value:"1,2,3"           (user is Skip or Drew or Mike)       [1,2,3] in? [2,1]      captureFieldID 1: 2:90,1:10
    //  Main Filter #3      capture_field_id:7, operator:"e", value:"9,10,11,12"      (stage is any closed stage)     [9,10,11,12] in? [9]        --field is not a sharedpercent type--
    //  Main Filter #4      capture_field_id:2, operator:"e", value:"1,3"             (division is Dev or Army)              [1,3] in? [1,2,3]    captureFieldID 2: 1:40,2:0,3:35
    //  Main Filter #5      capture_field_id:13, operator:"e", value:"4,5"            (reason is Price or Tech)              [4,5] in? [4]        --sharedpercent field type, but no need to compute this since this capture has 100% ownership of a single value with no split percentages, the math would work out to be a multiplier of 1 for this field--
    //  Category Filter #6  capture_field_id:2, operator:"e", value:"3"               (division is Army)                       [3] in? [1,2,3]    captureFieldID 2: 1:0,2:0,3:35
    //
    //  sharedPercentFieldValuesAndPercentsArrayOfObjs: combine all sharedPercent results from each filter that have matching captureFieldID, if any value has 0 for any of the entries, it is 0 at the end, all nonzero entries should be the same percentage taken from the capture colon comma list for that value
    //    captureFieldID 1: 2:90, 1:0
    //    captureFieldID 2: 1:0,2:0,3:35
    //
    //  add all percent values together within each field (divide by 100 to get the multiplier between 0 and 1)
    //    captureFieldID 1: 90 + 0 = 90% = 0.9
    //    captureFieldID 2: 0 + 0 + 35 = 35% = 0.35
    //
    //  finally multiply all shared percent field resulting values together to return a final partial multiplier
    //    partialMultiplier0to1 = 0.9 * 0.35 = 0.315
    //
    //  if a hypothetical capture #2 did not match 1 or more of the 5 filters above, then false would be returned from this function meaning it does not match and has a partialMultiplier0to1 score of 0

    var sharedPercentFieldValuesAndPercentsArrayOfObjs = [];

    //loop through each filter specified in the selected filter preset to see if this capture is within all of those bounds (no need to check more once the first filter is found to not fit this capture)
    for(let expandedFilterObj of i_expandedFiltersArrayOfObjs) {
      if(expandedFilterObj.zeroCapturesMatchFilterTF) { //this filter and thus this entire filter preset fails to match any captures
        return(false);
      }

      //need to verify which captures match this filter by checking each one against the capture raw value for this field
      if(!expandedFilterObj.allCapturesMatchFilterTF) { //no need to check this filter if it matches every capture, skip this filter and move to the next one in the loop
        //get the capture raw value for this filter's capture field
        var captureValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, expandedFilterObj.expandedCaptureFieldMap);

        //break any comma list values from selectMultis or sharedpercents into arrays, if the array only has 1 element, turn it back into a single value
        var captureValueOrValuesArray = undefined;
        var captureValueIsArrayTF = false;
        var captureValueHasPartialSharedPercentsTF = false; //if this field is a sharedpercent and this capture has more than 1 value in the colon comma list, handle the operators with completely separate logic
        var sharedPercentArrayOfObjs = undefined;
        var sharedPercentNumOptionsSelected = undefined;
        if(expandedFilterObj.captureFieldIsSharedPercentTF || expandedFilterObj.captureFieldIsMultiSelectTF) { //for sharedpercents or multiselects convert the comma list capture raw value to an array of selected values (ids)
          var captureValueRawIDArray = undefined; //initialize a new array for ids, if there's 0 or only 1 id present, use a single value as opposed to a length 1 array as the comparison code using == later is faster
          var numRawIDs = undefined;
          if(expandedFilterObj.captureFieldIsSharedPercentTF) { //sharedpercents
            sharedPercentArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(captureValueRaw, "optionID", "percent0to100");
            sharedPercentNumOptionsSelected = sharedPercentArrayOfObjs.length;
            if(sharedPercentNumOptionsSelected > 1) {
              captureValueHasPartialSharedPercentsTF = true;
            }
            else {
              captureValueRawIDArray = JSFUNC.get_column_vector_from_arrayOfObjs(sharedPercentArrayOfObjs, "optionID");
              numRawIDs = sharedPercentNumOptionsSelected;
            }
          }
          else { //multiselects
            captureValueRawIDArray = JSFUNC.convert_comma_list_to_int_array(captureValueRaw);
            numRawIDs = captureValueRawIDArray.length;
          }

          if(numRawIDs === 0) {
            captureValueOrValuesArray = undefined; //no values to compare against, so use undefined which will trigger false for "e" operators, but will be inclusive for "ne" and "ins" filters
          }
          else if(numRawIDs === 1) {
            captureValueOrValuesArray = captureValueRawIDArray[0]; //take the single value out of its array, thus when looping through all captures, this filter can be checked using == rather than in_array()
          }
          else {
            captureValueOrValuesArray = captureValueRawIDArray;
            captureValueIsArrayTF = true;
          }
        }
        else { //all other capture field types have a single value
          captureValueOrValuesArray = captureValueRaw;
        }

        //handle the different operators comparing the filter value(s) to the capture value(s)
        if(captureValueHasPartialSharedPercentsTF) { //different handling for sharedpercents with partial values for this capture
          //determine if any of the sharedpercent capture values (selected optionIDs) match any of the filter values and fill out a valuesAndPercentsObj for this sharedpercent field
          var anyOptionIDMatchesAnyFilterValueTF = false;
          var newSharedPercentFieldValuesAndPercentsObj = {captureFieldID:expandedFilterObj.capture_field_id, captureValuesAndPercentsArrayOfObjs:[]};
          for(let sharedPercentObj of sharedPercentArrayOfObjs) {
            var captureMatchesFilterTF = this.filter_values_with_operator_matches_capture_values_tf(expandedFilterObj, false, sharedPercentObj.optionID);

            var optionPartialPercentOr0 = 0; //is the value of the partial capture value percent0to100, or 0 if this optionID is not found to match the filter values
            if(captureMatchesFilterTF) {
              anyOptionIDMatchesAnyFilterValueTF = true;
              optionPartialPercentOr0 = sharedPercentObj.percent0to100;
            }

            newSharedPercentFieldValuesAndPercentsObj.captureValuesAndPercentsArrayOfObjs.push({
              optionID: sharedPercentObj.optionID,
              optionPartialPercentOr0: optionPartialPercentOr0
            });
          }

          //if no selected capture values matched any of the filter values, return false as this set of filters will return 0 matching captures
          if(!anyOptionIDMatchesAnyFilterValueTF) {
            return(false);
          }

          //check to see if this capture fieldID already exists as an entry in sharedPercentFieldValuesAndPercentsArrayOfObjs, otherwise insert this newSharedPercentFieldValuesAndPercentsObj as the first entry for this fieldID
          var foundMatchingCaptureFieldID = false;
          var s = 0;
          while(!foundMatchingCaptureFieldID && s < sharedPercentFieldValuesAndPercentsArrayOfObjs.length) {
            var oldSharedPercentFieldValuesAndPercentsObj = sharedPercentFieldValuesAndPercentsArrayOfObjs[s];
            if(oldSharedPercentFieldValuesAndPercentsObj.captureFieldID === newSharedPercentFieldValuesAndPercentsObj.captureFieldID) { //found an existing capture fieldID obj, need to merge the data from the new obj into the old one, 0s overwrite any nonzero percent values
              foundMatchingCaptureFieldID = true;
              for(let o = 0; o < sharedPercentNumOptionsSelected; o++) {
                if(newSharedPercentFieldValuesAndPercentsObj.captureValuesAndPercentsArrayOfObjs[o].optionPartialPercentOr0 <= 0) {
                  oldSharedPercentFieldValuesAndPercentsObj.captureValuesAndPercentsArrayOfObjs[o].optionPartialPercentOr0 = 0;
                }
              }
            }
            s++;
          }

          if(!foundMatchingCaptureFieldID) {
            sharedPercentFieldValuesAndPercentsArrayOfObjs.push(newSharedPercentFieldValuesAndPercentsObj); //new obj
          }
          else {
            newSharedPercentFieldValuesAndPercentsObj = oldSharedPercentFieldValuesAndPercentsObj; //obj with old and new percents merged together
          }

          //[changed to consider 0 as a valid counted percent] verify that the latest iteration of the percents obj for this fieldID has at least 1 nonzero percent, otherwise this check on all filters can cut out early and return false
          var sharedPercentFieldHasANonzeroPercentTF = false;
          var o = 0;
          while(!sharedPercentFieldHasANonzeroPercentTF && (o < sharedPercentNumOptionsSelected)) {
            if(newSharedPercentFieldValuesAndPercentsObj.captureValuesAndPercentsArrayOfObjs[o].optionPartialPercentOr0 >= 0) { //doesn't have to be nonzero, 0% for a sharedpercent portion is ok and rather than returning false as 'this capture does not match the filter(s)', it returns 0 partial multiplier
              sharedPercentFieldHasANonzeroPercentTF = true;
            }
            o++;
          }

          if(!sharedPercentFieldHasANonzeroPercentTF) {
            return(false);
          }
        }
        else { //regular logic for empty or single value shared percents and all other field types
          var captureMatchesFilterTF = this.filter_values_with_operator_matches_capture_values_tf(expandedFilterObj, captureValueIsArrayTF, captureValueOrValuesArray);
          if(!captureMatchesFilterTF) {
            return(false);
          }
        }
      }
    }

    //determine partial multiplier as a cumulative multiplication through each unique sharedpercent field found within the filters
    if(sharedPercentFieldValuesAndPercentsArrayOfObjs.length > 0) {
      var partialMultiplier0to1 = 1;
      for(let sharedPercentFieldValuesAndPercentsObj of sharedPercentFieldValuesAndPercentsArrayOfObjs) {
        var fieldPercentsSum0to100 = 0;
        for(let captureValuesAndPercentsObj of sharedPercentFieldValuesAndPercentsObj.captureValuesAndPercentsArrayOfObjs) {
          fieldPercentsSum0to100 += captureValuesAndPercentsObj.optionPartialPercentOr0;
        }
        partialMultiplier0to1 *= (fieldPercentsSum0to100 / 100);
      }
      return(partialMultiplier0to1);
    }

    //return 1 for full value multiplier (applied to capture count or contract value for example), no partial multipliers found within any of the filters
    return(1);
  }


  filter_values_with_operator_matches_capture_values_tf(i_expandedFilterObj, i_captureValueIsArrayTF, i_captureValueOrValuesArray) {
    //compare the capture value(s) to the filter value(s) depending on the filter operator
    var captureMatchesFilterTF = true; //initialize the flag that verifies all filters match this capture to true
    if(i_expandedFilterObj.operatorEqualsTF || i_expandedFilterObj.operatorNotEqualsTF) { //equals or not equals (or converted is set or is not set operators from select types)
      if(i_expandedFilterObj.valueConvertedIsArrayTF) { //filter values are in an array
        if(i_captureValueIsArrayTF) { //filterArray, captureArray
          captureMatchesFilterTF = JSFUNC.any_of_array1_is_in_array2(i_captureValueOrValuesArray, i_expandedFilterObj.valueConverted);
          //alert("any_of_array1_is_in_array2:\n" + JSFUNC.print(i_captureValueOrValuesArray) + "\n" + JSFUNC.print(i_expandedFilterObj.valueConverted) + "\n" + captureMatchesFilterTF);
        }
        else { //filterArray, single capture
          captureMatchesFilterTF = JSFUNC.in_array(i_captureValueOrValuesArray, i_expandedFilterObj.valueConverted);
          //alert("in_array:\n" + i_captureValueOrValuesArray + "\n" + JSFUNC.print(i_expandedFilterObj.valueConverted) + "\n" + captureMatchesFilterTF);
        }
      }
      else { //filter has single value
        if(i_captureValueIsArrayTF) { //single filter, capture values array
          captureMatchesFilterTF = JSFUNC.in_array(i_expandedFilterObj.valueConverted, i_captureValueOrValuesArray);
          //alert("in_array:\n" + i_expandedFilterObj.valueConverted + "\n" + JSFUNC.print(i_captureValueOrValuesArray) + "\n" + captureMatchesFilterTF);
        }
        else { //single filter, single capture value
          captureMatchesFilterTF = (i_captureValueOrValuesArray == i_expandedFilterObj.valueConverted); //intentional "==" not "==="
          //alert(i_captureValueOrValuesArray + " (" + typeof(i_captureValueOrValuesArray) + ")" + " == " + i_expandedFilterObj.valueConverted + " (" + typeof(i_expandedFilterObj.valueConverted) + ")\n" + captureMatchesFilterTF);
        }
      }

      if(i_expandedFilterObj.operatorNotEqualsTF) {
        captureMatchesFilterTF = (!captureMatchesFilterTF);
      }
    }
    else if(i_expandedFilterObj.operatorGtTF) { //greater than
      captureMatchesFilterTF = (i_captureValueOrValuesArray > i_expandedFilterObj.valueConverted);
      //alert(i_captureValueOrValuesArray + " (" + typeof(i_captureValueOrValuesArray) + ")" + " > " + i_expandedFilterObj.valueConverted + " (" + typeof(i_expandedFilterObj.valueConverted) + ")\n" + captureMatchesFilterTF);
    }
    else if(i_expandedFilterObj.operatorGteTF) { //greater than or equal to
      captureMatchesFilterTF = (i_captureValueOrValuesArray >= i_expandedFilterObj.valueConverted);
      //alert(i_captureValueOrValuesArray + " (" + typeof(i_captureValueOrValuesArray) + ")" + " >= " + i_expandedFilterObj.valueConverted + " (" + typeof(i_expandedFilterObj.valueConverted) + ")\n" + captureMatchesFilterTF);
    }
    else if(i_expandedFilterObj.operatorLtTF) { //less than
      captureMatchesFilterTF = (i_captureValueOrValuesArray < i_expandedFilterObj.valueConverted);
      //alert(i_captureValueOrValuesArray + " (" + typeof(i_captureValueOrValuesArray) + ")" + " < " + i_expandedFilterObj.valueConverted + " (" + typeof(i_expandedFilterObj.valueConverted) + ")\n" + captureMatchesFilterTF);
    }
    else if(i_expandedFilterObj.operatorLteTF) { //less than or equal to
      captureMatchesFilterTF = (i_captureValueOrValuesArray <= i_expandedFilterObj.valueConverted);
      //alert(i_captureValueOrValuesArray + " (" + typeof(i_captureValueOrValuesArray) + ")" + " <= " + i_expandedFilterObj.valueConverted + " (" + typeof(i_expandedFilterObj.valueConverted) + ")\n" + captureMatchesFilterTF);
    }
    else if(i_expandedFilterObj.operatorContainsTF) { //contains
      if(JSFUNC.is_string(i_captureValueOrValuesArray) && i_expandedFilterObj.valueConvertedIsArrayTF) { //ensure the capture value is a string and that the contains strings are always passed as an array from the expanded filter obj, even if there is only 1 string to check
        captureMatchesFilterTF = JSFUNC.input_string_contains_any_from_lowercase_contains_strings_array(i_captureValueOrValuesArray, i_expandedFilterObj.valueConverted);
      }
    }
    else if(i_expandedFilterObj.operatorIsSetTF) { //is set
      if(i_expandedFilterObj.captureFieldIsDateTF) {
        captureMatchesFilterTF = JSFUNC.date_is_filled_out_tf(i_captureValueOrValuesArray);
      }
      else if(i_expandedFilterObj.captureFieldIsDateTimeTF) {
        captureMatchesFilterTF = JSFUNC.datetime_is_filled_out_tf(i_captureValueOrValuesArray);
      }
      else if(i_expandedFilterObj.captureFieldIsFavoriteTF) {
        captureMatchesFilterTF = i_captureValueOrValuesArray;
      }
      else {
        captureMatchesFilterTF = JSFUNC.text_or_number_is_filled_out_tf(i_captureValueOrValuesArray);
      }
    }
    else if(i_expandedFilterObj.operatorIsNotSetTF) { //is not set
      if(i_expandedFilterObj.captureFieldIsDateTF) {
        captureMatchesFilterTF = (!JSFUNC.date_is_filled_out_tf(i_captureValueOrValuesArray));
      }
      else if(i_expandedFilterObj.captureFieldIsDateTimeTF) {
        captureMatchesFilterTF = (!JSFUNC.datetime_is_filled_out_tf(i_captureValueOrValuesArray));
      }
      else if(i_expandedFilterObj.captureFieldIsFavoriteTF) {
        captureMatchesFilterTF = (!i_captureValueOrValuesArray);
      }
      else {
        captureMatchesFilterTF = (!JSFUNC.text_or_number_is_filled_out_tf(i_captureValueOrValuesArray));
      }
    }
    else { //invalid operator
      return(false);
    }

    if(!captureMatchesFilterTF) {
      return(false);
    }
    return(true);
  }





  //Capture Summary Table controls
  get c_cstRowHeightFieldTypeObj() {
    const valueArray = JSFUNC.array_fill_multistep_x_to_y_to_z([1.2, 3, 10], [0.2, 1], 1);
    var displayArray = [];
    for(let value of valueArray) {
      if(value === 5) {
        displayArray.push("5\u00A0(default)");
      }
      else {
        displayArray.push(JSFUNC.num2str(value));
      }
    }
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Capture Table Size", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_stageViewBoxSizeFieldTypeObj() {
    const valueArray = [1, 2, 3];
    const displayArray = ["1\u00A0(default)", "2", "3"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Stage View Box Size", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_ganttChartRowHeightFieldTypeObj() {
    const valueArray = JSFUNC.array_fill_multistep_x_to_y_to_z([1, 10], [1], 0);
    var displayArray = [];
    for(let value of valueArray) {
      if(value === 2) {
        displayArray.push("2\u00A0(default)");
      }
      else {
        displayArray.push(JSFUNC.num2str(value));
      }
    }
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Gantt Chart Size", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_progressChartRowHeightFieldTypeObj() {
    const valueArray = JSFUNC.array_fill_multistep_x_to_y_to_z([5, 20, 60], [5, 20], 0);
    var displayArray = [];
    for(let value of valueArray) {
      if(value === 10) {
        displayArray.push("10\u00A0(default)");
      }
      else {
        displayArray.push(JSFUNC.num2str(value));
      }
    }
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Progress Chart Size", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }




  //Capture Summary Table
  get c_cstSumRowColumnsArrayOfObjsOrUndefined() {
    const c_cstColumnsArrayOfObjs = this.c_cstColumnsArrayOfObjs;
    const c_filteredCapturesMapOfMaps = this.c_filteredCapturesMapOfMaps;

    //check if any column in the CST has the sum calculation turned on
    var anyColumnHasCstSumTF = false;
    for(let cstColumnObj of c_cstColumnsArrayOfObjs) {
      if(cstColumnObj.cst_sum_row_01 === 1) {
        anyColumnHasCstSumTF = true;
        break;
      }
    }

    if(!anyColumnHasCstSumTF) {
      return(undefined);
    }

    var cstSumRowArrayOfObjs = [];
    for(let cstColumnObj of c_cstColumnsArrayOfObjs) {
      var isCstSumColumnTF = ((cstColumnObj.cst_sum_row_01 === 1) && cstColumnObj.canCalculateCstSumRowTF); //user marked this column as a sum column AND this field is allowed to calculate a sum column (all numeric fields)
      var sumValueRaw = 0;
      var sumValueMask = 0;
      var sumValueMaskPlainText = 0;

      //if this column is a CST sum column, calculate the sum from all of the data
      if(isCstSumColumnTF) {
        var columnExpandedCaptureFieldMap = DatabaseMobx.tbl_row_map_from_id("tbl_captures_fields", cstColumnObj.capture_field_id);
        var columnFieldTypeObj = columnExpandedCaptureFieldMap.get("fieldTypeObj");

        //compute the sum across all captures for this column
        for(let captureMap of c_filteredCapturesMapOfMaps.values()) {
          //get column raw value
          var columnCaptureValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, columnExpandedCaptureFieldMap);

          //convert favorites column raw value from true/false to 0/1
          if(columnFieldTypeObj.captureFavoritesTF) {
            columnCaptureValueRaw = ((columnCaptureValueRaw) ? (1) : (0));
          }

          //if the fetched capture valueRaw was numeric (not undefined, etc), add it to the sum
          if(JSFUNC.is_number_not_nan(columnCaptureValueRaw)) {
            sumValueRaw += columnCaptureValueRaw;
          }
        }

        //get the column fieldTypeObj (to mask for int/decimal/money/percent), for some fields, force a different masking fieldTypeObj
        if(columnFieldTypeObj.captureFavoritesTF || columnFieldTypeObj.capturePriorityLevelTF) {
          columnFieldTypeObj = DatabaseMobx.c_genericIntFieldTypeObj;
        }
        else if(columnFieldTypeObj.maskPercentProgressBarShapingTF || columnFieldTypeObj.maskPercentPwinCubeTF) {
          sumValueRaw = Math.round(sumValueRaw);
          columnFieldTypeObj = DatabaseMobx.c_genericPercentIntFieldTypeObj;
        }

        //get the value mask from the sum raw value
        var valueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(sumValueRaw, columnFieldTypeObj);
        sumValueMask = valueMaskSortIfoObj.valueMask;
        sumValueMaskPlainText = valueMaskSortIfoObj.valueMaskPlainText;
      }

      cstSumRowArrayOfObjs.push({
        isCstSumColumnTF: isCstSumColumnTF,
        columnName: cstColumnObj.fieldDisplayName,
        columnWidthEm: cstColumnObj.width_em,
        cstCellAlignClass: cstColumnObj.cstCellAlignClass,
        sumValueRaw: sumValueRaw,
        sumValueMask: sumValueMask,
        sumValueMaskPlainText: sumValueMaskPlainText
      });
    }
    return(cstSumRowArrayOfObjs);
  }


  get c_cstFilteredMaskedCaptureValuesArrayOfObjs() {
    const c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs = this.c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs;
    const c_filteredCapturesMapOfMaps = this.c_filteredCapturesMapOfMaps;

    const plainTextTF = false; //real mask displays for Capture Summary Table
    const valueMasksAlteredForCsvOrXmlTF = false;
    const cstFilteredMaskedCaptureValuesArrayOfObjs = this.compute_cst_filtered_masked_capture_values_arrayOfObjs(c_filteredCapturesMapOfMaps, c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs, plainTextTF, valueMasksAlteredForCsvOrXmlTF);
    return(cstFilteredMaskedCaptureValuesArrayOfObjs);
  }

  compute_cst_filtered_masked_capture_values_arrayOfObjs(i_filteredCapturesMapOfMaps, i_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs, i_plainTextTF=false, i_valueMasksAlteredForCsvOrXmlTF=false) {
    const c_combinedUserObj = UserMobx.c_combinedUserObj;

    //mask the capture raw values with mask values and sort values (capture["db_nameMASK"], capture["db_nameSORT"])
    //  - computed in html for the capture summary table cells
    //  - computed in plainText when the generate csv button is pushed to populate csv cells

    var filteredMaskedCapturesArrayOfObjs = [];
    for(let captureMap of i_filteredCapturesMapOfMaps.values()) { //loop through every filtered capture
      var captureID = captureMap.get("id");

      var captureObj = {};
      captureObj.id = captureID;
      captureObj.captureFullName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap); //compute capture full name for each filtered capture
      captureObj.capture_type_id = captureMap.get("capture_type_id"); //used for Stage View
      captureObj.stage_id = captureMap.get("stage_id"); //used for Stage View

      //loop through capture fields either in the column headers and/or used in the column sorts
      for(let allUniqueCstColumnAndSortCaptureFieldObj of i_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs) {
        var valueRaw = undefined;
        var valueMaskPlainText = undefined;
        var valueMask = undefined;
        var valueSort = undefined;
        var valueIsFilledOutTF = false;
        var columnExpandedCaptureFieldMap = undefined;
        var directEditCaptureFavoritesTF = false;
        var directEditCapturePriorityLevelTF = false;
        if(allUniqueCstColumnAndSortCaptureFieldObj.fieldMapDoesNotExistTF) { //this fieldID did not exist, give every mask value a message that the field does not exist
          valueMask = DatabaseMobx.not_filled_out_font_html("--Field Does Not Exist (ID: " + allUniqueCstColumnAndSortCaptureFieldObj.id + ")--", i_plainTextTF);
          valueSort = JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string();
        }
        else {
          columnExpandedCaptureFieldMap = allUniqueCstColumnAndSortCaptureFieldObj.fieldMap;

          var htmlValueMaskMaxHeightEmOrUndefined = undefined;

          //extract fieldTypeObj
          var fieldTypeObj = columnExpandedCaptureFieldMap.get("fieldTypeObj");
          if(fieldTypeObj !== undefined) {
            directEditCaptureFavoritesTF = fieldTypeObj.captureFavoritesTF;
            directEditCapturePriorityLevelTF = fieldTypeObj.capturePriorityLevelTF;

            //control max html height of valueMask based on user CST row size
            if(fieldTypeObj.maskPercentProgressBarShapingTF) {
              if(c_combinedUserObj.cst_row_height_em < 1.6) {
                htmlValueMaskMaxHeightEmOrUndefined = 1.2;
              }
            }
            else if(fieldTypeObj.maskPercentPwinCubeTF) {
              if(c_combinedUserObj.cst_row_height_em < 1.4) {
                htmlValueMaskMaxHeightEmOrUndefined = 1.2;
              }
              else if(c_combinedUserObj.cst_row_height_em < 2) {
                htmlValueMaskMaxHeightEmOrUndefined = 1.6;
              }
              else if(c_combinedUserObj.cst_row_height_em < 2.4) {
                htmlValueMaskMaxHeightEmOrUndefined = 2;
              }
            }
          }

          //extract the raw value from the capture map and get masking based on the field
          var valueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, columnExpandedCaptureFieldMap, i_plainTextTF, htmlValueMaskMaxHeightEmOrUndefined);

          valueRaw = valueMaskSortIfoCanEditObj.valueRaw;
          valueMaskPlainText = valueMaskSortIfoCanEditObj.valueMaskPlainText;
          valueSort = valueMaskSortIfoCanEditObj.valueSort;
          valueIsFilledOutTF = valueMaskSortIfoCanEditObj.isFilledOutTF;

          //value mask
          if(i_valueMasksAlteredForCsvOrXmlTF) { //always plaintext for csv/xml masking
            valueMask = valueMaskSortIfoCanEditObj.valueMaskCsvXmlPlainText;
          }
          else if(i_plainTextTF) { //if overriding flag for plaintext valueMask
            valueMask = valueMaskSortIfoCanEditObj.valueMaskPlainText;
          }
          else { //normal valueMask (don't put any click links on the main captures table so that clicking opens the capture)
            valueMask = valueMaskSortIfoCanEditObj.valueMaskNoClickLinks;
          }
        }

        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "ECFM"] = columnExpandedCaptureFieldMap;
        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "RAW"] = valueRaw;
        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "MASKPT"] = valueMaskPlainText;
        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "MASK"] = valueMask;
        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "SORT"] = valueSort;
        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "IFO"] = valueIsFilledOutTF;
        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "isCF"] = directEditCaptureFavoritesTF;
        captureObj[allUniqueCstColumnAndSortCaptureFieldObj.db_name + "isCFPL"] = directEditCapturePriorityLevelTF;
      }

      filteredMaskedCapturesArrayOfObjs.push(captureObj);
    }

    return(filteredMaskedCapturesArrayOfObjs);
  }


  get c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs() {
    const c_selectedSortFieldsAndDirectionsObj = this.c_selectedSortFieldsAndDirectionsObj;
    const c_cstFilteredMaskedCaptureValuesArrayOfObjs = this.c_cstFilteredMaskedCaptureValuesArrayOfObjs;

    var sortedFilteredMaskedCapturesArrayOfObjs = c_cstFilteredMaskedCaptureValuesArrayOfObjs;
    JSFUNC.sort_arrayOfObjs(sortedFilteredMaskedCapturesArrayOfObjs, c_selectedSortFieldsAndDirectionsObj.propertyNamesArray, c_selectedSortFieldsAndDirectionsObj.sortIsAscTFArray);
    return(sortedFilteredMaskedCapturesArrayOfObjs);
  }


  get c_ganttOrProgressChartSortedFilteredCapturesArrayOfObjs() {
    const c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs = this.c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs;
    const c_ganttSelectedPresetObj = this.c_ganttSelectedPresetObj;

    const ganntHasDatePresetSelectedTF = (this.c_ganttSelectedPresetObj !== undefined);

    //gantt chart needs the full captureName, id, and all of the date field raw values, progress chart just needs the captureName and id
    var ganttOrProgressChartSortedFilteredCaptureValuesArrayOfObjs = [];
    for(let captureValueObj of c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs) { //loop through every filtered capture
      var ganttOrProgressCaptureObj = {id:captureValueObj.id, captureFullName:captureValueObj.captureFullName};

      //loop through needed gantt date fields from the selected gantt date preset
      if(ganntHasDatePresetSelectedTF) {
        var captureMap = DatabaseMobx.o_tbl_captures.get(captureValueObj.id);
        if(captureMap !== undefined) {
          for(let ganttDateObj of c_ganttSelectedPresetObj.ganttDatesArrayOfObjs) {
            ganttOrProgressCaptureObj[ganttDateObj.fieldDbName] = captureMap.get(ganttDateObj.fieldDbName);
          }
        }
      }

      ganttOrProgressChartSortedFilteredCaptureValuesArrayOfObjs.push(ganttOrProgressCaptureObj);
    }

    return(ganttOrProgressChartSortedFilteredCaptureValuesArrayOfObjs);
  }



  get c_numFilteredCaptures() {
    return(this.c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs.length);
  }

  get c_captureTableTotalWidthEm() {
    var totalWidthEm = 0;
    for(let cstColumnObj of this.c_cstColumnsArrayOfObjs) {
      totalWidthEm += cstColumnObj.width_em;
    }
    return(totalWidthEm + 4);
  }



  //mass edit
  get c_captureTableCurrentlyProcessingCapturesTF() {
    const o_captureTableProcessingCapturesObjOrUndefined = this.o_captureTableProcessingCapturesObjOrUndefined;
    if(o_captureTableProcessingCapturesObjOrUndefined !== undefined) {
      if(o_captureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber === -1) { //currentlyProcessingCaptureNumber is set to -1 as a flag when the mass edit process is finished and showing the final screen of success/failed changes
        return(false);
      }
      return(true);
    }
    return(false);
  }



  //stage view
  get c_stageViewSelectedCaptureTypeStagesArrayOfObjs() {
    const o_cstOrChartMaxNumCapturesDrawn = this.o_cstOrChartMaxNumCapturesDrawn;
    const c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs = this.c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs;
    const c_allStagesArrayOfObjs = DatabaseMobx.c_allStagesArrayOfObjs;
    const c_captureTypeIDsArray = DatabaseMobx.c_captureTypeIDsArray;
    const c_userStageViewSelectedCaptureTypeIDsArray = UserMobx.c_userStageViewSelectedCaptureTypeIDsArray;

    const userNumCaptureTypesSelected = c_userStageViewSelectedCaptureTypeIDsArray.length;
    
    var stagesArrayOfObjs = [];
    if(userNumCaptureTypesSelected > 0) { //for 0 Capture Types selected, there will be 0 stages, otherwise determine which stages are used for the selected capture type(s)
      var singleCaptureTypeSelectedTrueMultipleFalse = (userNumCaptureTypesSelected === 1);
      var allCaptureTypesSelectedTF = false;
      var userSelectedSingleCaptureTypeID = undefined;
      if(singleCaptureTypeSelectedTrueMultipleFalse) { //if exactly 1 Capture Type is selected, get the stages in order for that 1 Capture Type
        userSelectedSingleCaptureTypeID = c_userStageViewSelectedCaptureTypeIDsArray[0];
        stagesArrayOfObjs = DatabaseMobx.create_stages_arrayOfObjs_from_capture_type_id(userSelectedSingleCaptureTypeID);
      }
      else { //for any number of multiple Capture Types selected, use every stage from the Pool
        allCaptureTypesSelectedTF = JSFUNC.all_of_array1_is_in_array2(c_captureTypeIDsArray, c_userStageViewSelectedCaptureTypeIDsArray); //check if every capture type is selected (faster filtering calculation if so)

        //get every stage from the stage pool in order of active, won, lost, closed not submitted
        const tfFieldsArray = ["isActiveStageTF", "isWonStageTF", "isLostStageTF", "isNotSubmittedStageTF"];
        var stagesArrayOfObjs = [];
        stagesArrayOfObjs = [];
        for(let tfField of tfFieldsArray) {
          for(let stageObj of c_allStagesArrayOfObjs) {
            if(stageObj[tfField]) {
              stagesArrayOfObjs.push(stageObj);
            }
          }
        }
      }

      //add other fields to each stageObj for Stage View
      for(let stageObj of stagesArrayOfObjs) {
        //get all or limited to 50 captures within this capture type/stage combination
        var numCapturesInStage = 0;
        var limitedOrAllStageCaptureIDsArray = [];
        var stageHasMoreCapturesThanLimitTF = false;
        for(let captureValueObj of c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs) {
          if(captureValueObj.stage_id === stageObj.id) { //capture stage matches this stage column
            //determine if capture capture type matches selected capture type(s)
            var captureMatchesSelectedCaptureTypesTF = false;
            if(singleCaptureTypeSelectedTrueMultipleFalse) { //single capture type selected, do direct comparison to single value
              captureMatchesSelectedCaptureTypesTF = (captureValueObj.capture_type_id === userSelectedSingleCaptureTypeID);
            }
            else if(allCaptureTypesSelectedTF) { //all capture types selected, every capture matches this filter
              captureMatchesSelectedCaptureTypesTF = true;
            }
            else { //multiple capture types selected, determine if the capture capture type is one of those
              captureMatchesSelectedCaptureTypesTF = JSFUNC.in_array(captureValueObj.capture_type_id, c_userStageViewSelectedCaptureTypeIDsArray);
            }

            if(captureMatchesSelectedCaptureTypesTF) {
              numCapturesInStage++;
              limitedOrAllStageCaptureIDsArray.push(captureValueObj.id);

              //if this capture type/stage combination has found more than 50 captures, stop recording captureIDs for this column (unless the limit drawn has been lifted to infinite by the user)
              if(numCapturesInStage > o_cstOrChartMaxNumCapturesDrawn) {
                stageHasMoreCapturesThanLimitTF = true;
                break;
              }
            }
          }
        }
        
        stageObj.numCapturesInStage = numCapturesInStage;
        stageObj.limitedOrAllStageCaptureIDsArray = limitedOrAllStageCaptureIDsArray;
        stageObj.stageHasMoreCapturesThanLimitTF = stageHasMoreCapturesThanLimitTF;
        stageObj.pastCurrentFutureFlag = "futureOpen";
      }
    }
    return(stagesArrayOfObjs);
  }

  get c_stageViewAtLeast1CaptureInSelectedCaptureTypeTF() {
    const c_stageViewSelectedCaptureTypeStagesArrayOfObjs = this.c_stageViewSelectedCaptureTypeStagesArrayOfObjs;

    for(let stageObj of c_stageViewSelectedCaptureTypeStagesArrayOfObjs) {
      if(stageObj.numCapturesInStage > 0) {
        return(true);
      }
    }
    return(false);
  }

  get c_stageViewAtLeast1StageHasMoreCapturesThanLimitTF() {
    const c_stageViewSelectedCaptureTypeStagesArrayOfObjs = this.c_stageViewSelectedCaptureTypeStagesArrayOfObjs;

    for(let stageObj of c_stageViewSelectedCaptureTypeStagesArrayOfObjs) {
      if(stageObj.stageHasMoreCapturesThanLimitTF) {
        return(true);
      }
    }
    return(false);
  }




  //timeline charts
  get c_timelineChartTimeSpanObj() {
    //timeline is defined where 0% is startDate at 0:00, and 100% at endDate+1 0:00 (basically endDate at 23:59:59.999)
    const startDate = UserMobx.c_combinedUserObj.capture_chart_start_date;
    var endDate = UserMobx.c_combinedUserObj.capture_chart_end_date; //end date is shifted up by 1 day, having the same start and end date (2018-04-15 to 2018-04-15) is a full 24 hours from midnight 4/15 to midnight 4/16

    const startDateIsValidTF = JSFUNC.date_is_filled_out_tf(startDate);
    const endDateIsValidTF = JSFUNC.date_is_filled_out_tf(endDate);

    var startDateUnixSec = 0;
    var endDateUnixSec = 0;
    var totalNumSec = 0;
    var totalNumDays = 0;
    var showDaysTF = false;
    var singleLetterMonthsTF = true;
    var monthLinesArrayOfObjs = [];
    const nowDateTimeUTC = JSFUNC.now_datetime_utc();
    var todayPos = 0;

    if(startDateIsValidTF && endDateIsValidTF && (endDate >= startDate)) {

      var minDaysSingleLetterMonths = 800;
      if(CaptureExecMobx.o_mediaQueryFlag === 1) { minDaysSingleLetterMonths = 300; }
      else if(CaptureExecMobx.o_mediaQueryFlag === 2) { minDaysSingleLetterMonths = 500; }
      else if(CaptureExecMobx.o_mediaQueryFlag === 3) { minDaysSingleLetterMonths = 800; }
      else if(CaptureExecMobx.o_mediaQueryFlag === 4) { minDaysSingleLetterMonths = 1000; }
      else if(CaptureExecMobx.o_mediaQueryFlag === 5) { minDaysSingleLetterMonths = 1200; }
      else if(CaptureExecMobx.o_mediaQueryFlag === 6) { minDaysSingleLetterMonths = 1500; }

      var startJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(startDate);
      var endJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(endDate);
      endJsDateObj.setDate(endJsDateObj.getDate() + 1);
      endDate = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(endJsDateObj, false); //record end date as endDate + 1
      totalNumDays = JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(startJsDateObj, endJsDateObj);

      startDateUnixSec = Math.round(startJsDateObj.getTime() / 1000);
      endDateUnixSec = Math.round(endJsDateObj.getTime() / 1000);

      showDaysTF = (totalNumDays < 94); //show day ticks within 3 months
      singleLetterMonthsTF = (totalNumDays > minDaysSingleLetterMonths);

      var currentMonthObj = undefined;
      var prevMonthIndex = -1; //-1 initialize to trigger the first month object being created in the array
      for(let d = 0; d < totalNumDays; d++) {
        var currentMonth0to11 = startJsDateObj.getMonth();
        var currentDate1to31 = startJsDateObj.getDate();

        if(currentMonth0to11 !== prevMonthIndex) {
          if(currentMonthObj !== undefined) { //push the previous month now finished onto the array (unless this is the first loop where it is still undefined)
            monthLinesArrayOfObjs.push(currentMonthObj);
          }

          var currentYear = startJsDateObj.getFullYear();
          var shortYear = "'" + JSFUNC.num2str(currentYear).substring(2,4);

          var monthPos = ((currentDate1to31 === 1) ? ((d / totalNumDays) * 100) : (0)); //label for the month goes on the 1st (this date in the loop), if this date is not the first when the month transitions, the timeline started in the middle of the month, the label goes at the 0% mark
          var monthName = ((singleLetterMonthsTF) ? (JSFUNC.date_month_letter_from_index(currentMonth0to11)) : (JSFUNC.date_mth_from_index(currentMonth0to11)));
          if(showDaysTF) {
            monthName += " " + shortYear;
          }

          currentMonthObj = {
            year: currentYear,
            shortYear: shortYear,
            monthName: monthName,
            monthPos: monthPos,
            monthIndex0to11: currentMonth0to11,
            datesArrayOfObjs: []
          }
        }

        currentMonthObj.datesArrayOfObjs.push({
          date: currentDate1to31,
          datePos: ((d / totalNumDays) * 100)
        })

        //increment the start date by 1 day for the next loop iteration
        prevMonthIndex = currentMonth0to11;
        startJsDateObj.setDate(startJsDateObj.getDate() + 1);
      }
      monthLinesArrayOfObjs.push(currentMonthObj);

      totalNumSec = endDateUnixSec - startDateUnixSec;

      const todayJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(nowDateTimeUTC);
      const todayDateTimeUnixSec = Math.round(todayJsDateObj.getTime() / 1000);
      todayPos = ((todayDateTimeUnixSec - startDateUnixSec) / totalNumSec) * 100;
      if(todayPos < 0 || todayPos > 100) {
        todayPos = undefined;
      }
    }

    const timelineChartTimeSpanObj = {
      startDate: startDate,
      endDate: endDate,
      startDateIsValidTF: startDateIsValidTF,
      endDateIsValidTF: endDateIsValidTF,
      startDateUnixSec: startDateUnixSec,
      endDateUnixSec: endDateUnixSec,
      totalNumSec: totalNumSec,
      totalNumDays: totalNumDays,
      showDaysTF: showDaysTF,
      singleLetterMonthsTF: singleLetterMonthsTF,
      monthLinesArrayOfObjs: monthLinesArrayOfObjs,
      nowDateTimeUTC: nowDateTimeUTC,
      todayPos: todayPos
    }

    return(timelineChartTimeSpanObj);
  }


  compute_progress_chart_bars_data_obj(i_dvDataArrayOfObjs, i_startDate, i_endDate, i_startDateUnixSec, i_timelineTotalNumSec, i_nowDateTimeUTC, i_progressPwinStagesFlag) {
    //progress/pwin/stages
    const shapingTruePwinFalse = (i_progressPwinStagesFlag === "progress");
    const isStagesTF = (i_progressPwinStagesFlag === "stages");

    //return data arrayOfObjs initialized
    var progressBarsArrayOfObjs = [];
    var bgProgressColorsArrayOfObjs = [];

    const numDataPoints = i_dvDataArrayOfObjs.length;

    //variables that continuously hold their value through data points that have the save progress/pwin value until it finally changes, then that elongated single record is pushed onto the array
    var barJsDateObj1 = undefined;
    var barPos1 = undefined;
    var barJsDateObj2 = undefined;
    var barPos2 = undefined;
    var barValue = undefined;

    var colorJsDateObj1 = undefined;
    var colorPos1 = undefined;
    var colorJsDateObj2 = undefined;
    var colorPos2 = undefined;
    var bgColor = undefined;

    //for every bar except the first, the prev pos2 right edge is the current pos1 left edge, save this so that the calculation is not duplicated each loop
    var thisJsDateObj = undefined;
    var thisPos = undefined;
    var nextJsDateObj = undefined;
    var nextPos = undefined;
    var thisValue = undefined;
    var thisBgColor = undefined;

    //loop through each log entry data point containing a datetime "d" and the progress/pwin value "v"
    for(let i = 0; i < numDataPoints; i++) {
      var nextIndex = i+1;
      var isLastEntryTF = (nextIndex === numDataPoints);

      //log entry dateTimeUTC stored in variable "d" in format "2018-04-15 16:00:00"
      var logDateTimeUTC = i_dvDataArrayOfObjs[i].d;

      //get date of the next entry in the array, if this is the last entry, set it to today's current dateTime (progress was set last time it was changed and has remained constant at that value until right now looking at the chart)
      var nextLogDateTimeUTC = undefined;
      if(isLastEntryTF) { //this is the last entry, set the date to today's current datetime
        nextLogDateTimeUTC = i_nowDateTimeUTC;
      }
      else { //date of the next entry in the array
        nextLogDateTimeUTC = i_dvDataArrayOfObjs[nextIndex].d;
      }

      //6 cases for this date and next date being among the timeline (line only drawn if next > start and this < end)
      //  - this next |           timeline |              no line drawn
      //  - this      | next      timeline |              line from beginning (0%) to next drawn
      //  - this      |           timeline | next         line from beginning (0%) to end (100%) drawn
      //  -           | this next timeline |              line from this to next drawn
      //  -           | this      timeline | next         line from this to end (100%) drawn
      //  -           |           timeline | this next    no line drawn

      var nextAboveStartTF = (nextLogDateTimeUTC > i_startDate);
      var thisBelowEndTF = (logDateTimeUTC < i_endDate);
      if(nextAboveStartTF && thisBelowEndTF) {
        var thisAboveStartTF = (logDateTimeUTC >= i_startDate);
        var nextBelowEndTF = (nextLogDateTimeUTC <= i_endDate);

        //progress percent value in data variable "v"
        thisValue = i_dvDataArrayOfObjs[i].v;

        //compute the position of this date
        if(!thisAboveStartTF) { //this date is before the timeline start
          thisJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(logDateTimeUTC);
          thisPos = 0;
        }
        else { //this date is between the timeline start and end
          if(nextPos === undefined) { //first entry has no previous reference for the left edge and must have its position calculated
            thisJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(logDateTimeUTC);
            thisPos = ((((thisJsDateObj.getTime() / 1000) - i_startDateUnixSec) / i_timelineTotalNumSec) * 100);
          }
          else { //pos1 left edge is the same as the previous bar's right edge
            thisJsDateObj = nextJsDateObj;
            thisPos = nextPos;
          }
        }

        //compute the position of the next date after this one (this serves as the right side "position2" of the current "this" bar)
        nextJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(nextLogDateTimeUTC);
        if(!nextBelowEndTF) { //next date is after timeline end
          nextPos = 100;
        }
        else { //next date is between the timeline start and end
          nextPos = ((((nextJsDateObj.getTime() / 1000) - i_startDateUnixSec) / i_timelineTotalNumSec) * 100);
        }

        //update the bar and color pos2 to the "this" calculations so that the previous bar/color can be pushed onto the array if the value has changed from the previous to this
        barJsDateObj2 = thisJsDateObj;
        barPos2 = thisPos;
        colorJsDateObj2 = thisJsDateObj;
        colorPos2 = thisPos;

        //determine if the bar changed progress/pwin value from the previous bar (or if this is the first item where comparing to undefined will trigger pushing it)
        if(thisValue !== barValue) { //this entry has a different value than the previous one
          //push the previous progress bar obj for the svg to draw with a constant progress value and a start and end position on the visible timeline (will be undefined the first time through this loop, so do not push that one on, have to separately push the last obj on at the bottom of this loop)
          if((barValue !== undefined) && (barPos2 > 0)) {
            //last minute corrections because the code above doesn't 100% solve this problem perfectly
            if(barPos1 < 0) {
              barPos1 = 0;
            }

            progressBarsArrayOfObjs.push({
              pos1: barPos1,
              pos2: barPos2,
              value: barValue,
              numDays: JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(barJsDateObj1, barJsDateObj2)
            });
          }

          //set the positions for the next bar to pos1=this and pos2=next with a value=this
          barJsDateObj1 = thisJsDateObj;
          barPos1 = thisPos;
          barJsDateObj2 = nextJsDateObj;
          barPos2 = nextPos;
          barValue = thisValue;
        }
        else { //this entry's value matches the previous one, extend the pos2 of the bar being held in memory
          barPos2 = nextPos;
        }

        if(!isStagesTF) { //no bgColor references for stages
          //color referenced from progress or pwin color tbl
          var [tempBgColor, tempFontColor] = DatabaseMobx.shaping_progress_or_pwin_color_from_percent0to100(thisValue, shapingTruePwinFalse);
          thisBgColor = "#" + tempBgColor;

          //determine if the bar changed bgColor due to progress changing
          if(thisBgColor !== bgColor) { //this entry has a different value than the previous one
            //push the previous bgColor obj for the svg to draw
            if((bgColor !== undefined) && (colorPos2 > 0)) {
              //last minute corrections because the code above doesn't 100% solve this problem perfectly
              if(colorPos1 < 0) {
                colorPos1 = 0;
              }

              bgProgressColorsArrayOfObjs.push({
                pos1: colorPos1,
                pos2: colorPos2,
                value: bgColor,
                numDays: JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(barJsDateObj1, barJsDateObj2)
              });
            }

            //set the positions for the next bar
            colorPos1 = thisPos;
            colorPos2 = nextPos;
            bgColor = thisBgColor;
          }
          else { //this entry's value matches the previous one, extend the pos2 of the bar being held in memory
            colorPos2 = nextPos;
          }
        }
      }
    }

    //push the last bar obj on
    if(barValue !== undefined) {
      progressBarsArrayOfObjs.push({
        pos1: barPos1,
        pos2: barPos2,
        value: barValue,
        numDays: JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(barJsDateObj1, barJsDateObj2)
      });
    }

    if(!isStagesTF) { //no bgColor references for stages
      //push the last color obj on
      if(bgColor !== undefined) {
        bgProgressColorsArrayOfObjs.push({
          pos1: colorPos1,
          pos2: colorPos2,
          value: bgColor,
          numDays: JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(barJsDateObj1, barJsDateObj2)
        });
      }
    }

    return({
      progressBarsArrayOfObjs: progressBarsArrayOfObjs,
      bgProgressColorsArrayOfObjs: bgProgressColorsArrayOfObjs
    });
  }






  //master presets editor
  get c_presetMyCaptureTablePresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_capture_table_presets", true));
  }
  get c_presetPublicCaptureTablePresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_capture_table_presets", false));
  }
  get c_presetMyCstColumnPresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_cst_column_presets", true));
  }
  get c_presetPublicCstColumnPresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_cst_column_presets", false));
  }
  get c_presetMyFilterPresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_filter_presets", true));
  }
  get c_presetPublicFilterPresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_filter_presets", false));
  }
  get c_presetMySortPresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_sort_presets", true));
  }
  get c_presetPublicSortPresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_sort_presets", false));
  }

  get c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj() { //used in Admin 'Automated Exports' to select a column/filter/sort View Button preset to export the .csv files
    const c_presetMyCaptureTablePresetsArrayOfObjs = this.c_presetMyCaptureTablePresetsArrayOfObjs;
    const c_presetPublicCaptureTablePresetsArrayOfObjs = this.c_presetPublicCaptureTablePresetsArrayOfObjs;

    var valueArray = [-2];
    var displayArray = ["My Presets"];
    var bgColorArray = ["dddddd"];
    var unableToHighlightOrClickTFArray = [true];

    for(let viewButtonObj of c_presetMyCaptureTablePresetsArrayOfObjs) {
      valueArray.push(viewButtonObj.id);
      displayArray.push(viewButtonObj.name);
      bgColorArray.push(undefined);
      unableToHighlightOrClickTFArray.push(false);
    }

    valueArray.push(-3);
    displayArray.push("Public Presets");
    bgColorArray.push("dddddd");
    unableToHighlightOrClickTFArray.push(true);

    for(let viewButtonObj of c_presetPublicCaptureTablePresetsArrayOfObjs) {
      valueArray.push(viewButtonObj.id);
      displayArray.push(viewButtonObj.name);
      bgColorArray.push(undefined);
      unableToHighlightOrClickTFArray.push(false);
    }

    const swsOptionsObj = {bgColorArray:bgColorArray, unableToHighlightOrClickTFArray:unableToHighlightOrClickTFArray, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Capture Table View Button Preset", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_presetEditorOpenPresetObj() {
    const o_presetEditorOpenPresetID = this.o_presetEditorOpenPresetID;
    const o_presetEditorOpenPresetType = this.o_presetEditorOpenPresetType;

    if(o_presetEditorOpenPresetID === undefined || this.o_presetEditorOpenPresetType === undefined) {
      return(undefined);
    }

    const presetTblName = this.preset_tbl_name_from_preset_type(o_presetEditorOpenPresetType);
    const presetItemsTblName = this.preset_field_items_tbl_name_from_preset_type(o_presetEditorOpenPresetType);
    const itemsPresetIDColumnName = this.field_items_preset_id_column_name_from_preset_type(o_presetEditorOpenPresetType);

    //load the open preset as an obj
    const openPresetMap = DatabaseMobx.tbl_row_map_from_id(presetTblName, o_presetEditorOpenPresetID);
    var openPresetObj = JSFUNC.obj_from_map(openPresetMap);

    openPresetObj.presetType = o_presetEditorOpenPresetType;

    var isMasterTF = false;
    var isCstColumnsTF = false;
    var isFilterTF = false;
    var isSortTF = false;
    var presetLabel = "--Invalid Preset Type '" + o_presetEditorOpenPresetType + "'--";
    if(o_presetEditorOpenPresetType === "master") { isMasterTF = true; presetLabel = "Master"; }
    else if(o_presetEditorOpenPresetType === "cstColumns") { isCstColumnsTF = true; presetLabel = "Column"; }
    else if(o_presetEditorOpenPresetType === "filter") { isFilterTF = true; presetLabel = "Filter"; }
    else if(o_presetEditorOpenPresetType === "sort") { isSortTF = true; presetLabel = "Sort"; }
    openPresetObj.isMasterTF = isMasterTF;
    openPresetObj.isCstColumnsTF = isCstColumnsTF;
    openPresetObj.isFilterTF = isFilterTF;
    openPresetObj.isSortTF = isSortTF;
    openPresetObj.presetLabel = presetLabel;
    openPresetObj.presetTblName = presetTblName;
    openPresetObj.presetItemsTblName = presetItemsTblName;
    openPresetObj.itemsPresetIDColumnName = itemsPresetIDColumnName;
    openPresetObj.isPresetOwnerCanEditTF = UserMobx.user_id_is_one_of_logged_in_user_per_email_multilogin_tf(openPresetObj.user_id); //user can only edit/delete presets that they have created

    //load the preset items (cstColumn fields, filter fields/operators/values, sort fields/directions)
    const allSelectWithSearchDataObjOptionalFieldNamesArray = DatabaseMobx.all_selectWithSearchDataObj_optional_field_names_array();
    var presetItemsArrayOfObjs = [];
    var addedFieldIDsArray = []; //fieldIDs that have already been added to this preset items list
    if(presetItemsTblName !== undefined) {
      const presetItemsTblRef = DatabaseMobx.tbl_ref_from_tbl_name(presetItemsTblName);
      presetItemsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(presetItemsTblRef, itemsPresetIDColumnName, o_presetEditorOpenPresetID);
      for(let presetItemObj of presetItemsArrayOfObjs) { //look up the date field data and add the name of the field to these objs
        var captureFieldMap = DatabaseMobx.tbl_row_map_from_id("tbl_captures_fields", presetItemObj.capture_field_id);
        var captureFieldID = captureFieldMap.get("id");
        var captureFieldDbName = captureFieldMap.get("db_name");
        var captureFieldDisplayName = captureFieldMap.get("display_name");
        var fieldTypeObj = captureFieldMap.get("fieldTypeObj");
        var canCalculateCstSumRowTF = captureFieldMap.get("canCalculateCstSumRowTF");

        //get the fieldTypeObj of this field (for cstColumn, filter, and sort), for filter reassign some of them to different types for selection
        var tempFieldTypeObj = undefined;
        if(openPresetObj.isFilterTF) { //for filter items, force any single select capture fields to become multiselect, also force all sharedpercent to become multiselect, also force dates to become dateWithRelativeDate
          tempFieldTypeObj = JSFUNC.copy_obj(fieldTypeObj); //idsb used in filter to determine if values comma list should be converted to an array of ints or an array of strings
          if(tempFieldTypeObj.textareaTF) {
            tempFieldTypeObj = DatabaseMobx.create_field_type_obj("text");
          }
          else if(tempFieldTypeObj.valueDisplayIsDateOrDateTimeTF && !tempFieldTypeObj.dateWithRelativeDateTF) { //convert a date input type to the dateWithRelativeDate construct with the option to change to today's date
            tempFieldTypeObj = DatabaseMobx.create_field_type_obj("dateWithRelativeDate");
          }
          else if(tempFieldTypeObj.sharedPercentTF || (tempFieldTypeObj.requiresSelectWithSearchDataObjTF && !tempFieldTypeObj.selectWithSearchDataObj.isMultiSelectTF)) { //change all sharedpercent fields to select with multiselect, change all single select fields to multiselect
            var sdo = tempFieldTypeObj.selectWithSearchDataObj;

            //create a copy of the sws data obj, but set isMultiSelectTF to true and remove any optionsHeightEm settings
            var multiSelectSwsOptionsObj = {};
            for(let swsDataObjOptionalFieldName of allSelectWithSearchDataObjOptionalFieldNamesArray) {
              multiSelectSwsOptionsObj[swsDataObjOptionalFieldName] = sdo[swsDataObjOptionalFieldName];
            }
            multiSelectSwsOptionsObj.isMultiSelectTF = true;
            multiSelectSwsOptionsObj.optionsHeightEm = undefined;

            var multiSelectSwsDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array(sdo.itemName, sdo.valueArray, sdo.valuesAreStringsTF, sdo.displayArray, multiSelectSwsOptionsObj);
            tempFieldTypeObj = DatabaseMobx.create_field_type_obj("select", multiSelectSwsDataObj);
          }
        }
        else { //cstColumn and sort
          tempFieldTypeObj = fieldTypeObj; //use the fieldTypeObj as is for read only
        }

        presetItemObj.fieldDbName = captureFieldDbName;
        presetItemObj.fieldDisplayName = captureFieldDisplayName;
        presetItemObj.fieldTypeObj = tempFieldTypeObj;
        presetItemObj.canCalculateCstSumRowTF = canCalculateCstSumRowTF;

        addedFieldIDsArray.push(captureFieldID);
      }

      //sort the presets that have their items sorted by "sort" ASC
      if(isCstColumnsTF || isSortTF) {
        JSFUNC.sort_arrayOfObjs(presetItemsArrayOfObjs, "sort", true);
      }
    }
    openPresetObj.presetItemsArrayOfObjs = presetItemsArrayOfObjs;
    openPresetObj.addedFieldIDsArray = addedFieldIDsArray;
    openPresetObj.numPresetItems = addedFieldIDsArray.length;

    return(openPresetObj);
  }


  get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name(i_tblName, i_myPresetsTruePublicPresetsThatAreNotMineFalse) {
    const c_multiLoginUserIDsArray = UserMobx.c_multiLoginUserIDsArray; //all multilogin userIDs of currently logged in userPerEmail

    const tblRef = DatabaseMobx.tbl_ref_from_tbl_name(i_tblName);
    var presetsArrayOfObjs = [];
    for(let presetMap of tblRef.values()) { //manually filter mapOfMaps
      var public01 = presetMap.get("public_01");
      var userID = presetMap.get("user_id");

      var myPresetTF = JSFUNC.in_array(userID, c_multiLoginUserIDsArray);
      var presetIsPublicTF = (public01 === 1);

      if((i_myPresetsTruePublicPresetsThatAreNotMineFalse && myPresetTF) || (!i_myPresetsTruePublicPresetsThatAreNotMineFalse && presetIsPublicTF && !myPresetTF)) {
        var presetObj = JSFUNC.obj_from_map(presetMap);
        presetObj.isPresetOwnerCanEditTF = myPresetTF; //user can always edit their own my presets, user cannot edit other user's public presets
        presetObj.nameLowercase = presetObj.name.toLowerCase(); //add a lowercase name field for sorting
        presetsArrayOfObjs.push(presetObj);
      }
    }
    presetsArrayOfObjs.sort(JSFUNC.sort_by_asc("nameLowercase"))
    return(presetsArrayOfObjs);
  }

  
  get c_viewButtonEditorOpenCFSSelectMultiCaptureFieldsFieldTypeObj() { //precomputed fieldTypeObj for adding new tbl_captures_fields columns to either a cstColumn preset, a filter preset, or a sort preset
    const c_presetEditorOpenPresetObj = this.c_presetEditorOpenPresetObj;
    const c_valueDisplayArraysObjCaptureFields = DatabaseMobx.c_valueDisplayArraysObjCaptureFields;

    var tempValueDisplayArraysObjCaptureFields = c_valueDisplayArraysObjCaptureFields; //for filters, you are allowed to add duplicate fields, which act as AND statements for that field, so all fields are included always
    if(c_presetEditorOpenPresetObj.isCstColumnsTF || c_presetEditorOpenPresetObj.isSortTF) { //for columns and sort columns, fields can only be added once, so do prevent them from being checked if they have already been added
      if(c_presetEditorOpenPresetObj.numPresetItems > 0) { //if any columns/sorts are already added, copy the valuedisplayarrays obj and modify the 
        tempValueDisplayArraysObjCaptureFields = JSFUNC.copy_obj(c_valueDisplayArraysObjCaptureFields);

        var bgColorArray = [];
        var unableToHighlightOrClickTFArray = [];
        for(let fieldID of c_valueDisplayArraysObjCaptureFields.valueArray) {
          if(JSFUNC.in_array(fieldID, c_presetEditorOpenPresetObj.addedFieldIDsArray)) {
            bgColorArray.push("cccccc");
            unableToHighlightOrClickTFArray.push(true);
          }
          else {
            bgColorArray.push("ffffff");
            unableToHighlightOrClickTFArray.push(false);
          }
        }

        tempValueDisplayArraysObjCaptureFields.bgColorArray = bgColorArray;
        tempValueDisplayArraysObjCaptureFields.unableToHighlightOrClickTFArray = unableToHighlightOrClickTFArray;
      }
    }

    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(tempValueDisplayArraysObjCaptureFields, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }






  //gantt presets editor
  get c_ganttDatesPresetMyPresetsArrayOfObjs() {
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_gantt_date_presets", true));
  }

  get c_ganttDatesPresetPublicPresetsArrayOfObjs() { //all public presets that are not mine
    return(this.get_my_presets_or_public_presets_that_are_not_mine_from_preset_tbl_name("tbl_f_gantt_date_presets", false));
  }

  get c_selectGanttPresetsFieldTypeObj() {
    const myAndPublicPresetsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(this.c_ganttDatesPresetMyPresetsArrayOfObjs, this.c_ganttDatesPresetPublicPresetsArrayOfObjs);

    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Gantt Dates Preset", myAndPublicPresetsArrayOfObjs, "id", false, "name", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_ganttPresetEditorOpenPresetObj() {
    return(this.create_gantt_preset_obj(this.o_ganttDatesOpenPresetID));
  }

  get c_ganttHasValidPresetSelectedTF() {
    const ganttDatePresetIDSelected = UserMobx.c_combinedUserObj.gantt_date_preset_id_selected;
    if(!JSFUNC.select_int_is_filled_out_tf(ganttDatePresetIDSelected)) {
      return(false);
    }
    return(DatabaseMobx.o_tbl_f_gantt_date_presets.has(ganttDatePresetIDSelected));
  }

  get c_ganttSelectedPresetObj() {
    return(this.create_gantt_preset_obj(UserMobx.c_combinedUserObj.gantt_date_preset_id_selected));
  }

  create_gantt_preset_obj(i_ganttDatePresetID) { //combination of the presetObj and all of its dateObjs assigned to it
    if(!this.c_ganttHasValidPresetSelectedTF) {
      return(undefined);
    }

    const openGanttPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_gantt_date_presets", i_ganttDatePresetID);
    if(openGanttPresetMap === undefined) {
      return(undefined);
    }

    const openGanttPresetObj = JSFUNC.obj_from_map(openGanttPresetMap);

    const ganttDatesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_f_gantt_dates, "gantt_date_preset_id", i_ganttDatePresetID); //no sorting, keep them in the order they were added
    for(let ganttDateObj of ganttDatesArrayOfObjs) { //look up the date field data and add the name of the field to these objs
      var captureFieldMap = DatabaseMobx.tbl_row_map_from_id("tbl_captures_fields", ganttDateObj.capture_field_id);

      var fieldIsDateWithDurationTF = false;
      var fieldTypeObj = captureFieldMap.get("fieldTypeObj");
      if(fieldTypeObj !== undefined) {
        fieldIsDateWithDurationTF = fieldTypeObj.dateWithDurationTF;
      }

      ganttDateObj.fieldDbName = captureFieldMap.get("db_name");
      ganttDateObj.fieldDisplayName = captureFieldMap.get("display_name");
      ganttDateObj.fieldIsDateWithDurationTF = fieldIsDateWithDurationTF;
    }

    openGanttPresetObj.ganttDatesArrayOfObjs = ganttDatesArrayOfObjs;
    openGanttPresetObj.addedDateFieldIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(ganttDatesArrayOfObjs, "capture_field_id"); //get all capture field ids already added
    openGanttPresetObj.numAddedDates = ganttDatesArrayOfObjs.length;
    openGanttPresetObj.isPresetOwnerCanEditTF = UserMobx.user_id_is_one_of_logged_in_user_per_email_multilogin_tf(openGanttPresetObj.user_id); //user can only edit/delete presets that they have created

    return(openGanttPresetObj);
  }





  //create new capture
  get c_createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs() {
    const o_createNewCaptureOpportunityNameLowercase = this.o_createNewCaptureOpportunityNameLowercase;
    const c_allCaptureIDsAndNamesLowercaseArrayOfObjs = DatabaseMobx.c_allCaptureIDsAndNamesLowercaseArrayOfObjs;

    if(!JSFUNC.is_string(o_createNewCaptureOpportunityNameLowercase) || (o_createNewCaptureOpportunityNameLowercase === "")) {
      return([]);
    }

    var createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs = [];
    for(let captureIDAndNameLowercaseObj of c_allCaptureIDsAndNamesLowercaseArrayOfObjs) {
      if(JSFUNC.input_lowercase_string_contains_lowercase_search_term_string_tf(captureIDAndNameLowercaseObj.nameLowercase, o_createNewCaptureOpportunityNameLowercase)) {
        createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs.push(JSFUNC.copy_obj(captureIDAndNameLowercaseObj));
      }
    }

    return(createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs);
  }

  get c_createNewCaptureDocumentsCardFoldersSelectedPresetName() {
    const o_createNewCaptureSelectedDocumentsCardFoldersPresetID = this.o_createNewCaptureSelectedDocumentsCardFoldersPresetID;

    const selectedDocumentsCardFoldersPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shortcut_presets_documents_card_folders", o_createNewCaptureSelectedDocumentsCardFoldersPresetID);
    return(selectedDocumentsCardFoldersPresetMap.get("preset_name"));
  }

  get c_createNewCaptureDocumentsCardFoldersSortedTreeWithIndentsArrayOfObjs() {
    const o_createNewCaptureSelectedDocumentsCardFoldersPresetID = this.o_createNewCaptureSelectedDocumentsCardFoldersPresetID;
    const o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem = DatabaseMobx.o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem;
    const c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF = DatabaseMobx.c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF;

    var documentsCardFoldersSortedTreeWithIndentsArrayOfObjs = [];
    if(c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF) {
      if(JSFUNC.select_int_is_filled_out_tf(o_createNewCaptureSelectedDocumentsCardFoldersPresetID)) {
        const filteredDocumentsCardFoldersPresetFoldersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem, "shortcut_preset_id", o_createNewCaptureSelectedDocumentsCardFoldersPresetID);
        documentsCardFoldersSortedTreeWithIndentsArrayOfObjs = JSFUNC.compute_sorted_tree_with_indents_arrayOfObjs_from_tree_arrayOfObjs(filteredDocumentsCardFoldersPresetFoldersArrayOfObjs, "parent_folder_id", "display_name");
      }
    }

    return(documentsCardFoldersSortedTreeWithIndentsArrayOfObjs);
  }






  //================================================================================================================================================================================
  //================================================================================================================================================================================
  //action methods
  a_set_cst_or_chart_max_num_captures_drawn(i_maxTrueResetFalse, i_viewIsProgressChartTF) {
    var newMaxNumCapturesDrawn = 50; //reset false resets the maximum number of captures to 50
    if(i_maxTrueResetFalse) {
      newMaxNumCapturesDrawn = JSFUNC.sort_max_mysqli_int(); //max true sets the nax number of captures to 1e9
    }
    this.o_cstOrChartMaxNumCapturesDrawn = newMaxNumCapturesDrawn;

    //if selecting progress chart, load the progress data from the database for the filtered captures, turn on the loading flag while this is loading
    if(i_viewIsProgressChartTF) {
      this.a_load_limited_progress_chart_thin_log_data(newMaxNumCapturesDrawn);
    }
  }

  a_load_limited_progress_chart_thin_log_data(i_maxNumCapturesShown) {
    if(CaptureExecMobx.o_isLocalhost3000SystemTF || (this.c_numFilteredCaptures === 0)) { //no need to load any data if there are no captures being displayed
      return;
    }

    this.a_set_view_is_loading_tf(true);

    const functionOnSuccess = () => {
      this.a_set_view_is_loading_tf(false);
    }

    const filteredCaptureIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(this.c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs, "id");

    //limit the amount of data by only fetching from captureIDs that will be shown on the screen (have to reload all the data if the button is pushed to show all captures)
    var limitedFilteredCaptureIDsArray = undefined;
    if(i_maxNumCapturesShown < JSFUNC.sort_max_mysqli_int()) {
      limitedFilteredCaptureIDsArray = filteredCaptureIDsArray.slice(0, i_maxNumCapturesShown);
    }
    else {
      limitedFilteredCaptureIDsArray = filteredCaptureIDsArray;
    }

    const limitedFilteredCaptureIDsComma = JSFUNC.convert_array_to_comma_list(limitedFilteredCaptureIDsArray);
    const companyUsingPwinTF = DatabaseMobx.c_companyUsingPwinTF;
    JSPHP.progress_chart_load_thin_log_tbls(limitedFilteredCaptureIDsComma, companyUsingPwinTF, functionOnSuccess);
  }

  a_set_view_is_loading_tf(i_newValueTF) {
    this.o_viewIsLoadingTF = i_newValueTF;
  }

  a_set_captures_tab_selected_view_button(i_selectedCapturesTabViewButton) {
    const c_selectedLeftNavIsProgressChartViewTF = CaptureExecMobx.c_selectedLeftNavIsProgressChartViewTF;

    //store the newly selected table preset in the user table
    UserMobx.a_update_user_field("quick_access_master_preset_id_selected", i_selectedCapturesTabViewButton, "i");

    //reset the maximum number of captures displayed to 50
    const maxTrueResetFalse = false;
    this.a_set_cst_or_chart_max_num_captures_drawn(maxTrueResetFalse, c_selectedLeftNavIsProgressChartViewTF);

    //reset the override sort now that a preset is selected
    this.o_captureTableOverrideSortFieldID = -1;
    this.o_captureTableOverrideSortFieldIsAscTF = true;
  }

  a_cst_column_sort_override(i_cstColumnCaptureFieldID) {
    if(this.o_captureTableOverrideSortFieldID > 0) { //an override has already been established, keep the direction the same and change the column, if clicking the same column, flip the direction
      if(i_cstColumnCaptureFieldID === this.o_captureTableOverrideSortFieldID) { //click the same override column already being sorted, flip the direction
        this.o_captureTableOverrideSortFieldIsAscTF = (!this.o_captureTableOverrideSortFieldIsAscTF);
      }
      else { //click a different column, change the column being sorted, but keep the same direction as before
        this.o_captureTableOverrideSortFieldID = i_cstColumnCaptureFieldID;
      }
    }
    else { //first time clicking a sort column to override the selected sort preset, select that column and set the sort to ascending
      this.o_captureTableOverrideSortFieldID = i_cstColumnCaptureFieldID;
      this.o_captureTableOverrideSortFieldIsAscTF = true;
    }
  }

  a_cst_column_resize(i_draggedColumnTblFCstColumnsID, i_dropMouseXPosition) {
    const o_cePreviouslyDraggedItemObjOrUndefined = CaptureExecMobx.o_cePreviouslyDraggedItemObjOrUndefined;
    const c_userFontSizePx = UserMobx.c_userFontSizePx;

    if(o_cePreviouslyDraggedItemObjOrUndefined !== undefined) { //don't resize unless a CE dragged item is set in memory
      const cstColumnMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_cst_columns", i_draggedColumnTblFCstColumnsID);
      if(cstColumnMap !== undefined) { //only update if the cst column record exists
        const newColumnMinimumSizeEm = 5; //the minimum width allowed by the system
        const oldColumnSizeEm = cstColumnMap.get("width_em");
        const resizeBarChangeInXPositionEm = Math.round((i_dropMouseXPosition - o_cePreviouslyDraggedItemObjOrUndefined.dragStartX) / c_userFontSizePx);
        var newColumnSizeEm = oldColumnSizeEm + resizeBarChangeInXPositionEm;
        if(newColumnSizeEm < newColumnMinimumSizeEm) {
          newColumnSizeEm = newColumnMinimumSizeEm;
        }

        const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_cst_column_resize", ["i_draggedColumnTblFCstColumnsID", "i_dropMouseXPosition"], [i_draggedColumnTblFCstColumnsID, i_dropMouseXPosition]);
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
        C_CallPhpTblUID.add_update("tbl_f_cst_columns", i_draggedColumnTblFCstColumnsID, "width_em", newColumnSizeEm, "i");
        C_CallPhpTblUID.execute();
      }
    }
  }



  a_set_capture_table_inline_edit_single_cell_value_raw(i_newValueRaw) {
    this.o_captureTableInlineEditSingleCellValueRaw = i_newValueRaw;
  }

  a_set_capture_table_inline_edit_single_cell_full_textarea_is_loading_tf(i_newValueTF) {
    this.o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF = i_newValueTF;
  }

  a_capture_table_inline_edit_load_single_cell_full_textarea_from_capture_id_and_field_db_name(i_captureID, i_fieldDbName, i_slimTextareaValueRaw) {
    this.a_set_capture_table_inline_edit_single_cell_full_textarea_is_loading_tf(true);
    const functionOnFinish = (i_valueRawStringOrUndefined) => {
      var fullTextareaValueRaw = "";
      if(i_valueRawStringOrUndefined === undefined) { //database issue fetching the single capture textarea field raw value, use the original slim textarea string loaded at login with the capture
        fullTextareaValueRaw = i_slimTextareaValueRaw;
      }
      else {
        fullTextareaValueRaw = i_valueRawStringOrUndefined;
      }
      this.a_set_capture_table_inline_edit_single_cell_value_raw(fullTextareaValueRaw);

      this.a_set_capture_table_inline_edit_single_cell_full_textarea_is_loading_tf(false);
    };
    JSPHP.load_db_single_data_raw_value_or_undefined_from_tbl_name_and_row_id_and_field_db_name("tbl_captures", i_captureID, i_fieldDbName, functionOnFinish);
  }


  a_generate_and_download_capture_table_report_csv(i_exportToCsvFetchFullTextareasTF) {
    this.a_set_capture_table_csv_is_generating_tf(true);

    if(i_exportToCsvFetchFullTextareasTF) { //need to first fetch textarea type columns data from the database, then build the .csv
      const captureIDsArray = JSFUNC.get_column_vector_from_mapOfMaps(this.c_filteredCapturesMapOfMaps, "id");

      //collect all cst column fieldDbNames of fields that are raw textarea types
      var textareaFieldDbNamesArray = [];
      for(let cstColumnObj of this.c_cstColumnsArrayOfObjs) {
        if(cstColumnObj.rawTextareaTF) { //if this column is a textarea field type (meaning the data in this CST column is truncated to 100 chars when loaded at login)
          textareaFieldDbNamesArray.push(cstColumnObj.fieldDbName);
        }
      }

      if((captureIDsArray.length > 0) && (textareaFieldDbNamesArray.length > 0)) { //only need to proceed with textarea data fetch from database if there is at least 1 capture and 1 textarea type data column
        const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_generate_and_download_capture_table_report_csv", ["i_exportToCsvFetchFullTextareasTF"], [i_exportToCsvFetchFullTextareasTF]);
        const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadMultipleCapturesFullTextareaDataAndDealShapingData", jsDescription);
        C_CallPhpScript.add_post_var("i_captureIDsComma", JSFUNC.convert_array_to_comma_list(captureIDsArray));
        C_CallPhpScript.add_post_var("i_captureFieldDbNamesComma", JSFUNC.convert_array_to_comma_list(textareaFieldDbNamesArray));
        C_CallPhpScript.add_post_var("i_captureDealShapingQuestionIDsComma", ""); //no deal shaping answers needed for capture table csv
        C_CallPhpScript.add_return_vars(["capturesTextareaDataOr0String", "capturesDealShapingDataOr0String"]);

        const functionOnSuccess = (i_parseResponse) => {
          this.a_generate_and_download_capture_table_report_csv_internal(i_parseResponse.capturesTextareaDataOr0String);
          this.a_set_capture_table_csv_is_generating_tf(false);
        }
        C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

        const functionOnError = () => { //if there was an error, still perform the .csv creation without the full textarea data
          this.a_generate_and_download_capture_table_report_csv_internal();
          this.a_set_capture_table_csv_is_generating_tf(false);
        }
        C_CallPhpScript.add_function("onError", functionOnError);

        C_CallPhpScript.execute();
      }
      else { //no textarea columns (or 0 captures), no need to fetch textarea data from database
        this.a_generate_and_download_capture_table_report_csv_internal();
        this.a_set_capture_table_csv_is_generating_tf(false);
      }
    }
    else { //no need to pre fetch full textarea data
      this.a_generate_and_download_capture_table_report_csv_internal();
      this.a_set_capture_table_csv_is_generating_tf(false);
    }
  }

  a_generate_and_download_capture_table_report_csv_internal(i_capturesTextareaDataArrayOfObjsOrFalse=false) {
    const c_cstColumnsArrayOfObjs = this.c_cstColumnsArrayOfObjs;
    const c_selectedSortFieldsAndDirectionsObj = this.c_selectedSortFieldsAndDirectionsObj;
    const c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs = this.c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs;
    const c_filteredCapturesMapOfMaps = this.c_filteredCapturesMapOfMaps;
    const c_cstSumRowColumnsArrayOfObjsOrUndefined = this.c_cstSumRowColumnsArrayOfObjsOrUndefined;

    //determine if supplemented full textarea data is preset to be integrated into the .csv
    const usingFullTextareaDataTF = (JSFUNC.is_array(i_capturesTextareaDataArrayOfObjsOrFalse) && (i_capturesTextareaDataArrayOfObjsOrFalse.length > 0));

    //initialize array of arrays of data to be converted to .csv file
    var captureTableArrayOfArrays = [];

    //add column header names as first row
    var columnHeaderNamesArray = [];
    for(let cstColumnObj of c_cstColumnsArrayOfObjs) {
      columnHeaderNamesArray.push(cstColumnObj.fieldDisplayName);
    }
    captureTableArrayOfArrays.push(columnHeaderNamesArray);

    //if there is a CST sum row, insert that data into the csv as a row below the header names
    if(JSFUNC.is_array(c_cstSumRowColumnsArrayOfObjsOrUndefined)) {
      var cstSumValueMaskPlainTextsArray = [];
      for(let cstSumRowColumnObj of c_cstSumRowColumnsArrayOfObjsOrUndefined) {
        var cstSumValueMaskPlainText = ""; //default value if this column is not a sum column
        if(cstSumRowColumnObj.isCstSumColumnTF) {
          cstSumValueMaskPlainText = cstSumRowColumnObj.sumValueMaskPlainText;
        }
        cstSumValueMaskPlainTextsArray.push(cstSumValueMaskPlainText);
      }
      captureTableArrayOfArrays.push(cstSumValueMaskPlainTextsArray);
    }

    //compute a new sorted/filtered capture table in plain text (no html tags)
    const plainTextTF = true;
    const valueMasksAlteredForCsvOrXmlTF = true; //flag to convert mask values appropriate for .csv files
    const plainTextFilteredMaskedCapturesArrayOfObjs = this.compute_cst_filtered_masked_capture_values_arrayOfObjs(c_filteredCapturesMapOfMaps, c_allUniqueCstColumnAndSortCaptureFieldsArrayOfObjs, plainTextTF, valueMasksAlteredForCsvOrXmlTF);
    JSFUNC.sort_arrayOfObjs(plainTextFilteredMaskedCapturesArrayOfObjs, c_selectedSortFieldsAndDirectionsObj.propertyNamesArray, c_selectedSortFieldsAndDirectionsObj.sortIsAscTFArray);

    //capture rows data
    for(let captureObj of plainTextFilteredMaskedCapturesArrayOfObjs) {
      var captureDataArray = [];
      for(let cstColumnObj of c_cstColumnsArrayOfObjs) {
        //fetch the CST cell display value from the "db_nameMASK" value within the computed capture obj
        var captureData = captureObj[cstColumnObj.fieldDbName + "MASK"];

        //overwrite this CST value with the full textarea data if this column is a textarea and the data is provided
        if(usingFullTextareaDataTF) { //textarea loaded full data is provided
          if(cstColumnObj.rawTextareaTF) { //this column is a textarea type
            if(captureObj[cstColumnObj.fieldDbName + "IFO"]) { //no need to search for the full data if the original raw value is not filled out "" (the loaded raw value will also be blank)
              var captureTextareaDataObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(i_capturesTextareaDataArrayOfObjsOrFalse, "id", captureObj.id); //find the textarea data for this captureID
              if(captureTextareaDataObj !== undefined) {
                captureData = captureTextareaDataObj[cstColumnObj.fieldDbName];
              }
            }
          }
        }

        captureDataArray.push(captureData);
      }
      captureTableArrayOfArrays.push(captureDataArray);
    }

    const csvString = JSFUNC.convert_data_table_to_csv_string(captureTableArrayOfArrays);
    const downloadFileNameAndExt = JSFUNC.now_date() + " Capture Table for " + this.c_selectedCaptureTableMasterPresetObj.name + ".csv";
    JSFUNC.browser_offer_file_download_from_file_data_string(csvString, downloadFileNameAndExt);
  }

  a_set_capture_table_csv_is_generating_tf(i_newValueTF) {
    this.o_captureTableCsvIsGeneratingTF = i_newValueTF;
  }


  a_mass_edit_field_for_all_captures_in_capture_table(i_capturesArrayOfObjs, i_expandedCaptureFieldMap, i_valueRaw, i_sendMassEditNotificationsTF, i_functionOnFinish=undefined) {
    var captureIDsAndNamesToProcessArrayOfObjs = [];
    for(let captureObj of i_capturesArrayOfObjs) {
      var captureFullNameWithForcedCaptureID = DatabaseMobx.capture_name_plaintext_from_capture_id(captureObj.id, true);
      captureIDsAndNamesToProcessArrayOfObjs.push({
        id: captureObj.id,
        captureFullName: captureFullNameWithForcedCaptureID
      });
    }
    const numCapturesToProcess = captureIDsAndNamesToProcessArrayOfObjs.length;

    if(numCapturesToProcess > 0) {
      var updatedCaptureTableProcessingCapturesObjOrUndefined = {
        expandedCaptureFieldMap: i_expandedCaptureFieldMap,
        valueRaw: i_valueRaw,
        sendMassEditNotificationsTF: i_sendMassEditNotificationsTF,
        currentlyProcessingCaptureName: "",
        currentlyProcessingCaptureNumber: 0,
        totalNumCapturesToProcess: numCapturesToProcess,
        successCaptureNamesArray: [],
        failedCaptureNamesArray: [],
        recalculateTeammateCountsCaptureIDsArray: []
      };

      this.a_mass_edit_captures_recursive_edit_next_capture(updatedCaptureTableProcessingCapturesObjOrUndefined, captureIDsAndNamesToProcessArrayOfObjs, i_functionOnFinish);
    }
  }

  a_mass_edit_captures_recursive_edit_next_capture(i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs, i_functionOnFinish=undefined) {
    const k_cardIDAdminMassEditCapture = DatabaseMobx.k_cardIDAdminMassEditCapture;

    //unpack obj
    const expandedCaptureFieldMap = i_updatedCaptureTableProcessingCapturesObjOrUndefined.expandedCaptureFieldMap;
    const valueRaw = i_updatedCaptureTableProcessingCapturesObjOrUndefined.valueRaw;
    const sendMassEditNotificationsTF = i_updatedCaptureTableProcessingCapturesObjOrUndefined.sendMassEditNotificationsTF;
    const currentlyProcessingCaptureName = i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureName;
    const currentlyProcessingCaptureNumber = i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber;
    const totalNumCapturesToProcess = i_updatedCaptureTableProcessingCapturesObjOrUndefined.totalNumCapturesToProcess;
    const successCaptureNamesArray = i_updatedCaptureTableProcessingCapturesObjOrUndefined.successCaptureNamesArray;
    const failedCaptureNamesArray = i_updatedCaptureTableProcessingCapturesObjOrUndefined.failedCaptureNamesArray;
    const recalculateTeammateCountsCaptureIDsArray = i_updatedCaptureTableProcessingCapturesObjOrUndefined.recalculateTeammateCountsCaptureIDsArray;

    const updatedCurrentlyProcessingCaptureNumber = (currentlyProcessingCaptureNumber + 1);

    //increment the current capture number
    i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber = updatedCurrentlyProcessingCaptureNumber;

    //if the current capture number exceeds that number to delete, break the recursion
    if(updatedCurrentlyProcessingCaptureNumber > totalNumCapturesToProcess) {
      const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_mass_edit_captures_recursive_edit_next_capture", ["i_updatedCaptureTableProcessingCapturesObjOrUndefined", "i_captureIDsAndNamesToProcessArrayOfObjs", "i_functionOnFinish"], [i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs, i_functionOnFinish]);

      //update the delete floating box to display success/failed deleted capture names
      i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureName = "--Finished Editing Captures--";
      i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber = -1;
      this.a_set_capture_table_processing_captures_obj_or_undefined(i_updatedCaptureTableProcessingCapturesObjOrUndefined);

      //recalculate teammate counts for all captures requiring it
      JSPHP.recalculate_and_update_capture_teammate_counts_and_allocations_from_capture_id_or_ids_array(recalculateTeammateCountsCaptureIDsArray);

      //create an admin changelog entry
      const fieldUpdatedString = "[Capture Field Updated: " + expandedCaptureFieldMap.get("display_name") + "]";
      const newValueString = "[New Value: " + DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(valueRaw, expandedCaptureFieldMap.get("fieldTypeObj")) + "]";
      if(successCaptureNamesArray.length > 0) {
        const C_CallPhpTblUIDAdminChangelog = new JSPHP.ClassCallPhpTblUID(jsDescription);
        const adminChangelogNewValueString = fieldUpdatedString + "\n" + newValueString + "\n" + successCaptureNamesArray.join("\n");
        C_CallPhpTblUIDAdminChangelog.add_changelog_admin(500, adminChangelogNewValueString);
        C_CallPhpTblUIDAdminChangelog.execute();
      }

      //if there were any unsuccessful deletes, create a z_error message
      if(failedCaptureNamesArray.length > 0) {
        const massEditCapturesErrorMessage = "Failed to mass edit Captures from Capture Table:\n" + fieldUpdatedString + "\n" + newValueString + "\n" + failedCaptureNamesArray.join("\n");;
        JSPHP.record_z_error(jsDescription, massEditCapturesErrorMessage);
      }

      //if a finish function was provided as input, run it at this point at the very end
      if(JSFUNC.is_function(i_functionOnFinish)) {
        i_functionOnFinish();
      }
    }
    else {
      //load by index which capture id and name to delete
      const captureIDAndNameToProcessObj = i_captureIDsAndNamesToProcessArrayOfObjs[(updatedCurrentlyProcessingCaptureNumber - 1)];
      const captureID = captureIDAndNameToProcessObj.id;
      const captureFullName = captureIDAndNameToProcessObj.captureFullName;

      //update the processing floating box that this current capture is being updated
      i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureName = captureFullName;
      this.a_set_capture_table_processing_captures_obj_or_undefined(i_updatedCaptureTableProcessingCapturesObjOrUndefined);

      //update the capture field, on finish (success or error), recursively call this function to update the next capture
      const updateIntegrationSystemLinkedFieldTF = true;

      const functionOnVerifiedSuccess = (i_teammateCountsRecalculationRequiredCaptureIDOrUndefined) => {
        i_updatedCaptureTableProcessingCapturesObjOrUndefined.successCaptureNamesArray.push(captureFullName);
        if(i_teammateCountsRecalculationRequiredCaptureIDOrUndefined !== undefined) {
          i_updatedCaptureTableProcessingCapturesObjOrUndefined.recalculateTeammateCountsCaptureIDsArray.push(i_teammateCountsRecalculationRequiredCaptureIDOrUndefined);
        }
        this.a_mass_edit_captures_recursive_edit_next_capture(i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs, i_functionOnFinish);
      }

      const functionOnVerifiedError = () => {
        i_updatedCaptureTableProcessingCapturesObjOrUndefined.failedCaptureNamesArray.push(captureFullName);
        this.a_mass_edit_captures_recursive_edit_next_capture(i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs, i_functionOnFinish);
      }

      OpenCaptureMobx.a_details_update_field_value(expandedCaptureFieldMap, valueRaw, captureID, k_cardIDAdminMassEditCapture, sendMassEditNotificationsTF, updateIntegrationSystemLinkedFieldTF, functionOnVerifiedSuccess, functionOnVerifiedError);
    }
  }


  a_delete_all_captures_in_capture_table(i_capturesArrayOfObjs) {
    var captureIDsAndNamesToProcessArrayOfObjs = [];
    for(let captureObj of i_capturesArrayOfObjs) {
      var captureFullNameWithForcedCaptureID = DatabaseMobx.capture_name_plaintext_from_capture_id(captureObj.id, true);
      captureIDsAndNamesToProcessArrayOfObjs.push({
        id: captureObj.id,
        captureFullName: captureFullNameWithForcedCaptureID
      });
    }
    const numCapturesToProcess = captureIDsAndNamesToProcessArrayOfObjs.length;

    if(numCapturesToProcess > 0) {
      var updatedCaptureTableProcessingCapturesObjOrUndefined = {
        currentlyProcessingCaptureName: "",
        currentlyProcessingCaptureNumber: 0,
        totalNumCapturesToProcess: numCapturesToProcess,
        successCaptureNamesArray: [],
        failedCaptureNamesArray: []
      };

      this.a_delete_all_captures_recursive_delete_next_capture(updatedCaptureTableProcessingCapturesObjOrUndefined, captureIDsAndNamesToProcessArrayOfObjs);
    }
  }

  a_delete_all_captures_recursive_delete_next_capture(i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs) {
    //unpack obj
    const currentlyProcessingCaptureName = i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureName;
    const currentlyProcessingCaptureNumber = i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber;
    const totalNumCapturesToProcess = i_updatedCaptureTableProcessingCapturesObjOrUndefined.totalNumCapturesToProcess;
    const successCaptureNamesArray = i_updatedCaptureTableProcessingCapturesObjOrUndefined.successCaptureNamesArray;
    const failedCaptureNamesArray = i_updatedCaptureTableProcessingCapturesObjOrUndefined.failedCaptureNamesArray;

    const updatedCurrentlyProcessingCaptureNumber = (currentlyProcessingCaptureNumber + 1);

    //increment the current capture number
    i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber = updatedCurrentlyProcessingCaptureNumber;

    //if the current capture number exceeds that number to delete, break the recursion
    if(updatedCurrentlyProcessingCaptureNumber > totalNumCapturesToProcess) {
      const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_delete_all_captures_recursive_delete_next_capture", ["i_updatedCaptureTableProcessingCapturesObjOrUndefined", "i_captureIDsAndNamesToProcessArrayOfObjs"], [i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs]);

      //update the delete floating box to display success/failed deleted capture names
      i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureName = "--Finished Deleting Captures--";
      i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber = -1;
      this.a_set_capture_table_processing_captures_obj_or_undefined(i_updatedCaptureTableProcessingCapturesObjOrUndefined);

      //create an admin changelog entry
      if(successCaptureNamesArray.length > 0) {
        const C_CallPhpTblUIDAdminChangelog = new JSPHP.ClassCallPhpTblUID(jsDescription);
        const adminChangelogNewValueString = successCaptureNamesArray.join("\n");
        C_CallPhpTblUIDAdminChangelog.add_changelog_admin(101, adminChangelogNewValueString);
        C_CallPhpTblUIDAdminChangelog.execute();
      }

      //if there were any unsuccessful deletes, create a z_error message
      if(failedCaptureNamesArray.length > 0) {
        const deleteCapturesErrorMessage = "Failed to delete Captures from Capture Table:\n" + failedCaptureNamesArray.join("\n");
        JSPHP.record_z_error(jsDescription, deleteCapturesErrorMessage);
      }
    }
    else {
      //load by index which capture id and name to delete
      const captureIDAndNameToProcessObj = i_captureIDsAndNamesToProcessArrayOfObjs[(updatedCurrentlyProcessingCaptureNumber - 1)];
      const captureID = captureIDAndNameToProcessObj.id;
      const captureFullName = captureIDAndNameToProcessObj.captureFullName;

      //update the delete floating box that this current capture is being deleted
      i_updatedCaptureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureName = captureFullName;
      this.a_set_capture_table_processing_captures_obj_or_undefined(i_updatedCaptureTableProcessingCapturesObjOrUndefined);

      //delete the capture, on finish (success or error), recursively call this function to delete the next capture
      const functionOnSuccess = () => {
        i_updatedCaptureTableProcessingCapturesObjOrUndefined.successCaptureNamesArray.push(captureFullName);
        this.a_delete_all_captures_recursive_delete_next_capture(i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs);
      }
      const functionOnError = () => {
        i_updatedCaptureTableProcessingCapturesObjOrUndefined.failedCaptureNamesArray.push(captureFullName);
        this.a_delete_all_captures_recursive_delete_next_capture(i_updatedCaptureTableProcessingCapturesObjOrUndefined, i_captureIDsAndNamesToProcessArrayOfObjs);
      }
      JSPHP.delete_single_capture_with_capture_data_and_integration_opp_from_capture_id(captureID, functionOnSuccess, functionOnError);
    }
  }

  a_set_capture_table_processing_captures_obj_or_undefined(i_updatedCaptureTableProcessingCapturesObjOrUndefined) {
    this.o_captureTableProcessingCapturesObjOrUndefined = i_updatedCaptureTableProcessingCapturesObjOrUndefined;
  }




  //master preset editor
  a_set_capture_table_preset_editor_open_tf(i_newValueTF) {
    this.o_masterPresetEditorOpenTF = i_newValueTF;
  }

  a_set_preset_editor_open_preset_type_and_id(i_presetType, i_presetID) {
    this.o_presetEditorOpenPresetType = i_presetType;
    this.o_presetEditorOpenPresetID = i_presetID;

    //exit the create new preset mode if one of these items is clicked
    this.a_set_preset_editor_creating_new_preset_type(undefined);
  }

  a_set_preset_editor_creating_new_preset_type(i_createNewPresetType) {
    this.o_presetEditorCreatingNewPresetType = i_createNewPresetType;
  }

  a_remove_master_preset_from_quick_access(i_removedMasterPresetID) {
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_remove_master_preset_from_quick_access", ["i_removedMasterPresetID"], [i_removedMasterPresetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //remove the removed preset from the quick access bar
    const quickAccessMasterPresetIDsWithDeletedRemovedArray = JSFUNC.remove_all_values_from_array(i_removedMasterPresetID, this.c_quickAccessMasterPresetIDsArray);
    const quickAccessMasterPresetIDsWithDeletedRemovedComma = JSFUNC.convert_array_to_comma_list(quickAccessMasterPresetIDsWithDeletedRemovedArray);
    C_CallPhpTblUID.add_update("tbl_a_users", UserMobx.o_userID, "quick_access_master_preset_ids_comma", quickAccessMasterPresetIDsWithDeletedRemovedComma, "s");

    //if the one deleted was the one selected, select the first one of the remaining presets, if there are none left, set it to -2 which is the default "My Captures" master preset
    if(UserMobx.c_combinedUserObj.quick_access_master_preset_id_selected === i_removedMasterPresetID) {
      const newMasterPresetIDSelected = ((quickAccessMasterPresetIDsWithDeletedRemovedArray.length === 0) ? (-2) : (quickAccessMasterPresetIDsWithDeletedRemovedArray[0]));
      C_CallPhpTblUID.add_update("tbl_a_users", UserMobx.o_userID, "quick_access_master_preset_id_selected", newMasterPresetIDSelected, "i");
    }

    C_CallPhpTblUID.execute();
  }

  a_resort_selected_quick_access_master_presets(i_draggedMasterPresetID, i_droppedOnMasterPresetID) {
    //compute how all of the selected quick access master presets for this user are going to be resorted, if i_droppedOnMasterPresetID is -1 then the master preset was dropped onto the end blank dropzone
    const c_quickAccessMasterPresetIDsArray = this.c_quickAccessMasterPresetIDsArray;

    const resortAboveTrueBottomFalseNoneUndefined = JSFUNC.is_number_not_nan_gt_0(i_droppedOnMasterPresetID); //sort the dragged item above the dropped on item

    var idSortArrayOfObjs = [];
    var oldSortNum = 1;
    for(let masterPresetID of c_quickAccessMasterPresetIDsArray) {
      idSortArrayOfObjs.push({id:masterPresetID, sort:oldSortNum});
      oldSortNum++;
    }

    const resortArrayOfObjs = JSFUNC.drag_drop_resort_arrayOfObjs(idSortArrayOfObjs, "id", "sort", i_draggedMasterPresetID, i_droppedOnMasterPresetID, resortAboveTrueBottomFalseNoneUndefined);
    const resortIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(resortArrayOfObjs, "id");
    const quickAccessMasterPresetIDsCommaResorted = JSFUNC.convert_array_to_comma_list(resortIDsArray);

    //update the user record if the sort order has changed
    if(quickAccessMasterPresetIDsCommaResorted !== UserMobx.c_combinedUserObj.quick_access_master_preset_ids_comma) {
      const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_resort_selected_quick_access_master_presets", ["i_draggedMasterPresetID", "i_droppedOnMasterPresetID"], [i_draggedMasterPresetID, i_droppedOnMasterPresetID]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_users", UserMobx.o_userID, "quick_access_master_preset_ids_comma", quickAccessMasterPresetIDsCommaResorted, "s");
      C_CallPhpTblUID.execute();
    }
  }

  a_add_master_preset_to_quick_access(i_addedMasterPresetID) {
    const quickAccessMasterPresetIDsWithAddedPresetComma = JSFUNC.add_value_to_comma_list(i_addedMasterPresetID, UserMobx.c_combinedUserObj.quick_access_master_preset_ids_comma); //add the master preset to the quick access bar

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_add_master_preset_to_quick_access", ["i_addedMasterPresetID"], [i_addedMasterPresetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_users", UserMobx.o_userID, "quick_access_master_preset_ids_comma", quickAccessMasterPresetIDsWithAddedPresetComma, "s");
    C_CallPhpTblUID.execute();
  }

  a_create_new_master_columns_filter_sort_preset(i_createdPresetType, i_createdPresetName) {
    const presetTblName = this.preset_tbl_name_from_preset_type(i_createdPresetType);
    var fieldNamesArray = undefined;
    var valuesArray = undefined;
    var idsbArray = undefined;
    if(i_createdPresetType === "master") {
      fieldNamesArray = ["name", "description", "public_01", "user_id", "cst_column_preset_id", "filter_preset_id", "sort_preset_id"];
      valuesArray = [i_createdPresetName, "", 0, UserMobx.o_userID, -1, -1, -1];
      idsbArray = ["s", "s", "i", "i", "i", "i", "i"];
    }
    else { //cstColumns, filter, sort
      fieldNamesArray = ["name", "public_01", "user_id"];
      valuesArray = [i_createdPresetName, 0, UserMobx.o_userID];
      idsbArray = ["s", "i", "i"];
      if(i_createdPresetType === "filter" || i_createdPresetType === "sort") {
        fieldNamesArray.push("excel_report_writer_codeword");
        valuesArray.push(JSFUNC.db_name_from_display_name(i_createdPresetName));
        idsbArray.push("s");
      }
    }

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_create_new_master_columns_filter_sort_preset", ["i_createdPresetType", "i_createdPresetName"], [i_createdPresetType, i_createdPresetName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert(presetTblName, fieldNamesArray, valuesArray, idsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedPresetID = i_parseResponse.outputObj.i0;
      this.a_set_preset_editor_open_preset_type_and_id(i_createdPresetType, newlyInsertedPresetID);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_delete_master_columns_filter_sort_preset(i_deletedPresetType, i_deletedPresetID) {
    const isMasterTF = (i_deletedPresetType === "master");
    const presetTblName = this.preset_tbl_name_from_preset_type(i_deletedPresetType);
    const presetFieldItemsTblName = this.preset_field_items_tbl_name_from_preset_type(i_deletedPresetType);

    //delete the preset record
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_delete_master_columns_filter_sort_preset", ["i_deletedPresetType", "i_deletedPresetID"], [i_deletedPresetType, i_deletedPresetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //if the deleted preset type was cstColumns, filter, or sort, delete all of the capture field items that made up this preset
    if(presetFieldItemsTblName !== undefined) {
      const fieldItemsPresetIDColumnName = this.field_items_preset_id_column_name_from_preset_type(i_deletedPresetType);
      const presetFieldItemsTblRef = DatabaseMobx.tbl_ref_from_tbl_name(presetFieldItemsTblName);
      const presetCaptureFieldItemIDsArrayToDelete = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(presetFieldItemsTblRef, fieldItemsPresetIDColumnName, i_deletedPresetID, "id")
      C_CallPhpTblUID.add_delete(presetFieldItemsTblName, presetCaptureFieldItemIDsArrayToDelete); //every item within the presetID is deleted, no resort in needed after delete
    }

    //delete the preset record
    C_CallPhpTblUID.add_delete(presetTblName, i_deletedPresetID); //preset records in all 4 tbls are sorted alphabetically, no need to resort when deleted

    //if deleting a master preset
    if(isMasterTF) {
      //remove the deleted master preset from the quick access presets the user has designated
      const quickAccessMasterPresetIDsWithDeletedRemovedArray = JSFUNC.remove_all_values_from_array(i_deletedPresetID, this.c_quickAccessMasterPresetIDsArray);
      const quickAccessMasterPresetIDsWithDeletedRemovedComma = JSFUNC.convert_array_to_comma_list(quickAccessMasterPresetIDsWithDeletedRemovedArray);
      C_CallPhpTblUID.add_update("tbl_a_users", UserMobx.o_userID, "quick_access_master_preset_ids_comma", quickAccessMasterPresetIDsWithDeletedRemovedComma, "s");

      //if the one deleted was the one selected, select the first one of the remaining presets, if there are none left, set it to -2 which is the default "My Captures" master preset
      if(UserMobx.c_combinedUserObj.quick_access_master_preset_id_selected === i_deletedPresetID) {
        const newMasterPresetIDSelected = ((quickAccessMasterPresetIDsWithDeletedRemovedArray.length === 0) ? (-2) : (quickAccessMasterPresetIDsWithDeletedRemovedArray[0]));
        C_CallPhpTblUID.add_update("tbl_a_users", UserMobx.o_userID, "quick_access_master_preset_id_selected", newMasterPresetIDSelected, "i");
      }
    }

    C_CallPhpTblUID.execute();

    //if the preset being deleted is the one open in the editor on the right side, close it
    if(this.o_presetEditorOpenPresetID === i_deletedPresetID) {
      this.a_set_preset_editor_open_preset_type_and_id(undefined, undefined);
    }
  }

  a_update_preset_field(i_openPresetObj, i_fieldName, i_newValue, i_newIdsb) {
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_update_preset_field", ["i_openPresetObj", "i_fieldName", "i_newValue", "i_newIdsb"], [i_openPresetObj, i_fieldName, i_newValue, i_newIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(i_openPresetObj.presetTblName, i_openPresetObj.id, i_fieldName, i_newValue, i_newIdsb);
    C_CallPhpTblUID.execute();
  }

  a_cfs_preset_add_capture_field_items(i_presetType, i_cfsPresetID, i_addedCaptureFieldIDsComma, i_numFieldsAlreadyAdded, i_addNewColumnsFirst1Last2) {
    const fieldItemsTblName = this.preset_field_items_tbl_name_from_preset_type(i_presetType);
    const addedCaptureFieldIDsArray = JSFUNC.convert_comma_list_to_int_array(i_addedCaptureFieldIDsComma);
    const numAddedCaptureFields = addedCaptureFieldIDsArray.length;
    const addNewColumnsFirstTrueLastFalse = (i_addNewColumnsFirst1Last2 === 1);

    var fieldNamesArray = [];
    var valuesArrayOfArrays = [];
    var idsbArray = [];
    var resortSortColumnName = undefined;
    var resortFilterFieldNameOrFieldNamesArray = undefined;
    var resortFilterValueOrValuesArray = undefined;
    if(i_presetType === "cstColumns") {
      var newCstColumnSortNumsArray = [];
      var newCstColumnSortNum = 1;
      for(let s = 1; s <= numAddedCaptureFields; s++) {
        if(addNewColumnsFirstTrueLastFalse) { //if adding columns to top of list of columns, use -(N-1) to 0 so that they are above the existing list 1-N when resorting
          newCstColumnSortNum = (s - numAddedCaptureFields);
        }
        else { //if adding columns to bottom of list of columns, add the i_numFieldsAlreadyAdded to the sort number
          newCstColumnSortNum = (s + i_numFieldsAlreadyAdded);
        }
        newCstColumnSortNumsArray.push(newCstColumnSortNum);
      }

      fieldNamesArray = ["cst_column_preset_id", "capture_field_id", "width_em", "sort", "cst_sum_row_01"];
      valuesArrayOfArrays = [i_cfsPresetID, addedCaptureFieldIDsArray, 10, newCstColumnSortNumsArray, 0];
      idsbArray = ["i", "i", "i", "i", "i"];

      //only need resort if injecting the new columns at the beginning with negative sort numbers, otherwise the correct sorted numbers are calculated above for inserting columns at the end and resort is not needed
      if(addNewColumnsFirstTrueLastFalse) {
        resortSortColumnName = "sort";
        resortFilterFieldNameOrFieldNamesArray = ["cst_column_preset_id"];
        resortFilterValueOrValuesArray = [i_cfsPresetID];
      }
    }
    else if(i_presetType === "filter") {
      //build the array of the initial operator value selected depending on which field type the select field(s) are
      var newInitialOperatorValuesArray = [];
      for(let fieldID of addedCaptureFieldIDsArray) {
        var expandedCaptureFieldMap = DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(fieldID);
        var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");
        var filterPresetFieldOperatorSelectChoicesObj = this.filter_preset_field_operator_select_choices_obj_from_field_type_obj(fieldTypeObj);
        newInitialOperatorValuesArray.push(filterPresetFieldOperatorSelectChoicesObj.initialOperatorValue);
      }

      fieldNamesArray = ["filter_preset_id", "capture_field_id", "operator", "value"];
      valuesArrayOfArrays = [i_cfsPresetID, addedCaptureFieldIDsArray, newInitialOperatorValuesArray, ""];
      idsbArray = ["i", "i", "s", "s"];
    }
    else if(i_presetType === "sort") {
      var newSortSortNumsArray = [];
      for(let s = 1; s <= numAddedCaptureFields; s++) {
        newSortSortNumsArray.push(i_numFieldsAlreadyAdded + s); //start the counting for sort numbers at the number already added, then continue incrementing for all the new inserts
      }

      fieldNamesArray = ["sort_preset_id", "capture_field_id", "is_asc_01", "sort"];
      valuesArrayOfArrays = [i_cfsPresetID, addedCaptureFieldIDsArray, 1, newSortSortNumsArray];
      idsbArray = ["i", "i", "i", "i"];
    }

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_cfs_preset_add_capture_field_items", ["i_presetType", "i_cfsPresetID", "i_addedCaptureFieldIDsComma"], [i_presetType, i_cfsPresetID, i_addedCaptureFieldIDsComma]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_multi_insert(fieldItemsTblName, fieldNamesArray, valuesArrayOfArrays, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
    C_CallPhpTblUID.execute();
  }

  filter_preset_field_operator_select_choices_obj_from_field_type_obj(i_fieldTypeObj) {
    var operatorValuesArray = ["e", "ne", "gt", "gte", "lt", "lte", "c", "is", "ins"];
    var operatorValuesToNotIncludeArray = [];
    var initialOperatorValue = "e";
    if(i_fieldTypeObj.captureFavoritesTF) { //favorite
      operatorValuesArray = ["is", "ins"];
      operatorValuesToNotIncludeArray = ["e", "ne", "gt", "gte", "lt", "lte", "c"];
      initialOperatorValue = "e";
    }
    else if(i_fieldTypeObj.requiresSelectWithSearchDataObjTF) { //all types of select (select, contacts, sharedpercent)
      operatorValuesArray = ["e", "ne", "c", "is", "ins"];
      operatorValuesToNotIncludeArray = ["gt", "gte", "lt", "lte"];
      initialOperatorValue = "e";
    }
    else if(i_fieldTypeObj.valueDisplayIsNumericTF || i_fieldTypeObj.valueDisplayIsDateOrDateTimeTF) { //int, decimal, percent, money, date, relative date, datetime
      operatorValuesArray = ["e", "ne", "gt", "gte", "lt", "lte", "is", "ins"];
      operatorValuesToNotIncludeArray = ["c"];
      initialOperatorValue = "e";
    }
    else { //string fields like text, textarea, email, phone, website, color, next active task
      operatorValuesArray = ["e", "c", "is", "ins"];
      operatorValuesToNotIncludeArray = ["ne", "gt", "gte", "lt", "lte"];
      initialOperatorValue = "c";
    }

    return({
      operatorValuesArray: operatorValuesArray,
      operatorValuesToNotIncludeArray: operatorValuesToNotIncludeArray,
      initialOperatorValue: operatorValuesArray[0]
    });
  }

  a_update_cfs_field_item_field(i_presetType, i_fieldItemID, i_fieldName, i_newValue, i_newValueIdsb) {
    const presetFieldItemsTblName = this.preset_field_items_tbl_name_from_preset_type(i_presetType);
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_update_cfs_field_item_field", ["i_presetType", "i_fieldItemID", "i_fieldName", "i_newValue", "i_newValueIdsb"], [i_presetType, i_fieldItemID, i_fieldName, i_newValue, i_newValueIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(presetFieldItemsTblName, i_fieldItemID, i_fieldName, i_newValue, i_newValueIdsb);
    C_CallPhpTblUID.execute();
  }

  a_remove_cfs_field_item_from_preset(i_presetType, i_fieldItemID, i_presetItemsHaveSortTF, i_presetID) {
    const presetFieldItemsTblName = this.preset_field_items_tbl_name_from_preset_type(i_presetType);

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_remove_cfs_field_item_from_preset", ["i_presetType", "i_fieldItemID", "i_presetItemsHaveSortTF", "i_presetID"], [i_presetType, i_fieldItemID, i_presetItemsHaveSortTF, i_presetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //if the field items tbl has a sort column (cstColumns and sort have this), after deleting the item, do a reshuffle of the sort column to get the sort back to 1-N
    if(i_presetItemsHaveSortTF) {
      const itemsPresetIDColumnName = this.field_items_preset_id_column_name_from_preset_type(this.o_presetEditorOpenPresetType); //filter the items by presetID to get all items that share the same sort from 1-N
      C_CallPhpTblUID.add_delete(presetFieldItemsTblName, i_fieldItemID, "sort", itemsPresetIDColumnName, i_presetID);
    }
    else { //simple delete with no resort (filters has this)
      C_CallPhpTblUID.add_delete(presetFieldItemsTblName, i_fieldItemID); //filter tbl has no sort column, no need to resort (sorted alphabetically by field display name)
    }

    C_CallPhpTblUID.execute();
  }

  a_copy_preset_with_new_name(i_presetObjToCopy, i_newPresetName) {
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_copy_preset_with_new_name", ["i_presetObjToCopy", "i_newPresetName"], [i_presetObjToCopy, i_newPresetName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var fieldNamesArray = undefined;
    var valuesArray = undefined;
    var idsbArray = undefined;

    //copy preset record
    if(i_presetObjToCopy.isMasterTF) { //master preset
      fieldNamesArray = ["name", "description", "public_01", "user_id", "cst_column_preset_id", "filter_preset_id", "sort_preset_id"];
      valuesArray = [i_newPresetName, i_presetObjToCopy.description, 0, UserMobx.o_userID, i_presetObjToCopy.cst_column_preset_id, i_presetObjToCopy.filter_preset_id, i_presetObjToCopy.sort_preset_id];
      idsbArray = ["s", "s", "i", "i", "i", "i", "i"];
    }
    else if(i_presetObjToCopy.isFilterTF || i_presetObjToCopy.isSortTF) { //filter or sort presets
      fieldNamesArray = ["name", "public_01", "user_id", "excel_report_writer_codeword"];
      valuesArray = [i_newPresetName, 0, UserMobx.o_userID, JSFUNC.db_name_from_display_name(i_newPresetName)];
      idsbArray = ["s", "i", "i", "s"];
    }
    else { //cstColumns preset
      fieldNamesArray = ["name", "public_01", "user_id"];
      valuesArray = [i_newPresetName, 0, UserMobx.o_userID];
      idsbArray = ["s", "i", "i"];
    }
    C_CallPhpTblUID.add_insert(i_presetObjToCopy.presetTblName, fieldNamesArray, valuesArray, idsbArray);

    //switch to the newly copied preset in the editor, insert the preset item rows for CFS types
    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedPresetID = i_parseResponse.outputObj.i0;

      //for cstColumns, filter, sort, copy all of the items that make up the preset from the items tbl (once the preset insert comes back successful so that the newly inserted presetID can be used in the items)
      if(i_presetObjToCopy.isCstColumnsTF || i_presetObjToCopy.isFilterTF || i_presetObjToCopy.isSortTF) {
        var fieldNamesArray = [];
        var valuesArrayOfArrays = [];
        var idsbArray = [];
        if(i_presetObjToCopy.isCstColumnsTF) {
          fieldNamesArray = ["cst_column_preset_id", "capture_field_id", "width_em", "sort", "cst_sum_row_01"];
          valuesArrayOfArrays = [newlyInsertedPresetID, JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "capture_field_id"), JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "width_em"), JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "sort"), JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "cst_sum_row_01")];
          idsbArray = ["i", "i", "i", "i", "i"];
        }
        else if(i_presetObjToCopy.isFilterTF) {
          fieldNamesArray = ["filter_preset_id", "capture_field_id", "operator", "value"];
          valuesArrayOfArrays = [newlyInsertedPresetID, JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "capture_field_id"), JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "operator"), JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "value")];
          idsbArray = ["i", "i", "s", "s"];
        }
        else if(i_presetObjToCopy.isSortTF) {
          fieldNamesArray = ["sort_preset_id", "capture_field_id", "is_asc_01", "sort"];
          valuesArrayOfArrays = [newlyInsertedPresetID, JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "capture_field_id"), JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "is_asc_01"), JSFUNC.get_column_vector_from_arrayOfObjs(i_presetObjToCopy.presetItemsArrayOfObjs, "sort")];
          idsbArray = ["i", "i", "i", "i"];
        }

        const C_CallPhpTblUIDPresetItems = new JSPHP.ClassCallPhpTblUID(jsDescription);
        C_CallPhpTblUIDPresetItems.add_multi_insert(i_presetObjToCopy.presetItemsTblName, fieldNamesArray, valuesArrayOfArrays, idsbArray);
        C_CallPhpTblUIDPresetItems.execute();
      }

      //open the editor to this newly copied preset
      this.a_set_preset_editor_open_preset_type_and_id(i_presetObjToCopy.presetType, newlyInsertedPresetID);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  preset_tbl_name_from_preset_type(i_presetType) {
    if(i_presetType === "master") { return("tbl_f_capture_table_presets"); }
    else if(i_presetType === "cstColumns") { return("tbl_f_cst_column_presets"); }
    else if(i_presetType === "filter") { return("tbl_f_filter_presets"); }
    else if(i_presetType === "sort") { return("tbl_f_sort_presets"); }
    return(undefined);
  }

  preset_field_items_tbl_name_from_preset_type(i_presetType) {
    if(i_presetType === "master") { return(undefined); }
    else if(i_presetType === "cstColumns") { return("tbl_f_cst_columns"); }
    else if(i_presetType === "filter") { return("tbl_f_filters"); }
    else if(i_presetType === "sort") { return("tbl_f_sorts"); }
    return(undefined);
  }

  field_items_preset_id_column_name_from_preset_type(i_presetType) {
    if(i_presetType === "master") { return(undefined); }
    else if(i_presetType === "cstColumns") { return("cst_column_preset_id"); }
    else if(i_presetType === "filter") { return("filter_preset_id"); }
    else if(i_presetType === "sort") { return("sort_preset_id"); }
    return(undefined);
  }







  //gantt preset editor
  a_set_gantt_dates_preset_editor_open_tf(i_newValueTF) {
    //initialize the editor to show the preset currently being used when the editor is opened
    var ganttDatePresetIDSelected = UserMobx.c_combinedUserObj.gantt_date_preset_id_selected;
    if(!DatabaseMobx.o_tbl_f_gantt_date_presets.has(ganttDatePresetIDSelected)) { //if the selected gantt preset does not exist in the database, set the open preset to undefined to signal not having any preset currently open in the editor
      ganttDatePresetIDSelected = undefined;
    }
    this.a_set_gantt_dates_preset_editor_open_preset_id(ganttDatePresetIDSelected);

    //open the gantt preset editor floating box
    this.o_ganttDatesPresetEditorOpenTF = i_newValueTF;
  }

  a_set_gantt_dates_preset_editor_open_preset_id(i_openedGanttDatesPresetID) {
    this.o_ganttDatesOpenPresetID = i_openedGanttDatesPresetID;
  }

  a_select_gantt_dates_preset(i_selectedGanttDatesPresetID) {
    UserMobx.a_update_user_field("gantt_date_preset_id_selected", i_selectedGanttDatesPresetID, "i");
  }

  a_create_new_gantt_preset(i_newGanttPresetName) {
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_create_new_gantt_preset", ["i_newGanttPresetName"], [i_newGanttPresetName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["name", "public_01", "user_id"];
    const valuesArray = [i_newGanttPresetName, 0, UserMobx.o_userID];
    const idsbArray = ["s", "i", "i"];
    C_CallPhpTblUID.add_insert("tbl_f_gantt_date_presets", fieldNamesArray, valuesArray, idsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedGanttPresetID = i_parseResponse.outputObj.i0;
      this.a_set_gantt_dates_preset_editor_open_preset_id(newlyInsertedGanttPresetID);
      this.a_select_gantt_dates_preset(newlyInsertedGanttPresetID);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_delete_gantt_preset(i_ganttDatePresetID) {
    //delete the gantt preset record
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_delete_gantt_preset", ["i_ganttDatePresetID"], [i_ganttDatePresetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //delete all date field ids that made up this preset from tbl_f_gantt_dates in an array to remove them all
    const ganttDateFieldsTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_f_gantt_dates");
    const ganttDateCaptureFieldIDsArrayToDelete = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(ganttDateFieldsTblRef, "gantt_date_preset_id", i_ganttDatePresetID, "id");
    C_CallPhpTblUID.add_delete("tbl_f_gantt_dates", ganttDateCaptureFieldIDsArrayToDelete); //every date in the preset is deleted, no need to resort after delete

    //delete the gantt date preset
    C_CallPhpTblUID.add_delete("tbl_f_gantt_date_presets", i_ganttDatePresetID); //no sort column in presets (sorted by preset name), no need to resort after delete

    //if the one being deleted is the preset currently selected to use on the chart, clear it in the user table
    if(UserMobx.c_combinedUserObj.gantt_date_preset_id_selected === i_ganttDatePresetID) {
      C_CallPhpTblUID.add_update("tbl_a_users", UserMobx.o_userID, "gantt_date_preset_id_selected", -1, "i");
    }
    C_CallPhpTblUID.execute();

    //if the one being deleted is open in the editor, close it
    if(this.o_ganttDatesOpenPresetID === i_ganttDatePresetID) {
      this.a_set_gantt_dates_preset_editor_open_preset_id(undefined);
    }
  }

  a_update_gantt_preset_field(i_ganttDatePresetID, i_fieldName, i_newValue, i_newIdsb) {
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_update_gantt_preset_field", ["i_ganttDatePresetID", "i_fieldName", "i_newValue", "i_newIdsb"], [i_ganttDatePresetID, i_fieldName, i_newValue, i_newIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_f_gantt_date_presets", i_ganttDatePresetID, i_fieldName, i_newValue, i_newIdsb);
    C_CallPhpTblUID.execute();
  }

  a_add_dates_to_gantt_preset(i_ganttDatePresetID, i_newDateFieldIDsComma) {
    const newDateFieldIDsArray = JSFUNC.convert_comma_list_to_int_array(i_newDateFieldIDsComma);

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_add_dates_to_gantt_preset", ["i_ganttDatePresetID", "i_newDateFieldIDsComma"], [i_ganttDatePresetID, i_newDateFieldIDsComma]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    for(let dateFieldID of newDateFieldIDsArray) {
      var fieldNamesArray = ["gantt_date_preset_id", "capture_field_id", "color"];
      var valuesArray = [i_ganttDatePresetID, dateFieldID, "666666"];
      var idsbArray = ["i", "i", "s"];
      C_CallPhpTblUID.add_insert("tbl_f_gantt_dates", fieldNamesArray, valuesArray, idsbArray);
    }
    C_CallPhpTblUID.execute();
  }

  a_update_gantt_date_color(i_ganttDateID, i_newColorWithHash) {
    const newColor = JSFUNC.convert_any_hex_to_hex6(i_newColorWithHash);

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_update_gantt_date_color", ["i_ganttDateID", "i_newColorWithHash"], [i_ganttDateID, i_newColorWithHash]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_f_gantt_dates", i_ganttDateID, "color", newColor, "s");
    C_CallPhpTblUID.execute();
  }

  a_delete_gantt_date_from_preset(i_ganttDateID) {
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_delete_gantt_date_from_preset", ["i_ganttDateID"], [i_ganttDateID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_f_gantt_dates", i_ganttDateID); //no sort column for gantt dates (sorted by field display name), no need to resort after delete
    C_CallPhpTblUID.execute();
  }

  a_copy_gantt_dates_preset_with_new_name(i_ganttDatesPresetObjToCopy, i_newPresetName) {
    const o_userID = UserMobx.o_userID;

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_copy_gantt_dates_preset_with_new_name", ["i_ganttDatesPresetObjToCopy", "i_newPresetName"], [i_ganttDatesPresetObjToCopy, i_newPresetName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //copy preset record
    const ganttDatesPresetFieldNamesArray = ["name", "public_01", "user_id"];
    const ganttDatesPresetValuesArray = [i_newPresetName, 0, o_userID];
    const ganttDatesPresetIdsbArray = ["s", "i", "i"];
    C_CallPhpTblUID.add_insert("tbl_f_gantt_date_presets", ganttDatesPresetFieldNamesArray, ganttDatesPresetValuesArray, ganttDatesPresetIdsbArray);

    //switch to the newly copied preset in the editor, insert the preset item rows for CFS types
    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedGanttDatesPresetID = i_parseResponse.outputObj.i0;

      //copy the dates that make up the gantt preset
      const C_CallPhpTblUIDPresetItems = new JSPHP.ClassCallPhpTblUID(jsDescription);
      const ganttDatesFieldNamesArray = ["gantt_date_preset_id", "capture_field_id", "color"];
      const ganttDatesValuesArrayOfArrays = [newlyInsertedGanttDatesPresetID, JSFUNC.get_column_vector_from_arrayOfObjs(i_ganttDatesPresetObjToCopy.ganttDatesArrayOfObjs, "capture_field_id"), JSFUNC.get_column_vector_from_arrayOfObjs(i_ganttDatesPresetObjToCopy.ganttDatesArrayOfObjs, "color")];
      const ganttDatesIdsbArray = ["i", "i", "s"];
      C_CallPhpTblUIDPresetItems.add_multi_insert("tbl_f_gantt_dates", ganttDatesFieldNamesArray, ganttDatesValuesArrayOfArrays, ganttDatesIdsbArray);
      C_CallPhpTblUIDPresetItems.execute();

      //open the editor to this newly copied preset
      this.a_set_gantt_dates_preset_editor_open_preset_id(newlyInsertedGanttDatesPresetID);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }




  //gantt/progress timeline start/end date controls
  a_timeline_chart_update_start_end_dates(i_newStartDate, i_newEndDate) {
    var fieldNamesArray = [];
    var valuesArray = [];
    var isdbArray = [];
    if(i_newStartDate !== undefined) {
      fieldNamesArray.push("capture_chart_start_date");
      valuesArray.push(i_newStartDate);
      isdbArray.push("s");
    }

    if(i_newEndDate !== undefined) {
      fieldNamesArray.push("capture_chart_end_date");
      valuesArray.push(i_newEndDate);
      isdbArray.push("s");
    }

    //if the chart zoom cooldown is not already running, start the timer counting down a short time period where updating the start or end date by any method will update local memory, but not the database (prevents flooding database with updates incrementing days)
    var onlyExecuteLocalChangesTF = this.o_chartZoomCoolDownIsRunningTF;
    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_timeline_chart_update_start_end_dates", ["i_newStartDate", "i_newEndDate"], [i_newStartDate, i_newEndDate]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription, onlyExecuteLocalChangesTF);
    C_CallPhpTblUID.add_update("tbl_a_users_per_email", UserMobx.o_userPerEmailID, fieldNamesArray, valuesArray, isdbArray);
    C_CallPhpTblUID.execute();
  }

  a_timeline_set_zoom_cool_down_is_running_tf(i_newValueTF) {
    this.o_chartZoomCoolDownIsRunningTF = i_newValueTF;
  }

  a_timeline_zoom_in_or_out(i_inOutFlag) {
    const startDate = UserMobx.c_combinedUserObj.capture_chart_start_date;
    const endDate = UserMobx.c_combinedUserObj.capture_chart_end_date;
    if(JSFUNC.date_is_filled_out_tf(startDate) && JSFUNC.date_is_filled_out_tf(endDate)) {
      var startJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(startDate);
      var endJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(endDate);
      const totalNumDays = JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(startJsDateObj, endJsDateObj);
      if(i_inOutFlag === "in") {
        if(totalNumDays === 1) {
          endJsDateObj = startJsDateObj;
        }
        else {
          const quarterDistance = Math.round(totalNumDays / 4);
          startJsDateObj.setDate(startJsDateObj.getDate() + quarterDistance);
          endJsDateObj.setDate(endJsDateObj.getDate() - quarterDistance);
        }
      }
      else if(i_inOutFlag === "out") {
        if(totalNumDays <= 1) {
          startJsDateObj.setDate(startJsDateObj.getDate() - 1);
          endJsDateObj.setDate(endJsDateObj.getDate() + 1);
        }
        else {
          const halfDistance = Math.round(totalNumDays / 2);
          startJsDateObj.setDate(startJsDateObj.getDate() - halfDistance);
          endJsDateObj.setDate(endJsDateObj.getDate() + halfDistance);
        }
      }
      else if(i_inOutFlag === "left") {
        if(totalNumDays <= 1) {
          startJsDateObj.setDate(startJsDateObj.getDate() - 1);
          endJsDateObj.setDate(endJsDateObj.getDate() - 1);
        }
        else {
          const halfDistance = Math.ceil(totalNumDays / 3);
          startJsDateObj.setDate(startJsDateObj.getDate() - halfDistance);
          endJsDateObj.setDate(endJsDateObj.getDate() - halfDistance);
        }
      }
      else if(i_inOutFlag === "right") {
        if(totalNumDays <= 1) {
          startJsDateObj.setDate(startJsDateObj.getDate() + 1);
          endJsDateObj.setDate(endJsDateObj.getDate() + 1);
        }
        else {
          const halfDistance = Math.ceil(totalNumDays / 3);
          startJsDateObj.setDate(startJsDateObj.getDate() + halfDistance);
          endJsDateObj.setDate(endJsDateObj.getDate() + halfDistance);
        }
      }

      const newStartDate = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(startJsDateObj, false);
      const newEndDate = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(endJsDateObj, false);
      this.a_timeline_chart_update_start_end_dates(newStartDate, newEndDate);
    }
  }

  a_timeline_zoom_to_single_month(i_year, i_monthIndex0to11) {
    const monthNumber01to12 = JSFUNC.zero_pad_integer_from_left(i_monthIndex0to11 + 1, 2);
    const dateStartOfMonth = i_year + "-" + monthNumber01to12 + "-01";
    const startOfMonthJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(dateStartOfMonth);
    const endOfMonthJsDateObj = JSFUNC.jsdateobj_add_months(startOfMonthJsDateObj, 1);
    endOfMonthJsDateObj.setDate(endOfMonthJsDateObj.getDate() - 1);
    const dateEndOfMonth = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(endOfMonthJsDateObj, false);

    this.a_timeline_chart_update_start_end_dates(dateStartOfMonth, dateEndOfMonth);
  }







  //create new capture
  a_set_create_new_capture_is_open_tf(i_newValueTF) {
    this.o_createNewCaptureIsOpenTF = i_newValueTF;
  }

  a_set_create_new_capture_opportunity_name_lowercase(i_newValueString) {
    this.o_createNewCaptureOpportunityNameLowercase = i_newValueString;
  }

  a_set_create_new_capture_selected_documents_card_folders_preset_id(i_newValueInt) {
    this.o_createNewCaptureSelectedDocumentsCardFoldersPresetID = i_newValueInt;
  }

  a_create_new_capture_from_new_capture_process(i_newCaptureObj, i_selectedDocumentsCardFoldersPresetID) { //insert new capture record, make changelog entries for stage, pwin, progress, details (added_date is today)
    const k_cardIDUserCreateNewCapture = DatabaseMobx.k_cardIDUserCreateNewCapture;

    const issueInsertingCaptureErrorMessage = "There was an issue inserting a record and a new Capture was not created.\n\nThis is usually caused when your Admin deletes a Capture field from the system and synchronization failed. Please log out and log back in and try to create this Capture again which should work properly.";

    const jsDescription = JSFUNC.js_description_from_action("CapturesMobx", "a_create_new_capture_from_new_capture_process", ["i_newCaptureObj"], [i_newCaptureObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //integrate the user selected values for the new capture with the system default/blank values for each field not specified
    const newCaptureFieldAndLogArraysObj = DatabaseMobx.create_new_capture_field_names_values_idsb_and_log_arrays_obj_from_new_capture_obj(i_newCaptureObj);
    const fieldIDsArray = newCaptureFieldAndLogArraysObj.fieldIDsArray; //all capture fields, whether filled out, blank value, or a default value assigned from capture type ('changelog' arrays in this obj are only fields that are filled out)
    const fieldDbNamesArray = newCaptureFieldAndLogArraysObj.fieldDbNamesArray;
    const rawValuesArray = newCaptureFieldAndLogArraysObj.rawValuesArray;
    const idsbArray = newCaptureFieldAndLogArraysObj.idsbArray;
    const newCaptureIDIQCaptureIDTOLink = newCaptureFieldAndLogArraysObj.newCaptureIDIQCaptureIDTOLink;
    const newCaptureContractTypeIsTaskOrderTF = newCaptureFieldAndLogArraysObj.newCaptureContractTypeIsTaskOrderTF;
    const newCaptureIDIQLinkContractTypeIsIDIQTF = newCaptureFieldAndLogArraysObj.newCaptureIDIQLinkContractTypeIsIDIQTF;

    //insert the new single capture record with all entered, default, and calculated field values
    C_CallPhpTblUID.add_insert("tbl_captures", fieldDbNamesArray, rawValuesArray, idsbArray);

    //when the capture record is successfully inserted, use the new captureID to open that capture for the user
    const functionOnSuccess = (i_parseResponse) => {
      //get the newly inserted captureID from the php insert response stored in "i0" (this capture insert operation result)
      const newCaptureID = i_parseResponse.outputObj.i0;

      if(!JSFUNC.is_number_not_nan_gt_0(newCaptureID)) {
        alert(issueInsertingCaptureErrorMessage);
      }
      else {
        //close the create new capture floating box
        this.a_set_create_new_capture_is_open_tf(false);

        //local memory update the capture row with the newly inserted captureID from the database (currently local record does not have an id field at all)
        DatabaseMobx.a_create_new_capture_inject_id_field_into_new_local_capture_record(newCaptureID);

        //insert changelog entries into all 4 changelogs for gantt/progress chart initialization values on the added_date, also send user notifications for being a capture manager, and add teammates if a Task Order linked to an IDIQ
        const C_CallPhpTblUIDChangelogs = new JSPHP.ClassCallPhpTblUID(jsDescription);

        //add all initial changelog entries
        C_CallPhpTblUIDChangelogs.add_all_new_capture_changelogs_from_new_capture_field_and_log_arrays_obj(newCaptureFieldAndLogArraysObj, newCaptureID, k_cardIDUserCreateNewCapture, "Create New Capture");

        //-----------------------------------------------------------------------------------------------------
        //add notifications/emails to each capture manager unique userPerEmailID that is not yourself
        var newCaptureCaptureMangersExcludingLoggedInUserUserIDsArray = [];
        const captureManagersUserIDsPercentsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(i_newCaptureObj.capture_managers_ids_colon_percent_comma, "userID", "percent0to100");
        for(let captureManagerUserIDPercentObj of captureManagersUserIDsPercentsArrayOfObjs) {
          if(captureManagerUserIDPercentObj.userID !== UserMobx.o_userID) {
            newCaptureCaptureMangersExcludingLoggedInUserUserIDsArray.push(captureManagerUserIDPercentObj.userID);
          }
        }

        if(newCaptureCaptureMangersExcludingLoggedInUserUserIDsArray.length > 0) {
          const userPerEmailTrueUserFalse = false; //added capture managers use userIDs (not userPerEmailIDs)
          const notificationMessage = "You have been added to the newly created Capture '" + DatabaseMobx.capture_name_plaintext_from_capture_id(newCaptureID) + "', created by " + UserMobx.c_userName;
          const notificationClickActionString = "openCapture:" + newCaptureID;
          C_CallPhpTblUIDChangelogs.add_notifications(newCaptureCaptureMangersExcludingLoggedInUserUserIDsArray, userPerEmailTrueUserFalse, notificationMessage, notificationClickActionString);
        }
        //-----------------------------------------------------------------------------------------------------

        //-----------------------------------------------------------------------------------------------------
        //if the capture is a Task Order contract type, then copy all of the teammates and surveys/questions from the linked IDIQ capture, then open the capture
        var callOpenCaptureAfterThisExecute = true;
        if(newCaptureContractTypeIsTaskOrderTF && newCaptureIDIQLinkContractTypeIsIDIQTF) {
          callOpenCaptureAfterThisExecute = false; //call open capture inside the onFinish of copying the surveys

          //copy all teammate records from IDIQ capture to the new capture
          const tblCTeammatesFieldDbNamesAndIdsbObj = DatabaseMobx.get_tbl_info_field_db_names_array_and_idsb_array_from_tbl_name("tbl_c_teammates", "id");
          var teammateFieldNamesArray = tblCTeammatesFieldDbNamesAndIdsbObj.fieldDbNamesArray;
          var teammateIdsbArray = tblCTeammatesFieldDbNamesAndIdsbObj.idsbArray;
          for(let teammateMap of DatabaseMobx.o_tbl_c_teammates.values()) {
            if(teammateMap.get("capture_id") === newCaptureIDIQCaptureIDTOLink) {
              var teammateValuesArray = [];
              for(let teammateFieldName of teammateFieldNamesArray) {
                if(teammateFieldName === "capture_id") { //overwrite copied captureID with new task order captureID
                  teammateValuesArray.push(newCaptureID);
                }
                else {
                  teammateValuesArray.push(teammateMap.get(teammateFieldName));
                }
              }
              C_CallPhpTblUIDChangelogs.add_insert("tbl_c_teammates", teammateFieldNamesArray, teammateValuesArray, teammateIdsbArray);
            }
          }

          //copy all created surveys and their questions
          const C_CallPhpScriptCopySurveys = new JSPHP.ClassCallPhpScript("copyAllTeammatesSurveysAndQuestionsFromCaptureIDToCaptureID", jsDescription);
          C_CallPhpScriptCopySurveys.add_post_var("i_fromCaptureID", newCaptureIDIQCaptureIDTOLink);
          C_CallPhpScriptCopySurveys.add_post_var("i_toCaptureID", newCaptureID);
          C_CallPhpScriptCopySurveys.add_return_vars("success01String");
          const functionOnFinishCopySurveys = () => {
            OpenCaptureMobx.a_open_single_capture(newCaptureID); //delayed opening of this new capture to its card snapshots page
          }
          C_CallPhpScriptCopySurveys.add_function("onFinish", functionOnFinishCopySurveys);
          C_CallPhpScriptCopySurveys.execute();
        }
        //-----------------------------------------------------------------------------------------------------

        //-----------------------------------------------------------------------------------------------------
        //if the 3rd Party Integration is turned on in BIT and the Admin, need to create a unique integration ID for the insert into their system (which uses the new CE Capture), then update that value in CE (blank when new capture is created), then create a replica of the capture in the integration system
        AdminIntegrationsMobx.insert_integration_new_opp_with_newly_created_unique_id_string_from_new_ce_capture_id(newCaptureID, fieldIDsArray, rawValuesArray);
        //-----------------------------------------------------------------------------------------------------

        C_CallPhpTblUIDChangelogs.execute();

        if(callOpenCaptureAfterThisExecute) {
          OpenCaptureMobx.a_open_single_capture(newCaptureID); //open this new capture to its card snapshots page
        }
        
        //if a Documents Card folders Shortcut Preset has been selected, fill the Documents Card of this new Capture with empty folders (in parallel php call, no functionOnFinish since this is a single capture)
        if(JSFUNC.select_int_is_filled_out_tf(i_selectedDocumentsCardFoldersPresetID)) {
          DatabaseMobx.insert_all_empty_folders_from_documents_card_shortcut_preset_id_into_capture_from_capture_id(i_selectedDocumentsCardFoldersPresetID, newCaptureID);
        }
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    
    const functionOnError = () => {
      alert(issueInsertingCaptureErrorMessage);
    }
    C_CallPhpTblUID.add_function("onError", functionOnError);

    C_CallPhpTblUID.execute();
  }





  //================================================================================================================================================================================
  //================================================================================================================================================================================
  //regular methods
  my_active_captures_capture_table_preset_obj() {
    return({id:-2, name:"My Captures", description:"", public_01:-2, user_id:-2, cst_column_preset_id:-2, filter_preset_id:-2, sort_preset_id:-2});
  }

  my_active_captures_cst_columns_arrayOfObjs() {
    const fieldIDAndWidthsArrayOfObjs = [
      {fieldID: DatabaseMobx.c_fieldMapOfCaptureID.get("id"), width: 6},
      {fieldID: DatabaseMobx.c_fieldMapOfOpportunityName.get("id"), width: 17},
      {fieldID: DatabaseMobx.c_fieldMapOfCodename.get("id"), width: 12},
      {fieldID: DatabaseMobx.c_fieldMapOfTotalShapingProgress.get("id"), width: 8},
      {fieldID: DatabaseMobx.c_fieldMapOfPwin.get("id"), width: 6},
      {fieldID: DatabaseMobx.c_fieldMapOfStage.get("id"), width: 9},
      {fieldID: DatabaseMobx.c_fieldMapOfContractOverallValue.get("id"), width: 9},
      {fieldID: DatabaseMobx.c_fieldMapOfCaptureType.get("id"), width: 9},
      {fieldID: DatabaseMobx.c_fieldMapOfPrimeSub.get("id"), width: 6},
      {fieldID: DatabaseMobx.c_fieldMapOfContractType.get("id"), width: 9},
      {fieldID: DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink.get("id"), width: 9},
      {fieldID: DatabaseMobx.c_fieldMapOfPeriodOfPerformance.get("id"), width: 6},
      {fieldID: DatabaseMobx.c_fieldMapOfRFPDate.get("id"), width: 7},
      {fieldID: DatabaseMobx.c_fieldMapOfCaptureManagers.get("id"), width: 12},
      {fieldID: DatabaseMobx.c_fieldMapOfDivisionOwners.get("id"), width: 12},
      {fieldID: DatabaseMobx.c_fieldMapOfAddedDate.get("id"), width: 7}
    ];

    var cstColumnsArrayOfObjs = [];
    for(let f = 0; f < fieldIDAndWidthsArrayOfObjs.length; f++) {
      cstColumnsArrayOfObjs.push({
        id: ((f * -1) - 2), //fake cstColumnIDs count from -2,-3,-4,-5...
        cst_column_preset_id: -2,
        capture_field_id: fieldIDAndWidthsArrayOfObjs[f].fieldID,
        width_em: fieldIDAndWidthsArrayOfObjs[f].width,
        sort: (f + 1), //cstColumn sort counts 1-N
        cst_sum_row_01: 0
      });
    }
    return(cstColumnsArrayOfObjs);
  }

  my_active_captures_cst_column_preset_obj() {
    return({id:-2, name:"CaptureExec Default Columns", public_01:-2, user_id:-2});
  }

  my_active_captures_filters_arrayOfObjs() {
    const captureManagersFieldID = DatabaseMobx.c_fieldMapOfCaptureManagers.get("id");
    return([
      {id:-2, filter_preset_id:-2, capture_field_id:captureManagersFieldID, operator:"e", value:JSFUNC.num2str(UserMobx.o_userID)}
    ]);
  }

  my_active_captures_filter_preset_obj() {
    return({id:-2, name:"My Captures", public_01:-2, user_id:-2});
  }

  my_active_captures_sorts_arrayOfObjs() { //sort by stage ASC and captureID DESC (new captures closest to top)
    const stageFieldID = DatabaseMobx.c_fieldMapOfStage.get("id");
    const captureIDFieldID = DatabaseMobx.c_fieldMapOfCaptureID.get("id");


    return([
      {id:-2, sort_preset_id:1, capture_field_id:stageFieldID, is_asc_01:1, sort:1},
      {id:-3, sort_preset_id:1, capture_field_id:captureIDFieldID, is_asc_01:0, sort:2}
    ]);
  }

  my_active_captures_sort_preset_obj() {
    return({id:-2, name:"Sort Stage, Capture ID", public_01:-2, user_id:-2});
  }

}
export default new CapturesMobx();

import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import AdminIntegrationsMobx from '../AdminIntegrations/AdminIntegrationsMobx.js';
import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';

class GCSSMobx {
  //========================================================================================
  //observable values
  o_gcssTabTblsHaveBeenLoadedIntoLocalMemoryTF = false;
  o_autoSearchPanelIsExpandedTF = true;
  o_manualSearchIsOpenTF = false;
  o_manualSearchLocalSelectedFilterValuesMap = new Map();
  o_selectedAutoSearchIDOrUndefined = undefined;
  o_selectedAutoSearchHasUnsavedChangesTF = false;
  o_searchResultsLoadingFlag = "init"; //"init", "loading", "loaded", "error"
  o_searchResultsErrorMessage = "";
  o_totalNumResultsMatchingSearch = 0;
  o_searchResultsArrayOfObjs = [];
  o_currentPageNumber = 1;
  o_selectedSearchResultsTabDbName = "all"; //"all", "undecided", "markedForImport", "markedAsNotInterested", "alreadyInCE"
  o_focusedTwsSearchResultGcssIDOrUndefined = undefined;
  o_openSearchResultDetailGcssIDOrUndefined = undefined; //either int (SAM id column from tbl_gcss_ops) or string (direct from GovWin ID in "FBO3348551" format)
  o_openSearchResultDetailOpTblDataRowsAreLoadingTF = false;
  o_openSearchResultDetailErrorMessage = "";
  o_openSearchResultDetailOpTblDataRowsArrayOfObjs = [];
  o_gcssSetupImportCaptureManagerIDsColonPercentsComma = "";
  o_gcssSetupImportDivisionOwnerIDsColonPercentsComma = "";
  o_gcssSetupImportCaptureTypeID = -1;
  o_gcssSetupImportStageID = -1;
  o_gcssSetupImportOverwriteDataWithUpdates01 = 1;
  o_gcssImportSearchResultsToImportGcssIDsArray = [];
  o_gcssImportCurrentImportCaptureIndex = -1;
  o_gcssImportCurrentImportCaptureName = "";
  o_gcssImportTotalNumCapturesToImport = -1;
  o_gcssImportSuccessImportGcssIDsArray = [];
  o_gcssImportSuccessImportCaptureIDsAndNamesArray = [];
  o_gcssImportErrorImportCaptureNamesArray = [];

  constructor() {
    makeObservable(this, {
      o_gcssTabTblsHaveBeenLoadedIntoLocalMemoryTF: observable,
      o_autoSearchPanelIsExpandedTF: observable,
      o_manualSearchIsOpenTF: observable,
      o_manualSearchLocalSelectedFilterValuesMap: observable,
      o_selectedAutoSearchIDOrUndefined: observable,
      o_selectedAutoSearchHasUnsavedChangesTF: observable,
      o_searchResultsLoadingFlag: observable,
      o_searchResultsErrorMessage: observable,
      o_totalNumResultsMatchingSearch: observable,
      o_searchResultsArrayOfObjs: observable,
      o_currentPageNumber: observable,
      o_selectedSearchResultsTabDbName: observable,
      o_focusedTwsSearchResultGcssIDOrUndefined: observable,
      o_openSearchResultDetailGcssIDOrUndefined: observable,
      o_openSearchResultDetailOpTblDataRowsAreLoadingTF: observable,
      o_openSearchResultDetailErrorMessage: observable,
      o_openSearchResultDetailOpTblDataRowsArrayOfObjs: observable,
      o_gcssSetupImportCaptureManagerIDsColonPercentsComma: observable,
      o_gcssSetupImportDivisionOwnerIDsColonPercentsComma: observable,
      o_gcssSetupImportCaptureTypeID: observable,
      o_gcssSetupImportStageID: observable,
      o_gcssSetupImportOverwriteDataWithUpdates01: observable,
      o_gcssImportSearchResultsToImportGcssIDsArray: observable,
      o_gcssImportCurrentImportCaptureIndex: observable,
      o_gcssImportCurrentImportCaptureName: observable,
      o_gcssImportTotalNumCapturesToImport: observable,
      o_gcssImportSuccessImportGcssIDsArray: observable,
      o_gcssImportSuccessImportCaptureIDsAndNamesArray: observable,
      o_gcssImportErrorImportCaptureNamesArray: observable,

      c_gcssDataSourceColorsObj: computed,
      c_gcssTblCapturesGcssIDColumnDbName: computed,
      c_gcssTblGGcssNotInterestedGcssIDColumnDbName: computed,
      c_upeGcssIDsMarkedForImportArray: computed,
      c_upeGcssMarkedForImportColumnDbName: computed,
      c_gcssAlreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray: computed,
      c_gcssAlreadyInCEImportedCapturesGcssIDFieldName: computed,
      c_manualSearchNumFiltersBoxesPerRow: computed,
      c_manualSearchBoxesOfBubblesOfFiltersArrayOfObjs: computed,
      c_autoSearchImportDefaultFieldsObjOfObjsByAutoSearchDbName: computed,
      c_searchFieldsWithValuesObj: computed,
      c_allSearchFiltersWithValuesArrayOfObjs: computed,
      c_searchImportDefaultSelectedCaptureTypeIsFilledOutTF: computed,
      c_searchImportDefaultStagesInSelectedCaptureTypeFieldTypeObj: computed,
      c_createNewSearchFilledOutFiltersWithValuesHoverTextBlock: computed,
      c_autoSearchesArrayOfObjs: computed,
      c_selectedAutoSearchObjOrUndefined: computed,
      c_numSearchResultsPerPage: computed,
      c_totalNumFilteredSearchResultsFromSelectedTab: computed,
      c_currentPageFirstItemNumber: computed,
      c_currentPageLastItemNumber: computed,
      c_canIncrementCurrentPageNumberTF: computed,
      c_canDecrementCurrentPageNumberTF: computed,
      c_searchResultSortSelectGcssFieldFieldTypeObj: computed,
      c_allLoadedExpandedSearchResultsArrayOfObjs: computed,
      c_allLoadedSortedExpandedSearchResultsArrayOfObjs: computed,
      c_undecidedExpandedSearchResultsArrayOfObjs: computed,
      c_markedForImportExpandedSearchResultsArrayOfObjs: computed,
      c_markedAsNotInterestedExpandedSearchResultsArrayOfObjs: computed,
      c_alreadyInCEExpandedSearchResultsArrayOfObjs: computed,
      c_selectedTabExpandedSearchResultsArrayOfObjs: computed,
      c_currentPageOfExpandedSearchResultsArrayOfObjs: computed,
      c_searchResultItemWidthFlag: computed,
      c_openExpandedDetailSearchResultObjOrUndefined: computed,

      a_load_gcss_tab_tbls_to_local_memory_if_not_yet_loaded: action,
      a_set_auto_search_panel_is_expanded_tf: action,
      a_open_gcss_panel: action,
      a_close_gcss_search_panel: action,
      a_close_any_open_gcss_panel: action,
      a_clear_previous_search_results_to_init_state: action,
      a_set_manual_search_local_selected_filter_value: action,
      a_fetch_gcss_tbl_search_results_from_current_filter_settings: action,
      a_manual_search_clear_all_filter_values: action,
      a_create_new_auto_search_or_save_existing_auto_search: action,
      a_set_selected_auto_search_id_or_undefined: action,
      a_set_selected_auto_search_has_unsaved_changes_tf: action,
      a_edit_auto_search_from_id: action,
      a_rename_auto_search_from_id_old_name_and_updated_name: action,
      a_delete_auto_search_from_id: action,
      a_fetch_gcss_tbl_search_results_from_search_fields_with_values_obj: action,
      a_set_search_results_loading_flag: action,
      a_set_search_results_error_message: action,
      a_set_total_num_results_matching_search: action,
      a_set_search_results_arrayOfObjs: action,
      a_set_current_page_number: action,
      a_increment_current_page_number: action,
      a_decrement_current_page_number: action,
      a_mark_search_result_from_expanded_search_result_obj: action,
      a_update_gcss_not_interested_field: action,
      a_merge_gcss_search_result_data_to_existing_capture_in_captureexec: action,
      a_set_selected_search_results_tab_db_name: action,
      a_set_focused_tws_search_result_gcss_id_or_undefined: action,
      a_set_open_search_result_detail_gcss_id_or_undefined: action,
      a_set_open_search_result_detail_op_tbl_data_rows_are_loading_tf: action,
      a_set_open_search_result_detail_error_message: action,
      a_set_open_search_result_detail_op_tbl_data_rows_arrayOfObjs: action,
      a_set_gcss_setup_import_capture_manager_ids_colon_percents_comma: action,
      a_set_gcss_setup_import_division_owner_ids_colon_percents_comma: action,
      a_set_gcss_setup_import_capture_type_id: action,
      a_set_gcss_setup_import_stage_id: action,
      a_set_gcss_setup_import_overwrite_data_with_updates_01: action,
      a_set_gcss_import_search_results_to_import_gcss_ids_array: action,
      a_set_gcss_import_current_import_capture_index: action,
      a_set_gcss_import_current_import_capture_name: action,
      a_set_gcss_import_total_num_captures_to_import: action,
      a_reset_gcss_import_success_import_capture_ids_and_names_array: action,
      a_append_to_gcss_import_success_import_capture_ids_and_names_array: action,
      a_reset_gcss_import_error_import_capture_names_array: action,
      a_append_to_gcss_import_error_import_capture_names_array: action,
      a_gcss_import_marked_for_import_results_into_captureexec: action,
      a_gcss_import_recursive_increment_to_next_index_and_import_single_capture: action,
      a_gcss_insert_z_gcss_searches_log_entry: action
    });
  }


  //========================================================================================
  //computed values
  get c_gcssDataSourceColorsObj() {
    const c_userGcssDataSource0SamTF = UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = UserMobx.c_userGcssDataSource2FedCompTF;

    var gcssBgLightGradientClass = "bgGrayGradient";
    var gcssHoverLighterGradient = "hoverLightestGrayGradient";
    var gcssBgDarkGradientClass = "bgDarkGrayGradient";
    var fontLightClass = "fontTextLightester";
    if(c_userGcssDataSource0SamTF) { //SAM.gov colors
      gcssBgLightGradientClass = "bgGcssSamLightGradient";
      gcssHoverLighterGradient = "hoverGcssSamLighterGradient";
      gcssBgDarkGradientClass = "bgGcssSamDarkGradient";
      fontLightClass = "fontGcssSam";
    }
    else if(c_userGcssDataSource1GovWinTF) { //GovWin colors
      gcssBgLightGradientClass = "bgGcssGovWinLightGradient";
      gcssHoverLighterGradient = "hoverGcssGovWinLighterGradient";
      gcssBgDarkGradientClass = "bgGcssGovWinDarkGradient";
      fontLightClass = "fontGcssGovWin";
    }
    else if(c_userGcssDataSource2FedCompTF) { //Federal Compass colors
      gcssBgLightGradientClass = "bgGcssFedCompLightGradient";
      gcssHoverLighterGradient = "hoverGcssFedCompLighterGradient";
      gcssBgDarkGradientClass = "bgGcssFedCompDarkGradient";
      fontLightClass = "fontGcssFedComp";
    }

    return({
      gcssBgLightGradientClass: gcssBgLightGradientClass,
      gcssHoverLighterGradientClass: gcssHoverLighterGradient,
      gcssBgLightGradientWithHoverClass: gcssBgLightGradientClass + " " + gcssHoverLighterGradient,
      gcssBgDarkGradientClass: gcssBgDarkGradientClass,
      fontLightClass: fontLightClass
    });
  }

  get c_gcssTblCapturesGcssIDColumnDbName() {
    if(UserMobx.c_userGcssDataSource0SamTF) { return("gcss_id"); }
    else if(UserMobx.c_userGcssDataSource1GovWinTF) { return("gcss_govwin_id_string"); }
    else if(UserMobx.c_userGcssDataSource2FedCompTF) { return("gcss_fedcomp_id_string"); }
    return("");
  }

  get c_gcssTblGGcssNotInterestedGcssIDColumnDbName() {
    if(UserMobx.c_userGcssDataSource0SamTF) { return("gcss_id"); }
    else if(UserMobx.c_userGcssDataSource1GovWinTF) { return("gcss_govwin_id_string"); }
    else if(UserMobx.c_userGcssDataSource2FedCompTF) { return("gcss_fedcomp_id_string"); }
    return("");
  }

  get c_upeGcssIDsMarkedForImportArray() {
    if(UserMobx.c_userGcssDataSource0SamTF) { return(UserMobx.c_userGcssSamGcssIDsMarkedForImportArray); }
    else if(UserMobx.c_userGcssDataSource1GovWinTF) { return(UserMobx.c_userGcssGovWinIDsMarkedForImportArray); }
    else if(UserMobx.c_userGcssDataSource2FedCompTF) { return(UserMobx.c_userGcssFedCompIDsMarkedForImportArray); }
    return([]);
  }

  get c_upeGcssMarkedForImportColumnDbName() {
    if(UserMobx.c_userGcssDataSource0SamTF) { return("gcss_ids_marked_for_import_comma"); }
    else if(UserMobx.c_userGcssDataSource1GovWinTF) { return("gcss_govwin_ids_marked_for_import_comma"); }
    else if(UserMobx.c_userGcssDataSource2FedCompTF) { return("gcss_fedcomp_ids_marked_for_import_comma"); }
    return("");
  }

  get c_gcssAlreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray() {
    const c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs = DatabaseMobx.c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs;
    const c_userGcssDataSource0SamTF = UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = UserMobx.c_userGcssDataSource2FedCompTF;

    var alreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray = [];
    for(let alreadyInCEImportedCaptureObj of c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs) {
      if(c_userGcssDataSource0SamTF && JSFUNC.select_int_is_filled_out_tf(alreadyInCEImportedCaptureObj.samGcssID)) {
        alreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray.push(alreadyInCEImportedCaptureObj.samGcssID);
      }
      else if(c_userGcssDataSource1GovWinTF && JSFUNC.string_is_filled_out_tf(alreadyInCEImportedCaptureObj.gcssGovWinIDString)) {
        alreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray.push(alreadyInCEImportedCaptureObj.gcssGovWinIDString);
      }
      else if(c_userGcssDataSource2FedCompTF && JSFUNC.string_is_filled_out_tf(alreadyInCEImportedCaptureObj.gcssFedCompIDString)) {
        alreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray.push(alreadyInCEImportedCaptureObj.gcssFedCompIDString);
      }
    }
    return(alreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray);
  }

  get c_gcssAlreadyInCEImportedCapturesGcssIDFieldName() {
    if(UserMobx.c_userGcssDataSource0SamTF) { return("samGcssID"); }
    else if(UserMobx.c_userGcssDataSource1GovWinTF) { return("gcssGovWinIDString"); }
    else if(UserMobx.c_userGcssDataSource2FedCompTF) { return("gcssFedCompIDString"); }
    return("");
  }





  get c_manualSearchNumFiltersBoxesPerRow() {
    const o_mediaQueryFlag = CaptureExecMobx.o_mediaQueryFlag;

    if(o_mediaQueryFlag === 1) { return(1); }
    else if(o_mediaQueryFlag === 2) { return(1); }
    else if(o_mediaQueryFlag === 3) { return(2); }
    else if(o_mediaQueryFlag === 4) { return(2); }
    else if(o_mediaQueryFlag === 5) { return(3); }
    return(4);
  }


  //hardcoded nested structure of search filters within bubbles within boxes (no selected filter values integrated in this variable, computed instead of k constant because of DatabaseMobx fieldTypeObjs)
  get c_manualSearchBoxesOfBubblesOfFiltersArrayOfObjs() {
    const c_userGcssDataSource0SamTF = UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = UserMobx.c_userGcssDataSource2FedCompTF;

    const proposalDueDateMinFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc.get("display_name") + " min",
      isMinDateTF: true,
      autoSearchDbName: "filter_proposal_due_date_min",
      searchPostVarName: "i_filterProposalDueDateMin",
      fieldTypeObj: DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj
    };

    const proposalDueDateMaxFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc.get("display_name") + " max",
      isMaxDateTF: true,
      autoSearchDbName: "filter_proposal_due_date_max",
      searchPostVarName: "i_filterProposalDueDateMax",
      fieldTypeObj: DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj
    };

    const lastUpdatedDateMinFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssLastUpdatedDateTimeUtc.get("display_name") + " min",
      isMinDateTF: true,
      autoSearchDbName: "filter_last_updated_date_min",
      searchPostVarName: "i_filterLastUpdatedDateMin",
      fieldTypeObj: DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj
    };

    const lastUpdatedDateMaxFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssLastUpdatedDateTimeUtc.get("display_name") + " max",
      isMaxDateTF: true,
      autoSearchDbName: "filter_last_updated_date_max",
      searchPostVarName: "i_filterLastUpdatedDateMax",
      fieldTypeObj: DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj
    };

    const originalPublishedDateMinFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssOriginalPublishedDateTimeUtc.get("display_name") + " min",
      isMinDateTF: true,
      autoSearchDbName: "filter_original_published_date_min",
      searchPostVarName: "i_filterOriginalPublishedDateMin",
      fieldTypeObj: DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj
    };

    const originalPublishedDateMaxFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssOriginalPublishedDateTimeUtc.get("display_name") + " max",
      isMaxDateTF: true,
      autoSearchDbName: "filter_original_published_date_max",
      searchPostVarName: "i_filterOriginalPublishedDateMax",
      fieldTypeObj: DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj
    };

    const opportunityNameFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfOpportunityName.get("display_name") + " Search",
      filterSubTitle: "Searches only the " + DatabaseMobx.c_fieldMapOfOpportunityName.get("display_name") + " field",
      filterInstructions: "This search can take longer, but is faster than the Keyword Search which includes the descriptions",
      autoSearchDbName: "filter_opportunity_name_search",
      searchPostVarName: "i_filterOpportunityNameSearch",
      fieldTypeObj: DatabaseMobx.c_genericTextFieldTypeObj
    };

    var opportunityNameDescriptionOfWorkFilterTitle = "Keyword Search";
    var opportunityNameDescriptionOfWorkFilterSubTitle = "Searches the " + DatabaseMobx.c_fieldMapOfOpportunityName.get("display_name") + " and " + DatabaseMobx.c_fieldMapOfDescriptionOfWork.get("display_name") + " fields";
    var opportunityNameDescriptionOfWorkFilterInstructions = "This keyword search can take longer to return results than other filters\nYou can search multiple keywords separated by commas";
    var opportunityNameDescriptionOfWorkFilterKeywordHelpLinkText = undefined;
    if(c_userGcssDataSource1GovWinTF) {
      opportunityNameDescriptionOfWorkFilterTitle = "GovWin Keyword Search";
      opportunityNameDescriptionOfWorkFilterSubTitle = "Utilizes GovWin's search across all opportunities for keywords in Name, Description, Solicitation Number, GovWin ID, Office, Classification Codes, Competition Type, Procurement, etc";
      opportunityNameDescriptionOfWorkFilterInstructions = undefined;
      opportunityNameDescriptionOfWorkFilterKeywordHelpLinkText = "GovWin Keywords Help";
    }
    else if(c_userGcssDataSource2FedCompTF) {
      opportunityNameDescriptionOfWorkFilterTitle = "Federal Compass Keyword Search";
      opportunityNameDescriptionOfWorkFilterSubTitle = "Utilizes Federal Compass' keyword search query";
      opportunityNameDescriptionOfWorkFilterInstructions = undefined;
      opportunityNameDescriptionOfWorkFilterKeywordHelpLinkText = "Federal Compass Keywords Help";
    }
    const opportunityNameDescriptionOfWorkFilterObj = {
      filterTitle: opportunityNameDescriptionOfWorkFilterTitle,
      filterSubTitle: opportunityNameDescriptionOfWorkFilterSubTitle,
      filterInstructions: opportunityNameDescriptionOfWorkFilterInstructions,
      filterKeywordsHelpLinkText: opportunityNameDescriptionOfWorkFilterKeywordHelpLinkText,
      autoSearchDbName: "filter_keyword_search_opportunity_name_description_of_work",
      searchPostVarName: "i_filterKeywordSearchOpportunityNameDescriptionOfWork",
      fieldTypeObj: DatabaseMobx.c_genericTextFieldTypeObj
    };

    const solicitationFilterObj = {
      filterTitle: "Search by " + DatabaseMobx.c_fieldMapOfSolicitationNumber.get("display_name"),
      autoSearchDbName: "filter_solicitation_num_search",
      searchPostVarName: "i_filterSolicitationNumSearch",
      fieldTypeObj: DatabaseMobx.c_genericTextFieldTypeObj
    };

    const departmentAgencyFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssBmDepartmentAgencyID.get("display_name"),
      autoSearchDbName: "filter_department_agency_bm_ids_comma",
      searchPostVarName: "i_filterDepartmentAgencyBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterDepartmentsAgenciesFieldTypeObj
    };

    const subTierFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssBmSubTierID.get("display_name"),
      autoSearchDbName: "filter_sub_tier_bm_ids_comma",
      searchPostVarName: "i_filterSubTierBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterSubTiersFieldTypeObj,
      requiresSAMSubTierDuplicatesForceMultiSelectTF: c_userGcssDataSource0SamTF
    };

    const compeititionTypeFieldDisplayName = DatabaseMobx.c_fieldMapOfCompetitionTypeBmSetAsideIDsComma.get("display_name");
    var setAsideFilterTitle = compeititionTypeFieldDisplayName;
    if(!JSFUNC.input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(compeititionTypeFieldDisplayName, "aside")) {
      setAsideFilterTitle += " (Set Aside)";
    }

    const setAsideFilterObj = {
      filterTitle: setAsideFilterTitle,
      autoSearchDbName: "filter_set_aside_bm_ids_comma",
      searchPostVarName: "i_filterSetAsideBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj,
      requiresFedCompSetAsidesTranslationFromBitMasterIDsTF: c_userGcssDataSource2FedCompTF
    };

    const contractOpportunityTypeFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssBmContractOpportunityTypeID.get("display_name"),
      autoSearchDbName: "filter_contract_opportunity_type_bm_ids_comma",
      searchPostVarName: "i_filterContractOpportunityTypeBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterContractOpportunityTypesFieldTypeObj,
      requiresContractOpportunityTypesTranslationFromBitMasterIDsTF: c_userGcssDataSource2FedCompTF
    };

    const fedCompContractTypeFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssBmFedCompContractTypeID.get("display_name"),
      autoSearchDbName: "filter_fedcomp_contract_type_bm_ids_comma",
      searchPostVarName: "i_filterFedCompContractTypeBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterFedCompContractTypeNamesFieldTypeObj,
      requiresFedCompContractTypesTranslationFromBitMasterIDsTF: c_userGcssDataSource2FedCompTF
    };

    const naicsCodeFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfNaicsCodeIDsComma.get("display_name"),
      autoSearchDbName: "filter_naics_code_bm_ids_comma",
      searchPostVarName: "i_filterNaicsCodeBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj,
      requiresNaicsCodesTranslationFromBitMasterIDsTF: (c_userGcssDataSource1GovWinTF || c_userGcssDataSource2FedCompTF)
    };

    const productServiceCodeFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssBmProductServiceCodeIDsComma.get("display_name"),
      autoSearchDbName: "filter_product_service_code_bm_ids_comma",
      searchPostVarName: "i_filterProductServiceCodeBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterProductServiceCodesFieldTypeObj,
      requiresProductServiceCodesTranslationFromBitMasterIDsTF: c_userGcssDataSource2FedCompTF
    };

    const placeOfPerformanceStateFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma.get("display_name"),
      autoSearchDbName: "filter_place_of_performance_state_bm_ids_comma",
      searchPostVarName: "i_filterPlaceOfPerformanceStateBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterStatesA2AndNameFieldTypeObj
    };

    const placeOfPerformanceCountryFilterObj = {
      filterTitle: DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma.get("display_name"),
      autoSearchDbName: "filter_place_of_performance_country_bm_ids_comma",
      searchPostVarName: "i_filterPlaceOfPerformanceCountryBmIDsComma",
      fieldTypeObj: DatabaseMobx.c_selectMultiBitMasterCountriesA3AndNameFieldTypeObj
    };

    //1. Dates box
    var datesBoxBubblesOfFiltersArrayOfArraysOfObjs = [];
    if(c_userGcssDataSource0SamTF) {
      datesBoxBubblesOfFiltersArrayOfArraysOfObjs = [[proposalDueDateMinFilterObj, proposalDueDateMaxFilterObj], [lastUpdatedDateMinFilterObj, lastUpdatedDateMaxFilterObj], [originalPublishedDateMinFilterObj, originalPublishedDateMaxFilterObj]];
    }
    else if(c_userGcssDataSource1GovWinTF) {
      datesBoxBubblesOfFiltersArrayOfArraysOfObjs = [[proposalDueDateMinFilterObj, proposalDueDateMaxFilterObj], [lastUpdatedDateMinFilterObj, lastUpdatedDateMaxFilterObj]];
    }
    else if(c_userGcssDataSource2FedCompTF) {
      datesBoxBubblesOfFiltersArrayOfArraysOfObjs = [[proposalDueDateMinFilterObj, proposalDueDateMaxFilterObj], [lastUpdatedDateMinFilterObj, lastUpdatedDateMaxFilterObj]];
    }
    const datesBoxObj = {
      boxTitle: "Dates",
      bubblesOfFiltersArrayOfArraysOfObjs: datesBoxBubblesOfFiltersArrayOfArraysOfObjs
    };

    //2. Search box
    var searchBoxBubblesOfFiltersArrayOfArraysOfObjs = [];
    if(c_userGcssDataSource0SamTF) {
      searchBoxBubblesOfFiltersArrayOfArraysOfObjs.push([opportunityNameFilterObj]);
    }
    searchBoxBubblesOfFiltersArrayOfArraysOfObjs.push([opportunityNameDescriptionOfWorkFilterObj]); //each data source has a differently worded and functioning keyword search
    if(c_userGcssDataSource0SamTF) { //solicitation number search SAM only (not GovWin/FedComp)
      searchBoxBubblesOfFiltersArrayOfArraysOfObjs.push([solicitationFilterObj]);
    }
    const searchBoxObj = {
      boxTitle: "Search",
      bubblesOfFiltersArrayOfArraysOfObjs: searchBoxBubblesOfFiltersArrayOfArraysOfObjs
    };

    //3. Codes box
    var codesBoxBubblesOfFiltersArrayOfArraysOfObjs = [[naicsCodeFilterObj]];
    if(c_userGcssDataSource0SamTF || c_userGcssDataSource2FedCompTF) {
      codesBoxBubblesOfFiltersArrayOfArraysOfObjs.push([productServiceCodeFilterObj]);
    }
    const codesBoxObj = {
      boxTitle: "Codes",
      bubblesOfFiltersArrayOfArraysOfObjs: codesBoxBubblesOfFiltersArrayOfArraysOfObjs
    };

    //4. Dept/Agency box
    var deptAgBoxObj = undefined;
    if(c_userGcssDataSource0SamTF) {
      deptAgBoxObj = {
        boxTitle: "Dept/Agency",
        bubblesOfFiltersArrayOfArraysOfObjs: [[departmentAgencyFilterObj], [subTierFilterObj]]
      };
    }

    //5. Opportunity box
    var opportunityBoxObj = undefined;
    if(c_userGcssDataSource0SamTF || c_userGcssDataSource2FedCompTF) {
      var opportunityBoxBubblesOfFiltersArrayOfArraysOfObjs = [];
      if(c_userGcssDataSource0SamTF) {
        opportunityBoxBubblesOfFiltersArrayOfArraysOfObjs = [[setAsideFilterObj], [contractOpportunityTypeFilterObj]];
      }
      else if(c_userGcssDataSource2FedCompTF) { //Federal Compass API has the search field 'noticeType' which maps to the values in tbl_bit_master_contract_opportunity_types, also FedComp has FedComp Contract Type unique BIT Master multiselect field
        opportunityBoxBubblesOfFiltersArrayOfArraysOfObjs = [[setAsideFilterObj], [contractOpportunityTypeFilterObj], [fedCompContractTypeFilterObj]];
      }
      opportunityBoxObj = {
        boxTitle: "Opportunity",
        bubblesOfFiltersArrayOfArraysOfObjs: opportunityBoxBubblesOfFiltersArrayOfArraysOfObjs
      };
    }

    //6. Location box
    var locationBoxObj = undefined;
    if(c_userGcssDataSource0SamTF) {
      locationBoxObj = {
        boxTitle: "Location",
        bubblesOfFiltersArrayOfArraysOfObjs: [[placeOfPerformanceStateFilterObj], [placeOfPerformanceCountryFilterObj]]
      };
    }

    var manualSearchBoxesOfBubblesOfFiltersArrayOfObjs = [];
    if(datesBoxObj !== undefined) { manualSearchBoxesOfBubblesOfFiltersArrayOfObjs.push(datesBoxObj); }
    if(searchBoxObj !== undefined) { manualSearchBoxesOfBubblesOfFiltersArrayOfObjs.push(searchBoxObj); }
    if(codesBoxObj !== undefined) { manualSearchBoxesOfBubblesOfFiltersArrayOfObjs.push(codesBoxObj); }
    if(deptAgBoxObj !== undefined) { manualSearchBoxesOfBubblesOfFiltersArrayOfObjs.push(deptAgBoxObj); }
    if(opportunityBoxObj !== undefined) { manualSearchBoxesOfBubblesOfFiltersArrayOfObjs.push(opportunityBoxObj); }
    if(locationBoxObj !== undefined) { manualSearchBoxesOfBubblesOfFiltersArrayOfObjs.push(locationBoxObj); }

    return(manualSearchBoxesOfBubblesOfFiltersArrayOfObjs);
  }

  get c_autoSearchImportDefaultFieldsObjOfObjsByAutoSearchDbName() {
    return({
      import_default_capture_managers_ids_colon_percent_comma: {
        fieldDisplayName: DatabaseMobx.c_fieldMapOfCaptureManagers.get("display_name"),
        fieldTypeObj: DatabaseMobx.c_fieldMapOfCaptureManagers.get("fieldTypeObj")
      },
      import_default_division_owners_ids_colon_percent_comma: {
        fieldDisplayName: DatabaseMobx.c_fieldMapOfDivisionOwners.get("display_name"),
        fieldTypeObj: DatabaseMobx.c_fieldMapOfDivisionOwners.get("fieldTypeObj")
      },
      import_default_capture_type_id: {
        fieldDisplayName: DatabaseMobx.c_fieldMapOfCaptureType.get("display_name"),
        fieldTypeObj: DatabaseMobx.c_fieldMapOfCaptureType.get("fieldTypeObj")
      },
      import_default_stage_id: {
        fieldDisplayName: DatabaseMobx.c_fieldMapOfStage.get("display_name"),
        fieldTypeObj: this.c_searchImportDefaultStagesInSelectedCaptureTypeFieldTypeObj
      }
    });
  }

  //full set of search fields (including checkboxes but not import defaults) with search panel current selected values integrated (new search or existing autosearch loaded)
  get c_searchFieldsWithValuesObj() {
    return(this.compute_search_fields_with_values_obj_from_search_filter_values_map(this.o_manualSearchLocalSelectedFilterValuesMap));
  }

  compute_search_fields_with_values_obj_from_search_filter_values_map(i_searchFilterValuesMap) {
    const c_manualSearchBoxesOfBubblesOfFiltersArrayOfObjs = this.c_manualSearchBoxesOfBubblesOfFiltersArrayOfObjs;

    //checkboxes
    const includeAlreadyInCEResultsCheckedTF = (i_searchFilterValuesMap.get("include_already_in_ce_results_01") === 1);
    const includeNotInterestedResultsCheckedTF = (i_searchFilterValuesMap.get("include_not_interested_results_01") === 1);

    //boxes of filters
    var boxesOfBubblesOfFiltersWithValuesArrayOfObjs = [];
    for(let manualSearchBoxOfBubblesOfFiltersObj of c_manualSearchBoxesOfBubblesOfFiltersArrayOfObjs) {
      //single box containing multiple vertical bubbles of filters with values
      var bubblesOfFiltersWithValuesArrayofObjs = [];
      for(let boxFiltersArrayOfObjs of manualSearchBoxOfBubblesOfFiltersObj.bubblesOfFiltersArrayOfArraysOfObjs) {
        //single bubble containing multiple side by side filters with values
        var filtersWithValuesArrayOfObjs = [];
        var anyFilterIsFilledOutTF = false;
        for(let boxFilterObj of boxFiltersArrayOfObjs) {
          var selectedFilterValue = i_searchFilterValuesMap.get(boxFilterObj.autoSearchDbName);
          if(selectedFilterValue === undefined) { //fill in undefined values with the field blankValue
            selectedFilterValue = boxFilterObj.fieldTypeObj.blankValue;
          }

          var filterValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(selectedFilterValue, boxFilterObj.fieldTypeObj);

          var filterWithValueObj = JSFUNC.copy_obj(boxFilterObj);
          filterWithValueObj.selectedFilterValue = selectedFilterValue;
          filterWithValueObj.filterValueMaskSortIfoObj = filterValueMaskSortIfoObj;

          filtersWithValuesArrayOfObjs.push(filterWithValueObj);

          if(filterValueMaskSortIfoObj.isFilledOutTF) {
            anyFilterIsFilledOutTF = true;
          }
        }

        var bubbleOfFiltersWithValuesObj = {
          anyFilterIsFilledOutTF: anyFilterIsFilledOutTF,
          filtersWithValuesArrayOfObjs: filtersWithValuesArrayOfObjs
        };

        bubblesOfFiltersWithValuesArrayofObjs.push(bubbleOfFiltersWithValuesObj);
      }

      var boxOfBubblesOfFiltersWithValuesObj = {
        boxTitle: manualSearchBoxOfBubblesOfFiltersObj.boxTitle,
        bubblesOfFiltersWithValuesArrayofObjs: bubblesOfFiltersWithValuesArrayofObjs
      };

      boxesOfBubblesOfFiltersWithValuesArrayOfObjs.push(boxOfBubblesOfFiltersWithValuesObj);
    }

    return({
      includeAlreadyInCEResultsCheckedTF: includeAlreadyInCEResultsCheckedTF,
      includeNotInterestedResultsCheckedTF: includeNotInterestedResultsCheckedTF,
      boxesOfBubblesOfFiltersWithValuesArrayOfObjs: boxesOfBubblesOfFiltersWithValuesArrayOfObjs
    });
  }


  get c_allSearchFiltersWithValuesArrayOfObjs() {
    const c_searchFieldsWithValuesObj = this.c_searchFieldsWithValuesObj;

    var filledOutSearchFieldsWithValuesArrayOfObjs = [];
    for(let boxOfBubblesOfFiltersWithValuesObj of c_searchFieldsWithValuesObj.boxesOfBubblesOfFiltersWithValuesArrayOfObjs) {
      for(let bubbleOfFiltersWithValuesObj of boxOfBubblesOfFiltersWithValuesObj.bubblesOfFiltersWithValuesArrayofObjs) {
        for(let filterWithValueObj of bubbleOfFiltersWithValuesObj.filtersWithValuesArrayOfObjs) {
          filledOutSearchFieldsWithValuesArrayOfObjs.push(filterWithValueObj);
        }
      }
    }
    return(filledOutSearchFieldsWithValuesArrayOfObjs);
  }


  get c_searchImportDefaultSelectedCaptureTypeIsFilledOutTF() {
    const o_manualSearchLocalSelectedFilterValuesMap = this.o_manualSearchLocalSelectedFilterValuesMap;

    const importDefaultSelectedCaptureTypeID = o_manualSearchLocalSelectedFilterValuesMap.get("import_default_capture_type_id");
    return(DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(importDefaultSelectedCaptureTypeID, DatabaseMobx.c_selectCaptureTypeNoClearFieldTypeObj));
  }


  get c_searchImportDefaultStagesInSelectedCaptureTypeFieldTypeObj() {
    const o_manualSearchLocalSelectedFilterValuesMap = this.o_manualSearchLocalSelectedFilterValuesMap;

    const importDefaultSelectedCaptureTypeID = o_manualSearchLocalSelectedFilterValuesMap.get("import_default_capture_type_id");
    return(DatabaseMobx.create_select_stages_field_type_obj_within_capture_type_id(importDefaultSelectedCaptureTypeID));
  }


  get c_createNewSearchFilledOutFiltersWithValuesHoverTextBlock() {
    const o_manualSearchLocalSelectedFilterValuesMap = this.o_manualSearchLocalSelectedFilterValuesMap;
    const o_selectedAutoSearchIDOrUndefined = this.o_selectedAutoSearchIDOrUndefined;

    var createNewSearchFilledOutFiltersWithValuesHoverTextBlock = "";
    if(o_selectedAutoSearchIDOrUndefined === undefined) { //an auto search is not currently selected
      createNewSearchFilledOutFiltersWithValuesHoverTextBlock = this.compute_gcss_search_all_filled_out_filters_with_values_hover_text_block_from_autosearch_map(o_manualSearchLocalSelectedFilterValuesMap);
    }
    return(createNewSearchFilledOutFiltersWithValuesHoverTextBlock);
  }


  get c_autoSearchesArrayOfObjs() {
    const o_tbl_u_gcss_auto_searches = DatabaseMobx.o_tbl_u_gcss_auto_searches;
    const o_userPerEmailID = UserMobx.o_userPerEmailID;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;

    //fetch only the auto searches this user created
    var autoSearchesArrayOfObjs = [];
    for(let autoSearchMap of o_tbl_u_gcss_auto_searches.values()) {
      if(autoSearchMap.get("user_per_email_id") === o_userPerEmailID) {
        if(autoSearchMap.get("auto_search_sam0_govwin1") === c_userGcssSam0GovWin1) {
          var autoSearchObj = JSFUNC.obj_from_map(autoSearchMap);

          autoSearchObj.filledOutFiltersWithValuesHoverTextBlock = this.compute_gcss_search_all_filled_out_filters_with_values_hover_text_block_from_autosearch_map(autoSearchMap);

          autoSearchesArrayOfObjs.push(autoSearchObj);
        }
      }
    }

    //sort the autosearches by the numeric sort field that the user can rearrange for themselves
    JSFUNC.sort_arrayOfObjs(autoSearchesArrayOfObjs, "sort", true);

    return(autoSearchesArrayOfObjs);
  }

  compute_gcss_search_all_filled_out_filters_with_values_hover_text_block_from_autosearch_map(i_autoSearchMap) {
    const searchFieldsWithValuesObj = this.compute_search_fields_with_values_obj_from_search_filter_values_map(i_autoSearchMap);
    return(this.compute_gcss_search_all_filled_out_filters_with_values_hover_text_block_from_search_fields_with_values_obj(searchFieldsWithValuesObj));
  }

  compute_gcss_search_all_filled_out_filters_with_values_hover_text_block_from_search_fields_with_values_obj(i_searchFieldsWithValuesObj) {
    var searchFilledOutFiltersHoverText = "";
    for(let boxOfBubblesOfFiltersWithValuesObj of i_searchFieldsWithValuesObj.boxesOfBubblesOfFiltersWithValuesArrayOfObjs) {
      for(let bubbleOfFiltersWithValuesObj of boxOfBubblesOfFiltersWithValuesObj.bubblesOfFiltersWithValuesArrayofObjs) {
        for(let filterWithValueObj of bubbleOfFiltersWithValuesObj.filtersWithValuesArrayOfObjs) {
          if(filterWithValueObj.filterValueMaskSortIfoObj.isFilledOutTF) {
            if(searchFilledOutFiltersHoverText !== "") { searchFilledOutFiltersHoverText += "\n"; }
            searchFilledOutFiltersHoverText += " - " + filterWithValueObj.filterTitle + ": " + filterWithValueObj.filterValueMaskSortIfoObj.valueMaskPlainText;
          }
        }
      }
    }

    if(searchFilledOutFiltersHoverText === "") {
      searchFilledOutFiltersHoverText = "--No Search Filters Applied--";
    }

    return(searchFilledOutFiltersHoverText);
  }


  get c_selectedAutoSearchObjOrUndefined() {
    const o_selectedAutoSearchIDOrUndefined = this.o_selectedAutoSearchIDOrUndefined;
    const c_autoSearchesArrayOfObjs = this.c_autoSearchesArrayOfObjs;

    if(o_selectedAutoSearchIDOrUndefined === undefined) {
      return(undefined);
    }
    return(JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_autoSearchesArrayOfObjs, "id", o_selectedAutoSearchIDOrUndefined));
  }


  get c_numSearchResultsPerPage() {
    const c_userGcssSearchResultsCompact0Large1 = UserMobx.c_userGcssSearchResultsCompact0Large1;
    if(c_userGcssSearchResultsCompact0Large1 === 1) {
      return(10); //10 large results per page
    }
    return(50); //50 compact results per page
  }

  get c_totalNumFilteredSearchResultsFromSelectedTab() {
    return(this.c_selectedTabExpandedSearchResultsArrayOfObjs.length);
  }

  get c_currentPageFirstItemNumber() {
    if(this.c_totalNumFilteredSearchResultsFromSelectedTab === 0) {
      return(0);
    }
    return(((this.o_currentPageNumber - 1) * this.c_numSearchResultsPerPage) + 1);
  }

  get c_currentPageLastItemNumber() {
    var currentPageLastItemNumber = (this.o_currentPageNumber * this.c_numSearchResultsPerPage);
    if(this.c_totalNumFilteredSearchResultsFromSelectedTab < currentPageLastItemNumber) {
      currentPageLastItemNumber = this.c_totalNumFilteredSearchResultsFromSelectedTab;
    }
    return(currentPageLastItemNumber);
  }


  get c_canIncrementCurrentPageNumberTF() {
    return(this.c_currentPageLastItemNumber < this.c_totalNumFilteredSearchResultsFromSelectedTab);
  }


  get c_canDecrementCurrentPageNumberTF() {
    return(this.o_currentPageNumber > 1);
  }


  get c_searchResultSortSelectGcssFieldFieldTypeObj() {
    var allGcssExpandedCaptureFieldsArrayOfMaps = [];
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfOpportunityName);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssLastUpdatedDateTimeUtc);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssOriginalPublishedDateTimeUtc);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfCompetitionTypeBmSetAsideIDsComma);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssBmContractOpportunityTypeID);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfNaicsCodeIDsComma);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssBmProductServiceCodeIDsComma);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssBmDepartmentAgencyID);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssBmSubTierID);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma);
    allGcssExpandedCaptureFieldsArrayOfMaps.push(DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma);
 
    var gcssFieldDbDisplayNamesArrayOfObjs = [];
    for(let gcssExpandedCaptureFieldMap of allGcssExpandedCaptureFieldsArrayOfMaps) {
      var dbName = gcssExpandedCaptureFieldMap.get("db_name");
      var displayName = gcssExpandedCaptureFieldMap.get("display_name");
      var displayNameLowercase = JSFUNC.str2lower(displayName, "");

      gcssFieldDbDisplayNamesArrayOfObjs.push({
        dbName: dbName,
        displayName: displayName,
        displayNameLowercase: displayNameLowercase
      });
    }

    JSFUNC.sort_arrayOfObjs(gcssFieldDbDisplayNamesArrayOfObjs, "displayNameLowercase", true);

    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("GCSS Sort Field", gcssFieldDbDisplayNamesArrayOfObjs, "dbName", false, "displayName", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }


  get c_allLoadedExpandedSearchResultsArrayOfObjs() {
    const o_searchResultsArrayOfObjs = this.o_searchResultsArrayOfObjs;
    const c_gcssTblGGcssNotInterestedGcssIDColumnDbName = this.c_gcssTblGGcssNotInterestedGcssIDColumnDbName;
    const c_upeGcssIDsMarkedForImportArray = this.c_upeGcssIDsMarkedForImportArray;
    const c_gcssAlreadyInCEImportedCapturesGcssIDFieldName = this.c_gcssAlreadyInCEImportedCapturesGcssIDFieldName;
    const o_tbl_g_gcss_not_interested = DatabaseMobx.o_tbl_g_gcss_not_interested;
    const c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs = DatabaseMobx.c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;

    var allLoadedExpandedSearchResultsArrayOfObjs = [];
    for(let searchResultObj of o_searchResultsArrayOfObjs) {
      var expandedSearchResultObj = this.create_gcss_expanded_search_result_obj_from_search_result_obj(searchResultObj, c_userGcssSam0GovWin1);

      //gcss search result already imported in CaptureExec based on gcss_id field in tbl_captures
      var alreadyInCETF = false;
      var alreadyInCEAddedDateMaskPlainText = JSFUNC.blank_date();
      var captureImportedFromGCSSObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs, c_gcssAlreadyInCEImportedCapturesGcssIDFieldName, expandedSearchResultObj.id);
      if(captureImportedFromGCSSObjOrUndefined !== undefined) {
        alreadyInCETF = true;
        alreadyInCEAddedDateMaskPlainText = captureImportedFromGCSSObjOrUndefined.addedDateMaskPlainText;
      }
      expandedSearchResultObj.alreadyInCETF = alreadyInCETF;
      expandedSearchResultObj.alreadyInCEAddedDateMaskPlainText = alreadyInCEAddedDateMaskPlainText;

      //initialize obj variables for not interested, undecided, mark for import
      var gcssNotInterestedRowExistsTF = false;
      var gcssNotInterestedRowID = -1;
      var notInterestedByUserPerEmailID = -1;
      var notInterestedByUserFullNamePlainText = "";
      var markedUndec0NotInt1Import2 = 0;
      var notInterestedReasonIDsComma = "";
      var notInterestedExplanation = "";

      //look up in gcss not interested tbl if this search result is marked as not interested or undecided with reasons given
      var matchingGcssNotInterestedMap = JSFUNC.get_first_map_matching_field_value(o_tbl_g_gcss_not_interested, c_gcssTblGGcssNotInterestedGcssIDColumnDbName, expandedSearchResultObj.id);
      if(matchingGcssNotInterestedMap !== undefined) {
        gcssNotInterestedRowExistsTF = true;
        gcssNotInterestedRowID = matchingGcssNotInterestedMap.get("id");
        notInterestedByUserPerEmailID = matchingGcssNotInterestedMap.get("user_per_email_id");
        notInterestedByUserFullNamePlainText = DatabaseMobx.user_full_name_plaintext_from_user_per_email_id(notInterestedByUserPerEmailID);
        markedUndec0NotInt1Import2 = matchingGcssNotInterestedMap.get("undecided0_notinterested1");
        notInterestedReasonIDsComma = matchingGcssNotInterestedMap.get("reason_ids_comma");
        notInterestedExplanation = matchingGcssNotInterestedMap.get("explanation");
      }

      var notInterestedReasonsValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(notInterestedReasonIDsComma, DatabaseMobx.c_selectMultiAddGcssNotInterestedReasonsFieldTypeObj);
      var notInterestedExplanationValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(notInterestedExplanation, DatabaseMobx.c_genericTextareaFieldTypeObj);

      //the logged in userPerEmail marked this gcss record for import
      if(JSFUNC.in_array(expandedSearchResultObj.id, c_upeGcssIDsMarkedForImportArray)) {
        markedUndec0NotInt1Import2 = 2; //overwrite company setting of not interested or undecided if user has selected this for import
      }

      var buttonNotInterestedSelectedTF = false;
      var buttonUndecidedSelectedTF = false;
      var buttonMarkForImportSelectedTF = false;
      if(markedUndec0NotInt1Import2 === 1) { buttonNotInterestedSelectedTF = true; }
      else if(markedUndec0NotInt1Import2 === 2) { buttonMarkForImportSelectedTF = true; }
      else { buttonUndecidedSelectedTF = true; }

      expandedSearchResultObj.markedUndec0NotInt1Import2 = markedUndec0NotInt1Import2;
      expandedSearchResultObj.gcssNotInterestedRowExistsTF = gcssNotInterestedRowExistsTF;
      expandedSearchResultObj.gcssNotInterestedRowID = gcssNotInterestedRowID;
      expandedSearchResultObj.notInterestedByUserPerEmailID = notInterestedByUserPerEmailID;
      expandedSearchResultObj.notInterestedByUserFullNamePlainText = notInterestedByUserFullNamePlainText;
      expandedSearchResultObj.notInterestedReasonsValueMaskSortIfoObj = notInterestedReasonsValueMaskSortIfoObj;
      expandedSearchResultObj.notInterestedExplanationValueMaskSortIfoObj = notInterestedExplanationValueMaskSortIfoObj;
      expandedSearchResultObj.buttonNotInterestedSelectedTF = buttonNotInterestedSelectedTF;
      expandedSearchResultObj.buttonUndecidedSelectedTF = buttonUndecidedSelectedTF;
      expandedSearchResultObj.buttonMarkForImportSelectedTF = buttonMarkForImportSelectedTF;
      
      allLoadedExpandedSearchResultsArrayOfObjs.push(expandedSearchResultObj);
    }

    return(allLoadedExpandedSearchResultsArrayOfObjs);
  }

  create_gcss_expanded_search_result_obj_from_search_result_obj(i_gcssRawSearchResutObj, i_gcssSam0GovWin1) {
    var expandedSearchResultObj = JSFUNC.copy_obj(i_gcssRawSearchResutObj);

    //the "id" field from expandedSearchResultObj is double used as the SAM GCSS int id from tbl_gcss_ops, and the GovWin ID string direct from GovWin
    var gcssIDValueRaw = 0; //0 is flag for a non SAM opp
    var gcssGovWinIDStringValueRaw = ""; //"" is flag for a non GovWin opp
    var gcssFedCompIDStringValueRaw = ""; //"" is flag for a non Federal Compass opp
    if(i_gcssSam0GovWin1 === 0) { //SAM
      gcssIDValueRaw = expandedSearchResultObj.id;
    }
    else if(i_gcssSam0GovWin1 === 1) { //GovWin
      gcssGovWinIDStringValueRaw = expandedSearchResultObj.id;
    }
    else if(i_gcssSam0GovWin1 === 2) { //FedComp
      gcssFedCompIDStringValueRaw = expandedSearchResultObj.id;
    }

    //convert dateTimeUtc raw format into converted local date formats for display in search results (only want full datetime displayed for proposal due date)
    var originalPublishedDateValueRaw = JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(expandedSearchResultObj.original_published_datetime_utc);
    var lastUpdatedDateValueRaw = JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(expandedSearchResultObj.last_updated_datetime_utc);

    //link fields have an extra [[Mask]] applied to the string value returning from the search
    var samLinkValueRaw = ((JSFUNC.string_is_filled_out_tf(expandedSearchResultObj.sam_url)) ? ("[[SAM Link]]" + expandedSearchResultObj.sam_url) : (""));
    var govWinLinkValueRaw = ((JSFUNC.string_is_filled_out_tf(expandedSearchResultObj.gcssGovWinLink)) ? ("[[GovWin Link]]" + expandedSearchResultObj.gcssGovWinLink) : (""));
    var fedCompLinkValueRaw = ((JSFUNC.string_is_filled_out_tf(expandedSearchResultObj.gcssFedCompLink)) ? ("[[FC Link]]" + expandedSearchResultObj.gcssFedCompLink) : (""));
    
    expandedSearchResultObj.gcssIDMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(gcssIDValueRaw, DatabaseMobx.c_fieldMapOfGcssID.get("fieldTypeObj"));
    expandedSearchResultObj.gcssGovWinIDStringMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(gcssGovWinIDStringValueRaw, DatabaseMobx.c_fieldMapOfGcssGovWinIDString.get("fieldTypeObj"));
    expandedSearchResultObj.gcssFedCompIDStringMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(gcssFedCompIDStringValueRaw, DatabaseMobx.c_fieldMapOfGcssFedCompIDString.get("fieldTypeObj"));
    expandedSearchResultObj.originalPublishedDateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(originalPublishedDateValueRaw, DatabaseMobx.c_genericDateFieldTypeObj);
    expandedSearchResultObj.lastUpdatedDateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(lastUpdatedDateValueRaw, DatabaseMobx.c_genericDateFieldTypeObj);
    expandedSearchResultObj.proposalDueDateTimeUtcMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.proposal_due_datetime_utc, DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc.get("fieldTypeObj"));
    expandedSearchResultObj.samUrlMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(samLinkValueRaw, DatabaseMobx.c_fieldMapOfGcssSamLink.get("fieldTypeObj"));
    expandedSearchResultObj.govWinLinkMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(govWinLinkValueRaw, DatabaseMobx.c_fieldMapOfGcssGovWinLink.get("fieldTypeObj"));
    expandedSearchResultObj.fedCompLinkMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(fedCompLinkValueRaw, DatabaseMobx.c_fieldMapOfGcssFedCompLink.get("fieldTypeObj"));
    expandedSearchResultObj.opportunityNameMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.opportunity_name, DatabaseMobx.c_fieldMapOfOpportunityName.get("fieldTypeObj"));
    expandedSearchResultObj.descriptionOfWorkOpeningMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.description_of_work_opening, DatabaseMobx.c_genericTextFieldTypeObj);
    expandedSearchResultObj.departmentAgencyMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.department_agency_bm_id, DatabaseMobx.c_selectMultiBitMasterDepartmentsAgenciesFieldTypeObj);
    expandedSearchResultObj.subTierMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.subtier_bm_id, DatabaseMobx.c_selectMultiBitMasterSubTiersFieldTypeObj);
    expandedSearchResultObj.officeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.gcssGovWinFedCompOffice, DatabaseMobx.c_fieldMapOfGcssOffice.get("fieldTypeObj")); //this office is only for GovWin/FedComp display on search results, single detail open overwrites this loaded/computed office value
    expandedSearchResultObj.setAsideMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.set_aside_bm_id, DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj);
    expandedSearchResultObj.contractOpportunityTypeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.contract_opportunity_type_bm_id, DatabaseMobx.c_selectMultiBitMasterContractOpportunityTypesFieldTypeObj);
    expandedSearchResultObj.fedCompContractTypeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.fedCompContractTypeID, DatabaseMobx.c_selectMultiBitMasterFedCompContractTypeNamesFieldTypeObj);
    expandedSearchResultObj.naicsCodeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.naics_code_bm_id, DatabaseMobx.c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj);
    expandedSearchResultObj.productServiceCodeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.product_service_code_bm_id, DatabaseMobx.c_selectMultiBitMasterProductServiceCodesFieldTypeObj);
    expandedSearchResultObj.placeOfPerformanceStateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.place_of_performance_state_bm_id, DatabaseMobx.c_selectMultiBitMasterStatesA2AndNameFieldTypeObj);
    expandedSearchResultObj.placeOfPerformanceCountryMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedSearchResultObj.place_of_performance_country_bm_id, DatabaseMobx.c_selectMultiBitMasterCountriesA3AndNameFieldTypeObj);

    const nowDateTimeUtc = JSFUNC.now_datetime_utc();
    expandedSearchResultObj.proposalDueDateHasPassedTF = (expandedSearchResultObj.proposal_due_datetime_utc < nowDateTimeUtc);
    
    //(only for fields available for user to sort) copy the sortValue from each valueMaskSortIfoObj to a separate variable that can be indexed by fieldDbName at the top obj level so that the arrayOfObjs can be sorted
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfOpportunityName.get("db_name") + "_SORT"] = expandedSearchResultObj.opportunityNameMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc.get("db_name") + "_SORT"] = expandedSearchResultObj.proposalDueDateTimeUtcMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssLastUpdatedDateTimeUtc.get("db_name") + "_SORT"] = expandedSearchResultObj.lastUpdatedDateMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssOriginalPublishedDateTimeUtc.get("db_name") + "_SORT"] = expandedSearchResultObj.originalPublishedDateMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfCompetitionTypeBmSetAsideIDsComma.get("db_name") + "_SORT"] = expandedSearchResultObj.setAsideMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssBmContractOpportunityTypeID.get("db_name") + "_SORT"] = expandedSearchResultObj.contractOpportunityTypeMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfNaicsCodeIDsComma.get("db_name") + "_SORT"] = expandedSearchResultObj.naicsCodeMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssBmProductServiceCodeIDsComma.get("db_name") + "_SORT"] = expandedSearchResultObj.productServiceCodeMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssBmDepartmentAgencyID.get("db_name") + "_SORT"] = expandedSearchResultObj.departmentAgencyMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssBmSubTierID.get("db_name") + "_SORT"] = expandedSearchResultObj.subTierMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma.get("db_name") + "_SORT"] = expandedSearchResultObj.placeOfPerformanceStateMaskSortIfoObj.valueSort;
    expandedSearchResultObj[DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma.get("db_name") + "_SORT"] = expandedSearchResultObj.placeOfPerformanceCountryMaskSortIfoObj.valueSort;
    
    return(expandedSearchResultObj);
}


  get c_allLoadedSortedExpandedSearchResultsArrayOfObjs() {
    const c_allLoadedExpandedSearchResultsArrayOfObjs = this.c_allLoadedExpandedSearchResultsArrayOfObjs;
    const c_userGcssSearchResultsSortGcssFieldDbName = UserMobx.c_userGcssSearchResultsSortGcssFieldDbName;
    const c_userGcssSearchResultsSortIsAscTF = UserMobx.c_userGcssSearchResultsSortIsAscTF;

    //no need to sort if no sort field is selected
    if(!JSFUNC.text_or_number_is_filled_out_tf(c_userGcssSearchResultsSortGcssFieldDbName)) {
      return(c_allLoadedExpandedSearchResultsArrayOfObjs);
    }

    const sortVarName = c_userGcssSearchResultsSortGcssFieldDbName + "_SORT";
    JSFUNC.sort_arrayOfObjs(c_allLoadedExpandedSearchResultsArrayOfObjs, sortVarName, c_userGcssSearchResultsSortIsAscTF);

    return(c_allLoadedExpandedSearchResultsArrayOfObjs);
  }


  get c_undecidedExpandedSearchResultsArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(this.c_allLoadedSortedExpandedSearchResultsArrayOfObjs, ["markedUndec0NotInt1Import2", "alreadyInCETF"], [0, false]));
  }
  get c_markedForImportExpandedSearchResultsArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(this.c_allLoadedSortedExpandedSearchResultsArrayOfObjs, ["markedUndec0NotInt1Import2", "alreadyInCETF"], [2, false]));
  }
  get c_markedAsNotInterestedExpandedSearchResultsArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(this.c_allLoadedSortedExpandedSearchResultsArrayOfObjs, ["markedUndec0NotInt1Import2", "alreadyInCETF"], [1, false]));
  }
  get c_alreadyInCEExpandedSearchResultsArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(this.c_allLoadedSortedExpandedSearchResultsArrayOfObjs, "alreadyInCETF", true));
  }


  get c_selectedTabExpandedSearchResultsArrayOfObjs() {
    const o_selectedSearchResultsTabDbName = this.o_selectedSearchResultsTabDbName;

    if(o_selectedSearchResultsTabDbName === "all") { return(this.c_allLoadedSortedExpandedSearchResultsArrayOfObjs); }
    else if(o_selectedSearchResultsTabDbName === "undecided") { return(this.c_undecidedExpandedSearchResultsArrayOfObjs); }
    else if(o_selectedSearchResultsTabDbName === "markedForImport") { return(this.c_markedForImportExpandedSearchResultsArrayOfObjs); }
    else if(o_selectedSearchResultsTabDbName === "markedAsNotInterested") { return(this.c_markedAsNotInterestedExpandedSearchResultsArrayOfObjs); }
    else if(o_selectedSearchResultsTabDbName === "alreadyInCE") { return(this.c_alreadyInCEExpandedSearchResultsArrayOfObjs); }
    return([]);
  }


  get c_currentPageOfExpandedSearchResultsArrayOfObjs() {
    const c_currentPageFirstItemNumber = this.c_currentPageFirstItemNumber;
    const c_currentPageLastItemNumber = this.c_currentPageLastItemNumber;
    const c_selectedTabExpandedSearchResultsArrayOfObjs = this.c_selectedTabExpandedSearchResultsArrayOfObjs;

    var expandedSearchResultsArrayOfObjs = [];
    var searchResultItemNumber = 1;
    for(let expandedSearchResultObj of c_selectedTabExpandedSearchResultsArrayOfObjs) {
      if((searchResultItemNumber >= c_currentPageFirstItemNumber) && (searchResultItemNumber <= c_currentPageLastItemNumber)) {
        expandedSearchResultsArrayOfObjs.push(expandedSearchResultObj);
      }
      searchResultItemNumber++;
    }
    return(expandedSearchResultsArrayOfObjs);
  }


  get c_searchResultItemWidthFlag() {
    const o_mediaQueryFlag = CaptureExecMobx.o_mediaQueryFlag;

    if(o_mediaQueryFlag === 1) { return(1); }
    else if(o_mediaQueryFlag <= 3) { return(2); }
    return(3);
  }


  get c_openExpandedDetailSearchResultObjOrUndefined() {
    const o_openSearchResultDetailGcssIDOrUndefined = this.o_openSearchResultDetailGcssIDOrUndefined;
    const o_openSearchResultDetailOpTblDataRowsArrayOfObjs = this.o_openSearchResultDetailOpTblDataRowsArrayOfObjs;
    const c_allLoadedSortedExpandedSearchResultsArrayOfObjs = this.c_allLoadedSortedExpandedSearchResultsArrayOfObjs;

    var openExpandedDetailSearchResultObjOrUndefined = undefined;
    if(o_openSearchResultDetailGcssIDOrUndefined !== undefined) {
      const openExpandedSearchResultObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_allLoadedSortedExpandedSearchResultsArrayOfObjs, "id", o_openSearchResultDetailGcssIDOrUndefined);
      if(openExpandedSearchResultObj !== undefined) {
        openExpandedDetailSearchResultObjOrUndefined = this.create_gcss_expanded_detail_search_results_obj_from_expanded_search_result_obj_and_op_tbl_data_rows_arrayOfObjs(openExpandedSearchResultObj, o_openSearchResultDetailOpTblDataRowsArrayOfObjs);
      }
    }
    return(openExpandedDetailSearchResultObjOrUndefined);
  }

  create_gcss_expanded_detail_search_results_obj_from_expanded_search_result_obj_and_op_tbl_data_rows_arrayOfObjs(i_expandedSearchResultObj, i_opTblDataRowsArrayOfObjs) {
    //precompute the number of op tbl data rows of history for this gcss record
    const numOpTblDataRows = i_opTblDataRowsArrayOfObjs.length;

    //copy the original expanded search result obj (has the masked bit master id values and the 200 char description)
    var expandedDetailSearchResultObj = JSFUNC.copy_obj(i_expandedSearchResultObj);

    //recompute gcss search result fields that use a different fieldTypeObj on the detail page vs the search result (like a date vs a datetime)
    const originalPublishedDateTimeUtcMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_expandedSearchResultObj.original_published_datetime_utc, DatabaseMobx.c_genericDateTimeFieldTypeObj);
    const lastUpdatedDateTimeUtcMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_expandedSearchResultObj.last_updated_datetime_utc, DatabaseMobx.c_genericDateTimeFieldTypeObj);

    //compute history objs for gcss precomputed fields in the search results (except description of work since it is a partial 200 char limit)
    expandedDetailSearchResultObj.gcssIDHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssID.get("display_name"), i_expandedSearchResultObj.gcssIDMaskSortIfoObj);
    expandedDetailSearchResultObj.gcssGovWinIDStringHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssGovWinIDString.get("display_name"), i_expandedSearchResultObj.gcssGovWinIDStringMaskSortIfoObj);
    expandedDetailSearchResultObj.gcssFedCompIDStringHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssFedCompIDString.get("display_name"), i_expandedSearchResultObj.gcssFedCompIDStringMaskSortIfoObj);
    expandedDetailSearchResultObj.originalPublishedDateTimeUtcHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssOriginalPublishedDateTimeUtc.get("display_name"), originalPublishedDateTimeUtcMaskSortIfoObj);
    expandedDetailSearchResultObj.lastUpdatedDateTimeUtcHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssLastUpdatedDateTimeUtc.get("display_name"), lastUpdatedDateTimeUtcMaskSortIfoObj);
    expandedDetailSearchResultObj.proposalDueDateTimeUtcHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_proposal_due_datetime_utc", DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc.get("display_name"), i_expandedSearchResultObj.proposalDueDateTimeUtcMaskSortIfoObj);
    //expandedDetailSearchResultObj.samUrlHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_sam_url", DatabaseMobx.c_fieldMapOfGcssSamLink.get("display_name"), i_expandedSearchResultObj.samUrlMaskSortIfoObj);
    expandedDetailSearchResultObj.govWinLinkHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "gcssGovWinLink", DatabaseMobx.c_fieldMapOfGcssGovWinLink.get("display_name"), i_expandedSearchResultObj.govWinLinkMaskSortIfoObj);
    expandedDetailSearchResultObj.fedCompLinkHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "gcssFedCompLink", DatabaseMobx.c_fieldMapOfGcssFedCompLink.get("display_name"), i_expandedSearchResultObj.fedCompLinkMaskSortIfoObj);
    expandedDetailSearchResultObj.opportunityNameHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_opportunity_name", DatabaseMobx.c_fieldMapOfOpportunityName.get("display_name"), i_expandedSearchResultObj.opportunityNameMaskSortIfoObj);
    expandedDetailSearchResultObj.naicsCodeHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_naics_code", DatabaseMobx.c_fieldMapOfNaicsCodeIDsComma.get("display_name"), i_expandedSearchResultObj.naicsCodeMaskSortIfoObj);
    expandedDetailSearchResultObj.setAsideHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_set_aside_name", DatabaseMobx.c_fieldMapOfCompetitionTypeBmSetAsideIDsComma.get("display_name"), i_expandedSearchResultObj.setAsideMaskSortIfoObj);
    expandedDetailSearchResultObj.contractOpportunityTypeHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_contract_opportunity_type_name", DatabaseMobx.c_fieldMapOfGcssBmContractOpportunityTypeID.get("display_name"), i_expandedSearchResultObj.contractOpportunityTypeMaskSortIfoObj);
    expandedDetailSearchResultObj.fedCompContractTypeHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssBmFedCompContractTypeID.get("display_name"), i_expandedSearchResultObj.fedCompContractTypeMaskSortIfoObj);
    expandedDetailSearchResultObj.productServiceCodeHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_product_service_code", DatabaseMobx.c_fieldMapOfGcssBmProductServiceCodeIDsComma.get("display_name"), i_expandedSearchResultObj.productServiceCodeMaskSortIfoObj);
    expandedDetailSearchResultObj.departmentAgencyHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_department_agency_name", DatabaseMobx.c_fieldMapOfGcssBmDepartmentAgencyID.get("display_name"), i_expandedSearchResultObj.departmentAgencyMaskSortIfoObj);
    expandedDetailSearchResultObj.subTierHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_subtier_name", DatabaseMobx.c_fieldMapOfGcssBmSubTierID.get("display_name"), i_expandedSearchResultObj.subTierMaskSortIfoObj);

    //compute latest raw string values for op tbl fields (fields not already in the gcss search result set of fields)
    const samUrlOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_sam_url");
    const solicitationNumberOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_solicitation_number");
    const officeOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_office_name");
    const rfpDateOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoRFPDate");
    const govWinProcurementCommentOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinProcurementComment");
    const periodOfPerformanceOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoPeriodOfPerformanceMonthsInt");
    const govWinDurationOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinDuration");
    const govWinPrimaryWorkRequiredOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinPrimaryWorkRequired");
    const govWinPriority1to5OpTblLatestIntValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinPriority1to5", true);
    const placeOfPerformanceAddressOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_address");
    const placeOfPerformanceCityOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_city");
    const placeOfPerformanceStateOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_state");
    const placeOfPerformanceZipOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_zip");
    const placeOfPerformanceCountryOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_country");
    const contractingOfficeCityOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_city");
    const contractingOfficeStateOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_state"); //KO state used for KO state (convert to state BIT Master id) and KO province here
    const contractingOfficeZipOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_zip");
    const contractingOfficeCountryOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_country"); //KO country needs to be converted to country BIT Master id
    const contractingOfficePOC1TextareaOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_poc1_textarea");
    const contractingOfficePOC2TextareaOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_poc2_textarea");
    const awardDateOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_award_date");
    const awardNumberOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_award_number");
    const contractOverallValueOpTblLatestIntValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_award_amount", true);
    const awardeeInformationOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_awardee_information");
    const descriptionOfWorkOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ce_description_plaintext");
    const gcssDocumentLinksJsonStringOpTblLatestStringValue = this.get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ce_document_links_json");

    //convert state/country string values into BIT Master tbl id raw values, also convert state values into province values if the state is not in the list of states
    var placeOfPerformanceProvinceOpTblLatestStringValue = ""; //initialize that there is no KO province value
    var placeOfPerformanceStateOpTblLatestBmStateIDOrUndefined = DatabaseMobx.get_bit_master_state_id_or_undefined_from_state_name(placeOfPerformanceStateOpTblLatestStringValue);
    if(placeOfPerformanceStateOpTblLatestBmStateIDOrUndefined === undefined) { //if the state had a string that did not match the list of US state strings, list it as a province
      placeOfPerformanceProvinceOpTblLatestStringValue = placeOfPerformanceStateOpTblLatestStringValue;

      //get the blank raw value for the KO state field
      placeOfPerformanceStateOpTblLatestBmStateIDOrUndefined = 0;
      var placeOfPerformanceStateFieldTypeObj = DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmStateID.get("fieldTypeObj"); //database blank value for single select int
      if(placeOfPerformanceStateFieldTypeObj !== undefined) {
        placeOfPerformanceStateOpTblLatestBmStateIDOrUndefined = placeOfPerformanceStateFieldTypeObj.blankValue;
      }
    }

    var placeOfPerformanceCountryOpTblLatestBmCountryID = DatabaseMobx.get_bit_master_country_id_or_undefined_from_country_name(placeOfPerformanceCountryOpTblLatestStringValue);
    if(placeOfPerformanceCountryOpTblLatestBmCountryID === undefined) {
      //get the blank raw value for the KO country field
      placeOfPerformanceCountryOpTblLatestBmCountryID = 0;
      var placeOfPerformanceCountryFieldTypeObj = DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmCountryID.get("fieldTypeObj"); //database blank value for single select int
      if(placeOfPerformanceCountryFieldTypeObj !== undefined) {
        placeOfPerformanceCountryOpTblLatestBmCountryID = placeOfPerformanceCountryFieldTypeObj.blankValue;
      }
    }

    var contractingOfficeProvinceOpTblLatestStringValue = ""; //initialize that there is no KO province value
    var contractingOfficeStateOpTblLatestBmStateIDOrUndefined = DatabaseMobx.get_bit_master_state_id_or_undefined_from_state_name(contractingOfficeStateOpTblLatestStringValue);
    if(contractingOfficeStateOpTblLatestBmStateIDOrUndefined === undefined) { //if the state had a string that did not match the list of US state strings, list it as a province
      contractingOfficeProvinceOpTblLatestStringValue = contractingOfficeStateOpTblLatestStringValue;

      //get the blank raw value for the KO state field
      contractingOfficeStateOpTblLatestBmStateIDOrUndefined = 0;
      var contractingOfficeStateFieldTypeObj = DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmStateID.get("fieldTypeObj"); //database blank value for single select int
      if(contractingOfficeStateFieldTypeObj !== undefined) {
        contractingOfficeStateOpTblLatestBmStateIDOrUndefined = contractingOfficeStateFieldTypeObj.blankValue;
      }
    }

    var contractingOfficeCountryOpTblLatestBmCountryID = DatabaseMobx.get_bit_master_country_id_or_undefined_from_country_name(contractingOfficeCountryOpTblLatestStringValue);
    if(contractingOfficeCountryOpTblLatestBmCountryID === undefined) {
      //get the blank raw value for the KO country field
      contractingOfficeCountryOpTblLatestBmCountryID = 0;
      var contractingOfficeCountryFieldTypeObj = DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmCountryID.get("fieldTypeObj"); //database blank value for single select int
      if(contractingOfficeCountryFieldTypeObj !== undefined) {
        contractingOfficeCountryOpTblLatestBmCountryID = contractingOfficeCountryFieldTypeObj.blankValue;
      }
    }

    //convert latest raw string values from op tbl to valueMaskSortIfoObjs based on each field type
    const samUrlMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(samUrlOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssSamLink);
    const solicitationNumberMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(solicitationNumberOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfSolicitationNumber);
    const officeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(officeOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssOffice);
    const rfpDateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(rfpDateOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfRFPDate);
    const govWinProcurementCommentMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(govWinProcurementCommentOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssGovWinProcurementComment);
    const periodOfPerformanceMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(periodOfPerformanceOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfPeriodOfPerformance);
    const govWinDurationMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(govWinDurationOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssGovWinDuration);
    const govWinPrimaryWorkRequiredMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(govWinPrimaryWorkRequiredOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssGovWinPrimaryWorkRequired);
    const govWinPriority1to5MaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(govWinPriority1to5OpTblLatestIntValue, undefined, DatabaseMobx.c_fieldMapOfGcssGovWinPriority1to5);
    const placeOfPerformanceAddressMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(placeOfPerformanceAddressOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceAddress);
    const placeOfPerformanceCityMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(placeOfPerformanceCityOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceCity);
    const placeOfPerformanceStateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(placeOfPerformanceStateOpTblLatestBmStateIDOrUndefined, undefined, DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma);
    const placeOfPerformanceZipMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(placeOfPerformanceZipOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceZip);
    const placeOfPerformanceProvinceMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(placeOfPerformanceProvinceOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceProvince);
    const placeOfPerformanceCountryMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(placeOfPerformanceCountryOpTblLatestBmCountryID, undefined, DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma);
    const contractingOfficeCityMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractingOfficeCityOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficeCity);
    const contractingOfficeStateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractingOfficeStateOpTblLatestBmStateIDOrUndefined, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmStateID);
    const contractingOfficeZipMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractingOfficeZipOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficeZip);
    const contractingOfficeProvinceMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractingOfficeProvinceOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficeProvince);
    const contractingOfficeCountryMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractingOfficeCountryOpTblLatestBmCountryID, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmCountryID);
    const contractingOfficePOC1TextareaMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractingOfficePOC1TextareaOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficePOC1Textarea);
    const contractingOfficePOC2TextareaMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractingOfficePOC2TextareaOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficePOC2Textarea);
    const awardDateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(awardDateOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfAwardDate);
    const awardNumberMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(awardNumberOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssAwardNumber);
    const contractOverallValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(contractOverallValueOpTblLatestIntValue, undefined, DatabaseMobx.c_fieldMapOfContractOverallValue);
    const awardeeInformationMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(awardeeInformationOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssAwardeeInformationTextarea);
    const descriptionOfWorkMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(descriptionOfWorkOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfDescriptionOfWork);
    const gcssDocumentLinksJsonStringMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(gcssDocumentLinksJsonStringOpTblLatestStringValue, undefined, DatabaseMobx.c_fieldMapOfGcssDocumentLinksJsonString);

    //compute history objs for op tbl fields not included in the gcss tbl row data
    expandedDetailSearchResultObj.samUrlHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_sam_url", DatabaseMobx.c_fieldMapOfGcssSamLink.get("display_name"), samUrlMaskSortIfoObj);
    expandedDetailSearchResultObj.solicitationNumberHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_solicitation_number", DatabaseMobx.c_fieldMapOfSolicitationNumber.get("display_name"), solicitationNumberMaskSortIfoObj);
    expandedDetailSearchResultObj.officeHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_office_name", DatabaseMobx.c_fieldMapOfGcssOffice.get("display_name"), officeMaskSortIfoObj);
    expandedDetailSearchResultObj.rfpDateHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoRFPDate", DatabaseMobx.c_fieldMapOfRFPDate.get("display_name"), rfpDateMaskSortIfoObj);
    expandedDetailSearchResultObj.govWinProcurementCommentHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinProcurementComment", DatabaseMobx.c_fieldMapOfGcssGovWinProcurementComment.get("display_name"), govWinProcurementCommentMaskSortIfoObj);
    expandedDetailSearchResultObj.periodOfPerformanceHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoPeriodOfPerformanceMonthsInt", DatabaseMobx.c_fieldMapOfPeriodOfPerformance.get("display_name"), periodOfPerformanceMaskSortIfoObj);
    expandedDetailSearchResultObj.govWinDurationHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinDuration", DatabaseMobx.c_fieldMapOfGcssGovWinDuration.get("display_name"), govWinDurationMaskSortIfoObj);
    expandedDetailSearchResultObj.govWinPrimaryWorkRequiredHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinPrimaryWorkRequired", DatabaseMobx.c_fieldMapOfGcssGovWinPrimaryWorkRequired.get("display_name"), govWinPrimaryWorkRequiredMaskSortIfoObj);
    expandedDetailSearchResultObj.govWinPriority1to5HistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rgoGovWinPriority1to5", DatabaseMobx.c_fieldMapOfGcssGovWinPriority1to5.get("display_name"), govWinPriority1to5MaskSortIfoObj);
    expandedDetailSearchResultObj.placeOfPerformanceAddressHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_address", DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceAddress.get("display_name"), placeOfPerformanceAddressMaskSortIfoObj);
    expandedDetailSearchResultObj.placeOfPerformanceCityHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_city", DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceCity.get("display_name"), placeOfPerformanceCityMaskSortIfoObj);
    expandedDetailSearchResultObj.placeOfPerformanceStateHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_state", DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma.get("display_name"), placeOfPerformanceStateMaskSortIfoObj);
    expandedDetailSearchResultObj.placeOfPerformanceZipHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_zip", DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceZip.get("display_name"), placeOfPerformanceZipMaskSortIfoObj);
    expandedDetailSearchResultObj.placeOfPerformanceProvinceHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceProvince.get("display_name"), placeOfPerformanceProvinceMaskSortIfoObj);
    expandedDetailSearchResultObj.placeOfPerformanceCountryHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_pop_country", DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma.get("display_name"), placeOfPerformanceCountryMaskSortIfoObj);
    expandedDetailSearchResultObj.contractingOfficeCityHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_city", DatabaseMobx.c_fieldMapOfGcssContractingOfficeCity.get("display_name"), contractingOfficeCityMaskSortIfoObj);
    expandedDetailSearchResultObj.contractingOfficeStateHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_state", DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmStateID.get("display_name"), contractingOfficeStateMaskSortIfoObj);
    expandedDetailSearchResultObj.contractingOfficeZipHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_zip", DatabaseMobx.c_fieldMapOfGcssContractingOfficeZip.get("display_name"), contractingOfficeZipMaskSortIfoObj);
    expandedDetailSearchResultObj.contractingOfficeProvinceHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, undefined, DatabaseMobx.c_fieldMapOfGcssContractingOfficeProvince.get("display_name"), contractingOfficeProvinceMaskSortIfoObj);
    expandedDetailSearchResultObj.contractingOfficeCountryHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_country", DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmCountryID.get("display_name"), contractingOfficeCountryMaskSortIfoObj);
    expandedDetailSearchResultObj.contractingOfficePOC1TextareaHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_poc1_textarea", DatabaseMobx.c_fieldMapOfGcssContractingOfficePOC1Textarea.get("display_name"), contractingOfficePOC1TextareaMaskSortIfoObj);
    expandedDetailSearchResultObj.contractingOfficePOC2TextareaHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ko_poc2_textarea", DatabaseMobx.c_fieldMapOfGcssContractingOfficePOC2Textarea.get("display_name"), contractingOfficePOC2TextareaMaskSortIfoObj);
    expandedDetailSearchResultObj.awardDateHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_award_date", DatabaseMobx.c_fieldMapOfAwardDate.get("display_name"), awardDateMaskSortIfoObj);
    expandedDetailSearchResultObj.awardNumberHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_award_number", DatabaseMobx.c_fieldMapOfGcssAwardNumber.get("display_name"), awardNumberMaskSortIfoObj);
    expandedDetailSearchResultObj.contractOverallValueHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_award_amount", DatabaseMobx.c_fieldMapOfContractOverallValue.get("display_name"), contractOverallValueMaskSortIfoObj);
    expandedDetailSearchResultObj.awardeeInformationHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_awardee_information", DatabaseMobx.c_fieldMapOfGcssAwardeeInformationTextarea.get("display_name"), awardeeInformationMaskSortIfoObj);
    expandedDetailSearchResultObj.descriptionOfWorkHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ce_description_plaintext", DatabaseMobx.c_fieldMapOfDescriptionOfWork.get("display_name"), descriptionOfWorkMaskSortIfoObj);
    expandedDetailSearchResultObj.gcssDocumentLinksJsonStringHistoryObj = this.create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, numOpTblDataRows, "rso_ce_document_links_json", DatabaseMobx.c_fieldMapOfGcssDocumentLinksJsonString.get("display_name"), gcssDocumentLinksJsonStringMaskSortIfoObj);

    return(expandedDetailSearchResultObj);
  }

  get_op_tbl_single_field_latest_nonblank_string_value(i_opTblDataRowsArrayOfObjs, i_numOpTblDataRows, i_opTblColumnDbName, i_opColumnTypeIntTrueStringFalse=false) {
    //initialize blankValue for this field if there is no history or non NULL value found
    var opTblLatestNonblankStringValue = "";
    if(i_opColumnTypeIntTrueStringFalse) {
      opTblLatestNonblankStringValue = 0;
    }

    for(let r = 0; r < i_numOpTblDataRows; r++) { //loops through op history rows from oldest (index 0) to newest (index N-1)
      var currentOpHistoryFieldValue = i_opTblDataRowsArrayOfObjs[r][i_opTblColumnDbName];
      if(JSFUNC.is_string(currentOpHistoryFieldValue) || JSFUNC.is_number(currentOpHistoryFieldValue)) { //if the value is a string or a number (basically not NULL)
        if(currentOpHistoryFieldValue !== opTblLatestNonblankStringValue) {
          opTblLatestNonblankStringValue = currentOpHistoryFieldValue;
        }
      }
    }
    return(opTblLatestNonblankStringValue);
  }

  create_gcss_expanded_detail_search_result_single_field_history_obj(i_opTblDataRowsArrayOfObjs, i_numOpTblDataRows, i_opTblColumnDbNameOrUndefined, i_fieldDisplayName, i_precomputedGcssFieldValueMaskSortIfoObj) {
    var historyArrayOfObjs = [];
    if(i_opTblColumnDbNameOrUndefined !== undefined) { //only fill out the history if a valid column in the op tbl is specified (rso_original_published_datetime_utc doesn't need history as it always remains the same)
      if(i_numOpTblDataRows > 1) { //only need to search for history if there are more than 1 row of op history data
        var previousOpHistoryFieldValue = undefined;
        for(let r = 0; r < i_numOpTblDataRows; r++) { //loops through op history rows from oldest (index 0) to newest (index N-1)
          var currentOpHistoryFieldValue = i_opTblDataRowsArrayOfObjs[r][i_opTblColumnDbNameOrUndefined];
          if(JSFUNC.string_is_filled_out_tf(currentOpHistoryFieldValue) || JSFUNC.is_number(currentOpHistoryFieldValue)) {
            if(currentOpHistoryFieldValue !== previousOpHistoryFieldValue) {
              var rawSamOppOriginalPublishedDateTimeUtc = i_opTblDataRowsArrayOfObjs[r]["rso_original_published_datetime_utc"];
              historyArrayOfObjs.push({
                rawSamOppOriginalPublishedDateTimeUtc: rawSamOppOriginalPublishedDateTimeUtc,
                valueMaskPlainText: currentOpHistoryFieldValue
              });
              previousOpHistoryFieldValue = currentOpHistoryFieldValue;
            }
          }
        }

        //if there is only 1 entry in the history list, it has not changed since its initial value, so empty out the history array
        if(historyArrayOfObjs.length <= 1) {
          historyArrayOfObjs = [];
        }
        else { //if there are at least 2 history entries, sort them by date desc
          JSFUNC.sort_arrayOfObjs(historyArrayOfObjs, "rawSamOppOriginalPublishedDateTimeUtc", false);
        }
      }
    }

    const hasHistoryTF = (historyArrayOfObjs.length > 0);

    return({
      fieldDisplayName: i_fieldDisplayName,
      currentValueMaskSortIfoObj: i_precomputedGcssFieldValueMaskSortIfoObj,
      historyArrayOfObjs: historyArrayOfObjs,
      hasHistoryTF: hasHistoryTF
    });
  }




  //========================================================================================
  //action methods
  a_load_gcss_tab_tbls_to_local_memory_if_not_yet_loaded() {
    //if this GCSS tbl data has not been loaded yet for this user during this session
    if(!this.o_gcssTabTblsHaveBeenLoadedIntoLocalMemoryTF) {
      //set that the data has been loaded so that it only happens the first time the GCSS tab is opened
      this.o_gcssTabTblsHaveBeenLoadedIntoLocalMemoryTF = true;

      //load the data (all not interested markings for all company users)
      JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(DatabaseMobx.c_gcssTabTblNamesToLoadArray);
    }
  }


  a_set_auto_search_panel_is_expanded_tf(i_newValueTF) {
    this.o_autoSearchPanelIsExpandedTF = i_newValueTF;
  }


  a_open_gcss_panel() {
    this.o_manualSearchIsOpenTF = true;
    this.a_set_open_search_result_detail_gcss_id_or_undefined(undefined);
  }


  a_close_gcss_search_panel() {
    this.o_manualSearchIsOpenTF = false;
  }

  a_close_any_open_gcss_panel() {
    this.a_close_gcss_search_panel();
    this.a_set_open_search_result_detail_gcss_id_or_undefined(undefined);
  }


  a_clear_previous_search_results_to_init_state() {
    //close a single search result detail panel if one is open
    this.a_set_open_search_result_detail_gcss_id_or_undefined(undefined);

    //clear previous search results
    this.a_set_selected_auto_search_id_or_undefined(undefined, false);
    this.a_set_search_results_error_message("");
    this.a_set_total_num_results_matching_search(0);
    this.a_set_search_results_arrayOfObjs([]);

    //reset GCSS search results back to init state
    this.a_set_search_results_loading_flag("init");
  }


  a_set_manual_search_local_selected_filter_value(i_autoSearchDbName, i_newValue) {
    this.o_manualSearchLocalSelectedFilterValuesMap.set(i_autoSearchDbName, i_newValue);

    //if the default import capture type field was changed, set the default import stage to the first stage in that capture type
    if(i_autoSearchDbName === "import_default_capture_type_id") {
      const updatedImportDefaultCaptureTypeFirstStageID = DatabaseMobx.get_first_stage_id_or_undefined_from_capture_type_id(i_newValue);
      this.o_manualSearchLocalSelectedFilterValuesMap.set("import_default_stage_id", updatedImportDefaultCaptureTypeFirstStageID);
    }

    //any change to any filter sets the unsaved changes flag
    this.a_set_selected_auto_search_has_unsaved_changes_tf(true);
  }


  a_fetch_gcss_tbl_search_results_from_current_filter_settings(i_numResultsToFetch=false, i_fetchOffset=false, i_appendResultsTrueOverwriteResultsFalse=false) {
    this.a_fetch_gcss_tbl_search_results_from_search_fields_with_values_obj(this.c_searchFieldsWithValuesObj, i_numResultsToFetch, i_fetchOffset, i_appendResultsTrueOverwriteResultsFalse);
  }


  a_manual_search_clear_all_filter_values() {
    const o_userID = UserMobx.o_userID;
    const userDivisionID = UserMobx.c_combinedUserObj.division_id;
    const c_firstCaptureTypeIDOrUndefined = DatabaseMobx.c_firstCaptureTypeIDOrUndefined;

    //clear all filter values
    this.o_manualSearchLocalSelectedFilterValuesMap = new Map();

    //initialize default values for checkboxes
    this.o_manualSearchLocalSelectedFilterValuesMap.set("include_already_in_ce_results_01", 1);
    this.o_manualSearchLocalSelectedFilterValuesMap.set("include_not_interested_results_01", 1);

    //initialize default values for the import defaults
    var initialImportDefaultCaptureManagersIDsColonPercentComma = undefined;
    var initialImportDefaultDivsionOwnersIDsColonPercentComma = undefined;
    var initialImportDefaultCaptureTypeID = undefined;
    var initialImportDefaultStageID = undefined;

    if(DatabaseMobx.user_is_filled_out_tf_from_user_id(o_userID)) {
      initialImportDefaultCaptureManagersIDsColonPercentComma = o_userID + ":100";
    }

    if(DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(userDivisionID, DatabaseMobx.c_selectDivisionFieldTypeObj)) {
      initialImportDefaultDivsionOwnersIDsColonPercentComma = userDivisionID + ":100";
    }

    if(c_firstCaptureTypeIDOrUndefined !== undefined) {
      initialImportDefaultCaptureTypeID = c_firstCaptureTypeIDOrUndefined;
      initialImportDefaultStageID = DatabaseMobx.get_first_stage_id_or_undefined_from_capture_type_id(c_firstCaptureTypeIDOrUndefined); //first stage within first capture type
    }

    this.o_manualSearchLocalSelectedFilterValuesMap.set("import_default_capture_managers_ids_colon_percent_comma", initialImportDefaultCaptureManagersIDsColonPercentComma);
    this.o_manualSearchLocalSelectedFilterValuesMap.set("import_default_division_owners_ids_colon_percent_comma", initialImportDefaultDivsionOwnersIDsColonPercentComma);
    this.o_manualSearchLocalSelectedFilterValuesMap.set("import_default_capture_type_id", initialImportDefaultCaptureTypeID);
    this.o_manualSearchLocalSelectedFilterValuesMap.set("import_default_stage_id", initialImportDefaultStageID);
  }


  a_create_new_auto_search_or_save_existing_auto_search(i_createNewAutoSearchTrueUpdateExistingFalse, i_newAutoSearchNameOrExistingAutoSearchID) {
    const o_manualSearchLocalSelectedFilterValuesMap = this.o_manualSearchLocalSelectedFilterValuesMap;
    const c_autoSearchImportDefaultFieldsObjOfObjsByAutoSearchDbName = this.c_autoSearchImportDefaultFieldsObjOfObjsByAutoSearchDbName;
    const c_allSearchFiltersWithValuesArrayOfObjs = this.c_allSearchFiltersWithValuesArrayOfObjs;
    const c_autoSearchesArrayOfObjs = this.c_autoSearchesArrayOfObjs;
    const o_userPerEmailID = UserMobx.o_userPerEmailID;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;

    const autoSearchCheckboxDbNamesArray = ["include_already_in_ce_results_01", "include_not_interested_results_01"];
    const autoSearchImportDefaultDbNamesArray = ["import_default_capture_managers_ids_colon_percent_comma", "import_default_division_owners_ids_colon_percent_comma", "import_default_capture_type_id", "import_default_stage_id"];

    const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_create_new_auto_search_or_save_existing_auto_search", ["i_createNewAutoSearchTrueUpdateExistingFalse", "i_newAutoSearchNameOrExistingAutoSearchID"], [i_createNewAutoSearchTrueUpdateExistingFalse, i_newAutoSearchNameOrExistingAutoSearchID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //initialize arrays for insert/update
    var fieldNamesArray = [];
    var valuesArray = [];
    var idsbArray = [];

    //add constant auto search db fields
    if(i_createNewAutoSearchTrueUpdateExistingFalse) {
      const newSortNum = (c_autoSearchesArrayOfObjs.length + 1);
      fieldNamesArray = ["user_per_email_id", "auto_search_sam0_govwin1", "name", "sort", "cron_last_fetch_datetime_utc", "cron_days_betweeen_fetches_or_off_m1"];
      valuesArray = [o_userPerEmailID, c_userGcssSam0GovWin1, i_newAutoSearchNameOrExistingAutoSearchID, newSortNum, JSFUNC.blank_datetime(), -1];
      idsbArray = ["i", "i", "s", "i", "s", "i"];
    }

    //add checkboxes
    for(let autoSearchCheckboxDbName of autoSearchCheckboxDbNamesArray) {
      var checkboxValue01 = ((o_manualSearchLocalSelectedFilterValuesMap.get(autoSearchCheckboxDbName) === 1) ? (1) : (0));
      fieldNamesArray.push(autoSearchCheckboxDbName);
      valuesArray.push(checkboxValue01);
      idsbArray.push("i");
    }

    //add filter fields
    for(let filterWithValueObj of c_allSearchFiltersWithValuesArrayOfObjs) {
      fieldNamesArray.push(filterWithValueObj.autoSearchDbName);
      valuesArray.push(filterWithValueObj.selectedFilterValue);
      idsbArray.push(filterWithValueObj.fieldTypeObj.idsb);
    }

    //add default import fields
    for(let autoSearchImportDefaultDbName of autoSearchImportDefaultDbNamesArray) {
      var fieldTypeObj = c_autoSearchImportDefaultFieldsObjOfObjsByAutoSearchDbName[autoSearchImportDefaultDbName].fieldTypeObj;
      var selectedImportDefaultValue = o_manualSearchLocalSelectedFilterValuesMap.get(autoSearchImportDefaultDbName);
      if(selectedImportDefaultValue === undefined) {
        selectedImportDefaultValue = fieldTypeObj.blankValue;
      }

      fieldNamesArray.push(autoSearchImportDefaultDbName);
      valuesArray.push(selectedImportDefaultValue);
      idsbArray.push(fieldTypeObj.idsb);
    }

    //insert or update the auto search record
    if(i_createNewAutoSearchTrueUpdateExistingFalse) {
      const resortSortColumnName = "sort";
      const resortFilterFieldNamesArray = ["user_per_email_id", "auto_search_sam0_govwin1"];
      const resortFilterValuesArray = [o_userPerEmailID, c_userGcssSam0GovWin1];
      C_CallPhpTblUID.add_insert("tbl_u_gcss_auto_searches", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNamesArray, resortFilterValuesArray);

      //after successful insert select the newly created auto search button
      const functionOnSuccess = (i_parseResponse) => {
        const newlyInsertedGcssAutoSearchID = i_parseResponse.outputObj.i0;
        this.a_set_selected_auto_search_id_or_undefined(newlyInsertedGcssAutoSearchID, false);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }
    else {
      C_CallPhpTblUID.add_update("tbl_u_gcss_auto_searches", i_newAutoSearchNameOrExistingAutoSearchID, fieldNamesArray, valuesArray, idsbArray);
    }

    C_CallPhpTblUID.execute();

    //reset the autosearch flag for unsaved changes
    this.a_set_selected_auto_search_has_unsaved_changes_tf(false);
  }


  //auto search
  a_set_selected_auto_search_id_or_undefined(i_newValue, i_fetchGcssTblSearchResultsTF) {
    //set that the autosearch id is selected
    this.o_selectedAutoSearchIDOrUndefined = i_newValue;

    //copy all values from saved auto search into manual search (or overwrite with reset blank filters if undefined is provided)
    var updatedLocalSelectedFilterValuesMap = new Map();
    if(i_newValue !== undefined) {
      const selectedAutoSearchMap = DatabaseMobx.o_tbl_u_gcss_auto_searches.get(i_newValue);
      if(selectedAutoSearchMap !== undefined) {
        updatedLocalSelectedFilterValuesMap = JSFUNC.copy_map(selectedAutoSearchMap);

        if(i_fetchGcssTblSearchResultsTF) {
          //craete an allFiltersWithValuesArrayOfObjs from the selected autosearch map (even though this is recomputed for the computed variable), so that these values are current to avoid relying on the computed value being available for this search
          const selectedAutoSearchFieldsWithValuesObj = this.compute_search_fields_with_values_obj_from_search_filter_values_map(selectedAutoSearchMap);

          //call to fetch search results from the autosearch settings
          const numResultsToFetch = false; //use default value for SAM/GovWin
          const fetchOffset = false; //default 0 offset
          const appendResultsTrueOverwriteResultsFalse = false; //overwrite previous results with newly fetched results of this autosearch
          this.a_fetch_gcss_tbl_search_results_from_search_fields_with_values_obj(selectedAutoSearchFieldsWithValuesObj, numResultsToFetch, fetchOffset, appendResultsTrueOverwriteResultsFalse);
        }
      }
    }
    this.o_manualSearchLocalSelectedFilterValuesMap = updatedLocalSelectedFilterValuesMap;
  }


  a_set_selected_auto_search_has_unsaved_changes_tf(i_newValueTF) {
    this.o_selectedAutoSearchHasUnsavedChangesTF = i_newValueTF;
  }


  a_edit_auto_search_from_id(i_autoSearchIDToEdit) {
    this.a_open_gcss_panel();
    this.a_set_selected_auto_search_id_or_undefined(i_autoSearchIDToEdit, false);
    this.a_set_selected_auto_search_has_unsaved_changes_tf(false);
  }


  a_rename_auto_search_from_id_old_name_and_updated_name(i_autoSearchIDToRename, i_oldName, i_updatedName) {
    const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_rename_auto_search_from_id_and_updated_name", ["i_autoSearchIDToRename", "i_oldName", "i_updatedName"], [i_autoSearchIDToRename, i_oldName, i_updatedName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_u_gcss_auto_searches", i_autoSearchIDToRename, "name", i_updatedName, "s", i_oldName);
    C_CallPhpTblUID.execute();
  }


  a_delete_auto_search_from_id(i_autoSearchIDToDelete) {
    const o_selectedAutoSearchIDOrUndefined = this.o_selectedAutoSearchIDOrUndefined;
    const o_userPerEmailID = UserMobx.o_userPerEmailID;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;

    //if deleting the auto search that is currently selected, unselect it
    if(i_autoSearchIDToDelete === o_selectedAutoSearchIDOrUndefined) {
      this.a_set_selected_auto_search_id_or_undefined(undefined, false);
      this.a_set_search_results_error_message("");
      this.a_set_total_num_results_matching_search(0);
      this.a_set_search_results_arrayOfObjs([]);
    }

    //reset the unsaved changes flag
    this.a_set_selected_auto_search_has_unsaved_changes_tf(false);

    //delete the single auto search by id, then resort the autosearches for this user under this data source
    const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_delete_auto_search_from_id", ["i_autoSearchIDToDelete"], [i_autoSearchIDToDelete]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_u_gcss_auto_searches", i_autoSearchIDToDelete, "sort", ["user_per_email_id", "auto_search_sam0_govwin1"], [o_userPerEmailID, c_userGcssSam0GovWin1]);
    C_CallPhpTblUID.execute();
  }


  //search results
  a_fetch_gcss_tbl_search_results_from_search_fields_with_values_obj(i_searchFieldsWithValuesObj, i_numResultsToFetch=false, i_fetchOffset=false, i_appendResultsTrueOverwriteResultsFalse=false) {
    const c_gcssAlreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray = this.c_gcssAlreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray;
    const c_selectedAutoSearchObjOrUndefined = this.c_selectedAutoSearchObjOrUndefined;
    const c_tbl_bit_master_sub_tiers = DatabaseMobx.c_tbl_bit_master_sub_tiers;
    const c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray = DatabaseMobx.c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray;
    const o_userID = UserMobx.o_userID;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;
    const c_userGcssDataSource0SamTF = UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = UserMobx.c_userGcssDataSource2FedCompTF;

    const includeAlreadyInCEResultsCheckedTF = i_searchFieldsWithValuesObj.includeAlreadyInCEResultsCheckedTF;
    const includeNotInterestedResultsCheckedTF = i_searchFieldsWithValuesObj.includeNotInterestedResultsCheckedTF;
    const boxesOfBubblesOfFiltersWithValuesArrayOfObjs = i_searchFieldsWithValuesObj.boxesOfBubblesOfFiltersWithValuesArrayOfObjs;

    this.a_set_search_results_loading_flag("loading");
    this.a_set_search_results_error_message("");
    this.a_set_selected_search_results_tab_db_name("all"); //force you back to the All tab when conducting a search
    this.a_set_current_page_number(1); //reset current page back to page 1

    const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_fetch_gcss_tbl_search_results_from_search_fields_with_values_obj", ["i_searchFieldsWithValuesObj", "i_numResultsToFetch", "i_fetchOffset", "i_appendResultsTrueOverwriteResultsFalse"], [i_searchFieldsWithValuesObj, i_numResultsToFetch, i_fetchOffset, i_appendResultsTrueOverwriteResultsFalse]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("gcssSearchOppsFromDataSource", jsDescription);

    var gcssNumResultsToFetch = 1;
    if(JSFUNC.is_number(i_numResultsToFetch) && (i_numResultsToFetch >= 0)) {
      gcssNumResultsToFetch = i_numResultsToFetch;
    }
    else { //default SAM/GovWin fetch batch sizes
      if(c_userGcssDataSource0SamTF) { gcssNumResultsToFetch = 1000; }
      else if(c_userGcssDataSource1GovWinTF) { gcssNumResultsToFetch = 50; }
      else if(c_userGcssDataSource2FedCompTF) { gcssNumResultsToFetch = 50; }
    }

    var gcssFetchOffset = 0; //default SAM/GovWin fetch offset is 0
    if(JSFUNC.is_number(i_fetchOffset) && (i_fetchOffset >= 0)) {
      gcssFetchOffset = i_fetchOffset;
    }

    C_CallPhpScript.add_post_var("i_userID", o_userID);
    C_CallPhpScript.add_post_var("i_gcssDataSourceSam0GovWin1", c_userGcssSam0GovWin1);
    C_CallPhpScript.add_post_var("i_gcssNumResultsToFetch", gcssNumResultsToFetch);
    C_CallPhpScript.add_post_var("i_gcssFetchOffset", gcssFetchOffset);

    //(SAM only) i_gcssIDsToNotIncludeComma add which gcssIDs to not include in the returned search results based on if the 2 checkboxes are checked
    if(c_userGcssDataSource0SamTF) {
      var gcssIDsToNotIncludeArray = [];
      if(!includeAlreadyInCEResultsCheckedTF) {
        gcssIDsToNotIncludeArray = c_gcssAlreadyInCEImportedCapturesSamGcssIDsOrGovWinIDStringsArray;
      }

      if(!includeNotInterestedResultsCheckedTF) {
        gcssIDsToNotIncludeArray = JSFUNC.concat_arrays_or_values_into_new_array(gcssIDsToNotIncludeArray, c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray);
      }

      if(gcssIDsToNotIncludeArray.length > 0) {
        gcssIDsToNotIncludeArray = JSFUNC.unique(gcssIDsToNotIncludeArray);
        const gcssIDsToNotIncludeComma = JSFUNC.convert_array_to_comma_list(gcssIDsToNotIncludeArray);
        C_CallPhpScript.add_post_var("i_gcssIDsToNotIncludeComma", gcssIDsToNotIncludeComma);
      }
    }

    //loop through each filter and add ones that are filled out
    for(let boxOfBubblesOfFiltersWithValuesObj of boxesOfBubblesOfFiltersWithValuesArrayOfObjs) {
      for(let bubbleOfFiltersWithValuesObj of boxOfBubblesOfFiltersWithValuesObj.bubblesOfFiltersWithValuesArrayofObjs) {
        for(let filterWithValueObj of bubbleOfFiltersWithValuesObj.filtersWithValuesArrayOfObjs) {
          if(filterWithValueObj.filterValueMaskSortIfoObj.isFilledOutTF) {
            var modifiedSearchPostVarName = filterWithValueObj.searchPostVarName;
            var modifiedSelectedFilterValue = filterWithValueObj.selectedFilterValue;

            //for relative dates, convert the relative date to a fixed date
            if(filterWithValueObj.fieldTypeObj.dateWithRelativeDateTF) {
              modifiedSelectedFilterValue = JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(filterWithValueObj.selectedFilterValue);
            }

            //(only for SAM database for datetimes) for min/max dates, set min to local time midnight and set max to local time 1 min before midnight at the end of the day
            if(c_userGcssDataSource0SamTF) {
              if(filterWithValueObj.isMinDateTF) {
                //modifiedSelectedFilterValue = JSFUNC.convert_local_dateYmd_hours0to23_minutes_seconds_to_mysqldatetimeutc(modifiedSelectedFilterValue, 0, 0, 0);
                modifiedSelectedFilterValue = modifiedSelectedFilterValue + " 00:00:00";
              }
              else if(filterWithValueObj.isMaxDateTF) {
                //modifiedSelectedFilterValue = JSFUNC.convert_local_dateYmd_hours0to23_minutes_seconds_to_mysqldatetimeutc(modifiedSelectedFilterValue, 23, 59, 59);
                modifiedSelectedFilterValue = modifiedSelectedFilterValue + " 23:59:59";
              }
            }

            //(if requiresSAMSubTierDuplicatesForceMultiSelectTF) for SAM if SubTier is selected, find any entries that are duplicates to the selected entry and force them into the subtierIDs sent to the php search
            if(filterWithValueObj.requiresSAMSubTierDuplicatesForceMultiSelectTF) {
              if(c_userGcssDataSource0SamTF) {
                var selectedFilterBmSubTierIDsArray = JSFUNC.convert_comma_list_to_int_array(filterWithValueObj.selectedFilterValue);
                var duplicateSubTierIDsToIncludeArray = [];
                for(let bmSubTierID of selectedFilterBmSubTierIDsArray) {
                  for(let subTierMap of c_tbl_bit_master_sub_tiers.values()) {
                    if(bmSubTierID === subTierMap.get("duplicate_map_to_other_id_or_0")) {
                      duplicateSubTierIDsToIncludeArray.push(subTierMap.get("id"));
                    }
                  }
                }

                for(let duplicateSubTierID of duplicateSubTierIDsToIncludeArray) {
                  selectedFilterBmSubTierIDsArray.push(duplicateSubTierID);
                }

                modifiedSelectedFilterValue = JSFUNC.convert_array_to_comma_list(selectedFilterBmSubTierIDsArray);
              }
            }

            //(if requiresContractOpportunityTypesTranslationFromBitMasterIDsTF) for FedComp if contract opportunity types are selected, translated the BIT Master contract opportunity type ids into a vert bar space list of type strings
            if(filterWithValueObj.requiresContractOpportunityTypesTranslationFromBitMasterIDsTF) {
              if(c_userGcssDataSource2FedCompTF) {
                var selectedFilterBmContractOpportunityTypeIDsArray = JSFUNC.convert_comma_list_to_int_array(filterWithValueObj.selectedFilterValue); //SAM contract opportunity type filter raw value is bm ids comma like "1,22,16"
                var selectedFilterContractOpportunityTypeNamesArray = JSFUNC.get_array_of_map_column_values_from_ids_array_if_exist(DatabaseMobx.o_tbl_bit_master_contract_opportunity_types, selectedFilterBmContractOpportunityTypeIDsArray, "name");
                modifiedSearchPostVarName = "i_filterFedCompContractOpportunityTypesVertBarSpace"; //postVarName for contract opportunity types for SAM is i_filterContractOpportunityTypeBmIDsComma, but is changed for fedcomp conversions
                modifiedSelectedFilterValue = JSFUNC.convert_array_to_list_string_with_separator(selectedFilterContractOpportunityTypeNamesArray, "| "); //FedComp convert bm ids to names with "| " between like "Presolicitation| Sources Sought| Modification/Amendment"
              }
            }

            //(if requiresFedCompContractTypesTranslationFromBitMasterIDsTF) for FedComp if fedcomp contract types are selected, translated the BIT Master fedcomp contract type ids into a vert bar space list of type strings
            if(filterWithValueObj.requiresFedCompContractTypesTranslationFromBitMasterIDsTF) {
              if(c_userGcssDataSource2FedCompTF) {
                var selectedFilterBmFedCompContractTypeIDsArray = JSFUNC.convert_comma_list_to_int_array(filterWithValueObj.selectedFilterValue); //fedcomp BM filter raw value is bm ids comma like "1,5,2"
                var selectedFilterFedCompContractTypeNamesArray = JSFUNC.get_array_of_map_column_values_from_ids_array_if_exist(DatabaseMobx.o_tbl_bit_master_fedcomp_contract_types, selectedFilterBmFedCompContractTypeIDsArray, "name");
                modifiedSearchPostVarName = "i_filterFedCompFedCompContractTypesVertBarSpace";
                modifiedSelectedFilterValue = JSFUNC.convert_array_to_list_string_with_separator(selectedFilterFedCompContractTypeNamesArray, "| "); //FedComp convert bm ids to names with "| " between like "Opportunity| Potential Task Order Recompete| Task Order Opportunity"
              }
            }

            //(if requiresNaicsCodesTranslationFromBitMasterIDsTF) for GovWin/FedComp if naics codes are selected, translated the BIT Master naics ids into a comma list of actual naics codes
            if(filterWithValueObj.requiresNaicsCodesTranslationFromBitMasterIDsTF) {
              var selectedFilterBmNaicsCodeIDsArray = JSFUNC.convert_comma_list_to_int_array(filterWithValueObj.selectedFilterValue); //SAM naics filter raw value is bm ids comma like "63,1022,753"
              var selectedFilterNaicsCodesArray = JSFUNC.get_array_of_map_column_values_from_ids_array_if_exist(DatabaseMobx.c_tbl_bit_master_naics_codes, selectedFilterBmNaicsCodeIDsArray, "code");
              if(c_userGcssDataSource1GovWinTF) {
                modifiedSearchPostVarName = "i_filterGovWinNaicsCodesComma"; //postVarName for naics for SAM is i_filterNaicsCodeBmIDsComma, but is changed for govwin/fedcomp conversions
                modifiedSelectedFilterValue = JSFUNC.convert_array_to_comma_list(selectedFilterNaicsCodesArray); //GovWin convert bm ids to codes comma list like "541330,213212,541215"
              }
              else if(c_userGcssDataSource2FedCompTF) {
                modifiedSearchPostVarName = "i_filterFedCompNaicsCodesVertBarSpace"; //postVarName for naics for SAM is i_filterNaicsCodeBmIDsComma, but is changed for govwin/fedcomp conversions
                modifiedSelectedFilterValue = JSFUNC.convert_array_to_list_string_with_separator(selectedFilterNaicsCodesArray, "| "); //FedComp convert bm ids to codes with "| " between like "541330| 213212| 541215"
              }
            }

            //(if requiresProductServiceCodesTranslationFromBitMasterIDsTF) for FedComp if psc codes are selected, translated the BIT Master psc ids into a comma list of actual psc codes
            if(filterWithValueObj.requiresProductServiceCodesTranslationFromBitMasterIDsTF) {
              if(c_userGcssDataSource2FedCompTF) {
                var selectedFilterBmProductServiceCodeIDsArray = JSFUNC.convert_comma_list_to_int_array(filterWithValueObj.selectedFilterValue); //SAM psc filter raw value is bm ids comma like "27,943,650"
                var selectedFilterProductServiceCodesArray = JSFUNC.get_array_of_map_column_values_from_ids_array_if_exist(DatabaseMobx.o_tbl_bit_master_product_service_codes, selectedFilterBmProductServiceCodeIDsArray, "code");
                modifiedSearchPostVarName = "i_filterFedCompProductServiceCodesVertBarSpace"; //postVarName for psc for SAM is i_filterProductServiceCodeBmIDsComma, but is changed for fedcomp conversions
                modifiedSelectedFilterValue = JSFUNC.convert_array_to_list_string_with_separator(selectedFilterProductServiceCodesArray, "| "); //FedComp convert bm ids to codes with "| " between like "AX23| 1142| Y26D"
              }
            }

            //(if requiresFedCompSetAsidesTranslationFromBitMasterIDsTF) for FedComp if set-asides are selected, translate the BIT Master set-aside ids into a comma list of (overlapping) FedComp specific set-aside options
            if(filterWithValueObj.requiresFedCompSetAsidesTranslationFromBitMasterIDsTF) {
              if(c_userGcssDataSource2FedCompTF) {
                var selectedFilterBmSetAsideIDsArray = JSFUNC.convert_comma_list_to_int_array(filterWithValueObj.selectedFilterValue); //SAM psc filter raw value is bm ids comma like "27,943,650"
                var selectedFilterSetAsideFedCompNamesArray = JSFUNC.get_array_of_map_column_values_from_ids_array_if_exist(DatabaseMobx.o_tbl_bit_master_set_asides, selectedFilterBmSetAsideIDsArray, "fedcomp_name");
                selectedFilterSetAsideFedCompNamesArray = JSFUNC.remove_all_values_from_array("", selectedFilterSetAsideFedCompNamesArray);
                selectedFilterSetAsideFedCompNamesArray = JSFUNC.unique(selectedFilterSetAsideFedCompNamesArray); //merge together all overlapping fedcomp set-aside strings that are not unique from larger SAM/GovWin BIT Master values list
                modifiedSearchPostVarName = "i_filterFedCompSetAsidesVertBarSpace"; //postVarName for psc for SAM is i_filterSetAsideBmIDsComma, but is changed for fedcomp conversions
                modifiedSelectedFilterValue = JSFUNC.convert_array_to_list_string_with_separator(selectedFilterSetAsideFedCompNamesArray, "| "); //FedComp convert bm ids to codes with "| " between like "AX23| 1142| Y26D"
              }
            }

            C_CallPhpScript.add_post_var(modifiedSearchPostVarName, modifiedSelectedFilterValue);
          }
        }
      }
    }

    C_CallPhpScript.add_return_vars(["totalNumResultsMatchingSearch", "gcssSearchResultsMatrix", "gcssSearchResultsErrorMessage"]);

    const functionOnSuccess = (i_parseResponse) => {
      this.a_set_search_results_loading_flag("loaded");

      if(JSFUNC.string_is_filled_out_tf(i_parseResponse.gcssSearchResultsErrorMessage)) {
        this.a_set_search_results_error_message(i_parseResponse.gcssSearchResultsErrorMessage);
      }
      else {
        this.a_set_total_num_results_matching_search(i_parseResponse.totalNumResultsMatchingSearch);
        
        //extract the search results matrix from the php response
        var gcssSearchResultsMatrix = i_parseResponse.gcssSearchResultsMatrix;

        //for GovWin/FedComp convert some data from specialized return fields into BIT Master ids (prevents doing these BIT master lookups on the server)
        if(c_userGcssDataSource1GovWinTF || c_userGcssDataSource2FedCompTF) {
          var convertedGovWinSearchResultsMatrix = []; //initialize a converted search results matrix that will overwrite the original search results matrix
          for(let gcssSearchResultObj of gcssSearchResultsMatrix) { //loop over each fetched govwin/fedcomp opportunity
            var convertedGovWinSearchResultObj = this.create_gcss_sam_expanded_search_result_obj_from_govwin_fedcomp_raw_search_result_obj(gcssSearchResultObj, c_userGcssSam0GovWin1);
            convertedGovWinSearchResultsMatrix.push(convertedGovWinSearchResultObj);
          }
          gcssSearchResultsMatrix = convertedGovWinSearchResultsMatrix;
        }

        //determine if this is a fresh fetch that overwrites any previous data, or a 'load more' fetch that will append new data to the existing dataset
        if(i_appendResultsTrueOverwriteResultsFalse) { //for an append results, concat the new results to the old
          gcssSearchResultsMatrix = JSFUNC.concat_arrays_or_values_into_new_array(this.o_searchResultsArrayOfObjs, gcssSearchResultsMatrix);
        }

        this.a_set_search_results_arrayOfObjs(gcssSearchResultsMatrix);
      }
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      this.a_set_search_results_loading_flag("error");
      this.a_set_search_results_error_message("jsPhpError");
      this.a_set_total_num_results_matching_search(0);
      this.a_set_search_results_arrayOfObjs([]);
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();

    //simultaneously send database call to insert record of the search in z_gcss_searches
    var autoSearchName = "";
    if(c_selectedAutoSearchObjOrUndefined !== undefined) {
      autoSearchName = c_selectedAutoSearchObjOrUndefined.name;
    }
    const filtersText = this.compute_gcss_search_all_filled_out_filters_with_values_hover_text_block_from_search_fields_with_values_obj(i_searchFieldsWithValuesObj);
    this.a_gcss_insert_z_gcss_searches_log_entry(autoSearchName, filtersText);
  }


  create_gcss_sam_expanded_search_result_obj_from_govwin_fedcomp_raw_search_result_obj(i_gcssRawSearchResultObj, i_gcssSam0GovWin1) {
    //row(s) of search results from:
    // - SAM tbl_gcss_ops for sam0 do not need modification to convert to an expanded search result obj
    // - GovWin1 or FedComp2 need to be converted using this code below to match the format of the SAM search result obj

    const o_tbl_bit_master_product_service_codes = DatabaseMobx.o_tbl_bit_master_product_service_codes;
    const o_tbl_bit_master_fedcomp_contract_types = DatabaseMobx.o_tbl_bit_master_fedcomp_contract_types;
    const c_tbl_bit_master_naics_codes = DatabaseMobx.c_tbl_bit_master_naics_codes;
    const c_tbl_bit_master_states = DatabaseMobx.c_tbl_bit_master_states;
    const c_tbl_bit_master_countries = DatabaseMobx.c_tbl_bit_master_countries;

    const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "create_gcss_sam_expanded_search_result_obj_from_govwin_fedcomp_raw_search_result_obj", ["i_gcssRawSearchResultObj", "i_gcssSam0GovWin1"], [i_gcssRawSearchResultObj, i_gcssSam0GovWin1]);

    //initialize a single obj for all fields for this opp
    var convertedGovWinSearchResultObj = {};

    //create a full copy of every field from this opp
    for(var propertyName in i_gcssRawSearchResultObj) {
      if(i_gcssRawSearchResultObj.hasOwnProperty(propertyName)) {
        convertedGovWinSearchResultObj[propertyName] = i_gcssRawSearchResultObj[propertyName];
      }
    }

    //create an error message string for this opp in case of a z error recording
    var oppErrString = "sam0_govwin1:'" + i_gcssSam0GovWin1 + "', gcssID:'" + i_gcssRawSearchResultObj.id + "', ";

    //convert state a2 name to BIT Master id and overwrite fedCompContractTypeID
    if(i_gcssRawSearchResultObj.hasOwnProperty("rfcoFedCompFedCompContractTypeName")) {
      var gcssFedCompContractTypeName = i_gcssRawSearchResultObj.rfcoFedCompFedCompContractTypeName;
      if(JSFUNC.string_is_filled_out_tf(gcssFedCompContractTypeName)) {
        var matchingFedCompContractTypeMap = JSFUNC.get_first_map_matching_value_to_any_in_array_of_single_field(o_tbl_bit_master_fedcomp_contract_types, "name", gcssFedCompContractTypeName);
        if(matchingFedCompContractTypeMap === undefined) {
          JSPHP.record_z_error(jsDescription, oppErrString + "rfcoFedCompFedCompContractTypeName not in BIT Master tbl o_tbl_bit_master_fedcomp_contract_types '" + gcssFedCompContractTypeName + "'");
        }
        else {
          convertedGovWinSearchResultObj.fedCompContractTypeID = matchingFedCompContractTypeMap.get("id");
        }
      }
    }

    //convert 6 digit naics codes comma list to BIT Master ids and overwrite naics_code_bm_id with comma list of BIT Master naics ids
    if(i_gcssRawSearchResultObj.hasOwnProperty("gcssGovWinFedCompNaicsCodesComma")) {
      var gcssNaicsCodesArray = JSFUNC.convert_comma_list_to_int_array(i_gcssRawSearchResultObj.gcssGovWinFedCompNaicsCodesComma);
      var gcssNaicsBitMasterIDsArray = [];
      for(let gcssNaicsCode of gcssNaicsCodesArray) {
        if(JSFUNC.is_number_not_nan_gt_0(gcssNaicsCode)) {
          var matchingNaicsCodeMap = JSFUNC.get_first_map_matching_field_value(c_tbl_bit_master_naics_codes, "code", gcssNaicsCode);
          if(matchingNaicsCodeMap === undefined) {
            JSPHP.record_z_error(jsDescription, oppErrString + "gcssGovWinFedCompNaicsCodesComma not in BIT Master tbl o_tbl_bit_master_naics_codes '" + gcssNaicsCode + "'");
          }
          else {
            gcssNaicsBitMasterIDsArray.push(matchingNaicsCodeMap.get("id"));
          }
        }
      }
      var gcssNaicsBitMasterIDsComma = JSFUNC.convert_array_to_comma_list(gcssNaicsBitMasterIDsArray);
      convertedGovWinSearchResultObj.naics_code_bm_id = gcssNaicsBitMasterIDsComma;
    }

    //convert 2-4 char Product Service Codes comma list to BIT Master ids and overwrite product_service_code_bm_id
    if(i_gcssRawSearchResultObj.hasOwnProperty("gcssGovWinFedCompProductServiceCodesComma")) {
      var gcssProductServiceCodesArray = JSFUNC.convert_comma_list_to_array(i_gcssRawSearchResultObj.gcssGovWinFedCompProductServiceCodesComma);
      var gcssProductServiceCodeBitMasterIDsArray = [];
      for(let gcssProductServiceCode of gcssProductServiceCodesArray) {
        if(JSFUNC.string_is_filled_out_tf(gcssProductServiceCode)) {
          var matchingProductServiceCodeMap = JSFUNC.get_first_map_matching_field_value(o_tbl_bit_master_product_service_codes, "code", gcssProductServiceCode);
          if(matchingProductServiceCodeMap === undefined) {
            JSPHP.record_z_error(jsDescription, oppErrString + "gcssGovWinFedCompProductServiceCodesComma not in BIT Master tbl o_tbl_bit_master_product_service_codes '" + gcssProductServiceCode + "'");
          }
          else {
            gcssProductServiceCodeBitMasterIDsArray.push(matchingProductServiceCodeMap.get("id"));
          }
        }
      }
      var gcssProductServiceBitMasterIDsComma = JSFUNC.convert_array_to_comma_list(gcssProductServiceCodeBitMasterIDsArray);
      convertedGovWinSearchResultObj.product_service_code_bm_id = gcssProductServiceBitMasterIDsComma;
    }

    //convert state a2 name to BIT Master id and overwrite place_of_performance_state_bm_id
    if(i_gcssRawSearchResultObj.hasOwnProperty("gcssGovWinFedCompStateA2")) {
      var gcssStatesA2Array = JSFUNC.convert_comma_list_to_array(i_gcssRawSearchResultObj.gcssGovWinFedCompStateA2);
      var gcssStateBitMasterIDsArray = [];
      for(let gcssStateA2 of gcssStatesA2Array) {
        if(JSFUNC.string_is_filled_out_tf(gcssStateA2)) {
          var gcssStateA2Lowercase = JSFUNC.str2lower(gcssStateA2);
          var matchingStateMap = JSFUNC.get_first_map_matching_value_to_any_in_array_of_single_field(c_tbl_bit_master_states, "allNameVariationsLowercaseArray", gcssStateA2Lowercase);
          if(matchingStateMap === undefined) {
            JSPHP.record_z_error(jsDescription, oppErrString + "gcssGovWinFedCompStateA2 not in BIT Master tbl c_tbl_bit_master_states '" + gcssStateA2 + "'");
          }
          else {
            gcssStateBitMasterIDsArray.push(matchingStateMap.get("id"));
          }
        }
      }
      var gcssStateBitMasterIDsComma = JSFUNC.convert_array_to_comma_list(gcssStateBitMasterIDsArray);
      convertedGovWinSearchResultObj.place_of_performance_state_bm_id = gcssStateBitMasterIDsComma;
    }

    //convert country a3 name to BIT Master id and overwrite place_of_performance_country_bm_id
    if(i_gcssRawSearchResultObj.hasOwnProperty("gcssGovWinFedCompCountryA3")) {
      var gcssCountriesA3Array = JSFUNC.convert_comma_list_to_array(i_gcssRawSearchResultObj.gcssGovWinFedCompCountryA3);
      var gcssCountryBitMasterIDsArray = [];
      for(let gcssCountryA3 of gcssCountriesA3Array) {
        if(JSFUNC.string_is_filled_out_tf(gcssCountryA3)) {
          var gcssCountryA3Lowercase = JSFUNC.str2lower(gcssCountryA3);
          var matchingCountryMap = JSFUNC.get_first_map_matching_value_to_any_in_array_of_single_field(c_tbl_bit_master_countries, "allNameVariationsLowercaseArray", gcssCountryA3Lowercase);
          if(matchingCountryMap === undefined) {
            JSPHP.record_z_error(jsDescription, oppErrString + "gcssGovWinFedCompCountryA3 not in BIT Master tbl c_tbl_bit_master_countries '" + gcssCountryA3 + "'");
          }
          else {
            gcssCountryBitMasterIDsArray.push(matchingCountryMap.get("id"));
          }
        }
      }
      var gcssCountryBitMasterIDsComma = JSFUNC.convert_array_to_comma_list(gcssCountryBitMasterIDsArray);
      convertedGovWinSearchResultObj.place_of_performance_country_bm_id = gcssCountryBitMasterIDsComma;
    }

    return(convertedGovWinSearchResultObj);
  }


  a_set_search_results_loading_flag(i_newValue) {
    this.o_searchResultsLoadingFlag = i_newValue;
  }


  a_set_search_results_error_message(i_newValueString) {
    this.o_searchResultsErrorMessage = i_newValueString;
  }


  a_set_total_num_results_matching_search(i_newValueInt) {
    this.o_totalNumResultsMatchingSearch = i_newValueInt;
  }


  a_set_search_results_arrayOfObjs(i_arrayOfObjs) {
    this.o_searchResultsArrayOfObjs = i_arrayOfObjs;
  }


  a_set_current_page_number(i_newValueInt) {
    this.o_currentPageNumber = i_newValueInt;
  }


  a_increment_current_page_number() {
    if(this.c_canIncrementCurrentPageNumberTF) {
      this.a_set_current_page_number(this.o_currentPageNumber + 1);
    }
  }


  a_decrement_current_page_number() {
    if(this.c_canDecrementCurrentPageNumberTF) {
      this.a_set_current_page_number(this.o_currentPageNumber - 1);
    }
  }


  a_mark_search_result_from_expanded_search_result_obj(i_markUndec0NotInt1Import2, i_expandedSearchResultObj) {
    const c_upeGcssIDsMarkedForImportArray = this.c_upeGcssIDsMarkedForImportArray;
    const c_upeGcssMarkedForImportColumnDbName = this.c_upeGcssMarkedForImportColumnDbName;
    const o_userPerEmailID = UserMobx.o_userPerEmailID;
    const c_userGcssDataSource0SamTF = UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = UserMobx.c_userGcssDataSource2FedCompTF;

    if(i_markUndec0NotInt1Import2 !== i_expandedSearchResultObj.markedUndec0NotInt1Import2) { //only need to make db change if value was changed (should be ensured by UI)
      const markAsUndecidedTF = (i_markUndec0NotInt1Import2 === 0);
      const markAsNotInterestedTF = (i_markUndec0NotInt1Import2 === 1);
      const markForImportTF = (i_markUndec0NotInt1Import2 === 2);
      const markImportAsUndOrNITF = (i_expandedSearchResultObj.markedUndec0NotInt1Import2 === 2);

      const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_mark_search_result_from_expanded_search_result_obj", ["i_markUndec0NotInt1Import2", "i_expandedSearchResultObj"], [i_markUndec0NotInt1Import2, i_expandedSearchResultObj]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //not interested tbl
      if(i_expandedSearchResultObj.gcssNotInterestedRowID > 0) { //an entry already exists for marking this gcss row, update the value or delete the row if you created it
        if(markAsNotInterestedTF || (markAsUndecidedTF && (o_userPerEmailID !== i_expandedSearchResultObj.notInterestedByUserPerEmailID))) { //updated to mark as not interested, or mark as undecided when someone else marked it previously as not interested
          C_CallPhpTblUID.add_update("tbl_g_gcss_not_interested", i_expandedSearchResultObj.gcssNotInterestedRowID, "undecided0_notinterested1", i_markUndec0NotInt1Import2, "i");
        }
        else { //mark for import or mark as undecided when you originally marked it as not interested
          C_CallPhpTblUID.add_delete("tbl_g_gcss_not_interested", i_expandedSearchResultObj.gcssNotInterestedRowID);
        }
      }
      else { //the not interested row does not exist yet (switched from undecided to not interested), insert a new one
        if(markAsNotInterestedTF) { //new mark as undecided or not interested
          var notInterestedSamGcssID = 0;
          var notInterestedGovWinIDString = "";
          var notInterestedFedCompIDString = "";
          if(c_userGcssDataSource0SamTF) {
            notInterestedSamGcssID = i_expandedSearchResultObj.id;
          }
          else if(c_userGcssDataSource1GovWinTF) {
            notInterestedGovWinIDString = i_expandedSearchResultObj.id;
          }
          else if(c_userGcssDataSource2FedCompTF) {
            notInterestedFedCompIDString = i_expandedSearchResultObj.id;
          }

          const fieldNamesArray = ["gcss_id", "gcss_govwin_id_string", "gcss_fedcomp_id_string", "user_per_email_id", "undecided0_notinterested1", "reason_ids_comma", "explanation"];
          const valuesArray = [notInterestedSamGcssID, notInterestedGovWinIDString, notInterestedFedCompIDString, o_userPerEmailID, i_markUndec0NotInt1Import2, "", ""];
          const idsbArray = ["i", "s", "s", "i", "i", "s", "s"];
          C_CallPhpTblUID.add_insert("tbl_g_gcss_not_interested", fieldNamesArray, valuesArray, idsbArray);
        }
      }

      //marked for import columns in upe tbl
      if(markForImportTF || markImportAsUndOrNITF) {
        var updatedGcssIDsMarkedForImportArray = [];
        if(markForImportTF) { //mark this search result for import from either undecided or not interested, add gcssID to user import list
          updatedGcssIDsMarkedForImportArray = JSFUNC.concat_arrays_or_values_into_new_array(c_upeGcssIDsMarkedForImportArray, i_expandedSearchResultObj.id);
        }
        else if(markImportAsUndOrNITF) { //mark this search result as either undecided or not interested from marked for import, remove gcssID from import list
          updatedGcssIDsMarkedForImportArray = JSFUNC.remove_all_values_from_array(i_expandedSearchResultObj.id, c_upeGcssIDsMarkedForImportArray);
        }
        const updatedGcssIDsMarkedForImportComma = JSFUNC.convert_array_to_comma_list(updatedGcssIDsMarkedForImportArray);
        C_CallPhpTblUID.add_update("tbl_a_users_per_email", o_userPerEmailID, c_upeGcssMarkedForImportColumnDbName, updatedGcssIDsMarkedForImportComma, "s");
      }

      C_CallPhpTblUID.execute();
    }
  }


  a_update_gcss_not_interested_field(i_gcssNotInterestedRowID, i_fieldName, i_value, i_idsb) {
    if(JSFUNC.is_number(i_gcssNotInterestedRowID) && (i_gcssNotInterestedRowID > 0)) {
      const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_update_gcss_not_interested_field", ["i_gcssNotInterestedRowID", "i_fieldName", "i_value", "i_idsb"], [i_gcssNotInterestedRowID, i_fieldName, i_value, i_idsb]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_g_gcss_not_interested", i_gcssNotInterestedRowID, i_fieldName, i_value, i_idsb);
      C_CallPhpTblUID.execute();
    }
  }



  a_merge_gcss_search_result_data_to_existing_capture_in_captureexec(i_expandedDetailSearchResultObj, i_selectedCaptureID, i_gcssOverwriteDataWithUpdates01, i_functionOnSuccess, i_functionOnError) {
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;

    //initialize capture update arrays
    var allCaptureFieldIDsArray = [];
    var allCaptureFieldDbNamesArray = [];
    var allCaptureValuesArray = [];
    var allCaptureIdsbArray = [];
    var changelogCaptureFieldIDsArray = [];
    var changelogCaptureFieldDisplayNamesArray = [];
    var changelogCaptureValueMaskPlainTextsArray = [];

    //include the selection of gcss updates overwrite data setting from the merge floating box
    allCaptureFieldIDsArray.push(DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("id"));
    allCaptureFieldDbNamesArray.push(DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("db_name"));
    allCaptureValuesArray.push(i_gcssOverwriteDataWithUpdates01);
    allCaptureIdsbArray.push(DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("fieldTypeObj").idsb);
    changelogCaptureFieldIDsArray.push(DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("id"));
    changelogCaptureFieldDisplayNamesArray.push(DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("display_name"));
    changelogCaptureValueMaskPlainTextsArray.push(DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_gcssOverwriteDataWithUpdates01, DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("fieldTypeObj")));

    //loop through every GCSS field (shown on GCSS search results detail panel) and add filled out fields as updates
    const importAllGcssExpandedCaptureFieldMapsAndFieldHistoryObjNamesArrayOfObjs = this.get_import_all_gcss_expanded_capture_field_maps_and_field_history_obj_names_arrayOfObjs();
    for(let gcssExpandedCaptureFieldMapAndHistoryObjNameObj of importAllGcssExpandedCaptureFieldMapsAndFieldHistoryObjNamesArrayOfObjs) {
      var importGcssExpandedCaptureFieldMap = gcssExpandedCaptureFieldMapAndHistoryObjNameObj.expandedCaptureFieldMap;
      var importFieldHistoryObjName = gcssExpandedCaptureFieldMapAndHistoryObjNameObj.fieldHistoryObjName;

      var fieldID = importGcssExpandedCaptureFieldMap.get("id");
      var fieldDbName = importGcssExpandedCaptureFieldMap.get("db_name");
      var fieldDisplayName = importGcssExpandedCaptureFieldMap.get("display_name");
      var fieldTypeObj = importGcssExpandedCaptureFieldMap.get("fieldTypeObj");
      var fieldHistoryObj = i_expandedDetailSearchResultObj[importFieldHistoryObjName];

      if((fieldTypeObj !== undefined) && (fieldHistoryObj !== undefined)) {
        if(fieldHistoryObj.currentValueMaskSortIfoObj.isFilledOutTF) { //only include filled out search result values in capture update
          allCaptureFieldIDsArray.push(fieldID);
          allCaptureFieldDbNamesArray.push(fieldDbName);
          allCaptureValuesArray.push(fieldHistoryObj.currentValueMaskSortIfoObj.valueRaw);
          allCaptureIdsbArray.push(fieldTypeObj.idsb);
          changelogCaptureFieldIDsArray.push(fieldID);
          changelogCaptureFieldDisplayNamesArray.push(fieldDisplayName);
          changelogCaptureValueMaskPlainTextsArray.push(fieldHistoryObj.currentValueMaskSortIfoObj.valueMaskChangelogPlainText);
        }
      }
    }

    //set capture last changed date as today
    allCaptureFieldIDsArray.push(DatabaseMobx.c_fieldMapOfLastChangedDate.get("id"));
    allCaptureFieldDbNamesArray.push(DatabaseMobx.c_fieldMapOfLastChangedDate.get("db_name"));
    allCaptureValuesArray.push(JSFUNC.now_date());
    allCaptureIdsbArray.push(DatabaseMobx.c_fieldMapOfLastChangedDate.get("fieldTypeObj").idsb);

    //update the capture record
    const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_merge_gcss_search_result_data_to_existing_capture_in_captureexec", ["i_expandedDetailSearchResultObj", "i_selectedCaptureID", "i_gcssOverwriteDataWithUpdates01"], [i_expandedDetailSearchResultObj, i_selectedCaptureID, i_gcssOverwriteDataWithUpdates01]);
    const C_CallPhpTblUIDUpdateCapture = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUIDUpdateCapture.add_update("tbl_captures", i_selectedCaptureID, allCaptureFieldDbNamesArray, allCaptureValuesArray, allCaptureIdsbArray);

    const functionOnSuccessUpdateCapture = (i_parseResponse) => {
      const updateSuccess01String = i_parseResponse.outputObj.u0;
      if(updateSuccess01String === "1") {
        //update opp in integration system with all updated fields
        if(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF) {
          for(let f = 0; f < allCaptureFieldIDsArray.length; f++) {
            AdminIntegrationsMobx.update_integration_opp_field_from_ce_capture_id_field_id_and_new_value_raw(i_selectedCaptureID, allCaptureFieldIDsArray[f], allCaptureValuesArray[f]);
          }
        }

        //insert changelog entries for updated capture fields
        const C_CallPhpTblUIDChangelogs = new JSPHP.ClassCallPhpTblUID(jsDescription);

        //add all fields that were filled out from the new capture form, plus the new captureID number first
        for(let f = 0; f < changelogCaptureFieldIDsArray.length; f++) {
          C_CallPhpTblUIDChangelogs.add_changelog_details(i_selectedCaptureID, DatabaseMobx.k_cardIDDetails, changelogCaptureFieldIDsArray[f], changelogCaptureFieldDisplayNamesArray[f], changelogCaptureValueMaskPlainTextsArray[f]);
        }

        C_CallPhpTblUIDChangelogs.execute();

        //call success function
        if(JSFUNC.is_function(i_functionOnSuccess)) {
          i_functionOnSuccess();
        }
      }
      else { //update capture failed
        if(JSFUNC.is_function(i_functionOnError)) {
          i_functionOnError();
        }
      }
    }
    C_CallPhpTblUIDUpdateCapture.add_function("onSuccess", functionOnSuccessUpdateCapture);

    C_CallPhpTblUIDUpdateCapture.add_function("onError", i_functionOnError);

    C_CallPhpTblUIDUpdateCapture.execute();
  }


  a_set_selected_search_results_tab_db_name(i_selectedTabDbName) {
    this.o_selectedSearchResultsTabDbName = i_selectedTabDbName;
    this.a_set_current_page_number(1); //reset current page back to page 1
  }


  a_set_focused_tws_search_result_gcss_id_or_undefined(i_newValueIntOrUndefined) {
    this.o_focusedTwsSearchResultGcssIDOrUndefined = i_newValueIntOrUndefined;
  }


  a_set_open_search_result_detail_gcss_id_or_undefined(i_newValueIntOrUndefined) {
    const o_openSearchResultDetailGcssIDOrUndefined = this.o_openSearchResultDetailGcssIDOrUndefined;
    const c_allLoadedSortedExpandedSearchResultsArrayOfObjs = this.c_allLoadedSortedExpandedSearchResultsArrayOfObjs;
    const o_userID = UserMobx.o_userID;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;
    const c_userGcssDataSource0SamTF = UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = UserMobx.c_userGcssDataSource2FedCompTF;

    //set the open search result id from the input
    this.o_openSearchResultDetailGcssIDOrUndefined = i_newValueIntOrUndefined;

    //reset any error message from previous detail fetch
    this.a_set_open_search_result_detail_error_message("");

    //if opening the detail panel unfocus any search result three way switch, if closing the detail panel transfer the gcssID to the three way switch focus
    var updatedFocusedTwsSearchResultGcssIDOrUndefined = undefined;
    if(i_newValueIntOrUndefined === undefined) {
      updatedFocusedTwsSearchResultGcssIDOrUndefined = o_openSearchResultDetailGcssIDOrUndefined;
    }
    this.a_set_focused_tws_search_result_gcss_id_or_undefined(updatedFocusedTwsSearchResultGcssIDOrUndefined);

    //if opening a search result detail (not undefined which is closing it), load the op tbl data rows from the database
    if(i_newValueIntOrUndefined !== undefined) {
      //fetch the gcss data row matching the input gcssID
      const openExpandedSearchResultObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_allLoadedSortedExpandedSearchResultsArrayOfObjs, "id", i_newValueIntOrUndefined);
      if(openExpandedSearchResultObj !== undefined) {
        //set that the op tbl data is loading and clear data from an old single opp detail fetch
        this.a_set_open_search_result_detail_op_tbl_data_rows_are_loading_tf(true);
        this.a_set_open_search_result_detail_op_tbl_data_rows_arrayOfObjs([]);

        //determine which opp(s) to load for the single detail fetch
        var rawGcssIDsComma = "";
        if(c_userGcssDataSource0SamTF) { rawGcssIDsComma = openExpandedSearchResultObj.matching_op_ids_comma; } //SAM load detail single opp with all history opps related to the same deal so that history can be filled in per field
        else if(c_userGcssDataSource1GovWinTF) { rawGcssIDsComma = openExpandedSearchResultObj.id; } //GovWin load single detail opp (no history)
        else if(c_userGcssDataSource2FedCompTF) { rawGcssIDsComma = openExpandedSearchResultObj.id; } //FedComp load single detail opp (no history)

        //load the op data from the database
        const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_set_open_search_result_detail_gcss_id_or_undefined", ["i_newValueIntOrUndefined"], [i_newValueIntOrUndefined]);
        const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadGcssTblRawSamOppsRowsFromIDsComma", jsDescription);

        C_CallPhpScript.add_post_var("i_userID", o_userID);
        C_CallPhpScript.add_post_var("i_gcssDataSourceSam0GovWin1", c_userGcssSam0GovWin1);
        C_CallPhpScript.add_post_var("i_rawGcssIDsComma", rawGcssIDsComma);

        C_CallPhpScript.add_return_vars(["tblRawSamOppsDataMatrix", "gcssDetailResultErrorMessage"]);

        const functionOnSuccess = (i_parseResponse) => {
          if(JSFUNC.string_is_filled_out_tf(i_parseResponse.gcssDetailResultErrorMessage)) { //controlled error message code from details fetch
            this.a_set_open_search_result_detail_error_message(i_parseResponse.gcssDetailResultErrorMessage);
          }
          else { //successful single search result details fetch
            this.a_set_open_search_result_detail_op_tbl_data_rows_arrayOfObjs(i_parseResponse.tblRawSamOppsDataMatrix);
          }
        }
        C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

        const functionOnError = () => {
          this.a_set_open_search_result_detail_error_message("jsPhpError");
        }
        C_CallPhpScript.add_function("onError", functionOnError);

        const functionOnFinish = () => {
          this.a_set_open_search_result_detail_op_tbl_data_rows_are_loading_tf(false);
        }
        C_CallPhpScript.add_function("onFinish", functionOnFinish);

        C_CallPhpScript.execute();
      }
    }
  }


  a_set_open_search_result_detail_op_tbl_data_rows_are_loading_tf(i_newValueTF) {
    this.o_openSearchResultDetailOpTblDataRowsAreLoadingTF = i_newValueTF;
  }


  a_set_open_search_result_detail_error_message(i_openSearchResultDetailErrorMessageString) {
    this.o_openSearchResultDetailErrorMessage = i_openSearchResultDetailErrorMessageString;
  }


  a_set_open_search_result_detail_op_tbl_data_rows_arrayOfObjs(i_arrayOfObjs) {
    this.o_openSearchResultDetailOpTblDataRowsArrayOfObjs = i_arrayOfObjs;
  }


  previous_search_result_id_or_undefined_on_current_page_from_search_result_id(i_currentSearchResultIDOrUndefined) {
    const c_currentPageOfExpandedSearchResultsArrayOfObjs = this.c_currentPageOfExpandedSearchResultsArrayOfObjs;

    if(i_currentSearchResultIDOrUndefined !== undefined) {
      for(let c = (c_currentPageOfExpandedSearchResultsArrayOfObjs.length - 1); c >= 1; c--) {
        if(c_currentPageOfExpandedSearchResultsArrayOfObjs[c].id === i_currentSearchResultIDOrUndefined) {
          return(c_currentPageOfExpandedSearchResultsArrayOfObjs[(c - 1)].id);
        }
      }
    }
    return(undefined);
  }


  next_search_result_id_or_undefined_on_current_page_from_search_result_id(i_currentSearchResultIDOrUndefined) {
    const c_currentPageOfExpandedSearchResultsArrayOfObjs = this.c_currentPageOfExpandedSearchResultsArrayOfObjs;

    if(i_currentSearchResultIDOrUndefined !== undefined) {
      for(let c = 0; c < (c_currentPageOfExpandedSearchResultsArrayOfObjs.length - 1); c++) {
        if(c_currentPageOfExpandedSearchResultsArrayOfObjs[c].id === i_currentSearchResultIDOrUndefined) {
          return(c_currentPageOfExpandedSearchResultsArrayOfObjs[(c + 1)].id);
        }
      }
    }
    return(undefined);
  }



  //import marked search results into captureexec
  a_set_gcss_setup_import_capture_manager_ids_colon_percents_comma(i_newValueColonCommaString) {
    this.o_gcssSetupImportCaptureManagerIDsColonPercentsComma = i_newValueColonCommaString;
  }
  a_set_gcss_setup_import_division_owner_ids_colon_percents_comma(i_newValueColonCommaString) {
    this.o_gcssSetupImportDivisionOwnerIDsColonPercentsComma = i_newValueColonCommaString;
  }
  a_set_gcss_setup_import_capture_type_id(i_newValueInt) {
    this.o_gcssSetupImportCaptureTypeID = i_newValueInt;
  }
  a_set_gcss_setup_import_stage_id(i_newValueInt) {
    this.o_gcssSetupImportStageID = i_newValueInt;
  }
  a_set_gcss_setup_import_overwrite_data_with_updates_01(i_newValue01) {
    this.o_gcssSetupImportOverwriteDataWithUpdates01 = i_newValue01;
  }
  a_set_gcss_import_search_results_to_import_gcss_ids_array(i_newValueArray) {
    this.o_gcssImportSearchResultsToImportGcssIDsArray = i_newValueArray;
  }
  a_set_gcss_import_current_import_capture_index(i_newValueInt) {
    this.o_gcssImportCurrentImportCaptureIndex = i_newValueInt;
  }
  a_set_gcss_import_current_import_capture_name(i_newValueString) {
    this.o_gcssImportCurrentImportCaptureName = i_newValueString;
  }
  a_set_gcss_import_total_num_captures_to_import(i_newValueInt) {
    this.o_gcssImportTotalNumCapturesToImport = i_newValueInt;
  }
  a_reset_gcss_import_success_import_capture_ids_and_names_array() {
    this.o_gcssImportSuccessImportGcssIDsArray = [];
    this.o_gcssImportSuccessImportCaptureIDsAndNamesArray = [];
  }
  a_append_to_gcss_import_success_import_capture_ids_and_names_array(i_gcssID, i_captureID, i_captureName) {
    this.o_gcssImportSuccessImportGcssIDsArray.push(i_gcssID);
    this.o_gcssImportSuccessImportCaptureIDsAndNamesArray.push("[" + DatabaseMobx.c_fieldMapOfCaptureID.get("display_name") + ": " + i_captureID + "] " + i_captureName);
  }
  a_reset_gcss_import_error_import_capture_names_array() {
    this.o_gcssImportErrorImportCaptureNamesArray = [];
  }
  a_append_to_gcss_import_error_import_capture_names_array(i_newValueString) {
    this.o_gcssImportErrorImportCaptureNamesArray.push(i_newValueString);
  }

  a_gcss_import_marked_for_import_results_into_captureexec(i_gcssOverwriteDataWithUpdates01) {
    const c_markedForImportExpandedSearchResultsArrayOfObjs = this.c_markedForImportExpandedSearchResultsArrayOfObjs;

    //create a "copied" list of search result gcssIDs to be imported from c_markedForImportExpandedSearchResultsArrayOfObjs (because c_markedForImportExpandedSearchResultsArrayOfObjs will shrink as each one is imported, affecting the indexing access of each imported search result as the index increments)
    const searchResultsToImportGcssIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(c_markedForImportExpandedSearchResultsArrayOfObjs, "id");

    //initialize the observable vars controlling the recursive import progression through the array of search results marked for import
    this.a_set_gcss_import_search_results_to_import_gcss_ids_array(searchResultsToImportGcssIDsArray);
    this.a_set_gcss_import_current_import_capture_index(-1); //start new import at first Marked For Import search result at index 0 in the array c_markedForImportExpandedSearchResultsArrayOfObjs (starts at -1 as first step is to increment this index counter)
    this.a_set_gcss_import_total_num_captures_to_import(searchResultsToImportGcssIDsArray.length);
    this.a_reset_gcss_import_success_import_capture_ids_and_names_array();
    this.a_reset_gcss_import_error_import_capture_names_array();

    //call the first iteration of the recursive loop for marked search result index 0
    this.a_gcss_import_recursive_increment_to_next_index_and_import_single_capture();
  }

  a_gcss_import_recursive_increment_to_next_index_and_import_single_capture() {
    const o_gcssSetupImportCaptureManagerIDsColonPercentsComma = this.o_gcssSetupImportCaptureManagerIDsColonPercentsComma;
    const o_gcssSetupImportDivisionOwnerIDsColonPercentsComma = this.o_gcssSetupImportDivisionOwnerIDsColonPercentsComma;
    const o_gcssSetupImportCaptureTypeID = this.o_gcssSetupImportCaptureTypeID;
    const o_gcssSetupImportStageID = this.o_gcssSetupImportStageID;
    const o_gcssSetupImportOverwriteDataWithUpdates01 = this.o_gcssSetupImportOverwriteDataWithUpdates01;
    const o_gcssImportSearchResultsToImportGcssIDsArray = this.o_gcssImportSearchResultsToImportGcssIDsArray;
    const o_gcssImportCurrentImportCaptureIndex = this.o_gcssImportCurrentImportCaptureIndex;
    const o_gcssImportTotalNumCapturesToImport = this.o_gcssImportTotalNumCapturesToImport;
    const o_gcssImportSuccessImportGcssIDsArray = this.o_gcssImportSuccessImportGcssIDsArray;
    const c_upeGcssIDsMarkedForImportArray = this.c_upeGcssIDsMarkedForImportArray;
    const c_upeGcssMarkedForImportColumnDbName = this.c_upeGcssMarkedForImportColumnDbName;
    const c_markedForImportExpandedSearchResultsArrayOfObjs = this.c_markedForImportExpandedSearchResultsArrayOfObjs;
    const o_userID = UserMobx.o_userID;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;
    const c_userGcssDataSource0SamTF = UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = UserMobx.c_userGcssDataSource2FedCompTF;

    //create jsDescription for each database php call
    const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_gcss_import_recursive_increment_to_next_index_and_import_single_capture", [], []);

    //increment the current import capture index
    const updatedCurrentImportCaptureIndex = (o_gcssImportCurrentImportCaptureIndex + 1);
    this.a_set_gcss_import_current_import_capture_index(updatedCurrentImportCaptureIndex);

    if(updatedCurrentImportCaptureIndex >= o_gcssImportTotalNumCapturesToImport) { //finishing steps after every capture is imported (admin changelog entry, etc)
      //upon completion of the full import of all selected captures, remove all successfull gcssIDs from the userPerEmail marked for import column
      const updatedGcssIDsMarkedForImportArray = JSFUNC.remove_all_values_from_array(o_gcssImportSuccessImportGcssIDsArray, c_upeGcssIDsMarkedForImportArray);
      const updatedGcssIDsMarkedForImportComma = JSFUNC.convert_array_to_comma_list(updatedGcssIDsMarkedForImportArray);
      UserMobx.a_update_user_per_email_field(c_upeGcssMarkedForImportColumnDbName, updatedGcssIDsMarkedForImportComma, "s");
    }
    else { //work on the current capture of this particular recursion index
      //load the expanded search result to import from the updated current import index
      const currentSearchResultToImportGcssID = o_gcssImportSearchResultsToImportGcssIDsArray[updatedCurrentImportCaptureIndex];
      const currentImportExpandedSearchResultObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_markedForImportExpandedSearchResultsArrayOfObjs, "id", currentSearchResultToImportGcssID);

      //if the indexing is invalid, record it as an error and move on
      if(currentImportExpandedSearchResultObj === undefined) {
        this.a_append_to_gcss_import_error_import_capture_names_array("[Import search result index " + updatedCurrentImportCaptureIndex + "]");
        this.a_gcss_import_recursive_increment_to_next_index_and_import_single_capture();
      }
      else {
        //get the name of the search result for the new capture (and set it to the observable var for the import progress box to display)
        const currentImportCaptureName = currentImportExpandedSearchResultObj.opportunityNameMaskSortIfoObj.valueMaskPlainText;
        this.a_set_gcss_import_current_import_capture_name(currentImportCaptureName);

        //determine which opp(s) to load for the single detail fetch
        var rawGcssIDsComma = "";
        if(c_userGcssDataSource0SamTF) { rawGcssIDsComma = currentImportExpandedSearchResultObj.matching_op_ids_comma; } //SAM load detail single opp with all history opps related to the same deal so that history can be filled in per field
        else if(c_userGcssDataSource1GovWinTF) { rawGcssIDsComma = currentImportExpandedSearchResultObj.id; } //GovWin load single detail opp (no history)
        else if(c_userGcssDataSource2FedCompTF) { rawGcssIDsComma = currentImportExpandedSearchResultObj.id; } //FedComp load single detail opp (no history)

        //attempt to fetch the op tbl rows that match the ids from the gcss search result row
        const C_CallPhpScriptFetchOpTblRows = new JSPHP.ClassCallPhpScript("loadGcssTblRawSamOppsRowsFromIDsComma", jsDescription);
        C_CallPhpScriptFetchOpTblRows.add_post_var("i_userID", o_userID);
        C_CallPhpScriptFetchOpTblRows.add_post_var("i_gcssDataSourceSam0GovWin1", c_userGcssSam0GovWin1);
        C_CallPhpScriptFetchOpTblRows.add_post_var("i_rawGcssIDsComma", rawGcssIDsComma);
        C_CallPhpScriptFetchOpTblRows.add_return_vars(["tblRawSamOppsDataMatrix", "gcssDetailResultErrorMessage"]);

        //fetching the op tbl rows was successful
        const functionOnSuccessFetchOpTblRows = (i_parseResponse) => {
          const opTblDataRowsArrayOfObjs = i_parseResponse.tblRawSamOppsDataMatrix; //if there was an error in php fetching these op rows this returns an empty array (fine to proceed, importing an empty capture is better than an infinite loop if the corresponding op tbl row doesn't exist anymore where this capture can't be imported)

          //convert the single expanded search result and op rows into an expanded detail search results obj that has all capture values for import
          const currentImportExpandedDetailSearchResultsObj = this.create_gcss_expanded_detail_search_results_obj_from_expanded_search_result_obj_and_op_tbl_data_rows_arrayOfObjs(currentImportExpandedSearchResultObj, opTblDataRowsArrayOfObjs);

          //initialize new capture obj of all set fields/values
          var gcssImportNewCaptureObj = {};

          //all fixed data to insert into the new capture (Added Date, plus the 4 fields that can be adjusted within GCSS capture managers, division owners, capture type, stage)
          const importAllFixedExpandedCaptureFieldsArrayOfMaps = [DatabaseMobx.c_fieldMapOfAddedDate, DatabaseMobx.c_fieldMapOfCaptureManagers, DatabaseMobx.c_fieldMapOfDivisionOwners, DatabaseMobx.c_fieldMapOfCaptureType, DatabaseMobx.c_fieldMapOfStage, DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01];
          const importAllFixedValuesArray = [JSFUNC.now_date(), o_gcssSetupImportCaptureManagerIDsColonPercentsComma, o_gcssSetupImportDivisionOwnerIDsColonPercentsComma, o_gcssSetupImportCaptureTypeID, o_gcssSetupImportStageID, o_gcssSetupImportOverwriteDataWithUpdates01];
          for(let f = 0; f < importAllFixedExpandedCaptureFieldsArrayOfMaps.length; f++) {
            var importFixedExpandedCaptureFieldMap = importAllFixedExpandedCaptureFieldsArrayOfMaps[f];
            var importFixedValue = importAllFixedValuesArray[f];
            var fieldDbName = importFixedExpandedCaptureFieldMap.get("db_name");
            gcssImportNewCaptureObj[fieldDbName] = importFixedValue;
          }

          //all GCSS data to insert into the new capture
          const importAllGcssExpandedCaptureFieldMapsAndFieldHistoryObjNamesArrayOfObjs = this.get_import_all_gcss_expanded_capture_field_maps_and_field_history_obj_names_arrayOfObjs();
          for(let gcssExpandedCaptureFieldMapAndHistoryObjNameObj of importAllGcssExpandedCaptureFieldMapsAndFieldHistoryObjNamesArrayOfObjs) {
            var importGcssExpandedCaptureFieldMap = gcssExpandedCaptureFieldMapAndHistoryObjNameObj.expandedCaptureFieldMap;
            var importFieldHistoryObjName = gcssExpandedCaptureFieldMapAndHistoryObjNameObj.fieldHistoryObjName;

            var fieldDbName = importGcssExpandedCaptureFieldMap.get("db_name");
            var fieldHistoryObj = currentImportExpandedDetailSearchResultsObj[importFieldHistoryObjName];

            if(fieldHistoryObj !== undefined) {
              var importGcssValue = fieldHistoryObj.currentValueMaskSortIfoObj.valueRaw;
              var importGcssValueIsFilledOutTF = fieldHistoryObj.currentValueMaskSortIfoObj.isFilledOutTF;
              if(importGcssValueIsFilledOutTF) {
                gcssImportNewCaptureObj[fieldDbName] = importGcssValue;
              }
            }
          }

          const gcssImportNewCaptureFieldAndLogArraysObj = DatabaseMobx.create_new_capture_field_names_values_idsb_and_log_arrays_obj_from_new_capture_obj(gcssImportNewCaptureObj);
          const fieldIDsArray = gcssImportNewCaptureFieldAndLogArraysObj.fieldIDsArray;
          const fieldDbNamesArray = gcssImportNewCaptureFieldAndLogArraysObj.fieldDbNamesArray;
          const rawValuesArray = gcssImportNewCaptureFieldAndLogArraysObj.rawValuesArray;
          const idsbArray = gcssImportNewCaptureFieldAndLogArraysObj.idsbArray;

          //insert the capture record
          const C_CallPhpTblUIDInsertCapture = new JSPHP.ClassCallPhpTblUID(jsDescription);

          C_CallPhpTblUIDInsertCapture.add_insert("tbl_captures", fieldDbNamesArray, rawValuesArray, idsbArray);

          const functionOnSuccessInsertCapture = (i_parseResponse) => {
            const newlyInsertedCaptureID = i_parseResponse.outputObj.i0;

            if(JSFUNC.is_number(newlyInsertedCaptureID) && (newlyInsertedCaptureID > 0)) { //i0 > 0 is a successful insert, "0" is returned if the insert failed
              //update the capture record in local memory with the new captureID from the database (currently local record does not have an id field at all)
              DatabaseMobx.a_create_new_capture_inject_id_field_into_new_local_capture_record(newlyInsertedCaptureID);

              //if integration is turned on, insert the new record into the linked integration system with a newly created integration uniqueID string which is updated into the CE record as well
              AdminIntegrationsMobx.insert_integration_new_opp_with_newly_created_unique_id_string_from_new_ce_capture_id(newlyInsertedCaptureID, fieldIDsArray, rawValuesArray);

              //insert changelog entries into all 4 changelogs for gantt/progress chart initialization values on the added_date
              const C_CallPhpTblUIDChangelogs = new JSPHP.ClassCallPhpTblUID(jsDescription);
              C_CallPhpTblUIDChangelogs.add_all_new_capture_changelogs_from_new_capture_field_and_log_arrays_obj(gcssImportNewCaptureFieldAndLogArraysObj, newlyInsertedCaptureID, DatabaseMobx.k_cardIDGCSSUserImportNewCapture, "Imported Capture from GCSS");

              //regardless of changelog inserts success or fail, mark this capture as successfully imported and move to the next one
              const functionOnFinishInsertCaptureChangelogs = () => {
                this.a_append_to_gcss_import_success_import_capture_ids_and_names_array(currentImportExpandedDetailSearchResultsObj.id, newlyInsertedCaptureID, currentImportCaptureName);
                this.a_gcss_import_recursive_increment_to_next_index_and_import_single_capture();
              }
              C_CallPhpTblUIDChangelogs.add_function("onFinish", functionOnFinishInsertCaptureChangelogs);

              C_CallPhpTblUIDChangelogs.execute();
            }
            else { //insert capture failed
              this.a_append_to_gcss_import_error_import_capture_names_array(currentImportCaptureName);
              this.a_gcss_import_recursive_increment_to_next_index_and_import_single_capture();
            }
          }
          C_CallPhpTblUIDInsertCapture.add_function("onSuccess", functionOnSuccessInsertCapture);

          const functionOnErrorInsertCapture = () => {
            this.a_append_to_gcss_import_error_import_capture_names_array(currentImportCaptureName);
            this.a_gcss_import_recursive_increment_to_next_index_and_import_single_capture();
          }
          C_CallPhpTblUIDInsertCapture.add_function("onError", functionOnErrorInsertCapture);

          C_CallPhpTblUIDInsertCapture.execute();

        }
        C_CallPhpScriptFetchOpTblRows.add_function("onSuccess", functionOnSuccessFetchOpTblRows);

        //fetching the op tbl data rows was not successful, add this search result as an import error and move to the next one
        const functionOnErrorFetchOpTblRows = () => {
          this.a_append_to_gcss_import_error_import_capture_names_array(currentImportCaptureName);
          this.a_gcss_import_recursive_increment_to_next_index_and_import_single_capture();
        }
        C_CallPhpScriptFetchOpTblRows.add_function("onError", functionOnErrorFetchOpTblRows);

        C_CallPhpScriptFetchOpTblRows.execute();
      }
    }
  }



  a_gcss_insert_z_gcss_searches_log_entry(i_autoSearchName, i_filtersText) {
    const c_userCompanyCodeIsBITTestDemoTF = UserMobx.c_userCompanyCodeIsBITTestDemoTF;
    const c_userName = UserMobx.c_userName;
    const c_userGcssSam0GovWin1 = UserMobx.c_userGcssSam0GovWin1;

    if(!c_userCompanyCodeIsBITTestDemoTF) {
      const jsDescription = JSFUNC.js_description_from_action("GCSSMobx", "a_gcss_insert_z_gcss_searches_log_entry", ["i_autoSearchName", "i_filtersText"], [i_autoSearchName, i_filtersText]);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("insertBITZGcssSearchesEntry", jsDescription);
      C_CallPhpScript.add_post_var("i_userName", c_userName);
      C_CallPhpScript.add_post_var("i_gcssSam0GovWin1", c_userGcssSam0GovWin1);
      C_CallPhpScript.add_post_var("i_autoSearchName", i_autoSearchName);
      C_CallPhpScript.add_post_var("i_filtersText", i_filtersText);
      C_CallPhpScript.add_return_vars("success01String");
      C_CallPhpScript.execute();
    }
  }


  get_all_gcss_fields_with_history_obj_names_organized_by_single_detail_sections_arrayOfObjs() {
    //all 'left side' single detail sections fields (for all sam/govwin/fedcomp data sources) are listed in the array gcssFieldsBySingleDetailSectionsArrayOfObjs (with descriptionOfWork at bottom as a rightSideTF section)
    //'other' necessary id fields at bottom for database loading to be included (but not shown left or right) with a call to get_import_all_gcss_expanded_capture_field_maps_and_field_history_obj_names_arrayOfObjs()
    return([
      {
        sectionHeader: "Opportunity",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"opportunityNameHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfOpportunityName},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"gcssGovWinIDStringHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssGovWinIDString},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"govWinLinkHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssGovWinLink},
          {samTF:false, govWinTF:false, fecCompTF:true, fieldHistoryObjName:"gcssFedCompIDStringHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssFedCompIDString},
          {samTF:false, govWinTF:false, fecCompTF:true, fieldHistoryObjName:"fedCompLinkHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssFedCompLink},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"samUrlHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssSamLink},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"solicitationNumberHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfSolicitationNumber},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"govWinPriority1to5HistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssGovWinPriority1to5},
          {samTF:false, govWinTF:false, fecCompTF:true, fieldHistoryObjName:"fedCompContractTypeHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssBmFedCompContractTypeID}
        ]
      },
      {
        sectionHeader: "Documents",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"gcssDocumentLinksJsonStringHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssDocumentLinksJsonString, showFieldDisplayNameTF:false}
        ]
      },
      {
        sectionHeader: "Dates",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"proposalDueDateTimeUtcHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"rfpDateHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfRFPDate},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"periodOfPerformanceHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfPeriodOfPerformance},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"govWinDurationHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssGovWinDuration},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"lastUpdatedDateTimeUtcHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssLastUpdatedDateTimeUtc},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"originalPublishedDateTimeUtcHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssOriginalPublishedDateTimeUtc}
        ]
      },
      {
        sectionHeader: "Deptartment/Agency",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"departmentAgencyHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssBmDepartmentAgencyID},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"subTierHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssBmSubTierID},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"officeHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssOffice}
        ]
      },
      {
        sectionHeader: "Set Aside/Codes",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"setAsideHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfCompetitionTypeBmSetAsideIDsComma},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"naicsCodeHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfNaicsCodeIDsComma},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractOpportunityTypeHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssBmContractOpportunityTypeID},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"govWinProcurementCommentHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssGovWinProcurementComment},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"productServiceCodeHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssBmProductServiceCodeIDsComma},
          {samTF:false, govWinTF:true, fecCompTF:false, fieldHistoryObjName:"govWinPrimaryWorkRequiredHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssGovWinPrimaryWorkRequired}
        ]
      },
      {
        sectionHeader: "Place of Performance",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"placeOfPerformanceAddressHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceAddress},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"placeOfPerformanceCityHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceCity},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"placeOfPerformanceStateHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"placeOfPerformanceZipHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceZip},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"placeOfPerformanceProvinceHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceProvince},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"placeOfPerformanceCountryHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma}
        ]
      },
      {
        sectionHeader: "Contracting Office",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractingOfficeCityHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssContractingOfficeCity},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractingOfficeStateHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmStateID},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractingOfficeZipHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssContractingOfficeZip},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractingOfficeProvinceHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssContractingOfficeProvince},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractingOfficeCountryHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssContractingOfficeBmCountryID},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractingOfficePOC1TextareaHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssContractingOfficePOC1Textarea},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractingOfficePOC2TextareaHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssContractingOfficePOC2Textarea}
        ]
      },
      {
        sectionHeader: "Award Info",
        leftSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"awardDateHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfAwardDate},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"awardNumberHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssAwardNumber},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"contractOverallValueHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfContractOverallValue},
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"awardeeInformationHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssAwardeeInformationTextarea}
        ]
      },
      {
        sectionHeader: DatabaseMobx.c_fieldMapOfDescriptionOfWork.get("display_name"),
        rightSideTF: true,
        gcssFieldsArrayOfObjs: [
          {samTF:true, govWinTF:true, fecCompTF:true, fieldHistoryObjName:"descriptionOfWorkHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfDescriptionOfWork}
        ]
      },
      {
        sectionHeader: "",
        leftSideTF: false,
        rightSideTF: false,
        gcssFieldsArrayOfObjs: [
          {samTF:false, govWinTF:false, fecCompTF:false, fieldHistoryObjName:"gcssIDHistoryObj", expandedCaptureFieldMap:DatabaseMobx.c_fieldMapOfGcssID}
        ]
      }
    ]);
  }


  get_import_all_gcss_expanded_capture_field_maps_and_field_history_obj_names_arrayOfObjs() {
    const allGcssFieldsWithHistoryObjNamesOrganizedBySingleDetailSectionsArrayOfObjs = this.get_all_gcss_fields_with_history_obj_names_organized_by_single_detail_sections_arrayOfObjs();
    var importAllGcssExpandedCaptureFieldMapsAndFieldHistoryObjNamesArrayOfObjs = [];
    for(let gcssDetailSectionObj of allGcssFieldsWithHistoryObjNamesOrganizedBySingleDetailSectionsArrayOfObjs) { //regardless of right/left side or sam/govwin/fedcomp, include all gcss import fields in this array
      for(let gcssFieldObj of gcssDetailSectionObj.gcssFieldsArrayOfObjs) {
        importAllGcssExpandedCaptureFieldMapsAndFieldHistoryObjNamesArrayOfObjs.push(gcssFieldObj);
      }
    }
    return(importAllGcssExpandedCaptureFieldMapsAndFieldHistoryObjNamesArrayOfObjs);
  }


  translate_gcss_error_message_to_user_display_error_message(i_gcssErrorMessage) {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const c_userGcssDataSourceName = UserMobx.c_userGcssDataSourceName;

    var errorDescription = "";
    if(i_gcssErrorMessage === "jsPhpError") {
      errorDescription = "Error establishing connection to start GCSS API";
    }
    else if(i_gcssErrorMessage === "credentialsTblLoadError") {
      errorDescription = "Error fetching " + c_userGcssDataSourceName + " connection credentials from " + c_productStylingObj.productName;
    }
    else if(i_gcssErrorMessage === "newOauthTokenBadCredentials") {
      errorDescription = "Your company's " + c_userGcssDataSourceName + " credentials are not valid. Let your " + c_productStylingObj.productName + " Admin know to reivew/update the credentials.";
    }
    else if(i_gcssErrorMessage === "newOauthTokenUnexpectedData") {
      errorDescription = c_userGcssDataSourceName + " credentials authentication has returned data in an unrecognized format and the APi fetch could not be completed";
    }
    else if(i_gcssErrorMessage === "gcssApiFailed") {
      errorDescription = "The " + c_userGcssDataSourceName + " services web address could not be accessed to complete the requested API data fetch";
    }
    else if(i_gcssErrorMessage === "gcssOppsUnexpectedData") {
      errorDescription = c_userGcssDataSourceName + " API returned data in an unrecognized format";
    }
    else if(i_gcssErrorMessage === "noMatchingSamRecordBySolicitationNumber") {
      errorDescription = "No SAM.gov deals within the GCSS library match the requested Solicitation Number";
    }
    else if(i_gcssErrorMessage === "gcssDetail0Opps") {
      errorDescription = "The detailed data fetch for a single " + c_userGcssDataSourceName + " opportunity by ID number has returned 0 results from " + c_userGcssDataSourceName;
    }

    return(errorDescription);
  }



}
export default new GCSSMobx();

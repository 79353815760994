/*=======================================================================================
//measure code performance timing
var t0 = performance.now();
alert((performance.now() - t0) + " ms");
=======================================================================================*/
export function is_number(i_input) { return(typeof(i_input) === "number"); }
export function is_string(i_input) { return(typeof(i_input) === "string"); }
export function is_bool(i_input) { return(typeof(i_input) === "boolean"); }
export function is_undefined(i_input) { return(typeof(i_input) === "undefined"); }
export function is_obj(i_input) { return(typeof(i_input) === "object"); }
export function is_array(i_input) { return(Array.isArray(i_input)); }
export function is_function(i_input) { return(typeof(i_input) === "function"); }
export function is_symbol(i_input) { return(typeof(i_input) === "symbol"); }

export function is_number_not_nan(i_input) { return(is_number(i_input) && !isNaN(i_input)); }
export function is_number_not_nan_gt_0(i_input) { return(is_number(i_input) && !isNaN(i_input) && (i_input > 0)); }
export function is_number_not_nan_gte_0(i_input) { return(is_number(i_input) && !isNaN(i_input) && (i_input >= 0)); }
export function string_is_number_tf(i_string) { return(!isNaN(i_string)); }

export function print(i_input, i_printArrayTF=true, i_printObjTF=true) {
  if(i_input === undefined) { return("undefined"); }
  else if(i_input === null) { return("null"); }
  else if(i_input === true) { return("true"); }
  else if(i_input === false) { return("false"); }
  else if(is_array(i_input)) { return((i_printArrayTF) ? (print_array(i_input)) : ("[Array]")); }
  else if(is_number(i_input)) { return(i_input.toString()); }
  else if(is_string(i_input)) { return('"' + i_input + '"'); }
  else if(is_symbol(i_input)) { return("Symbol"); }
  else if(is_function(i_input)) { return("Function"); }
  else if(is_obj(i_input)) { return((i_printObjTF) ? (print_obj(i_input)) : ("{Object}")); }
  return("Unknown");
}

export function print_array(i_array) {
  var output = "[";
  var e = 0;
  for(let element of i_array) {
    if(e > 0) { output += ", "; }
    output += print(element, false, true);
    e++;
  }
  output += "]";
  return(output);
}

export function print_obj(i_obj) {
  var output = "{";
  var p = 0;
  for(var propertyName in i_obj) {
    if(i_obj.hasOwnProperty(propertyName)) {
      if(p > 0) { output += ", "; }
      output += propertyName + ": " + print(i_obj[propertyName], false, false);
    }
    p++;
  }
  output += "}";
  return(output);
}

export function print_obj_field_names_comma(i_obj) {
  if(!is_obj(i_obj)) {
    return(print(i_obj));
  }

  var alertString = "";
  for(var key in i_obj) {
    if(i_obj.hasOwnProperty(key)) {
      alertString += key + ", ";
    }
  }
  return(alertString);
}

export function print_aoo(i_arrayOfObjs) {
  if(!is_array(i_arrayOfObjs)) {
    return(print(i_arrayOfObjs));
  }
  else {
    var output = "";
    for(let i = 0; i < i_arrayOfObjs.length; i++) {
      output += "[" + i + "] " + print_obj(i_arrayOfObjs[i]) + "\n";
    }
    return(output);
  }
}

export function print_aoo_vertical(i_arrayOfObjs) {
  if(!is_array(i_arrayOfObjs)) {
    return(print(i_arrayOfObjs));
  }
  else {
    var output = "";
    for(let i = 0; i < i_arrayOfObjs.length; i++) {
      output += "[" + i + "] {\n";
      var obj = i_arrayOfObjs[i];
      for(var propertyName in obj) {
        if(obj.hasOwnProperty(propertyName)) {
          output += "\t" + propertyName + ": " + print(obj[propertyName]) + "\n";
        }
      }
      output += "}\n";
    }
    return(output);
  }
}

export function print_map2(i_jsMapMap) {
  if(i_jsMapMap === undefined) {
    return(undefined);
  }
  
  var alertString = "";
  for(var [id, objMap] of i_jsMapMap) {
    alertString += id + "\n";
    for(var [key, value] of objMap) {
      alertString += " - " + key + ": " + value + "\n";
    }
  }
  return(alertString);
}

export function print_map(i_jsMap) {
  if(i_jsMap === undefined) {
    return(undefined);
  }

  var alertString = "";
  for(var [key, value] of i_jsMap) {
    alertString += " - " + key + ": " + print(value) + "\n";
  }
  return(alertString);
}

export function alert_map2(i_jsMapMap) {
  alert(print_map2(i_jsMapMap));
}

export function alert_map(i_jsMap) {
  alert(print_map(i_jsMap));
}

export function alert_aoo(i_arrayOfObjs) {
  alert(print_aoo(i_arrayOfObjs));
}

export function alert_obj(i_obj) {
  if(!is_obj(i_obj)) {
    alert(print(i_obj));
  }
  else {
    var alertString = "";
    for(var key in i_obj) {
      if(i_obj.hasOwnProperty(key)) {
        alertString += " - " + key + ": " + print(i_obj[key]) + "\n";
      }
    }
    alert(alertString);
  }
}

export function alert_obj_field_names_comma(i_obj) {
  alert(print_obj_field_names_comma(i_obj));
}

export function alert_array(i_array) {
  if(!is_array(i_array)) {
    alert(print(i_array));
  }
  else {
    var alertString = "";
    for(let i = 0; i < i_array.length; i++) {
      alertString += " - [" + i + "]: " + print(i_array[i]) + "\n";
    }
    alert(alertString);
  }
}


/* Testing for JSFiddle
var m11 = new Map(); m11.set("id", 1); m11.set("name", "one");
var m22 = new Map(); m22.set("id", 2); m22.set("name", "two");
var m33 = new Map(); m33.set("id", 3); m33.set("name", "three");
var m2 = new Map(); m2.set(1, m11); m2.set(2, m22); m2.set(3, m33);
var aoo = [{id:1, name:"B", date:"2018-03-01"},{id:3, name:"A", date:"2018-04-01"},{id:2, name:"D", date:"2018-01-01"},{id:4, name:"C", date:"2018-02-01"}];

function is_number(i1) { return(typeof(i1) === "number"); }
function is_string(i1) { return(typeof(i1) === "string"); }
function is_bool(i1) { return(typeof(i1) === "boolean"); }
function is_undefined(i1) { return(typeof(i1) === "undefined"); }
function is_obj(i1) { return(typeof(i1) === "object"); }
function is_array(i1) { return(Array.isArray(i1)); }
function is_function(i1) { return(typeof(i1) === "function"); }
function is_symbol(i1) { return(typeof(i1) === "symbol"); }
function is_number_not_nan(i_input) { return(is_number(i_input) && !isNaN(i_input)); }
function is_number_not_nan_gt_0(i_input) { return(is_number(i_input) && !isNaN(i_input) && (i_input > 0)); }
function is_number_not_nan_gte_0(i_input) { return(is_number(i_input) && !isNaN(i_input) && (i_input >= 0)); }
function string_is_number_tf(i_string) { return(!isNaN(i_string)); }
function string_is_number_tf(i1) { return(!isNaN(i1)); }
function print(i1, i2=true, i3=true) { if(i1 === undefined) { return("undefined"); } else if(i1 === null) { return("null"); } else if(i1 === true) { return("true"); } else if(i1 === false) { return("false"); } else if(is_array(i1)) { return((i2) ? (print_array(i1)) : ("[Array]")); } else if(is_number(i1)) { return(i1.toString()); } else if(is_string(i1)) { return('"' + i1 + '"'); } else if(is_symbol(i1)) { return("Symbol"); } else if(is_function(i1)) { return("Function"); } else if(is_obj(i1)) { return((i3) ? (print_obj(i1)) : ("{Object}")); } return("Unknown"); }
function print_array(i1) {var o = "[";var e = 0;for(let e of i1) {if(e > 0) { o += ", "; }o += print(e, false, true);e++;}o += "]";return(o);}
function print_obj(i1) {var o = "{";var p = 0;for(var p in i1) {if(i1.hasOwnProperty(p)) {if(p > 0) { o += ", "; }o += p + ": " + print(i1[p], false, false);}p++;}o += "}";return(o);}
function print_obj_field_names_comma(i1) {if(!is_obj(i1)) {return(print(i1));}var o = "";for(var k in i1) {if(i1.hasOwnProperty(k)) {o += k + ", ";}}return(o);}
function print_aoo(i1) {if(!is_array(i1)) {return(print(i1));}else {var o = "";for(let i = 0; i < i1.length; i++) {o += "[" + i + "] " + print_obj(i1[i]) + "\n";}return(o);}}
function print_aoo_vertical(i1) {if(!is_array(i1)) {return(print(i1));}else {var o = "";for(let i = 0; i < i1.length; i++) {o += "[" + i + "] {\n";var obj = i1[i];for(var p in obj) {if(obj.hasOwnProperty(p)) {o += "\t" + p + ": " + print(obj[p]) + "\n";}}o += "}\n";}return(o);}}
function alert_map2(i1) {if(i1 === undefined) {alert(undefined);}else {var o = "";for(var [id, oM] of i1) {o += id + "\n";for(var [k, v] of oM) {o += " - " + k + ": " + v + "\n";}}alert(o);}}
function alert_map(i1) {if(i1 === undefined) {alert(undefined);}else {var o = "";for(var [k, v] of i1) {o += " - " + k + ": " + print(v) + "\n";}alert(o);}}
function alert_aoo(i1) {alert(print_aoo(i1));}
function alert_obj(i1) {if(!is_obj(i1)) {alert(print(i1));}else {var o = "";for(var k in i1) {if(i1.hasOwnProperty(k)) {o += " - " + k + ": " + print(i1[k]) + "\n";}}alert(o);}}
function alert_obj_field_names_comma(i1) {alert(print_obj_field_names_comma(i1));}
function alert_array(i1) {if(!is_array(i1)) {alert(print(i1));}else {var o = "";for(let i = 0; i < i1.length; i++) {o += " - [" + i + "]: " + print(i1[i]) + "\n";}alert(o);}}
function in_array(i1, i2) {return(i2.indexOf(i1) >= 0);}
function convert_single_or_array_to_array(i1) {if(is_array(i1)) {return(i1);}return([i1]);}
function sort_arrayOfObjs(i1, i2, i3) {const pa = convert_single_or_array_to_array(i2);const sa = convert_single_or_array_to_array(i3);for(let i = pa.length-1; i >= 0; i--) {if(sa[i]) {i1.sort(sort_by_asc(pa[i]));}else {i1.sort(sort_by_desc(pa[i]));}}}
function sort_by_asc(i1) {return(function(a,b) {var av = a[i1];var bv = b[i1];return((av < bv) ? (-1) : ((av > bv) ? (1) : (0)));});}
function sort_by_desc(i1) {return(function(a,b) {var av = a[i1];var bv = b[i1];return((av < bv) ? (1) : ((av > bv) ? (-1) : (0)));});}
function convert_any_style_string_to_int(i1) { const s = string_keep_only_numbers_decimals_negatives(i1); const int = str2int(s); if(int === undefined) { return(0); } return(int); }
function convert_any_style_string_to_decimal(i1) { const s = string_keep_only_numbers_decimals_negatives(i1); const d = str2int_or_decimal(s); if(d === undefined) { return(0); } return(d); }
function string_keep_only_numbers_decimals_negatives(i1) { if(is_string(i1)) { return(i1.replace(/[^-0-9.]+/g, '')); } return(i1); }
function str2int(i1, i2=undefined) { const int = parseInt(i1, 10); if(isNaN(int)) { return(i2); } return(int); }
function str2int_or_decimal(i1) { const f = parseFloat(i1); if(isNaN(f)) { return(undefined); } return(f); }
function num2str(i1) { return((is_number(i1)) ? (i1.toString()) : (i1)); }
function zero_pad_integer_from_left(i1, i2) { var s = ""; if(is_number_not_nan_gt_0(i1)) { s = num2str(i1); } const c = s.length; if(is_number_not_nan_gt_0(i2)) { if(i2 > c) { const n = (i2 - c); var z = ""; for(let i = 0; i < n; i++) { z += "0"; } z += s; return(z); } } return(s); }
*/



//=====================================================================================================================================================
//XML Http request to call php script
//=====================================================================================================================================================
export function xmlhttprequest_send_form_data_post_to_php_script_with_response_function(i_relativePathToPhpFileFromIndexDotHtml, i_postVariableNameOrNamesArray, i_postVariableValueOrValuesArray, i_functionOnReadyState4WithXhrInput=undefined, i_returnsArrayBufferFileDataTF=false) {
  //convert input name/value POST variables to arrays if single values are provided
  const postVariableNamesArray = convert_single_or_array_to_array(i_postVariableNameOrNamesArray);
  const postVariableValuesArray = convert_single_or_array_to_array(i_postVariableValueOrValuesArray);

  //create xhr and send form data to specified php script
  const xmlHttpRequest = new XMLHttpRequest();

  //open the request with POST
  xmlHttpRequest.open("POST", i_relativePathToPhpFileFromIndexDotHtml, true);

  //if the php script being called opens and reads a file on the server and returns that file data string, set the responseType to arraybuffer
  if(i_returnsArrayBufferFileDataTF) {
    xmlHttpRequest.responseType = "arraybuffer";
  }

  //execute an onReadyState4 function when the php script has completed if provided
  if(is_function(i_functionOnReadyState4WithXhrInput)) {
    const functionOnReadyState4 = () => {
      if(xmlHttpRequest.readyState === 4) {
        i_functionOnReadyState4WithXhrInput(xmlHttpRequest); //response function takes the entire resulting xmlHttpRequest obj as an input
      }
    };

    if(i_returnsArrayBufferFileDataTF) {
      xmlHttpRequest.onload = functionOnReadyState4;
    }
    else {
      xmlHttpRequest.onreadystatechange = functionOnReadyState4;
    }
  }

  //create POST form data from post var names/values
  const formData = new FormData();
  for(let v = 0; v < postVariableNamesArray.length; v++) {
    formData.append(postVariableNamesArray[v], postVariableValuesArray[v]);
  }

  //calls the send() method with data as parameter
  xmlHttpRequest.send(formData);
}




//=====================================================================================================================================================
//File Download/Upload and Paths
//=====================================================================================================================================================
export function browser_offer_file_download_from_file_data_string(i_fileDataString, i_downloadSaveAsFileNameAndExt) {
  var contentType = "application/octet-stream"; //type is from Content-Type in download.php

  //check to see if browser has File() as a function to use, otherwise use Blob()
  var blob = undefined;
  if(!is_undefined(window.navigator.msSaveBlob)) { //IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    blob = new Blob([i_fileDataString], {type:contentType});
    window.navigator.msSaveBlob(blob, i_downloadSaveAsFileNameAndExt);
  }
  else {
    blob = new File([i_fileDataString], i_downloadSaveAsFileNameAndExt, {type:contentType});

    const windowURL = ((window.webkitURL) ? (window.webkitURL) : (window.URL)); //different browsers use different window URL calls
    const downloadUrl = windowURL.createObjectURL(blob);
    var aElement = document.createElement("a"); //use HTML5 a[download] attribute to specify i_downloadSaveAsFileNameAndExt
    if(is_undefined(aElement.download)) {
      window.location = downloadUrl; //Safari browser does not have <a download="" /> property
    }
    else {
      //create an <a> element for the blob download within the <body> tag
      aElement.href = downloadUrl; //path to local browser blob file
      aElement.download = i_downloadSaveAsFileNameAndExt; //default file name and extension in your computer file explorer that you can change before saving
      document.body.appendChild(aElement);
      aElement.click();

      //cleanup by removing the created <a> element
      document.body.removeChild(aElement);
    }

    //cleanup by removing blob obj URL from the window
    windowURL.revokeObjectURL(downloadUrl);
  }
}


export function read_file_data_string_from_uploaded_data_transfer_file_obj(i_dataTransferFileObj, i_functionOnSucces=undefined, i_functionOnError=undefined) {
  //a dataTransferFileObj comes from a <div onDrop={} /> function where the event input is used to extract the uploaded file(s) (event.dataTransfer.files)
  //"files" is an array of dataTransferFileObjs, a single one of those is passed into this function to extract its raw data string of content
  var fileReaderClass = new FileReader();

  if(is_function(i_functionOnSucces)) {
    fileReaderClass.onload = () => {
      i_functionOnSucces(fileReaderClass.result);
    };
  }

  if(is_function(i_functionOnError)) {
    fileReaderClass.onerror = i_functionOnError;
  }

  fileReaderClass.readAsText(i_dataTransferFileObj, "ISO-8859-1");
}


export function read_csv_file_data_string_into_arrayOfArrays(i_csvFileDataString) {
  if(!is_string(i_csvFileDataString)) {
    return([]);
  }

  var prevChar = "";
  var rowArray = [""];
  var csvDataArrayOfArrays = [rowArray]; //csvDataArrayOfArrays[rowIndex][columnIndex]
  var rowIndex = 0;
  var columnIndex = 0;
  var outsideOfDoubleQuotesTF = true;
  for(let char of i_csvFileDataString) {
    if(char === '"') {
      if(outsideOfDoubleQuotesTF && (char === prevChar)) { //two double quotes in a row "" is how a csv escapes a single " when inside of cell contents that are surrounded by "" ( "cell ""contents"""  =>  cell "contents")
        rowArray[columnIndex] += char;
      }
      outsideOfDoubleQuotesTF = !outsideOfDoubleQuotesTF;
    }
    else if(outsideOfDoubleQuotesTF && (char === ",")) {
      columnIndex++;
      char = rowArray[columnIndex] = "";
    }
    else if(outsideOfDoubleQuotesTF && (char === "\n")) {
      if(prevChar === "\r") {
        rowArray[columnIndex] = rowArray[columnIndex].slice(0, -1);
      }
      rowIndex++;
      rowArray = csvDataArrayOfArrays[rowIndex] = [""];
      columnIndex = 0;
      char = "";
    }
    else {
      rowArray[columnIndex] += char;
    }
    prevChar = char;
  }

  //loop through all rows and remove any where every cell is blank, also count # columns in each row to find the greatest # columns in any row (should all be equal)
  var csvDataBlankRowsRemovedArrayOfArrays = [];
  var greatestNumColumns = 0;
  for(let rowArray of csvDataArrayOfArrays) {
    var allRowCellsAreBlankTF = true;
    for(let cellString of rowArray) {
      if(cellString !== "") {
        allRowCellsAreBlankTF = false;
        break;
      }
    }

    if(!allRowCellsAreBlankTF) {
      csvDataBlankRowsRemovedArrayOfArrays.push(rowArray);
    }

    //get the number of columns in this row
    var rowNumColumns = rowArray.length;
    if(rowNumColumns > greatestNumColumns) {
      greatestNumColumns = rowNumColumns;
    }
  }

  //loop through all columns and remove any where every cell is blank
  var blankColumnIndicesArray = [];
  for(let columnIndex = 0; columnIndex < greatestNumColumns; columnIndex++) {
    var allColumnCellsAreBlankTF = true;
    for(let rowArray of csvDataBlankRowsRemovedArrayOfArrays) {
      var cellString = rowArray[columnIndex];
      if(cellString !== "") {
        allColumnCellsAreBlankTF = false;
        break;
      }
    }

    if(allColumnCellsAreBlankTF) {
      blankColumnIndicesArray.push(columnIndex); //note column indices that are blank to remove in the next loop
    }
  }

  //loop through creating a new arrayOfArrays omitting the blank columns
  var csvDataBlankRowsAndColumnsRemovedArrayOfArrays = [];
  if(blankColumnIndicesArray.length === 0) { //if there are no blank columns to remove, return the arrayOfArrays
    csvDataBlankRowsAndColumnsRemovedArrayOfArrays = csvDataBlankRowsRemovedArrayOfArrays;
  }
  else {
    for(let rowArray of csvDataBlankRowsRemovedArrayOfArrays) {
      var rowBlankColumnsRemovedArray = [];
      for(let columnIndex = 0; columnIndex < rowArray.length; columnIndex++) {
        if(!in_array(columnIndex, blankColumnIndicesArray)) { //don't include blank column indices in the final output
          rowBlankColumnsRemovedArray.push(rowArray[columnIndex]);
        }
      }
      csvDataBlankRowsAndColumnsRemovedArrayOfArrays.push(rowBlankColumnsRemovedArray);
    }
  }

  //loop through every cell to remove unicode characters higher than utf-8
  var csvDataUnicodeRemovedArrayOfArrays = [];
  for(let rowArray of csvDataBlankRowsAndColumnsRemovedArrayOfArrays) {
    var rowUnicodeRemovedArray = [];
    for(let cellString of rowArray) {
      var cellStringUnicodeRemoved = "";
      if(cellString !== "") {
        cellStringUnicodeRemoved = remove_unicode_from_string(cellString);
      }
      rowUnicodeRemovedArray.push(cellStringUnicodeRemoved);
    }
    csvDataUnicodeRemovedArrayOfArrays.push(rowUnicodeRemovedArray);
  }

  return(csvDataUnicodeRemovedArrayOfArrays);
}


export function read_csv_file_data_string_into_single_array_of_all_values(i_csvFileDataString) {
  //looks for "\n" and "," to break up the file into all rows and columns and headers into a single array
  const csvDataArrayOfArrays = read_csv_file_data_string_into_arrayOfArrays(i_csvFileDataString);

  var stringsArray = [];
  for(let rowArray of csvDataArrayOfArrays) {
    for(let cellString of rowArray) {
      stringsArray.push(cellString);
    }
  }

  return(stringsArray);
}


export function convert_data_table_to_csv_string(i_dataTable) {
  //i_dataTable is an arrayOfArrays of strings
  return(
    i_dataTable.map((m_row) =>
      m_row.map((m_cell) => {
        if(is_string(m_cell) && m_cell.replace(/ /g, '').match(/[\s,"]/)) { //we remove blanks and check if the column contains other whitespace,`,` or `"`, in that case, we need to quote the column
          return('"' + m_cell.replace(/"/g, '""') + '"');
        }
        return(m_cell);
      }).join(',')
    ).join('\n')
  );
}


export function strip_tags(i_stringWithTags) {
  if(is_string(i_stringWithTags)) {
    return(i_stringWithTags.replace(/<[^>]*>/g, ""));
  }
  return(i_stringWithTags);
}


export function htmlspecialchars(i_stringWithSpecialChars) {
  //convert the 5 special chars < > ' " & into their html special codes &lt; &gt; &quot; &#039; &amp; which is safe for powerpoint, excel, and word xml files
  if(!is_string(i_stringWithSpecialChars) || (i_stringWithSpecialChars === "")) {
    return(i_stringWithSpecialChars);
  }

  //Characters that need to be escaped (to obtain a well-formed document):
  //- The < must be escaped with a &lt; entity, since it is assumed to be the beginning of a tag.
  //- The & must be escaped with a &amp; entity, since it is assumed to be the beginning a entity reference
  //- The > should be escaped with &gt; entity. It is not mandatory -- it depends on the context -- but it is strongly advised to escape it.
  //- The ' should be escaped with a &apos; entity -- mandatory in attributes defined within single quotes but it is strongly advised to always escape it.
  //- The " should be escaped with a &quot; entity -- mandatory in attributes defined within double quotes but it is strongly advised to always escape it.
  var safeString = i_stringWithSpecialChars.replace(/&/g, "&amp;");
  safeString = safeString.replace(/"/g, "&quot;");
  safeString = safeString.replace(/'/g, "&#039;");
  safeString = safeString.replace(/</g, "&lt;");
  safeString = safeString.replace(/>/g, "&gt;");

  //Reference: see XML recommendation 1.0, §2.2 Characters (exclude 9 (tab), 10 (carriage), 13 (carriage))
  //- The global list of allowed characters is: #x9 | #xA | #xD | [#x20-#xD7FF] | [#xE000-#xFFFD] | [#x10000-#x10FFFF] (any Unicode character, excluding the surrogate blocks, FFFE, and FFFF)
  const asciiCharNumsToReplaceWithSpaceArray = concat_arrays_or_values_into_new_array(array_fill_incrementing_x_to_y(1,8), 11, 12, array_fill_incrementing_x_to_y(14,31));
  for(let asciiCharNum of asciiCharNumsToReplaceWithSpaceArray) {
    var asciiCharString = String.fromCharCode(asciiCharNum);
    var regex = new RegExp(asciiCharString, "g");
    safeString = safeString.replace(regex, " ");
  }

  return(safeString);
}



export function file_parts_obj(i_fileLoc) { //"/folder1/folder2/folder3/file.name.ext"
  var foldersPath = "";
  var fileNameAndExt = "";
  var fileName = "";
  var fileExt = "";

  var filePartsArray = i_fileLoc.split(/(\\|\/)/g); //split() array ["", "/", "folder1", "/", "folder2", "/", "folder3", "/", "file.name.ext"]
  fileNameAndExt = filePartsArray.pop(); //pop the last string ("file.name.ext") from the split() array
  filePartsArray.pop(); //take off the last "/" between the folders and the filenameAndExt
  foldersPath = filePartsArray.join(""); //"/folder1/folder2/folder3"
  var fileNameSplitDotArray = fileNameAndExt.split("."); //["file", "name", "ext"]
  if(fileNameSplitDotArray.length === 1) { //file has no extension
    fileExt = "";
    fileName = fileNameAndExt; //"file.name"
  }
  else {
    fileExt = fileNameSplitDotArray.pop(); //"ext"
    fileExt = fileExt.toLowerCase();
    fileName = fileNameSplitDotArray.join("."); //"file.name"
  }

  return({
    foldersPath: foldersPath,
    fileNameAndExt: fileNameAndExt,
    fileName: fileName,
    fileExt: fileExt
  });
}


export function db_name_from_display_name(i_displayName) {
  return(i_displayName.toLowerCase().replace(/[^a-z0-9]/g, "_"));
}


export function js_description_from_action(i_jsFileName, i_actionName, i_inputNamesArray, i_argumentsArray, i_jsDescriptionFromHigherCaller=undefined) {
  var output = i_jsFileName + " - " + i_actionName + "()";
  for(let i = 0; i < i_inputNamesArray.length; i++) { //prevent printing the jsDescription as the last variable since it is reprinted below, only print the number of input names given
    output += "\r\n - " + i_inputNamesArray[i] + ": " + print(i_argumentsArray[i]);
  }

  if(i_jsDescriptionFromHigherCaller) {
    output = i_jsDescriptionFromHigherCaller + "\r\n" + output;
  }

  return(output);
}






//=====================================================================================================================================================
//MapOfMaps
//=====================================================================================================================================================
export function mapOfMaps_from_arrayOfObjs(i_arrayOfObjs) {
  //i_arrayOfObjs = [         =>    mapOfMaps = Map(
  //  {id:3, name: "three"},          [3, Map( ["id", 3], ["name", "three"] )],
  //  {id:1, name: "one"},            [1, Map( ["id", 1], ["name", "one"] )],
  //  {id:2, name: "two"}             [2, Map( ["id", 2], ["name", "two"] )],
  //]                               )
  var mapOfMaps = new Map();
  for(let a = 0; a < i_arrayOfObjs.length; a++) {
    if(!i_arrayOfObjs[a].hasOwnProperty("id")) { //ensure the obj has a field called "id"
      return(undefined);
    }

    //populate the map with the objs converted to Maps
    mapOfMaps.set(i_arrayOfObjs[a].id, map_from_obj(i_arrayOfObjs[a]));
  }
  return(mapOfMaps);
}

export function arrayOfObjs_from_mapOfMaps(i_mapOfMaps, i_sortByProperty=undefined, i_sortIsAscTF=undefined) {
  var arrayOfObjs = [];
  for(let innerMap of i_mapOfMaps.values()) {
    arrayOfObjs.push(obj_from_map(innerMap));
  }
  if(i_sortByProperty && arrayOfObjs.length > 0) { //optional sorting the final matrix by a provided field in the arrayOfObjs
    const sortByFunction = ((i_sortIsAscTF) ? (sort_by_asc(i_sortByProperty)) : (sort_by_desc(i_sortByProperty)));
    arrayOfObjs.sort(sortByFunction);
  }
  return(arrayOfObjs);
}

export function map_from_obj(i_obj) {
  var map = new Map();
  single_map_update_or_insert_values_from_obj(map, i_obj);
  return(map);
}

export function obj_from_map(i_map) {
  var obj = {};
  for(let [key, value] of i_map) {
    obj[key] = value;
  }
  return(obj);
}

export function single_map_update_or_insert_values_from_obj(i_map, i_dataObj) {
  for(var key in i_dataObj) {
    if(i_dataObj.hasOwnProperty(key)) {
      i_map.set(key, i_dataObj[key]);
    }
  }
}

export function filtered_mapOfMaps_from_id_array_or_comma(i_mapOfMaps, i_idArrayOrComma, i_doesNotExistNameString) {
  //gets maps in the order of the id numbers
  //this function skips over ids that do not exist, does not handle --Does Not Exist-- entries
  var idArray = i_idArrayOrComma;
  if(!is_array(i_idArrayOrComma)) {
    idArray = convert_comma_list_to_int_array(i_idArrayOrComma);
  }

  var filteredMapOfMaps = new Map();
  for(let id of idArray) {
    var itemMap = i_mapOfMaps.get(id);
    if(itemMap) {
      filteredMapOfMaps.set(id, itemMap);
    }
    else {
      var doesNotExistItemMap = new Map();
      doesNotExistItemMap.set("id", id);
      doesNotExistItemMap.set("name", "--" + i_doesNotExistNameString + " Does Not Exist (ID: " + id + ")--");
      filteredMapOfMaps.set(id, doesNotExistItemMap);
    }
  }
  return(filteredMapOfMaps);
}

export function filtered_mapOfMaps_from_matching_field_value(i_mapOfMaps, i_fieldNameOrFieldNamesArrayToCheck, i_valueOrValuesArrayToCompare) {
  var filteredMapOfMaps = new Map();
  if(is_string(i_fieldNameOrFieldNamesArrayToCheck)) { //input fieldName is a single field string
    const fieldNameToCheck = i_fieldNameOrFieldNamesArrayToCheck;
    if(!is_array(i_valueOrValuesArrayToCompare)) { //input value is a single value (int or string) to compare field to
      const valueToCompare = i_valueOrValuesArrayToCompare;
      for(let [itemID, itemMap] of i_mapOfMaps) {
        if(itemMap.get(fieldNameToCheck) === valueToCompare) { //include only submaps where field = value
          filteredMapOfMaps.set(itemID, itemMap);
        }
      }
    }
    else { //input valuesArray is an array of values that the field can be equal to
      const valuesArrayToCompare = i_valueOrValuesArrayToCompare;
      if(fieldNameToCheck.substring(0,1) === "!") { //can also provide "!field" as the fieldName, which is field != (value1 OR value2 OR value3)
        const notFieldNameToCheck = fieldNameToCheck.substring(1, fieldNameToCheck.length);
        for(let [itemID, itemMap] of i_mapOfMaps) {
          if(valuesArrayToCompare.indexOf(itemMap.get(notFieldNameToCheck)) < 0) { //include only submaps where field != (value1 OR value2 OR value3)
            filteredMapOfMaps.set(itemID, itemMap);
          }
        }
      }
      else {
        for(let [itemID, itemMap] of i_mapOfMaps) {
          if(valuesArrayToCompare.indexOf(itemMap.get(fieldNameToCheck)) >= 0) { //include only submaps where field = (value1 OR value2 OR value3)
            filteredMapOfMaps.set(itemID, itemMap);
          }
        }
      }
    }
  }
  else if(is_array(i_fieldNameOrFieldNamesArrayToCheck)) { //input fieldNamesArray is an array of fieldName strings
    //include only submaps where (field1 = value1) AND (field2 = value2) AND (field3 = value3)
    const fieldNamesArrayToCheck = i_fieldNameOrFieldNamesArrayToCheck;
    const valuesArrayToCompare = i_valueOrValuesArrayToCompare;
    const numFields = fieldNamesArrayToCheck.length;
    var allValuesMatch = true;
    for(let [itemID, itemMap] of i_mapOfMaps) {
      allValuesMatch = true;
      for(let fv = 0; fv < numFields; fv++) {
        if(itemMap.get(fieldNamesArrayToCheck[fv]) !== valuesArrayToCompare[fv]) {
          allValuesMatch = false;
          break;
        }
      }

      if(allValuesMatch) {
        filteredMapOfMaps.set(itemID, itemMap);
      }
    }
  }
  return(filteredMapOfMaps);
}

export function filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(i_mapOfMaps, i_fieldNameOrFieldNamesArrayToCheck, i_valueOrValuesArrayToCompare, i_sortFieldNameOrNamesArray=undefined, i_sortIsAscTFOrArray=undefined, i_sortCaseInsensitiveTF=false) {
  var arrayOfObjs = [];
  if(is_array(i_fieldNameOrFieldNamesArrayToCheck)) { //input fieldNamesArray is an array of fieldName strings, include only submaps where (field1 = value1) AND (field2 = value2) AND (field3 = value3)
    const numFields = i_fieldNameOrFieldNamesArrayToCheck.length;
    var allValuesMatch = true;
    for(let [itemID, itemMap] of i_mapOfMaps) {
      allValuesMatch = true;
      for(let fv = 0; fv < numFields; fv++) {
        if(itemMap.get(i_fieldNameOrFieldNamesArrayToCheck[fv]) !== i_valueOrValuesArrayToCompare[fv]) {
          allValuesMatch = false;
          break;
        }
      }

      if(allValuesMatch) {
        arrayOfObjs.push(obj_from_map(itemMap));
      }
    }
  }
  else {
    for(let itemMap of i_mapOfMaps.values()) {
      if(itemMap.get(i_fieldNameOrFieldNamesArrayToCheck) === i_valueOrValuesArrayToCompare) {
        arrayOfObjs.push(obj_from_map(itemMap));
      }
    }
  }

  if(i_sortFieldNameOrNamesArray !== undefined) {
    sort_arrayOfObjs(arrayOfObjs, i_sortFieldNameOrNamesArray, i_sortIsAscTFOrArray, i_sortCaseInsensitiveTF);
  }

  return(arrayOfObjs);
}

export function get_first_map_matching_field_value(i_mapOfMaps, i_fieldNameOrFieldNamesArrayToCheck, i_valueOrValuesArrayToCompare) {
  if(!is_array(i_fieldNameOrFieldNamesArrayToCheck)) {
    const fieldNameToCheck = i_fieldNameOrFieldNamesArrayToCheck;
    if(!is_array(i_valueOrValuesArrayToCompare)) {
      //include only submaps where field = value
      const valueToCompare = i_valueOrValuesArrayToCompare;
      for(let itemMap of i_mapOfMaps.values()) {
        if(itemMap.get(fieldNameToCheck) === valueToCompare) {
          return(itemMap);
        }
      }
    }
    else {
      //include only submaps where field = value1 OR value2 OR value3
      const valuesArrayToCompare = i_valueOrValuesArrayToCompare;
      if(valuesArrayToCompare.length === 0) {
        return(undefined); //no values given to compare to, which will return no matches, no need to search
      }

      for(let itemMap of i_mapOfMaps.values()) {
        if(valuesArrayToCompare.indexOf(itemMap.get(fieldNameToCheck)) >= 0) {
          return(itemMap);
        }
      }
    }
  }
  else {
    //include only submaps where field1 = value1 AND field2 = value2 AND field3 = value3
    const fieldNamesArrayToCheck = i_fieldNameOrFieldNamesArrayToCheck;
    const valuesArrayToCompare = i_valueOrValuesArrayToCompare;
    const numFields = fieldNamesArrayToCheck.length;
    var allValuesMatch = true;
    for(let itemMap of i_mapOfMaps.values()) {
      allValuesMatch = true;
      for(let fv = 0; fv < numFields; fv++) {
        if(itemMap.get(fieldNamesArrayToCheck[fv]) !== valuesArrayToCompare[fv]) {
          allValuesMatch = false;
          break;
        }
      }

      if(allValuesMatch) {
        return(itemMap);
      }
    }
  }
  return(undefined);
}

export function get_first_map_matching_value_to_any_in_array_of_single_field(i_mapOfMaps, i_mapFieldNameWithArrayData, i_valueToCompare) {
  for(let itemMap of i_mapOfMaps.values()) {
    var itemMapFieldArrayOfPossibleValues = itemMap.get(i_mapFieldNameWithArrayData);
    if(in_array(i_valueToCompare, itemMapFieldArrayOfPossibleValues)) {
      return(itemMap);
    }
  }
  return(undefined);
}

export function get_column_vector_from_mapOfMaps(i_mapOfMaps, i_propertyName) {
  var columnVector = [];
  for(let itemMap of i_mapOfMaps.values()) {
    columnVector.push(itemMap.get(i_propertyName));
  }
	return(columnVector);
}

export function get_column_vector_from_mapOfMaps_matching_field_value(i_mapOfMaps, i_fieldNameToCheck, i_valueToCompare, i_propertyName) {
  var columnVector = [];
  for(let itemMap of i_mapOfMaps.values()) {
    if(itemMap.get(i_fieldNameToCheck) === i_valueToCompare) {
      columnVector.push(itemMap.get(i_propertyName));
    }
  }
	return(columnVector);
}

export function get_column_vector_from_mapOfMaps_matching_field_array_of_values(i_mapOfMaps, i_fieldNameToCheck, i_valuesArrayToCompare, i_propertyName) {
  const numValuesToCompare = i_valuesArrayToCompare.length;
  if(numValuesToCompare === 0) {
    return([]);
  }

  if(numValuesToCompare === 1) {
    return(get_column_vector_from_mapOfMaps_matching_field_value(i_mapOfMaps, i_fieldNameToCheck, i_valuesArrayToCompare[0], i_propertyName));
  }

  var columnVector = [];
  for(let itemMap of i_mapOfMaps.values()) {
    if(in_array(itemMap.get(i_fieldNameToCheck), i_valuesArrayToCompare)) {
      columnVector.push(itemMap.get(i_propertyName));
    }
  }
	return(columnVector);
}

export function get_column_vector_from_mapOfMaps_matching_multiple_fields_and_values(i_mapOfMaps, i_fieldNamesArrayToCheck, i_valuesArrayToCompare, i_propertyName) {
  //include only submaps where (field1 = value1) AND (field2 = value2) AND (field3 = value3)
  const numFields = i_fieldNamesArrayToCheck.length;
  var columnVector = [];
  var allValuesMatch = true;
  for(let itemMap of i_mapOfMaps.values()) {
    allValuesMatch = true;
    for(let fv = 0; fv < numFields; fv++) {
      if(itemMap.get(i_fieldNamesArrayToCheck[fv]) !== i_valuesArrayToCompare[fv]) {
        allValuesMatch = false;
        break;
      }
    }

    if(allValuesMatch) {
      columnVector.push(itemMap.get(i_propertyName));
    }
  }
	return(columnVector);
}


export function get_array_of_map_column_values_from_ids_array_if_exist(i_mapOfMaps, i_idsArray, i_columnName) {
  //i_mapOfMaps: ((1, id:1, name:"One"), (2, id:2, name:"Two"), (3, id:3, name:"Three"))
  //i_idsArray: [3,6,1]
  //i_columnName: "name"
  //output mapColumnValuesArray: ["Three", "One"]     (skips over 6 because it does not exist as a Map id within the mapOfMaps)
  var mapColumnValuesArray = [];
  if(is_array(i_idsArray) && is_string(i_columnName)) {
    for(let id of i_idsArray) {
      var matchingMapOrUndefined = i_mapOfMaps.get(id);
      if(matchingMapOrUndefined !== undefined) {
        mapColumnValuesArray.push(matchingMapOrUndefined.get(i_columnName));
      }
    }
  }
  return(mapColumnValuesArray);
}


export function determine_if_every_id_within_mapOfMaps_is_preset_in_input_int_comma_list_tf(i_mapOfMaps, i_idFieldName, i_commaListOfIntIDsToCheck) {
  const arrayOfIntIDsToCheck = convert_comma_list_to_int_array(i_commaListOfIntIDsToCheck);
  return(determine_if_every_id_within_mapOfMaps_is_preset_in_input_array_tf(i_mapOfMaps, i_idFieldName, arrayOfIntIDsToCheck));
}


export function determine_if_every_id_within_mapOfMaps_is_preset_in_input_array_tf(i_mapOfMaps, i_idFieldName, i_arrayOfIDsToCheck) {
  //i_mapOfMaps: ((1, id:1, name:"One"), (2, id:2, name:"Two"), (3, id:3, name:"Three"))
  //i_idFieldName: "id"
  //i_arrayOfIDsToCheck: [] false, [1,2] false, [1,2,4] false, [1,3,2] true, [1,2,3,4,5] true
  for(let itemMap of i_mapOfMaps.values()) {
    if(!in_array(itemMap.get(i_idFieldName), i_arrayOfIDsToCheck)) {
      return(false);
    }
  }
  return(true);
}


export function max_value_from_mapOfMaps_column(i_mapOfMaps, i_columnName) {
  if(!i_mapOfMaps) {
    return(-1); //-1 flag that table does not exist
  }

  if(i_mapOfMaps.size === 0) {
    return(0); //map is empty, return 0 as the max value
  }

  var columValuesArray = get_column_vector_from_mapOfMaps(i_mapOfMaps, i_columnName); //get all the values in the column as an array
  var columnMaxValue = array_max(columValuesArray); //find the max of that array
  return(columnMaxValue);
}


export function array_of_keys_from_map(i_map) {
  var keysArray = [];
  for(let key of i_map.keys()) {
    keysArray.push(key);
  }
  return(keysArray);
}

export function copy_map(i_map) {
  var copyMap = new Map();
  for(let [key, value] of i_map) {
    copyMap.set(key, value);
  }
  return(copyMap);
}

export function copy_obj(i_obj) {
  //copies at least first and second level of objs within obj
  return(Object.assign({}, i_obj));
}





//=====================================================================================================================================================
//ArrayOfObjs
//=====================================================================================================================================================
export function filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(i_arrayOfObjs, i_fieldName, i_value) {
  const filterFunction = (i_obj) => { return(i_obj[i_fieldName] === i_value); };
  const filteredArrayOfObjs = i_arrayOfObjs.filter(filterFunction); //creates a new arrayOfObjs
  return(filteredArrayOfObjs);
}

export function filtered_arrayOfObjs_from_arrayOfObjs_not_matching_single_field_value(i_arrayOfObjs, i_fieldName, i_valueToNotMatch) {
  const filterFunction = (i_obj) => { return(i_obj[i_fieldName] !== i_valueToNotMatch); };
  const filteredArrayOfObjs = i_arrayOfObjs.filter(filterFunction); //creates a new arrayOfObjs
  return(filteredArrayOfObjs);
}

export function count_num_filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(i_arrayOfObjs, i_fieldName, i_value) {
  const filteredArrayOfObjs = filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(i_arrayOfObjs, i_fieldName, i_value);
  return(filteredArrayOfObjs.length);
}

export function filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(i_arrayOfObjs, i_fieldNameOrFieldNamesArrayToCheck, i_valueOrValuesArrayToCompare, i_sortFieldName=undefined, i_sortIsAscTF=undefined, i_sortCaseInsensitiveTF=false) {
  const fieldNamesArrayToCheck = convert_single_or_array_to_array(i_fieldNameOrFieldNamesArrayToCheck);
  const valuesArrayToCompare = convert_single_or_array_to_array(i_valueOrValuesArrayToCompare);

  const filterFunction = (i_obj) => {
    for(let f = 0; f < fieldNamesArrayToCheck.length; f++) {
      if(i_obj[fieldNamesArrayToCheck[f]] !== valuesArrayToCompare[f]) { //first field/value pair that doesn't match the input, return false and reject this row from the final filter set
        return(false);
      }
    }
    return(true);
  };

  var filteredArrayOfObjs = i_arrayOfObjs.filter(filterFunction); //creates a new arrayOfObjs

  if(i_sortFieldName !== undefined) {
    sort_arrayOfObjs(filteredArrayOfObjs, i_sortFieldName, i_sortIsAscTF, i_sortCaseInsensitiveTF);
  }

  return(filteredArrayOfObjs);
}

export function get_first_obj_from_arrayOfObjs_matching_field_value(i_arrayOfObjs, i_fieldNameOrFieldNamesArrayToCheck, i_valueOrValuesArrayToCompare) {
  if(is_array(i_fieldNameOrFieldNamesArrayToCheck)) { //multiple field/value pairs to check
    const numFields = i_fieldNameOrFieldNamesArrayToCheck.length;
    for(let obj of i_arrayOfObjs) {
      var allValuesMatchTF = true;
      for(let f = 0; f < numFields; f++) {
        if(obj[i_fieldNameOrFieldNamesArrayToCheck[f]] !== i_valueOrValuesArrayToCompare[f]) {
          allValuesMatchTF = false;
          break;
        }
      }

      if(allValuesMatchTF) {
        return(obj);
      }
    }
  }
  else { //single field/value to check
    for(let obj of i_arrayOfObjs) {
      if(obj[i_fieldNameOrFieldNamesArrayToCheck] === i_valueOrValuesArrayToCompare) {
        return(obj);
      }
    }
  }
  return(undefined);
}

export function get_first_obj_value_or_undefined_from_arrayOfObjs_matching_field_value(i_arrayOfObjs, i_fieldNameOrFieldNamesArrayToCheck, i_valueOrValuesArrayToCompare, i_outputFieldName, i_returnValueIfObjNotFound=undefined) {
  var firstObj = get_first_obj_from_arrayOfObjs_matching_field_value(i_arrayOfObjs, i_fieldNameOrFieldNamesArrayToCheck, i_valueOrValuesArrayToCompare);
  if(firstObj !== undefined) {
    return(firstObj[i_outputFieldName]);
  }
  return(i_returnValueIfObjNotFound);
}

export function filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(i_arrayOfObjs, i_outputFieldName, i_filterFieldName, i_filterValueOrValuesArray) {
  //i_arrayOfObjs: [
  //  {id:1,name:"One",capture_id:15},
  //  {id:2,name:"Two",capture_id:15},
  //  {id:3,name:"Three",capture_id:22},
  //  {id:4,name:"Four",capture_id:33}
  //]
  //i_outputFieldName: "name"
  //i_filterFieldName: "capture_id"
  //i_filterValueOrValuesArray: [15, 33]
  //output filteredArrayOfValues: ["One", "Two", "Four"]

  var filterValueIsArrayTF = is_array(i_filterValueOrValuesArray);

  var filteredArrayOfValues = [];

  if(filterValueIsArrayTF) {
    for(let obj of i_arrayOfObjs) {
      if(in_array(obj[i_filterFieldName], i_filterValueOrValuesArray)) {
        filteredArrayOfValues.push(obj[i_outputFieldName]);
      }
    }
  }
  else {
    for(let obj of i_arrayOfObjs) {
      if(obj[i_filterFieldName] === i_filterValueOrValuesArray) {
        filteredArrayOfValues.push(obj[i_outputFieldName]);
      }
    }
  }

  return(filteredArrayOfValues);
}

export function get_column_vector_from_arrayOfObjs(i_arrayOfObjs, i_propertyName) {
	return(i_arrayOfObjs.map((obj) => obj[i_propertyName]));
}

export function obj_with_field_matching_value_is_in_arrayOfObjs_tf(i_arrayOfObjs, i_fieldName, i_value) {
  for(let i_obj of i_arrayOfObjs) {
    if(i_obj[i_fieldName] === i_value) {
      return(true);
    }
  }
  return(false);
}

export function get_obj_id_or_m1_where_field_has_lowest_value(i_arrayOfObjs, i_idFieldName, i_searchForMinFieldName) {
  var lowestFieldValueObjIDOrM1 = -1; //-1 returned as a flag that there were 0 objs in the array
  if(is_array(i_arrayOfObjs)) {
    var lowestValueFoundSoFar = sort_max_mysqli_bigint();
    for(let obj of i_arrayOfObjs) {
      var objFieldValue = obj[i_searchForMinFieldName];
      if(objFieldValue < lowestValueFoundSoFar) {
        lowestValueFoundSoFar = objFieldValue;
        lowestFieldValueObjIDOrM1 = obj[i_idFieldName];
      }
    }
  }
  return(lowestFieldValueObjIDOrM1);
}

export function sum_of_arrayOfObjs_column(i_arrayOfObjs, i_columnName, i_sumValueIfEmpty) {
  if(i_arrayOfObjs.length === 0) {
    return(i_sumValueIfEmpty);
  }
  var sum = 0;
  for(let obj of i_arrayOfObjs) {
    sum += str2int(obj[i_columnName]);
  }
  return(sum);
}

export function inject_obj_into_arrayOfObjs_at_index(i_arrayOfObjs, i_newObj, i_newObjInsertIndex=undefined) {
  var newArrayOfObjs = [];
  var currentNewArrayIndex = 0;
  var newObjWasInsertedTF = false;
  for(let obj of i_arrayOfObjs) {
    if(currentNewArrayIndex === i_newObjInsertIndex) {
      newArrayOfObjs.push(i_newObj);
      currentNewArrayIndex++;
      newObjWasInsertedTF = true;
    }

    newArrayOfObjs.push(obj);
    currentNewArrayIndex++;
  }

  //places new obj at the end if i_newObjInsertIndex was not specified or was not within the range of the original array
  if(!newObjWasInsertedTF) {
    newArrayOfObjs.push(i_newObj);
  }

  return(newArrayOfObjs);
}

export function sort_array(i_array, i_sortIsAscTF=true, i_sortCaseInsensitiveTF=false) {
  //no need to sort an empty array or an array with only 1 item
  if(i_array.length < 2) {
    return(i_array);
  }

  if(i_sortIsAscTF) {
    if(i_sortCaseInsensitiveTF) {
      i_array.sort(sort_function_on_array_asc_case_insensitive());
    }
    else {
      i_array.sort(sort_function_on_array_asc());
    }
  }
  else {
    if(i_sortCaseInsensitiveTF) {
      i_array.sort(sort_function_on_array_desc_case_insensitive());
    }
    else {
      i_array.sort(sort_function_on_array_desc());
    }
  }
}

function sort_function_on_array_asc() {
  return(
    function(a,b) {
      return((a < b) ? (-1) : ((a > b) ? (1) : (0)));
    }
  );
}

function sort_function_on_array_desc() {
  return(
    function(a,b) {
      return((a < b) ? (1) : ((a > b) ? (-1) : (0)));
    }
  );
}

function sort_function_on_array_asc_case_insensitive() {
  return(
    function(a,b) {
      if(is_string(a) && is_string(b)) {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }
      return((a < b) ? (-1) : ((a > b) ? (1) : (0)));
    }
  );
}

function sort_function_on_array_desc_case_insensitive() {
  return(
    function(a,b) {
      if(is_string(a) && is_string(b)) {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }
      return((a < b) ? (1) : ((a > b) ? (-1) : (0)));
    }
  );
}

export function sort_arrayOfObjs(i_arrayOfObjs, i_propertyNameOrNamesArray, i_sortIsAscTFOrArray, i_sortCaseInsensitiveTF=false) {
  //no need to sort an empty array or an array with only 1 item
  if(i_arrayOfObjs.length < 2) {
    return(i_arrayOfObjs);
  }

  //this sorts the input arrayOfObjs in place, it does not create/return a copy
	const propertyNamesArray = convert_single_or_array_to_array(i_propertyNameOrNamesArray);
  const sortIsAscTFArray = convert_single_or_array_to_array(i_sortIsAscTFOrArray);
  for(let i = (propertyNamesArray.length-1); i >= 0; i--) {
  	if(sortIsAscTFArray[i]) {
      if(i_sortCaseInsensitiveTF) {
        i_arrayOfObjs.sort(sort_function_on_arrayOfObjs_asc_case_insensitive(propertyNamesArray[i]));
      }
      else {
        i_arrayOfObjs.sort(sort_by_asc(propertyNamesArray[i]));
      }
    }
  	else {
      if(i_sortCaseInsensitiveTF) {
        i_arrayOfObjs.sort(sort_function_on_arrayOfObjs_desc_case_insensitive(propertyNamesArray[i]));
      }
      else {
        i_arrayOfObjs.sort(sort_by_desc(propertyNamesArray[i]));
      }
    }
  }
}

export function sort_by_asc(property) {
  return(
    function(a,b) {
      var aValue = a[property];
      var bValue = b[property];
      return((aValue < bValue) ? (-1) : ((aValue > bValue) ? (1) : (0)));
    }
  );
}

export function sort_by_desc(property) {
  return(
    function(a,b) {
      var aValue = a[property];
      var bValue = b[property];
      return((aValue < bValue) ? (1) : ((aValue > bValue) ? (-1) : (0)));
    }
  );
}

function sort_function_on_arrayOfObjs_asc_case_insensitive(property) {
  return(
    function(a,b) {
      var aValue = a[property];
      var bValue = b[property];
      if(is_string(aValue) && is_string(bValue)) {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }
      return((aValue < bValue) ? (-1) : ((aValue > bValue) ? (1) : (0)));
    }
  );
}

function sort_function_on_arrayOfObjs_desc_case_insensitive(property) {
  return(
    function(a,b) {
      var aValue = a[property];
      var bValue = b[property];
      if(is_string(aValue) && is_string(bValue)) {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }
      return((aValue < bValue) ? (1) : ((aValue > bValue) ? (-1) : (0)));
    }
  );
}

export function create_data_arrayOfObjs_from_idOrIdArray_fieldName_and_value_arrays(i_idOrIdArray, i_fieldNamesArray, i_valuesArray) {
  //single id update case
  //  i_idOrIdArray: [4]           i_fieldNamesArray: ["name", "sort"]                 i_valuesArray: ["New Name 4", 4]
  //  dataArrayOfObjs: [{id:4, name:"New Name 4", sort:4}]
  //multiple id update case
  //   i_idOrIdArray: [1,4,7]      i_fieldNamesArray: ["flagToReset", "otherFlag"]     i_valuesArray: [0, 2]
  //  dataArrayOfObjs: [{id:1, flagToReset:0, otherFlag:2}, {id:4, flagToReset:0, otherFlag:2}, {id:7, flagToReset:0, otherFlag:2}]
  const idArray = convert_single_or_array_to_array(i_idOrIdArray);
  const numFields = i_fieldNamesArray.length;
  var dataArrayOfObjs = [];
  for(let id of idArray) {
    var dataObj = {id:id}; //hardcode a field called id into the object for the Map() update/inserts to work
    for(let f = 0; f < numFields; f++) {
      dataObj[i_fieldNamesArray[f]] = i_valuesArray[f];
    }
    dataArrayOfObjs.push(dataObj);
  }
  return(dataArrayOfObjs);
}


export function drag_drop_resort_arrayOfObjs(i_arrayOfObjsWithIDAndSort, i_idColumnName, i_sortColumnName, i_draggedItemID, i_droppedOnItemID, i_resortAboveTrueBottomFalseNoneUndefined) {
  //i_arrayOfObjsWithIDAndSort
  //  - all objs have at least the idColumn field and the sortColumn field, both as positive ints, it is sorted ASC within this function
  //i_resortAboveTrueBottomFalseNoneUndefined
  //  - true        moves the dragged item above the dropped item's position
  //  - false       moves the dragged item to the bottom of the entire list
  //  - undefined   does not change the existing order of the items, but recounts to ensure all their sort numbers count strictly 1-N
  //
  //returns a newly created resortArrayOfObjs with exactly 3 fields per obj: "id", "oldSort", "newSort", newSort counts 1-N through the array

  //sort the input arrayOfObjs by the sort column
  sort_arrayOfObjs(i_arrayOfObjsWithIDAndSort, i_sortColumnName, true);

  //look up the sort value of the obj corresponding to the dragged item id
  var draggedRowOldSort = undefined;
  var resortAboveTrueBottomFalseNoneUndefined = i_resortAboveTrueBottomFalseNoneUndefined; //initialize as the input flag
  if(i_resortAboveTrueBottomFalseNoneUndefined !== undefined) { //only need this value if this flag is true or false
    for(let obj of i_arrayOfObjsWithIDAndSort) {
      if(obj[i_idColumnName] === i_draggedItemID) {
        draggedRowOldSort = obj[i_sortColumnName];
        break;
      }
    }

    //if the dragged item id cannot be found in the input arrayOfObjs, the flag is switched to use the no change undefined mode
    if(draggedRowOldSort === undefined) { //draggedItemID was not found to match any of the ids in the input arrayOfObjs
      resortAboveTrueBottomFalseNoneUndefined = undefined; //force the flag to change to undefined for a no movement resort
    }
  }

  //calculate the new sort positions building a new array of data
  const numItems = i_arrayOfObjsWithIDAndSort.length;
  var resortArrayOfObjs = [];
  var newSortNum = 1; //start counting for the new sort numbers at 1 and increment for each item
  for(let i = 0; i < numItems; i++) {
    var itemRowID = i_arrayOfObjsWithIDAndSort[i][i_idColumnName];
    var itemOldSort = i_arrayOfObjsWithIDAndSort[i][i_sortColumnName];

    if(resortAboveTrueBottomFalseNoneUndefined === true) { //true move dragged item above droppedOn item
      //if this is the dropped on row, inject the dragged row in before it
      if(itemRowID === i_droppedOnItemID) {
        resortArrayOfObjs.push({id:i_draggedItemID, oldSort:draggedRowOldSort, newSort:newSortNum});
        newSortNum++;
      }

      //if this row is the dragged row, do not push it as it was/will be pushed when the dropped on row is detected
      if(itemRowID !== i_draggedItemID) { //not dragged item
        resortArrayOfObjs.push({id:itemRowID, oldSort:itemOldSort, newSort:newSortNum});
        newSortNum++;
      }
    }
    else if(resortAboveTrueBottomFalseNoneUndefined === false) { //false move dragged item to bottom
      //if this row is the dragged row, do not push it as it will be pushed at the end
      if(itemRowID !== i_draggedItemID) { //not dragged item
        resortArrayOfObjs.push({id:itemRowID, oldSort:itemOldSort, newSort:newSortNum});
        newSortNum++;
      }

      //if this is the last row, append the dragged row in after it
      if(i === (numItems - 1)) {
        resortArrayOfObjs.push({id:i_draggedItemID, oldSort:draggedRowOldSort, newSort:newSortNum});
        newSortNum++;
      }
    }
    else { //undefined resort existing list
      //for each item in the same order they were in, renumber the sorts counting 1-N
      resortArrayOfObjs.push({id:itemRowID, oldSort:itemOldSort, newSort:newSortNum});
      newSortNum++;
    }
  }

  return(resortArrayOfObjs);
}


export function create_where_id_IN_statement_from_id_array(i_tblIdFieldName, i_tblRowIdArray) {
  //i_tblIdFieldName: "id"     i_tblRowIdArray: [4,1,2]
  //whereStatementWithQuestionMarks: " WHERE `id` IN(?,?,?)"       whereValuesArray: [4,2,1]     whereValuesIdsbArray: ["i","i","i"]

  //if the id array provided is empty, return the where statement " WHERE `id` IN(-1)" with no question marks
  const numIDs = i_tblRowIdArray.length;
  if(numIDs === 0) {
      return([" WHERE `id` IN(-1)", [], []]);
  }

  var whereStatementWithQuestionMarks = " WHERE `" + i_tblIdFieldName + "` IN(";
  var whereValuesArray = i_tblRowIdArray;
  var whereValuesIdsbArray = [];
  for(let i = 0; i < numIDs; i++) {
    if(i > 0) {
      whereStatementWithQuestionMarks += ",";
    }
    whereStatementWithQuestionMarks += "?";
    whereValuesIdsbArray.push("i");
  }
  whereStatementWithQuestionMarks += ")";

  return([whereStatementWithQuestionMarks, whereValuesArray, whereValuesIdsbArray]);
}


export function message_obj(i_typeString, i_message) {
  return({type:i_typeString, message:i_message});
}


export function list_nonexistant_obj_properties_from_array(i_obj, i_arrayOfPropertyNamesToCheck) {
  var nonexistantPropertyNamesArray = [];
  for(let propertyName of i_arrayOfPropertyNamesToCheck) {
    if(i_obj[propertyName] === undefined) {
      nonexistantPropertyNamesArray.push(propertyName);
    }
  }

  if(nonexistantPropertyNamesArray.length > 0) {
    return(nonexistantPropertyNamesArray.join(", ")); //"nonexistantPropertyName1, nonexistantPropertyName2, nonexistantPropertyName3"
  }
  return(undefined); //returns undefined if all property names specified exist in the obj
}






//=====================================================================================================================================================
//Arrays
//=====================================================================================================================================================
export function is_array_not_empty(i_array) {
  //true if is an array with at least 1 item in it, false otherwise
  if(is_array(i_array)) {
    if(i_array.length > 0) {
      return(true);
    }
  }
  return(false);
}

export function array_fill(i_numEntries, i_valueToReplicate) {
  //array_fill(6, 0)  returns  [0, 0, 0, 0, 0, 0]
  var outputArray = [];
  for(let i = 0; i < i_numEntries; i++) {
    outputArray.push(i_valueToReplicate);
  }
  return(outputArray);
}

export function array_fill_incrementing_0_to_nm1(i_numItems) {
  var indexArray = []; //index array 0 to N-1
  for(let i = 0; i < i_numItems; i++) {
    indexArray.push(i);
  }
  return(indexArray);
}

export function array_fill_incrementing_x_to_xpnm1(i_startingValueX, i_numItems) {
  //i_startingValueX: 9, i_numItems: 4, outputCountArray: [9, 10, 11, 12]
  var outputCountArray = []; //count from x to (x + N - 1)
  for(let i = 0; i < i_numItems; i++) {
    outputCountArray.push(i_startingValueX + i);
  }
  return(outputCountArray);
}

export function array_fill_incrementing_x_to_y(i_startingValueX, i_endingValueY, i_skipValue=1) {
  var outputValueArray = [];
  for(let i = i_startingValueX; i <= i_endingValueY; i += i_skipValue) {
    outputValueArray.push(i);
  }
  return(outputValueArray);
}

export function zeros_arrayOfArrays(i_numRows, i_numColumns) {
  var zerosArrayOfArrays = [];
  for(let r = 0; r < i_numRows; r++) {
    zerosArrayOfArrays[r] = array_fill(i_numColumns, 0);
  }
  return(zerosArrayOfArrays);
}

export function array_fill_multistep_x_to_y_to_z(i_anchorPointsArray, i_stepsArray, i_maxNumDecimalsOrUndefined=undefined) {
  //i_anchorPointsArray: [1, 3, 10, 50]
  //i_stepsArray: [0.2, 1, 5]  (last index if this array is not used, since they represent the steps between the anchor values)
  //output: [1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50]
  const numAnchors = i_anchorPointsArray.length;

  const usingMaxNumDecimals = is_number_not_nan_gte_0(i_maxNumDecimalsOrUndefined);

  //loop through each step pair of anchors
  var outputArray = [];
  for(let a = 0; a < (numAnchors - 1); a++) {
    var currentAnchor = i_anchorPointsArray[a];
    var nextAnchor = i_anchorPointsArray[a + 1];
    var currentStep = i_stepsArray[a];

    //include the current anchor always as the start, even if the step is too big to fit between the next anchor
    outputArray.push(currentAnchor);

    //loop over all following steps until just short of the next anchor
    for(let v = (currentAnchor + currentStep); v < nextAnchor; v += currentStep) {
      var outputValue = v;
      if(usingMaxNumDecimals) {
        outputValue = remove_javascript_precision_decimal_float_math_errors(v, i_maxNumDecimalsOrUndefined);
      }
      outputArray.push(outputValue);
    }
  }

  //add the very last anchor to the end of the output array
  outputArray.push(i_anchorPointsArray[numAnchors - 1]);

  return(outputArray);
}


export function array1_equals_array2_tf(i_array1, i_array2) {
  if(!is_array(i_array1) || !is_array(i_array2)) {
    return(false);
  }

  const arrayLength = i_array1.length;
  if(arrayLength !== i_array2.length) {
    return(false);
  }

  if(arrayLength === 0) {
    return(true);
  }

  if(arrayLength === 1) {
    return(i_array1[0] === i_array2[0]);
  }

  i_array1.sort();
  i_array2.sort();
  for(let a = 0; a < arrayLength; a++) {
    if(i_array1[a] !== i_array2[a]) {
      return(false);
    }
  }

  return(true);
}

export function in_array(i_needle, i_haystackArray) { //emulates php in_array() using js indexOf() (returns -1 if the needle is not found in the array haystack)
  return(i_haystackArray.indexOf(i_needle) >= 0);
}

export function convert_single_or_array_to_array(i_singleOrArray) {
  if(is_array(i_singleOrArray)) {
    return(i_singleOrArray); //input already an array, return it as is
  }
  return([i_singleOrArray]); //input is a single value, thus encapsulate it in an array and return
}

export function all_of_array1_is_in_array2(i_array1, i_array2) {
  for(let value1 of i_array1) {
    if(i_array2.indexOf(value1) < 0) {
      return(false); //if any of the elements do not exist in array2, return false
    }
  }
  return(true);
}

export function any_of_array1_is_in_array2(i_array1, i_array2) {
  for(let value1 of i_array1) {
    if(i_array2.indexOf(value1) >= 0) {
      return(true); //if any of the elements exist in array2, return true
    }
  }
  return(false);
}

export function remove_all_values_from_array(i_valueOrArrayOfValuesToRemove, i_array) {
  //creates a new array, does not change the original
  //i_valueOrArrayOfValuesToRemove: 9
  //i_array: [3, 6, 9, 4, 9]
  //reducedArray = [3, 6, 4]
  var reducedArray = [];
  if(!is_array(i_valueOrArrayOfValuesToRemove)) {
    const valueToRemove = i_valueOrArrayOfValuesToRemove;
    for(let arrayValue of i_array) {
      if(arrayValue !== valueToRemove) { //onlykeep values from array that are not found in valueseToRemoveArray
        reducedArray.push(arrayValue);
      }
    }
  }
  else {
    const arrayOfValuesToRemove = i_valueOrArrayOfValuesToRemove;
    for(let arrayValue of i_array) {
      if(arrayOfValuesToRemove.indexOf(arrayValue) < 0) { //onlykeep values from array that are not found in valueseToRemoveArray
        reducedArray.push(arrayValue);
      }
    }
  }
  return(reducedArray);
}

export function remove_value_from_array_if_exists_otherwise_append(i_value, i_array) {
  if(!is_array(i_array)) {
    return([i_value]);
  }

  if((i_array.length === 0)) {
    return([i_value]);
  }

  if(in_array(i_value, i_array)) {
    return(remove_all_values_from_array(i_value, i_array));
  }

  var arrayCopy = copy_array(i_array);
  arrayCopy.push(i_value);
  return(arrayCopy);
}

export function remove_entries_matching_array1_values_from_array2(i_array2ValuesToRemoveArray, i_array2) {
	var outputArrayOfArrays = remove_entries_matching_array1_values_from_all_arrays(i_array2ValuesToRemoveArray, i_array2);
  return(outputArrayOfArrays[0]);
}

export function remove_entries_matching_array1_values_from_all_arrays(i_array1ValuesToRemoveArray, i_array1) { //, i_array2, i_array3, i_array4, ...   unlimited number of input arrays to filter based on values in i_array1
	const numArraysToFilter = (arguments.length - 1);

	//case where there are no values to remove, return the input arrays with no change
  var outputArrayOfArrays = [];
  if(i_array1ValuesToRemoveArray.length === 0) {
    for(let i = 0; i < numArraysToFilter; i++) {
      outputArrayOfArrays[i] = arguments[i+1];
    }
    return(outputArrayOfArrays);
  }

  //initialize output array of arrays
  for(let i = 0; i < numArraysToFilter; i++) {
  	outputArrayOfArrays[i] = [];
  }

  //for all provided input arrays, only push values where the array1 value was not found in the valuesToRemove array
  for(let a1 = 0; a1 < i_array1.length; a1++) {
  	var array1Value = i_array1[a1];
  	if(!in_array(array1Value, i_array1ValuesToRemoveArray)) {
    	for(let i = 0; i < numArraysToFilter; i++) {
        outputArrayOfArrays[i].push(arguments[i+1][a1]);
      }
    }
  }
  return(outputArrayOfArrays); //filtered version of all input arrays
}

export function remove_array_element_by_index(i_array, i_indexToRemove) {
  var newArray = [];
  for(let i = 0; i < i_array.length; i++) {
    if(i !== i_indexToRemove) {
      newArray.push(i_array[i]);
    }
  }
  return(newArray);
}

export function unique(i_array) {
  return(Array.from(new Set(i_array)));
}

export function concat_arrays_or_values_into_new_array() { //provide unlimited number of arrays (or single value ints/strings) as inputs to all be concatenated in order
  //concatArray = concat_arrays_or_values_into_new_array([1,2,3], 77, [25, "hey"], "you", 27, ["26"]) = [1,2,3,77,25,"hey","you",27,"26"]
  var concatArray = [];
  for(let a = 0; a < arguments.length; a++) {
    var inputArg = arguments[a];
    if(is_array(inputArg)) { //array input arg
      for(let value of inputArg) {
        concatArray.push(value);
      }
    }
    else { //single value number/string input
      concatArray.push(inputArg);
    }
  }
  return(concatArray);
}

export function merge_unique(i_array1, i_array2) {
  return(unique(concat_arrays_or_values_into_new_array(i_array1, i_array2)));
}

export function copy_array(i_array) {
  return(i_array.slice(0));
}

export function array_min(i_array) {
  return(Math.min(...i_array));
}

export function array_max(i_array) {
  return(Math.max(...i_array));
}

export function array_sum(i_array) {
  if(!is_array(i_array)) {
    return(0);
  }

  var sum = 0;
  for(let number of i_array) {
    sum += number;
  }
  return(sum);
}

export function array_mean(i_array) {
  if(!is_array(i_array)) {
    return(0);
  }

  var numEntries = i_array.length;
  if(numEntries === 0) {
    return(0);
  }

  var sum = 0;
  for(let number of i_array) {
    sum += number;
  }
  return(sum / numEntries);
}

export function value_from_array_indexed_1toN(i_index1toN, i_valuesArray, i_outOfRangeValue) {
  if(i_index1toN <= 0 || i_index1toN > i_valuesArray.length) {
    return(i_outOfRangeValue);
  }
  return(i_valuesArray[i_index1toN - 1]);
}

export function array_index_of_first_matching_value(i_array, i_valueToMatch, i_indexReturnedIfMatchNotFound=undefined) {
  if(is_array(i_array)) {
    for(let a = 0; a < i_array.length; a++) {
      if(i_array[a] === i_valueToMatch) {
        return(a);
      }
    }
  }
  return(i_indexReturnedIfMatchNotFound);
}

export function get_first_array2_value_or_undefined_where_array1_matches_input(i_array1ValueToMatch, i_array1, i_array2, i_returnIfArray1MatchNotFound=undefined) {
  const array1MatchIndex = array_index_of_first_matching_value(i_array1, i_array1ValueToMatch, undefined);
  if(array1MatchIndex !== undefined) {
    return(i_array2[array1MatchIndex]);
  }
  return(i_returnIfArray1MatchNotFound);
}

export function get_first_array2_value_and_found_match_tf_where_array1_matches_input(i_array1ValueToMatch, i_array1, i_array2, i_returnIfArray1MatchNotFound=undefined) {
  //i_array1ValueToMatch: 3
  //i_array1: [1, 3, 6]
  //i_array2: ["one", "three", "six"]
  //returns ["three", true]
  //i_array1ValueToMatch of 4 would return [i_returnIfArray1MatchNotFound, false]
  const array1MatchIndex = array_index_of_first_matching_value(i_array1, i_array1ValueToMatch);
  if(array1MatchIndex !== undefined) {
    return([i_array2[array1MatchIndex], true]);
  }
  return([i_returnIfArray1MatchNotFound, false]);
}

export function created_filtered_array1_where_array2_index_true(i_array1ToFilter, i_array2TF) {
  //i_array1ToFilter: [1, 2, 3, 4, 5, 6]
  //i_array2TF: [false, undefined, true, false, true, true]
  //returns [3, 5, 6]
  var outputFilteredArray = [];
  if(is_array(i_array1ToFilter) && is_array(i_array2TF)) {
    const array1Length = i_array1ToFilter.length;
    const array2Length = i_array2TF.length;
    if(array1Length === array2Length) {
      for(let a = 0; a < array1Length; a++) {
        if(i_array2TF[a]) {
          outputFilteredArray.push(i_array1ToFilter[a]);
        }
      }
    }
  }
  return(outputFilteredArray);
}

export function created_filtered_array1_where_array2_index_not_true(i_array1ToFilter, i_array2TF) {
  //i_array1ToFilter: [1, 2, 3, 4, 5, 6]
  //i_array2TF: [false, undefined, true, false, true, true]
  //returns [1, 2, 4]
  var outputFilteredArray = [];
  if(is_array(i_array1ToFilter) && is_array(i_array2TF)) {
    const array1Length = i_array1ToFilter.length;
    const array2Length = i_array2TF.length;
    if(array1Length === array2Length) {
      for(let a = 0; a < array1Length; a++) {
        if(i_array2TF[a] !== true) {
          outputFilteredArray.push(i_array1ToFilter[a]);
        }
      }
    }
  }
  return(outputFilteredArray);
}

export function get_comma_list_of_array2_values_where_array1_matches_input_comma_string(i_inputCommaStringWithOrWithoutSpaces, i_array1, i_array2, i_returnIfArray1MatchNotFound=undefined) {
  var inputArray1ValuesToMatchArray = convert_comma_list_with_or_without_spaces_to_array_blank_entries_removed(i_inputCommaStringWithOrWithoutSpaces);
  var matchingArray2ValuesArray = [];
  for(let array1ValueToMatch of inputArray1ValuesToMatchArray) {
    var matchingArray2Value = get_first_array2_value_or_undefined_where_array1_matches_input(array1ValueToMatch, i_array1, i_array2, undefined);
    if(matchingArray2Value !== undefined) {
      matchingArray2ValuesArray.push(matchingArray2Value);
    }
  }

  if(matchingArray2ValuesArray.length > 0) {
    return(convert_array_to_comma_list(matchingArray2ValuesArray)); //comma list string of matching values (usually id numbers like "3,15,6,12")
  }
  return(i_returnIfArray1MatchNotFound);
}






//=====================================================================================================================================================
//Objects
//=====================================================================================================================================================
export function merge_objs(i_obj1, i_obj2) {
  const obj1IsObjTF = is_obj(i_obj1);
  const obj2IsObjTF = is_obj(i_obj2);
  if(!obj1IsObjTF && !obj2IsObjTF) {
    return({});
  }
  else if(obj1IsObjTF && !obj2IsObjTF) {
    return(i_obj1);
  }
  else if(!obj1IsObjTF && obj2IsObjTF) {
    return(i_obj2);
  }

  var mergedObj = copy_obj(i_obj1);
  for(var propertyName in i_obj2) {
    if(i_obj2.hasOwnProperty(propertyName)) {
      mergedObj[propertyName] = i_obj2[propertyName];
    }
  }
  return(mergedObj);
}

export function get_property(i_dataMatrix, i_propertyString, i_valueIfNotExist) {
  //i_propertyString can be a single "property" or multiple nested "prop1.prop2.prop3"
  if(!i_dataMatrix) { return(i_valueIfNotExist); }
  const propertySplitArray = i_propertyString.split(".");
  var currentData = i_dataMatrix;
  for(let s = 0; s < propertySplitArray.length; s++) {
    var currentPropertyString = propertySplitArray[s];
    if(!currentData.hasOwnProperty(currentPropertyString)) { return(i_valueIfNotExist); }
    currentData = currentData[currentPropertyString];
  }
  return(currentData);
}


export function create_array_from_obj_and_array_of_property_names(i_obj, i_propertyNamesArray) {
  var outputArray = [];
  if(is_array(i_propertyNamesArray)) {
    for(let propertyName of i_propertyNamesArray) {
      outputArray.push(i_obj[propertyName]);
    }
  }
  return(outputArray);
}








//=====================================================================================================================================================
//Input 'Props'
//=====================================================================================================================================================
export function prop_value(i_propValueOrUndefined, i_valueIfUndefined) {
  if(i_propValueOrUndefined !== undefined) {
    return(i_propValueOrUndefined);
  }
  return(i_valueIfUndefined);
}

export function prop_number(i_propValueOrUndefined, i_valueIfUndefined) {
  if(is_number(i_propValueOrUndefined) && !isNaN(i_propValueOrUndefined)) {
    return(i_propValueOrUndefined);
  }
  return(i_valueIfUndefined);
}

export function prop_number_positive(i_propValueOrUndefined, i_valueIfUndefined) {
  if(is_number(i_propValueOrUndefined) && !isNaN(i_propValueOrUndefined) && (i_propValueOrUndefined > 0)) {
    return(i_propValueOrUndefined);
  }
  return(i_valueIfUndefined);
}

export function input_number_invalid_default_with_min_max_bounds(i_inputNumber, i_invalidNumberDefault, i_minValue, i_maxValue) {
  if(!is_number(i_inputNumber)) {
    return(i_invalidNumberDefault);
  }

  if(i_inputNumber < i_minValue) {
    return(i_minValue);
  }

  if(i_inputNumber > i_maxValue) {
    return(i_maxValue);
  }

  return(i_inputNumber);
}


export function is_empty(data) {
  if(is_number(data) || is_bool(data)) {  return(false); }
  if(is_undefined(data) || data === null) { return(true); }
  if(!is_undefined(data.length)) { return(data.length === 0); }
  var count = 0;
  for(var i in data) {
    if(data.hasOwnProperty(i)) {
      count++;
    }
  }
  return(count === 0);
}







//=====================================================================================================================================================
//Comma Lists
//=====================================================================================================================================================
export function convert_comma_list_to_array(i_commaListString) {
  if(is_string(i_commaListString)) {
    if(i_commaListString.length > 0) {
      return(i_commaListString.split(",")); //returns strings in an array
    }
  }
  return([]);
}

export function convert_comma_list_to_int_array(i_commaListString) {
  //handles input "1,3,6,4"   "1, 3,  6,4"   "1, 3,   6,,,a,a4, 4"   "3"   [1,3,6,4]   [3]   3   []   undefined
  //undefined or false or null
  if((i_commaListString === undefined) || (i_commaListString === false) || (i_commaListString === null)) {
    return([]);
  }

  //int or array
  if(!is_string(i_commaListString)) {
    return(convert_single_or_array_to_array(i_commaListString));
  }

  //empty string ""
  if(i_commaListString.length === 0) {
    return([]);
  }

  //string "1,3,6,4"
  const itemStringsArray = i_commaListString.split(",");
  var intArray = [];
  for(let itemString of itemStringsArray) {
    var itemInt = str2int(itemString);
    if(itemInt !== undefined) {
      intArray.push(itemInt); //convert to base10 ints
    }
  }
  return(intArray);
}

export function convert_unformatted_comma_list_to_int_only_comma_list(i_unformattedCommaList) {
  const intArray = convert_comma_list_to_int_array(i_unformattedCommaList);
  return(convert_array_to_comma_list(intArray));
}

export function convert_comma_list_with_or_without_spaces_to_array_blank_entries_removed(i_commaListWithOrWithoutSpaces) {
  //"1, 3,6 ,4"  ->  [1,3,6,4]
  //"one,two, three , four"  ->  ["one", "two", "three", "four"]
  var array = [];
  const semicolon1SplitArray = i_commaListWithOrWithoutSpaces.split(" ; ");
  for(let sc1 = 0; sc1 < semicolon1SplitArray.length; sc1++) {
    var semicolon1Segment = semicolon1SplitArray[sc1];
    var semicolon2SplitArray = semicolon1Segment.split("; ");
    for(let sc2 = 0; sc2 < semicolon2SplitArray.length; sc2++) {
      var semicolon2Segment = semicolon2SplitArray[sc2];
      var semicolon3SplitArray = semicolon2Segment.split(";");
      for(let sc3 = 0; sc3 < semicolon3SplitArray.length; sc3++) {
        var semicolon3Segment = semicolon3SplitArray[sc3];
        var commaWithDoubleSpaceSplitArray = semicolon3Segment.split(" , ");
        for(let c1 = 0; c1 < commaWithDoubleSpaceSplitArray.length; c1++) {
          var commaWithDoubleSpaceSegment = commaWithDoubleSpaceSplitArray[c1];
          var commaWithSpaceSplitArray = commaWithDoubleSpaceSegment.split(", ");
          for(let c2 = 0; c2 < commaWithSpaceSplitArray.length; c2++) {
            var commaWithSpaceSegment = commaWithSpaceSplitArray[c2];
            var commaSplitArray = commaWithSpaceSegment.split(",");
            for(let c3 = 0; c3 < commaSplitArray.length; c3++) {
              var commaSegment = commaSplitArray[c3];
              if(commaSegment !== "") {
                array.push(commaSegment);
              }
            }
          }
        }
      }
    }
  }
  return(array);
}

export function convert_array_to_comma_list(i_array, i_emptyArrayReturnString="") {
  if(!is_array(i_array)) {
    return(i_emptyArrayReturnString);
  }

  const numItems = i_array.length;
  if(numItems === 0) {
    return(i_emptyArrayReturnString);
  }

  var commaList = "";
  for(let a = 0; a < numItems; a++) {
    if(a > 0) {
      commaList += ",";
    }
    commaList += i_array[a];
  }
  return(commaList);
}

export function convert_array_to_display_comma_list(i_array, i_charAroundItems="") {
  if(!is_array(i_array)) {
    return("");
  }

  const numItems = i_array.length;
  if(numItems === 0) {
    return("");
  }

  var commaList = "";
  for(let a = 0; a < numItems; a++) {
    if(a > 0) {
      commaList += ", ";
    }
    commaList += i_charAroundItems + i_array[a] + i_charAroundItems;
  }
  return(commaList);
}

export function convert_array_to_display_comma_list_different_left_right(i_array, i_charAroundItemsLeft, i_charAroundItemsRight) {
  if(!is_array(i_array)) {
    return("");
  }

  const numItems = i_array.length;
  if(numItems === 0) {
    return("");
  }

  var commaList = "";
  for(let a = 0; a < numItems; a++) {
    if(a > 0) {
      commaList += ", ";
    }
    commaList += i_charAroundItemsLeft + i_array[a] + i_charAroundItemsRight;
  }
  return(commaList);
}

export function convert_array_to_list_string_with_separator(i_array, i_separatorChars, i_emptyArrayReturnString="") {
  if(!is_array(i_array)) {
    return(i_emptyArrayReturnString);
  }

  const numItems = i_array.length;
  if(numItems === 0) {
    return(i_emptyArrayReturnString);
  }

  var commaList = "";
  for(let a = 0; a < numItems; a++) {
    if(a > 0) {
      commaList += i_separatorChars;
    }
    commaList += i_array[a];
  }
  return(commaList);
}

export function add_value_to_comma_list(i_value, i_commaListString) {
  //can append an int to a comma list, or append another comma list to the end of the comma list
  if(i_commaListString === "") {
    return(num2str(i_value));
  }
  return(i_commaListString + "," + i_value); //returns a newly created comma list string (note: this does not change the original referenced value from i_commaListString)
}

export function remove_all_values_from_comma_list(i_valueOrArrayOfValuesToRemove, i_commaListString) {
  const oldArray = convert_comma_list_to_int_array(i_commaListString);
  const updatedArray = remove_all_values_from_array(i_valueOrArrayOfValuesToRemove, oldArray);
  return(convert_array_to_comma_list(updatedArray));
}

export function convert_all_occurances_of_string_to_different_string_in_comma_list(i_commaListString, i_searchString, i_replacementString) {
  //i_commaListString: "1,4,987987987987,3,4,987987987987,12"
  //i_searchString: "987987987987"
  //i_replacementString: "-2"
  //replacedCommaListString: "1,4,-2,3,4,-2,12"
  //fast case of a blank string where no replacement is needed
  if(i_commaListString === "") {
    return("");
  }

  const itemStringsArray = convert_comma_list_to_array(i_commaListString);
  var replacedStringsArray = [];
  for(let itemString of itemStringsArray) {
    if(itemString === i_searchString) {
      replacedStringsArray.push(i_replacementString);
    }
    else {
      replacedStringsArray.push(itemString);
    }
  }
  const replacedCommaListString = convert_array_to_comma_list(replacedStringsArray);
  return(replacedCommaListString);
}

export function convert_array_to_comma_list_with_oxford_and(i_array) {
  if(!is_array(i_array)) {
    return("");
  }

  const numEntries = i_array.length;
  if(numEntries === 0) {
    return("");
  }
  else if(numEntries === 1) {
    return(i_array.shift()); //"one"
  }
  else if(numEntries === 2) {
    return(i_array.shift() + " and " + i_array.shift()); //"one and two"
  }

  var commaList = "";
  for(let a = 0; a < numEntries; a++) {
    if(a > 0) {
      commaList += ", ";
      if(a === (numEntries - 1)) {
        commaList += "and ";
      }
    }
    commaList += i_array[a];
  }
  return(commaList);
}


export function count_comma_list_num_items(i_commaListString) {
  if(string_is_filled_out_tf(i_commaListString)) {
    const array = i_commaListString.split(",");
    return(array.length);
  }
  return(0);
}


export function convert_semicolon_list_to_array(i_semicolonListString) {
  if(is_string(i_semicolonListString)) {
    if(i_semicolonListString.length > 0) {
      return(i_semicolonListString.split(";")); //returns strings in an array
    }
  }
  return([]);
}






//=====================================================================================================================================================
//Colon Comma Lists
//=====================================================================================================================================================
export function convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, i_int1VarName, i_int2VarName, i_var2IsStringTF=false, i_sortFieldName=undefined, i_sortIsAscTF=true) {
  //i_colonCommaList: "1:9,2,3:15", i_int1VarName: "id", i_int2VarName: "percent"
  //intsArrayOfObjs: [{id:1, percent:9}, {id:2, percent:0}, {id:3, percent:15}]
  if(!is_string(i_colonCommaList) || !text_or_number_is_filled_out_tf(i_colonCommaList)) {
    return([]);
  }

  const int1ColonInt2Array = i_colonCommaList.split(","); //int1ColonInt2Array: ["1:9", "2", "3:15"]
  const numItems = int1ColonInt2Array.length;

  var intsArrayOfObjs = [];
  for(let i = 0; i < numItems; i++) {
    const int1Int2Array = int1ColonInt2Array[i].split(":"); //int1Int2Array[0]: ["1", "9"]
    var int1Int2Obj = {};
    if(int1Int2Array.length === 2) {
      int1Int2Obj[i_int1VarName] = str2int(int1Int2Array[0]);
      int1Int2Obj[i_int2VarName] = ((i_var2IsStringTF) ? (int1Int2Array[1]) : (str2int(int1Int2Array[1])));
    }
    else {
      int1Int2Obj[i_int1VarName] = str2int(int1ColonInt2Array[i]); //convert 2nd example entry "2" to {id:2, percent:0}
      int1Int2Obj[i_int2VarName] = 0;
    }

    intsArrayOfObjs.push(int1Int2Obj);
  }

  if(i_sortFieldName !== undefined && numItems > 1) {
    sort_arrayOfObjs(intsArrayOfObjs, i_sortFieldName, i_sortIsAscTF);
  }

  return(intsArrayOfObjs);
}

export function sort_colon_comma_list(i_colonCommaList, i_sortByField1or2, i_sortIsAscTF) {
  var intsArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, "int1", "int2");
  const numItems = intsArrayOfObjs.length;
  if(numItems === 0) {
    return("");
  }

  const int1OrInt2String = "int" + i_sortByField1or2;
  const sortByFunction = ((i_sortIsAscTF) ? (sort_by_asc(int1OrInt2String)) : (sort_by_desc(int1OrInt2String)));
  intsArrayOfObjs.sort(sortByFunction);
  return(convert_arrayOfObjs_to_colon_comma_list(intsArrayOfObjs, "int1", "int2"));
}

export function get_first_ints_obj_or_undefined_from_colon_comma_list_after_sorting(i_colonCommaList, i_sortByField1or2, i_sortIsAscTF) {
  var intsArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, "int1", "int2");
  const numItems = intsArrayOfObjs.length;
  if(numItems > 0) {
    const int1OrInt2String = "int" + i_sortByField1or2;
    const sortByFunction = ((i_sortIsAscTF) ? (sort_by_asc(int1OrInt2String)) : (sort_by_desc(int1OrInt2String)));
    intsArrayOfObjs.sort(sortByFunction);
    return(intsArrayOfObjs[0]);
  }
  return(undefined);
}

export function int1_is_in_colon_comma_list_tf(i_int1ToFind, i_colonCommaListString, i_var2IsStringTF=false) {
  const int1VarName = "int1";
  const int2VarName = "int2";
  const intsArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaListString, int1VarName, int2VarName, i_var2IsStringTF);
  for(let int1Int2Obj of intsArrayOfObjs) {
    if(int1Int2Obj[int1VarName] === i_int1ToFind) {
      return(true);
    }
  }
  return(false);
}

export function convert_value_colon_percent_string_to_value_percent_obj(i_valueColonPercentString, i_convertValueToIntTF, i_returnedPercentIntIfNoValidPercentFound) {
  //i_valueColonPercentString: "3" or "3:45" or "3: 45" or "3 :45" or "3 : 45" or "Display Value" or "Display Value:45"
  var value = undefined;
  var percent = undefined;
  if(is_string(i_valueColonPercentString)) {
    var colonSplitArray = i_valueColonPercentString.split(" :"); //first try splitting by " :"
    var found1ColonTF = (colonSplitArray.length === 2);
    if(!found1ColonTF) { //if there is not exactly 1 occurance of " :"
      colonSplitArray = i_valueColonPercentString.split(":"); //try again by splitting by ":" (much more likely input format)
      found1ColonTF = (colonSplitArray.length === 2);
    }

    if(!found1ColonTF) { //if exactly 1 colon (with or without space) was not found, return the input as the value
      value = i_valueColonPercentString;
    }
    else {
      value = colonSplitArray[0];
      const percentIntOrUndefined = str2int(colonSplitArray[1]);
      if((percentIntOrUndefined !== undefined) && (percentIntOrUndefined >= 0) && (percentIntOrUndefined <= 100)) {
        percent = percentIntOrUndefined;
      }
    }

    //if no percent was filled in, use the provided return percent
    if(percent === undefined) {
      percent = i_returnedPercentIntIfNoValidPercentFound;
    }

    //convert the string value to an int if requested by the input flag
    if(i_convertValueToIntTF) {
      value = str2int(value);
    }
  }

  return({
    value: value,
    percent: percent
  });
}

export function convert_arrayOfObjs_to_colon_comma_list(i_arrayOfObjs, i_int1VarName, i_int2VarName) {
  var int1ColonInt2StringsArray = [];
  for(let obj of i_arrayOfObjs) {
    int1ColonInt2StringsArray.push(obj[i_int1VarName] + ":" + obj[i_int2VarName]);
  }
  return(int1ColonInt2StringsArray.join(","));
}

export function get_value_from_colon_comma_list_from_id(i_colonCommaList, i_idToGetValueFrom) {
  const intsArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, "int1", "int2");
  for(let int1Int2Obj of intsArrayOfObjs) {
    if(int1Int2Obj["int1"] === i_idToGetValueFrom) { //if the ids match (id stored in first value int1)
      return(int1Int2Obj["int2"]); //return the value paired with that id (stored in int2)
    }
  }
  return(undefined);
}

export function update_or_insert_value_into_colon_comma_list(i_colonCommaList, i_idToUpdateOrInsert, i_newValueForId) {
  //i_colonCommaList: "1:9,2:40,3:15"     i_idToUpdateOrInsert: 1, i_newValueForId: 27      updatedColonCommaList: "1:27,2:40,3:15"
  //i_colonCommaList: "1:9,2:40,3:15"     i_idToUpdateOrInsert: 4, i_newValueForId: 44      updatedColonCommaList: "1:9,2:40,3:15,4:44"
  var idValueArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, "id", "value");
  var idExistedTF = false;
  for(let idValueObj of idValueArrayOfObjs) {
    if(idValueObj.id === i_idToUpdateOrInsert) {
      idValueObj.value = i_newValueForId;
      idExistedTF = true;
    }
  }

  if(!idExistedTF) {
    idValueArrayOfObjs.push({id:i_idToUpdateOrInsert, value:i_newValueForId});
  }

  const updatedColonCommaList = convert_arrayOfObjs_to_colon_comma_list(idValueArrayOfObjs, "id", "value");
  return(updatedColonCommaList);
}

export function insert_values_into_colon_comma_list(i_colonCommaList, i_idsArrayToInsert, i_newValuesArrayToInsert) {
  var idValueArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, "id", "value");
  for(let i = 0; i < i_idsArrayToInsert.length; i++) {
    idValueArrayOfObjs.push({id:i_idsArrayToInsert[i], value:i_newValuesArrayToInsert[i]});
  }

  const updatedColonCommaList = convert_arrayOfObjs_to_colon_comma_list(idValueArrayOfObjs, "id", "value");
  return(updatedColonCommaList);
}

export function remove_item_from_colon_comma_list_by_id(i_colonCommaList, i_idToRemove) {
  var idValueArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, "id", "value");
  var updatedIdValueArrayOfObjs = [];
  for(let idValueObj of idValueArrayOfObjs) {
    if(idValueObj.id !== i_idToRemove) {
      updatedIdValueArrayOfObjs.push(idValueObj);
    }
  }
  const updatedColonCommaList = convert_arrayOfObjs_to_colon_comma_list(updatedIdValueArrayOfObjs, "id", "value");
  return(updatedColonCommaList);
}

export function count_colon_comma_list_num_items(i_colonCommaListString) {
  if(string_is_filled_out_tf(i_colonCommaListString)) {
    const int1ColonInt2Array = i_colonCommaListString.split(","); //int1ColonInt2Array: ["1:9", "2", "3:15"]
    return(int1ColonInt2Array.length);
  }
  return(0);
}

export function sum_of_colon_comma_list_int2(i_colonCommaList, i_sumValueIfEmpty) {
  const intsArrayOfObjs = convert_colon_comma_list_to_ints_arrayOfObjs(i_colonCommaList, "int1", "int2");
  return(sum_of_arrayOfObjs_column(intsArrayOfObjs, "int2", i_sumValueIfEmpty));
}

export function array_of_equal_ints_add_up_to_100(i_numInts) { //i_numInts = 6, returns [17 17 17 17 16 16]     i_numInts = 4, returns [25 25 25 25]
  var equalIntsArray = [];
  if(i_numInts > 0) {
    const higherInt = Math.ceil(100 / i_numInts); //17
    const lowerInt = higherInt - 1; //16
    const allHigherIntsSum = higherInt * i_numInts; //102
    const numLowerInts = allHigherIntsSum - 100; //2
    const numHigherInts = i_numInts - numLowerInts; //4
    for(let h = 0; h < numHigherInts; h++) {
      equalIntsArray.push(higherInt);
    }
    for(let l = 0; l < numLowerInts; l++) {
      equalIntsArray.push(lowerInt);
    }
  }
  return(equalIntsArray);
}





//=====================================================================================================================================================
//RC Matrix
//=====================================================================================================================================================
export function get_rc_matrix_from_num_items_and_columns(i_numItems, i_numColumns) {
  //i_numItems: 10   i_numColumns: 4
  //crMatrix: [[0,1,2,3], [4,5,6,7], [8,9,-1,-2]]
  const indexArray = array_fill_incrementing_0_to_nm1(i_numItems);
  return(get_rc_matrix_from_id_array_and_num_columns(indexArray, i_numColumns));
}

export function get_rc_matrix_from_id_array_and_num_columns(i_idArray, i_numColumns) {
  //i_idArray: [1,2,4,5,6,7,8,9,10,11]   i_numColumns: 4
  //rcMatrix: [[1,2,4,5], [6,7,8,9], [10,11,-1,-2]]
  const numItems = i_idArray.length;
  const numRows = Math.ceil(numItems/i_numColumns);
  var itemIndex = 0;
  var negativeIDCounter = -1;
  var rcMatrix = []; //matrix is 2D to represent rows and columns, negative numbers starting at -1 are put where an empty slot should be drawn at the end if a row is not finished
  for(let r = 0; r < numRows; r++) {
    rcMatrix[r] = [];
    for(let c = 0; c < i_numColumns; c++) {
      if(itemIndex >= numItems) {
        rcMatrix[r][c] = negativeIDCounter;
        negativeIDCounter--;
      }
      else {
        rcMatrix[r][c] = i_idArray[itemIndex];
      }
      itemIndex++;
    }
  }
  return(rcMatrix);
}

export function get_cr_matrix_from_num_items_and_columns(i_numItems, i_numColumns) {
  //i_numItems: 10   i_numColumns: 4
  //crMatrix: [[0,1,2], [3,4,5], [6,7,8], [9,-1,-2]]
  var indexArray = [];
  for(let i = 0; i < i_numItems; i++) {
    indexArray.push(i);
  }
  return(get_cr_matrix_from_id_array_and_num_columns(indexArray, i_numColumns));
}

export function get_cr_matrix_from_id_array_and_num_columns(i_idArray, i_numColumns) {
  //i_idArray: [1,2,4,5,6,7,8,9,10,11]   i_numColumns: 4
  //crMatrix: [[1,2,4], [5,6,7], [8,9,10], [11,-1,-2]]
  const numItems = i_idArray.length;
  const numRows = Math.ceil(numItems/i_numColumns);
  var itemIndex = 0;
  var negativeIDCounter = -1;
  var crMatrix = []; //matrix is 2D to represent rows and columns, negative numbers starting at -1 are put where an empty slot should be drawn at the end if a row is not finished
  for(let c = 0; c < i_numColumns; c++) {
    crMatrix[c] = [];
    for(let r = 0; r < numRows; r++) {
      if(itemIndex >= numItems) {
        crMatrix[c][r] = negativeIDCounter;
        negativeIDCounter--;
      }
      else {
        crMatrix[c][r] = i_idArray[itemIndex];
      }
      itemIndex++;
    }
  }
  return(crMatrix);
}

export function rc_unique_row_key(i_numColumns, i_rowIndex) {
  return("r" + i_numColumns + "_" + i_rowIndex);
}



//create an RC matrix full of objs instead of just id numbers, access objs from an arrayOfObjs from matching id numbers
export function get_rc_matrix_of_objs_or_undefined_from_id_array_and_num_columns_and_arrayOfObjs_and_id_field_name(i_idArray, i_numColumns, i_arrayOfObjsWithIDColumn, i_idFieldName) {
  //ensure input arrayOfObjs is an array, otherwise use an empty array which will fill output with all undefined instead of fetched objs
  var arrayOfObjsWithIDColumn = [];
  if(is_array(i_arrayOfObjsWithIDColumn)) {
    arrayOfObjsWithIDColumn = i_arrayOfObjsWithIDColumn;
  }

  const rcMatrix = get_rc_matrix_from_id_array_and_num_columns(i_idArray, i_numColumns);
  var rcMatrixOfObjsOrUndefined = [];
  for(let rowIDsArray of rcMatrix) {
    var rowArrayOfObjsOrUndefined = [];
    for(let objID of rowIDsArray) {
      var objOrUndefined = undefined; //undefined appears where the id is -1 (last empty cells of the RC matrix) or where the id does not exist in the arrayOfObjs
      if(objID > 0) {
        objOrUndefined = get_first_obj_from_arrayOfObjs_matching_field_value(arrayOfObjsWithIDColumn, i_idFieldName, objID);
      }
      rowArrayOfObjsOrUndefined.push(objOrUndefined);
    }
    rcMatrixOfObjsOrUndefined.push(rowArrayOfObjsOrUndefined);
  }
  return(rcMatrixOfObjsOrUndefined);
}







//=====================================================================================================================================================
//Trees using parent_id field
//=====================================================================================================================================================
export function recursive_array_of_ids_under_node_in_tree(i_treeArrayOfObjs, i_parentIDFieldName, i_parentNodeID) {
  var childNodeIDsArray = [];
  for(let n = 0; n < i_treeArrayOfObjs.length; n++) {
    if(i_treeArrayOfObjs[n][i_parentIDFieldName] === i_parentNodeID) {
      var childNodeID = i_treeArrayOfObjs[n].id;
      childNodeIDsArray.push(childNodeID);
      var subNodeIDsArray = recursive_array_of_ids_under_node_in_tree(i_treeArrayOfObjs, i_parentIDFieldName, childNodeID);
      for(let s = 0; s < subNodeIDsArray.length; s++) {
        childNodeIDsArray.push(subNodeIDsArray[s]);
      }
    }
  }
  return(childNodeIDsArray);
}

export function tree_field_array_from_top_to_node(i_treeArrayOfObjs, i_parentIDFieldName, i_endingNodeID, i_outputFieldName) {
  //i_treeArrayOfObjs: [{id:1, parent_id: -1, name:"top"}, {id:2, parent_id: -1, name:"other top"}, {id:3, parent_id: 1, name:"subtop"}, {id:4, parent_id: 3, name:"subsubtop"}]
  //i_parentIDFieldName: "parent_id"
  //i_endingNodeID: 4
  //i_outputFieldName: "name"     (can be "Obj" to return an arrayOfObjs with all fields)
  //treeFieldArray: ["top", "subtop", "subsubtop"]      (or if "id" was the outputFieldName: [1,3,4])
  const outputIsObjTF = (i_outputFieldName === "Obj");

  var treeFieldArray = [];
  var currentNodeID = i_endingNodeID; //start at the endingNode and work backwards through the parentIDs to find the top level where parentID is -1, then reverse the array to go from top down
  while(currentNodeID > 0) {
    var currentNodeObj = get_first_obj_from_arrayOfObjs_matching_field_value(i_treeArrayOfObjs, "id", currentNodeID);
    if(currentNodeObj === undefined) { //the searched for currentNodeID does not exist as an id in any obj in the tree
      currentNodeID = -1; //break the while loop
    }
    else {
      if(outputIsObjTF) { //entire obj added to array
        treeFieldArray.push(currentNodeObj);
      }
      else { //single field from obj added to output array
        treeFieldArray.push(currentNodeObj[i_outputFieldName]); //get the requested field from the node obj and add it to the output array
      }
      currentNodeID = currentNodeObj[i_parentIDFieldName]; //parent of the current node becomes the next current node
    }
  }
  treeFieldArray.reverse(); //reverse the array to order from top node down to ending node
  return(treeFieldArray);
}

export function compute_sorted_tree_with_indents_arrayOfObjs_from_tree_arrayOfObjs(i_treeArrayOfObjs, i_parentIDFieldName, i_sortFieldName) {
  /*assumes "id" is the name of the id field that parent_id refers to
  //i_treeArrayOfObjs: [                              |folders:
    {id:1, parent_id:-1, name:"B"},                   | A(ID:2)
    {id:2, parent_id:-1, name:"A"},                   |   A(ID:7)
    {id:3, parent_id:2, name:"C"},                    |   B(ID:5)
    {id:4, parent_id:1, name:"D"},                    |     E(ID:6)
    {id:5, parent_id:2, name:"B"},                    |   C(ID:3)
    {id:6, parent_id:5, name:"E"},                    | B(ID:1)
    {id:7, parent_id:2, name:"A"}                     |   D(ID:4)
  ]
  //i_parentIDFieldName: "parent_id"
  //i_sortFieldName: "name"

  fieldSortedTreeArrayOfObjs: [
    {id:1, parent_id:-1, name:"A"},
    {id:7, parent_id:2, name:"A"},
    {id:1, parent_id:-1, name:"B"},
    {id:5, parent_id:2, name:"B"},
    {id:3, parent_id:2, name:"C"},
    {id:4, parent_id:1, name:"D"},
    {id:6, parent_id:5, name:"E"}
  ]

  parentSortedTreeWithIndentsArrayOfObjs: [
    {id:2, parent_id:-1, name:"A", indentLevel:0},
    {id:7, parent_id:2, name:"A", indentLevel:1},
    {id:5, parent_id:2, name:"B", indentLevel:1},
    {id:6, parent_id:5, name:"E", indentLevel:2},
    {id:3, parent_id:2, name:"C", indentLevel:1},
    {id:1, parent_id:-1, name:"B", indentLevel:0},
    {id:4, parent_id:1, name:"D", indentLevel:1}
  ]
  */

  if(!is_array(i_treeArrayOfObjs)) {
    return([]);
  }

  const numTreeItems = i_treeArrayOfObjs.length;

  //no need to sort or find parents if the tree is empty
  if(numTreeItems === 0) {
    return([]);
  }

  //sort the tree by the sort field initially to make sure all levels/sublevels are already in order when searched
  const fieldSortedTreeArrayOfObjs = copy_array(i_treeArrayOfObjs);
  sort_arrayOfObjs(fieldSortedTreeArrayOfObjs, i_sortFieldName, true);

  //initialize the first loop
  var parentSortedTreeWithIndentsArrayOfObjs = []; //output as empty
  var currentIndentLevel = 0; //indent level at 0
  var currentParentIDToSearch = -1; //first level of parent id at root level is -1

  //call intial recursion start looking for root level tree items, each matching item calls its own search for items that have itself listed as a parent, the bottom of the recursion is when a search results in no matches of parentID
  parentSortedTreeWithIndentsArrayOfObjs = recursive_get_first_child_of_tree_and_add_obj_to_array(parentSortedTreeWithIndentsArrayOfObjs, currentIndentLevel, currentParentIDToSearch, fieldSortedTreeArrayOfObjs, i_parentIDFieldName);
  
  return(parentSortedTreeWithIndentsArrayOfObjs);
}


function recursive_get_first_child_of_tree_and_add_obj_to_array(i_parentSortedTreeWithIndentsArrayOfObjs, i_currentIndentLevel, i_currentParentIDToSearch, i_fieldSortedTreeArrayOfObjs, i_parentIDFieldName) {
  //loop over every tree item
  for(let sortedTreeObj of i_fieldSortedTreeArrayOfObjs) {
  	//console.log("check {" + sortedTreeObj.id + " " + sortedTreeObj[i_parentIDFieldName] + " " + sortedTreeObj.name + "} for pID:" + i_currentParentIDToSearch)

    //if this tree item being checked has a parentID that matches the parentID currently being searched
  	if(sortedTreeObj[i_parentIDFieldName] === i_currentParentIDToSearch) {
      //create a copy of the tree item and add the current indent level from the input to it as a new field, append it to the output array
    	var treeWithIndentsObj = copy_obj(sortedTreeObj);
      treeWithIndentsObj.indentLevel = i_currentIndentLevel;
    	i_parentSortedTreeWithIndentsArrayOfObjs.push(treeWithIndentsObj);
      
      //increment the indent level for the deeper recursion searching if this tree item has any children
      var newIndentLevel = (i_currentIndentLevel + 1);

      //search all tree items for children with a parentID matching this item's id number
    	i_parentSortedTreeWithIndentsArrayOfObjs = recursive_get_first_child_of_tree_and_add_obj_to_array(i_parentSortedTreeWithIndentsArrayOfObjs, newIndentLevel, sortedTreeObj.id, i_fieldSortedTreeArrayOfObjs, i_parentIDFieldName);
    }
  }

  //return the growing output array of tree items (with indentLevel field) in the order of parent/child relationships
  return(i_parentSortedTreeWithIndentsArrayOfObjs);
}




//=====================================================================================================================================================
//Trees using tree_id field
//=====================================================================================================================================================
export function tree_id_is_child_of_parent_tree_id_tf(i_parentTreeID, i_childTreeID, i_directChildrenOnlyTF=false) {
  //parent: "0102"   child: "01020101"  true (child is within 0102 branch), false for the same treeIDs
  const parentTreeID = ((is_string(i_parentTreeID)) ? (i_parentTreeID) : (""));
  const childTreeID = ((is_string(i_childTreeID)) ? (i_childTreeID) : (""));

  const parentLength = parentTreeID.length;
  const childLength = childTreeID.length;
  if(childLength <= parentLength) {
    return(false);
  }

  var isChildTF = (childTreeID.substring(0, parentLength) === parentTreeID);
  if(i_directChildrenOnlyTF) {
    return(isChildTF && (childLength === (parentLength + 2)));
  }
  return(isChildTF);
}

export function get_all_children_ids_array_from_parent_tree_id(i_arrayOfObjsWithTreeID, i_parentTreeID, i_includeInputParentIDInOutputArrayTF=false, i_directChildrenOnlyTF=false, i_treeIDFieldName="tree_id") {
  var childrenIDsArray = [];
  for(let treeObj of i_arrayOfObjsWithTreeID) {
    //include parent id if desired when the treeID matches the input parent treeID
    if(i_includeInputParentIDInOutputArrayTF) {
      if(treeObj[i_treeIDFieldName] === i_parentTreeID) {
        childrenIDsArray.push(treeObj.id);
      }
    }

    var isChildOfParentTF = tree_id_is_child_of_parent_tree_id_tf(i_parentTreeID, treeObj[i_treeIDFieldName], i_directChildrenOnlyTF);
    if(isChildOfParentTF) {
      childrenIDsArray.push(treeObj.id);
    }
  }
  return(childrenIDsArray);
}

export function get_parent_item_id_from_child_tree_id(i_arrayOfObjsWithTreeID, i_childTreeID) {
  const childTreeIDLength = i_childTreeID.length
  if(childTreeIDLength < 4) { //"00" top level does not have a parent, return -1
    return(-1);
  }

  //if the child treeID provided was "000104", then loop through all given tree objs until one that has treeID "0001" is found, return their id number, otherwise if no match is found, return -1
  const parentTreeID = i_childTreeID.substring(0, childTreeIDLength-2);
  for(let treeObj of i_arrayOfObjsWithTreeID) {
    if(treeObj.tree_id === parentTreeID) {
      return(treeObj.id);
    }
  }
  return(-1);
}

export function get_new_child_tree_id_from_arrayOfObjs(i_arrayOfObjsWithTreeID, i_parentTreeID) {
  //i_arrayOfObjsWithTreeID: [{id:1, tree_id:""}, {id:2, tree_id:"01"}, {id:3, tree_id:"02"}]
  //i_parentTreeID: ""    (if you were going to insert a new child for the obj with id=1, what would the new tree_id be)
  //newChildTreeID: "03"  (after "01" and "02", both children of top level "")
  const parentTreeID = ((is_string(i_parentTreeID)) ? (i_parentTreeID) : (""));

  var allChildTreeIDIntsOfParentArray = [];
  const childTreeIDLength = parentTreeID.length + 2;
  for(let treeObj of i_arrayOfObjsWithTreeID) {
    var thisTreeID = treeObj.tree_id;
    var thisTreeIDLength = thisTreeID.length;
    if(thisTreeIDLength === childTreeIDLength) {
      var childTreeIDLast2DigitsString = thisTreeID.substring(thisTreeIDLength-2, thisTreeIDLength);
      allChildTreeIDIntsOfParentArray.push(str2int(childTreeIDLast2DigitsString));
    }
  }

  var nextChildInt = 1; //set to "01" if this is the first child being added to a new parent
  if(allChildTreeIDIntsOfParentArray.length > 0) {
    nextChildInt = (array_max(allChildTreeIDIntsOfParentArray) + 1);
    if(nextChildInt > 99) {
      nextChildInt = 99; //limit the max number to 99 to fit within 2 digits (ok to have multiple entries with treeID 99, they just will not be sorted in a particular order)
    }
  }

  return(parentTreeID + zero_pad_integer_from_left(nextChildInt, 2));
}









//=====================================================================================================================================================
//Strings
//=====================================================================================================================================================
export function str2lower(i_string, i_returnIfInputIsNotStringType=undefined) {
  if(is_string(i_string)) {
    return(i_string.toLowerCase());
  }

  if(i_returnIfInputIsNotStringType !== undefined) {
    return(i_returnIfInputIsNotStringType);
  }

  return(i_string);
}

export function convert_array_to_lowercase_array(i_arrayOfStrings) {
  var lowercaseArrayOfStrings = [];
  for(let inputString of i_arrayOfStrings) {
    lowercaseArrayOfStrings.push(str2lower(inputString));
  }
  return(lowercaseArrayOfStrings);
}


export function input_lowercase_string_contains_lowercase_search_term_string_tf(i_inputLongStringLowercase, i_lowercaseSearchTermString) {
  return(i_inputLongStringLowercase.indexOf(i_lowercaseSearchTermString) >= 0);
}

export function input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(i_inputLongStringNotYetLowercase, i_lowercaseSearchTermString) {
  const inputLongStringLowercase = i_inputLongStringNotYetLowercase.toLowerCase();
  return(input_lowercase_string_contains_lowercase_search_term_string_tf(inputLongStringLowercase, i_lowercaseSearchTermString));
}


export function input_string_contains_any_from_lowercase_contains_strings_array(i_inputString, i_lowerCaseContainsStringsArray) {
  //i_inputString: "Eccentric Street"
  //i_lowerCaseContainsStringsArray: ["ecc", "bo t"]
  //returns true because lowercase "eccentric stree" contains first test string "ecc"
  const inputStringLowercase = i_inputString.toLowerCase();
  for(let containsString of i_lowerCaseContainsStringsArray) {
    if(inputStringLowercase.indexOf(containsString) >= 0) {
      return(true);
    }
  }
  return(false);
}

export function remove_whitespace_from_string(i_string) {
  return(i_string.replace(/\s+/g, ''));
}

export function remove_unicode_from_string(i_string) {return(i_string)
  if(!is_string(i_string)) {
    return(i_string);
  }
  var string = i_string.replace(/\u2019/g, "'"); //0x2019 &rsquo;
  string = string.replace(/\u00A0/g, ""); //0xA0 &nbsp;
  string = string.replace(/[\u00FF-\uFFFF]/g, "");
  return(string);
}

export function trim_string_max_chars(i_string, i_maxNumChars) {
  if(is_string(i_string)) {
    if(i_string.length > i_maxNumChars) {
      return(i_string.substring(0, i_maxNumChars));
    }
  }
  return(i_string);
}

export function trim_string_max_chars_add_ellipsis(i_string, i_maxNumChars) {
  if(is_string(i_string)) {
    if(i_string.length > i_maxNumChars) {
      return(i_string.substring(0, i_maxNumChars) + "...");
    }
  }
  return(i_string);
}

export function trim_excel_cell_max_chars(i_string) {
  return(trim_string_max_chars(i_string, 32500));
}


export function valid_email_address_undefined_or_invalid_email_error_message_string(i_emailString) {
  if(!is_string(i_emailString)) {
    return("Email format is '" + typeof(i_emailString) + "', must be in string format");
  }

  if(i_emailString === "") {
    return("Email is blank");
  }

  const atSignSplitArray = i_emailString.split("@");
  const numAtSignSplits = atSignSplitArray.length;
  if(numAtSignSplits < 2) {
    return("Email does not contain the '@' sign");
  }

  if(numAtSignSplits > 2) {
    return("Email contains more than one '@' sign");
  }

  const emailStringLength = i_emailString.length;
  const emailFirstChar = i_emailString.substring(0, 1);
  const emailLastChar = i_emailString.substring(emailStringLength - 1, emailStringLength);

  if(emailFirstChar === "@") {
    return("Email cannot start with '@' sign");
  }

  if(emailLastChar === "@") {
    return("Email cannot end with '@' sign");
  }

  if(emailFirstChar === " ") {
    return("Email not valid due to a space at the beginning");
  }

  if(emailLastChar === " ") {
    return("Email not valid due to a space at the end");
  }

  if(emailFirstChar === ".") {
    return("Email not valid due to a period '.' at the beginning");
  }

  if(emailLastChar === ".") {
    return("Email not valid due to a period '.' at the end");
  }

  const firstSpaceIndex = i_emailString.indexOf(" ");
  if(firstSpaceIndex >= 0) {
    return("Email not valid due to a space at character number " + (firstSpaceIndex + 1));
  }

  const firstDoublePeriodIndex = i_emailString.indexOf("..");
  if(firstDoublePeriodIndex >= 0) {
    return("Email not valid due to two periods next to each other '..' at character number " + (firstDoublePeriodIndex + 1));
  }

  const localPartString = atSignSplitArray[0];
  const domainString = atSignSplitArray[1];
  const localPartStringLength = localPartString.length;
  const localPartLastChar = localPartString.substring(localPartStringLength - 1, localPartStringLength);

  if(localPartLastChar === ".") {
    return("Email not valid due to a period '.' directly before the '@' sign");
  }

  //filter the front and the back surrounding the @ sign with different regexp rules (the back half domain portion length requirement was changed from {2,4} to {2,99} to account for "local@exampledomain.careers")
  const emailValidationRegExp = /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@([A-Z0-9-]+\.)+[A-Z]{2,99}$/i; //"Aa.Zz.09!#$%&'*+-/=?^_`{|}~@gmail1-hotmail2.AA.aaa.BBBB.bb.CCC.cccc" is valid
  const localPartTestEmail = localPartString + "@example.com";
  const domainTestEmail = "test@" + domainString;

  if(!emailValidationRegExp.test(localPartTestEmail)) {
    return("Local part of email before '@' must be 'A-Z' 'a-z' '0-9' and only special characters .!#$%&'*+-/=?^_`{|}~");
  }

  if(!emailValidationRegExp.test(domainTestEmail)) {
    return("Domain of email after '@' must be 'A-Z' 'a-z' '0-9' and '-' followed by .com .org .co etc separated by '.'");
  }

  //valid email address, return undefined
  return(undefined);
}


export function excel_AZAAZZ_from_int(i_int1toN) {
	//ascii A-65, Z-90
  if(i_int1toN < 1) {
  	return("");
  }

  if(i_int1toN <= 26) { //1-26 A-Z
  	const aToZAscii = (i_int1toN + 64);
  	return(String.fromCharCode(aToZAscii));
  }

  if(i_int1toN <= 702) {
  	const firstLetterFloorMult = Math.floor((i_int1toN - 1) / 26);
    const firstLetter = String.fromCharCode(firstLetterFloorMult + 64);

    const firstLetterFloorMultMult26 = (firstLetterFloorMult * 26);
    const secondLetterRemainder = (i_int1toN - firstLetterFloorMultMult26);
    const secondLetter = String.fromCharCode(secondLetterRemainder + 64);
    return(firstLetter + secondLetter);
  }

	return(i_int1toN);
}


export function create_string_from_string_and_array_of_char_indices(i_inputString, i_arrayOfCharIndicesToConcat) {
  //i_inputString = "ABCDEFGHIJ", i_arrayOfCharIndicesToConcat = [4, 0, 6, 0, 1], outputString = "EAGAB"
  var outputString = "";
  if(is_string(i_inputString) && is_array(i_arrayOfCharIndicesToConcat)) {
    const inputStringLength = i_inputString.length;
    if(inputStringLength > 0) { //no need to work if input string is "", output will be ""
      for(let charIndex of i_arrayOfCharIndicesToConcat) {
        if(is_number(charIndex) && (charIndex >= 0) && (charIndex < inputStringLength)) {
          outputString += i_inputString.substring(charIndex, (charIndex + 1));
        }
      }
    }
  }
  return(outputString);
}

export function remove_chars_in_string_from_string_and_array_of_char_indices(i_inputString, i_arrayOfCharIndicesToRemove) {
  //i_inputString = "ABCDEFGHIJ", i_arrayOfCharIndicesToRemove = [4, 0, 6], outputString = "BCDFHIJ"
  var outputString = "";
  if(is_string(i_inputString) && is_array(i_arrayOfCharIndicesToRemove)) {
    for(let i = 0; i < i_inputString.length; i++) {
      if(!in_array(i, i_arrayOfCharIndicesToRemove)) {
        outputString += i_inputString.substring(i, (i + 1));
      }
    }
  }
  return(outputString);
}


export function max_string_num_chars_from_arrayOfObjs_column(i_arrayOfObjs, i_columnName, i_minNumChars=0) {
  var maxNumChars = i_minNumChars;
  for(let obj of i_arrayOfObjs) {
    var objColumnString = obj[i_columnName];
    if(is_number(objColumnString)) {
      objColumnString = num2str(objColumnString);
    }

    if(is_string(objColumnString)) {
      var objColumnNumChars = objColumnString.length;
      if(objColumnNumChars > maxNumChars) {
        maxNumChars = objColumnNumChars;
      }
    }
  }
  return(maxNumChars);
}


export function char_upper_AZ_from_index_0to25(i_index0to25) {
	return(String.fromCodePoint(65 + i_index0to25));
}


export function char_lower_az_from_index_0to25(i_index0to25) {
	return(String.fromCodePoint(97 + i_index0to25));
}


export function convert_int_to_base_52_string(i_int) {
  //converts an int 0 to 19.77M to a 6 character string containing A-Za-z
  //0 = "AAAAAA"
  //1 = "AAAAAB"
  //2 = "AAAAAC"
  //...
  //25 = "AAAAAZ"
  //26 = "AAAAAa"
  //...
  //51 = "AAAAAz"
  //52 = "AAAABA"
  //53 = "AAAABB"
  //...

  if(i_int === -2) {
    return("zzzzzC");
  }

  const base = 52;
  var placeValuesArray = [0, 0, 0, 0, 0, 0];
  const numPlaceValues = placeValuesArray.length;
  var remainingInt = i_int;
  for(let i = 0; i < numPlaceValues; i++) {
    var modDivision = (remainingInt % base);
    var floorDivision = Math.floor(remainingInt / base);

    placeValuesArray[numPlaceValues - i - 1] = modDivision;

    remainingInt = floorDivision;
    if(remainingInt === 0) {
      break;
    }
  }

  var base52String = "";
  for(let i = 0; i < numPlaceValues; i++) {
    var placeValue = placeValuesArray[i];
    var letter = "a";
    if(placeValue < 26) {
      letter = char_upper_AZ_from_index_0to25(placeValue);
    }
    else {
      letter = char_lower_az_from_index_0to25(placeValue - 26);
    }
    base52String += letter;
  }
  return(base52String);
}


export function convert_base_52_string_to_int(i_base52String) {
  if(!is_string(i_base52String)) {
    return(-1);
  }

  if(i_base52String === "zzzzzC") {
    return(-2);
  }

  const numDigits = i_base52String.length;

  var sum = 0;
  for(let i = 0; i < numDigits; i++) {
    var letter = i_base52String.substring(i, i+1);
    var asciiInt = letter.charCodeAt(0);
    var int0to51 = 0; //A-65, Z-90, a-97, z-122
    if(asciiInt >= 65 && asciiInt <= 90) {
      int0to51 = asciiInt - 65;
    }
    else if(asciiInt >= 97 && asciiInt <= 122) {
      int0to51 = asciiInt - 71;
    }
    sum += (int0to51 * (52 ** (numDigits - 1 - i)));
  }
  return(sum);
}


export function generate_random_AZaz09_string(i_numChars) {
  var randomAZaz09String = "";
  for(let i = 0; i < i_numChars; i++) {
    randomAZaz09String += generate_random_AZaz09_char();
  }
  return(randomAZaz09String);
}


export function generate_random_AZaz09_char() {
  const randInt0to61 = Math.floor((Math.random() * 62)); //62 choices representing A-Z a-z 0-9

  if(randInt0to61 < 26) { //A-Z
    return(char_upper_AZ_from_index_0to25(randInt0to61));
  }

  if(randInt0to61 < 52) { //a-z
    return(char_lower_az_from_index_0to25(randInt0to61 - 26));
  }

  return(randInt0to61 - 52); //0-9
}


//=====================================================================================================================================================
//JSON
//=====================================================================================================================================================
export function parse_json_arrayOfObjs_string_into_arrayOfObjs_or_undefined(i_jsonArrayOfObjsString) {
  var arrayOfObjsOrUndefined = undefined;
  if(is_string(i_jsonArrayOfObjsString)) {
    if(i_jsonArrayOfObjsString.substring(0, 2) === "[{") { //json string with an arrayOfObjs will start with "[{" for the array and the opening of the first obj
      try { //place this json parse inside a try-catch because it can throw a breaking error if the input string has invalid formatting
        arrayOfObjsOrUndefined = JSON.parse(i_jsonArrayOfObjsString);
      }
      catch(i_error) {
        arrayOfObjsOrUndefined = undefined;
      }
    }
  }
  return(arrayOfObjsOrUndefined);
}



//=====================================================================================================================================================
//Passwords/Security
//=====================================================================================================================================================
export function rfr_scramble_or_unscramble_password(i_realOrScrambledString) {
	//"ABCDEFGHIJKLMNOPQRSTUVWXYZ" -> "IHGFEDCBAKJMLONQPRZYXWVUTS" -> "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
	if(!is_string(i_realOrScrambledString)) {
		return("");
	}

	const numChars = i_realOrScrambledString.length;

	//""->"", "A"->"A", "AB"->"BA", "ABC"->"CBA", "ABCD"->"DCBA", "ABCDE"->"EDCBA"
	if(numChars <= 5) {
		return(reverse_string(i_realOrScrambledString));
	}

	const oneThirdOfLengthRoundedUp = Math.ceil(numChars / 3); //"ABCDEFGHIJ" 10 chars, ceil(10/3) = 4
	const thirdSegmentLength = (numChars - (2 * oneThirdOfLengthRoundedUp)); //1st segment length 4, 2nd segment length 4, 3rd segment length is remainder 2  "ABCD" "EFGH" "IJ"

	const realFirstSegment = i_realOrScrambledString.substring(0, oneThirdOfLengthRoundedUp); //"ABCD"
	const realSecondSegment = i_realOrScrambledString.substring(oneThirdOfLengthRoundedUp, (2 * oneThirdOfLengthRoundedUp)); //"EFGH"
	const realThirdSegment = i_realOrScrambledString.substring(2 * oneThirdOfLengthRoundedUp); //"IJ"

	const scrambleFirstSegment = reverse_string(realFirstSegment);
	const scrambleSecondSegment = interlace_string(realSecondSegment);
	const scrambleThirdSegment = reverse_string(realThirdSegment);

	return(scrambleFirstSegment + scrambleSecondSegment + scrambleThirdSegment);
}

export function reverse_string(i_initialString) {
	//"ABCDEFG" -> "GFEDCBA"
	if(!is_string(i_initialString)) {
		return("");
	}

  const numChars = i_initialString.length;
	var reverseString = "";
	for(let i = (numChars - 1); i >= 0; i--) {
		reverseString += i_initialString[i];
	}

	return(reverseString);
}

export function interlace_string(i_initialString) {
	//""->"", "A"->"A", "AB"->"BA", "ABC"->"BAC", "ABCD"->"BADC", "ABCDE"->"BADCE", "ABCDEF"->"BADCFE", "ABCDEFG"->"BADCFEG"
	//"ABCDEFG"->"BADCFEG"->"ABCDEFG" (operation unscrambles itself with a 2nd call)
	if(!is_string(i_initialString)) {
		return("");
	}

  const numChars = i_initialString.length;
	var interlacedString = "";
	for(let i = 1; i <= numChars; i+=2) {
  	if(i >= numChars) {
    	interlacedString += i_initialString.substring(numChars - 1);
    }
    else {
    	interlacedString += i_initialString[i] + i_initialString[i - 1];
    }
	}

	return(interlacedString);
}






//=====================================================================================================================================================
//Numbers/Money
//=====================================================================================================================================================
export function convert_any_style_string_to_int(i_string) {
  const stringOnlyNumbersDecimalsNegatives = string_keep_only_numbers_decimals_negatives(i_string);
  const int = str2int(stringOnlyNumbersDecimalsNegatives);
  if(int === undefined) {
    return(0); //return 0 if the string is still invalid (2 decimal places, etc)
  }
  return(int);
}

export function convert_any_style_string_to_decimal(i_string) {
  const stringOnlyNumbersDecimalsNegatives = string_keep_only_numbers_decimals_negatives(i_string);
  const decimal = str2int_or_decimal(stringOnlyNumbersDecimalsNegatives);
  if(decimal === undefined) {
    return(0); //return 0 if the string is still invalid (2 decimal places, etc)
  }
  return(decimal);
}

export function string_keep_only_numbers_decimals_negatives(i_string) {
  if(is_string(i_string)) {
    return(i_string.replace(/[^-0-9.]+/g, ''));
  }
  return(i_string);
}

export function str2int(i_string, i_returnIfInputIsNotNumeric=undefined) {
  const int = parseInt(i_string, 10);
  if(isNaN(int)) {
    return(i_returnIfInputIsNotNumeric);
  }
  return(int);
}

export function str2int_or_decimal(i_string) {
  const float = parseFloat(i_string);
  if(isNaN(float)) {
    return(undefined);
  }
  return(float);
}

export function num2str(i_number) {
  //[an input of undefined results in an output of undefined]
  return((is_number(i_number)) ? (i_number.toString()) : (i_number));
}

export function round_number_to_num_decimals_if_needed(i_number, i_numDecimals) {
  if(!("" + i_number).includes("e")) {
    return +(Math.round(i_number + "e+" + i_numDecimals)  + "e-" + i_numDecimals);
  }
  else {
    var arr = ("" + i_number).split("e");
    var sig = ""
    if(+arr[1] + i_numDecimals > 0) {
      sig = "+";
    }
    var i = +arr[0] + "e" + sig + (+arr[1] + i_numDecimals);
    var j = Math.round(i);
    var k = +(j + "e-" + i_numDecimals);
    return k;
  }
}

export function number_to_fixed_decimals(i_numberOrDecimal, i_numDecimals) { //91.031 -> "91" "91.0" "91.03" "91.031" "91.0310" ...
  if(i_numDecimals > 0) {
    return(parseFloat(i_numberOrDecimal).toFixed(i_numDecimals));
  }
  return(Math.round(i_numberOrDecimal)); //round works on ints, decimals, and string numbers
}

export function remove_javascript_precision_decimal_float_math_errors(i_decimal, i_precisionNumDecimalsError) {
  //try to fix 25.5 - 23.25 = 2.2500000000000000007
  //so using this function:
  //  remove_javascript_precision_decimal_float_math_errors(2.2500000000000000007, 8)
  //  returns 2.25
  return(Math.round(i_decimal * (10**i_precisionNumDecimalsError))/(10**i_precisionNumDecimalsError));
}

export function percent_float(i_decimal) { //91.03100 -> "91.031%"
  return(parseFloat(i_decimal) + "%");
}

export function percent_fixed(i_decimal, i_numDecimals) {
  return(number_to_fixed_decimals(i_decimal, i_numDecimals) + "%"); //91.031 -> "91%" "91.0%" "91.03%" "91.031%" "91.0310%" ...
}

export function round_percent_to_num_decimals_if_needed(i_decimal, i_numDecimals) {
  return(round_number_to_num_decimals_if_needed(i_decimal, i_numDecimals) + "%");
}

export function round_percent_to_num_decimals_if_needed_but_show_all_for_less_than_1(i_decimal, i_numDecimals) {
  if(i_decimal < 1) { //capture all decimals from "0.1%" to numbers like "0.000000000318%"
    return(percent_float(i_decimal));
  }
  return(round_percent_to_num_decimals_if_needed(i_decimal, i_numDecimals)); //all other percents cap at 1 decimal place like "1.5%", "20%", "154.3%"
}



export function zero_pad_integer_from_left(i_number, i_finalStringNumChars) {
  var numberString = "";
  if(is_number_not_nan_gt_0(i_number)) {
    numberString = num2str(i_number);
  }

  const numberStringInitialNumChars = numberString.length;
  if(is_number_not_nan_gt_0(i_finalStringNumChars)) {
    if(i_finalStringNumChars > numberStringInitialNumChars) {
      const numLeadingZeros = (i_finalStringNumChars - numberStringInitialNumChars);
      var zeroPaddedIntString = "";
      for(let i = 0; i < numLeadingZeros; i++) {
        zeroPaddedIntString += "0";
      }
      zeroPaddedIntString += numberString;
      return(zeroPaddedIntString);
    }
  }
  return(numberString);
}

export function string_is_negative_from_dashes(i_stringNumber) { //12 is positive, -12 is negative, 12- is negative, -12- is positive since the two dashes cancel out
  const firstCharIsDashTF = (i_stringNumber.substring(0, 1) === "-");
  const restOfStringHasDashTF = (i_stringNumber.substring(1).indexOf("-") >= 0);
  return((firstCharIsDashTF && !restOfStringHasDashTF) || (!firstCharIsDashTF && restOfStringHasDashTF));
}

export function string_keep_only_numbers(i_string) {
  return(i_string.replace(/[^0-9]/g, ""));
}

export function remove_leading_zeros_from_decimal_string(i_decimalString) {
  //i_decimalString: "", "0", ".93", "0.2", "10", "100", "10010", "100.0", "0100.1", "10.23", "10.230", "017", "017.2", "0009.3"
  //outputString:  "", "0", ".93", "0.2", "10", "100", "10010", "100.0", "100.1", "10.23", "10.230", "17", "17.2", "9.3"
  i_decimalString = num2str(i_decimalString);
  if(!is_string(i_decimalString)) {
    return(i_decimalString); //undefined, boolean, number
  }

  const stringLength = i_decimalString.length;
  if(stringLength < 2) { //nothing to remove if the length is 1 or 0
    return(i_decimalString); //"", "0", "4", "9", "."
  }

  var outputString = ""; //build output string with leading zeros omitted
  var foundFirstNonZeroOrDecimal = false;
  for(let i = 0; i < stringLength; i++) { //go through each character in the string
    var currentChar = i_decimalString.substring(i, i+1);
    var nextChar = i_decimalString.substring(i+1, i+2);
    if(currentChar !== "0" || currentChar === "." || nextChar === ".") {
      foundFirstNonZeroOrDecimal = true;
    }

    if(foundFirstNonZeroOrDecimal || currentChar !== "0") {
      outputString += currentChar;
    }
  }
  return(outputString);
}

export function convert_integer_value_to_zero_padded_string(i_valueString, i_numZeroPad) {
  var displayString = num2str(i_valueString);

  //zero pad
  const numZeroPad = ((is_number(i_numZeroPad)) ? (i_numZeroPad) : (0));
  if(numZeroPad > 0) {
    displayString = zero_pad_integer_from_left(displayString, numZeroPad);
  }

  return(displayString);
}

export function number_commas_decimal_format(i_decimal, i_numDecimals, i_decimalSymbol, i_thousandsSymbol) {
  var numDecimals = Math.abs(i_numDecimals);
  numDecimals = ((isNaN(numDecimals)) ? (2) : (numDecimals));
  var decimalSymbol = ((i_decimalSymbol === undefined) ? (".") : (i_decimalSymbol));
  var thousandsSymbol = ((i_thousandsSymbol === undefined) ? (",") : (i_thousandsSymbol));
  var negativeSign = ((i_decimal < 0) ? ("-") : (""));
  var parsedNumber = String(str2int(i_decimal = Math.abs(Number(i_decimal) || 0).toFixed(numDecimals)));
  var numberNumDigits = parsedNumber.length;
  numberNumDigits = ((numberNumDigits > 3) ? (numberNumDigits % 3) : (0));
  var something = ((numberNumDigits) ? (parsedNumber.substr(0, numberNumDigits) + thousandsSymbol) : (""));
  var formattedNumber = parsedNumber.substr(numberNumDigits).replace(/(\d{3})(?=\d)/g, ("$&" + thousandsSymbol));
  var formattedDecimals = ((numDecimals) ? (decimalSymbol + Math.abs(i_decimal - parsedNumber).toFixed(numDecimals).slice(2)) : (""));
  return(negativeSign + something + formattedNumber + formattedDecimals);
};

export function money(i_decimal, i_numDecimals, i_includeDollarSignTF=false) {
  const currencySymbol = currency_symbol();
  const numberCommasDemicalFormat = number_commas_decimal_format(i_decimal, i_numDecimals, ".", ",");
  if(i_includeDollarSignTF) {
    if(i_decimal < 0) {
      return("-" + currencySymbol + numberCommasDemicalFormat.substring(1)); //remove the "-" from the negative number string and put it back with the dollar sign after it (-9,000 becomes -$9,000)
    }
    return(currencySymbol + numberCommasDemicalFormat); //$9,000
  }
  return(numberCommasDemicalFormat); //9,000
}

export function money_short(i_decimal, i_includeDollarSignTF=true) {
  const currencySymbol = ((i_includeDollarSignTF) ? (currency_symbol()) : (""));
  const parseDecimal = parseFloat(i_decimal);
  if(parseDecimal < 1000 && parseDecimal > -1000) {
    const decimalAsInt = Math.round(parseDecimal);
    if(parseDecimal === decimalAsInt) {
      return(currencySymbol + parseDecimal); //input was int between -999 and 999
    }
    else if(parseDecimal < 10 && parseDecimal > -10) {
      return(currencySymbol + parseDecimal.toFixed(2)); //input between -9.99 and 9.99
    }
    return(currencySymbol + decimalAsInt); //remove decimals and print int between 10 and 999
  }
  else {
    const multArray = [1000, 1000000, 1000000000, 1000000000000, 1000000000000000];
    const letterArray = ["k", "M", "B", "T", "Q"];
    for(let m = 0; m < multArray.length; m++) {
      var mult = multArray[m];
      var letter = letterArray[m];
      if(parseDecimal < (100*mult) && parseDecimal > (-100*mult)) {
        const parseDecimalOverMult = parseDecimal/mult;
        if(parseDecimalOverMult === Math.round(parseDecimalOverMult)) {
          return(currencySymbol + parseDecimalOverMult.toFixed(0) + letter); //"$2k", "$13k"
        }
        return(currencySymbol + parseDecimalOverMult.toFixed(1) + letter); //"$1.2k", "$84.7k"
      }
      else if(parseDecimal < (1000*mult) && parseDecimal > (-1000*mult)) {
        return(currencySymbol + (parseDecimal/mult).toFixed(0) + letter); //"$122k", "$923k"
      }
    }
  }
  return(money(i_decimal, 0, i_includeDollarSignTF));
}

export function currency_symbol() {
  return("$");
}




export function convert_money_value_to_display(i_newValueString, i_centsTF) {
  if(i_newValueString === "" || i_newValueString === undefined) {
    return("");
  }
  else if(i_newValueString === "-") {
    return("-");
  }

  var displayString = num2str(i_newValueString);

  //truncate decimal places (choose to show "100" instead of "100.00" when a whole number to allow the ability to delete the decimal places to type new numbers in)
  const numDecimals = ((i_centsTF) ? (2) : (0));
  const firstDecimalIndex = displayString.indexOf(".");
  if(firstDecimalIndex >= 0) { //"." was found somewhere
    const substringCutoffIndex = ((numDecimals <= 0) ? (firstDecimalIndex) : (firstDecimalIndex + numDecimals + 1));
    displayString = displayString.substring(0, substringCutoffIndex);
  }

  //add commas for thousands places, displayString = 12345.678
  const decimalIndex = displayString.indexOf("."); //decimalIndex = 5
  var numberString = undefined;
  var decimalString = undefined;
  if(decimalIndex >= 0) {
    numberString = displayString.substring(0, decimalIndex); //numberString = 12345
    decimalString = displayString.substring(decimalIndex); //decimalString = .678
  }
  else {
    numberString = displayString;
    decimalString = "";
  }

  //put commas into the string every 3rd place from the decimal moving left
  var commaNumberString = "";
  var t = 0;
  for(let n = (numberString.length - 1); n >= 0; n--) { //loop through each digit in the number starting at the far right
    commaNumberString = numberString[n] + commaNumberString;
    t++;
    if(t === 3 && n > 0) {
      commaNumberString = "," + commaNumberString;
      t = 0;
    }
  }
  displayString = commaNumberString + decimalString;

  return(displayString);
}



export function stripe_formatted_price_from_amount_and_currency(i_stripeAmountCentsInt, i_stripeCurrency3LetterString, i_forceCentsTF=false) {
  //info from https://stripe.com/docs/currencies

  //ensure input i_stripeAmountCentsInt is an integer
  var stripeAmountCentsInt = 0;
  if(is_number(i_stripeAmountCentsInt)) {
    stripeAmountCentsInt = Math.round(i_stripeAmountCentsInt);
  }

  //check that input currency is a string and force it to uppercase for comparisons and display
  var currencyStringUppercase = "";
  if(is_string(i_stripeCurrency3LetterString)) {
    currencyStringUppercase = i_stripeCurrency3LetterString.toUpperCase();
  }

  //if currency input is not a string or is blank, return just the amount as a string
  if(currencyStringUppercase === "") {
    return(num2str(stripeAmountCentsInt));
  }

  //Zero-decimal currencies
  // - For zero-decimal currencies, still provide amounts as an integer but without multiplying by 100. For example, to charge ¥500, provide an amount value of 500.
  if(in_array(currencyStringUppercase, ["BIF","CLP","DJF","GNF","JPY","KMF","KRW","MGA","PYG","RWF","UGX","VND","VUV","XAF","XOF","XPF"])) {
    //special formatting for common currency symbols
    if(currencyStringUppercase === "JPY") { //Japanese Yen
      return("¥" + stripeAmountCentsInt); //"¥500"
    }
    return(stripeAmountCentsInt + " " + currencyStringUppercase); //"21000 UGX"
  }

  //Three-decimal currencies
  // - The API supports three-decimal currencies for the standard payment flows, including Payment Intents, Refunds, and Disputes.
  // - However, to ensure compatibility with Stripe’s payments partners, these API calls require the least-significant (last) digit to be 0.
  // - Your integration must round amounts to the nearest ten. For example, 5.124 KWD must be rounded to 5120 or 5130.)
  if(in_array(currencyStringUppercase, ["BHD","JOD","KWD","OMR","TND"])) {
    const threeDecimalRoundedCurrencyAmount = (stripeAmountCentsInt / 1000); //javascript division automatically uses the least amount of decimals necessary, so 21000/1000 is 21, 21003/1000 is 21.003, and 300/1000 is 0.3
    return(threeDecimalRoundedCurrencyAmount + " " + currencyStringUppercase); //"5.124 KWD"
  }

  //Two-decimal currencies (all others USD, EUR, GBP, CAD, AUD, etc)
  var twoDecimalRoundedCurrencyAmount = (stripeAmountCentsInt / 100);
  const hasCentsTF = ((stripeAmountCentsInt % 100) !== 0);
  if(hasCentsTF || i_forceCentsTF) { //force amounts that have cents to 2 decimals so that 5000 is $50, 5001 is $50.01, and 5010 is $50.10 not $50.1
    twoDecimalRoundedCurrencyAmount = number_to_fixed_decimals(twoDecimalRoundedCurrencyAmount, 2);
  }

  //special formatting for common currency symbols
  if(currencyStringUppercase === "USD") { //US Dollar
    return("$" + twoDecimalRoundedCurrencyAmount); //"$100" or "$14.70"
  }

  return(twoDecimalRoundedCurrencyAmount + " " + currencyStringUppercase); //"100 AUD"
}




export function sort_max_mysqli_bigint() {
  return(9000000000000000000); //2^63 is max for MYSQL bigint(20) signed)
}
export function sort_max_mysqli_int() {
  return(2147483647);
}
export function sort_max_mysqli_decimal_18_9() {
  return(999999999.999999999);
}
export function sort_max_string() {
  return("Ω");
}
export function sort_max_date() {
  return("9999-99-99");
}
export function sort_max_datetime() {
  return("9999-99-99 99:99:99");
}


export function key_rand() {
  return(Math.round(Math.random()*1000000));
}


export function plural(i_numItems, i_singularString, i_pluralString) {
  return((i_numItems === 1) ? (i_singularString) : (i_pluralString));
}

export function string_replace_new_lines_with_br_tags(i_stringWithNewLines) {
  return(i_stringWithNewLines.replace(/(?:\r\n|\r|\n)/g, '<br />'));
}



//=====================================================================================================================================================
//Math/Calculations
//=====================================================================================================================================================
export function calculate_circle_points_xy_0to1_arrays_obj(i_startAngleDeg, i_stepAnglePositiveDeg, i_ccwTrueCwFalse, i_numPoints, i_roundNumDecimals=4) {
  //startAngleDeg: 0-right, 90-top, 180-left, 270-bottom, 360-right, -90-bottom
  var x0to1Array = [];
  var y0to1Array = [];
  if(is_number_not_nan_gt_0(i_numPoints)) { //prevents infinite for loop from negative input number
    var stepAnglePositiveDeg = i_stepAnglePositiveDeg;
    if(!i_ccwTrueCwFalse) {
      stepAnglePositiveDeg *= -1;
    }

    var roundNumDecimals = 4;
    if(is_number_not_nan_gte_0(i_roundNumDecimals)) {
      roundNumDecimals = i_roundNumDecimals;
    }

    for(let p = 0; p < i_numPoints; p++) {
      var degrees = (i_startAngleDeg + (p * stepAnglePositiveDeg));
      var radians = (degrees * Math.PI / 180);
      var x0to1 = Math.cos(radians);
      var y0to1 = Math.sin(radians);
      var x0to1Rounded = round_number_to_num_decimals_if_needed(x0to1, roundNumDecimals);
      var y0to1Rounded = round_number_to_num_decimals_if_needed(y0to1, roundNumDecimals);
      x0to1Array.push(x0to1Rounded);
      y0to1Array.push(y0to1Rounded);
    }
  }

  return({
    x0to1Array: x0to1Array,
    y0to1Array: y0to1Array
  });
}





//=====================================================================================================================================================
//Fields
//=====================================================================================================================================================
export function date_is_filled_out_tf(i_date) {
  if(is_string(i_date)) {
    if((i_date.length === 10) && (i_date !== blank_date())) {
      return(true);
    }
  }
  return(false);
}

export function datetime_is_filled_out_tf(i_dateTime) {
  if(is_string(i_dateTime)) {
    if((i_dateTime.length === 19) && (i_dateTime !== blank_datetime())) {
      return(true);
    }
  }
  return(false);
}

export function string_is_filled_out_tf(i_string) {
  return(is_string(i_string) && (i_string !== ""));
}

export function text_or_number_is_filled_out_tf(i_numberOrText) {
  return((is_string(i_numberOrText) || is_number(i_numberOrText)) && (i_numberOrText !== ""));
}

export function select_int_is_filled_out_tf(i_selectID) {
  return(is_number_not_nan_gt_0(i_selectID)); //selectID of -1 is a flag in the database (initialization) that a select has no answer selected
}

export function selectmulti_is_filled_out_tf(i_selectIDsComma) {
  return(string_is_filled_out_tf(i_selectIDsComma)); //an empty comma list "" means nothing is selected
}

export function scored_textarea_obj_is_filled_out_tf(i_textareaScoredObj) {
  return(text_or_number_is_filled_out_tf(i_textareaScoredObj.textarea) && is_number_not_nan_gt_0(i_textareaScoredObj.score0to100));
}


export function website_mask_only(i_doubleBracketsMaskWebsiteOrWebsite) {
  const [mask, website] = website_determine_mask_website(i_doubleBracketsMaskWebsiteOrWebsite);
  return(mask);
}

export function website_determine_mask_website(i_doubleBracketsMaskWebsiteOrWebsite) {
	//i_doubleBracketsMaskWebsiteOrWebsite:
	//  [[Mask String]]http://www.website.com
	//  http://www.website.com[[Mask String]]
	//  http://www.w[[Mask String]]ebsite.com
	//  http://www.website.com
	//  [[Mask String]]

  var mask = "";
	var website = "";
  if(is_string(i_doubleBracketsMaskWebsiteOrWebsite) || is_number(i_doubleBracketsMaskWebsiteOrWebsite)) { //undefined into num2str() will return undefined, which breaks undefined.search()
    const doubleBracketsMaskWebsiteOrWebsite = num2str(i_doubleBracketsMaskWebsiteOrWebsite);

    var mask = doubleBracketsMaskWebsiteOrWebsite;
    var website = doubleBracketsMaskWebsiteOrWebsite;
    const llBracketsIndex = doubleBracketsMaskWebsiteOrWebsite.search(/\[\[/);
    if(llBracketsIndex >= 0) {
      const rrBracketsIndex = doubleBracketsMaskWebsiteOrWebsite.search(/\]\]/);
      if(rrBracketsIndex >= 0) {
        mask = doubleBracketsMaskWebsiteOrWebsite.substring(llBracketsIndex+2, rrBracketsIndex);
        website = doubleBracketsMaskWebsiteOrWebsite.substring(0, llBracketsIndex) + doubleBracketsMaskWebsiteOrWebsite.substring(rrBracketsIndex+2);
      }
    }

    //remove all spaces
    website = website.replace(/\s/g, "");

    //add http:// to the beginning if it is not there
    if((website !== "") && (website.substring(0,7) !== "http://") && (website.substring(0,8) !== "https://")) {
      website = "http://" + website;
    }
  }
  
	return([mask, website]);
}



export function convert_styling_string_comma_list_to_styling_obj(i_stylingStringCommaList) {
  //"bold,italic,color#333333,highlight#cccccc"

  var boldTF = false;
  var italicTF = false;
  var fontColorTF = false;
  var fontSelectedColor = "333333";
  var highlightTF = false;
  var highlightSelectedColor = "cccccc";
  var anyStylingAppliedTF = false;

  if(string_is_filled_out_tf(i_stylingStringCommaList)) {
    const stylingStringCommaSplitArray = i_stylingStringCommaList.split(",");

    for(let stylingString of stylingStringCommaSplitArray) {
      if(stylingString === "bold") {
        boldTF = true;
      }
      else if(stylingString === "italic") {
        italicTF = true;
      }
      else if(stylingString.substring(0, 6) === "color#") {
        fontColorTF = true;
        fontSelectedColor = stylingString.substring(6, stylingString.length);
      }
      else if(stylingString.substring(0, 10) === "highlight#") {
        highlightTF = true;
        highlightSelectedColor = stylingString.substring(10, stylingString.length);
      }
    }
    
    anyStylingAppliedTF = (boldTF || italicTF || fontColorTF || highlightTF);
  }

  return({
    boldTF: boldTF,
    italicTF: italicTF,
    fontColorTF: fontColorTF,
    fontSelectedColor: fontSelectedColor,
    highlightTF: highlightTF,
    highlightSelectedColor: highlightSelectedColor,
    anyStylingAppliedTF: anyStylingAppliedTF
  });
}

export function convert_preset_styling_obj_to_preset_string_or_styling_string_comma_list(i_presetStylingObj) {
  //preset string "presetID:3"
  if(i_presetStylingObj.presetTF) {
    return("presetID:" + i_presetStylingObj.presetID);
  }

  //custom styling comma string "bold,italic,color#333333,highlight#cccccc"
  return(convert_styling_obj_to_styling_string_comma_list(i_presetStylingObj));
}

export function convert_styling_obj_to_styling_string_comma_list(i_stylingObj) {
  //custom styling comma string "bold,italic,color#333333,highlight#cccccc"
  var stylingStringsArray = [];

  if(i_stylingObj.boldTF) {
    stylingStringsArray.push("bold");
  }
  
  if(i_stylingObj.italicTF) {
    stylingStringsArray.push("italic");
  }
  
  if(i_stylingObj.fontColorTF) {
    stylingStringsArray.push("color#" + i_stylingObj.fontSelectedColor);
  }
  
  if(i_stylingObj.highlightTF) {
    stylingStringsArray.push("highlight#" + i_stylingObj.highlightSelectedColor);
  }

  return(convert_array_to_comma_list(stylingStringsArray));
}







//=====================================================================================================================================================
//Names
//=====================================================================================================================================================
export function full_name_from_first_name_last_name(i_firstName, i_lastName) {
  var firstNameFilledOutTF = text_or_number_is_filled_out_tf(i_firstName);
  var lastNameFilledOutTF = text_or_number_is_filled_out_tf(i_lastName);
  if(!firstNameFilledOutTF && !lastNameFilledOutTF) {
    return("--Blank Name--");
  }
  else if(!firstNameFilledOutTF) {
    return(i_lastName);
  }
  else if(!lastNameFilledOutTF) {
    return(i_firstName);
  }
  return(i_firstName + " " + i_lastName);
}



export function match_grid(i_passcode64Chars, i_vpwArray) {
  //passcode_date_or_undefined_from_passcode_and_vpw_array
  const passcodeLength = i_passcode64Chars.length;
  if(passcodeLength !== 64) {
  	return(undefined);
  }

  const numVpwRows = i_vpwArray.length;
  const numPasswordsInVPW = i_vpwArray[0].length;
  var matchAnyTF = false;
  var pwIndex = 0;
  while(!matchAnyTF && (pwIndex < numPasswordsInVPW)) {
    var matchesCurrentIndexTF = true;
    var charIndex = 0;
    while(matchesCurrentIndexTF && (charIndex < passcodeLength)) {
    	if(charIndex < numVpwRows) {
        var inputPasscodeChar = i_passcode64Chars.substring(charIndex, charIndex+1);
        var vpwIndexChar = i_vpwArray[charIndex].substring(pwIndex, pwIndex+1);
        if(inputPasscodeChar !== vpwIndexChar) {
          matchesCurrentIndexTF = false;
        }
      }
      charIndex++;
    }

    if(matchesCurrentIndexTF) {
      matchAnyTF = true;
      break;
    }

    pwIndex++;
  }

  if(matchAnyTF) {
    const jsDateObj = new Date(2021, (9 + (pwIndex * 3)), 15);
    return(get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false));
  }
  return(undefined);
}


//=====================================================================================================================================================
//Dates
//=====================================================================================================================================================
export function now_timestamp_ms() {
  const jsDateObj = new Date();
  return(jsDateObj.getTime());
}
export function now_date() {
  const jsDateObj = new Date();
  return(get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false));
}
export function now_year_int() {
  const jsDateObj = new Date();
  return(jsdateobj_full_year(jsDateObj, false));
}

export function now_datetime() {
  const jsDateObj = new Date();
  const inUtcFlagTF = false;
  return(get_YmdHis_datetime_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF));
}

export function now_datetime_utc() {
  const jsDateObj = new Date();
  const inUtcFlagTF = true;
  return(get_YmdHis_datetime_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF));
}

export function now_datetime_utc_set_minutes_seconds_to_00() {
  const nowDateTimeUTC = now_datetime_utc();
  return(nowDateTimeUTC.substring(0, 14) + "00:00");
}

export function now_datetime_utc_number() { //"20180415113259"
  const jsDateObj = new Date();
  return(date_yyyy(jsDateObj, "utc") + date_mm(jsDateObj, "utc") + date_dd(jsDateObj, "utc") + date_hh(jsDateObj, "utc") + date_ii(jsDateObj, "utc") + date_ss(jsDateObj, "utc"));
}

export function now_datetime_utc_number_with_ms() { //"20180415113259123"
  const jsDateObj = new Date();
  return(date_yyyy(jsDateObj, "utc") + date_mm(jsDateObj, "utc") + date_dd(jsDateObj, "utc") + date_hh(jsDateObj, "utc") + date_ii(jsDateObj, "utc") + date_ss(jsDateObj, "utc") + date_vvv(jsDateObj, "utc"));
}

export function now_datetime_filenames() { //2019-12-31_225959
  const jsDateObj = new Date();
  return(date_yyyy(jsDateObj) + "-" + date_mm(jsDateObj) + "-" + date_dd(jsDateObj) + "_" + date_hh(jsDateObj) + date_ii(jsDateObj) + date_ss(jsDateObj));
}

export function convert_any_style_string_to_ymd_date(i_anyStyleDateString) {
  //quick handling of blank input
  if(!is_string(i_anyStyleDateString) || (i_anyStyleDateString === "")) {
    return(blank_date());
  }

  //handles input like "9/8/19", "Nov 2, 2018", etc using Date()
  const jsDateObj = new Date(i_anyStyleDateString);
  if(isNaN(jsDateObj)) { //invalid date format, return blank date
    return(blank_date());
  }
  const inUtcFlagTF = true;
  return(get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF));
}

export function convert_any_style_string_to_ce_datetime_YmdHis(i_anyStyleDateTimeString) {
  //quick handling of blank input
  if(!is_string(i_anyStyleDateTimeString) || (i_anyStyleDateTimeString === "")) {
    return(blank_datetime());
  }

  //intiialize a date string to convert and an output time as time not set for CE datetime
  var anyStyleDateString = i_anyStyleDateTimeString; //initialize that this whole string is a date (which needs string conversion) with no time on the end
  var ceTimeHisString = "00:00:58";

  //lowercase to search for "am" or "pm" in time portion (or "ce" at beginning)
  var anyStyleDateTimeStringLowercase = i_anyStyleDateTimeString.toLowerCase();
  
  //get the num chars of the input string to help translate it
  var inputNumChars = anyStyleDateTimeStringLowercase.length;

  //to prevent MS Excel from automatically converting "2023-12-09 11:00:00" into 44382.67 for its own datetime formats, put "CE" in front of CE formatted datetimes (for custom customer import jobs), remove that "CE" here
  var anyStyleDateTimeStringCERemoved = i_anyStyleDateTimeString;
  if(inputNumChars > 2) {
    if(anyStyleDateTimeStringLowercase.substring(0, 2) === "ce") {
      anyStyleDateTimeStringCERemoved = i_anyStyleDateTimeString.substring(2, inputNumChars);
      anyStyleDateTimeStringLowercase = anyStyleDateTimeStringLowercase.substring(2, inputNumChars);
      inputNumChars = anyStyleDateTimeStringLowercase.length;
    }
  }

  

  //try to extract time off of the end of the input string in the format " HH:ii:ss" or " HH:ii pm" or " H:ii pm" or " HH:ii"
  if(inputNumChars > 9) {
    var matchedTimeFormatTF = false;

    //9 chars " HH:ii:ss" or " HH:ii pm"
    const last9Chars = anyStyleDateTimeStringLowercase.substring(inputNumChars - 9);
    const frontSpace9 = last9Chars.substring(0, 1);
    const hh00to23String9 = last9Chars.substring(1, 3);
    const firstColon9 = last9Chars.substring(3, 4);
    const ii00to59String9 = last9Chars.substring(4, 6);
    const secondColonOrSpace9 = last9Chars.substring(6, 7);
    const ss00to59OrAMPMString9 = last9Chars.substring(7, 9);
    if((frontSpace9 === " ") && string_is_number_tf(hh00to23String9) && (firstColon9 === ":") && string_is_number_tf(ii00to59String9)) {
      if((secondColonOrSpace9 === ":") && string_is_number_tf(ss00to59OrAMPMString9)) { //" HH:ii:ss"
        anyStyleDateString = anyStyleDateTimeStringCERemoved.substring(0, inputNumChars - 9); //extract date from input datetime string
        if(hh00to23String9 === "00" && ii00to59String9 === "00" && ss00to59OrAMPMString9 === "58") {
          ceTimeHisString = "00:00:58";
        }
        else {
          ceTimeHisString = hh00to23String9 + ":" + ii00to59String9 + ":" + "00"; //CE datetime will set any seconds given here to "00" to avoid 'time not set' 58/59 seconds
        }
        matchedTimeFormatTF = true;
      }
      else if((secondColonOrSpace9 === " ") && (ss00to59OrAMPMString9 === "am" || ss00to59OrAMPMString9 === "pm")) { //" HH:ii pm"
        anyStyleDateString = anyStyleDateTimeStringCERemoved.substring(0, inputNumChars - 9); //extract date from input datetime string
        const hours1to12Int9 = str2int(hh00to23String9); //this am/pm hours should be between 1 and 12
        const hours0to23Int9 = hours0to23_from_hours1to12_with_ampm(hours1to12Int9, ss00to59OrAMPMString9);
        const ampmFixedHh00to23String9 = zero_pad_integer_from_left(hours0to23Int9, 2);
        ceTimeHisString = ampmFixedHh00to23String9 + ":" + ii00to59String9 + ":" + "00"; //CE datetime will set any seconds given here to "00" to avoid 'time not set' 58/59 seconds
        matchedTimeFormatTF = true;
      }
    }

    //8 chars " H:ii pm"
    if(!matchedTimeFormatTF) {
      const last8Chars = anyStyleDateTimeStringLowercase.substring(inputNumChars - 8);
      const frontSpace8 = last8Chars.substring(0, 1);
      const h1to9String8 = last8Chars.substring(1, 2);
      const firstColon8 = last8Chars.substring(2, 3);
      const ii00to59String8 = last8Chars.substring(3, 5);
      const secondSpace8 = last8Chars.substring(5, 6);
      const ampmString8 = last8Chars.substring(6, 8);
      if((frontSpace8 === " ") && string_is_number_tf(h1to9String8) && (firstColon8 === ":") && string_is_number_tf(ii00to59String8) && (secondSpace8 === " ") && (ampmString8 === "am" || ampmString8 === "pm")) {
        anyStyleDateString = anyStyleDateTimeStringCERemoved.substring(0, inputNumChars - 8); //extract date from input datetime string
        const hours1to9Int8 = str2int(h1to9String8); //this am/pm hours should be between 1 and 12
        const hours0to23Int8 = hours0to23_from_hours1to12_with_ampm(hours1to9Int8, ampmString8);
        const ampmFixedHh00to23String8 = zero_pad_integer_from_left(hours0to23Int8, 2);
        ceTimeHisString = ampmFixedHh00to23String8 + ":" + ii00to59String8 + ":" + "00"; //CE datetime will set any seconds given here to "00" to avoid 'time not set' 58/59 seconds
        matchedTimeFormatTF = true;
      }
    }

    //6 chars " HH:ii"
    if(!matchedTimeFormatTF) {
      const last6Chars = anyStyleDateTimeStringLowercase.substring(inputNumChars - 6);
      const frontSpace6 = last6Chars.substring(0, 1);
      const hh00to23String6 = last6Chars.substring(1, 3);
      const firstColon6 = last6Chars.substring(3, 4);
      const ii00to59String6 = last6Chars.substring(4, 6);
      if((frontSpace6 === " ") && string_is_number_tf(hh00to23String6) && (firstColon6 === ":") && string_is_number_tf(ii00to59String6)) {
        anyStyleDateString = anyStyleDateTimeStringCERemoved.substring(0, inputNumChars - 6); //extract date from input datetime string
        ceTimeHisString = hh00to23String6 + ":" + ii00to59String6 + ":" + "00"; //CE datetime will set any seconds given here to "00" to avoid 'time not set' 58/59 seconds
        matchedTimeFormatTF = true;
      }
    }
  }

  const ceDateYmd = convert_any_style_string_to_ymd_date(anyStyleDateString);
  if(ceDateYmd == blank_date()) {
    return(blank_datetime());
  }

  const ceDateTimeYmdHis = ceDateYmd + " " + ceTimeHisString;
  const ss00to59String = direct_get_ss_00to59_string_from_YmdHis_datetime(ceDateTimeYmdHis);
  if((ss00to59String == "58") || (ss00to59String == "59")) {
    return(ceDateTimeYmdHis); //do not convert local/utc if CE time is 'time not set'
  }

  //convert datetime to a UTC value that would make the original input match the user's local browser
  const jsDateObj = convert_mysqldatetimelocal_to_jsdateobj(ceDateTimeYmdHis);
  const inUtcFlagTF = true;
  const ceDateTimeYmdHisUtc = get_YmdHis_datetime_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF);
  return(ceDateTimeYmdHisUtc);
}

export function convert_mysqldate_to_jsdateobj(i_mysqlDateYmd) {
  //mysql date: "YYYY-MM-DD"
  //javascript dateObj: "YYYY-MM-DD"
  var inputDateYmd = i_mysqlDateYmd;
  if(!date_is_filled_out_tf(i_mysqlDateYmd)) {
    inputDateYmd = blank_date();
  }

  const yyyyString = direct_get_yyyy_string_from_Ymd_date(inputDateYmd);
  const mm01to12String = direct_get_mm_01to12_string_from_Ymd_date(inputDateYmd);
  const dd01to31String = direct_get_dd_01to31_string_from_Ymd_date(inputDateYmd);

  const yearInt = str2int(yyyyString);
  const monthIndex0to11 = (str2int(mm01to12String) - 1); //convert php 01-12 to js 0-11
  const dayNumInt1to31 = str2int(dd01to31String);
  const jsDateObj = new Date(yearInt, monthIndex0to11, dayNumInt1to31);
  return(jsDateObj);
}

export function convert_mysqldatetimelocal_to_jsdateobj(i_dateTimeYmdhis) {
  //mysql datetime: "Y-m-d H:i:s"
  var inputDateTimeYmdhis = i_dateTimeYmdhis;
  if(!datetime_is_filled_out_tf(i_dateTimeYmdhis)) {
    inputDateTimeYmdhis = blank_datetime();
  }

  const yyyyString = direct_get_yyyy_string_from_Ymd_date(inputDateTimeYmdhis);
  const mm01to12String = direct_get_mm_01to12_string_from_Ymd_date(inputDateTimeYmdhis);
  const dd01to31String = direct_get_dd_01to31_string_from_Ymd_date(inputDateTimeYmdhis);
  const hh00to23String = direct_get_hh_00to23_string_from_YmdHis_datetime(inputDateTimeYmdhis);
  const ii00to59String = direct_get_ii_00to59_string_from_YmdHis_datetime(inputDateTimeYmdhis);
  const ss00to59String = direct_get_ss_00to59_string_from_YmdHis_datetime(inputDateTimeYmdhis);

  const yearInt = str2int(yyyyString);
  const monthIndex0to11 = (str2int(mm01to12String) - 1); //convert php 01-12 to js 0-11
  const dayNumInt1to31 = str2int(dd01to31String);
  const hours0to23Int = str2int(hh00to23String);
  const minutes0to59Int = str2int(ii00to59String);
  const seconds0to59Int = str2int(ss00to59String);
  const jsDateObj = new Date(yearInt, monthIndex0to11, dayNumInt1to31, hours0to23Int, minutes0to59Int, seconds0to59Int, 0);
  return(jsDateObj);
}

export function convert_mysqldatetimeutc_to_jsdateobj(i_mysqlDateTimeUtc) {
  //mysql datetime: "Y-m-d H:i:s" "2018-04-01 10:02:57"
  //javascript utc: "Y-m-dTH:i:sZ" "2018-04-01T10:02:57Z"
  var inputDateTimeYmdhis = i_mysqlDateTimeUtc;
  if(!datetime_is_filled_out_tf(i_mysqlDateTimeUtc)) {
    inputDateTimeYmdhis = blank_datetime();
  }

  //if the datetime has 58 seconds, convert the input datetime as if it was local (keeps the date portion on the same date, time is usually 00:00:58 which is kept as a 58 second flag that the time is not set)
  const inputDateSecondsString0to59 = direct_get_ss_00to59_string_from_YmdHis_datetime(inputDateTimeYmdhis);
  if(inputDateSecondsString0to59 === "58") {
    return(convert_mysqldatetimelocal_to_jsdateobj(i_mysqlDateTimeUtc));
  }

  return(convert_natural_mysqldatetimeutc_to_jsdateobj(i_mysqlDateTimeUtc));
}

export function convert_natural_mysqldatetimeutc_to_jsdateobj(i_mysqlDateTimeUtc) {
  //mysql datetime: "Y-m-d H:i:s" "2018-04-01 10:02:57"
  //javascript utc: "Y-m-dTH:i:sZ" "2018-04-01T10:02:57Z"
  var inputDateTimeYmdhis = i_mysqlDateTimeUtc;
  if(!datetime_is_filled_out_tf(i_mysqlDateTimeUtc)) {
    inputDateTimeYmdhis = blank_datetime();
  }

  //convert mysql datetime in UTC to javascript date object, then convert that into local timezone in the format specified
  const inputDateYmd = direct_get_Ymd_string_from_YmdHis_datetime(inputDateTimeYmdhis);
  const jsUtcString = inputDateYmd + "T" + inputDateTimeYmdhis.substring(11) + "Z";
  return(new Date(jsUtcString));
}

export function convert_mysqldatetimeutc_to_mysqldatelocal(i_mysqlDateTimeUtc) {
  if(!datetime_is_filled_out_tf(i_mysqlDateTimeUtc)) { //if this is not a valid datetime input or the datetime is not filled out, return a blank date
    return(blank_date());
  }

  const jsDateObj = convert_mysqldatetimeutc_to_jsdateobj(i_mysqlDateTimeUtc);
  const mysqlDateLocal = get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false);
  return(mysqlDateLocal);
}

export function convert_local_dateYmd_hours0to23_minutes_seconds_to_mysqldatetimeutc(i_localDateYmd, i_localHoursInt0to23, i_localMinutesInt0to59, i_localSecondsInt0to59) {
  const localDateYYYYString = direct_get_yyyy_string_from_Ymd_date(i_localDateYmd);
  const localDateMM01to12String = direct_get_mm_01to12_string_from_Ymd_date(i_localDateYmd);
  const localDateDD01to31String = direct_get_dd_01to31_string_from_Ymd_date(i_localDateYmd);

  const localYearInt = str2int(localDateYYYYString);
  const localMonthIndex0to11 = (str2int(localDateMM01to12String) - 1); //convert php 01-12 to js 0-11
  const localDayNumInt1to31 = str2int(localDateDD01to31String);

  const jsDateObj = new Date(localYearInt, localMonthIndex0to11, localDayNumInt1to31, i_localHoursInt0to23, i_localMinutesInt0to59, i_localSecondsInt0to59, 0);
  const inUtcFlagTF = true; //must be UTC for date to match UTC time
  const dateYmdUtc = get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF);
  const timeHisUtc = get_His_time_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF);
  return(dateYmdUtc + " " + timeHisUtc);
}

export function convert_mysqldatetimeutc_to_excel_datetime_local_or_date_local_if_time_not_set(i_mysqlDateTimeUtc) {
  //excel raw datetime format is "2018-01-01T00:00:00.000"
  if(!datetime_is_filled_out_tf(i_mysqlDateTimeUtc)) { //if this is not a valid datetime input or the datetime is not filled out, return a blank date
    return(blank_date());
  }

  const jsDateObj = convert_mysqldatetimeutc_to_jsdateobj(i_mysqlDateTimeUtc);
  const timeIsSetTF = ce_time_is_set_tf_from_jsdateobj(jsDateObj);
  const dateYmdLocal = get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false);
  if(!timeIsSetTF) {
    return(dateYmdLocal);
  }
  const excelDateTimeLocal = dateYmdLocal + "T" + get_His_time_from_jsdateobj_and_utctf(jsDateObj, false) + ".000";
  return(excelDateTimeLocal);
}

export function convert_natural_mysqldatetimeutc_to_excel_datetime_local(i_naturalDateTimeUtc) {
  const jsDateObj = convert_natural_mysqldatetimeutc_to_jsdateobj(i_naturalDateTimeUtc);
  const excelDateTimeLocal = get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false) + "T" + get_His_time_from_jsdateobj_and_utctf(jsDateObj, false) + ".000";
  return(excelDateTimeLocal);
}

export function convert_mysqldate_or_mysqldatetimeutc_to_mysqldatelocal(i_mysqlDateOrDatetimeUtc) {
  if(date_is_filled_out_tf(i_mysqlDateOrDatetimeUtc)) { //if the input is a filled out date like "2020-12-14", then return the date input exactly as is because it already represents the date in the local timezone (since no time was given)
    return(i_mysqlDateOrDatetimeUtc);
  }
  return(convert_mysqldatetimeutc_to_mysqldatelocal(i_mysqlDateOrDatetimeUtc));
}

export function ce_time_is_set_tf_from_jsdateobj(i_jsDateObj) {
  const dateSecondsInt = date_s(i_jsDateObj, false); //seconds are not affected by timezone changes to utc
  return((dateSecondsInt !== 58) && (dateSecondsInt !== 59));
}

export function direct_get_mdY_date_from_Ymd_date(i_mysqlDateYmd) {
  return(direct_get_mm_01to12_string_from_Ymd_date(i_mysqlDateYmd) + "/" + direct_get_dd_01to31_string_from_Ymd_date(i_mysqlDateYmd) + "/" + direct_get_yyyy_string_from_Ymd_date(i_mysqlDateYmd));
}
export function direct_get_dmY_date_from_Ymd_date(i_mysqlDateYmd) {
  return(direct_get_dd_01to31_string_from_Ymd_date(i_mysqlDateYmd) + "/" + direct_get_mm_01to12_string_from_Ymd_date(i_mysqlDateYmd) + "/" + direct_get_yyyy_string_from_Ymd_date(i_mysqlDateYmd));
}
export function direct_convert_mysqldate_to_MjY(i_mysqlDateYmd) {
  const yyyyString = direct_get_yyyy_string_from_Ymd_date(i_mysqlDateYmd); //"2021"
  const mm01to12String = direct_get_mm_01to12_string_from_Ymd_date(i_mysqlDateYmd); //"04"
  const dd01to31String = direct_get_dd_01to31_string_from_Ymd_date(i_mysqlDateYmd); //"15"
  const monthIndex0to11 = (str2int(mm01to12String) - 1); //3
  const mthString = date_mth_from_index(monthIndex0to11); //"Apr"
  const d1To31Int = str2int(dd01to31String); //15
  return(mthString + " " + d1To31Int + ", " + yyyyString);
}

export function date_format_from_jsdateobj(i_jsDateObj, i_dateFormat, i_inUtcFlagTF=false) {
  if(!i_jsDateObj) {
    return("--Invalid Input JS Date Obj (" + i_jsDateObj + ")--");
  }

  var date = "";
  if(i_dateFormat === "Y-m-d" || i_dateFormat === "") { //default option if the date format cannot be loaded from loggedInUserDataArray
    date = get_Ymd_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF);
  }
  else if(i_dateFormat === "m/d/Y") { //12/31/2018
    date = date_mm(i_jsDateObj, i_inUtcFlagTF) + "/" + date_dd(i_jsDateObj, i_inUtcFlagTF) + "/" + date_yyyy(i_jsDateObj, i_inUtcFlagTF);
  }
  else if(i_dateFormat === "d/m/Y") { //31/12/2018
    date = date_dd(i_jsDateObj, i_inUtcFlagTF) + "/" + date_mm(i_jsDateObj, i_inUtcFlagTF) + "/" + date_yyyy(i_jsDateObj, i_inUtcFlagTF);
  }
  else if(i_dateFormat === "M j, Y") { //Dec 7, 2018
    date = get_MjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF);
  }
  else if(i_dateFormat === "D M j, Y") { //Fri Dec 7, 2018
    date = get_DMjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF);
  }
  else {
    return("--Invalid Date Format (" + i_dateFormat + ")--");
  }
  return(date);
}

export function date_or_datetime_format_from_jsdateobj(i_jsDateObj, i_dateFormat, i_timeFormat, i_inUtcFlagTF=false) {
  if(!i_jsDateObj) {
    return("--Invalid Input JS Date Obj (" + i_jsDateObj + ")--");
  }

  var date = date_format_from_jsdateobj(i_jsDateObj, i_dateFormat, i_inUtcFlagTF=false);

  var time = "";
  if(i_timeFormat === "g:i A") { //3:59 PM
    time = get_giA_time_with_59sec_not_set_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF);
  }

  var dateTime = date;
  if(time !== "") {
    dateTime += " " + time;
  }
  return(dateTime);
}

//php time: [g 1-12, G 0-23, h 01-12, H 00-23], [i 00-59], [s 00-59], [a am/pm A AM/PM]
export function get_Ymd_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //2021-09-07
  return(date_yyyy(i_jsDateObj, i_inUtcFlagTF) + "-" + date_mm(i_jsDateObj, i_inUtcFlagTF) + "-" + date_dd(i_jsDateObj, i_inUtcFlagTF));
}
export function get_MjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //Sep 7, 2021
  return(date_mth(i_jsDateObj, i_inUtcFlagTF) + " " + date_d(i_jsDateObj, i_inUtcFlagTF) + ", " + date_yyyy(i_jsDateObj, i_inUtcFlagTF));
}
export function get_DMjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //Tue Sep 7, 2021
  return(date_day(i_jsDateObj, i_inUtcFlagTF) + " " + date_mth(i_jsDateObj, i_inUtcFlagTF) + " " + date_d(i_jsDateObj, i_inUtcFlagTF) + ", " + date_yyyy(i_jsDateObj, i_inUtcFlagTF));
}
export function get_md_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //09/07
  return(date_mm(i_jsDateObj, i_inUtcFlagTF) + "/" + date_dd(i_jsDateObj, i_inUtcFlagTF));
}

export function get_His_time_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //15:08:42
  return(date_hh(i_jsDateObj, i_inUtcFlagTF) + ":" + date_ii(i_jsDateObj, i_inUtcFlagTF) + ":" + date_ss(i_jsDateObj, i_inUtcFlagTF));
}
export function get_giA_time_with_59sec_not_set_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //3:08 PM (or --:--)
  const timeIsSetTF = ce_time_is_set_tf_from_jsdateobj(i_jsDateObj);
  if(!timeIsSetTF) {
    return("--:--");
  }
  return(get_giA_time_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}
export function get_giA_time_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //3:08 PM
  return(date_hap(i_jsDateObj, i_inUtcFlagTF) + ":" + date_ii(i_jsDateObj, i_inUtcFlagTF) + " " + date_ampm(i_jsDateObj, i_inUtcFlagTF));
}

export function get_YmdHis_datetime_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //2021-09-07 15:08:42
  return(get_Ymd_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF) + " " + get_His_time_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}

export function get_YmdgiA_datetime_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //2021-09-07 3:08 PM (or --:--)
  return(get_Ymd_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF) + " " + get_giA_time_with_59sec_not_set_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}
export function get_YmdgiA_datetime_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //2021-09-07 3:08 PM
  return(get_Ymd_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF) + " " + get_giA_time_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}

export function get_MjYgiA_datetime_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //Sep 7, 2021 3:08 PM (or --:--)
  return(get_MjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF) + " " + get_giA_time_with_59sec_not_set_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}
export function get_MjYgiA_datetime_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //Sep 7, 2021 3:08 PM
  return(get_MjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF) + " " + get_giA_time_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}

export function get_DMjYgiA_datetime_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //Tue Sep 7, 2021 3:08 PM (or --:--)
  return(get_DMjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF) + " " + get_giA_time_with_59sec_not_set_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}
export function get_DMjYgiA_datetime_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF=false) { //Tue Sep 7, 2021 3:08 PM
  return(get_DMjY_date_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF) + " " + get_giA_time_natural_from_jsdateobj_and_utctf(i_jsDateObj, i_inUtcFlagTF));
}

export function get_Ymd_date_local_from_natural_datetime_utc(i_naturalDateTimeUtc) {
  const jsDateObj = convert_natural_mysqldatetimeutc_to_jsdateobj(i_naturalDateTimeUtc);
  const inUtcFlagTF = false;
  return(get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF));
}
export function get_MjYgiA_datetime_local_from_natural_datetime_utc(i_naturalDateTimeUtc) {
  const jsDateObj = convert_natural_mysqldatetimeutc_to_jsdateobj(i_naturalDateTimeUtc);
  const inUtcFlagTF = false;
  return(get_MjY_date_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF) + " " + get_giA_time_natural_from_jsdateobj_and_utctf(jsDateObj, inUtcFlagTF));
}

export function direct_get_yyyy_string_from_Ymd_date(i_mysqlDateYmd) { return(i_mysqlDateYmd.substring(0,4)); }
export function direct_get_mm_01to12_string_from_Ymd_date(i_mysqlDateYmd) { return(i_mysqlDateYmd.substring(5,7)); }
export function direct_get_dd_01to31_string_from_Ymd_date(i_mysqlDateYmd) { return(i_mysqlDateYmd.substring(8,10)); }
export function direct_get_Ymd_string_from_YmdHis_datetime(i_mysqlDateYmd) { return(i_mysqlDateYmd.substring(0,10)); }
export function direct_get_hh_00to23_string_from_YmdHis_datetime(i_mysqlDateTimeYmdHis) { return(i_mysqlDateTimeYmdHis.substring(11,13)); }
export function direct_get_ii_00to59_string_from_YmdHis_datetime(i_mysqlDateTimeYmdHis) { return(i_mysqlDateTimeYmdHis.substring(14,16)); }
export function direct_get_ss_00to59_string_from_YmdHis_datetime(i_mysqlDateTimeYmdHis) { return(i_mysqlDateTimeYmdHis.substring(17,19)); }

function jsdateobj_full_year(i_jsDateObj, i_inUtcFlagTF=false) { //year number
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCFullYear()); }
  return(i_jsDateObj.getFullYear());
}
function jsdateobj_month(i_jsDateObj, i_inUtcFlagTF=false) { //number 0-11
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCMonth()); }
  return(i_jsDateObj.getMonth());
}
function jsdateobj_date_of_month(i_jsDateObj, i_inUtcFlagTF=false) { //number 1-31
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCDate()); }
  return(i_jsDateObj.getDate());
}
function jsdateobj_day_of_week(i_jsDateObj, i_inUtcFlagTF=false) { //number 0-6
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCDay()); }
  return(i_jsDateObj.getDay());
}
function jsdateobj_hours(i_jsDateObj, i_inUtcFlagTF=false) { //number 0-23
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCHours()); }
  return(i_jsDateObj.getHours());
}
function jsdateobj_minutes(i_jsDateObj, i_inUtcFlagTF=false) { //number 0-59
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCMinutes()); }
  return(i_jsDateObj.getMinutes());
}
function jsdateobj_seconds(i_jsDateObj, i_inUtcFlagTF=false) { //number 0-59
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCSeconds()); }
  return(i_jsDateObj.getSeconds());
}
function jsdateobj_milliseconds(i_jsDateObj, i_inUtcFlagTF=false) { //number 0-999
  if(i_inUtcFlagTF) { return(i_jsDateObj.getUTCMilliseconds()); }
  return(i_jsDateObj.getMilliseconds());
}

export function date_yyyy(i_jsDateObj, i_inUtcFlagTF=false) {
  return(jsdateobj_full_year(i_jsDateObj, i_inUtcFlagTF));
}
export function date_mm(i_jsDateObj, i_inUtcFlagTF=false) { //string
  const month01to12 = jsdateobj_month(i_jsDateObj, i_inUtcFlagTF) + 1;
  return(zero_pad_integer_from_left(month01to12, 2));
}
export function date_dd(i_jsDateObj, i_inUtcFlagTF=false) {
  const date01to31 = jsdateobj_date_of_month(i_jsDateObj, i_inUtcFlagTF);
  return(zero_pad_integer_from_left(date01to31, 2));
}
export function date_d(i_jsDateObj, i_inUtcFlagTF=false) {
  return(jsdateobj_date_of_month(i_jsDateObj, i_inUtcFlagTF));
}
export function date_hh(i_jsDateObj, i_inUtcFlagTF=false) {
  const hours00to23 = jsdateobj_hours(i_jsDateObj, i_inUtcFlagTF);
  return(zero_pad_integer_from_left(hours00to23, 2));
}
export function date_h(i_jsDateObj, i_inUtcFlagTF=false) {
  return(jsdateobj_hours(i_jsDateObj, i_inUtcFlagTF));
}
export function date_hap(i_jsDateObj, i_inUtcFlagTF=false) {
  const hours = jsdateobj_hours(i_jsDateObj, i_inUtcFlagTF);
  if(hours === 0) { return(12); } //midnight
  else if(hours > 12) { return(hours - 12); }
  else { return(hours); }
}
export function date_ii(i_jsDateObj, i_inUtcFlagTF=false) {
  const minutes00to59 = jsdateobj_minutes(i_jsDateObj, i_inUtcFlagTF);
  return(zero_pad_integer_from_left(minutes00to59, 2));
}
export function date_i(i_jsDateObj, i_inUtcFlagTF=false) {
  return(jsdateobj_minutes(i_jsDateObj, i_inUtcFlagTF));
}
export function date_ss(i_jsDateObj, i_inUtcFlagTF=false) {
  const seconds00to59 = jsdateobj_seconds(i_jsDateObj, i_inUtcFlagTF);
  return(zero_pad_integer_from_left(seconds00to59, 2));
}
export function date_s(i_jsDateObj, i_inUtcFlagTF=false) {
  return(jsdateobj_seconds(i_jsDateObj, i_inUtcFlagTF));
}
export function date_vvv(i_jsDateObj, i_inUtcFlagTF=false) { //milliseconds
  const milliseconds000to999 = jsdateobj_milliseconds(i_jsDateObj, i_inUtcFlagTF);
  return(zero_pad_integer_from_left(milliseconds000to999, 3));
}
export function date_month(i_jsDateObj, i_inUtcFlagTF=false) {
  return(date_month_from_index(jsdateobj_month(i_jsDateObj, i_inUtcFlagTF)));
}
export function date_month_from_index(i_monthIndex0to11) {
  const monthArray = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  return(monthArray[i_monthIndex0to11]);
}
export function date_mth(i_jsDateObj, i_inUtcFlagTF=false) {
  return(date_mth_from_index(jsdateobj_month(i_jsDateObj, i_inUtcFlagTF)));
}
export function date_mth_from_index(i_monthIndex0to11) {
  const mthArray = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  return(mthArray[i_monthIndex0to11]);
}
export function date_month_letter(i_jsDateObj, i_inUtcFlagTF=false) {
  return(date_month_letter_from_index(jsdateobj_month(i_jsDateObj, i_inUtcFlagTF)));
}
export function date_month_letter_from_index(i_monthIndex0to11) {
  const mthArray = ["J","F","M","A","M","J","J","A","S","O","N","D"];
  return(mthArray[i_monthIndex0to11]);
}
export function date_w(i_jsDateObj, i_inUtcFlagTF=false) { //0-6 for Sun-Sat
  return(jsdateobj_day_of_week(i_jsDateObj, i_inUtcFlagTF));
}
export function date_dayname(i_jsDateObj, i_inUtcFlagTF=false) {
  return(date_dayname_from_index(jsdateobj_day_of_week(i_jsDateObj, i_inUtcFlagTF)));
}
export function date_dayname_from_index(i_dayOfWeekIndex0to6) {
  const daynameArray = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  return(daynameArray[i_dayOfWeekIndex0to6]);
}
export function date_day(i_jsDateObj, i_inUtcFlagTF=false) {
  return(date_day_from_index(jsdateobj_day_of_week(i_jsDateObj, i_inUtcFlagTF)));
}
export function date_day_from_index(i_dayOfWeekIndex0to6) {
  const dayArray = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
  return(dayArray[i_dayOfWeekIndex0to6]);
}
export function date_ampm(i_jsDateObj, i_inUtcFlagTF=false) {
  return((jsdateobj_hours(i_jsDateObj, i_inUtcFlagTF) < 12) ? ("AM") : ("PM"));
}
export function date_ap(i_jsDateObj, i_inUtcFlagTF=false) {
  return((jsdateobj_hours(i_jsDateObj, i_inUtcFlagTF) < 12) ? ("a") : ("p"));
}

export function hours0to23_from_hours1to12_with_ampm(i_hours1to12, i_ampm) {
  var hours1to12 = i_hours1to12;
  if(i_hours1to12 < 1) {
    hours1to12 = 1;
  }
  else if(i_hours1to12 > 12) {
    hours1to12 = 12;
  }

  var hours0to23 = undefined;
  if(i_ampm === "AM" || i_ampm === "am" || i_ampm === "a") {
    if(hours1to12 === 12) {
      hours0to23 = 0; //midnight 12AM is 00
    }
    else {
      hours0to23 = hours1to12; //1AM - 11AM is 01 - 11
    }
  }
  else { //"PM"
    if(hours1to12 === 12) {
      hours0to23 = 12; //midnight 12PM is 12
    }
    else {
      hours0to23 = hours1to12 + 12; //1PM - 11PM is 13 - 23
    }
  }
  return(hours0to23);
}

export function hours1to12_with_ampm_from_hours0to23(i_hours0to23) {
  var hours0to23 = i_hours0to23;
  if(is_number_not_nan_gte_0(i_hours0to23)) {
    if(i_hours0to23 > 23) {
      hours0to23 = 23;
    }
  }
  else {
    hours0to23 = 0;
  }
  
  var amPm = "AM";
  if(hours0to23 >= 12) {
    amPm = "PM";
  }

  var hours1to12 = 0;
  if(hours0to23 === 0) { //12AM
    hours1to12 = 12;
  }
  else if(hours0to23 < 13) { //1AM - 12PM
    hours1to12 = hours0to23;
  }
  else { //1PM - 11PM
    hours1to12 = (hours0to23 - 12);
  }

  return(hours1to12 + amPm);
}

export function date_add_days(i_dateYmd, i_nunDays) {
  const jsDateObj = convert_mysqldate_to_jsdateobj(i_dateYmd);
  const shiftedJsDateObj = jsdateobj_add_days(jsDateObj, i_nunDays);
  return(get_Ymd_date_from_jsdateobj_and_utctf(shiftedJsDateObj, false));
}

export function jsdateobj_add_days(i_jsDateObj, i_nunDays) {
  i_jsDateObj.setDate(i_jsDateObj.getDate() + i_nunDays);
  return(i_jsDateObj);
}

export function date_add_months(i_dateYmd, i_numMonths) {
  const jsDateObj = convert_mysqldate_to_jsdateobj(i_dateYmd);
  const shiftedJsDateObj = jsdateobj_add_months(jsDateObj, i_numMonths);
  return(get_Ymd_date_from_jsdateobj_and_utctf(shiftedJsDateObj, false));
}

export function jsdateobj_add_months(i_jsDateObj, i_numMonths) {
  var d = i_jsDateObj.getDate();
  i_jsDateObj.setMonth(i_jsDateObj.getMonth() + +i_numMonths);
  if(i_jsDateObj.getDate() !== d) {
    i_jsDateObj.setDate(0);
  }
  return(i_jsDateObj);
}

export function num_days_from_date1_to_date2(i_dateyyyymmdd1, i_dateyyyymmdd2) {
  return(num_days_from_jsDateObj1_to_jsDateObj2(convert_mysqldate_to_jsdateobj(i_dateyyyymmdd1), convert_mysqldate_to_jsdateobj(i_dateyyyymmdd2)));
}

export function num_days_from_jsDateObj1_to_jsDateObj2(i_jsDateObj1, i_jsDateObj2) {
  return(Math.round(num_seconds_from_jsDateObj1_to_jsDateObj2(i_jsDateObj1, i_jsDateObj2) / 86400));
}

export function num_seconds_from_dateTimeUtc1_to_dateTimeUtc2(i_dateTimeUtc1, i_dateTimeUtc2) {
  return(num_seconds_from_jsDateObj1_to_jsDateObj2(convert_mysqldatetimeutc_to_jsdateobj(i_dateTimeUtc1), convert_mysqldatetimeutc_to_jsdateobj(i_dateTimeUtc2)));
}

export function num_seconds_from_jsDateObj1_to_jsDateObj2(i_jsDateObj1, i_jsDateObj2) {
  return(Math.round((i_jsDateObj2.getTime() - i_jsDateObj1.getTime()) / 1000));
}

export function compute_end_date_from_start_date_and_num_months(i_startDateYmd, i_numMonths) {
  //example: 03/20/2019  and  3 months  results in  06/19/2019
  if(!date_is_filled_out_tf(i_startDateYmd)) { //invalid start date results in an invalid end date, regardless of months input
    return(blank_date());
  }
  else if(i_numMonths === 0) { //for an input of 0 months, return the same date rather than -1 day which would be default behavior
    return(i_startDateYmd);
  }

  var endDateYmd = date_add_months(i_startDateYmd, i_numMonths);
  endDateYmd = date_add_days(endDateYmd, -1);
  return(endDateYmd);
}

export function blank_date() {
  return("0000-00-00");
}

export function blank_datetime() {
  return("0000-00-00 00:00:00");
}


export function convert_relative_ymd_date_to_fixed_date_ymd(i_realativeYmdDate) {
  var fixedDateObj = convert_relative_ymd_date_to_fixed_date_obj(i_realativeYmdDate, undefined);
  return(fixedDateObj.fixedDateYmd);
}

export function convert_relative_ymd_date_to_fixed_date_obj(i_realativeYmdDate, i_outputDateFormat=undefined) {
  //i_relativeDate is a normal fixed date unless the year is >= 8000
  //
  //'years' 4 digit number is the relative offset amount
  //'months' 2 digit number is always 01 and is ignored
  //'date' 2 digit number is a flag to differentiate relative # days/weeks/months/years
  //    "01" - relative # days
  //    "02" - relative # weeks
  //    "03" - relative # months
  //    "04" - relative # years
  //
  //"9001-01-01"  =>  today + 1 day
  //"9157-01-01"  =>  today + 157 days
  //"8012-01-01"  =>  today - 12 days
  //"9005-01-04"  =>  today + 4 years

  var isRelativeTF = false;
  var relativeType = "fixed";
  var relativeTypeMask = "fixed";
  var relativeOffset = 0;
  var fixedDateYmd = undefined;
  var fixedDateFormatted = undefined;
  var jsDateObj = undefined;
  if(date_is_filled_out_tf(i_realativeYmdDate)) { //verifies the input date is a string so that substring() can be used safely
    const yyyyString = direct_get_yyyy_string_from_Ymd_date(i_realativeYmdDate);
    const yearInt = str2int(yyyyString);
    if(yearInt >= 8000) { //relative dates based on today's date and a specified positive/negative offset number of days using the raw date's year
      isRelativeTF = true;

      if((yearInt > 8000) && (yearInt < 9000)) {
        relativeOffset = (8000 - yearInt); //negative offset from today's date
      }
      else if((yearInt > 9000) && (yearInt < 10000)) {
        relativeOffset = (yearInt - 9000); //positive offset from today's date
      }

      const relativeOffsetIs1Orm1TF = ((relativeOffset === 1) || (relativeOffset === -1));

      jsDateObj = new Date(); //set the initial jsDateObj to today's date
      const dd01to31String = direct_get_dd_01to31_string_from_Ymd_date(i_realativeYmdDate);
      if(dd01to31String === "02") {
        relativeType = "weeks";
        relativeTypeMask = ((relativeOffsetIs1Orm1TF) ? ("week") : ("weeks"));
        jsDateObj.setDate(jsDateObj.getDate() + (relativeOffset * 7));
      }
      else if(dd01to31String === "03") {
        relativeType = "months";
        relativeTypeMask = ((relativeOffsetIs1Orm1TF) ? ("month") : ("months"));
        jsDateObj = jsdateobj_add_months(jsDateObj, relativeOffset)
      }
      else if(dd01to31String === "04") {
        relativeType = "years";
        relativeTypeMask = ((relativeOffsetIs1Orm1TF) ? ("year") : ("years"));
        jsDateObj = new Date((jsdateobj_full_year(jsDateObj) + relativeOffset), jsdateobj_month(jsDateObj), jsdateobj_date_of_month(jsDateObj));
      }
      else { //"01"
        relativeType = "days";
        relativeTypeMask = ((relativeOffsetIs1Orm1TF) ? ("day") : ("days"));
        jsDateObj.setDate(jsDateObj.getDate() + relativeOffset);
      }

      fixedDateYmd = get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false);
    }
    else { //fixed dates < 8000
      jsDateObj = convert_mysqldate_to_jsdateobj(i_realativeYmdDate);
      fixedDateYmd = i_realativeYmdDate;
    }

    if(i_outputDateFormat !== undefined) { //save computation time if a conversion is not needed
      fixedDateFormatted = date_or_datetime_format_from_jsdateobj(jsDateObj, i_outputDateFormat, "", false);
    }
  }
  else { //invalid date
    fixedDateYmd = blank_date();
    fixedDateFormatted = blank_date();
  }

  return({
    valueRaw: i_realativeYmdDate,
    isRelativeTF: isRelativeTF,
    relativeType: relativeType,
    relativeTypeMask: relativeTypeMask,
    relativeOffset: relativeOffset,
    fixedDateYmd: fixedDateYmd,
    fixedDateFormatted: fixedDateFormatted
  });
}



export function convert_date_with_duration_raw_datetime_to_date_with_duration_obj(i_dateWithDurationRawDateTime) {
  //input raw value is a datetime where the time is used to represent the duration hh:ii:ss -> h1 (unused), i1 (2=weeks, 3=months, 4=years, anything else days), s1/h2/i2/s2 (4 digit number 0000-5999)
  var inputDateWithDurationRawDateTime = i_dateWithDurationRawDateTime;
  if(!datetime_is_filled_out_tf(inputDateWithDurationRawDateTime)) {
    inputDateWithDurationRawDateTime = blank_datetime();
  }

  const dateYmd = direct_get_Ymd_string_from_YmdHis_datetime(inputDateWithDurationRawDateTime);

  const i1String = inputDateWithDurationRawDateTime.substring(14, 15); //i1 of the minutes, which for a datetime can only be an int from 0-5
  var days1Weeks2Months3Years4Int = str2int(i1String, 1);
  if(!in_array(days1Weeks2Months3Years4Int, [2, 3, 4])) {
    days1Weeks2Months3Years4Int = 1;
  }

  const s1String = inputDateWithDurationRawDateTime.substring(17, 18); //s1 of the seconds, which for a datetime can only be an int from 0-5
  const h2String = inputDateWithDurationRawDateTime.substring(12, 13); //h2 of the hours, can be 0-9
  const i2String = inputDateWithDurationRawDateTime.substring(15, 16); //i2 of the minutes, can be 0-9
  const s2String = inputDateWithDurationRawDateTime.substring(18, 19); //s2 of the seconds, can be 0-9
  const numDaysWeeksMonthsYearsString = s1String + h2String + i2String + s2String;
  const numDaysWeeksMonthsYearsInt = str2int(numDaysWeeksMonthsYearsString, 0);

  return({
    dateYmd: dateYmd,
    numDaysWeeksMonthsYearsInt: numDaysWeeksMonthsYearsInt,
    days1Weeks2Months3Years4Int: days1Weeks2Months3Years4Int
  });
}



export function convert_date_with_duration_date_ymd_and_num_and_type1234_to_date_with_duration_raw_datetime(i_dateYmd, i_numDaysWeeksMonthsYearsInt, i_days1Weeks2Months3Years4Int) {
  var dateYmd = i_dateYmd;
  if(!date_is_filled_out_tf(i_dateYmd)) {
    dateYmd = blank_date();
  }

  var h1String = "0"; //h1 string is unused and always 0

  var i1String = "0"; //days as 1, but represented as 0 so that it's easier to get a full blank time of "00:00:00"
  if(in_array(i_days1Weeks2Months3Years4Int, [2, 3, 4])) {
    i1String = num2str(i_days1Weeks2Months3Years4Int);
  }

  var s1h2i2s2String = "0000";
  if((i_numDaysWeeksMonthsYearsInt >= 0) && (i_numDaysWeeksMonthsYearsInt <= 5999)) {
    s1h2i2s2String = zero_pad_integer_from_left(i_numDaysWeeksMonthsYearsInt, 4);
  }
  var s1String = s1h2i2s2String.substring(0, 1);
  var h2String = s1h2i2s2String.substring(1, 2);
  var i2String = s1h2i2s2String.substring(2, 3);
  var s2String = s1h2i2s2String.substring(3, 4);

  return(dateYmd + " " + h1String + h2String + ":" + i1String + i2String + ":" + s1String + s2String);
}



export function num_days_weeks_months_years_until_date_or_overdue_string(i_numDaysUntilDate) {
  var numDaysWeeksMonthsYearsUntilDateOrOverdueString = "--Initialize--";
  if(i_numDaysUntilDate < 0) {
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = "Overdue";
  }
  else if(i_numDaysUntilDate === 0) { //today
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = "Today";
  }
  else if(i_numDaysUntilDate === 1) { //1 day
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = "1 day";
  }
  else if(i_numDaysUntilDate < 14) { //2 days, 3 days, ..., 12 days, 13 days
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = i_numDaysUntilDate + " days";
  }
  else if(i_numDaysUntilDate < 32) { //(14-17) 2 weeks, (18-24) 3 weeks, (25-31) 4 weeks
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = Math.round(i_numDaysUntilDate/7) + " weeks";
  }
  else if(i_numDaysUntilDate < 59) {//(32-58) 1 month
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = "1 month";
  }
  else if(i_numDaysUntilDate < 351) {//(59-76) 2 months, (77-106) 3 months, ..., (290-319) 10 months, (320-350) 11 months
    numDaysWeeksMonthsYearsUntilDateOrOverdueString= Math.round(i_numDaysUntilDate/30.4375) + " months";
  }
  else if(i_numDaysUntilDate < 720) { //(351+) 1 year
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = "1 year";
  }
  else {
    numDaysWeeksMonthsYearsUntilDateOrOverdueString = Math.floor(i_numDaysUntilDate/365) + " years";
  }
  return(numDaysWeeksMonthsYearsUntilDateOrOverdueString);
}



//==================================================================================================================================================
//Color
//==================================================================================================================================================
export function color_interpolation(i_percent0to100, i_redDecAt0, i_redDecAt100, i_greenDecAt0, i_greenDecAt100, i_blueDecAt0, i_blueDecAt100) {
  return(single_color_interpolation(i_percent0to100, i_redDecAt0, i_redDecAt100) + single_color_interpolation(i_percent0to100, i_greenDecAt0, i_greenDecAt100) + single_color_interpolation(i_percent0to100, i_blueDecAt0, i_blueDecAt100));
}

function single_color_interpolation(i_percent0to100, i_colorDec0to255At0percent, i_colorDec0to255At100percent) {
  //bound the input percent between 0 and 100
  var percent0to100 = i_percent0to100;
  if(i_percent0to100 > 100) {
    percent0to100 = 100;
  }
  else if(i_percent0to100 < 0) {
    percent0to100 = 0;
  }

  const range = (i_colorDec0to255At100percent - i_colorDec0to255At0percent);
  const distanceFromLower = Math.round((percent0to100 / 100) * range);
  const interpColorDec = i_colorDec0to255At0percent + distanceFromLower;
  return(color_decimal_to_hex2(interpColorDec));
}



export function color_2d_interpolation(i_x0to1, i_y0to1, i_rTL, i_gTL, i_bTL, i_rTR, i_gTR, i_bTR, i_rBR, i_gBR, i_bBR, i_rBL, i_gBL, i_bBL) { //all colors are decimals 0-255
  return(single_color_2d_interpolation(i_x0to1, i_y0to1, i_rTL, i_rTR, i_rBR, i_rBL) + single_color_2d_interpolation(i_x0to1, i_y0to1, i_gTL, i_gTR, i_gBR, i_gBL) + single_color_2d_interpolation(i_x0to1, i_y0to1, i_bTL, i_bTR, i_bBR, i_bBL));
}

function single_color_2d_interpolation(i_x0to1, i_y0to1, i_cTL, i_cTR, i_cBR, i_cBL) {
  //i_x0to1  desired x position from 0 (left wall) to 1 (right wall)
  //i_y0to1  desired y position from 0 (bottom wall) to 1 (top wall)
  //i_cTL    single color (red or green or blue, decimal value 0 to 255) of the top-left corner
  //i_cTR    single color (red or green or blue, decimal value 0 to 255) of the top-right corner
  //i_cBR    single color (red or green or blue, decimal value 0 to 255) of the bottom-right corner
  //i_cBL    single color (red or green or blue, decimal value 0 to 255) of the bottom-left corner

  //interpolation reference corner points at (0,0) (0,1) (1,0) and (1,1)    f(Q11) = f(x1,y1) etc
  const interpColor0to255 = bilinear_interpolation(0, 0, 1, 1, i_cBL, i_cTL, i_cBR, i_cTR, i_x0to1, i_y0to1)
  return(color_decimal_to_hex2(interpColor0to255));
}



export function color_add_decimal_rgb_values(i_initialColorHex3OrHex6, i_redDecToAdd, i_greenDecToAdd, i_blueDecToAdd, i_returnWithHashTF=true) {
  //i_initialColorHex3OrHex6: "cccccc"/"#cccccc"/"ccc"/"#ccc"   i_redDecToAdd = 2, i_greenDecToAdd = -2, i_blueDecToAdd = 1000
  //returns "#cecaff"
  const [red0to255, green0to255, blue0to255] = convert_hex3_or_hex6_to_rgb_decimals(i_initialColorHex3OrHex6);
  const newRed0to255 = red0to255 + i_redDecToAdd;
  const newGreen0to255 = green0to255 + i_greenDecToAdd;
  const newBlue0to255 = blue0to255 + i_blueDecToAdd;
  const newHex6Color = convert_rgb_decimals_to_hex6_no_pound(newRed0to255, newGreen0to255, newBlue0to255);
  if(i_returnWithHashTF) {
    return("#" + newHex6Color);
  }
  return(newHex6Color);
}

function convert_rgb_decimals_to_hex6_no_pound(i_red0to255, i_green0to255, i_blue0to255) {
  return(color_decimal_to_hex2(i_red0to255) + color_decimal_to_hex2(i_green0to255) + color_decimal_to_hex2(i_blue0to255));
}

function convert_hex3_or_hex6_to_rgb_decimals(i_hex3OrHex6) {
  //i_hex3OrHex6 formats: "3bf" (33bbff), "#3bf" (33bbff), "36bafe", "#36bafe"
  //returns [red0to255, green0to255, blue0to255]
  const hex6 = convert_any_hex_to_hex6(i_hex3OrHex6);

  const redHex = hex6.substring(0,2);
  const greenHex = hex6.substring(2,4);
  const blueHex = hex6.substring(4,6);

  const redDec = color_hex1_or_hex2_to_decimal(redHex);
  const greenDec = color_hex1_or_hex2_to_decimal(greenHex);
  const blueDec = color_hex1_or_hex2_to_decimal(blueHex);

  return([redDec, greenDec, blueDec]);
}

export function convert_any_hex_to_hex6(i_hex3OrHex6WithOrWithoutHash) {
  //i_hex3OrHex6WithOrWithoutHash formats: "3bf" (33bbff), "#3bf" (33bbff), "36bafe", "#36bafe"
  //returns "33bbff"
  if(is_string(i_hex3OrHex6WithOrWithoutHash)) {
    const hexStrLen = i_hex3OrHex6WithOrWithoutHash.length;
    var hex6 = undefined;
    if(hexStrLen === 3 || hexStrLen === 4) {
      var hex3 = i_hex3OrHex6WithOrWithoutHash;
      if(hexStrLen === 4) {
        hex3 = i_hex3OrHex6WithOrWithoutHash.substring(1, hexStrLen);
      }
      const h1 = hex3.substring(0,1);
      const h2 = hex3.substring(1,2);
      const h3 = hex3.substring(2,3);
      hex6 = h1 + h1 + h2 + h2 + h3 + h3; //"3bf" or "#3bf" becomes "33bbff"
    }
    else if(hexStrLen === 6 || hexStrLen === 7) {
      hex6 = i_hex3OrHex6WithOrWithoutHash;
      if(hexStrLen === 7) {
        hex6 = i_hex3OrHex6WithOrWithoutHash.substring(1, hexStrLen);
      }
    }

    //valid color input was passed and converted to hex6
    if(hex6 !== undefined) {
      return(hex6);
    }
  }
  return("000000");
}

function color_hex1_or_hex2_to_decimal(i_hex1OrHex2) {
  //i_hex1OrHex2 formats: "f" (ff), "fe"
  if(!is_string(i_hex1OrHex2)) {
    return(0);
  }
  const hexStrLen = i_hex1OrHex2.length;
  if(hexStrLen === 2) {
    return(parseInt(i_hex1OrHex2, 16));
  }
  else if(hexStrLen === 1) {
    const hex2 = i_hex1OrHex2 + i_hex1OrHex2; //"f" becomes "ff"
    return(parseInt(hex2, 16));
  }
  else {
    return(0);
  }
}

function color_decimal_to_hex2(i_decimal0to255) {
  var decimal0to255 = Math.round(i_decimal0to255);
  if(decimal0to255 < 0) {
    return("00");
  }
  else if(decimal0to255 > 255) {
    return("ff");
  }

  if(decimal0to255 >= 16) {
    return(decimal0to255.toString(16));
  }
  return("0" + decimal0to255.toString(16));
}

export function unique_color(i_index0toN) {
  if(i_index0toN >= 36) {
    return("999999");
  }

  const uniqueColorArray = [
    "005da3","bd2326","c59f30","00a35d","6c4679","cb7927",
    "3d939c","8a1c26","edf060","74d369","b483ac","f9ae6a",
    "abf3f8","fdaaaa","fffda8","9ff2ac","e9b1ed","f3d3b6",
    "2d2381","622626","fff200","657d33","6b0ba6","ff6a06",
    "7092be","ff0080","a2a251","13ff0d","ca3eff","ff8040",
    "42effd","ff4040","c7f50a","00ff80","534057","ee20ff"
  ];
  return(uniqueColorArray[i_index0toN]);
}











//==================================================================================================================================================
//SVG
//==================================================================================================================================================
export function svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(i_xyCoords0to100ArrayOfArrays, i_svgWidthHeightPx) {
  var polygonPointsString = "";
  for(let p = 0; p < i_xyCoords0to100ArrayOfArrays.length; p++) {
    if(p > 0) { polygonPointsString += " "; }
    polygonPointsString += ((i_xyCoords0to100ArrayOfArrays[p][0] / 100) * i_svgWidthHeightPx) + "," + ((i_xyCoords0to100ArrayOfArrays[p][1] / 100) * i_svgWidthHeightPx);
  }
  return(polygonPointsString);
}









//==================================================================================================================================================
//Math
//==================================================================================================================================================
export function two_point_interpolation(x1, x2, y1, y2, xInterp) {
  if(xInterp <= x1) {
    return(y1);
  }
  else if(xInterp >= x2) {
    return(y2);
  }
  const yInterp = ((xInterp - x1) * ((y2 - y1) / (x2 - x1))) + y1;
  return(yInterp);
}

function bilinear_interpolation(x1, y1, x2, y2, fQ11, fQ12, fQ21, fQ22, x, y) {
  //x1      x position of left 2 reference points
  //y1      y position of bottom 2 reference points
  //x2      x position of right 2 reference points
  //y2      y position of top 2 reference points
  //fQ11    f(Q11) = f(x1,y1)
  //fQ12    f(Q12) = f(x1,y2)
  //fQ21    f(Q21) = f(x2,y1)
  //fQ22    f(Q22) = f(x2,y2)
  //x       desired x position from 0 (left wall) to 1 (right wall)
  //y       desired y position from 0 (bottom wall) to 1 (top wall)
  const q11 = fQ11 * (x2 - x) * (y2 - y);
  const q12 = fQ12 * (x2 - x) * (y - y1);
  const q21 = fQ21 * (x - x1) * (y2 - y);
  const q22 = fQ22 * (x - x1) * (y - y1);
  const denom = (x2 - x1) * (y2 - y1);
  return((q11 + q12 + q21 + q22) / denom);
}



export function compute_box_plot_values_from_numbers_array(i_numbersArray) {
  if(!is_array(i_numbersArray)) {
    return(undefined);
  }

  const numValues = i_numbersArray.length;

  if(numValues === 0) {
    return([0, 0, 0, 0, 0]);
  }
  else if(numValues === 1) {
    const value = i_numbersArray[0];
    return([value, value, value, value, value]);
  }

  const sortedArray = [...i_numbersArray].sort((a, b) => a - b); //copy array and sort

  if(numValues === 2) {
    const value1 = sortedArray[0];
    const value2 = sortedArray[1];
    const mean = ((value1 + value2) / 2);
    return([value1, value1, mean, value2, value2]);
  }

  const min = sortedArray[0];
  const max = sortedArray[numValues - 1];
  const median = median_value_of_sorted_array(sortedArray);

  const numValuesIsEvenTF = ((numValues % 2) === 0);
  var mid1 = 0;
  var mid2 = 0;
  if(numValuesIsEvenTF) {
    const numValuesDividedBy2 = (numValues / 2);
    mid1 = (numValuesDividedBy2 - 1);
    mid2 = numValuesDividedBy2;
  }
  else {
    const oddNumValuesMiddleIndex = (((numValues + 1) / 2) - 1);
    mid1 = oddNumValuesMiddleIndex;
    mid2 = oddNumValuesMiddleIndex;
  }
  const q1 = median_value_of_sorted_array(sortedArray.slice(0, mid1));
  const q3 = median_value_of_sorted_array(sortedArray.slice(mid2));

  return([min, q1, median, q3, max]);
}


function median_value_of_sorted_array(i_sortedNumbersArray) {
  const numValues = i_sortedNumbersArray.length;
  if(numValues === 0) {
    return(0);
  }
  else if(numValues === 1) {
    return(i_sortedNumbersArray[0]);
  }
  else if(numValues === 2) {
    return((i_sortedNumbersArray[0] + i_sortedNumbersArray[1]) / 2);
  }
  else if(numValues === 3) {
    return(i_sortedNumbersArray[1]);
  }

  //even num values - average of middle 2 values
  if((numValues % 2) === 0) {
    const numValuesDividedBy2 = (numValues / 2);
    return((i_sortedNumbersArray[numValuesDividedBy2 - 1] + i_sortedNumbersArray[numValuesDividedBy2]) / 2);
  }

  //odd num values - middle value
  const oddNumValuesMiddleIndex = (((numValues + 1) / 2) - 1);
  return(i_sortedNumbersArray[oddNumValuesMiddleIndex]);
}





//==================================================================================================================================================
//Graphs
//==================================================================================================================================================
export function create_axis_obj_from_max_value_and_height_px(i_dataMinValue, i_dataMaxValue, i_heightPx, i_reversePos100to0TF, i_valueFormat, i_isLogarithmicTF, i_axisMaxValueMultiplier, i_dataMinValueAbove0=0) {
  var dataMinValue = 0;
  if(i_dataMinValue < 0) {
    dataMinValue = i_dataMinValue;
  }

  var axisMaxValue = i_dataMaxValue;
  if(is_number(i_axisMaxValueMultiplier)) {
    axisMaxValue = (i_dataMaxValue * i_axisMaxValueMultiplier);
  }

  if(axisMaxValue < 0) {
    axisMaxValue = 0;
  }

  if(axisMaxValue <= dataMinValue) {
    axisMaxValue = (dataMinValue + 1);
  }

  var formatIsNumberTF = false;
  var formatIsPercentTF = false;
  var formatIsMoneyShortTF = false;
  if(i_valueFormat === "number") { formatIsNumberTF = true; }
  else if(i_valueFormat === "percent") { formatIsPercentTF = true; }
  else if(i_valueFormat === "moneyShort") { formatIsMoneyShortTF = true; }

  var axisTicksArrayOfObjs = [];
  if(i_isLogarithmicTF) {
    axisTicksArrayOfObjs = logarithmic_axis_ticks_arrayOfObjs_from_max_value_and_height_px(axisMaxValue, i_dataMinValueAbove0, i_reversePos100to0TF, formatIsNumberTF, formatIsPercentTF, formatIsMoneyShortTF);
  }
  else {
    axisTicksArrayOfObjs = linear_axis_ticks_arrayOfObjs_from_max_value_and_height_px(dataMinValue, axisMaxValue, i_heightPx, i_reversePos100to0TF, formatIsNumberTF, formatIsPercentTF, formatIsMoneyShortTF);
  }
  const numTicks = axisTicksArrayOfObjs.length;
  const atLeast1TickTF = (numTicks > 0);

  var axisMinValue = 0;
  if(atLeast1TickTF) {
    axisMinValue = axisTicksArrayOfObjs[0].value;
  }

  if(i_isLogarithmicTF && atLeast1TickTF) {
    axisMaxValue = axisTicksArrayOfObjs[numTicks - 1].value;
  }

  return({
    axisTicksArrayOfObjs: axisTicksArrayOfObjs, //"value", "valueLabel", "svgPos0to100"
    numTicks: numTicks,
    axisMinValue: axisMinValue,
    axisMaxValue: axisMaxValue,
    axisMinPos0to100: ((i_reversePos100to0TF) ? (100) : (0)),
    axisMaxPos0to100: ((i_reversePos100to0TF) ? (0) : (100)),
    isLogarithmicTF: i_isLogarithmicTF,
    reversePos100to0TF: i_reversePos100to0TF,
    formatIsNumberTF: formatIsNumberTF,
    formatIsPercentTF: formatIsPercentTF,
    formatIsMoneyShortTF: formatIsMoneyShortTF
  });
}


export function compute_svg_pos_0to100_from_value_and_axis_obj(i_value, i_axisObj) {
  const axisTicksArrayOfObjs = i_axisObj.axisTicksArrayOfObjs;
  const numTicks = i_axisObj.numTicks;
  const axisMinValue = i_axisObj.axisMinValue;
  const axisMaxValue = i_axisObj.axisMaxValue;
  const axisMinPos0to100 = i_axisObj.axisMinPos0to100;
  const axisMaxPos0to100 = i_axisObj.axisMaxPos0to100;
  const isLogarithmicTF = i_axisObj.isLogarithmicTF;
  const reversePos100to0TF = i_axisObj.reversePos100to0TF;

  var svgPos0to100 = 0;
  if(isLogarithmicTF) { //log scale
    if((numTicks > 0) && (i_value > 0)) { //force y value to be >= 0 (no negative numbers can be graphed in a log scale)
      var foundValueBetweenTicksTF = false;
      var t = 0;
      while(!foundValueBetweenTicksTF && (t < (numTicks - 1))) {
        var lowerTickObj = axisTicksArrayOfObjs[t];
        var upperTickObj = axisTicksArrayOfObjs[t + 1];
        if((i_value > lowerTickObj.value) && (i_value <= upperTickObj.value)) {
          foundValueBetweenTicksTF = true;
          if(t === 0) { //log scale is actually linear between the first and second ticks (0 to T1)
            svgPos0to100 = two_point_interpolation(lowerTickObj.value, upperTickObj.value, lowerTickObj.svgPos0to100, upperTickObj.svgPos0to100, i_value); //linear interpolation equation
          }
          else { //logarithmic interpolation between other pairs of tick marks
            svgPos0to100 = (Math.log10(i_value / lowerTickObj.value) * (upperTickObj.svgPos0to100 - lowerTickObj.svgPos0to100)) + lowerTickObj.svgPos0to100; //logarithmic interpolation into a linear scale
          }
        }
        t++;
      }

      //if the value is greater than the last tick mark value,
      if(!foundValueBetweenTicksTF) {
        svgPos0to100 = ((Math.log10(i_value / lowerTickObj.value) / Math.log10(axisMaxPos0to100 / lowerTickObj.value)) * (upperTickObj.svgPos0to100 - lowerTickObj.svgPos0to100)) + lowerTickObj.svgPos0to100;
      }
    }
    else { //values < 0 are given the position at the bottom of the axis
      svgPos0to100 = axisMinPos0to100;
    }
  }
  else { //linear scale
    if((axisMaxValue - axisMinValue) !== 0) { //avoid divide by 0 if all data points have a y value of 0
      svgPos0to100 = ((i_value - axisMinValue) * ((100) / (axisMaxValue - axisMinValue))); //linear interpolation assuming the axis is from 0 to maxValue and the svg to map into is 0 to 100
    }

    //reverse the 0 to 100 position for the y svg position 100 to 0 bottom to top
    if(reversePos100to0TF) {
      svgPos0to100 = (100 - svgPos0to100);
    }
  }

  return(svgPos0to100);
}


function linear_axis_ticks_arrayOfObjs_from_max_value_and_height_px(i_dataMinValue, i_axisMaxValue, i_heightPx, i_reversePos100to0TF, i_formatIsNumberTF, i_formatIsPercentTF, i_formatIsMoneyShortTF) {
  //i_reversePos100to0TF:
  //  - false (x axis ticks) position values for svg scale (left to right) from 0 to 100
  //  - true (y axis ticks) position values for svg scale in reverse (top to bottom) from 100 to 0
  //
  //examples
  //heightPx:           360
  //tickMinSpacingPx:   (15 + (360 / 50)) = 22.2
  //maxNumTicks:        Math.floor(360 / 22.2) = 16
  //axisMaxValue:                             1         2         3         4         5         9         60        88        130       330       727       2030
  //smallestTickRange:                        0.0625    0.125     0.1875    0.25      0.3125    0.5625    3.75      5.5       8.125     20.625    45.4375   126.875
  //log10SmallestTickRange:                   -1.204    -0.903    -0.726    -0.602    -0.505    -0.249    0.574     0.740     0.909     1.314     1.657     2.103
  //floorLog10SmallestTickRange:              -2        -1        -1        -1        -1        -1        0         0         0         1         1         2
  //power10Multiplier:                        0.01      0.1       0.1       0.1       0.1       0.1       1         1         1         10        10        100
  //floorLog10SmallestTickRangeScaled1to10:   6.25      1.25      1.875     2.5       3.125     5.625     3.75      5.5       8.125     2.062     4.543     1.268
  //spacingMultiplier2or5or10:                10        2         2         5         5         10        5         10        10        5         5         2
  //tickSpacingValue:                         0.1       0.2       0.5       0.5       0.5       1         5         10        10        50        50        200
  //maxNumDecimals:                           2         1         1         1         1         1         0         0         0         0         0         0

  //harcoded adjustment settings
  const tickMinSpacingPx = (15 + (i_heightPx / 50));

  //input handling
  const dataMinValue = ((is_number(i_dataMinValue)) ? (i_dataMinValue) : (0));
  const axisMaxValue = ((is_number(i_axisMaxValue) && i_axisMaxValue > 0) ? (i_axisMaxValue) : (1)); //invalid input sets the max value to 1

  const heightPx = ((is_number(i_heightPx) && i_heightPx > 0) ? (i_heightPx) : (100));

  //compute the regular ticks spacing locked to a multiple of 1, 2, or 5
  const maxNumTicks = Math.floor(heightPx / tickMinSpacingPx); //there can be less ticks than this number, but not more

  const smallestTickRange = ((axisMaxValue - dataMinValue) / maxNumTicks);
  const log10SmallestTickRange = Math.log10(smallestTickRange);
  const floorLog10SmallestTickRange = Math.floor(log10SmallestTickRange);
  const power10Multiplier = (10**floorLog10SmallestTickRange);
  const floorLog10SmallestTickRangeScaled1to10 = (smallestTickRange / power10Multiplier);
  var spacingMultiplier2or5or10 = 2;
  if(floorLog10SmallestTickRangeScaled1to10 > 5) {
    spacingMultiplier2or5or10 = 10;
  }
  else if(floorLog10SmallestTickRangeScaled1to10 > 2) {
    spacingMultiplier2or5or10 = 5;
  }
  const tickSpacingValue = (spacingMultiplier2or5or10 * power10Multiplier);
  const maxNumDecimals = ((floorLog10SmallestTickRange >= 0) ? (0) : (floorLog10SmallestTickRange * -1));

  //compute the minimum tick value
  var axisMinValue = 0; //start at 0 and count into the negatives until the min tick is reached (if the minimum is negative)
  if(dataMinValue < 0) {
    while(axisMinValue > dataMinValue) {
      axisMinValue -= tickSpacingValue;
    }
  }

  //compute the ticks arrayOfObjs
  var ticksArrayOfObjs = [];
  var tickValue = axisMinValue;
  while(tickValue < axisMaxValue) {
    //mask the tick label
    var valueLabel = mask_tick_value_to_tick_label(tickValue, maxNumDecimals, i_formatIsNumberTF, i_formatIsPercentTF, i_formatIsMoneyShortTF);

    //scale the tickValue within the svg position 0 to 100 scaled based from axisMinValue to axisMaxValue
    var svgPos0to100 = ((tickValue - axisMinValue) * ((100) / (axisMaxValue - axisMinValue)));

    //reverse the svg position to count from 100 down to 0 if specified (for y axis ticks in svg)
    if(i_reversePos100to0TF) {
      svgPos0to100 = (100 - svgPos0to100);
    }

    ticksArrayOfObjs.push({
      value: tickValue,
      valueLabel: valueLabel,
      svgPos0to100: svgPos0to100
    });

    tickValue += tickSpacingValue;
  }

  return(ticksArrayOfObjs);
}

function logarithmic_axis_ticks_arrayOfObjs_from_max_value_and_height_px(i_axisMaxValue, i_dataMinValueAbove0, i_reversePos100to0TF, i_formatIsNumberTF, i_formatIsPercentTF, i_formatIsMoneyShortTF) {
  const maxNumDecimals = 0;

  //input handling
  var axisMaxValue = i_axisMaxValue;
  if(!is_number(i_axisMaxValue) || (i_axisMaxValue < 1)) {
    axisMaxValue = 1; //invalid input sets the max value to 1
  }

  var dataMinValueAbove0 = i_dataMinValueAbove0;
  if(!is_number(i_dataMinValueAbove0) || (i_dataMinValueAbove0 < 0)) {
    dataMinValueAbove0 = 0; //invalid input sets the max value to 1
  }

  //compute necessary ticks between the min/max dataset values
  var tickValuesArray = [0]; //initialize with tick at true 0
  var exponent = 0; //first tick value to try is 1 (10^0)
  var metOrExceededMaxValueTF = false;
  while(!metOrExceededMaxValueTF) {
    var tenToExponentValue = (10**exponent);
    if(tenToExponentValue >= dataMinValueAbove0) {
      tickValuesArray.push(tenToExponentValue);
      if(tenToExponentValue >= axisMaxValue) {
        metOrExceededMaxValueTF = true;
      }
    }
    exponent++;
  }
  const numTicks = tickValuesArray.length;

  //compute the ticks arrayOfObjs
  var ticksArrayOfObjs = [];
  for(let t = 0; t < numTicks; t++) {
    var tickValue = tickValuesArray[t];

    //mask the tick label
    var valueLabel = mask_tick_value_to_tick_label(tickValue, maxNumDecimals, i_formatIsNumberTF, i_formatIsPercentTF, i_formatIsMoneyShortTF);

    //scale the tickValue within the svg position 0 to 100 scaled based from 0 to axisMaxValue
    var svgPos0to100 = ((t / (numTicks - 1)) * 100);

    //reverse the svg position to count from 100 down to 0 if specified (for y axis ticks in svg)
    if(i_reversePos100to0TF) {
      svgPos0to100 = (100 - svgPos0to100);
    }

    ticksArrayOfObjs.push({
      value: tickValue,
      valueLabel: valueLabel,
      svgPos0to100: svgPos0to100
    });
  }

  return(ticksArrayOfObjs);
}


function mask_tick_value_to_tick_label(i_tickValue, i_maxNumDecimals, i_formatIsNumberTF, i_formatIsPercentTF, i_formatIsMoneyShortTF) {
  if(i_formatIsNumberTF) {
    return(num2str(i_tickValue.toFixed(i_maxNumDecimals)));
  }
  else if(i_formatIsPercentTF) {
    return(percent_fixed(i_tickValue, i_maxNumDecimals));
  }
  else if(i_formatIsMoneyShortTF) {
    return(money_short(i_tickValue));
  }
  return(undefined);
}



export function bins_obj_from_start_end_date_and_time_bins(i_startDate, i_endDate, i_timeBins) {
  //i_timeBins
  //  - "weekly"          sun to sat
  //  - "monthly"         1st to last day of each month
  //  - "quarterly"       1st of 1st month to last day of 3rd month
  //  - "yearly"          jan 1st to dec 31st
  //
  //binsArrayOfObjs
  //  - leftDate          leftmost date for this bin
  //  - rightDate         rightmost date for this bin, equals leftDate of the next bin
  //  - leftNumDays       number of days (0 to N-1) from the start of the left edge
  //  - rightNumDays      number of days (0 to N-1) from the start of the right edge (left edge of next bin)
  //  - leftPos           x position 0 to 100 of left edge of bin on an svg chart
  //  - centerPos         x position 0 to 100 of center of bin on an svg chart
  //  - rightPos          x position 0 to 100 of right edge of bin on an svg chart (equals left edge of next bin)
  //  - valueTotal        initialized to 0 for the bar graph to use
  //  - valueTotalMask    initialized to 0 for the bar graph to use
  //
  //monthBinsArrayOfObjs
  //  - leftDate
  //  - rightDate
  //  - leftNumDays
  //  - rightNumDays
  //  - leftPos
  //  - centerPos
  //  - rightPos
  //  - valueTotal
  //  - monthIndex0to11   month number (useful to determine which month is january to put a year with it)
  //  - mthLabel          Jan, Feb, Mar
  //  - mLabel            J, F, M
  //  - year              2019
  //  - yr                '19

  var binsObj = {
    startDate: i_startDate,
    endDate: i_endDate,
    monthBinsArrayOfObjs: [],
    todayPos: undefined,
    binsArrayOfObjs: [],
    numBins: 0,
    totalNumDays: 0
  }

  if(!date_is_filled_out_tf(i_startDate) || !date_is_filled_out_tf(i_endDate) || i_startDate >= i_endDate) {
    return(binsObj);
  }

  const binCountLimit = 1000;

  const startJsDateObj = convert_mysqldate_to_jsdateobj(i_startDate);
  const startYear = jsdateobj_full_year(startJsDateObj);
  const startMonthIndex0to11 = jsdateobj_month(startJsDateObj);
  const startDate1to31 = jsdateobj_date_of_month(startJsDateObj);

  const endJsDateObj = convert_mysqldate_to_jsdateobj(i_endDate);
  const endYear = jsdateobj_full_year(endJsDateObj);
  const endMonthIndex0to11 = jsdateobj_month(endJsDateObj);
  const endDate1to31 = jsdateobj_date_of_month(endJsDateObj);

  //1st bin left edge is at the start date (0 x pos)
  var leftDate = undefined;
  var rightDate = undefined;
  var leftNumDays = undefined;
  var rightNumDays = undefined;

  //while loop controls
  var addedLastBinTF = false;
  var b = 0;

  //----------------------------------------------------------------
  //compute the month bins
  var monthBinsArrayOfObjs = [];
  var year = startYear;
  var monthIndex0to11 = startMonthIndex0to11;
  var leftJsDateObj = convert_mysqldate_to_jsdateobj(i_startDate);
  leftDate = i_startDate;
  leftNumDays = 0;
  while(!addedLastBinTF && b < binCountLimit) {
    var currentMonthIndex0to11 = monthIndex0to11;
    var currentYear = year;

    monthIndex0to11++;
    if(monthIndex0to11 === 12) {
      monthIndex0to11 = 0;
      year++;
    }

    var rightJsDateObj = new Date(year, monthIndex0to11, 1);
    rightDate = get_Ymd_date_from_jsdateobj_and_utctf(rightJsDateObj, false);
    if(rightDate >= i_endDate) {
      rightJsDateObj = endJsDateObj;
      rightDate = i_endDate;
      addedLastBinTF = true;
    }
    rightNumDays = (leftNumDays + num_days_from_jsDateObj1_to_jsDateObj2(leftJsDateObj, rightJsDateObj));

    monthBinsArrayOfObjs.push({
      leftDate: leftDate,
      rightDate: rightDate,
      leftNumDays: leftNumDays,
      rightNumDays: rightNumDays,
      monthIndex0to11: currentMonthIndex0to11,
      mthLabel: date_mth_from_index(currentMonthIndex0to11),
      mLabel: date_month_letter_from_index(currentMonthIndex0to11),
      year: currentYear,
      yr: ("'" + num2str(currentYear).substring(2))
    });

    //set leftDate for the next loop as the previous bin's rightDate
    leftJsDateObj = rightJsDateObj;
    leftDate = rightDate;
    leftNumDays = rightNumDays;

    //increment the loop index
    b++;
  }

  //compute the left/center/right pos for each bin
  if(rightNumDays > 0) { //last bin's computed rightNumDays is the total number of days in the window
    for(let binObj of monthBinsArrayOfObjs) {
      binObj.leftPos = ((binObj.leftNumDays / rightNumDays) * 100);
      binObj.rightPos = ((binObj.rightNumDays / rightNumDays) * 100);
      binObj.centerPos = ((binObj.leftPos + binObj.rightPos) / 2);
      binObj.valueTotal = 0; //initialized for the bar graph to use for totals
      binObj.valueTotalMask = 0; //initialized for the bar graph to use for totals
    }
    binsObj.monthBinsArrayOfObjs = monthBinsArrayOfObjs;
  }
  binsObj.totalNumDays = rightNumDays;


  //----------------------------------------------------------------
  //compute today pos if today falls between the start and end date
  const todayDate = now_date();
  if(todayDate >= i_startDate && todayDate <= i_endDate) {
    const todayJsDateObj = new Date();
    const numDaysStartToToday = num_days_from_jsDateObj1_to_jsDateObj2(startJsDateObj, todayJsDateObj);
    binsObj.todayPos = ((numDaysStartToToday / binsObj.totalNumDays) * 100);
  }


  //----------------------------------------------------------------
  //compute the time bins for different timeBin algorithms
  if(i_timeBins === "monthly") {
    binsObj.binsArrayOfObjs = binsObj.monthBinsArrayOfObjs;
  }
  else {
    var binsArrayOfObjs = [];
    addedLastBinTF = false;
    b = 0;
    if(i_timeBins === "weekly") {
      var binLeftJsDateObj = convert_mysqldate_to_jsdateobj(i_startDate);
      while(!addedLastBinTF && b < binCountLimit) {
        //determine the end of this bin, usually +7 days, but for the first bin, it's only the # days until a sunday is reached
        if(b === 0) { //first bin, count until sunday is reached
          var startDayOfWeek0to6 = jsdateobj_day_of_week(binLeftJsDateObj); //sun - 0, sat - 6
          var firstBinWidthNumDays = (7 - startDayOfWeek0to6);

          binLeftJsDateObj.setDate(binLeftJsDateObj.getDate() + firstBinWidthNumDays);

          leftDate = i_startDate; //1st bin left edge is at the start date (0 x pos)
          rightDate = get_Ymd_date_from_jsdateobj_and_utctf(binLeftJsDateObj, false);
          leftNumDays = 0;
          rightNumDays = firstBinWidthNumDays;
        }
        else { //all other bins, leftDate and leftNumDays are already set from the previous loop
          binLeftJsDateObj.setDate(binLeftJsDateObj.getDate() + 7);
          rightDate = get_Ymd_date_from_jsdateobj_and_utctf(binLeftJsDateObj, false);
          rightNumDays = leftNumDays + 7;
        }

        //determine if this bin is the last bin based on whether the computed right date is past the endDate
        if(rightDate >= i_endDate) {
          rightDate = i_endDate;
          rightNumDays = (leftNumDays + num_days_from_date1_to_date2(leftDate, i_endDate));
          addedLastBinTF = true; //stop the while loop
        }

        binsArrayOfObjs.push({
          leftDate: leftDate,
          rightDate: rightDate,
          leftNumDays: leftNumDays,
          rightNumDays: rightNumDays
        });

        //set leftDate for the next loop as the previous bin's rightDate
        leftDate = rightDate;
        leftNumDays = rightNumDays;

        //increment the loop index
        b++;
      }
    }
    else if(i_timeBins === "yearly") {
      var year = startYear;
      var leftJsDateObj = convert_mysqldate_to_jsdateobj(i_startDate);
      leftDate = i_startDate;
      leftNumDays = 0;
      while(!addedLastBinTF && b < binCountLimit) {
        year++;

        var rightJsDateObj = new Date(year, 0, 1); //months use index 0-11, so Jan is 0
        rightDate = get_Ymd_date_from_jsdateobj_and_utctf(rightJsDateObj, false);
        if(rightDate >= i_endDate) {
          rightJsDateObj = endJsDateObj;
          rightDate = i_endDate;
          addedLastBinTF = true;
        }
        rightNumDays = (leftNumDays + num_days_from_jsDateObj1_to_jsDateObj2(leftJsDateObj, rightJsDateObj));

        binsArrayOfObjs.push({
          leftDate: leftDate,
          rightDate: rightDate,
          leftNumDays: leftNumDays,
          rightNumDays: rightNumDays
        });

        //set leftDate for the next loop as the previous bin's rightDate
        leftJsDateObj = rightJsDateObj;
        leftDate = rightDate;
        leftNumDays = rightNumDays;

        //increment the loop index
        b++;
      }
    }

    //compute the left/center/right pos for each bin
    if(rightNumDays > 0) { //last bin's computed rightNumDays is the total number of days in the window
      for(let binObj of binsArrayOfObjs) {
        binObj.leftPos = ((binObj.leftNumDays / rightNumDays) * 100);
        binObj.rightPos = ((binObj.rightNumDays / rightNumDays) * 100);
        binObj.centerPos = ((binObj.leftPos + binObj.rightPos) / 2);
        binObj.valueTotal = 0; //initialized for the bar graph to use for totals
        binObj.valueTotalMask = 0; //initialized for the bar graph to use for totals
      }
      binsObj.binsArrayOfObjs = binsArrayOfObjs;
    }
  }

  //get the number of bins found in this window
  binsObj.numBins = binsObj.binsArrayOfObjs.length;

  return(binsObj);
}





//==================================================================================================================================================
//Colors
//==================================================================================================================================================
export function custom_colors_obj() {
  return({
    wordBlue: "294fbe",
    excelGreen: "247151",
    pptOrange: "e36b1f"
  });
}






//==================================================================================================================================================
//Other
//==================================================================================================================================================
export function get_user_browser_timezone_string() {
  return(Intl.DateTimeFormat().resolvedOptions().timeZone);
}


export function get_user_browser_type_string() {
  var ua = navigator.userAgent;

  if(is_string(ua)) {
    return(ua);
  }

  var tem = undefined;

  var M = (ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []);

  if(/trident/i.test(M[1])) {
    tem = (/\brv[ :]+(\d+)/g.exec(ua) || []);
    return('IE' + (tem[1] || ''));
  }

  if(M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if(tem != null) {
      return('Opera' + tem[1]);
    }
  }

  M = ((M[2]) ? ([M[1], M[2]]) : ([navigator.appName, navigator.appVersion, '-?']));

  tem = ua.match(/version\/(\d+)/i);
  if(tem != null) {
    M.splice(1, 1, tem[1]);
  }

  return(M[0] + " " + M[1]); //"name version"
}

/*
function text_width(text, fontSizePx) {
  var canvas = text_width.canvas || (text_width.canvas = document.createElement("canvas")); //if given, use cached canvas for better performance, else, create new canvas
  var context = canvas.getContext("2d");
  context.font = "sans-serif " + fontSizePx + "px";
  var metrics = context.measureText(text);
  return metrics.width;
}
*/

Element.prototype.scrollIntoViewIfNeeded = function(depthOfParentsToCheck = -1) {
  var parentCounter = 0;
  var parent;
  var elem = this;
  var area = makeArea(this.offsetLeft, this.offsetTop, this.offsetWidth, this.offsetHeight);
  while(((parent = elem.parentNode) instanceof HTMLElement) && (depthOfParentsToCheck === -1 || parentCounter < depthOfParentsToCheck)) {
    var clientLeft = parent.offsetLeft + parent.clientLeft;
    var clientTop = parent.offsetTop + parent.clientTop;

    //make area relative to parent's client area
    area = area.relativeFromTo(elem, parent).translate(-clientLeft, -clientTop);
    parent.scrollLeft = withinBounds(parent.scrollLeft, area.right - parent.clientWidth, area.left, parent.clientWidth);
    parent.scrollTop = withinBounds(parent.scrollTop, area.bottom - parent.clientHeight, area.top, parent.clientHeight);

    //determine actual scroll amount by reading back scroll properties
    area = area.translate(clientLeft - parent.scrollLeft, clientTop - parent.scrollTop);
    elem = parent;

    parentCounter++;
  }

  function withinBounds(value, min, max, extent) {
    const centerIfNeeded = true;
    if(centerIfNeeded === false || (max <= value + extent && value <= min + extent)) {
      return(Math.min(max, Math.max(min, value)));
    }
    else {
      return((min + max) / 2);
    }
  }

  function makeArea(left, top, width, height) {
    return({
      "left": left,
      "top": top,
      "width": width,
      "height": height,
      "right": left + width,
      "bottom": top + height,
      "translate": ((x,y) => makeArea(x + left, y + top, width, height)),
      "relativeFromTo":
      function(lhs, rhs) {
        lhs = lhs.offsetParent;
        rhs = rhs.offsetParent;
        if(lhs === rhs) { return(area); }

        var newLeft = left;
        var newTop = top;
        for(; lhs; lhs = lhs.offsetParent) {
          newLeft += lhs.offsetLeft + lhs.clientLeft;
          newTop += lhs.offsetTop + lhs.clientTop;
        }
        for(; rhs; rhs = rhs.offsetParent) {
          newLeft -= rhs.offsetLeft + rhs.clientLeft;
          newTop -= rhs.offsetTop + rhs.clientTop;
        }
        return(makeArea(newLeft, newTop, width, height));
      }
    });
  }
}

/*
export function filtered_data_matrix_from_given_ids(i_dataMatrix, i_idFieldName, i_idArrayOrCommaList) {
  //i_dataMatrix - [{id:1,name:"One"},{id:2,name:"Two"},{id:3,name:"Three"}]
  //i_idFieldName - "id"
  //i_idArrayOrCommaList - "3,1" or [3,1]
  //filteredDataMatrix - [{id:3,name:"Three"},{id:1,name:"One"}]

  //convert input id commaList string to an array if a comma list is provided
  var idArray = [];
  if(!is_array(i_idArrayOrCommaList)) { //commaList input string "3,1"
    idArray = convert_comma_list_to_int_array(i_idArrayOrCommaList);
  }
  else { //array input [3,1]
    idArray = i_idArrayOrCommaList;
  }

  const dataMatrixIDsArray = i_dataMatrix.map((dataObj) => dataObj[i_idFieldName]); //get list of data matrix ids in the order they appear using the provided id field name

  var filteredDataMatrix = [];
  var indexOfMatchingID = -1; //initialize for use in loop
  for(let i = 0; i < idArray.length; i++) {
    indexOfMatchingID = dataMatrixIDsArray.indexOf(idArray[i]); //find the data matrix index that matches this desired id number
    if(indexOfMatchingID >= 0) { //if the matching id was found
      filteredDataMatrix.push(i_dataMatrix[indexOfMatchingID]); //add the entire dataMatrix object at that index position with the matching id
    }
  }

  return(filteredDataMatrix);
}


export function get_tbl_row_index_matching_id(i_tblMatrix, i_idFieldName, i_idToFind) {
  for(let i = 0; i < i_tblMatrix.length; i++) {
    if(i_tblMatrix[i][i_idFieldName] === i_idToFind) {
      return(i);
    }
  }
  return(-1); //-1 flag that the id does not exist
}
*/

import { makeObservable, observable, computed, action } from 'mobx';
import React, { Component } from 'react';

import * as JSFUNC from "../Library/JSFUNC.js";

import DatabaseMobx from './DatabaseMobx.js';
import * as JSPHP from "./JSPHP.js";

import CaptureExecMobx from '../CaptureExecReactMobxPairs/CaptureExec/CaptureExecMobx.js';
import RightPanelMobx from '../CaptureExecReactMobxPairs/RightPanel/RightPanelMobx.js';

import * as CEGeneralReact from "../CaptureExecGeneral/CEGeneralReact.js"; //precompute SVGs at all sizes/colors used in CaptureExec


export class UserMobx {
  //**observable values************************************************************************************************************
  o_loginWithKeyTF = false;
  o_userPerEmailID = -1; //set by login.php in CaptureExecMobx when companyCode/email/password is verified
  o_userID = -1; //set by multiLogin selection or by login.php in CaptureExecMobx for a single user
  o_userCompanyCode = "";

  constructor() {
    makeObservable(this, {
      o_loginWithKeyTF: observable,
      o_userPerEmailID: observable,
      o_userID: observable,
      o_userCompanyCode: observable,

      c_userCompanyCodeIsBITTestDemoTF: computed,
      c_userIDAsBase52String: computed,
      c_sortedMultiLoginCombinedUsersArrayOfObjs: computed,
      c_multiLoginUserIDsArray: computed,
      c_hasMultipleLoginsTF: computed,
      c_combinedUserObj: computed,
      c_anyOfUsersMultiLoginsHaveAdminPowerTF: computed,
      c_userIsReadOnlyTF: computed,
      c_userCanEditDivexecPerformanceGoalsTF: computed,
      c_userCanCreateNewCapturesTF: computed,
      c_userCanSwitchToCSTCellEditModeTF: computed,
      c_userCanMarkImportOrMergeCapturesFromGCSSTF: computed,
      c_userCanEditCaptureCardContentTF: computed,
      c_userCanAdvanceCaptureStageTF: computed,
      c_userCannotSearchCapturesTF: computed,
      c_userDocsSearchLimitedToLoadedCapturesTF: computed,
      c_userCanMassEditAndMassDeleteCaptureTableTF: computed,
      c_userCanCreateContactsTF: computed,
      c_userCanEditContactsTF: computed,
      c_userCanDeleteContactsTF: computed,
      c_userCanCreatePublicItemsTF: computed,
      c_userCanUploadFilesTF: computed,
      c_userCanEditInfoOrMoveFilesTF: computed,
      c_userCanDeleteFilesTF: computed,
      c_userCanManageTeammateContractsProcessTF: computed,
      c_userPerEmailHasNotDeactivatedAdminOrSuperAdminLoginTF: computed,
      c_userName: computed,
      c_userEmail: computed,
      c_userLeftNavIsExpandedTF: computed,
      c_userFontSizePx: computed,
      c_userTeammatesRatingsCELogoSizePx: computed,
      c_userRightPanelWidthEm: computed,
      c_userHelpPanelWidthEm: computed,
      c_userCaptureCardMinHeightEm: computed,
      c_userCaptureCardMaxHeightEm: computed,
      c_recentlyVisitedCaptureIDsArray: computed,
      c_userPerEmailFavoritedCaptureIDsArray: computed,
      c_userCSTEditCellsModeTF: computed,
      c_userCaptureTableRowHeightOptionsObj: computed,
      c_userStageViewBoxSize: computed,
      c_userStageViewSelectedCaptureTypeIDsArray: computed,
      c_userStageViewMinimizedStageIDsArray: computed,
      c_userDivisionFirewalledDivisionIDsCommaOrAllm1: computed,
      c_userDivisionFirewalledDivisionIDsArray: computed,
      c_userFirewalledCaptureIDsCommaOrAllm1: computed,
      c_userArchivedCapturesAlwaysLoadArchivedTF: computed,
      c_userArchivedCapturesNoLoadArchivedWithPromptTF: computed,
      c_userDatesCardIsFixedAdminSingleColumnTF: computed,
      c_userNotepadSelectedTabDbName: computed,
      c_userNotepadNoteStampsViewFlag: computed,
      c_userGcssSam0GovWin1: computed,
      c_userGcssDataSource0SamTF: computed,
      c_userGcssDataSource1GovWinTF: computed,
      c_userGcssDataSource2FedCompTF: computed,
      c_userGcssDataSourceName: computed,
      c_userGcssSearchResultsCompact0Large1: computed,
      c_userGcssSearchResultsSortGcssFieldDbName: computed,
      c_userGcssSearchResultsSortIsAscTF: computed,
      c_userGcssSamGcssIDsMarkedForImportArray: computed,
      c_userGcssGovWinIDsMarkedForImportArray: computed,
      c_userGcssFedCompIDsMarkedForImportArray: computed,
      c_svgFavoriteIconStdSizeFav: computed,
      c_svgFavoriteIconStdSizeNotFav: computed,
      c_svgFavoriteIconCSTSizeFav: computed,
      c_svgFavoriteIconCSTSizeNotFav: computed,
      c_svgEditPencil11Gray: computed,
      c_svgEditPencil11White: computed,
      c_svgCompany24CreateNew: computed,
      c_svgPerson24CreateNew: computed,
      c_svgCompany20: computed,
      c_svgCompany15: computed,
      c_svgCompany10: computed,
      c_svgPerson15: computed,
      c_svgPerson10: computed,

      a_set_login_with_key_tf: action,
      a_set_user_id: action,
      a_set_user_per_email_id: action,
      a_set_user_company_code: action,
      a_update_user_field: action,
      a_update_user_per_email_field: action
    });
  }



  //**computed values************************************************************************************************************
  get c_userCompanyCodeIsBITTestDemoTF() {
    return(JSFUNC.in_array(this.o_userCompanyCode, ["BITBREAK", "BITDEMO", "BITDEMO_AM", "BITDEMO_MN", "BITDEMO_NH", "BITDEMO_SB", "BITNEWCOMPANY", "BITTEST", "NEWCO_NH"]));
  }

  get c_userIDAsBase52String() {
    return(JSFUNC.convert_int_to_base_52_string(this.o_userID));
  }

  get c_sortedMultiLoginCombinedUsersArrayOfObjs() { //creates an array of DatabaseMobx.c_tbl_a_users that match the logged in user's userPerEmailID
    const c_allUserPowersDbNamesArray = DatabaseMobx.c_allUserPowersDbNamesArray;
    const o_userPerEmailID = this.o_userPerEmailID;

    const userPerEmailUsersMatrix = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.c_tbl_a_users, "user_per_email_id", o_userPerEmailID, "divisionTreeID", true);
    
    var combinedUsersArrayOfObjs = [];
    for(let userPowerDbName of c_allUserPowersDbNamesArray) {
      for(let userObj of userPerEmailUsersMatrix) {
        if(userObj.power_name === userPowerDbName) {
          combinedUsersArrayOfObjs.push(userObj);
        }
      }
    }

    return(combinedUsersArrayOfObjs);
  }
  get c_multiLoginUserIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_sortedMultiLoginCombinedUsersArrayOfObjs, "user_id"));
  }
  get c_hasMultipleLoginsTF() { //the user has 2 or more tbl_a_users records that have the same userPerEmailID
    return(this.c_sortedMultiLoginCombinedUsersArrayOfObjs.length > 1);
  }

  get c_combinedUserObj() { //loads a single combined c_combinedUserObj record for logged in user
    var combinedUserMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.c_tbl_a_users, "user_id", this.o_userID);
    if(combinedUserMap !== undefined) {
      return(JSFUNC.obj_from_map(combinedUserMap));
    }

    //if there are no users matching the userID, return a nonexistant combinedUserObj record created from nonexistant_user using the userID stored here in UserMobx
    const nonexistantCombinedUserMap = DatabaseMobx.create_combined_user_map(-1);
    return(JSFUNC.obj_from_map(nonexistantCombinedUserMap));
  }

  get c_anyOfUsersMultiLoginsHaveAdminPowerTF() {
    for(let userObj of this.c_sortedMultiLoginCombinedUsersArrayOfObjs) {
      if(userObj.powerHasAdminPowerTF) {
        return(true);
      }
    }
    return(false);
  }

  //user system limitations
  get c_userIsReadOnlyTF() { return(this.c_combinedUserObj.powerIsReadOnlyTF); }
  get c_userCanEditDivexecPerformanceGoalsTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanCreateNewCapturesTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanSwitchToCSTCellEditModeTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanMarkImportOrMergeCapturesFromGCSSTF() { return(!this.c_userIsReadOnlyTF); } //removes blue 'Import' button, three-way switch per result, and inside a result marking buttons and green 'Merge' button
  get c_userCanEditCaptureCardContentTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanAdvanceCaptureStageTF() {
    const o_userPerEmailID = this.o_userPerEmailID;
    const c_userCanEditCaptureCardContentTF = this.c_userCanEditCaptureCardContentTF;
    const c_companyRestrictAdvanceCaptureStageTF = DatabaseMobx.c_companyRestrictAdvanceCaptureStageTF;
    const c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma = DatabaseMobx.c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma;

    //read-only users prevented from editing anything on a capture cannot advance stage
    if(!c_userCanEditCaptureCardContentTF) {
      return(false);
    }

    //users that are restricted cannot advance stage
    if(c_companyRestrictAdvanceCaptureStageTF) {
      const companyRestrictAdvanceCaptureStageUserPerEmailIDsArray = JSFUNC.convert_comma_list_to_int_array(c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma);
      return(JSFUNC.in_array(o_userPerEmailID, companyRestrictAdvanceCaptureStageUserPerEmailIDsArray));
    }

    return(true); //otherwise any user is free to advance any stage on any capture
  }
  get c_userCannotSearchCapturesTF() { return(false); }
  get c_userDocsSearchLimitedToLoadedCapturesTF() { return(this.c_combinedUserObj.powerIsCaptureConsultantTF); }
  get c_userCanMassEditAndMassDeleteCaptureTableTF() { return(this.c_combinedUserObj.powerHasAdminPowerTF && (!this.c_userIsReadOnlyTF)); }
  get c_userCanCreateContactsTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanEditContactsTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanDeleteContactsTF() { return(!this.c_userIsReadOnlyTF && !this.c_combinedUserObj.powerIsCaptureConsultantTF); }
  get c_userCanCreatePublicItemsTF() { return((this.c_combinedUserObj.can_create_public_01 !== 0) && (!this.c_userIsReadOnlyTF)); }
  get c_userCanUploadFilesTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanEditInfoOrMoveFilesTF() { return(!this.c_userIsReadOnlyTF); }
  get c_userCanDeleteFilesTF() { return(!this.c_userIsReadOnlyTF && !this.c_combinedUserObj.powerIsCaptureConsultantTF); }
  get c_userCanManageTeammateContractsProcessTF() { return(this.c_combinedUserObj.powerHasContractsPowerTF); }

  get c_userPerEmailHasNotDeactivatedAdminOrSuperAdminLoginTF() { return(JSFUNC.in_array(this.o_userPerEmailID, DatabaseMobx.c_userPerEmailIDsOfAllAdminPowerUsersNotDeactivatedArray)); }

  get c_userName() { return(this.c_combinedUserObj.fullName); }
  get c_userEmail() { return(this.c_combinedUserObj.email); }

  get c_userLeftNavIsExpandedTF() {
    return(this.c_combinedUserObj.left_nav_0collapsed_1expanded === 1);
  }

  get c_userFontSizePx() {
    var userFontSizePx = 12;
    if(this.c_combinedUserObj !== undefined) {
      userFontSizePx = this.c_combinedUserObj.font_size_px;
      if(!JSFUNC.is_number(userFontSizePx) || (userFontSizePx < 8) || (userFontSizePx > 50)) {
        userFontSizePx = 12;
      }
    }
    return(userFontSizePx);
  }

  get c_userTeammatesRatingsCELogoSizePx() {
    const userFontSizePx = this.c_userFontSizePx;

    var userTeammatesRatingsCELogoSizePx = 20;
    if(userFontSizePx <= 10) { //8-10
      userTeammatesRatingsCELogoSizePx = 15;
    }
    else if(userFontSizePx <= 14) { //11-14
      userTeammatesRatingsCELogoSizePx = 20;
    }
    else if(userFontSizePx <= 24) { //15-24
      userTeammatesRatingsCELogoSizePx = 30;
    }
    else { //26+
      userTeammatesRatingsCELogoSizePx = 50;
    }

    return(userTeammatesRatingsCELogoSizePx);
  }

  get c_userRightPanelWidthEm() {
    const userPanelWidthEm = this.c_combinedUserObj.right_panel_width_em;
    return((userPanelWidthEm > 15) ? (userPanelWidthEm) : (22));
  }

  get c_userHelpPanelWidthEm() {
    return(50);
  }

  get c_userCaptureCardMinHeightEm() {
    var userCaptureCardMinHeightEm = this.c_combinedUserObj.capture_card_min_height_em;
    if(!JSFUNC.is_number(userCaptureCardMinHeightEm) || (userCaptureCardMinHeightEm < 15) || (userCaptureCardMinHeightEm > 100)) {
      userCaptureCardMinHeightEm = 20;
    }
    return(userCaptureCardMinHeightEm);
  }

  get c_userCaptureCardMaxHeightEm() {
    var userCaptureCardMaxHeightEm = this.c_combinedUserObj.capture_card_max_height_em;
    if(!JSFUNC.is_number(userCaptureCardMaxHeightEm) || (userCaptureCardMaxHeightEm < 15) || (userCaptureCardMaxHeightEm > 100)) {
      userCaptureCardMaxHeightEm = 20;
    }

    //max height cannot be less than the min height
    if(userCaptureCardMaxHeightEm < this.c_userCaptureCardMinHeightEm) {
      userCaptureCardMaxHeightEm = this.c_userCaptureCardMinHeightEm;
    }

    return(userCaptureCardMaxHeightEm);
  }

  get c_recentlyVisitedCaptureIDsArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_combinedUserObj.recently_visited_capture_ids_comma));
  }

  get c_userPerEmailFavoritedCaptureIDsArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_combinedUserObj.favorited_capture_ids_comma));
  }


  //user options on Capture Table
  get c_userCSTEditCellsModeTF() {
    const c_userCanSwitchToCSTCellEditModeTF = this.c_userCanSwitchToCSTCellEditModeTF;
    return(c_userCanSwitchToCSTCellEditModeTF && (this.c_combinedUserObj.cst_edit_cells_mode_01 === 1));
  }

  get c_userCaptureTableRowHeightOptionsObj() {
    const c_combinedUserObj = this.c_combinedUserObj;

    const headerTextMaxHeightTrueNowrapFalse = (c_combinedUserObj.cst_row_height_em > 2);
    var headerHeightEm = 2;
    var headerTextMaxHeightEm = undefined;
    if(headerTextMaxHeightTrueNowrapFalse) {
      headerHeightEm = 4;
      headerTextMaxHeightEm = 3.3;
    }

    var cellCaptureFavoritesDirectEditSizeEm = undefined; //default favorite star size for large rows
    if(c_combinedUserObj.cst_row_height_em <= 2) {
      cellCaptureFavoritesDirectEditSizeEm = 1.2;
    }
    else if(c_combinedUserObj.cst_row_height_em <= 2.8) {
      cellCaptureFavoritesDirectEditSizeEm = 1.6;
    }

    const cellCapturePriorityDirectEditTF = (c_combinedUserObj.cst_row_height_em >= 5);

    const cellTextMaxHeightWrapTrueNowrapFalse = (c_combinedUserObj.cst_row_height_em > 2.4);

    var cellTextMaxHeightEm = 1.2;
    if(c_combinedUserObj.cst_row_height_em > 1.5) {
      cellTextMaxHeightEm = (c_combinedUserObj.cst_row_height_em - 0.5);
    }

    return({
      headerTextMaxHeightTrueNowrapFalse: headerTextMaxHeightTrueNowrapFalse,
      headerHeightEm: headerHeightEm,
      headerTextMaxHeightEm: headerTextMaxHeightEm,
      cellCaptureFavoritesDirectEditSizeEm: cellCaptureFavoritesDirectEditSizeEm,
      cellCapturePriorityDirectEditTF: cellCapturePriorityDirectEditTF,
      cellTextMaxHeightWrapTrueNowrapFalse: cellTextMaxHeightWrapTrueNowrapFalse,
      cellTextMaxHeightEm: cellTextMaxHeightEm
    });
  }


  //user options on Stages View
  get c_userStageViewBoxSize() {
    const c_combinedUserObj = this.c_combinedUserObj;
    if((c_combinedUserObj.stage_view_box_size >= 1) && (c_combinedUserObj.stage_view_box_size <= 3)) {
      return(c_combinedUserObj.stage_view_box_size);
    }
    return(2);
  }

  get c_userStageViewSelectedCaptureTypeIDsArray() {
    const c_combinedUserObj = this.c_combinedUserObj;
    return(JSFUNC.convert_comma_list_to_int_array(c_combinedUserObj.stage_view_selected_capture_type_ids_comma));
  }

  get c_userStageViewMinimizedStageIDsArray() {
    const c_combinedUserObj = this.c_combinedUserObj;
    return(JSFUNC.convert_comma_list_to_int_array(c_combinedUserObj.stage_view_minimized_stage_ids_comma));
  }



  //firewalled divisions
  get c_userDivisionFirewalledDivisionIDsCommaOrAllm1() {
    if(DatabaseMobx.c_companyUsingDivisionFirewallingTF) { //if not using firewalling, always return "-1" for all users to load all captures
      if(this.c_combinedUserObj.divisionTreeID !== "00") { //top 00 division always loads all captures, return "-1"
        if(this.c_combinedUserObj.divisionFirewalledDivisionIDsComma !== "-1") { //if the user's firewalled division ids is "-1" return "-1"
          const allDivisionIDsAreSelectedForFirewallingTF = JSFUNC.determine_if_every_id_within_mapOfMaps_is_preset_in_input_int_comma_list_tf(DatabaseMobx.o_tbl_a_divisions, "id", this.c_combinedUserObj.divisionFirewalledDivisionIDsComma);
          if(!allDivisionIDsAreSelectedForFirewallingTF) { //if every divisionID is selected for firewalling, return "-1" and load all captures
            return(this.c_combinedUserObj.divisionFirewalledDivisionIDsComma);
          }
        }
      }
    }
    return("-1"); //-1 flag to load all captures when firewalling is not in use or user's division is top 00
  }

  get c_userDivisionFirewalledDivisionIDsArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_userDivisionFirewalledDivisionIDsCommaOrAllm1));
  }

  get c_userFirewalledCaptureIDsCommaOrAllm1() {
    if(this.c_userDivisionFirewalledDivisionIDsCommaOrAllm1 === "-1") {
      return("-1");
    }
    return(JSFUNC.get_column_vector_from_mapOfMaps(DatabaseMobx.o_tbl_captures, "id"));
  }


  //User Panel - System
  get c_userArchivedCapturesAlwaysLoadArchivedTF() {
    return(this.c_combinedUserObj.archived_captures_preference === "alwaysLoadArchived");
  }

  get c_userArchivedCapturesNoLoadArchivedWithPromptTF() {
    return(!JSFUNC.in_array(this.c_combinedUserObj.archived_captures_preference, ["alwaysLoadArchived", "noLoadArchivedNoPrompt"])); //"noLoadArchivedWithPrompt"
  }
 

  //User Panel - Open Capture
  get c_userDatesCardIsFixedAdminSingleColumnTF() {
    return(this.c_combinedUserObj.dates_card_three_cols_0_single_col_1 === 1);
  }


  //user options on Capture cards
  get c_userNotepadSelectedTabDbName() {
    const c_combinedUserObj = this.c_combinedUserObj;
    if(JSFUNC.in_array(c_combinedUserObj.notepad_selected_tab_db_name, ["noteStamps", "notepad"])) {
      return(c_combinedUserObj.notepad_selected_tab_db_name);
    }
    return("noteStamps");
  }

  get c_userNotepadNoteStampsViewFlag() {
    const c_combinedUserObj = this.c_combinedUserObj;
    if(JSFUNC.in_array(c_combinedUserObj.notepad_note_stamps_view_flag, ["cards2col", "cards3col"])) {
      return(c_combinedUserObj.notepad_note_stamps_view_flag);
    }
    return("full");
  }


  //user GCSS options
  get c_userGcssSam0GovWin1() {
    return(this.c_combinedUserObj.gcss_sam0_govwin1);
  }

  get c_userGcssDataSource0SamTF() { return(this.c_userGcssSam0GovWin1 === 0); }
  get c_userGcssDataSource1GovWinTF() { return(this.c_userGcssSam0GovWin1 === 1); }
  get c_userGcssDataSource2FedCompTF() { return(this.c_userGcssSam0GovWin1 === 2); }

  get c_userGcssDataSourceName() {
    if(this.c_userGcssDataSource0SamTF) { return("SAM.gov"); }
    else if(this.c_userGcssDataSource1GovWinTF) { return("GovWin"); }
    else if(this.c_userGcssDataSource2FedCompTF) { return("Federal Compass"); }
    return("GCSS Data Source " + this.c_userGcssSam0GovWin1);
  }

  get c_userGcssSearchResultsCompact0Large1() {
    if(this.c_combinedUserObj.gcss_search_results_compact0_large1 === 1) {
      return(1);
    }
    return(0); //compact is default
  }

  get c_userGcssSearchResultsSortGcssFieldDbName() {
    return(this.c_combinedUserObj.gcss_search_results_sort_gcss_field_db_name);
  }

  get c_userGcssSearchResultsSortIsAscTF() {
    return(this.c_combinedUserObj.gcss_search_results_sort_desc0_asc1 !== 0); //ASC is default
  }

  get c_userGcssSamGcssIDsMarkedForImportArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_combinedUserObj.gcss_ids_marked_for_import_comma));
  }

  get c_userGcssGovWinIDsMarkedForImportArray() {
    return(JSFUNC.convert_comma_list_to_array(this.c_combinedUserObj.gcss_govwin_ids_marked_for_import_comma));
  }

  get c_userGcssFedCompIDsMarkedForImportArray() {
    return(JSFUNC.convert_comma_list_to_array(this.c_combinedUserObj.gcss_fedcomp_ids_marked_for_import_comma));
  }



  //precomputed SVGs in all sizes needed based on user font size (svgName20 means SVG "Name" with a size of 2.0 em)
  get c_svgFavoriteIconStdSizeFav() { return(<CEGeneralReact.SvgFavoriteIcon p_sizeEm={1.9} p_color={DatabaseMobx.c_companyCaptureFavoritesColor} />); }
  get c_svgFavoriteIconStdSizeNotFav() { return(<CEGeneralReact.SvgFavoriteIcon p_sizeEm={1.9} p_color="dddddd" />); }
  get c_svgFavoriteIconCSTSizeFav() { return(<CEGeneralReact.SvgFavoriteIcon p_sizeEm={this.c_userCaptureTableRowHeightOptionsObj.cellCaptureFavoritesDirectEditSizeEm} p_color={DatabaseMobx.c_companyCaptureFavoritesColor} />); }
  get c_svgFavoriteIconCSTSizeNotFav() { return(<CEGeneralReact.SvgFavoriteIcon p_sizeEm={this.c_userCaptureTableRowHeightOptionsObj.cellCaptureFavoritesDirectEditSizeEm} p_color="dddddd" />); }
  get c_svgEditPencil11Gray() { return(<CEGeneralReact.SvgEditPencil p_sizeEm={1.1} p_color="bbbbbb" />); }
  get c_svgEditPencil11White() { return(<CEGeneralReact.SvgEditPencil p_sizeEm={1.1} p_color="ffffff" />); }
  get c_svgCompany24CreateNew() { return(<CEGeneralReact.SvgCompany p_sizePx={2.4 * this.c_userFontSizePx} p_color="f6f6f1" p_plusTF={true} />); }
  get c_svgPerson24CreateNew() { return(<CEGeneralReact.SvgPerson p_sizePx={2.4 * this.c_userFontSizePx} p_color="f6f6f1" p_plusTF={true} />); }
  get c_svgCompany20() { return(<CEGeneralReact.SvgCompany p_sizePx={2.0 * this.c_userFontSizePx} p_color="996" p_plusTF={false} />); }
  get c_svgCompany15() { return(<CEGeneralReact.SvgCompany p_sizePx={1.5 * this.c_userFontSizePx} p_color="996" p_plusTF={false} />); }
  get c_svgCompany10() { return(<CEGeneralReact.SvgCompany p_sizePx={1.0 * this.c_userFontSizePx} p_color="996" p_plusTF={false} />); }
  get c_svgPerson15() { return(<CEGeneralReact.SvgPerson p_sizePx={1.5 * this.c_userFontSizePx} p_color="996" p_plusTF={false} />); }
  get c_svgPerson10() { return(<CEGeneralReact.SvgPerson p_sizePx={1.0 * this.c_userFontSizePx} p_color="996" p_plusTF={false} />); }



  //**action methods*************************************************************************************************************
  a_set_login_with_key_tf(i_loginWithKeyTF) { this.o_loginWithKeyTF = i_loginWithKeyTF; }
  a_set_user_id(i_userID) { this.o_userID = i_userID; }
  a_set_user_per_email_id(i_userPerEmailID) { this.o_userPerEmailID = i_userPerEmailID; }
  a_set_user_company_code(i_companyCode) { this.o_userCompanyCode = i_companyCode.toUpperCase(); }

  a_update_user_field(i_columnDbName, i_newValue, i_fieldIdsb) { //update single column value in tbl_a_users
    const jsDescription = JSFUNC.js_description_from_action("UserMobx", "a_update_user_field", ["i_columnDbName", "i_newValue", "i_fieldIdsb"], [i_columnDbName, i_newValue, i_fieldIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_users", this.o_userID, i_columnDbName, i_newValue, i_fieldIdsb);
    C_CallPhpTblUID.execute();
  }

  a_update_user_per_email_field(i_columnDbName, i_newValue, i_fieldIdsb, i_functionOnSuccess=undefined, i_functionOnError=undefined) { //update single column value in tbl_a_users_per_email
    const jsDescription = JSFUNC.js_description_from_action("UserMobx", "a_update_user_per_email_field", ["i_columnDbName", "i_newValue", "i_fieldIdsb"], [i_columnDbName, i_newValue, i_fieldIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_update("tbl_a_users_per_email", this.o_userPerEmailID, i_columnDbName, i_newValue, i_fieldIdsb);

    if(JSFUNC.is_function(i_functionOnSuccess)) {
      const functionOnSuccess = (i_parseResponse) => {
        if(i_parseResponse.outputObj.u0 === "1") {
          i_functionOnSuccess();
        }
        else {
          i_functionOnError();
        }
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    if(JSFUNC.is_function(i_functionOnError)) {
      C_CallPhpTblUID.add_function("onError", i_functionOnError);
    }

    C_CallPhpTblUID.execute();

    //simultaneously set enquire media for screen size of certain user screen size fields are changed that alter the main screen size
    if(i_columnDbName === "font_size_px") {
      CaptureExecMobx.a_set_enquire_media_width_from_font_size_and_right_panel(i_newValue, RightPanelMobx.c_currentDesktopRightPanelWidthEm);
    }
    else if(i_columnDbName === "right_panel_width_em") {
      CaptureExecMobx.a_set_enquire_media_width_from_font_size_and_right_panel(this.c_userFontSizePx, i_newValue);
    }
  }

  //**regular class methods*************************************************************************************************************
  user_id_is_one_of_logged_in_user_per_email_multilogin_tf(i_userID) {
    return(JSFUNC.in_array(i_userID, this.c_multiLoginUserIDsArray));
  }

  compute_updated_recently_visited_capture_ids_comma(i_newCaptureID) {
    const maxNumRecentCaptures = 5;
    var updatedRecentlyVisitedCaptureIDsArray = [i_newCaptureID]; //start with the new captureID at the front of the updated array, move the others down
    var numCaptures = 1; //counter for recent captures stored in the updated array (1 is already stored)
    for(let oldCaptureID of this.c_recentlyVisitedCaptureIDsArray) {
      if(oldCaptureID !== i_newCaptureID) { //the new captureID moves to the front of the list, but any duplicate entries will not be printed
        updatedRecentlyVisitedCaptureIDsArray.push(oldCaptureID);
        numCaptures++;
        if(numCaptures >= maxNumRecentCaptures) {
          break;
        }
      }
    }
    return(JSFUNC.convert_array_to_comma_list(updatedRecentlyVisitedCaptureIDsArray));
  }


}
export default new UserMobx();
